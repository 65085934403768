import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import context from "../../../context";
import { WidgetTitle } from "../../../components";
import { LastSyncDataBar } from "../../../components";
import { WidgetData } from "../../../store/widgetsData/widgetsDataSlice";

// Inner imports
import { EDUCATE_ME_LINKS } from "./constants";
import { useCustomWidgetText } from "../../../hooks";
// import { SoSFunnelButton } from "./components/SoSFunnelButton/SoSFunnelButton";

const useWidgetActionBar = (
  searchId: string,
  dashboardId: string,
  widgetId: string = "",
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = history.location as any;

  const { setActionBarProps, isPresentation } = useContext(context);

  const widgetsData = useSelector(({ widgetsData }: RootState) => widgetsData);

  const [isEditing, setIsEditing] = useState(false);

  const [isCopiedDashboard, dashboardName]: [boolean, string] = useSelector(
    ({ dashboards }: RootState) => {
      const { isCopiedDashboard = false, name = "" } =
        dashboards.entities[dashboardId] || {};

      return [isCopiedDashboard, name];
    },
  );

  useEffect(() => {
    setIsEditing(false);
  }, [searchId, dashboardId, widgetId]);

  const {
    customWidgetSettings,
    editingEnabled,
    saveCustomWidgetSettings,
  } = useCustomWidgetText(searchId, dashboardId, widgetId);

  const customWidgetTitle = useMemo(() => customWidgetSettings?.title, [
    customWidgetSettings?.title,
  ]);

  const widgetData: WidgetData | undefined = useMemo(() => {
    const widget = widgetsData.entities[widgetId];
    if (!widget) return;

    return widget;
  }, [widgetsData.entities, widgetId]);

  const breadcrumbs: ActionBar.BreadcrumbOption[] = useMemo(() => {
    if (isPresentation) return [];
    const { homePosition } = state || {};
    return [
      {
        title: dashboardName,
        linkOptions: {
          href: `/dashboards/${dashboardId}`,
          state: {
            position: homePosition || 0,
          },
        },
      },
      { title: widgetData?.placeHolderOnUi || "" },
    ];
  }, [
    isPresentation,
    dashboardName,
    dashboardId,
    state,
    widgetData?.placeHolderOnUi,
  ]);

  const actions = useMemo((): JSX.Element | undefined => {
    const isSearchIdOk = searchId && typeof searchId === "string";
    const isWidgetIdOk = widgetId && typeof widgetId === "string";
    if (!isSearchIdOk || !isWidgetIdOk) return;

    return <LastSyncDataBar searchId={searchId} widgetId={widgetId} />;
  }, [searchId, widgetId]);

  const actionsOptions = useMemo(() => {
    const actionsOptions: ActionBar.ActionsOption[] = [];
    const educateMeLink = EDUCATE_ME_LINKS[widgetId];

    if (educateMeLink) {
      actionsOptions.push({
        id: "widgetDetails",
        text: t("widget_title_learn_more"),
        icon: "Details",
        onClickHandler: () => window.open(educateMeLink, "_blank"),
      });
    }

    if (isCopiedDashboard) return actionsOptions;

    if (editingEnabled) {
      actionsOptions.push({
        id: "widgetEdit",
        icon: "Edit",
        text: t("widget_title_option_edit"),
        onClickHandler: () => setIsEditing(true),
      });
    }

    if (customWidgetTitle) {
      actionsOptions.push({
        id: "widgetClear",
        icon: "Trash",
        text: t("widget_title_option_clear"),
        onClickHandler: () => saveCustomWidgetSettings({ title: "" }),
      });
    }

    return actionsOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetId, isCopiedDashboard, editingEnabled, customWidgetTitle, t]);

  const widgetTitle = useMemo(() => {
    return (
      <WidgetTitle
        searchId={searchId}
        dashboardId={dashboardId}
        widgetId={widgetId}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }, [searchId, dashboardId, widgetId, isEditing]);

  // const additionalButton: JSX.Element | null = useMemo(() => {
  //   if (isPresentation || widgetId !== "search funnel") return null;

  //   return <SoSFunnelButton searchId={searchId} dashboardId={dashboardId} />;
  // }, [widgetId, searchId, dashboardId, isPresentation]);

  useEffect(() => {
    setActionBarProps({
      title: widgetTitle,
      lastSync: {
        searchId,
        widgetId,
      },
      // ...(additionalButton ? { additionalButton } : {}),
      actionsOptions: {
        options: actionsOptions,
        placeholder: "d_actions_button",
      },
      breadcrumbs,
    });
  }, [
    widgetTitle,
    actions,
    breadcrumbs,
    // additionalButton,
    actionsOptions,
    setActionBarProps,
    searchId,
    widgetId,
  ]);
};

export { useWidgetActionBar };
