import * as yup from "yup";

const notImportantStringSchema = yup.string().notRequired().default("");

const arrayOfStringsSchema = yup
  .array()
  .of(yup.string().required())
  .transform((x) => x || []);

const excludedArticlesSchema = yup.array().of(
  yup
    .object({
      id: yup.string().required(),
      title: notImportantStringSchema,
      date: notImportantStringSchema,
      parameter: notImportantStringSchema,
      sentence: notImportantStringSchema,
      source: notImportantStringSchema,
      url: notImportantStringSchema,
    })
    .transform((x) => x || []),
);

const searchBrandSchema = yup
  .array()
  .of(
    yup.object({
      name: yup.string().required(),
      brandTableName: yup.string().required(),
      brandNames: yup
        .array()
        .of(
          yup
            .object({
              name: yup.string().required(),
              tableName: yup.string().required(),
            })
            .required(),
        )
        .required(),
      include: arrayOfStringsSchema,
      exclude: arrayOfStringsSchema,
      selected: arrayOfStringsSchema,
      keywords: arrayOfStringsSchema,
      aiTrainerExcludedWords: arrayOfStringsSchema,
      excludedKeywords: arrayOfStringsSchema,
      excludedArticles: yup
        .array()
        .of(
          yup.object({
            id: yup.string().required(),
            title: yup.string().default(""),
          }),
        )
        .transform((x) => x || []),
      excludedESGArticles: excludedArticlesSchema,
      excludedESGSources: arrayOfStringsSchema,
      excludedBusinessDriversArticles: excludedArticlesSchema,
      excludedBusinessDriversSources: arrayOfStringsSchema,
      excludedReputationArticles: excludedArticlesSchema,
      excludedReputationSources: arrayOfStringsSchema,
      useAllKeywords: yup.boolean().notRequired().default(false),
    }),
  )
  .required();

const searchSchema = yup
  .object({
    brandId: yup.string().required(),
    searchName: yup.string().default("Unnamed search"),
    brands: searchBrandSchema,
    mainBrand: yup.string().required(),
    subRegions: arrayOfStringsSchema,
    createdAt: notImportantStringSchema,
    updatedAt: notImportantStringSchema,
    ownerId: notImportantStringSchema,
    industryId: notImportantStringSchema,
    industryName: notImportantStringSchema,
    marketId: notImportantStringSchema,
    lastSyncDate: notImportantStringSchema,
    isPinned: yup.boolean().default(false),
    isFirstSyncDone: yup.boolean().default(false),
  })
  .required();

const keyWordsSchema = yup
  .array()
  .of(
    yup
      .object({
        string: yup.string().required(),
        totalVolume: yup.number().required(),
        values: yup
          .array()
          .of(
            yup.object({
              date: yup.string().required(),
              volume: yup.number().required(),
            }),
          )
          .required(),
      })
      .required(),
  )
  .required();

export { searchSchema, searchBrandSchema, keyWordsSchema };
