type Words = {
  keyword?: string;
  volume?: number;
  trend?: number;
}[];

export const filterBrandExcludedWords = (
  topKeywords?: Words,
  topKeywordsExcludedWords?: string[],
): Words => {
  if (!topKeywords) return [];
  if (!topKeywordsExcludedWords) return topKeywords;

  const flitteredBrandExcludedWords = topKeywords.filter(({ keyword }) => {
    if (!keyword) return;
    return !topKeywordsExcludedWords.includes(keyword.toLowerCase());
  });

  return flitteredBrandExcludedWords;
};
