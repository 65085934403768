import { TableCheckbox } from "../../../../../components/Table/components";
import { updateTimePlot } from "../../../../../store/timePlots/actions";
import { TimePlotterTableSort } from "./types";

const TIME_PLOTTER_TABLE_DEFAULT_SORT = {
  isActive: "desc",
} as TimePlotterTableSort;

const TIME_PLOTTER_TABLE_COLUMNS = [
  {
    key: "name",
    labelKey: "tp_table_th_name",
    isSortable: true,
    isInShortTable: true,
  },
  {
    key: "searchName",
    labelKey: "event_table_th_searchId",
    isSortable: true,
    isInShortTable: true,
  },
  {
    key: "dateFrom",
    dataKey: "dateFromFormatted",
    labelKey: "tp_table_th_startDate",
    isSortable: true,
    isInShortTable: true,
  },
  {
    key: "dateTo",
    dataKey: "dateToFormatted",
    labelKey: "tp_table_th_endDate",
    isSortable: true,
    isInShortTable: true,
  },
  {
    key: "countryName",
    labelKey: "tp_table_th_country",
    isSortable: true,
    isInShortTable: true,
  },
  {
    key: "isActive",
    dataKey: "active",
    labelKey: "tp_table_th_isActive",
    isSortable: true,
    isInShortTable: true,
    isAction: true,
    getComponent: ({ id, isActive }: Store.TimePlot) => (
      <TableCheckbox
        key={id}
        type="default"
        isChecked={isActive}
        onClick={() =>
          updateTimePlot(
            id,
            {
              isActive: !isActive,
            },
            false,
          )
        }
      />
    ),
  },
];

export { TIME_PLOTTER_TABLE_DEFAULT_SORT, TIME_PLOTTER_TABLE_COLUMNS };
