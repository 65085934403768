import { FC, useMemo } from "react";

import styles from "./EsgIndexPreview.module.scss";
import esgParameters from "../../../data/esgParameters";
import {
  Preloader,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { INDEX } from "../../constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import { WIDGET_ID } from "./constants";
import { WidgetChart, WidgetSummaryProps } from "../../types";
import {
  SummaryTimePeriod,
  SummaryYearlyAverage,
} from "../../descriptionInsertionComponents";

export const EsgIndexSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData, pieChartData } = useMemo(() => {
    const { brandData = {} } =
      widgetData?.find(({ brandName }) => brandName === selectedBrand.name) ||
      {};
    const { lineChart = [], pieChart } = brandData;

    return {
      lineChartData: lineChart,
      pieChartData: pieChart,
    };
  }, [widgetData, selectedBrand]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (!lineChartData.length) return charts;

    charts.push({
      type: "lineChart",
      chart: (
        <LineChart
          className={styles.chart}
          labels={esgParameters.map(({ name, color }) => ({
            id: name,
            name,
            color,
          }))}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: 3,
          }}
          chartSettings={{
            hasBackground: true,
          }}
          axis={{
            yAxisVerticalPadding: { top: 10, bottom: 0 },
          }}
        />
      ),
    });

    return charts;
  }, [filteredData, lineChartData.length, searchId, timePlot.id]);

  const { widgetViews: views } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
  });

  const mainBrandPieChartYearlyValue = useMemo<number>(
    () => pieChartData?.values[INDEX] || 0,
    [pieChartData],
  );

  const mainBrandValues = useMemo<number[]>(
    () => filteredData.map(({ values }) => values[INDEX] || 0),
    [filteredData],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={mainBrandValues}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
        <SummaryYearlyAverage
          data={mainBrandPieChartYearlyValue}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
      </div>
      <div className={styles.chartWrapper}>{views[0]?.chart}</div>
    </div>
  );
};
