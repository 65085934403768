import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./TimePeriodSelector.module.scss";
import { Select } from "../../../components";
import { BookDemo } from "../../../icons";
// Inner imports
import type { TimePeriodSelectorType } from "../../WidgetButtonsBar/types";

export const TimePeriodSelector: FC<TimePeriodSelectorType> = ({
  periods = [],
  currentPeriodValue = "",
  onPeriodClicked,
}) => {
  const { t } = useTranslation();

  const currentPeriodLabel = useMemo(
    () =>
      periods.find(({ value }) => value === currentPeriodValue)?.value || "",
    [periods, currentPeriodValue],
  );

  if (!periods.length) return null;

  const title = t("time_period_selector");

  return (
    <div className={styles.timePeriodSelector}>
      <p className={styles.title}>{title}</p>
      <Select
        icon={<BookDemo />}
        title={title}
        options={periods}
        value={currentPeriodLabel}
        changeHandler={onPeriodClicked}
      />
    </div>
  );
};
