import actionTypes from "../actionTypes";

const initialState: Store.User = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  userCreatedAt: "",
  company: "",
  role: "",
  phone: "",
  department: "",
  market: "",
};

const setUserData = (state: Store.User, action: any) => {
  return { ...state, ...action.userData };
};

const removeUserData = () => initialState;

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return setUserData(state, action);
    case actionTypes.REMOVE_USER_DATA:
      return removeUserData();
    default:
      return state;
  }
};

export default reducer;
