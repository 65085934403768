import {
  FC,
  useRef,
  useEffect,
  useState,
  ReactChild,
  memo,
  ReactElement,
} from "react";
import styles from "./WithTooltip.module.scss";
import { useOutsideClickHandler } from "../../hooks";
import cx from "classnames";
import { showToastNotification } from "../ToastNotification/utils";

type CustomPosition = {
  vertical: number;
  horizontal: number;
};

type Props = {
  toolTipContent?: string;
  className?: string;
  wrapperClassName?: string;
  children: ReactChild;
  isOpenOnClick?: boolean;
  customPosition?: CustomPosition;
  isToolTipActive?: boolean;
  isMobileView?: boolean;
};

export const WithTooltip: FC<Props> = memo(
  ({
    toolTipContent,
    className,
    wrapperClassName,
    children,
    isOpenOnClick,
    customPosition,
    isToolTipActive = true,
    isMobileView = false,
  }: Props): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openingDirection, setOpeningDirection] = useState<{
      x?: "center" | "left";
      y?: "down" | "up";
    }>({
      x: undefined,
      y: undefined,
    });

    const tooltipWrapperRef = useRef<HTMLDivElement>(null);
    const tooltipContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!!customPosition) return;
      const _setOpeningDirection = () => {
        const clientHeight = document.documentElement.clientHeight;
        const clientWidth = document.documentElement.clientWidth;
        const selectWidth = tooltipContentRef.current?.clientWidth;
        const selectHeight = tooltipContentRef.current?.clientHeight;
        const selectBottom = tooltipWrapperRef.current?.getBoundingClientRect()
          .bottom!;
        const selectRight = tooltipWrapperRef.current?.getBoundingClientRect()
          .right!;

        if (selectWidth && selectHeight) {
          return setOpeningDirection({
            x: clientWidth > selectRight + selectWidth ? "center" : "left",
            y: clientHeight > selectBottom + selectHeight ? "down" : "up",
          });
        }
        return setOpeningDirection({
          x: undefined,
          y: undefined,
        });
      };
      _setOpeningDirection();
      window.addEventListener("resize", _setOpeningDirection);
      return () => {
        window.removeEventListener("resize", _setOpeningDirection);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
      if (isOpen && isMobileView && toolTipContent) {
        showToastNotification({
          type: "info",
          text: toolTipContent,
        });
      }
    }, [isOpenOnClick, isMobileView, isOpen, toolTipContent]);

    useOutsideClickHandler(tooltipWrapperRef, () => setIsOpen(false));

    const onHandleClickOpen = (): void => setIsOpen((prev) => !prev);

    const closeTooltip = (): void => {
      setIsOpen(false);
    };

    return (
      <div
        className={cx(styles.withTooltip, wrapperClassName)}
        ref={tooltipWrapperRef}
        onMouseEnter={() =>
          !isMobileView && isToolTipActive && !isOpenOnClick && setIsOpen(true)
        }
        onMouseLeave={() =>
          !isMobileView && isToolTipActive && !isOpenOnClick && closeTooltip()
        }
        onClick={() => isToolTipActive && isOpenOnClick && onHandleClickOpen()}
      >
        {children}
        <div
          ref={tooltipContentRef}
          className={cx(
            styles.tooltip,
            className,
            styles[openingDirection?.x || ""],
            styles[openingDirection?.y || ""],
            isOpen ? styles.tooltipActive : "",
          )}
          style={
            customPosition
              ? {
                  left: `${customPosition.horizontal}px`,
                  bottom: `${customPosition.vertical}px`,
                }
              : {}
          }
        >
          {toolTipContent}
        </div>
      </div>
    );
  },
);
