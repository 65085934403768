import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./ShareOfVolumePreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
} from "src/components";
import { onErrorHandler } from "src/widgets/helpers";
import { useWidgetFetching, useWidgetPreview } from "src/widgets/hooks";

// Inner imports
import { extractShareOfVolumeData } from "./utils";
import { WIDGET_ID, WIDGET_COLLECTION_ID } from "./constants";
import { ShareOfVolumeTablePreview } from "./components";
import { WidgetChart } from "../../types";

export const ShareOfVolumePreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(
    searchId,
    WIDGET_COLLECTION_ID,
  );

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};

    return {
      lineChartData: extractShareOfVolumeData(lineChart),
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  const charts = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(pieChartData).length) {
      charts.push({
        type: "table",
        chart: <ShareOfVolumeTablePreview searchId={searchId} />,
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            labels={brands}
            data={lineChartData}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              increaseYAxisWidthInPercentage: 10,
            }}
          />
        ),
      });
    }

    return charts;
  }, [pieChartData, lineChartData, brands, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(
              setChartRef,
              searchId,
              WIDGET_COLLECTION_ID,
              dispatch,
            )
          }
        />
      )}
    </div>
  );
};
