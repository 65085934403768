import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  WidgetPendingStub,
  WidgetRequiresCompetitors,
} from "../../../../icons";
import styles from "./Stubs.module.scss";

const DashboardNotFoundStub: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.placeholder}>
      {t("wt_dashboard_not_found_error")}
    </div>
  );
};

const SearchWasDeletedStub: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.placeholder}>{t("wt_brand_was_deleted_error")}</div>
  );
};

const PendingStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholderAutomaticallyReloadWrapper}>
      <WidgetPendingStub />
      {t("wt_widget_calculating")}
    </div>
  );
};

const UnavailableForSingleBrandStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholderWrapper}>
      <WidgetRequiresCompetitors />
      {t("widget_competitors_required")}
    </div>
  );
};

const NoDataForSelectedBrandStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholderWrapper}>
      {t("wt_widget_brand_no_data")}
    </div>
  );
};

export {
  DashboardNotFoundStub,
  SearchWasDeletedStub,
  PendingStub,
  UnavailableForSingleBrandStub,
  NoDataForSelectedBrandStub,
};
