import { useMemo, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./CompetitivenessTable.module.scss";
import DATE_RANGES_WITH_OVERALL from "../../../../../data/dateRangesWithOverall";

import type { MarketometerDataItem } from "../../types";
import { parseRangeData } from "../../utils";

interface IProps {
  className?: string;
  style?: CSSProperties;
  dateRanges: {
    label: string;
    currentDataItem: MarketometerDataItem;
    previousDataItem: MarketometerDataItem;
    widgetData: ReturnType<typeof parseRangeData>;
  }[];
  mainBrandName?: string;
  currentDateRange?: string;
}

export const CompetitivenessTable = ({
  className = "",
  dateRanges,
  mainBrandName,
  currentDateRange,
}: IProps) => {
  const { t } = useTranslation();

  const currentRange = useMemo(() => {
    const currentDateRangeLabel = DATE_RANGES_WITH_OVERALL.find(
      ({ value }) => value === currentDateRange,
    )?.label;

    const currentRange = dateRanges.find(
      ({ label }) => label === currentDateRangeLabel,
    );
    if (currentRange) {
      return {
        ...currentRange,
        widgetData: [...currentRange.widgetData].sort(
          (a, b) => b.values.current - a.values.current,
        ),
      };
    }
  }, [dateRanges, currentDateRange]);

  const data = useMemo(() => {
    return currentRange?.widgetData || [];
  }, [currentRange]);
  const range = useMemo(() => currentRange?.label || "1 month", [currentRange]);

  if (!data.length) return null;

  return (
    <table className={cx(styles.marketometerTable, className)}>
      <thead className={styles.thead}>
        <tr>
          <td>{t("marketometer_table_competitor_name")}</td>
          <td>{t("marketometer_table_this_month_label")}</td>
          <td>{range}</td>
          <td>{t("marketometer_table_change_label")}</td>
        </tr>
      </thead>
      <tbody>
        {data.map(({ brandName, values, color }, i) => (
          <tr
            key={i}
            style={{ color: brandName === mainBrandName ? color : "" }}
          >
            <td>
              <div className={styles.brandName}>
                <span
                  className={styles.dot}
                  style={{ background: color }}
                ></span>{" "}
                {brandName}
              </div>
            </td>
            <td>{values.current}</td>
            <td>{values.prev}</td>
            <td>{values.delta}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
