import React, { useState } from "react";
import { TagCloud } from "react-tagcloud";
import useResizeObserver from "use-resize-observer";
import styles from "./TopKeywordsWidget.module.scss";
import cx from "classnames";

type Element = {
  value: string;
  count: number;
  isPercentage: boolean;
};

type Props = {
  className?: string;
  data: Element[];
  parent?: HTMLElement;
  type: "preview" | "page";
  customStyles?: {
    tagColor?: string;
    tagBackgroundColor?: string;
  };
};

export const TopKeywordsWidget = React.memo(
  ({
    className = "",
    data = [],
    parent = undefined,
    customStyles = { tagColor: "white", tagBackgroundColor: "#bbbb77" },
    type = "preview",
  }: Props) => {
    const {
      ref: chartWrapperRef,
      width: chartWrapperWidth = 0,
      height: chartWrapperHeight = 0,
    } = useResizeObserver<HTMLDivElement>();

    const size =
      type === "preview"
        ? Math.min(chartWrapperHeight, chartWrapperWidth)
        : 320;

    const parentPosition = parent?.getBoundingClientRect();

    const parentData = parentPosition
      ? {
          top: parentPosition.y,
          bottom: parentPosition.y + parentPosition.height,
          left: parentPosition.x,
          right: parentPosition.x + parentPosition.width,
        }
      : {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        };

    const CustomRenderer = ({
      tag,
      tagSize,
    }: {
      tag: Element;
      tagSize: number;
    }) => {
      const [overflowingParent, setOverflowingParent] = useState(false);

      const getTriangleSize = () => {
        const initSize = size / 2.5;
        if (initSize < 10) return 10;
        if (initSize > 15) return 15;
        return initSize;
      };

      const checkIfOverflowing = (element: HTMLDivElement | null) => {
        if (!element || !parentPosition) return;

        const position = element.getBoundingClientRect();

        const childrenData = {
          top: position.y,
          bottom: position.y + position.height,
          left: position.x,
          right: position.x + position.width,
        };

        const leftIsOk = parentData.left < childrenData.left;
        const rightIsOk = parentData.right > childrenData.right;
        const bottomIsOk = parentData.bottom > childrenData.bottom + 20;

        if (!leftIsOk || !rightIsOk || !bottomIsOk) {
          setOverflowingParent(true);
        }
      };

      const tagSizes = () => {
        const minTagWidth = 100;
        const minTagHeight = 30;
        const percentageOfTagSize = 0.8;
        const heightValueToWidth = 3.5;

        const initWidth =
          (chartWrapperWidth * (tagSize * percentageOfTagSize)) / 100;
        const tagWidth = initWidth > minTagWidth ? initWidth : minTagWidth;
        const initHeight = tagWidth / heightValueToWidth;
        const tagHeight = initHeight > minTagHeight ? initHeight : minTagHeight;

        return {
          tagWidth,
          tagHeight,
        };
      };

      const { tagWidth, tagHeight } = tagSizes();

      const svgViewBox = `0 0 ${tagWidth} ${tagHeight / 2}`;

      const setSvgWordAttributes = (
        el: SVGTextElement | null,
        maxSize: number,
      ) => {
        if (!el) return;
        const { width, height } = el.getBBox();

        const widthTransform = (tagWidth / width) * maxSize;
        const heightTransform = (tagHeight / height) * maxSize;
        const value = Math.min(widthTransform, heightTransform);
        el.setAttribute("transform", `scale(${value})`);
        el.setAttribute("x", `${50 / value}%`);
        el.setAttribute("y", `${50 / value}%`);
      };

      return (
        <div
          style={{
            color: customStyles.tagColor,
            background: customStyles.tagBackgroundColor,
            opacity: overflowingParent ? "0" : "1",
            width: tagWidth,
            height: tagHeight,
          }}
          className={styles.word}
          title={String(tag.count)}
          ref={(element) => checkIfOverflowing(element)}
        >
          <div className={styles.name}>
            <svg
              viewBox={svgViewBox}
              style={{
                textAnchor: "middle",
              }}
              className={styles.name}
            >
              <text
                x="50%"
                y="50%"
                dy=".30em"
                ref={(el) => setSvgWordAttributes(el, 0.8)}
              >
                {tag.value}
              </text>
            </svg>
          </div>
          <div className={styles.count}>
            <svg
              viewBox={svgViewBox}
              style={{
                textAnchor: "middle",
              }}
              className={styles.count}
            >
              <text
                x="50%"
                y="50%"
                dy=".30em"
                ref={(el) => setSvgWordAttributes(el, 0.6)}
              >
                {tag.count}
                {tag.isPercentage && "%"}
              </text>
            </svg>
          </div>
          <div
            className={styles.triangle}
            style={{
              borderLeft: `${getTriangleSize()}px solid transparent`,
              borderRight: `${getTriangleSize()}px solid transparent`,
              borderTop: `${getTriangleSize()}px solid ${
                customStyles.tagBackgroundColor
              }`,
            }}
          ></div>
        </div>
      );
    };

    return (
      <div ref={chartWrapperRef} className={cx(styles.wrapper, className)}>
        {size > 0 && (
          <TagCloud
            className={styles.tagCloud}
            minSize={size / 35}
            maxSize={size / 5}
            tags={data}
            shuffle={false}
            renderer={(tag: Element, size: number, key: string | number) => (
              <CustomRenderer
                tag={tag}
                tagSize={size}
                key={`${tag.value}_${tag.count}_${key}`}
              />
            )}
          />
        )}
      </div>
    );
  },
);
