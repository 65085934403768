import { useEffect, useState } from "react";

type Element = {
  value: string;
  label: string;
};

type ReturnType = [
  Element[],
  (element: Element) => void,
  (value: string) => void,
];

const FIELD_NAME = "Telescope.searchingData";

const useSearchingData = (type: ActionBar.SearchBar.DataType): ReturnType => {
  const [searchingDataAsString, setSearchingDataAsString] = useState("");
  const [data, setData] = useState<Element[]>([]);

  useEffect(() => {
    const searchingDataAsString = localStorage.getItem(FIELD_NAME) || "";
    setSearchingDataAsString(searchingDataAsString);
  }, [type]);

  useEffect(() => {
    if (!searchingDataAsString) return;

    const searchingData = JSON.parse(searchingDataAsString);

    if (typeof searchingData === "object" && !Array.isArray(searchingData)) {
      const gotDataByType = searchingData?.[type] || [];

      if (typeof gotDataByType === "object" && Array.isArray(gotDataByType)) {
        const isCorrectObjectsInside = gotDataByType.every(
          ({ value, label }) => {
            const isValueExist = typeof value === "string";
            const isLabelExist = typeof label === "string";

            return isValueExist && isLabelExist;
          },
        );

        if (!isCorrectObjectsInside) return;
        return setData(gotDataByType.slice(0, 3));
      } else {
        setData([]);
      }

      return;
    }

    return;
  }, [searchingDataAsString, type]);

  const setElement = (element: Element) => {
    try {
      if (!searchingDataAsString) {
        const obj = {
          [type]: [element],
        };
        localStorage.setItem(FIELD_NAME, JSON.stringify(obj));
        return setData([element]);
      }

      const dataWithoutDuplicates = [element, ...data].reduce((acc, item) => {
        const { value } = item;
        const isItemAlreadyAdded = acc.some(
          (element) => element.value === value,
        );

        if (!isItemAlreadyAdded) acc.push(item);
        return acc;
      }, [] as Element[]);

      setDataToStateAndLocalStorage(dataWithoutDuplicates.slice(0, 3));
    } catch (err) {
      console.error(err);
    }
  };

  const deleteElement = (value: string) => {
    const newData = data.filter((element) => element.value !== value);
    setDataToStateAndLocalStorage(newData);
  };

  function setDataToStateAndLocalStorage(data: Element[]) {
    const searchingData = JSON.parse(searchingDataAsString || "");

    const dataForLocalStorage = {
      ...searchingData,
      [type]: data,
    };
    localStorage.setItem(FIELD_NAME, JSON.stringify(dataForLocalStorage));
    setData(data);
  }

  return [data, setElement, deleteElement];
};

export { useSearchingData };
