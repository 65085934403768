import { createDraftSafeSelector } from "@reduxjs/toolkit";

export type GetUserStatusResult = {
  isUserCompanyAdmin: boolean;
  isUserCompanyOwner: boolean;
  isUserCompanyOwnerOrAdmin: boolean;
  isSimpleCompanyMember: boolean;
};

export const selectUser = createDraftSafeSelector(
  ({ user }: Store.State) => user,
  (user) => user,
);

export const selectUserId = createDraftSafeSelector(selectUser, ({ id }) => id);

export const selectUserStatus = createDraftSafeSelector(
  ({ company }: Store.State) => company,
  selectUserId,
  getUserStatus,
);

export function getUserStatus(
  company: Store.Company,
  userId: Store.User["id"],
): GetUserStatusResult {
  const {
    isAdmin: isUserCompanyAdmin = false,
    isOwner: isUserCompanyOwner = false,
  } = company.members.find(({ id }) => id === userId) || {};

  return {
    isUserCompanyAdmin,
    isUserCompanyOwner,
    isUserCompanyOwnerOrAdmin: isUserCompanyOwner || isUserCompanyAdmin,
    isSimpleCompanyMember: !isUserCompanyAdmin && !isUserCompanyOwner,
  };
}
