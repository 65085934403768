const DATE_RANGES = [
  {
    value: "oneMonth",
    additionalValue: "oneMonthAgo",
    label: "1 month",
  },
  {
    value: "twoMonths",
    additionalValue: "twoMonthsAgo",
    label: "2 months",
  },
  {
    value: "threeMonths",
    additionalValue: "threeMonthsAgo",
    label: "3 months",
  },
  {
    value: "sixMonths",
    additionalValue: "sixMonthsAgo",
    label: "6 months",
  },
  {
    value: "oneYear",
    additionalValue: "oneYearAgo",
    label: "1 year",
  },
] as const;

export const ALL_TIME_RANGE = {
  value: "allTime",
  additionalValue: "allTime",
  label: "All time",
} as const;

export const INITIAL_DATE_RANGE: Exclude<DATE_RANGES_TYPE, undefined> =
  "oneYear";

export const DATE_RANGE_LABELS: Record<DateRange, string> = {
  oneMonth: "drl_oneMonth",
  twoMonths: "drl_twoMonths",
  threeMonths: "drl_threeMonths",
  sixMonths: "drl_sixMonths",
  oneYear: "drl_oneYear",
};

export const TIME_PERIOD_TYPES: Option<TopKeywords.TimeRangeType>[] = [
  { value: "yearly", label: "drl_oneYear" },
  { value: "monthly", label: "drl_oneMonth" },
];

export type DATE_RANGES_TYPE = typeof DATE_RANGES[number]["value"] | undefined;

export default DATE_RANGES;
