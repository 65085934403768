import { useMemo } from "react";
import styles from "./EsgSentimentPieChart.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import esgParameters from "../../../../../data/esgParameters";
import useResizeObserver from "use-resize-observer";
import { GREEN, RED, ORANGE } from "../../../../../data/colors";

interface Props {
  data: ValueOf<EsgSentiment.PieChart.Data>;
}

export const EsgSentimentPieChart = ({ data }: Props) => {
  const modifiedData = useMemo(
    () =>
      esgParameters.map(({ name, description }) => ({
        label: name,
        description,
        data: [
          {
            value: Number(data?.values[name]?.positive.toFixed()) || 0,
            color: GREEN,
          },
          {
            value: Number(data?.values[name]?.negative.toFixed()) || 0,
            color: RED,
          },
          {
            value: Number(data?.values[name]?.neutral.toFixed()) || 0,
            color: ORANGE,
          },
        ],
      })),
    [data],
  );

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const chartsStyle = useMemo(() => {
    if ((width - 48) / (height - 24) >= 4) {
      const x = height - 24;

      return {
        height: height,
        width: x * 4 + 48,
        gridTemplate: `
          "pie0 pie1 pie2 pie3" auto / 1fr 1fr 1fr 1fr
        `,
      };
    }

    if ((width - 32) / (height - 64) >= 3 / 2) {
      const x = (height - 64) / 2;

      return {
        height: height,
        width: x * 3 + 32,
        gridTemplate: `
          "blank1 pie0 blank2" 1fr
          "pie1 pie2 pie3" 1fr / 1fr 1fr 1fr
        `,
      };
    }

    if ((height - 144) / width >= 4) {
      const x = width;

      return {
        height: x * 4 + 144,
        width: x,
        gridTemplate: `
          "pie0" 1fr
          "pie1" 1fr
          "pie2" 1fr
          "pie3" 1fr / auto
        `,
      };
    }

    if ((height - 104) / (width - 16) >= 3 / 2) {
      const x = (width - 16) / 2;

      return {
        height: x * 3 + 104,
        width: width,
        gridTemplate: `
          "blank1 pie1" 1fr
          "pie0 pie2" 1fr
          "blank2 pie3" 1fr / 1fr 1fr
        `,
      };
    }

    let x;

    if (width - 16 > height - 64) {
      x = (height - 64) / 2;
    } else {
      x = (width - 16) / 2;
    }

    return {
      height: x * 2 + 64,
      width: x * 2 + 16,
      gridTemplate: `
          "pie0 pie1" 1fr
          "pie2 pie3" 1fr / 1fr 1fr
        `,
    };
  }, [width, height]);

  return (
    <div ref={ref} className={styles.esgSentimentPieChart}>
      <div className={styles.charts} style={chartsStyle}>
        {modifiedData.map(({ label, description, data }, i) => (
          <div
            className={styles.chartArea}
            style={{ gridArea: "pie" + i }}
            key={i}
          >
            <div className={styles.chartWrapperOuter}>
              <div className={styles.chartWrapperInner}>
                <PieChart
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  data={data}
                  label={(props) => {
                    const value = props.dataEntry.value;
                    return value ? value + "%" : "";
                  }}
                  labelStyle={{
                    fill: "#efe9ea",
                    fontSize: 8,
                    fontWeight: 500,
                  }}
                  startAngle={-90}
                />
              </div>
              <div className={styles.label} title={description}>
                {label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
