const locales = {
  sek: "sv-se",
  gbp: "en-gb",
  usd: "en-US",
} as const;

export function formatToCurrency(
  number: number,
  currency: keyof typeof locales,
) {
  return new Intl.NumberFormat(locales[currency], {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(number);
}
