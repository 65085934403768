import { useCallback, useEffect } from "react";

export const useOutsideClickHandler = (
  ref: any,
  cb: (e: MouseEvent) => void,
): void => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        cb(e);
      }
    },
    [ref, cb],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
};
