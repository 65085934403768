type DataPoint<T> = Record<keyof T, number>;

// Trend Line Formula Examples: https://study.com/learn/lesson/trend-line-formula-examples.html
// A trend line is a line that is drawn above, below or through various data
// points in order to show their general direction.
export const calculateTrendLine = <T extends DataPoint<T>, K extends keyof T>(
  data: T[],
  xKey: K,
  yKey: K,
) => {
  const [xValues, yValues] = [
    data.map<number>((value) => value[xKey]),
    data.map<number>((value) => value[yKey]),
  ];

  const trendLineFunc = calculateTrendLineFunc(data, xValues, yValues);

  const trendLineData: T[] = [];

  for (const xValue of new Set(xValues)) {
    const yValue = trendLineFunc(xValue);

    const value = {
      [xKey]: xValue,
      [yKey]: yValue,
    };

    trendLineData.push(value as T);
  }

  return trendLineData;
};

// The trend line may be drawn from the upper left corner to the lower right
// corner, indicating that the data have a negative slope, or from the lower
// left corner to the upper right corner, indicating that the data have a positive
// slope.
// The y-intercept of the trend line is the point at which the trend line has
// an x value of zero.
function calculateTrendLineFunc<T extends DataPoint<T>>(
  data: T[],
  xValues: number[],
  yValues: number[],
): (x: number) => number {
  const [xAverage, yAverage] = [getAverage(xValues), getAverage(yValues)];

  // [Array<(x - xAverage)>, Array<(y - yAverage)>]
  const [xSubXAverageValues, ySubYAverageValues] = [
    xValues.map<number>((value) => value - xAverage),
    yValues.map<number>((value) => value - yAverage),
  ];

  // Array<(x - xAverage)^2>
  const xSubXAverageExp2Values = xSubXAverageValues.map((val) =>
    Math.pow(val, 2),
  );

  // Array<(x - xAverage) * (y - yAverage)>
  const xSubXAverageMulYSubYAverageValues = [];

  for (let count = 0; count < data.length; count++) {
    const [xSubXAverage = 0, ySubYAverage = 0] = [
      xSubXAverageValues[count],
      ySubYAverageValues[count],
    ];

    xSubXAverageMulYSubYAverageValues.push(xSubXAverage * ySubYAverage);
  }

  const slope = calculateTrendLineSlope(
    xSubXAverageMulYSubYAverageValues,
    xSubXAverageExp2Values,
  );

  const yIntercept = yAverage - slope * xAverage;

  return (x: number): number => yIntercept + slope * x;
}

function calculateTrendLineSlope(a: number[], b: number[]): number {
  const [sumA, sumB] = [getSum(a), getSum(b)];

  if (!sumB) return 0;

  return sumA / sumB;
}

function getAverage(array: number[]): number {
  if (!array.length) return 0;

  const total = array.reduce<number>((acc, c) => acc + c, 0);

  return total / array.length;
}

function getSum(arr: number[]): number {
  return arr.reduce<number>((acc, c) => acc + c, 0);
}
