import { memo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Message.module.scss";

type Props = {
  isDataChanged: boolean;
  isFilterTypeChanged: boolean;
  isExcludedWordsNotIncludeInWidgetReturnedBack: boolean;
};

export const Message = memo(
  ({
    isDataChanged,
    isFilterTypeChanged,
    isExcludedWordsNotIncludeInWidgetReturnedBack,
  }: Props) => {
    const { t } = useTranslation();

    if (isDataChanged && !isExcludedWordsNotIncludeInWidgetReturnedBack) {
      return <p className={styles.message}>{t("rd_data_changed_message")}</p>;
    }
    if (isFilterTypeChanged) {
      return <p className={styles.message}>{t("rd_type_of_filter_changed")}</p>;
    }

    if (isExcludedWordsNotIncludeInWidgetReturnedBack) {
      return (
        <p className={styles.message}>
          {t("rd_excluded_word_returned_message")}
        </p>
      );
    }
    return null;
  },
);
