import React, { useContext } from "react";
import cx from "classnames";

import styles from "./SidebarToggler.module.scss";
import context from "../../context";

const SidebarToggler = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(context);

  return (
    <button
      className={cx(styles.menu, styles[isSidebarOpen ? "menuOpen" : ""])}
      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
    >
      <span className={styles.line} />
    </button>
  );
};

export default SidebarToggler;
