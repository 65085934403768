import { ComponentProps, FC } from "react";
import PreloadImage from "react-preload-image";
import images from "../Image/images";

type PreloadImageProps = Omit<ComponentProps<typeof PreloadImage>, "src">;

interface BaseProps {
  name: keyof typeof images;
}

export const FadeInImage: FC<PreloadImageProps & BaseProps> = ({
  name,
  style,
  innerStyle,
  ...props
}) => {
  return (
    <PreloadImage
      style={{ position: "relative", ...style }}
      innerStyle={{ backgroundSize: "contain", ...innerStyle }}
      src={images[name]}
      {...props}
    />
  );
};
