import { FC } from "react";
import cx from "classnames";

import styles from "./AdditionalButton.module.scss";
import * as icons from "../../../../icons";

type Props = ActionBar.AdditionalButton & { className?: string };

export const AdditionalButton: FC<Props> = ({
  className = "",
  title,
  icon,
  onClickHandler,
}) => {
  const Icon = icon && icons[icon];

  return (
    <button
      className={cx(styles.additionalButton, className)}
      onClick={onClickHandler}
    >
      {Icon && <Icon />}
      {title && <span>{title}</span>}
    </button>
  );
};
