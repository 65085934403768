import { useMemo } from "react";
import subMonths from "date-fns/subMonths";
import isWithinInterval from "date-fns/isWithinInterval";
import { useTranslation } from "react-i18next";

import classes from "./BusinessDriversLineChart.module.scss";
import { LineChart } from "src/components";
import businessDriversParameters from "src/data/businessDriversParameters";
import DATE_RANGES, { ALL_TIME_RANGE } from "src/data/dateRanges";
import { LineChartProps } from "src/components/widgets/LineChart/types";

// Inner imports
import {
  LINE_STROKE_WIDTH,
  Y_AXIS_VERTICAL_TOP_PADDING,
  Y_AXIS_VERTICAL_BOTTOM_PADDING,
} from "./constants";
import {
  BUSINESS_DRIVERS_DATE_RANGERS_TYPE,
  BusinessDriversDateRange,
  BusinessDriversParameter,
} from "../../types";
import { BUSINESS_DRIVERS_DATE_RANGES } from "../../constants";

interface Props {
  data: BusinessDrivers.LineChart.Data;
  searchId: string;
  dashboardId?: string;
  dateRange?: BUSINESS_DRIVERS_DATE_RANGERS_TYPE;
  viewMode?: LineChartProps["viewMode"];
  timePlotterId?: string;
}

export const BusinessDriversLineChart = ({
  data,
  searchId,
  dateRange,
  dashboardId,
  viewMode = "widgetPage",
  timePlotterId,
}: Props) => {
  const { t } = useTranslation();

  const modifiedData = useMemo(() => {
    const sortedData = [...data].sort(sortData);

    const modifiedData: (typeof DATE_RANGES[number] & {
      rangeData: typeof data;
    })[] = [];

    const allData = {
      ...ALL_TIME_RANGE,
      rangeData: data,
    };

    let endDate;

    const endDateString = sortedData[sortedData.length - 1]?.date;
    if (endDateString) {
      endDate = new Date(endDateString);
    } else {
      return modifiedData;
    }

    const dateRanges: Record<BusinessDriversDateRange, Date> = {
      oneMonth: subMonths(endDate, 1),
      threeMonths: subMonths(endDate, 3),
      sixMonths: subMonths(endDate, 6),
      oneYear: subMonths(endDate, 12),
    };

    for (let i = 0; i < BUSINESS_DRIVERS_DATE_RANGES.length; i++) {
      const rangeData: typeof data = [];

      for (let k = sortedData.length - 1; k >= 0; k--) {
        const isDateInRange = isWithinInterval(new Date(sortedData[k]!.date), {
          start: dateRanges[BUSINESS_DRIVERS_DATE_RANGES[i]!.value],
          end: endDate,
        });

        if (isDateInRange) {
          rangeData.push(sortedData[k]!);
        } else {
          break;
        }
      }

      rangeData.sort(sortData);

      if (
        (i === 0 && rangeData.length) ||
        rangeData.length > modifiedData[i - 1]!.rangeData.length
      ) {
        modifiedData.push({
          ...BUSINESS_DRIVERS_DATE_RANGES[i]!,
          rangeData,
        });
      } else {
        break;
      }
    }

    return [...modifiedData, allData];

    function sortData<T extends typeof data[number]>(a: T, b: T) {
      return a.date.localeCompare(b.date);
    }
  }, [data]);

  const currentDateRangeIndex = useMemo(() => {
    return [...BUSINESS_DRIVERS_DATE_RANGES, ALL_TIME_RANGE].findIndex(
      ({ value }) => value === dateRange,
    );
  }, [dateRange]);

  const formattedBusinessDriversParameters = useMemo<
    BusinessDriversParameter[]
  >(
    () =>
      businessDriversParameters.map((param) => ({
        ...param,
        name: t(param.name),
        description: t(param.description),
      })),
    [t],
  );

  return (
    <LineChart
      className={classes.lineChart}
      labels={formattedBusinessDriversParameters}
      data={modifiedData[currentDateRangeIndex]?.rangeData}
      dashboardId={dashboardId}
      searchId={searchId}
      timePlotterId={timePlotterId}
      viewMode={viewMode}
      chartStyles={{
        lineStrokeWidth: LINE_STROKE_WIDTH,
      }}
      axis={{
        yAxisVerticalPadding: {
          top: Y_AXIS_VERTICAL_TOP_PADDING,
          bottom: Y_AXIS_VERTICAL_BOTTOM_PADDING,
        },
      }}
    />
  );
};
