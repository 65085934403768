import { FC, useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./FunnelPreview.module.scss";
import {
  Preloader,
  ChartButton,
  HorizontalFunnelChart,
  VerticalFunnelChart,
  ErrorPlaceholder,
  BrandSelector,
} from "../../../components";

// Inner imports
import { WidgetChart } from "../../types";

import { getFunnelDataBrands, onErrorHandler } from "../../helpers";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const FunnelPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const [brandIndex, setBrandIndex] = useState<number>(0);

  const brands: string[] = useMemo(() => getFunnelDataBrands(widgetData), [
    widgetData,
  ]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } =
      widgetData || {};

    const brandValues =
      (oneYear || sixMonths || threeMonths || twoMonths || oneMonth)
        ?.brandValues ?? [];

    if (brandValues.length > 1) {
      charts.push({
        type: "funnel",
        subType: "vertical",
        chart: (
          <div className={styles.charts}>
            {brandValues.map(({ brandName, values }, i) => (
              <VerticalFunnelChart
                className={styles.chart}
                title={brandName}
                data={values}
                key={i}
                widgetId={"funnel"}
              />
            ))}
          </div>
        ),
      });
    }

    if (brandValues.length) {
      charts.push({
        type: "funnel",
        subType: "horizontal",
        chart: (
          <HorizontalFunnelChart
            className={styles.chart}
            data={brandValues[brandIndex]?.values}
            widgetId={"funnel"}
          />
        ),
      });
    }

    return charts;
  }, [widgetData, brandIndex]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isHorizontalFunnelOpen =
    viewIndex ===
    views.findIndex(
      ({ type, subType }) => type === "funnel" && subType === "horizontal",
    );

  const isBrandsSelectorShown = brands.length > 1 && isHorizontalFunnelOpen;

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {isBrandsSelectorShown && (
              <BrandSelector
                className={styles.brandSelector}
                brands={brands}
                currentBrandIndex={brandIndex}
                onBrandClicked={setBrandIndex}
                isEventPropagationStopped
              />
            )}
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
