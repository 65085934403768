import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./VerticalFunnelChart.module.scss";

// Inner imports
import type { VerticalFunnelChartProps } from "../types";
import { funnelChartsColors } from "../constants";
import {
  getLabel,
  calcShapesSize,
  calcShapeBorderSize,
  formatFunnelNumbers,
} from "../utils";

export const VerticalFunnelChart: FC<VerticalFunnelChartProps> = ({
  className = "",
  style,
  data: initialData = [],
  graphHeight,
  widgetId,
  title,
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => initialData.slice(0, 4), [initialData]);

  const {
    ref: rowsRef,
    width: rowWidth = 0,
    height: rowsHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const rowHeight = rowsHeight / data.length;

  const shapeBorderHeight = formatFunnelNumbers(rowHeight / 2);

  const shapesWidth = useMemo(() => calcShapesSize(data), [data]);

  const isDataValid = data.length > 1;

  // HELPERS
  function calcShapeWidth(value: number, prevValue?: number): number {
    if (prevValue && Number.isFinite(prevValue)) {
      // Seems it's last element of array (PURCHASE INTENT);
      if (prevValue > 0) return prevValue;
    }
    return value;
  }

  function getPercentageValue(index: number, initialValue: number) {
    const shapesSizeForWidget = widgetId === "funnel" && shapesWidth[index - 1];
    const value =
      typeof shapesSizeForWidget === "number"
        ? shapesSizeForWidget
        : initialValue;

    return formatFunnelNumbers(value);
  }

  return isDataValid ? (
    <div className={cx(styles.verticalFunnelChart, className)} style={style}>
      <div className={styles.title}>
        <div title={title}>{title}</div>
        {rowWidth > 128 && <div />}
      </div>
      <div
        ref={rowsRef}
        className={styles.rows}
        style={{ height: graphHeight }}
      >
        {data.map(({ label, value }, i) => (
          <div className={styles.rowWrapper} key={i}>
            <div className={styles.row}>
              <div className={styles.shapeWrapper}>
                {i < data.length - 1 ? (
                  <>
                    <div
                      style={{
                        borderLeft: `${calcShapeBorderSize({
                          value: value.percentage,
                          shapeHeight: shapesWidth[i]!,
                          blockSize: rowWidth,
                          prevValue: shapesWidth[i - 1],
                        })}px solid transparent`,
                        borderRight: `${calcShapeBorderSize({
                          value: value.percentage,
                          shapeHeight: shapesWidth[i]!,
                          blockSize: rowWidth,
                          prevValue: shapesWidth[i - 1],
                        })}px solid ${funnelChartsColors[i]}`,
                        borderTop: `${shapeBorderHeight}px solid ${funnelChartsColors[i]}`,
                        borderBottom: `${shapeBorderHeight}px solid transparent`,
                      }}
                    />
                    <div
                      style={{
                        width: `${calcShapeWidth(shapesWidth[i]!)}%`,
                        backgroundColor: funnelChartsColors[i],
                      }}
                    />
                    <div
                      style={{
                        borderLeft: `${calcShapeBorderSize({
                          value: value.percentage,
                          shapeHeight: shapesWidth[i]!,
                          blockSize: rowWidth,
                          prevValue: shapesWidth[i - 1],
                        })}px solid ${funnelChartsColors[i]}`,
                        borderRight: `${calcShapeBorderSize({
                          value: value.percentage,
                          shapeHeight: shapesWidth[i]!,
                          blockSize: rowWidth,
                          prevValue: shapesWidth[i - 1],
                        })}px solid transparent`,
                        borderTop: `${shapeBorderHeight}px solid ${funnelChartsColors[i]}`,
                        borderBottom: `${shapeBorderHeight}px solid transparent`,
                      }}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      width: `${calcShapeWidth(
                        shapesWidth[i]!,
                        shapesWidth[i - 1],
                      )}%`,
                      backgroundColor: funnelChartsColors[i],
                    }}
                  />
                )}
              </div>
              <div
                className={styles.labelWrapper}
                title={`${getPercentageValue(i, value.percentage)}% ${getLabel(
                  label,
                  t,
                ).toUpperCase()}`}
              >
                <div style={{ backgroundColor: funnelChartsColors[i] }}>
                  {getPercentageValue(i, value.percentage)}%
                </div>
                {rowWidth > 128 && <div>{getLabel(label, t)}</div>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
