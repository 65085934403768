import { FC, SVGProps, ReactElement } from "react";
import cx from "classnames";

import styles from "./BlackButton.module.scss";

type Props = {
  additionalClassName?: string;
  text: string;
  onClickHandler: () => void;
  icon?: FC<SVGProps<SVGSVGElement>>;
};

export const BlackButton: FC<Props> = ({
  additionalClassName,
  text,
  onClickHandler,
  icon,
}): ReactElement => (
  <button
    className={cx(styles.timePlotterButton, additionalClassName ?? "")}
    onClick={onClickHandler}
  >
    {!icon && <div className={styles.timePlotterIcon}>{icon}</div>}
    {text}
  </button>
);
