import { createDraftSafeSelector } from "@reduxjs/toolkit";

import {
  GetUserStatusResult,
  selectUser,
  selectUserStatus,
} from "../user/selectors";
import { selectCompany } from "../company/selectors";
import { selectCompanySubscriptionPlan } from "../subscriptionPlans/selectors";
import { Dashboard } from "./dashboardsSlice";
import { selectSearches } from "../searches/selectors";

export const selectDashboards = createDraftSafeSelector(
  ({ dashboards }: RootState) => dashboards,
  (dashboards) => dashboards,
);

export const selectDashboardsEntities = createDraftSafeSelector(
  selectDashboards,
  (dashboards) => dashboards.entities,
);

export const selectDashboardsStatus = createDraftSafeSelector(
  selectDashboards,
  (dashboards) => dashboards.status,
);

export const selectAvailableDashboards = createDraftSafeSelector(
  selectUser,
  selectCompany,
  selectDashboards,
  (user, { members }, dashboards) => {
    const suitableDashboardsIds: string[] = [];
    const suitableDashboards: { [dashboardId: string]: Dashboard } = {};

    for (const dashboardId in dashboards.entities) {
      const dashboard = dashboards.entities[dashboardId];

      if (!dashboard) continue;

      const isUserDashboardOwner = dashboard.userId === user.id;

      if (dashboard.isPrivate && !isUserDashboardOwner) continue;

      const userFullName = members.find(({ id }) => id === dashboard.userId)
        ?.fullName;

      suitableDashboards[dashboardId] = {
        ...dashboard,
        isOwnedByUser: isUserDashboardOwner,
        userFullName,
      };
    }

    return {
      ...dashboards,
      ids: suitableDashboardsIds,
      entities: suitableDashboards,
    };
  },
);

export const selectDashboardsQuantity = createDraftSafeSelector(
  selectCompany,
  selectDashboardsEntities,
  (company, dashboards) => {
    const suitableDashboardIds: string[] = [];

    for (const dashboardId in dashboards) {
      const dashboard = dashboards[dashboardId];

      if (!dashboard || dashboard.companyId !== company.id) continue;

      suitableDashboardIds.push(dashboardId);
    }

    return suitableDashboardIds.length;
  },
);

export const selectDashboardIdsWhichUserCanModify = createDraftSafeSelector(
  selectUser,
  selectUserStatus,
  selectDashboardsEntities,
  (user, { isUserCompanyOwnerOrAdmin }, dashboards) => {
    const dashboardsIds: string[] = [];

    for (const dashboardId in dashboards) {
      const dashboard = dashboards[dashboardId];

      if (!dashboard) continue;

      const isUserDashboardOwner = dashboard.userId === user.id;

      if (!isUserCompanyOwnerOrAdmin && !isUserDashboardOwner) continue;

      dashboardsIds.push(dashboardId);
    }

    return dashboardsIds;
  },
);

export const selectDashboardsLimits = createDraftSafeSelector(
  selectCompany,
  selectCompanySubscriptionPlan,
  selectDashboardsQuantity,
  (company, currentSubPlan, dashboardsCount) => {
    const { customDashboardLimit } = company;
    const { dashboardsLimit: subPlanDashboardsLimit } = currentSubPlan || {};

    const dashboardsLimit = customDashboardLimit ?? subPlanDashboardsLimit ?? 1;

    const isDashboardsLimitReached = dashboardsCount >= dashboardsLimit;

    return {
      dashboardsLimit,
      isDashboardsLimitReached,
    };
  },
);

export const selectDashboardById = (dashboardId: string) =>
  createDraftSafeSelector(
    selectDashboardsEntities,
    (dashboards) => dashboards[dashboardId],
  );

export const selectAvailableDashboardById = (dashboardId: string) =>
  createDraftSafeSelector(
    selectAvailableDashboards,
    (dashboards) => dashboards.entities[dashboardId],
  );

export const selectDashboardsByUserId = (userId: string) =>
  createDraftSafeSelector(selectDashboardsEntities, (dashboards) => {
    const userDashboards: Dashboard[] = [];

    for (const dashboardId in dashboards) {
      const dashboard = dashboards[dashboardId];

      if (!dashboard) continue;

      const isUserDashboardOwner = dashboard.userId === userId;

      if (!isUserDashboardOwner) continue;

      userDashboards.push(dashboard);
    }

    return userDashboards;
  });

export const selectDashboardViewSettings = (dashboardId: string) =>
  createDraftSafeSelector(
    selectDashboardById(dashboardId),
    (dashboard) => dashboard?.viewSettings,
  );

export const selectDashboardWidgetViewSettings = ({
  dashboardId,
  searchId,
  widgetId,
}: {
  dashboardId: string;
  searchId: string;
  widgetId: WIDGET_IDS_TYPES;
}) =>
  createDraftSafeSelector(
    selectDashboardViewSettings(dashboardId),
    (viewSettings) => viewSettings?.[searchId]?.[widgetId],
  );

export const selectUserDashboardPermissions = (dashboardId: string) =>
  createDraftSafeSelector(
    selectUser,
    selectUserStatus,
    selectDashboardById(dashboardId),
    getUserDashboardPermissions,
  );

export const selectDashboardSearchIds = (dashboardId: string) =>
  createDraftSafeSelector(
    selectDashboardById(dashboardId),
    selectSearches,
    (dashboard, searches) => getDashboardSearchIds(dashboard?.tiles, searches),
  );

export const selectDashboardSearches = (dashboardId: string) =>
  createDraftSafeSelector(
    selectDashboardSearchIds(dashboardId),
    selectSearches,
    (searchIds, searches) => {
      const _searches: Store.Search[] = [];

      for (const searchId of searchIds) {
        const search = searches.find(({ id }) => id === searchId);

        if (search) _searches.push(search);
      }

      return _searches;
    },
  );

export function getUserDashboardPermissions(
  user: Store.User,
  { isUserCompanyOwnerOrAdmin }: Partial<GetUserStatusResult>,
  dashboard?: Dashboard,
) {
  if (!dashboard) return {};

  const canUserEditDashboard =
    isUserCompanyOwnerOrAdmin || dashboard.userId === user.id;

  return {
    canUserEditDashboard,
  };
}

export function getDashboardSearchIds(
  dashboardTiles: Dashboard["tiles"] = {},
  searches: Store.Search[] = [],
) {
  if (!dashboardTiles) return [];

  const searchIds = new Set<string>();

  for (const searchId in dashboardTiles) {
    const widgets = dashboardTiles[searchId] || {};

    const isSearchExist = searches.some(({ id }) => id === searchId);
    const isWidgetExist = Object.keys(widgets).length;

    if (isWidgetExist && isSearchExist) searchIds.add(searchId);
  }

  return Array.from(searchIds);
}
