import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./Awareness.module.scss";
import {
  Preloader,
  AwarenessPieChart,
  LineChart,
  ErrorPlaceholder,
  AboutThis,
  WidgetButtonsBar,
  WidgetFooterButtons,
} from "../../../components";
import DATE_RANGES from "../../../data/dateRanges";
import { useNameForDownloadFiles } from "../../../hooks";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";

// Inner imports
import { WIDGET_ID } from "./constants";

type DATE_RANGES_TYPE = typeof DATE_RANGES[number]["value"] | undefined;

export const Awareness: FC<WidgetsView.Page> = ({ searchId, dashboardId }) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  // Date Range ---->
  const dateRanges = useMemo(() => {
    const formattedDateRanges = DATE_RANGES.map(({ value, label }) => ({
      value,
      label,
    }));
    return formattedDateRanges.filter(
      ({ value }) => widgetData?.pieChart[value],
    );
  }, [widgetData]);

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) {
      const initialDateRange = dateRanges[0]?.value;
      setCurrentDateRange(initialDateRange);
    }
  }, [dateRanges]);
  // <---- Date Range

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};

    return {
      lineChartData: lineChart,
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(pieChartData).length) {
      charts.push({
        type: "pieChart",
        chart: (
          <AwarenessPieChart
            data={pieChartData[currentDateRange!]?.values}
            type="page"
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            data={lineChartData}
            labels={brands}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              graphHeight: "100%",
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_awareness_linechart_legend"),
              yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
              increaseYAxisWidthInPercentage: 10,
            }}
          />
        ),
      });
    }

    return charts;
  }, [
    pieChartData,
    lineChartData,
    currentDateRange,
    brands,
    dashboardId,
    searchId,
    t,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const firstBrand = widgetData?.brands[0];

  const descriptionInsertion = widgetData?.pieChart[
    currentDateRange!
  ]?.values.find(({ brandName }) => brandName === firstBrand!.name)?.value;

  const conclusion = useMemo(() => {
    if (!descriptionInsertion) return "";

    return t("w_awareness_main_tip", {
      mainBrandName: firstBrand!.name,
      descriptionInsertion,
    });
  }, [descriptionInsertion, firstBrand, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "lineChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
