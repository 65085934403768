import { Switch, Route, Redirect } from "react-router-dom";

import { ROUTS } from "../../data/routs";

// Inner imports
import styles from "./Auth.module.scss";
import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import PasswordReset from "./PasswordReset/PasswordReset";

const Auth = () => {
  return (
    <div className={styles.auth}>
      <Switch>
        <Route exact path={ROUTS.login}>
          <Login />
        </Route>
        <Route exact path={ROUTS.signUp}>
          <Signup />
        </Route>
        <Route exact path={ROUTS.passwordReset}>
          <PasswordReset />
        </Route>
        <Redirect to={ROUTS.login} />
      </Switch>
    </div>
  );
};

export default Auth;
