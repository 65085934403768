const CHART_COLORS = {
  POSITIVE: "#62AF6E",
  NEUTRAL: "#CECE84",
  NEGATIVE: "#EF336A",
  DEFAULT: "grey",
} as const;

const ICON_WIDTH: number = 22 as const;

const NEEDED_ADDITIONAL_SPACE_FOR_POPPER: number = 130 as const;

export { CHART_COLORS, ICON_WIDTH, NEEDED_ADDITIONAL_SPACE_FOR_POPPER };
