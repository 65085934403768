const WIDGET_ID = "result-position-vs-competition";

const DATE_RANGES = [
  "oneMonth",
  "twoMonths",
  "threeMonths",
  "sixMonths",
  "oneYear",
] as const;

export { WIDGET_ID, DATE_RANGES };
