import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ConfirmModalWrapper } from "../..";
import context from "../../../context";

type Props = {
  path: string;
  hash: string;
  onOkHandleClick?: Function;
  onCancelHandleClick?: Function;
  state?: Object | unknown;
};

const ConfirmRedirectionModal: FC<Props> = ({
  path,
  hash,
  onOkHandleClick,
  onCancelHandleClick,
  state = {},
}) => {
  const history = useHistory();

  const { setModalElement } = useContext(context);
  const { t } = useTranslation();

  const handleOK = useCallback(
    (currentPath: string) => {
      history.block(() => {});
      setModalElement(null);
      onOkHandleClick && onOkHandleClick();

      history.push(`${currentPath}${hash}`, state);
    },
    [history, hash, onOkHandleClick, state, setModalElement],
  );

  const handleCancel = useCallback(() => {
    onCancelHandleClick && onCancelHandleClick();
    setModalElement(null);
  }, [setModalElement, onCancelHandleClick]);

  return (
    <ConfirmModalWrapper
      type="error"
      acceptButton={{
        text: t("rmw_ok_btn"),
        onClicked: () => handleOK(path),
      }}
      cancelButton={{
        text: t("rmw_cancel_btn"),
        onClicked: () => handleCancel(),
      }}
      title={t("rmw_title")}
    >
      <p>{t("rmw_text")}</p>
    </ConfirmModalWrapper>
  );
};

export { ConfirmRedirectionModal };
