const PREVIEW_LINE_CHART = {
  VERTICAL_PADDING: { top: 10, bottom: 0 },
  STROKE_WIDTH: 3,
  Y_AXIS_UNIT: "%",
} as const;

const VIEW_LINE_CHART = {
  VERTICAL_PADDING: { top: 20, bottom: 0 },
  STROKE_WIDTH: 4,
  Y_AXIS_UNIT: "%",
  GRAPH_HEIGHT: "100%",
} as const;

const WIDGET_ID = "result-media-share";

export { WIDGET_ID, PREVIEW_LINE_CHART, VIEW_LINE_CHART };
