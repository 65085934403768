import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ConfirmModalWrapper.module.scss";
import context from "../../../context";
import * as icons from "../../../icons";
import { getMessageIcon } from "../../../utils/getMessageIcon";

// Inner imports
import { ConfirmModalWrapperProps } from "./types";

export const ConfirmModalWrapper: FC<ConfirmModalWrapperProps> = ({
  className = "",
  type,
  title,
  cancelButton,
  acceptButton,
  extraButtons,
  children,
}) => {
  const { t } = useTranslation();

  const { isDarkTheme } = useContext(context);

  const modalIcon: JSX.Element | null = useMemo(
    () => getMessageIcon(type, isDarkTheme),
    [isDarkTheme, type],
  );

  const hasModalButtons: boolean = useMemo(
    () => !!(cancelButton || acceptButton || extraButtons?.length),
    [acceptButton, cancelButton, extraButtons],
  );

  const acceptButtonColor: string = useMemo(() => {
    if (type === "error") return "#FF4451";

    return acceptButton?.color ?? "";
  }, [acceptButton?.color, type]);

  return (
    <div className={cx(styles.basicModal, className)}>
      <div className={styles.contentWrapper}>
        {title && (
          <div className={styles.titleWrapper}>
            {modalIcon}
            <div className={styles.title} title={title}>
              {title}
            </div>
          </div>
        )}
        <div className={styles.message}>{children}</div>
      </div>
      {hasModalButtons && (
        <div className={styles.buttonsWrapper}>
          {!!extraButtons?.length && (
            <div className={styles.extraButtonsWrapper}>
              {extraButtons.map((button, index) => {
                const Icon = button.icon && icons[button.icon];

                return (
                  <button
                    type="button"
                    key={`${button.text}-${index}`}
                    className={cx(styles.extraButton, button.className)}
                    onClick={button.onClicked}
                    style={{ backgroundColor: button.color ?? "" }}
                    disabled={button.disabled}
                  >
                    {!!Icon && <Icon />}
                    {button.text ?? ""}
                  </button>
                );
              })}
            </div>
          )}
          {cancelButton && (
            <button
              type="button"
              className={styles.cancelButton}
              onClick={cancelButton.onClicked}
              style={{ backgroundColor: cancelButton.color ?? "" }}
              disabled={cancelButton.disabled}
            >
              {cancelButton.text ?? t("tp_modal_wrapper_cancel_button")}
            </button>
          )}
          {acceptButton && (
            <button
              type="button"
              className={cx(styles.acceptButton, acceptButton.className)}
              onClick={acceptButton.onClicked}
              style={{ backgroundColor: acceptButtonColor }}
              disabled={acceptButton.disabled}
            >
              {acceptButton.text ?? t("tp_modal_wrapper_accept_button")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
