import { FC, useMemo } from "react";
import Table from "rsuite/Table";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SearchLinkCell.module.scss";
import { Magnifier, ImageSearch } from "src/icons";
import { withTooltip } from "src/hocs";
import { sendDataToGTM } from "src/utils";

// Inner imports
import type {
  SearchLinkProps,
  SearchLinkCellProps,
  LinkType,
} from "../../types";
import { GOOGLE_SEARCH_URL } from "../../constants";

const { Cell } = Table;

const LinkWithTooltip = withTooltip(Link);

const SearchLink: FC<SearchLinkProps> = ({ link, isImageSearch }) => {
  const { t } = useTranslation();

  const { keyword, url } = useMemo<LinkType>(
    () => link || { keyword: "", url: "" },
    [link],
  );

  const tooltip = useMemo<string>(
    () =>
      isImageSearch
        ? t("dc_keywords_table_keywords_image_link_cell_tooltip", {
            keyword,
          })
        : t("dc_keywords_table_keywords_link_cell_tooltip", {
            keyword,
          }),
    [isImageSearch, keyword, t],
  );

  const onLinkClick = (): void =>
    sendDataToGTM("UserRedirectsToKeywordGoogleSearch", {
      keywordGoogleSearchType: isImageSearch ? "imageSearch" : "defaultSearch",
    });

  if (!keyword) return null;

  return (
    <LinkWithTooltip
      to={{ pathname: url }}
      target="_blank"
      tooltip={tooltip}
      isTooltipActive
      tooltipPosition="left"
      tooltipClassName={styles.tooltip}
      onClick={onLinkClick}
    >
      {isImageSearch ? <ImageSearch /> : <Magnifier />}
    </LinkWithTooltip>
  );
};

export const SearchLinkCell: FC<SearchLinkCellProps> = ({
  rowData,
  dataKey,
  isImageSearch = false,
  ...props
}) => {
  const searchLink = useMemo<LinkType | undefined>(() => {
    let rawKeyword = rowData?.value;

    if (!rawKeyword || typeof rawKeyword !== "string") return;

    const keyword = rawKeyword.replace("_parent", "");

    const url = `${GOOGLE_SEARCH_URL}?q=${keyword}${
      isImageSearch ? "&tbm=isch" : ""
    }`;

    return { url, keyword };
  }, [isImageSearch, rowData?.value]);

  return (
    <Cell {...props} dataKey={dataKey} align="center">
      <SearchLink link={searchLink} isImageSearch={isImageSearch} />
    </Cell>
  );
};
