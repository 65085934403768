import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./BusinessDriverPositioning.module.scss";
import {
  ErrorPlaceholder,
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { QuadrantChart } from "./components/QuadrantChart/QuadrantChart";
import { getPositionVsCompetitionDescriptionInsertion } from "../../../utils";
import DATE_RANGE_LABELS from "../../../data/dateRangeLabels";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { WidgetChart } from "../../types";
import { WIDGET_ID, DATE_RANGES } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const BusinessDriverPositioning: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  // Date Range ---->
  const dateRanges = useMemo(
    () => DATE_RANGES.filter((dateRange) => widgetData?.[dateRange]),
    [widgetData],
  );

  const [currentDateRange, setCurrentDateRange] = useState<
    typeof DATE_RANGES[number]
  >("oneYear");

  useEffect(() => {
    const dateRange = dateRanges[dateRanges.length - 1];
    dateRange && setCurrentDateRange(dateRange);
  }, [dateRanges]);

  const dateRangeOptions = dateRanges.map((dateRange) => ({
    value: dateRange,
    label: t(DATE_RANGE_LABELS[dateRange]),
  }));
  // <---- Date Range

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (widgetData) {
      charts.push({
        type: "quadrantChart",
        chart: (
          <QuadrantChart
            brands={widgetData.brands}
            axes={widgetData?.[currentDateRange!]?.attributes}
            data={widgetData?.[currentDateRange!]?.values}
          />
        ),
      });
    }

    return charts;
  }, [widgetData, currentDateRange]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const firstBrand = widgetData?.brands[0];

  const descriptionInsertion = getPositionVsCompetitionDescriptionInsertion({
    attributes: widgetData?.[currentDateRange!]?.attributes || [],
    values: widgetData?.[currentDateRange!]?.values[firstBrand!.id],
  });

  const conclusion = useMemo(() => {
    if (!descriptionInsertion) return "";

    return t("w_idc_main_tip", {
      mainBrandName: firstBrand!.name,
      descriptionInsertion1: descriptionInsertion[0],
      descriptionInsertion2: descriptionInsertion[1],
    });
  }, [descriptionInsertion, firstBrand, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          timePeriodSelector={{
            periods: dateRangeOptions,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                viewElement
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
