import { FC, useMemo } from "react";

import styles from "./BusinessDriversPreview.module.scss";
import {
  Preloader,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { useWidgetFetching } from "../../hooks";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import { WIDGET_ID } from "./constants";
import { SummaryHighestDriver } from "../../descriptionInsertionComponents";
import { WidgetSummaryProps } from "../../types";
import { BusinessDriversLineChart } from "./components";

export const BusinessDriversSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData } = useMemo(() => {
    const { brandData = {} } =
      widgetData?.find(({ brandName }) => brandName === selectedBrand.name) ||
      {};
    const { lineChart = [] } = brandData;

    return {
      lineChartData: lineChart,
    };
  }, [selectedBrand, widgetData]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryHighestDriver
          data={filteredData}
          selectedBrand={selectedBrand}
        />
      </div>
      <div className={styles.chartWrapper}>
        <BusinessDriversLineChart
          data={lineChartData}
          searchId={searchId}
          dateRange="allTime"
          viewMode="timePlotterSummary"
          timePlotterId={timePlot.id}
        />
      </div>
    </div>
  );
};
