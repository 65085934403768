import actionTypes from "../actionTypes";

const initialState: Store.TimePlots = {
  data: [],
  status: "idle",
  isShownInLineChart: true,
};

const setTimePlots = (
  state: Store.TimePlots,
  {
    timePlotData,
    status,
    isShownInLineChart,
  }: {
    timePlotData?: Store.TimePlot[];
    status?: "loading" | "idle" | "succeeded";
    isShownInLineChart?: boolean;
  },
) => {
  return {
    ...state,
    ...(timePlotData ? { data: [...timePlotData] } : {}),
    ...(status ? { status } : {}),
    ...(typeof isShownInLineChart !== "undefined"
      ? { isShownInLineChart }
      : {}),
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_TIME_PLOTS:
      return setTimePlots(state, action);
    default:
      return state;
  }
};

export default reducer;
