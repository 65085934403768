const ARTICLES_LIMIT = 1000 as const;

const COLUMNS_FILTER_TYPES = {
  columnsCount: "columnsCount",
} as const;

const SOCIAL_GIST_DATA_SOURCE_NAME = "socialgist" as const;

const SOCIAL_GIST_DATE_FORMAT = "yyyy-MM-dd" as const;

const ADDITIONAL_STRING_FOR_COPY_SEARCH_NAME = "copy" as const;

const REFINED_MENTION_DATA_ALLOWED_FOR: {
  [widgetId: string]: true;
} = {
  "result-sentiment-drivers": true,
  "result-attitude": true,
  "result-sentiment-trends": true,
  "result-sentiment-index": true,
  "result-media-share": true,
  "result-esg-index": true,
  "result-esg-sentiment": true,
  "result-esg-distribution": true,
  "result-reputation-index": true,
  "result-reputation-sentiment": true,
  "result-reputation-distribution": true,
  "result-business-drivers": true,
  "result-key-areas": true,
  "result-position-vs-competition": true,
  "result-robustness-score": true,
};

const REDIRECTED_WIDGET_ID: WIDGET_IDS_TYPES = "result-volume-share";

export {
  ARTICLES_LIMIT,
  COLUMNS_FILTER_TYPES,
  SOCIAL_GIST_DATA_SOURCE_NAME,
  SOCIAL_GIST_DATE_FORMAT,
  ADDITIONAL_STRING_FOR_COPY_SEARCH_NAME,
  REFINED_MENTION_DATA_ALLOWED_FOR,
  REDIRECTED_WIDGET_ID,
};
