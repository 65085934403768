import { CSSProperties, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./HorizontalFunnelChart.module.scss";

// Inner imports
import type { HorizontalFunnelChartProps } from "../types";
import { funnelChartsColors } from "../constants";
import {
  getLabel,
  calcShapesSize,
  calcShapeBorderSize,
  formatFunnelNumbers,
} from "../utils";

export const HorizontalFunnelChart: FC<HorizontalFunnelChartProps> = ({
  className = "",
  style,
  data: initialData = [],
  graphHeight,
  widgetId,
}) => {
  const { t } = useTranslation();
  //DATA
  const data = useMemo(() => initialData.slice(0, 4), [initialData]);

  //BLOCK SIZE
  const {
    ref: blocksRef,
    width: blocksWidth = 0,
    height: blockHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const blockWidth = blocksWidth / data.length;

  const shapeBorderWidth = formatFunnelNumbers(blockWidth / 2);

  const shapesHeight = useMemo(() => calcShapesSize(data), [data]);

  // TICKS
  const ticks = useMemo(() => {
    const interval = blockHeight > 320 ? 10 : 25;

    return [...Array(200 / interval + 1).keys()].map((key) =>
      Math.abs(100 - interval * key),
    );
  }, [blockHeight]);

  const Ticks = (style?: CSSProperties) => (
    <div className={styles.ticks} style={style}>
      {ticks.map((tick, i) => (
        <div key={i}>{tick}%</div>
      ))}
    </div>
  );

  const TicksGrid = () => (
    <div className={styles.ticksGrid}>
      {ticks.map((_, i) => (
        <div key={i} />
      ))}
    </div>
  );

  // HELPERS
  function calcShapeHeight(value: number, prevValue?: number): number {
    if (typeof prevValue !== "undefined") {
      // Seems it's last element of array (PURCHASE INTENT);
      if (prevValue > 0) {
        return (prevValue / 100 / 2 / 2) * blockHeight;
      }
    }

    return (value / 100) * blockHeight;
  }

  function getPercentageValue(index: number, initialValue: number) {
    const shapesSizeForWidget =
      widgetId === "funnel" && shapesHeight[index - 1];
    const value =
      typeof shapesSizeForWidget === "number"
        ? shapesSizeForWidget
        : initialValue;

    return formatFunnelNumbers(value);
  }

  return data.length ? (
    <div className={cx(styles.horizontalFunnelChart, className)} style={style}>
      <div className={styles.main} style={{ minHeight: graphHeight }}>
        {Ticks()}
        <div className={styles.blocksWrapper}>
          {TicksGrid()}
          <div ref={blocksRef} className={styles.blocks}>
            {data.map(({ label, value }, i, arr) => (
              <div className={styles.blockWrapper} key={i}>
                <div className={styles.block}>
                  <div className={styles.shapeWrapper}>
                    {i < arr.length - 1 ? (
                      <>
                        <div
                          style={{
                            borderLeft: `${shapeBorderWidth}px solid ${funnelChartsColors[i]}`,
                            borderRight: `${shapeBorderWidth}px solid transparent`,
                            borderTop: `${calcShapeBorderSize({
                              value: value.percentage,
                              shapeHeight: shapesHeight[i]!,
                              blockSize: blockHeight,
                              prevValue: shapesHeight[i - 1],
                            })}px solid transparent`,
                            borderBottom: `${calcShapeBorderSize({
                              value: value.percentage,
                              shapeHeight: shapesHeight[i]!,
                              blockSize: blockHeight,
                              prevValue: shapesHeight[i - 1],
                            })}px solid ${funnelChartsColors[i]}`,
                          }}
                        />
                        <div
                          style={{
                            height: `${calcShapeHeight(shapesHeight[i]!)}px`,
                            backgroundColor: funnelChartsColors[i],
                          }}
                        />
                        <div
                          style={{
                            borderLeft: `${shapeBorderWidth}px solid ${funnelChartsColors[i]}`,
                            borderRight: `${shapeBorderWidth}px solid transparent`,
                            borderTop: `${calcShapeBorderSize({
                              value: value.percentage,
                              shapeHeight: shapesHeight[i]!,
                              blockSize: blockHeight,
                              prevValue: shapesHeight[i - 1],
                            })}px solid ${funnelChartsColors[i]}`,
                            borderBottom: `${calcShapeBorderSize({
                              value: value.percentage,
                              shapeHeight: shapesHeight[i]!,
                              blockSize: blockHeight,
                              prevValue: shapesHeight[i - 1],
                            })}px solid transparent`,
                          }}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          height: `${calcShapeHeight(
                            shapesHeight[i]!,
                            shapesHeight[i - 1],
                          )}%`,
                          backgroundColor: funnelChartsColors[i],
                        }}
                      />
                    )}
                  </div>
                  <div className={styles.label}>
                    <div title={getLabel(label, t)}>{getLabel(label, t)}</div>
                    <div style={{ borderColor: funnelChartsColors[i] }} />
                  </div>
                  <div className={styles.value}>
                    {getPercentageValue(i, value.percentage)}%
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {Ticks({ alignItems: "flex-start" })}
      </div>
      <div className={styles.descriptions}>
        {data.map(({ description }, i) => (
          <div key={i}>{description}</div>
        ))}
      </div>
    </div>
  ) : null;
};
