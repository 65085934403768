const TIME_PLOTTER_LIMIT_PER_COMPANY = 1000 as const;

const TIME_PLOTTER_FORM_REQUIRED_FIELDS = [
  "name",
  "searchId",
  "dateFrom",
  "dateTo",
];

const TIME_PLOTTER_FORM_MAX_LENGTH = {
  input: 30,
  nameInput: 50,
  textarea: 120,
} as const;

const TIME_PLOTTER_FORM_MIN_DIFFERENCE_IN_DAYS = 1 as const;

const WEEK_IN_DAYS = 7 as const;

export {
  WEEK_IN_DAYS,
  TIME_PLOTTER_LIMIT_PER_COMPANY,
  TIME_PLOTTER_FORM_REQUIRED_FIELDS,
  TIME_PLOTTER_FORM_MAX_LENGTH,
  TIME_PLOTTER_FORM_MIN_DIFFERENCE_IN_DAYS,
};
