import actionTypes from "../actionTypes";
import firestore from "../../firestore";
import { showDevelopmentError } from "../../utils";

// MUTATIONS
export const setKeyWordsWidgetsUpdateDate = (
  keyWordsWidgetsUpdateDate: Store.KeyWordsWidgetsUpdateDate,
) => {
  return {
    type: actionTypes.SET_KEYWORDS_WIDGETS_UPDATE_DATE,
    keyWordsWidgetsUpdateDate,
  };
};

// ACTIONS
export const readKeyWordsWidgetsUpdateDate = () => {
  return async (dispatch: Function): Promise<any> => {
    const db = firestore();
    const docRef = await db.collection("app").doc("widgets-settings").get();
    if (docRef.exists) {
      const data = docRef.data();
      if (data) {
        const { keyWordsWidgetsUpdateDate } = data || "";
        dispatch(
          setKeyWordsWidgetsUpdateDate({
            keyWordsWidgetsUpdateDate,
          }),
        );
      }
    } else {
      showDevelopmentError({
        additionalTexts: [
          `"widgets-settings" collection broken or doesn't exist`,
        ],
      });
    }
  };
};
