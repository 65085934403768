import firebase from "firebase/app";
import * as yup from "yup";
import mapValues from "lodash/mapValues";
import axios from "../../axios";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

const filterSchema = yup.array().of(yup.string().required()).required();

const funnelCustomDataSchema = yup.lazy((obj) => {
  return yup.object(
    mapValues(obj, () => {
      const fieldSchemaWithTransformation = () =>
        yup.array().of(yup.string().required()).default([]);

      return yup.object({
        consideration: fieldSchemaWithTransformation(),
        preference: fieldSchemaWithTransformation(),
        purchaseIntent: fieldSchemaWithTransformation(),
      });
    }),
  );
});

export async function getSosFunnelSuggestedFilters(): Promise<SoSFunnelReviewData.Item> {
  const doc = await firebase
    .firestore()
    .collection("app")
    .doc("search-funnel-suggested-filters")
    .get();

  const data = doc.data();

  const filters: SoSFunnelReviewData.Item = {
    consideration: [],
    preference: [],
    purchaseIntent: [],
  };

  if (!data) return filters;

  // VALIDATION ---->
  if (typeof data === "object" || !Array.isArray(data)) {
    const dataForValidation = data as SoSFunnelReviewData.Item;

    Object.entries(dataForValidation).forEach(([type, typeData]) => {
      try {
        const validatedData = filterSchema.validateSync(typeData);
        filters[type as SoSFunnelReviewData.Types] = validatedData;
      } catch (err) {
        console.error(`NOT CORRECT DATA FORMAT IN "${type}" ----->`, err);
      }
    });
  }
  // <---- VALIDATION

  return filters;
}

export async function getSoSFunnelDataBySearchId(searchId: string) {
  const doc = await firebase
    .firestore()
    .collection("search-funnel-filters")
    .doc(searchId)
    .get();

  const data = doc.data();

  let formattedData: SoSFunnelReviewData.Data = {};

  try {
    const validatedData = funnelCustomDataSchema.validateSync(data);
    formattedData = validatedData;
  } catch (err) {
    formattedData = {};
    console.error(err);
  }

  return formattedData;
}

export async function writeNewFilters(
  searchId: string,
  data: SoSFunnelReviewData.Data,
) {
  await firebase
    .firestore()
    .collection("search-funnel-filters")
    .doc(searchId)
    .set(data);

  return data;
}

export async function removeFilterDocument(searchId: string) {
  await firebase
    .firestore()
    .collection("search-funnel-filters")
    .doc(searchId)
    .delete();
}

export async function recalculateSoSFunnelWidget(searchId: string) {
  return axios.put(`search-funnel-widget-${urlInsertion}-api/v1/${searchId}`);
}
