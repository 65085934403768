import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import * as api from "./industriesApi";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  // error: string | null;
};

export type Industry = {
  id: string;
  label: string;
};

const industriesAdapter = createEntityAdapter<Industry>({
  sortComparer: (a, b) => a.label.localeCompare(b.label),
});

const initialState = industriesAdapter.getInitialState<InitialState>({
  status: "idle",
  // error: null,
});

export const fetchAllIndustries = createAsyncThunk<Industry[]>(
  "industries/fetchAll",
  async () => {
    return await api.getIndustries();
  },
);

const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllIndustries.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllIndustries.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.error = null;
      industriesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchAllIndustries.rejected, (state) => {
      state.status = "failed";
      // state.error = "Can't fetch faqs";
    });
  },
});

export default industriesSlice.reducer;
