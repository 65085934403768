import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./TableCard.module.scss";
import { TableColumn, TableItemGenericProps } from "../../types";
import { MenuDropdown } from "../../../Dropdowns/MenuDropdown/MenuDropdown";
import { TableItemToggler } from "../";
import * as icons from "../../../../icons";

// Inner imports
import { TableCardProps } from "./types";

const TableCard = <T extends TableItemGenericProps>({
  item,
  tableColumns,
  getActionMenuOptions,
  cardHeaderPropName = "name",
  forwardedRef,
}: TableCardProps<T>) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const cardHeaderProp = useMemo(
    () => tableColumns.find(({ key }) => key === cardHeaderPropName),
    [cardHeaderPropName, tableColumns],
  );

  const CardHeaderIcon = useMemo(() => {
    const icon = cardHeaderProp?.icon;

    return icon ? icons[icon] : null;
  }, [cardHeaderProp?.icon]);

  const hasSubTable: boolean = useMemo(() => !!item.subTableProps, [
    item.subTableProps,
  ]);

  const toggleCollapse = useCallback(
    (): void => setIsCollapsed((state) => !state),
    [],
  );

  const headerOnClickProp = useMemo(
    () => tableColumns.find(({ key }) => key === cardHeaderPropName)?.onClick,
    [cardHeaderPropName, tableColumns],
  );

  const renderTableCardCell = useCallback(
    (tableColumn: TableColumn<T>) => {
      const { key, dataKey, onClick } = tableColumn;

      const valueKey = (dataKey || key) as keyof T;
      const value = item[valueKey] || "";
      const valueTooltip = typeof value === "string" ? value : "";

      switch (true) {
        case !!onClick && !!value:
          return (
            <button
              className={styles.clickable}
              onClick={() => onClick?.(item)}
              title={valueTooltip}
            >
              {value}
            </button>
          );
        case !onClick:
          return (
            <div className={styles.infoValue} title={valueTooltip}>
              {value}
            </div>
          );
        default:
          return null;
      }
    },
    [item],
  );

  return (
    <div className={styles.tableCardWrapper}>
      <div className={styles.tableCardHeader}>
        <TableItemToggler
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />
        <div className={styles.headerName} title={item.name}>
          {headerOnClickProp ? (
            <button
              className={styles.clickable}
              onClick={() => headerOnClickProp(item)}
            >
              {item[cardHeaderPropName]}
            </button>
          ) : (
            <span>
              {CardHeaderIcon && <CardHeaderIcon />}
              <span>{item[cardHeaderPropName]}</span>
            </span>
          )}
        </div>
        <div className={styles.headerAction}>
          {tableColumns.map(({ isAction, getComponent }) => {
            if (!isAction || !getComponent) return null;

            return getComponent(item);
          })}
          {!!getActionMenuOptions && !!getActionMenuOptions(item)?.length && (
            <MenuDropdown
              options={getActionMenuOptions(item).map((option) => ({
                ...option,
                onClicked: option.onClick,
              }))}
              className={styles.actionsMenuDropdown}
              ref={forwardedRef}
            />
          )}
        </div>
      </div>
      {isCollapsed && (
        <div
          className={cx(
            styles.tableCardBody,
            isCollapsed ? styles.isBodyCollapsed : "",
          )}
        >
          <div className={styles.tableCardInfoWrapper}>
            {tableColumns.map((tableColumn) => {
              const { key, labelKey, isAction } = tableColumn;

              if (isAction || key === cardHeaderPropName || !labelKey)
                return null;

              const label = t(labelKey);

              return (
                <div key={key} className={styles.tableCardInfo}>
                  <div className={styles.infoName} title={label}>
                    {label}
                  </div>
                  {renderTableCardCell(tableColumn)}
                </div>
              );
            })}
          </div>
          {hasSubTable &&
            item.subTableProps?.items.map((subItem) => (
              <TableCard
                key={subItem.id}
                item={subItem}
                tableColumns={item.subTableProps?.tableColumns || []}
                getActionMenuOptions={item.subTableProps?.getActionMenuOptions}
                forwardedRef={forwardedRef}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default TableCard;
