import { useState, memo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./AddMultiName.module.scss";

import { FadeInImage } from "../..";
import { Input } from "../../inputs/Input/Input";
import { useTemporaryErrors } from "../../../hooks";
import { withError } from "../../../hocs";
import { NOT_ALLOWED_SYMBOLS } from "../../../data/searchNotAllowedSymbols";

// Inner imports
import { NamesList } from "./components/NamesList";

const InputWithError = withError(Input);

type Props = {
  names: string[];
  max?: number;
  onDone: (additionalNames: string[]) => void;
  mainNameValue: string;
};

export const AddMultiName = memo(
  ({ names, max = 3, onDone, mainNameValue }: Props) => {
    const [value, setValue] = useState<string>("");
    const [additionalNames, setAdditionalNames] = useState<string[]>(names);
    const { t } = useTranslation();

    const { errors, setErrors } = useTemporaryErrors(3000);

    const onSubmitHandler = (e: React.SyntheticEvent) => {
      e.preventDefault();
      const ifNameExist = additionalNames.find(
        (el) => el.toLowerCase().trim() === value.toLowerCase().trim(),
      );

      const isSameWithBrand: boolean =
        value.toLowerCase().trim() === mainNameValue.toLowerCase().trim();

      const isBrandEnteredWithNotAllowedSymbol = NOT_ALLOWED_SYMBOLS.some(
        (symbol) => value.includes(symbol),
      );

      if (isBrandEnteredWithNotAllowedSymbol) {
        const joinedNotAllowedSymbols = NOT_ALLOWED_SYMBOLS.join("  ");
        setErrors({
          input: `Not allowed symbols: [ ${joinedNotAllowedSymbols} ]`,
        });
        return;
      }

      if (ifNameExist || isSameWithBrand) {
        setErrors({
          input: t("dc_name_already_added_error"),
        });
        return;
      }

      if (value) {
        setAdditionalNames((state) => [...state, value.trim()]);
        setValue("");
      }
    };

    const deleteHandler = (name: string): void => {
      const newAdditionalNames = additionalNames.filter((el) => el !== name);
      setAdditionalNames(newAdditionalNames);
    };

    return (
      <div className={styles.additionalNames}>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <h6>{t("dc_additional_multiname_title")}</h6>
            <p>{t("dc_additional_multiname_subtitle")}</p>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.names}>
              {additionalNames.length < max && (
                <form onSubmit={onSubmitHandler} className={styles.form}>
                  <InputWithError
                    value={value}
                    changeHandler={(value: string) =>
                      setValue(value.trimStart())
                    }
                    error={errors.input}
                  />
                  <button type="submit">
                    {t("dc_additional_multiname_button_add")}
                  </button>
                </form>
              )}
              <NamesList
                names={additionalNames}
                deleteHandler={deleteHandler}
              />
            </div>

            <button
              type="button"
              className={styles.doneBtn}
              onClick={() => onDone(additionalNames)}
            >
              {t("dc_additional_multiname_button_done")}
            </button>
          </div>
        </div>
        <div className={styles.imageArea}>
          <FadeInImage className={styles.image} name="birdWaving" />
        </div>
      </div>
    );
  },
);
