import { FC, useMemo } from "react";

import styles from "./EsgSentimentPreview.module.scss";
import {
  Preloader,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import esgParameters from "../../../data/esgParameters";
import { useWidgetFetching } from "../../hooks";
import { INDEX } from "../../constants";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import { WIDGET_ID } from "./constants";
import { SummaryTimePeriod } from "../../descriptionInsertionComponents";
import { WidgetSummaryProps } from "../../types";

export const EsgSentimentSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData } = useMemo(() => {
    const { brandData = {} } =
      widgetData?.find(({ brandName }) => brandName === selectedBrand.name) ||
      {};
    const { lineChart = [] } = brandData;

    return {
      lineChartData: lineChart.map(({ date, values }) => ({
        date,
        values: Object.fromEntries(
          Object.entries(values).map(([label, value]) => [
            label,
            value.positive,
          ]),
        ),
      })),
    };
  }, [widgetData, selectedBrand]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const mainBrandValues = useMemo<number[]>(
    () => filteredData.map(({ values }) => values[INDEX] || 0),
    [filteredData],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={mainBrandValues}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
      </div>
      <div className={styles.chartWrapper}>
        <LineChart
          className={styles.chart}
          labels={esgParameters}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: 3,
          }}
          axis={{
            yAxisVerticalPadding: { top: 10, bottom: 0 },
          }}
        />
      </div>
    </div>
  );
};
