import { compareAsc } from "date-fns";

import { INITIAL_MONTHS_COUNT, MAX_WORDS_QUANTITY } from "./constants";

const removeDuplicates = (mergedPeriods: SentimentDrivers.Value[]) => {
  const uniqueWordsMap: {
    [word: string]: Omit<SentimentDrivers.Value, "word"> & {
      quantity: number;
    };
  } = {};

  mergedPeriods.forEach(({ word, relatedWords, count: wordCount }) => {
    const alreadyAddedWord = uniqueWordsMap[word];
    if (alreadyAddedWord) {
      const { quantity, count } = alreadyAddedWord;
      alreadyAddedWord.quantity = quantity + 1;
      alreadyAddedWord.count = count + wordCount;
    } else {
      uniqueWordsMap[word] = {
        relatedWords,
        count: wordCount,
        quantity: 1,
      };
    }
  });

  const wordsList: SentimentDrivers.Value[] = [];

  for (let word in uniqueWordsMap) {
    const uniqueWordsData = uniqueWordsMap[word];
    if (uniqueWordsData) {
      const { relatedWords, count, quantity } = uniqueWordsData;

      wordsList.push({
        word,
        relatedWords,
        count: count / quantity,
      });
    }
  }

  return wordsList;
};

const calculateBrandDataByPeriod = ({
  brandData,
  dateRange: { startDate, endDate },
}: {
  brandData?: SentimentDrivers.Item[];
  dateRange: { startDate?: string | null; endDate?: string | null };
}) => {
  if (!brandData || !brandData.length || !startDate) return;

  if (!endDate) {
    return brandData.find(({ date }) => {
      return date === startDate;
    });
  }

  const brandDataSuitableByPeriod = brandData.filter((item) => {
    const isDateAfterChosenStartDate =
      compareAsc(new Date(item.date), new Date(startDate)) >= 0;

    const isBeforeChosenEndDate =
      compareAsc(new Date(endDate), new Date(item.date)) >= 0;

    return isDateAfterChosenStartDate && isBeforeChosenEndDate;
  });

  const mergedPeriods = brandDataSuitableByPeriod.reduce(
    (acc, item) => {
      acc.positive.push(...(item.positive || []));
      acc.neutral.push(...(item.neutral || []));
      acc.negative.push(...(item.negative || []));
      return acc;
    },
    {
      positive: [],
      neutral: [],
      negative: [],
    } as Omit<SentimentDrivers.Item, "date">,
  );

  const { positive, neutral, negative } = mergedPeriods;

  return {
    positive: removeDuplicates(positive),
    neutral: removeDuplicates(neutral),
    negative: removeDuplicates(negative),
  };
};

const checkIfDataIsExist = (data?: SentimentDrivers.Value[]): boolean =>
  !!data && Array.isArray(data) && !!data.length;

const getBiggestBrandDataItems = (
  data?: Pick<SentimentDrivers.Item, "positive" | "negative" | "neutral">,
) => {
  if (!data) return;

  return Object.entries(data)
    .map(([key, values]) => [
      key,
      [...values]
        .sort((a, b) => b.count - a.count)
        .slice(0, MAX_WORDS_QUANTITY),
    ])
    .reduce(
      (acc, [key, values]) => ({
        ...acc,
        [key as string]: values,
      }),
      {} as Pick<SentimentDrivers.Item, "positive" | "negative" | "neutral">,
    );
};

const getFirstSuitableMonth = (
  brandData: SentimentDrivers.ChartsData[string],
  monthsCount: number,
): SentimentDrivers.Item | undefined => {
  const initialIndex =
    monthsCount > brandData.length ? 0 : brandData.length - monthsCount;

  for (let i = initialIndex; i <= brandData.length; i++) {
    const { positive, negative, neutral } = brandData[i] || {};

    const isPositiveDataExist = checkIfDataIsExist(positive);
    const isNegativeDataExist = checkIfDataIsExist(negative);
    const isNeutralDataExist = checkIfDataIsExist(neutral);

    if (isPositiveDataExist || isNegativeDataExist || isNeutralDataExist) {
      return brandData[i];
    }
  }

  return brandData[initialIndex];
};

const getLastSuitableMonth = (
  brandData: SentimentDrivers.ChartsData[string],
): SentimentDrivers.Item | undefined => {
  const monthQuantity = brandData.length - 1;

  return brandData[monthQuantity];

  /*
   * this logic is deprecated as we take the last available month despite
   * existence of data in this month
   */
  // for (let i = monthQuantity; i >= 0; i--) {
  //   const { positive, negative, neutral } = brandData[i] || {};
  //
  //   const isPositiveDataExist = checkIfDataIsExist(positive);
  //   const isNegativeDataExist = checkIfDataIsExist(negative);
  //   const isNeutralDataExist = checkIfDataIsExist(neutral);
  //
  //   if (isPositiveDataExist || isNegativeDataExist || isNeutralDataExist) {
  //     return brandData[i];
  //   }
  // }
};

const prepareDataForTagCloud = (tags: SentimentDrivers.Value[]) => {
  return tags.map(({ word, relatedWords, count }) => ({
    value: word,
    count,
    relatedWords,
  }));
};

const getInitialStartDate = (
  chosenBrandData?: SentimentDrivers.Item[],
): string | undefined => {
  if (!chosenBrandData) return;
  const { date } =
    getFirstSuitableMonth(chosenBrandData, INITIAL_MONTHS_COUNT) || {};
  return date;
};

const getInitialEndDate = (
  chosenBrandData?: SentimentDrivers.Item[],
): string | undefined => {
  if (!chosenBrandData) return;
  const { date } = getLastSuitableMonth(chosenBrandData) || {};
  return date;
};

const checkIfDataExists = (
  chartData: SentimentDrivers.ChartsData = {},
): boolean =>
  Object.keys(chartData).some((brandId: string) =>
    _checkIfBrandDataExists(chartData[brandId]),
  );

const getIndexOfFirstBrandWithData = (
  widgetData?: SentimentDrivers.Data,
): number => {
  const { brands = [], chartsData = {} } = widgetData || {};

  const brandIndexWithExistingData = brands.findIndex(({ id: brandId }) =>
    _checkIfBrandDataExists(chartsData[brandId]),
  );

  return brandIndexWithExistingData < 0 ? 0 : brandIndexWithExistingData;
};

export {
  calculateBrandDataByPeriod,
  removeDuplicates,
  checkIfDataIsExist,
  getBiggestBrandDataItems,
  getFirstSuitableMonth,
  getLastSuitableMonth,
  prepareDataForTagCloud,
  getInitialStartDate,
  getInitialEndDate,
  checkIfDataExists,
  getIndexOfFirstBrandWithData,
};

function _checkIfBrandDataExists(
  brandData?: SentimentDrivers.ChartsData[string],
): boolean {
  if (!brandData) return false;

  return brandData?.some(
    ({ positive, neutral, negative }) =>
      !!positive.length || !!negative.length || !!neutral.length,
  );
}
