import { createReducer } from "@reduxjs/toolkit";
import actionTypes from "../actionTypes";

const initialState: Store.Company = {
  id: "",
  name: "",
  marketCode: "",
  subscriptionPlanId: "",
  enterpriseDashboards: [],
  members: [],
  companySearchesQuantity: 0,
  createdAt: "",
  mainDataSource: "meltwater",
};

const setCompanyData = (state: Store.Company, action: any) => {
  state = { ...state, ...action.companyData };
  return state;
};

const setCompanyMembersEmail = (
  state: Store.Company,
  action: { members?: Store.Company["members"] },
) => {
  const { members = [] } = action || {};

  if (!!members.length) {
    state["members"] = members;
  }
};

const removeCompanyData = (state: Store.Company) => {
  state = initialState;
  return state;
};

const reducer = createReducer(initialState, {
  [actionTypes.SET_COMPANY_DATA]: setCompanyData,
  [actionTypes.REMOVE_COMPANY_DATA]: removeCompanyData,
  [actionTypes.SET_COMPANY_MEMBERS_EMAIL]: setCompanyMembersEmail,
});

export default reducer;
