import { useEffect, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Presentation.module.scss";
import context from "../../context";
import { WidgetPage } from "../Home/WidgetPage/WidgetPage";
import { ROUTS } from "../../data/routs";
import { getCompanyIdByUserId, readCompany } from "../../store/company/actions";

// TODO: ActionBar needs to be transferred to the <App /> component and removed from Home and curren component
import ActionBar from "../../components/ActionBar/ActionBar";

// Inner imports
import { PresentationFirstPage, PresentationLastPage } from "./components";
import { getLayoutSize } from "./utils";

const LAYOUT_SIZE = getLayoutSize();

const Presentation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setIsPresentation, actionBarProps } = useContext(context);

  const params = useParams<{ dashboardId?: string }>();
  const state = history.location.state as { dashboardId?: string } | undefined;

  const userId = useSelector(({ user }: Store.State) => user.id);

  useEffect(() => {
    setIsPresentation(true);
    return () => setIsPresentation(false);
  }, [setIsPresentation]);

  useEffect(() => {
    // Fetch company if user logged in
    if (!userId) return;

    try {
      (async () => {
        const companyId = await getCompanyIdByUserId(userId);
        await dispatch(readCompany(companyId));
      })();
    } catch (err) {
      console.error(err);
    }
  }, [userId, dispatch]);

  const dashboardId = useMemo(() => {
    const id = params?.dashboardId || state?.dashboardId || "";
    return id.replace(`?size=${LAYOUT_SIZE}`, "");
  }, [params, state]);

  return (
    <>
      {actionBarProps && <ActionBar {...actionBarProps} />}
      <Switch>
        <Route exact path={ROUTS.presentationFirstPage}>
          <PresentationFirstPage
            layoutSize={LAYOUT_SIZE}
            dashboardId={dashboardId}
          />
        </Route>
        <Route exact path={ROUTS.presentationMain}>
          <div className={styles.dashboards}>
            <WidgetPage layoutSize={LAYOUT_SIZE} />
          </div>
        </Route>
        <Route exact path={ROUTS.presentationLastPage}>
          <PresentationLastPage />
        </Route>
        <Redirect to={`/presentation/${dashboardId}?size=${LAYOUT_SIZE}`} />
      </Switch>
    </>
  );
};

export default Presentation;
