import { isNumber } from "src/utils";

export const sortWords = <T>(
  wordA: T,
  wordB: T,
  sortBy: keyof T,
  sortDirection: SortDirection,
): number => {
  const [valueA, valueB] = [wordA[sortBy], wordB[sortBy]];

  const [firstValue, secondValue] =
    sortDirection === "ASC" ? [valueA, valueB] : [valueB, valueA];

  if (typeof firstValue === "string" && typeof secondValue === "string") {
    const [firstStringLowerCase, secondStringLowerCase] = [
      firstValue.toLowerCase(),
      secondValue.toLowerCase(),
    ];

    return firstStringLowerCase.localeCompare(secondStringLowerCase);
  }

  if (typeof firstValue === "boolean" && typeof secondValue === "boolean") {
    return Number(firstValue) - Number(secondValue);
  }

  if (typeof firstValue === "number" && typeof secondValue === "number") {
    return firstValue - secondValue;
  }

  return 0;
};

export const roundNumberToDecimals = (num: number, digits: number): number => {
  if (!isNumber(num)) return 0;

  const isInteger = num % 1 === 0;

  return isInteger ? num : Number(num.toFixed(digits));
};
