import { FC, FormEvent, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./CreateFolderModal.module.scss";
import context from "src/context";
import { Check } from "src/icons";
import { withError } from "src/hocs";
import { useTemporaryErrors } from "src/hooks";
import { MultiSelect, Input, Label } from "src/components";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { selectAvailableDashboards } from "src/store/selectors";
import { addOneFolder } from "src/store/folders/foldersSlice";

// Types
import type { MultiselectOption } from "src/components/inputs/MultiSelect/types";
import type { Dashboard } from "src/store/dashboards/dashboardsSlice";

const InputWithError = withError(Input);
const MultiSelectWithError = withError(MultiSelect);

export const CreateFolderModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModalElement, setIsGlobalPreloaderShown } = useContext(context);
  const { errors, setErrors } = useTemporaryErrors(3000);

  const folders = useSelector(({ folders }: RootState) => folders);
  const dashboards = useSelector(selectAvailableDashboards);

  const [folderName, setFolderName] = useState<string>("");
  const [selectedDashboards, setSelectedDashboards] = useState<
    MultiselectOption[]
  >([]);

  useEffect(() => {
    const modalWrapperRef = document.getElementById("modal-wrapper");
    if (!modalWrapperRef) return;

    const modalWrapperChildrenWrapper = modalWrapperRef
      .childNodes[0]! as HTMLElement;

    modalWrapperChildrenWrapper.style.overflow = "initial";

    return () => {
      modalWrapperChildrenWrapper.style.overflow = "hidden";
    };
  }, []);

  const allFoldersName = useMemo(() => {
    const names: string[] = [];

    Object.values(folders.entities).forEach((folder) => {
      const folderName = folder?.name;
      if (folderName) names.push(folderName);
    });

    return names;
  }, [folders.entities]);

  const dashboardsInFolderIds = useMemo(
    () =>
      Object.values(folders.entities).reduce((dashboardsIds, folderData) => {
        const dashboardsInFolder = folderData?.dashboardIds || [];

        dashboardsIds.push(...dashboardsInFolder);
        return dashboardsIds;
      }, [] as string[]),
    [folders.entities],
  );

  const allowedForChoseDashboards = useMemo(() => {
    const suitableDashboards = Object.values(dashboards.entities).filter(
      (dashboardData) => {
        if (!dashboardData) return false;
        const { id } = dashboardData;

        return !dashboardsInFolderIds.includes(id);
      },
    ) as Dashboard[];

    return suitableDashboards.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [dashboards, dashboardsInFolderIds]);

  const onDashboardCheckHandler = (option: MultiselectOption) => {
    const optionValue = option.value;
    const isDashboardChecked = selectedDashboards.some(
      ({ value }) => value === optionValue,
    );

    if (!isDashboardChecked)
      return setSelectedDashboards((state) => [...state, option]);

    const newSelectedDashboards = selectedDashboards.filter(
      ({ value }) => value !== optionValue,
    );
    setSelectedDashboards(newSelectedDashboards);
  };

  const onSubmitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate();

    if (!!Object.keys(errors).length) return;

    const dashboardIds = selectedDashboards.map(({ value }) => value);

    try {
      setIsGlobalPreloaderShown(true);
      await dispatch(addOneFolder({ name: folderName, dashboardIds }));
      showToastNotification({
        type: "success",
        text: t("d_table_folder_create_success"),
      });
    } catch (err) {
      showToastNotification({
        type: "error",
        text: t("request_error"),
      });
      console.error(err);
    } finally {
      setIsGlobalPreloaderShown(false);
      setModalElement(null);
    }
  };

  const onResetFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setModalElement(false);
  };

  function validate() {
    const errors: Errors = {};

    const trimmedName = folderName.trim().toLowerCase();

    if (!trimmedName) {
      errors["name"] = t("cf_required_error");
    }

    if (
      allFoldersName.some((name) => name.trim().toLowerCase() === trimmedName)
    ) {
      errors["name"] = t("cf_folder_already_exist_error");
    }

    if (!selectedDashboards.length) {
      errors["dashboards"] = t("cf_required_error");
    }

    setErrors(errors);
    return errors;
  }

  return (
    <div className={styles.createFolderModal}>
      <div className={styles.head}>
        <Check />
        {t("cf_title")}
      </div>
      <form
        className={styles.createFolderForm}
        onSubmit={onSubmitFormHandler}
        onReset={onResetFormHandler}
      >
        <div>
          <Label leftText={t("cf_folder_name_label")} />
          <InputWithError
            placeholder={t("cf_folder_name_placeholder")}
            value={folderName}
            changeHandler={setFolderName}
            error={errors["name"]}
          />
        </div>
        <div>
          <Label leftText={t("cf_dashboards_label")} />
          <MultiSelectWithError
            showFilter
            customInputText={t("cf_dashboards_text", {
              count: selectedDashboards.length,
            })}
            placeholder={t("cf_dashboards_placeholder")}
            openDirection={"down"}
            options={allowedForChoseDashboards}
            selectedOptions={selectedDashboards}
            onCheckHandler={onDashboardCheckHandler}
            error={errors["dashboards"]}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <button type="reset">{t("cd_reset_button")}</button>
          <button type="submit">{t("cf_submit_button")}</button>
        </div>
      </form>
    </div>
  );
};
