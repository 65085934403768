import { sortArrayByAlphabet } from "../../../../utils";

type ReturnType = {
  positive: string[];
  negative: string[];
  neutral: string[];
};

const getPositiveNegativeAndNeutralWords = (
  chartsData: SentimentDrivers.Item[],
): ReturnType => {
  const allWordsForAllPeriods = chartsData.reduce(
    (acc, item) => {
      const { positive, neutral, negative } = item;

      const arrayOfPositiveWordsFromCurrentPeriod = getWordsAsArrayFromPeriod(
        positive,
      );
      const arrayOfNeutralWordsFromCurrentPeriod = getWordsAsArrayFromPeriod(
        neutral,
      );
      const arrayOfNegativeWordsFromCurrentPeriod = getWordsAsArrayFromPeriod(
        negative,
      );

      acc.positive.push(...arrayOfPositiveWordsFromCurrentPeriod);
      acc.neutral.push(...arrayOfNeutralWordsFromCurrentPeriod);
      acc.negative.push(...arrayOfNegativeWordsFromCurrentPeriod);

      return acc;
    },
    {
      positive: [],
      negative: [],
      neutral: [],
    } as ReturnType,
  );

  const { positive, neutral, negative } = allWordsForAllPeriods;

  const positiveWordsWithoutDuplicates = removeDuplicatesFromStringArray(
    positive,
  );
  const neutralWordsWithoutDuplicates = removeDuplicatesFromStringArray(
    neutral,
  );
  const negativeWordsWithoutDuplicates = removeDuplicatesFromStringArray(
    negative,
  );

  return {
    positive: sortArrayByAlphabet(positiveWordsWithoutDuplicates),
    neutral: sortArrayByAlphabet(neutralWordsWithoutDuplicates),
    negative: sortArrayByAlphabet(negativeWordsWithoutDuplicates),
  };
};

// HELPERS ---->
function getWordsAsArrayFromPeriod(dataArray: SentimentDrivers.Value[]) {
  return dataArray.map(({ word }) => word);
}

function removeDuplicatesFromStringArray(stringArray: string[]) {
  return [...new Set(stringArray)];
}
// <---- HELPERS

export { getPositiveNegativeAndNeutralWords };
