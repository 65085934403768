import { FC, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ShareOfSearch.module.scss";
import DATE_RANGES, { INITIAL_DATE_RANGE } from "../../../data/dateRanges";
import {
  PieChartWithInnerLabels,
  LineChart,
  WidgetButtonsBar,
  WidgetFooterButtons,
  AboutThis,
  ErrorPlaceholder,
  Preloader,
} from "../../../components";
import { useNameForDownloadFiles } from "../../../hooks";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";

// Inner imports
import { WIDGET_ID } from "./constants";

export const ShareOfSearch: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  // Date Range ---->
  const dateRanges = useMemo(() => {
    const formattedDateRanges = DATE_RANGES.map(({ value, label }) => ({
      value,
      label,
    }));
    return formattedDateRanges.filter(
      ({ value }) => widgetData && widgetData.pieChart[value],
    );
  }, [widgetData]);

  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    INITIAL_DATE_RANGE,
  );
  // <---- Date Range

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};

    return {
      lineChartData: lineChart,
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(pieChartData).length) {
      charts.push({
        type: "pieChart",
        chart: (
          <PieChartWithInnerLabels
            className={styles.pieChart}
            data={pieChartData[currentDateRange]?.values}
            fitTo="height"
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={brands}
            data={lineChartData}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              graphHeight: "100%",
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_share_of_search_title"),
              yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
            }}
          />
        ),
      });
    }

    return charts;
  }, [
    pieChartData,
    lineChartData,
    currentDateRange,
    brands,
    dashboardId,
    searchId,
    t,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const firstBrand = widgetData && widgetData.brands[0];

  const descriptionInsertion =
    widgetData &&
    widgetData.pieChart[currentDateRange!]?.values.find(
      ({ brandName }) => brandName === firstBrand!.name,
    )?.value;

  const totalVolume = useMemo(() => {
    if (viewType === "pieChart") {
      return widgetData?.pieChart[currentDateRange!]?.values.reduce(
        (acc, { additionalValue }) => (acc += additionalValue || 0),
        0,
      );
    }
    if (viewType === "lineChart") {
      return widgetData?.lineChart.reduce((acc, { additionalValues }) => {
        const values = +Object.values(additionalValues ?? {}).reduce(
          (a, b) => a + b,
          0,
        );
        if (isNaN(values)) return acc;
        return (acc += values);
      }, 0);
    }
  }, [viewType, widgetData, currentDateRange]);

  const conclusion = useMemo(() => {
    const descriptionInsertionText =
      descriptionInsertion || !!totalVolume
        ? t("w_share_of_search_main_tip", {
            mainBrandName: firstBrand!.name,
            descriptionInsertion,
          })
        : "";

    const totalVolumeText = !!totalVolume
      ? t("w_share_of_search_main_tip_total_volume", {
          totalVolume: totalVolume.toLocaleString(),
        })
      : "";

    return `${descriptionInsertionText} ${totalVolumeText}`.trim();
  }, [descriptionInsertion, totalVolume, firstBrand, t]);
  //  Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "lineChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            downloadExcelButtonProps={{
              widgetName: "share-of-search",
              fileName: downloadedFileName,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
