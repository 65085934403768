import { FC } from "react";

import styles from "./NamesList.module.scss";
import { Trash } from "../../../../icons";

type Props = {
  names: string[];
  deleteHandler: (name: string) => void;
};

const NamesList: FC<Props> = ({ names, deleteHandler }) => {
  return (
    <div className={styles.namesList}>
      <ul>
        {names.map((name, index) => (
          <li className={styles.namesListItem} key={`${name}-${index}`}>
            <span>{name}</span>
            <button type="button" onClick={() => deleteHandler(name)}>
              <Trash />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { NamesList };
