import { StatusType } from "./TopTrendKeywordsReviewData/types";
import {
  DataLoadingPlug,
  EmptyDataPlug,
  LoadingFailedPlug,
  WidgetIsCalculatingPlug,
} from "./Plugs/Plugs";

const getPlug = (
  status: StatusType,
  isBrandDataEmpty: boolean,
  isWidgetCalculating: boolean,
) => {
  switch (true) {
    case status === "loading":
      return DataLoadingPlug;
    case status === "failed":
      return LoadingFailedPlug;
    case isWidgetCalculating:
      return WidgetIsCalculatingPlug;
    case isBrandDataEmpty:
      return EmptyDataPlug;
    default:
      return null;
  }
};

export { getPlug };
