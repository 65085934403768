import firebase from "firebase/app";
import * as yup from "yup";
import mapValues from "lodash/mapValues";

import axios from "../../axios";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

const getStringValueWithDefault = (value: any, defaultValue?: string): string =>
  value === String(value) ? value : defaultValue;

const getNumberValueWithDefault = (value: any, defaultValue?: number): number =>
  value === Number(value) ? value : defaultValue;

const enterpriseDashboardSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
});

const companySchema = yup
  .object()
  .shape({
    name: yup.string(),
    enterpriseDashboards: yup.array().of(enterpriseDashboardSchema),
    subscriptionPlanId: yup.string().required(),
    team: yup.lazy((obj) =>
      yup.object(
        mapValues(obj, () =>
          yup.object({
            fullName: yup.string(),
            role: yup.string(),
            isOwner: yup.boolean(),
            brands: yup.array().of(yup.string()),
          }),
        ),
      ),
    ),
    customDashboardLimit: yup
      .number()
      .transform((value) => (value === Number(value) ? value : undefined)),
    customSearchesLimit: yup
      .number()
      .transform((value) => (value === Number(value) ? value : undefined)),
    marketCode: yup.string(),
    workFor: yup.string(),
    primaryFocus: yup.array().of(yup.string().required()),
    _timestamps: yup.object().shape({ createdAt: yup.string() }),
  })
  .noUnknown();

export const deleteUser = (userId: string) =>
  axios.delete(`users-service-${urlInsertion}-api/${userId}`);

export const changeCompanySubscriptionPlanFromInactiveToFremium = (
  companyId: string,
) => axios.get(`companies-${urlInsertion}-api/${companyId}/activate`);

export const getCompany = async (companyId: string) => {
  const company = await firebase
    .firestore()
    .collection("companies")
    .doc(companyId)
    .get();

  if (!company.exists) {
    throw "Can't find company";
  }

  const {
    name,
    enterpriseDashboards: enterpriseDashboardsData,
    subscriptionPlan,
    team,
    customDashboardLimit,
    customSearchesLimit,
    marketCode,
    workFor,
    primaryFocus: primaryFocusData,
    _timestamps,
  } = company.data() || {};

  const enterpriseDashboards = Array.isArray(enterpriseDashboardsData)
    ? (enterpriseDashboardsData as Store.Company["enterpriseDashboards"])?.filter(
        ({ id, name }) =>
          !!getStringValueWithDefault(id) && !!getStringValueWithDefault(name),
      )
    : [];

  const primaryFocus = Array.isArray(primaryFocusData)
    ? (primaryFocusData as Store.Company["primaryFocus"])?.filter(
        (value) => !!getStringValueWithDefault(value),
      )
    : [];

  const companyDataWithDefaultValues = {
    name: getStringValueWithDefault(name, ""),
    subscriptionPlanId: subscriptionPlan,
    enterpriseDashboards,
    team,
    customDashboardLimit: getNumberValueWithDefault(customDashboardLimit),
    customSearchesLimit: getNumberValueWithDefault(customSearchesLimit),
    marketCode: getStringValueWithDefault(marketCode, ""),
    workFor: getStringValueWithDefault(workFor, ""),
    primaryFocus,
    _timestamps: {
      createdAt: getStringValueWithDefault(_timestamps?.createdAt, ""),
    },
  };

  return companySchema.validateSync(companyDataWithDefaultValues);
};
