import { CSSProperties, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-paginate";
import cx from "classnames";

import styles from "./Table.module.scss";
import {
  PAGINATION_MIN_ITEMS_COUNT,
  usePagination,
  useTableSettings,
  useWindowWidth,
} from "../../hooks";
import { Select } from "../inputs/Select/Select";
import {
  ChevronLeft,
  ChevronRight,
  EmptyTableIcon,
  Triangle,
} from "../../icons";
import { TableCard, TableRow } from "./components";

// Inner imports
import { TableItemGenericProps, TableProps, TableSort } from "./types";
import {
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  TABLE_PAGINATION_NAVIGATION_ICON_SIZE,
  TABLE_PAGINATION_BREAK_LABEL,
  TABLE_PAGINATION_CONFIG,
  MOBILE_WIDTH_BREAKPOINT,
} from "./constants";
import { sortValues } from "./utils";

export const Table = <T extends TableItemGenericProps>({
  type = "",
  items: allItems,
  tableColumns,
  isTableShort,
  isTableExpandable,
  isTableScrollable,
  isSubTable,
  emptyTableLabel,
  getActionMenuOptions,
  defaultSort,
  customPreSort,
  customAfterSort,
}: TableProps<T>) => {
  const { t } = useTranslation();

  const windowWidth: number = useWindowWidth();

  const contentRef = useRef(null);

  const {
    data: dashboardTableSettings,
    setter: setDashboardTableSettings,
  } = useTableSettings(type);

  const [sort, setSort] = useState<TableSort>(defaultSort);

  const isTableEmpty: boolean = useMemo(() => !allItems.length, [allItems]);

  const isMobileView: boolean = useMemo(
    () => windowWidth < MOBILE_WIDTH_BREAKPOINT,
    [windowWidth],
  );

  const isPaginationVisible: boolean = useMemo(
    () => allItems.length > PAGINATION_MIN_ITEMS_COUNT && !isSubTable,
    [allItems, isSubTable],
  );

  const sortedItems: T[] = useMemo(() => {
    if (!sort) return allItems;

    const sortField = Object.keys(sort)[0] as keyof T;
    const sortDirection = Object.values(sort)[0];

    if (!sortField || !sortDirection) return allItems;

    return [...allItems].sort((a, b) => {
      const valueA = a[sortField] ?? "";
      const valueB = b[sortField] ?? "";
      const valueCompareResult = sortValues(valueA, valueB, sortDirection);

      const preSort = customPreSort ? customPreSort(a, b) : 0;
      const afterSort = customAfterSort ? customAfterSort(a, b) : 0;

      return preSort || valueCompareResult || afterSort;
    });
  }, [sort, allItems, customPreSort, customAfterSort]);

  const {
    paginationData: {
      slicedItems,
      totalItemsCount,
      pagesCount,
      pageNumber,
      itemsPerPage,
      firstItemIndex,
      lastItemIndex,
    },
    paginationMutation: { setPageNumber, setItemsPerPage },
  } = usePagination({
    items: sortedItems,
    initialPageNumber: dashboardTableSettings.page,
  });

  const items: T[] = useMemo(() => (isSubTable ? sortedItems : slicedItems), [
    isSubTable,
    slicedItems,
    sortedItems,
  ]);

  const getSortIconClassName = useCallback(
    (value?: "asc" | "desc" | null): string => {
      switch (value) {
        case "desc":
          return styles.activeDesc || "";
        case "asc":
          return styles.activeAsc || "";
        default:
          return "";
      }
    },
    [],
  );

  const onSortableFieldClick = useCallback(
    (field: string): void => {
      const previousField = Object.keys(sort || {})[0];
      const previousDirection = (sort || {})[field];
      let newSort: TableSort;

      if (field === previousField) {
        newSort =
          previousDirection === "desc" ? { [field]: "asc" } : defaultSort;
      } else {
        newSort = {
          [field]: "desc",
        };
      }

      setSort(newSort);
    },
    [defaultSort, sort],
  );

  const onPageChange = useCallback(
    ({ selected }) => {
      const tableBodyElement = (contentRef.current || {}) as HTMLElement;

      tableBodyElement.scroll?.({ top: 0 });
      setDashboardTableSettings({ page: selected });
      setPageNumber(selected);
    },
    [setPageNumber, setDashboardTableSettings],
  );

  const tableHeaders: JSX.Element[] = useMemo(
    () =>
      tableColumns.map(({ key, labelKey, isSortable, isAction, isNumeric }) => {
        const sortableClassName = isSortable ? styles.sortable : "";
        const actionableClassName = isAction ? styles.columnAction : "";
        const numericClassName = isNumeric ? styles.columnNumeric : "";
        const sortIconClassName = getSortIconClassName(sort?.[key]);

        const label = t(labelKey);

        return (
          <th
            key={key}
            className={cx(
              sortableClassName,
              actionableClassName,
              numericClassName,
            )}
            onClick={() => {
              if (!isSortable || isTableEmpty) return;
              onSortableFieldClick(key);
            }}
          >
            <span title={label}>{label}</span>
            {isSortable && (
              <div className={cx(styles.sortIconsWrapper, sortIconClassName)}>
                <Triangle />
                <Triangle />
              </div>
            )}
          </th>
        );
      }),
    [
      tableColumns,
      getSortIconClassName,
      sort,
      t,
      isTableEmpty,
      onSortableFieldClick,
    ],
  );

  const tableRows: JSX.Element[] = useMemo(
    () =>
      items.map((item) => (
        <TableRow
          key={item.id}
          item={item}
          tableColumns={tableColumns}
          isSubTable={isSubTable}
          getActionMenuOptions={getActionMenuOptions}
          forwardedRef={contentRef}
        />
      )),
    [items, isSubTable, tableColumns, getActionMenuOptions],
  );

  const getContentScrollBarWidth = (): number => {
    const element = (contentRef.current || {}) as HTMLElement;

    if (!element) return 0;

    const resultWidth = element.offsetWidth - element.clientWidth;

    if (isNaN(resultWidth)) return 0;

    return resultWidth;
  };

  const contentStyle: CSSProperties = {
    width: isTableScrollable
      ? `calc(100% + ${getContentScrollBarWidth()}px`
      : "100%",
  };

  if (isSubTable) return <>{tableRows}</>;

  return (
    <div
      className={cx(
        styles.wrapper,
        isTableShort ? styles.shortTableWrapper : "",
      )}
      style={{
        height: isTableExpandable ? "100%" : "auto",
      }}
    >
      {isMobileView && !isTableEmpty && (
        <div
          className={styles.tableCardsWrapper}
          style={contentStyle}
          ref={contentRef}
        >
          {items.map((item) => (
            <TableCard
              key={item.id}
              item={item}
              tableColumns={tableColumns}
              getActionMenuOptions={getActionMenuOptions}
              forwardedRef={contentRef}
            />
          ))}
        </div>
      )}
      {!isMobileView && (
        <table
          className={cx(
            styles.tableWrapper,
            isTableScrollable ? styles.scrollableTable : "",
            isSubTable ? styles.subTable : "",
          )}
        >
          {!isSubTable && (
            <thead
              className={cx(
                styles.tableHeaderWrapper,
                isTableScrollable ? styles.scrollableTableHeaderWrapper : "",
                isTableShort ? styles.shortTableHeaderWrapper : "",
              )}
            >
              <tr>{tableHeaders}</tr>
            </thead>
          )}
          {!!tableRows.length && (
            <tbody
              className={cx(
                styles.tableBodyWrapper,
                isTableScrollable ? styles.scrollableTableBodyWrapper : "",
                isTableShort ? styles.shortTableBodyWrapper : "",
                isTableEmpty && isTableShort
                  ? styles.tableBodyWrapperEmpty
                  : "",
              )}
              style={contentStyle}
              ref={contentRef}
            >
              {tableRows}
            </tbody>
          )}
        </table>
      )}
      {isTableEmpty && !isSubTable && (
        <div className={styles.emptyTableWrapper}>
          <EmptyTableIcon />
          <p className={styles.emptyTableText}>
            {emptyTableLabel || t("table_empty_label")}
          </p>
        </div>
      )}
      {isPaginationVisible && (
        <div
          className={cx(
            styles.tablePaginationWrapper,
            isTableShort ? styles.shortTablePaginationWrapper : "",
          )}
        >
          <div className={styles.itemsPerPage}>
            {t("table_rows_per_page")}
            <Select
              options={TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS}
              value={itemsPerPage.toString()}
              changeHandler={(value) => {
                const newItemsPerPage = +value;

                if (isNaN(newItemsPerPage)) {
                  return setItemsPerPage(PAGINATION_MIN_ITEMS_COUNT);
                }

                setPageNumber(0);
                setItemsPerPage(newItemsPerPage);
              }}
              className={styles.selectPerPage}
              inputClassName={styles.selectInputPerPage}
            />
          </div>
          <Pagination
            previousLabel={
              <ChevronLeft size={TABLE_PAGINATION_NAVIGATION_ICON_SIZE} />
            }
            nextLabel={
              <ChevronRight size={TABLE_PAGINATION_NAVIGATION_ICON_SIZE} />
            }
            breakLabel={TABLE_PAGINATION_BREAK_LABEL}
            pageCount={pagesCount}
            marginPagesDisplayed={TABLE_PAGINATION_CONFIG.marginPagesDisplayed}
            pageRangeDisplayed={TABLE_PAGINATION_CONFIG.pageRangeDisplayed}
            forcePage={pageNumber}
            onPageChange={onPageChange}
            containerClassName={styles.paginationContainer}
            activeLinkClassName={styles.activeLink}
            disabledClassName={styles.disabledLink}
          />
          <div className={styles.itemsCount}>
            {t("table_pagination_indexes", {
              firstItemIndex: firstItemIndex + 1,
              lastItemIndex,
              totalItemsCount,
            })}
          </div>
        </div>
      )}
    </div>
  );
};
