import { FC, useContext } from "react";

import styles from "./ModalWrapper.module.scss";
import { Close } from "../../icons";
import context from "../../context";

export const ModalWrapper: FC = ({ children }) => {
  const { setModalElement, isWithCloseButton } = useContext(context);

  return (
    <div className={styles.modalWrapper} id="modal-wrapper">
      <div className={styles.childrenWrapper}>
        {children}
        {isWithCloseButton && (
          <button
            className={styles.closeButton}
            onClick={() => setModalElement(null)}
          >
            <Close size={20} />
          </button>
        )}
      </div>
    </div>
  );
};
