import { Words, DataForTagCloud } from "../types";

export const filterBrandExcludedWords = (
  topKeywords?: Words,
  topKeywordsExcludedWords?: string[],
): Words => {
  if (!topKeywords) return [];
  if (!topKeywordsExcludedWords) return topKeywords;

  return topKeywords.filter(({ keyword }) => {
    if (!keyword) return;
    return !topKeywordsExcludedWords.includes(keyword.toLowerCase());
  });
};

export const formatWordsForWidget = (
  type: "volume" | "trend",
  words?: TopKeywords.TopWords | TopKeywords.TrendingWords,
  currentBrandId?: string,
  brandExcludedWords?: string[],
) => {
  const formattedArr: DataForTagCloud = [];

  if (!currentBrandId) return formattedArr;

  const values = words?.values?.[currentBrandId];

  if (values) {
    values.forEach((element: any) => {
      const keyWord = element?.keyword;

      let isWordExcluded = false;

      if (brandExcludedWords) {
        isWordExcluded = brandExcludedWords.some((excludedWord: string) => {
          if (typeof keyWord !== "string") return false;
          return excludedWord.toLowerCase() === keyWord.toLowerCase();
        });
      }

      if (
        element.keyword &&
        element[type] !== undefined &&
        element[type] !== null &&
        !isWordExcluded
      ) {
        formattedArr.push({
          value: element.keyword,
          count: element[type],
          isPercentage: type === "trend",
        });
      }
    });
  }

  return formattedArr;
};

export const checkIfDateSuit = (valueDate: Date, startDate: Date): boolean => {
  const valueDateMonth = valueDate.getMonth();
  const valueDateYear = valueDate.getFullYear();
  const startDateMonth = startDate?.getMonth();
  const startDateYear = startDate?.getFullYear();

  return valueDateMonth === startDateMonth && valueDateYear === startDateYear;
};
