import React, { forwardRef } from "react";
import classes from "./Input.module.scss";
import cx from "classnames";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  maxLength?: React.InputHTMLAttributes<HTMLInputElement>["maxLength"];
  value?: string;
  changeHandler?: (value: string) => void;
  blurHandler?: (value: string) => void;
  placeholder?: string;
  isEnabled?: boolean;
  isReadOnly?: boolean;
  title?: string;
  tabIndex?: number;
  isAutoComplete?: boolean;
}

export const Input = forwardRef(
  (
    {
      className = "",
      style,
      type = "text",
      maxLength = 120,
      value = "",
      changeHandler = () => "",
      blurHandler = () => "",
      placeholder = "Write here...",
      isEnabled = true,
      isReadOnly,
      title,
      tabIndex,
      isAutoComplete = true,
    }: IProps,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <div
      className={cx(classes.inputWrapper, className)}
      style={style}
      title={title || ""}
      tabIndex={-1}
    >
      <input
        tabIndex={tabIndex}
        ref={ref}
        style={isEnabled ? undefined : { cursor: "not-allowed" }}
        type={type}
        maxLength={maxLength}
        value={value}
        onChange={(e) => changeHandler(e.target.value)}
        onBlur={(e) => blurHandler(e.target.value)}
        placeholder={placeholder}
        disabled={!isEnabled}
        readOnly={isReadOnly}
        autoComplete={isAutoComplete ? "" : "new-password"}
      />
    </div>
  ),
);
