import { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useImmer } from "use-immer";
import { set, update } from "lodash";
import { BiSearch as Search } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import cx from "classnames";

import styles from "./SentimentDriversReviewData.module.scss";
import context from "../../../context";
import { ReviewDataHeader } from "../ReviewDataHeader";
import {
  fetchRelatedArticles,
  getSentimentDriversChangedParameters,
  setSentimentDriversParameters,
  recalculateWidgetsDependentOnExcludes,
  subscribeOnWidgetCalculationStatus,
  setWidgetStatus,
  getSentimentDriversWidget,
} from "../../../store/api";
import {
  setWidget,
  updateSearchExcludedSuggestionsByName,
} from "../../../store/actions";
import { capitalizeString, sendDataToGTM } from "../../../utils";
import { texts } from "../ReviewsDataTexts";
import { fetchSentimentDriverData } from "./utils/fetchSentimentDriverData";
import { getPositiveNegativeAndNeutralWords } from "./utils/getPositiveNegativeAndNeutralWords";
import { WordsBlock } from "./components/WordsBlock/WordsBlock";
import { showToastNotification } from "../../ToastNotification/utils";

import { Input, Label, SearchTable, Preloader, KeyWordButton } from "../..";
import { useWidgetCalculatingStatus } from "../../../hooks/useWidgetCalculatingStatus";
import { getPlug } from "../utils";
import { MENTIONS_WORDS_QUANTITY_LIMIT } from "../../../data/additionalConstants";

// Inner import
import {
  WIDGET_ID,
  ARTICLES_PARAMETERS,
  ARTICLES_LIMIT,
  ABOUT_THIS,
  REVIEW_DATA_CATEGORY,
} from "./constants";
import type {
  Props,
  GotArticle,
  Article,
  Articles,
  ChangedParameters,
  Data,
} from "./types";

export const SentimentDriversReviewData = ({
  initialSearch,
  openedFromSideBar = false,
}: Props) => {
  const { t } = useTranslation();

  const widget = useSelector(
    ({ widgetsData }: RootState) => widgetsData.entities[WIDGET_ID],
  );

  const TITLE =
    t(texts[WIDGET_ID]?.customWidgetName || "") || t(widget?.name || "") || "";

  const store = useStore();
  const dispatch = useDispatch();
  const { setIsGlobalPreloaderShown } = useContext(context);

  const notifyAboutError = () =>
    showToastNotification({ type: "error", text: t("request_error") });

  const {
    widgets,
    searches,
  }: {
    widgets: {
      [searchId: string]: Store.Widgets;
    };
    searches: Store.Searches;
  } = store.getState();

  const [data, setData] = useImmer<Data>({
    status: "loading",
    searches: {},
  });

  const [mentions, setMentions] = useImmer<{
    status: "loading" | "succeeded" | "failed";
    canLoadMore: boolean;
    data: {
      [brandId: string]: Articles;
    };
  }>({
    status: "loading",
    canLoadMore: true,
    data: {},
  });

  const [excludedData, updateExcludedData] = useImmer<{
    [brandId: string]: {
      excludedWords: string[];
      excludedArticles: {
        id: string;
        title: string;
      }[];
    };
  }>({});

  const [
    changedParameters,
    updateChangedParameters,
  ] = useImmer<ChangedParameters>({});

  const [
    initialParameters,
    updateInitialParameters,
  ] = useImmer<ChangedParameters>({});

  const [initialExcludedData, setInitialExcludedData] = useState<{
    [brandId: string]: {
      excludedWords: string[];
      excludedArticles: {
        id: string;
        title: string;
      }[];
    };
  }>({});

  const [currentSearchId, setCurrentSearchId] = useState(initialSearch.id);
  const [searchDataLoaded, setSearchDataLoaded] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [articlesParameter, setArticlesParameter] = useState<
    typeof ARTICLES_PARAMETERS[number]
  >("all");
  const [
    currentCheckedBrand,
    setCurrentCheckedBrand,
  ] = useState<Store.Brand | null>(null);
  const [articlesOffset, setArticlesOffset] = useState(0);
  const [switchWarningIsShown, setSwitchWarningIsShown] = useState(false);

  const [isWidgetCalculating] = useWidgetCalculatingStatus(currentSearchId, [
    WIDGET_ID,
  ]);

  const searchBrands = useMemo(
    () =>
      searches.find(({ id }) => id === currentSearchId)?.brandsTableNames || [],
    [searches, currentSearchId],
  );

  const currentSearch = useMemo(() => {
    return searches.find((el) => el.id === currentSearchId);
  }, [searches, currentSearchId]);

  const parameterDropdownOptions = ARTICLES_PARAMETERS.filter(
    (parameter) => parameter !== "all",
  ).map((filteredOption) => ({
    label: capitalizeString(filteredOption),
    value: filteredOption,
  }));

  const noData =
    data.status === "succeeded" &&
    !Object.values(data.searches[currentSearchId || ""]?.chartsData || {})
      .length;

  const dataHaveNotChanged = checkIfInitialStateEqualCurrentState(
    initialExcludedData,
    excludedData,
  );

  const parametersHaveNotChanged = checkIfInitialStateEqualCurrentState(
    initialParameters,
    changedParameters,
  );

  const getParameters = useCallback(
    async (currentSearchId) => {
      const parameters =
        (await getSentimentDriversChangedParameters(currentSearchId)) || {};
      updateChangedParameters((diff) => {
        set(diff, [currentSearchId], parameters);
      });

      updateInitialParameters((diff) => {
        set(diff, [currentSearchId], parameters);
      });
    },
    [updateChangedParameters, updateInitialParameters],
  );

  useEffect(() => {
    if (changedParameters[currentSearchId]) return;
    (async () => {
      await getParameters(currentSearchId);
    })();
  }, [changedParameters, currentSearchId, getParameters]);

  const widgetData = useMemo(() => {
    if (data.status !== "succeeded" || !currentSearchId) return;
    return data.searches?.[currentSearchId];
  }, [data, currentSearchId]);

  const currentBrandId = useMemo(() => {
    if (!currentCheckedBrand) return;
    return currentCheckedBrand.id;
  }, [currentCheckedBrand]);

  const currentBrandName = useMemo(() => {
    if (!currentCheckedBrand) return;
    return currentCheckedBrand.name;
  }, [currentCheckedBrand]);

  const currentBrandExcludedData = useMemo(() => {
    const DATA = {
      excludedWords: [],
      excludedArticles: [],
    };
    if (!currentBrandId) return DATA;
    return excludedData[currentBrandId] || DATA;
  }, [excludedData, currentBrandId]);

  const positiveNegativeAndNeutralWords = useMemo(() => {
    const DEFAULT_DATA = {
      positive: [],
      neutral: [],
      negative: [],
    };

    if (!widgetData || !widgetData.chartsData) return DEFAULT_DATA;

    const { chartsData } = widgetData;

    const currentBrandData = chartsData?.[currentBrandId || ""];

    if (!currentBrandData?.length) return DEFAULT_DATA;

    return getPositiveNegativeAndNeutralWords(currentBrandData);
  }, [widgetData, currentBrandId]);

  const positiveWords = useMemo(() => {
    return positiveNegativeAndNeutralWords.positive.filter(
      (word) => !currentBrandExcludedData.excludedWords.includes(word),
    );
  }, [positiveNegativeAndNeutralWords, currentBrandExcludedData]);

  const neutralWords = useMemo(() => {
    return positiveNegativeAndNeutralWords.neutral.filter(
      (word) => !currentBrandExcludedData.excludedWords.includes(word),
    );
  }, [positiveNegativeAndNeutralWords, currentBrandExcludedData]);

  const negativeWords = useMemo(() => {
    return positiveNegativeAndNeutralWords.negative.filter(
      (word) => !currentBrandExcludedData.excludedWords.includes(word),
    );
  }, [positiveNegativeAndNeutralWords, currentBrandExcludedData]);

  const formatArticles = useCallback(() => {
    const articles = mentions.data?.[currentBrandId || ""];
    if (!articles) return [];
    return articles.map((el) => {
      const changedParameter =
        changedParameters[currentSearchId]?.[currentBrandId || ""]?.[el.id]
          ?.parameter;

      return {
        ...el,
        date: formatDistanceToNow(Date.parse(el.date), {
          addSuffix: true,
        }),
        parameter: changedParameter || el.parameter,
      };
    });
  }, [changedParameters, currentBrandId, currentSearchId, mentions.data]);

  const articles = useMemo(() => {
    const articles = formatArticles();

    const { excludedArticles, excludedWords } = currentBrandExcludedData;
    if (!articles.length) return [];

    const withoutExcludedArticles = filterArticles(articles).removeExcluded(
      excludedArticles,
    );

    const withoutExcludedWords = filterArticles(
      withoutExcludedArticles,
    ).removeNotSuitableByExcludedWord(excludedWords);

    const filteredByParameter = filterArticles(
      withoutExcludedWords,
    ).removeNotSuitableByParameter(articlesParameter);

    const filteredByFilterValue = filterArticles(
      filteredByParameter,
    ).removeNotSuitableByFilterInput(filterValue);

    return filteredByFilterValue;
  }, [
    currentBrandExcludedData,
    filterValue,
    articlesParameter,
    formatArticles,
  ]);

  const initialExcludedWords = useMemo(() => {
    return (
      currentSearch?.suggestions[currentBrandName || ""]
        ?.aiTrainerExcludedWords || []
    );
  }, [currentBrandName, currentSearch?.suggestions]);

  const initialExcludedArticles = useMemo(() => {
    return (
      currentSearch?.suggestions[currentBrandName || ""]?.excludedArticles || []
    );
  }, [currentBrandName, currentSearch?.suggestions]);

  const companyId = useMemo(() => {
    const { company } = store.getState();
    return company.id;
  }, [store]);

  const isCanExcludeWord = useMemo(() => {
    const { exclude = [], include = [] } =
      currentSearch?.suggestions[currentBrandName || ""] || {};

    const allWords = [
      ...exclude,
      ...include,
      ...currentBrandExcludedData.excludedWords,
    ];

    return MENTIONS_WORDS_QUANTITY_LIMIT > allWords.length;
  }, [
    currentBrandExcludedData.excludedWords,
    currentBrandName,
    currentSearch?.suggestions,
  ]);

  useEffect(() => {
    // Every time when user will be change brand, this useEffect will run
    // This useEffect give us a possibility write data (which we need for work) about checked search
    if (!currentSearchId) return;

    const searchIsInState = data.searches?.[currentSearchId];
    const searchWidgets: Store.Widgets | undefined = widgets[currentSearchId];
    const widget = searchWidgets && searchWidgets[WIDGET_ID];

    setSearchDataLoaded(false);

    if (searchIsInState) {
      // If search is already been added to state
      setData((diff) => ({
        ...diff,
        status: "succeeded",
      }));
      return setSearchDataLoaded(true);
    }

    if (widget) {
      // If we have search data in redux it will be taken and pushed to main data state
      setData((diff) => ({
        status: "succeeded",
        searches: {
          ...diff.searches,
          [currentSearchId]: widget,
        },
      }));
      return setSearchDataLoaded(true);
    }

    // If we have not data already written to redux it will be taken from API
    (async () => {
      try {
        const sentimentDriverData = await fetchSentimentDriverData(
          currentSearchId,
          searchBrands,
        );

        const { brands, chartsData } = sentimentDriverData;

        setData((diff) => ({
          status: "succeeded",
          searches: {
            ...diff.searches,
            [currentSearchId]: {
              brands,
              chartsData,
            },
          },
        }));
      } catch (err) {
        console.error(err);
        setData((diff) => ({
          ...diff,
          status: "failed",
        }));
      }
      setSearchDataLoaded(true);
    })();
  }, [searchBrands, currentSearchId, data.searches, setData, widgets]);

  useEffect(() => {
    if (!currentBrandId || data.status === "loading") return;
    const isBrandAddedToImmer = mentions.data[currentBrandId];

    if (!isBrandAddedToImmer) {
      (async () => {
        await loadArticles({
          currentBrandId,
          excludedWords: initialExcludedWords,
          excludedArticles: initialExcludedArticles.map((el) => el.id),
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.status,
    currentBrandId,
    initialExcludedArticles,
    initialExcludedWords,
    mentions.data,
  ]);

  useEffect(() => {
    const brandNotInList = !excludedData[currentCheckedBrand?.id || ""];
    const initialExcludedWordsIsEmpty = !initialExcludedWords.length;
    const initialExcludedArticlesIsEmpty = !initialExcludedArticles.length;

    if (!initialExcludedWordsIsEmpty || !initialExcludedArticlesIsEmpty) {
      brandNotInList && writeBrandToExcludedList(currentCheckedBrand?.id || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCheckedBrand,
    initialExcludedArticles.length,
    initialExcludedWords.length,
  ]);

  function addeArticleToExcluded(articleId: string) {
    if (!currentBrandId) return;
    const brandInList = !!excludedData[currentBrandId];

    const article = mentions.data[currentBrandId]?.find(
      (article) => article.id === articleId,
    );
    if (!article) return;
    const { id, text } = article;

    if (brandInList) {
      updateExcludedData((diff) =>
        update(
          diff,
          [currentBrandId, "excludedArticles"],
          (excludedArticles) => [...excludedArticles, { id, title: text }],
        ),
      );
    } else {
      updateExcludedData((diff) => ({
        ...diff,
        [currentBrandId]: {
          excludedWords: diff[currentBrandId]?.excludedWords || [],
          excludedArticles: [{ id, title: text }],
        },
      }));
    }
  }

  function removeArticleFromExcluded(id: string) {
    if (!currentBrandId) return;

    updateExcludedData((diff) =>
      update(diff, [currentBrandId, "excludedArticles"], (excludedArticles) => [
        ...excludedArticles.filter(
          (el: { id: string; title: string }) => el.id !== id,
        ),
      ]),
    );
  }

  function addWordToExcludedList(word: string) {
    if (!isCanExcludeWord) {
      return showToastNotification({
        type: "warning",
        text: t("dc_data_refine_limit_reached"),
      });
    }

    if (!currentBrandId) return;
    const brandInList = !!excludedData[currentBrandId];

    if (brandInList) {
      updateExcludedData((diff) =>
        update(diff, [currentBrandId, "excludedWords"], (excludedWords) => [
          ...excludedWords,
          word,
        ]),
      );
    } else {
      updateExcludedData((diff) => ({
        ...diff,
        [currentBrandId]: {
          excludedArticles: diff[currentBrandId]?.excludedArticles || [],
          excludedWords: [word],
        },
      }));
    }
  }

  function removeWordFromExcludedList(word: string) {
    if (!currentBrandId) return;
    updateExcludedData((diff) =>
      update(diff, [currentBrandId, "excludedWords"], (excludedWords) => [
        ...excludedWords.filter((el: string) => el !== word),
      ]),
    );
  }

  async function loadMoreArticlesHandler() {
    if (!currentBrandId) return;
    const offset = articlesOffset + ARTICLES_LIMIT;
    const getExcludedArticles = excludedData[
      currentBrandId
    ]?.excludedArticles.map((el) => el.id);
    const getExcludedWords = excludedData[
      currentBrandId
    ]?.excludedWords?.map((el) => el.toLowerCase());
    loadArticles({
      currentBrandId,
      offset,
      excludedArticles: getExcludedArticles || [],
      excludedWords: getExcludedWords || [],
    });
    setArticlesOffset(offset);
  }

  function resetChanges() {
    if (!currentBrandId) return;
    updateExcludedData(() => initialExcludedData);
    updateChangedParameters(() => initialParameters);
  }

  const [temporarySearchId, setTemporarySearchId] = useState("");

  function handleSearchChange(value: string, forceChange: boolean = false) {
    if (currentSearchId === value) return;
    if (!dataHaveNotChanged && !forceChange) {
      setTemporarySearchId(value);
      return setSwitchWarningIsShown(true);
    }
    if (forceChange) {
      setSwitchWarningIsShown(false);
    }

    setData((diff) => ({
      ...diff,
      status: "loading",
    }));

    updateChangedParameters((diff) => ({
      ...diff,
      [currentSearchId]: {
        ...initialParameters[currentSearchId],
      },
    }));
    setCurrentSearchId(value);
    updateExcludedData(() => ({}));
    setInitialExcludedData(() => ({}));
  }

  function handleBrandChange(brand: Store.Brand) {
    setCurrentCheckedBrand(brand);
  }

  function writeBrandToExcludedList(brandId: string) {
    setInitialExcludedData((state) => ({
      ...state,
      [brandId]: {
        excludedWords: initialExcludedWords,
        excludedArticles: initialExcludedArticles,
      },
    }));

    updateExcludedData((diff) =>
      set(diff, [brandId], {
        excludedWords: diff[brandId]?.excludedWords ?? initialExcludedWords,
        excludedArticles:
          diff[brandId]?.excludedArticles ?? initialExcludedArticles,
      }),
    );
  }

  async function onExcludeClicked() {
    if (!currentSearch) return;

    sendDataToGTM("UserUsesReviewData", {
      reviewDataCategory: REVIEW_DATA_CATEGORY,
    });

    const { id } = currentSearch;

    setIsGlobalPreloaderShown(true);

    if (!parametersHaveNotChanged) {
      const parameters = changedParameters[currentSearchId];
      if (parameters) {
        await setSentimentDriversParameters(currentSearchId, parameters);
        if (dataHaveNotChanged) {
          updateInitialParameters(() => changedParameters);
          setIsGlobalPreloaderShown(false);
        }
      }
    }

    if (companyId) {
      const preparedDataForFetch = Object.entries(excludedData).map(
        ([brandId, data]) => {
          const { excludedWords, excludedArticles } = data;
          const brandName = searchBrands.find(({ id }) => id === brandId)?.name;

          return new Promise((resolve, reject) => {
            try {
              resolve(
                dispatch(
                  updateSearchExcludedSuggestionsByName(
                    id,
                    brandId,
                    brandName || "",
                    excludedWords,
                    excludedArticles,
                  ),
                ),
              );
            } catch (error) {
              reject(error);
            }
          });
        },
      );

      try {
        await Promise.all(preparedDataForFetch);
        await setWidgetStatus(currentSearch.id, [WIDGET_ID]);
        recalculateWidgetsDependentOnExcludes(companyId, id);

        const stopSearchWatcher = subscribeOnWidgetCalculationStatus(
          currentSearch.id,
          [WIDGET_ID],
          (isAllWidgetsFinishCalculation) => {
            if (isAllWidgetsFinishCalculation) {
              setInitialExcludedData(excludedData);
              updateInitialParameters(() => changedParameters);
              (async () => {
                await fetchAndSetNewMentionWidgetData(
                  currentSearch.id,
                  searchBrands,
                );
                showToastNotification({
                  type: "success",
                  text: t("rd_recalculation_ended_message_new"),
                });
                stopSearchWatcher();
              })();
            }
          },
        );
      } catch (err) {
        notifyAboutError();
      }
    }
    setIsGlobalPreloaderShown(false);
  }

  function parameterChangeHandler(value: string, fieldId?: string) {
    if (!fieldId || !currentBrandId) return notifyAboutError();

    const gotArticlesData = mentions.data[currentBrandId]?.find(
      ({ id }) => id === fieldId,
    );

    const { sentence = "", parameter = "", date = "" } = gotArticlesData || {};

    const checkIfParameterAddedToInitialData =
      initialParameters[currentSearchId]?.[currentBrandId]?.[fieldId] !==
      undefined;

    if (!checkIfParameterAddedToInitialData) {
      updateInitialParameters((diff) => {
        update(diff, [currentSearchId, currentBrandId], (parameters) => {
          const gotSentence = parameters?.[fieldId]?.sentence ?? sentence;
          return {
            ...(parameters || {}),
            [fieldId]: {
              parameter,
              sentence: gotSentence,
              date,
            },
          };
        });
      });
    }

    updateChangedParameters((diff) => {
      update(diff, [currentSearchId, currentBrandId], (parameters) => {
        const gotSentence = parameters?.[fieldId]?.sentence ?? sentence;
        return {
          ...(parameters || {}),
          [fieldId]: {
            parameter: value,
            sentence: gotSentence,
            date,
          },
        };
      });
    });
  }

  async function loadArticles({
    currentBrandId,
    offset,
    excludedArticles,
    excludedWords,
  }: {
    currentBrandId: string;
    offset?: number;
    excludedArticles: string[];
    excludedWords: string[];
  }) {
    setMentions((diff) => ({
      ...diff,
      status: "loading",
      data: {
        ...diff.data,
        [currentBrandId]: [],
      },
    }));

    function stringArrayToString(arr: string[]) {
      const arrayWithCapitalizedString = arr.map((el) => capitalizeString(el));

      return arrayWithCapitalizedString.join(", ");
    }

    const relatedWords = Object.entries(positiveNegativeAndNeutralWords).reduce(
      (acc, item) => {
        const words = item[1].map((el) => el.toLowerCase());
        return [...acc, ...words];
      },
      [] as string[],
    );

    const fetchData: {
      table: string;
      brandId: string;
      excludedArticles: string[];
      relatedWords: string[];
      excludedWords: string[];
      limit?: number;
      offset?: number;
    } = {
      table: currentBrandId,
      brandId: currentSearchId,
      excludedArticles,
      relatedWords,
      excludedWords,
      limit: ARTICLES_LIMIT,
      offset: offset ?? 0,
    };

    try {
      const gotData = (await fetchRelatedArticles(fetchData))
        .data as GotArticle[];

      const articles = gotData.map((el) => ({
        ...el,
        words: stringArrayToString(el.words),
        text: el.title,
        link: `<a href='${el.url}' target="_blank">${el.title}</a>`,
        date: el.date,
      }));

      setMentions((diff) => ({
        status: "succeeded",
        canLoadMore: articles.length >= ARTICLES_LIMIT,
        data: {
          ...diff.data,
          [currentBrandId]: [
            ...(diff.data?.[currentBrandId]
              ? (diff.data?.[currentBrandId] as Articles)
              : []),
            ...articles,
          ],
        },
      }));
    } catch (error) {
      setMentions((diff) => ({
        ...diff,
        status: "failed",
      }));
    }
  }

  // Helpers
  function filterArticles(articles: Article[]) {
    function removeExcluded(excludedArticles: { id: string; title: string }[]) {
      return articles.filter(
        (article) =>
          !excludedArticles.find((articleId) => articleId.id === article.id),
      );
    }

    function removeNotSuitableByExcludedWord(excludedWords: string[]) {
      return articles.filter((article) => {
        // If you exclude keyWord and the article has this word in words array it'll be hidden
        const articleArrayToLowerCase = article.words.toLowerCase().split(", ");
        const excludedWordsToLowerCase = excludedWords.map((el) =>
          el.toLowerCase(),
        );
        return !articleArrayToLowerCase.every((el) =>
          excludedWordsToLowerCase.includes(el),
        );
      });
    }

    function removeNotSuitableByParameter(parameter: string) {
      if (parameter === "all") return articles;

      return articles.filter(({ parameter: articlesParameter }) => {
        return articlesParameter === parameter;
      });
    }

    function removeNotSuitableByFilterInput(filterValue: string) {
      if (!filterValue) return articles;

      return articles.filter((el) => {
        const isArticleHasFilteredWord = el.text
          .toLowerCase()
          .includes(filterValue.toLowerCase());
        const isWordsListHasFilteredWord = el.words
          .toLowerCase()
          .includes(filterValue.toLowerCase());

        return isArticleHasFilteredWord || isWordsListHasFilteredWord;
      });
    }

    return {
      removeExcluded,
      removeNotSuitableByExcludedWord,
      removeNotSuitableByParameter,
      removeNotSuitableByFilterInput,
    };
  }

  function checkIfInitialStateEqualCurrentState(
    initialState: any,
    currentState: any,
  ) {
    return JSON.stringify(initialState) === JSON.stringify(currentState);
  }

  async function fetchAndSetNewMentionWidgetData(
    searchId: string,
    brands: Store.Brands,
  ) {
    const sentimentDrivers = await getSentimentDriversWidget(searchId, brands);

    dispatch(setWidget(WIDGET_ID, sentimentDrivers, searchId));
  }

  // Renderers
  const Plug = useMemo(() => {
    const Plug = getPlug(data.status, noData, isWidgetCalculating);

    return Plug && <Plug />;
  }, [data.status, noData, isWidgetCalculating]);

  function renderInnerModal() {
    if (switchWarningIsShown) {
      return (
        <div className={styles.switchWarningWrapper}>
          <div className={styles.switchWarning}>
            <p>{t("rd_without_saving_modal_title")}</p>
            <div className={styles.switchWarningButtons}>
              <button onClick={() => setSwitchWarningIsShown(false)}>
                {t("rd_without_saving_modal_no_btn")}
              </button>
              <button
                onClick={() => handleSearchChange(temporarySearchId, true)}
              >
                {t("rd_without_saving_modal_yes_btn")}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.sentimentDriversReviewData}>
      <ReviewDataHeader
        className={styles.reviewData}
        currentSearchId={currentSearchId}
        widgetNameForTitle={TITLE}
        aboutThisText={t(ABOUT_THIS)}
        openedFromSideBar={openedFromSideBar}
        onSearchChangeHandler={handleSearchChange}
        onBrandChangeHandler={handleBrandChange}
        onResetHandler={() => resetChanges()}
      >
        {renderInnerModal()}
        {Plug ?? (
          <>
            <div className={styles.wordsWrapper}>
              <WordsBlock
                words={positiveWords}
                status={data.status}
                labelText={t("rd_positive_words")}
                noWordsText={t("rd_no_words_found")}
                onExcludeWordClicked={addWordToExcludedList}
              />
              <WordsBlock
                words={neutralWords}
                status={data.status}
                labelText={t("rd_neutral_words")}
                noWordsText={t("rd_no_words_found")}
                onExcludeWordClicked={addWordToExcludedList}
              />
              <WordsBlock
                words={negativeWords}
                status={data.status}
                labelText={t("rd_negative_words")}
                noWordsText={t("rd_no_words_found")}
                onExcludeWordClicked={addWordToExcludedList}
              />
            </div>
            <div className={styles.parameters}>
              <Label
                leftText={t("rd_esg_choose_parameter_label")}
                className={styles.boldLabel}
              />
              {ARTICLES_PARAMETERS.map((el) => (
                <button
                  key={el}
                  onClick={() => setArticlesParameter(el)}
                  className={cx(
                    styles.parameter,
                    articlesParameter === el ? styles.parameterActive : "",
                  )}
                >
                  {capitalizeString(el)}
                </button>
              ))}
            </div>
            <div className={styles.filter}>
              <Search size={24} />
              <Input
                value={filterValue}
                changeHandler={(value) => setFilterValue(value)}
                placeholder={t("rd_search_words_filter_placeholder")}
                className={styles.input}
              />
            </div>
            <SearchTable
              className={styles.searchTable}
              rows={articles}
              columns={[
                {
                  label: t("mentions_table_article_label"),
                  field: "link",
                  isExpandable: true,
                  isWrapped: true,
                  isSortable: true,
                },
                {
                  label: t("mentions_table_source_label"),
                  field: "source",
                  isSortable: true,
                  isSqueezed: true,
                },
                {
                  label: t("mentions_table_parameter_label"),
                  field: "parameter",
                  isSqueezed: true,
                  dropdown: {
                    isDropdownChecked: parameterChangeHandler,
                    options: parameterDropdownOptions,
                  },
                },
                {
                  label: t("mentions_table_words_label"),
                  field: "words",
                  maxWidth: 100,
                  isSqueezed: true,
                  isSortable: true,
                },
                {
                  label: t("mentions_table_date_label"),
                  field: "date",
                  isSortable: true,
                  isSqueezed: true,
                },
              ]}
              status={mentions.status}
              canLoadMore={mentions.canLoadMore}
              hasRowDeletion
              onRowDeleted={(id) => addeArticleToExcluded(id)}
              loadMoreHandler={() => loadMoreArticlesHandler()}
              noRowsText={t("rd_no_articles_found")}
            />
            <div className={styles.excluded}>
              <div className={styles.excludedWrapper}>
                <div className={styles.excludedArticles}>
                  <p className={styles.excludedArticlesPlaceHolder}>
                    {t("mentions_articles_to_exclude")}
                  </p>
                  <div className={styles.excludedArticlesWrapper}>
                    {excludedData[currentBrandId || ""]?.excludedArticles.map(
                      ({ id, title }) => (
                        <KeyWordButton
                          text={title}
                          id={id}
                          key={id}
                          clickHandler={({ id }) =>
                            removeArticleFromExcluded(id || "")
                          }
                          excluded={true}
                          className={styles.excludedItem}
                        />
                      ),
                    )}
                  </div>
                </div>
                <div className={styles.excludedWords}>
                  <p className={styles.excludedWordsPlaceHolder}>
                    {t("mentions_words_to_exclude")}
                  </p>
                  <div className={styles.excludedWordsWrapper}>
                    {excludedData[currentBrandId || ""]?.excludedWords.map(
                      (word, index) => (
                        <KeyWordButton
                          text={word}
                          id={word}
                          key={index}
                          clickHandler={({ text }) =>
                            removeWordFromExcludedList(text)
                          }
                          excluded={true}
                          className={styles.excludedItem}
                        />
                      ),
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={onExcludeClicked}
                disabled={dataHaveNotChanged && parametersHaveNotChanged}
              >
                {t("rd_exclude_button")}
              </button>
            </div>
            {!searchDataLoaded && (
              <Preloader className={styles.preloader} type="scale" />
            )}
          </>
        )}
      </ReviewDataHeader>
    </div>
  );
};
