import { Dispatch } from "react";

import { changeIsWidgetBrokenStatus } from "../store/actions";

const onErrorHandler = (
  chartSetter: (chartRef?: HTMLDivElement | undefined) => void,
  searchId: string,
  widgetId: WIDGET_IDS_TYPES,
  dispatch: Dispatch<any>,
) => {
  chartSetter(undefined);
  dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
};

const getFunnelDataBrands = (data?: Funnel.Data): string[] =>
  Object.values(data || {}).reduce((acc, brand) => {
    if (acc.length) return acc;

    if (brand?.brandValues.length)
      brand?.brandValues.forEach(({ brandName }) => acc.push(brandName));

    return acc;
  }, [] as string[]);

export { onErrorHandler, getFunnelDataBrands };
