import axios, { AxiosError } from "axios";

import {
  showDevelopmentError,
  getHttpResponseStatusType,
  sleep,
} from "src/utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const statusCode = error?.response?.status || 0;
    const statusType = getHttpResponseStatusType(statusCode);

    if (statusType !== "server error") throw error;

    const message = `[${statusCode}] SERVER ERROR`;

    return new Promise(async (resolve, reject) => {
      const maxCount = 3;
      const delay = 3000;

      let counter: number = 0;

      while (counter < maxCount) {
        await sleep(delay);

        try {
          const response = await axios(error?.config);
          resolve(response);
          return;
        } catch (error) {}

        counter++;
      }

      showDevelopmentError({
        additionalTexts: [message],
        error,
        clickUpProps: {
          name: message,
          text_content: {
            error,
          },
        },
      });

      reject(error);
    });
  },
);

export default axiosInstance;
