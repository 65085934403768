import { CSSProperties, useState, useMemo, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";

import styles from "./BarChart.module.scss";

interface Props {
  className?: string;
  style?: CSSProperties;
  color?: {
    border?: string;
    background?: string;
    label?: string;
  };
  data?: {
    label: string;
    value: number;
    // description?: string;
  }[];
}

export const BarChart = ({
  className = "",
  style,
  color,
  data = [],
}: Props) => {
  const { t } = useTranslation();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    let _isMounted = true;

    setTimeout(() => {
      if (_isMounted) setIsMounted(true);
    }, 10);

    return () => void (_isMounted = false);
  }, []);

  const modifiedData = useMemo(
    () =>
      data
        .map((item) => ({ ...item, value: +item.value.toFixed() }))
        .sort((a, b) => b.value - a.value),
    [data],
  );

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = Infinity,
  } = useResizeObserver<HTMLDivElement>();

  return modifiedData.length ? (
    <div
      ref={chartWrapperRef}
      className={`${styles.barChart} ${className}`}
      style={style}
    >
      <div className={styles.columns}>
        {modifiedData.map(({ label, value }, i) => (
          <div
            className={styles.column}
            style={{
              borderColor: color?.border,
            }}
            key={i}
          >
            <div
              className={styles.indicator}
              style={{
                height: (isMounted ? value : 0) + "%",
                background: color?.background,
              }}
            >
              <div className={styles.value}>{value}%</div>
            </div>
            <div className={styles.labelWrapper}>
              <div
                className={styles.label}
                style={{
                  color: color?.label,
                  transform: `rotate(${chartWrapperWidth > 576 ? 0 : -15}deg)`,
                }}
              >
                {t(label)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
