export const getMaxNumberOfDigitsAfterDecimal = (numbers: number[]): number =>
  numbers.reduce((acc, number) => {
    const numberOfDigitsAfterDecimal = getNumberOfDigitsAfterDecimal(number);

    if (numberOfDigitsAfterDecimal > acc) return numberOfDigitsAfterDecimal;

    return acc;
  }, 0);

function getNumberOfDigitsAfterDecimal(number: number): number {
  if (Number.isInteger(number)) return 0;

  return number.toString().split(".")[1]?.length || 0;
}
