const WIDGET_ID = "result-volume-share";

// NOTE: Share of Volume widget shares data with Share of Search widget (result-search-share collection)
const WIDGET_COLLECTION_ID = "result-search-share";

const SHARE_OF_VOLUME_CHANGE_DATA_POINTS_MIN_COUNT = 2 as const;

export {
  WIDGET_ID,
  WIDGET_COLLECTION_ID,
  SHARE_OF_VOLUME_CHANGE_DATA_POINTS_MIN_COUNT,
};
