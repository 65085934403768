import { useMemo, FC } from "react";

import styles from "./ShareOfSearchPreview.module.scss";
import {
  Preloader,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { ONE_YEAR, NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";
import { useWidgetFetching } from "../../hooks";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner import
import { WIDGET_ID } from "./constants";
import {
  SummaryTimePeriod,
  SummaryYearlyAverage,
} from "../../descriptionInsertionComponents";
import { WidgetSummaryProps } from "../../types";

export const ShareOfSearchSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};
    const { name: mainBrandName = "" } = brands[0] || {};

    return {
      lineChartData: lineChart,
      pieChartData: pieChart,
      brands,
      mainBrandName,
    };
  }, [widgetData]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const brandValues = useMemo<number[]>(
    () => filteredData.map(({ values }) => values[selectedBrand.name] || 0),
    [filteredData, selectedBrand],
  );

  const brandPieChartYearlyValue = useMemo<number>(
    () =>
      pieChartData[ONE_YEAR]?.values.find(
        ({ brandName }) => brandName === selectedBrand.name,
      )?.value || 0,
    [selectedBrand, pieChartData],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={brandValues}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
        <SummaryYearlyAverage
          data={brandPieChartYearlyValue}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
      </div>
      <div className={styles.chartWrapper}>
        <LineChart
          className={styles.chart}
          labels={brands}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: 3,
          }}
          chartSettings={{
            hasTrendLine: true,
          }}
          axis={{
            yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
            yAxisVerticalPadding: { top: 10, bottom: 0 },
          }}
        />
      </div>
    </div>
  );
};
