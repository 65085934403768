import { Dispatch } from "react";
import {
  Folder,
  removeOneFolder,
  updateOneFolder,
} from "../../../store/folders/foldersSlice";

async function removeDashboardFromFolder(
  dashboardId: string,
  dispatch: Dispatch<any>,
  folder?: Folder,
) {
  if (!folder) throw Error("Folder not found");

  const { id: folderId } = folder;

  const newDashboards = folder.dashboardIds.filter((id) => id !== dashboardId);

  if (!newDashboards.length) {
    return dispatch(removeOneFolder(folderId));
  }

  return dispatch(
    updateOneFolder({
      id: folderId,
      changes: { dashboardIds: newDashboards },
    }),
  );
}

function getFolderById(folders: Folder[], folderId: string) {
  return folders.find(({ id }) => id === folderId);
}

export { removeDashboardFromFolder, getFolderById };
