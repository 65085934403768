import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import context from "../../../context";
import { verifyEmailByJWTToken, sendVerifyEmail } from "../../../store/api";
import { logInByToken } from "../../../store/actions";
import { sendDataToGTM } from "../../../utils";
import { showToastNotification } from "../../../components/ToastNotification/utils";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const param = useParams() as { userId?: string; token?: string };

  const { userId, token } = param;

  const { setIsGlobalPreloaderShown } = useContext(context);

  useEffect(() => {
    if (!userId || !token) {
      return history.push("/");
    }
    setIsGlobalPreloaderShown(true);
    (async () => {
      try {
        const userLogInToken = await verifyEmailByJWTToken(token);
        sendDataToGTM("UserVerifiedEmail");
        localStorage.setItem("isFirstLogin", "true");
        if (userLogInToken) {
          await userAutoLogin(userLogInToken);
        }
        showToastNotification({
          type: "success",
          text: t("email_confirmation_email_verified"),
        });
      } catch (err) {
        showWarningAndSendNewLink(err, userId);
      } finally {
        setIsGlobalPreloaderShown(false);
        history.push("/");
      }
    })();

    async function userAutoLogin(token: string) {
      try {
        await logInByToken(token);
      } catch (err) {
        console.error(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, token, userId, setIsGlobalPreloaderShown, t]);

  function showWarningAndSendNewLink(err: string, userId?: string) {
    if (!userId) {
      return showToastNotification({
        type: "warning",
        text: t("email_confirmation_error_bad_link"),
      });
    }

    const isUserNotFound = err.toLowerCase().includes("not found");

    const isUserAlreadyVerified = err === "User already verified";

    let warningText = t("email_confirmation_standard_error");

    if (err === "jwt expired") {
      warningText = t("email_confirmation_error_token_expired");
    }

    if (isUserNotFound) {
      warningText = t("email_confirmation_error_user_not_found");
    }

    if (isUserAlreadyVerified) {
      warningText = t("email_confirmation_error_already_verified");
    }

    showToastNotification({
      type: "warning",
      text: warningText,
    });

    !isUserNotFound && !isUserAlreadyVerified && sendVerifyEmail(userId);
  }

  return <div />;
};

export default VerifyEmail;
