const SELECT_INPUT_PLACEHOLDER = "select_input_placeholder";

const SElECT_FILTER_PLACEHOLDER = "select_filter_placeholder";

const SELECT_FILTER_MIN_ITEMS_COUNT = 5;

export {
  SELECT_INPUT_PLACEHOLDER,
  SElECT_FILTER_PLACEHOLDER,
  SELECT_FILTER_MIN_ITEMS_COUNT,
};
