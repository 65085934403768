import { texts } from "../ReviewsDataTexts";
import type { CheckBoxValueType } from "./types";

const WIDGET_ID = "result-top-trend-keywords";

const ABOUT_THIS = texts[WIDGET_ID]?.aboutThis || "";
const CUSTOM_TITLE = texts[WIDGET_ID]?.customWidgetName;

const CHECKBOX_OPTIONS: { label: string; value: CheckBoxValueType }[] = [
  {
    label: "rd_ckeckbox_only_brand",
    value: "only brand",
  },
  {
    label: "rd_checkbox_all_related",
    value: "all",
  },
];

const REVIEW_DATA_CATEGORY = "Top keywords review data";

export {
  WIDGET_ID,
  ABOUT_THIS,
  CUSTOM_TITLE,
  CHECKBOX_OPTIONS,
  REVIEW_DATA_CATEGORY,
};
