import { ComponentProps, FC } from "react";
import { Input, MenuDropdown } from "../../../components";

import styles from "./InputWithMenu.module.scss";

type Props = ComponentProps<typeof Input> & {
  menuOptions?: ComponentProps<typeof MenuDropdown>["options"];
};

export const InputWithMenu: FC<Props> = (props) => (
  <div className={styles.inputWithMenu}>
    <Input className={styles.inputWrapper} {...props} />
    {!!props.menuOptions?.length && (
      <MenuDropdown className={styles.dropDown} options={props.menuOptions} />
    )}
  </div>
);
