const ONE_YEAR = "oneYear";

const INDEX = "Index";

const NUMBER_MEASUREMENT_PERCENTAGE = "%";

const SUMMARY_WIDGET_INSERTION_TEXT = {
  "result-search-share": "tp_summary_sos_widget_insertion_text",
  "result-media-share": "tp_summary_som_widget_insertion_text",
  "result-attitude": "tp_summary_attitude_widget_insertion_text",
  "result-volume-share": "tp_summary_sov_widget_insertion_text",
  "result-search-trends": "tp_summary_search_trend_widget_insertion_text",
  "result-sentiment-index": "tp_summary_sentiment_index_widget_insertion_text",
  "result-sentiment-trends": "tp_summary_sentiment_trend_widget_insertion_text",
  "result-reputation-sentiment":
    "tp_summary_reputation_sentiment_widget_insertion_text",
  "result-reputation-index":
    "tp_summary_reputation_index_widget_insertion_text",
  "result-esg-sentiment": "tp_summary_esg_sentiment_widget_insertion_text",
  "result-esg-index": "tp_summary_esg_index_widget_insertion_text",
};

const SUMMARY_WIDGET_MEASUREMENT_UNIT = {
  "result-search-share": NUMBER_MEASUREMENT_PERCENTAGE,
  "result-media-share": NUMBER_MEASUREMENT_PERCENTAGE,
  "result-attitude": NUMBER_MEASUREMENT_PERCENTAGE,
  "result-volume-share": "",
  "result-search-trends": "",
  "result-sentiment-index": "",
  "result-sentiment-trends": "",
  "result-reputation-sentiment": "",
  "result-reputation-index": "",
  "result-esg-sentiment": "",
  "result-esg-index": "",
};

export {
  ONE_YEAR,
  INDEX,
  NUMBER_MEASUREMENT_PERCENTAGE,
  SUMMARY_WIDGET_INSERTION_TEXT,
  SUMMARY_WIDGET_MEASUREMENT_UNIT,
};
