import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./BusinessDriversPreview.module.scss";
import {
  Preloader,
  ChartButton,
  BarChart,
  ErrorPlaceholder,
} from "../../../components";
import businessDriversParameters from "../../../data/businessDriversParameters";

// Inner imports
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import { BusinessDriversLineChart } from "./components";

export const BusinessDriversPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { lineChartData, barChartData } = useMemo(() => {
    const { lineChart = [], barChart = {} } = widgetData?.[0]?.brandData || {};
    const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } = barChart;
    const values = (
      oneYear ||
      sixMonths ||
      threeMonths ||
      twoMonths ||
      oneMonth
    )?.values;

    return {
      lineChartData: lineChart,
      barChartData: businessDriversParameters.map(({ name }) => ({
        label: name,
        value: values?.[name] || 0,
      })),
    };
  }, [widgetData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (barChartData.length) {
      charts.push({
        type: "barChart",
        chart: <BarChart className={styles.chart} data={barChartData} />,
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <BusinessDriversLineChart
            data={lineChartData}
            searchId={searchId}
            dateRange="oneYear"
            viewMode="widgetPreview"
            dashboardId={dashboardId}
          />
        ),
      });
    }

    return charts;
  }, [barChartData, dashboardId, lineChartData, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
