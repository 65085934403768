const GREY_COLOR = "#707070" as const;

const SWITCHER_STYLES = {
  COLOR_OFF: GREY_COLOR,
  COLOR_ON: "#41B167",
  HANDLER_DIAMETER: 28,
  HEIGHT: 30,
  WIDTH: 50,
  ACTIVE_BOX_SHADOW: "none",
} as const;

const CATEGORY_TREND_LINE = {
  color: GREY_COLOR,
  label: "Trend",
} as const;

const TICK_STYLES = {
  fill: "currentColor",
  fontSize: 10,
  fontWeight: "bold",
  opacity: 0.75,
} as const;

export { GREY_COLOR, SWITCHER_STYLES, TICK_STYLES, CATEGORY_TREND_LINE };
