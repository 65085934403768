import { addOneDashboard } from "../store/dashboards/dashboardsSlice";
import { createDashboardGrid } from "../utils";

type Props = {
  searchId: string;
  dashboardName?: string;
  isPrivate?: boolean;
};

export const automaticallyGenerateWidgets = ({
  searchId,
  dashboardName = "",
  isPrivate = false,
}: Props) => async (dispatch: Function, getState: () => RootState) => {
  const state = getState();

  const search = state.searches.find(({ id }) => id === searchId);

  if (!search) {
    console.error(
      `Can't auto generate dashboard because search with ${searchId} not found`,
    );
    return;
  }

  const { name, brands } = search;

  const isSearchHasCompetitors = !!brands.competitors.length;

  const autoGenerateWidgetList = selectAvailableWidgetsIdsForAutoGeneration(
    state,
    isSearchHasCompetitors,
  );

  if (!autoGenerateWidgetList.length) {
    console.error(
      `Can't auto generate dashboard because any widget available for generation`,
    );
    return;
  }

  const tiles = autoGenerateWidgetList.reduce((acc, widgetId) => {
    acc[widgetId] = true;
    return acc;
  }, {} as { [widgetId: string]: true });

  const { payload } = await dispatch(
    addOneDashboard({
      name: dashboardName || name,
      isPrivate,
      tiles: {
        [searchId]: tiles,
      },
      layouts: {
        small: createDashboardGrid(searchId, autoGenerateWidgetList, "small"),
        medium: createDashboardGrid(searchId, autoGenerateWidgetList, "medium"),
        large: createDashboardGrid(searchId, autoGenerateWidgetList, "large"),
      },
      lastUsedSearchId: searchId,
    }),
  );

  return typeof payload.id === "string" ? payload.id : "";
};

export const selectAvailableWidgetsIdsForAutoGeneration = (
  state: RootState,
  isSearchHasCompetitors: boolean,
) => {
  const autoGenerateWidgetList: string[] = [];

  const { widgetsData, company, subscriptionPlans } = state;

  const subscriptionPlanId = company.subscriptionPlanId;

  const subscriptionPlanAvailableWidgets =
    subscriptionPlans.entities[subscriptionPlanId]?.availableWidgets || {};

  Object.values(widgetsData.entities).forEach((widget) => {
    if (!widget) return;

    const { id, isAvailableForOneBrand, isImmediatelyAvailable } = widget;

    const isWidgetAvailableInSP = Boolean(subscriptionPlanAvailableWidgets[id]);
    const _isAvailableForOneBrand =
      isSearchHasCompetitors || isAvailableForOneBrand;

    if (
      isImmediatelyAvailable &&
      isWidgetAvailableInSP &&
      _isAvailableForOneBrand
    ) {
      autoGenerateWidgetList.push(id);
    }
  });

  return autoGenerateWidgetList;
};
