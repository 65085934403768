import * as yup from "yup";
import {
  getSearches,
  postSearch,
  putSearch,
  deleteSearch,
  writeSearchKeyWords,
  writeSearchData,
} from "./api";
import TagManager from "react-gtm-module";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import actionTypes from "../actionTypes";
import {
  getSlugifiedString,
  getArrayOfUniqueObjectsByKey,
  showDevelopmentError,
} from "../../utils";
import { writeWidgetState } from "../actions";
import { searchSchema, searchBrandSchema } from "./schemas";

// MUTATIONS
export const setSearches = (searches: Store.Searches) => {
  return {
    type: actionTypes.SET_SEARCHES,
    searches,
  };
};

// ACTIONS
export const readSearches = (
  companyId: string,
): ThunkAction<Promise<Store.Searches>, Store.State, unknown, AnyAction> => {
  return async (dispatch: Function, getState: () => Store.State) => {
    const { user } = getState();
    const { id: userId } = user;

    const { data } = await getSearches(companyId);
    const searches: Store.Searches = [];

    if (Array.isArray(data)) {
      for (const search of data) {
        try {
          const validatedData = searchSchema.validateSync(search);

          const {
            brandId,
            searchName,
            brands,
            mainBrand,
            industryId,
            industryName,
            marketId,
            subRegions = [],
            ownerId,
            lastSyncDate,
            createdAt,
            updatedAt,
            isPinned,
            isFirstSyncDone,
          } = validatedData;

          const {
            suggestions,
            competitors,
            additionalBrandNames,
            brandsTableNames,
          } = parseSearchBrands(brands);

          searches.push({
            id: brandId,
            name: searchName,
            brands: {
              main: mainBrand,
              competitors,
            },
            brandsTableNames,
            additionalBrandNames,
            industry: {
              id: industryId,
              name: industryName,
            },
            market: {
              code: marketId,
              subRegions: subRegions,
            },
            suggestions,
            owner: {
              id: ownerId,
            },
            lastSyncDate: lastSyncDate,
            createdAt: createdAt,
            updatedAt: updatedAt,
            isPinned,
            isFirstSyncDone,
          });
        } catch (error) {
          const errorName = `User - [${userId}] from [${companyId}] company: Error on loading of search`;
          const errorText = `Error occurred with [${
            search.brandId || "Can't get searchId"
          }] search`;
          const errorMessage = error.message || JSON.stringify(error);

          showDevelopmentError({
            additionalTexts: [errorName, errorText, errorMessage],
            error,
            clickUpProps: {
              name: errorName,
              text_content: {
                error: errorText,
                message: errorMessage,
              },
            },
          });
        }
      }
    }

    const uniqueSearches = getArrayOfUniqueObjectsByKey(searches, "id");

    dispatch(setSearches(uniqueSearches));

    return searches;
  };
};

export const createSearch = ({
  userId,
  name,
  mainBrand,
  competitors,
  additionalBrandNames,
  industryId,
  marketCode,
  subRegions,
  suggestions,
}: {
  userId: string;
  name: string;
  mainBrand: string;
  competitors: string[];
  additionalBrandNames?: {
    [k: string]: string[];
  };
  industryId: string;
  marketCode: string;
  subRegions: string[];
  suggestions: Store.Search["suggestions"];
}): Function => {
  return async (
    dispatch: Function,
    getState: Function,
  ): Promise<{
    searchId?: string;
  }> => {
    const { searches, industries } = getState() as Store.State & RootState;

    const industry = industries.entities[industryId];

    const { data } = await postSearch({
      userId,
      name,
      mainBrand,
      competitors,
      additionalBrandNames: additionalBrandNames || {},
      industryId,
      industry,
      marketCode,
      subRegions,
      suggestions,
      calculatingImmediatelyAvailableWidgets: new Date().toISOString(),
    });

    if (!searches.length) {
      TagManager.dataLayer({
        dataLayer: {
          event: "First search",
          category: "Competitive Compass",
          action: "First search",
        },
      });
    }

    await dispatch(writeWidgetState(data.brandId));

    dispatch(
      setSearches([
        ...searches,
        {
          id: data.brandId,
          name,
          brands: {
            main: mainBrand,
            competitors: competitors,
          },
          additionalBrandNames: additionalBrandNames || {},
          brandsTableNames: prepareBrandsTableNames(
            [mainBrand, ...competitors],
            marketCode,
          ),
          industry: {
            id: industryId,
            name: industry?.label || "",
          },
          market: {
            code: marketCode,
            subRegions,
          },
          suggestions,
          owner: {
            id: userId,
          },
          isFirstSyncDone: false,
          createdAt: new Date().toISOString(),
          lastSyncDate: new Date().toISOString(),
        },
      ]),
    );

    return {
      searchId: data.brandId,
    };
  };
};

export const editSearch = (
  searchId: string,
  {
    name,
    mainBrand,
    competitors,
    additionalBrandNames,
    industryId,
    marketCode,
    subRegions,
    suggestions,
  }: {
    name: string;
    mainBrand: string;
    competitors: string[];
    additionalBrandNames: {
      [name: string]: string[];
    };
    industryId: string;
    marketCode: string;
    subRegions: string[];
    suggestions: Store.Search["suggestions"];
  },
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { user, searches, industries } = getState() as Store.State &
      RootState;

    const industry = industries.entities[industryId];

    await putSearch(searchId, {
      userId: user.id,
      name,
      mainBrand,
      competitors,
      additionalBrandNames,
      industryId,
      industry,
      marketCode,
      subRegions,
      suggestions,
      calculatingImmediatelyAvailableWidgets: new Date().toISOString(),
    });

    await dispatch(writeWidgetState(searchId));

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                id: searchId,
                name,
                brands: {
                  main: mainBrand,
                  competitors: competitors,
                },
                additionalBrandNames,
                brandsTableNames: prepareBrandsTableNames(
                  [mainBrand, ...competitors],
                  marketCode,
                ),
                industry: {
                  id: industryId,
                  name: industry?.label || "",
                },
                market: {
                  code: marketCode,
                  subRegions,
                },
                suggestions,
                owner: {
                  id: user.id,
                },
                isFirstSyncDone: false,
                lastSyncDate: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              }
            : search,
        ),
      ),
    );
  };
};

export const removeSearch = (searchId: string): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { user, searches } = getState() as Store.State;
    await deleteSearch(user.id, searchId);
    dispatch(setSearches(searches.filter(({ id }) => id !== searchId)));
  };
};

export const updateSearchKeyWords = (
  searchId: string,
  brandName: string,
  marketCode: string,
  keywords: string[],
): Function => {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions?.[brandName],
                    keywords,
                  },
                },
              }
            : search,
        ),
      ),
    );

    const slugifiedBrandName = getSlugifiedString(brandName);

    return await writeSearchKeyWords(
      searchId,
      `${slugifiedBrandName}_${marketCode}`.toLowerCase(),
      keywords,
    );
  };
};

export const updateSearchData = (
  searchId: string,
  changes: Partial<Pick<Store.Search, "isPinned" | "searchName">>,
): Function => async (dispatch: Function, getState: Function) => {
  const { searches } = getState() as Store.State;

  await writeSearchData(searchId, changes);

  dispatch(
    setSearches(
      searches.map((search) =>
        search.id === searchId
          ? {
              ...search,
              ...changes,
              name: changes.searchName || search.name,
            }
          : search,
      ),
    ),
  );
};

// Helpers
function parseSearchBrands(
  searchBrands: yup.InferType<typeof searchBrandSchema>,
) {
  const suggestions: Store.Search["suggestions"] = {};
  const competitors: Store.Search["brands"]["competitors"] = [];
  const additionalBrandNames: {
    [brandName: string]: string[];
  } = {};
  const brandsTableNames: Store.Brands = [];

  if (Array.isArray(searchBrands)) {
    for (let i = 0; i < searchBrands.length; i++) {
      const searchBrand = searchBrands[i];

      if (!searchBrand) {
        throw new Error("Something happened with brand");
      }

      const {
        brandNames,
        name: brandName,
        brandTableName,
        exclude,
        aiTrainerExcludedWords,
        excludedKeywords: topKeywordsExcludedWords,
      } = searchBrand;

      brandsTableNames.push({
        name: brandName,
        id: brandTableName,
      });

      const additionalNames: string[] = brandNames.reduce((acc, brand) => {
        return brand.name === brandName ? acc : acc.concat(brand.name);
      }, [] as string[]);

      additionalBrandNames[brandName] = additionalNames;

      if (i > 0) {
        competitors.push(brandName);
      }

      const getEditSearchExcludedWords = () => {
        if (!Array.isArray(exclude) || !Array.isArray(aiTrainerExcludedWords))
          return [];

        return exclude.filter((el) => !aiTrainerExcludedWords.includes(el));
      };

      suggestions[brandName] = {
        ...searchBrand,
        editSearchEcxludedWords: getEditSearchExcludedWords(),
        topKeywordsExcludedWords,
      };
    }
  }

  return {
    suggestions,
    competitors,
    additionalBrandNames,
    brandsTableNames,
  };
}

function prepareBrandsTableNames(
  brands: string[],
  marketCode: string,
): Store.Brands {
  return brands.map((name) => ({
    name,
    id: `${getSlugifiedString(name)}_${marketCode}`,
  }));
}
