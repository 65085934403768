import { FC, useState, useRef, useEffect, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./Select.module.scss";
import { Triangle } from "../../../icons";
import { useOutsideClickHandler } from "../../../hooks";
import { getMessageIcon } from "../../../utils/getMessageIcon";

// Inner imports
import { OpeningDirection, SelectProps } from "./types";
import {
  SELECT_FILTER_MIN_ITEMS_COUNT,
  SElECT_FILTER_PLACEHOLDER,
  SELECT_INPUT_PLACEHOLDER,
} from "./constants";

export const Select: FC<SelectProps> = ({
  inputRef,
  className = "",
  optionsClassName = "",
  inputClassName = "",
  style,
  options = [],
  additionalButton,
  value = "",
  changeHandler = () => "",
  placeholder = SELECT_INPUT_PLACEHOLDER,
  isEnabled = true,
  title,
  hasFilter = true,
  icon,
  openingDirection: _openingDirection,
  dropdownMessage,
}) => {
  const { t } = useTranslation();

  const selectRef = useRef<HTMLDivElement>(null);

  const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
    _openingDirection || "down",
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  const selectedOption: Option | undefined = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  const filteredOptions: Option[] = useMemo(
    () =>
      options.filter((option) =>
        t(option.label.toLowerCase()).includes(filter.trim().toLowerCase()),
      ),
    [filter, options, t],
  );

  const isFilterPresent: boolean = useMemo(
    () => hasFilter && options.length > SELECT_FILTER_MIN_ITEMS_COUNT,
    [hasFilter, options.length],
  );

  useOutsideClickHandler(selectRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);

  useEffect(() => {
    if (_openingDirection) return;

    const _setOpeningDirection = () => {
      const clientHeight = document.documentElement.clientHeight;
      const selectTop = selectRef.current?.getBoundingClientRect().top!;
      setOpeningDirection(selectTop > clientHeight / 2 ? "up" : "down");
    };
    _setOpeningDirection();

    window.addEventListener("resize", _setOpeningDirection);

    return () => {
      window.removeEventListener("resize", _setOpeningDirection);
    };
  }, [_openingDirection]);

  const openingDirectionClassName: string =
    openingDirection === "up" ? "popupUp" : "popupDown";

  return (
    <div className={cx(styles.select, className)} style={style} ref={selectRef}>
      <div
        ref={inputRef}
        className={cx(styles.input, inputClassName)}
        style={isEnabled ? undefined : { cursor: "not-allowed" }}
        onClick={
          isEnabled ? () => setIsOpen((prevIsOpen) => !prevIsOpen) : undefined
        }
        tabIndex={0}
      >
        {selectedOption ? (
          <div
            className={styles.label}
            title={t(title || selectedOption.label)}
          >
            {icon}
            <span>{t(selectedOption.label)}</span>
          </div>
        ) : (
          <div className={styles.placeholder} title={title || t(placeholder)}>
            {icon}
            {t(placeholder)}
          </div>
        )}
        <div className={styles.arrowIconWrapper}>
          <Triangle
            style={{ ...(!isOpen && { transform: "rotate(180deg)" }) }}
            size={10}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className={cx(
            styles.popup,
            openingDirectionClassName,
            styles[openingDirectionClassName],
          )}
          tabIndex={1}
        >
          {isFilterPresent && (
            <input
              className={styles.filterInput}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={t(SElECT_FILTER_PLACEHOLDER)}
            />
          )}
          {filteredOptions.length ? (
            <div className={cx(styles.options, optionsClassName)}>
              {additionalButton ? additionalButton : null}
              {filteredOptions.map((option, i) => (
                <div
                  className={styles.option}
                  onClick={() => {
                    changeHandler(option.value, option.label);
                    setIsOpen(false);
                  }}
                  title={t(option.label)}
                  key={i}
                >
                  {t(option.label)}
                </div>
              ))}
              {!!dropdownMessage?.text && (
                <div
                  className={cx(styles.message, styles[dropdownMessage.type])}
                >
                  {getMessageIcon(dropdownMessage.type)}
                  {dropdownMessage?.text}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noOptionsWrapper}>
              {additionalButton ? additionalButton : null}
              <div className={styles.noOptions}>{t("select_no_results")}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
