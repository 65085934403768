import { FC, memo, useEffect, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./Tabs.module.scss";

// Inner imports
import type { TabsProps } from "./types";

export const Tabs: FC<TabsProps> = memo(
  ({ className = "", initialActiveTab, autoSet = true, tabs }) => {
    const firstTabValue: string = useMemo(() => tabs[0]?.value || "", [tabs]);

    const [activeTabValue, setActiveTabValue] = useState(
      initialActiveTab || firstTabValue,
    );

    useEffect(() => {
      if (firstTabValue) setActiveTabValue(initialActiveTab || firstTabValue);
    }, [firstTabValue, initialActiveTab]);

    return (
      <div className={cx(styles.tabs, className)}>
        {tabs.map(({ value, label, onClickHandler }) => (
          <button
            className={cx(
              styles.tab,
              styles[activeTabValue === value ? "tabActive" : ""],
            )}
            key={value}
            onClick={() => {
              onClickHandler(value);
              autoSet && setActiveTabValue(value);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    );
  },
);
