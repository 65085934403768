import firebase from "firebase/app";
import * as yup from "yup";
import { Faq } from "./faqsSlice";

const faqSchema = yup
  .object({
    title: yup.string().required(),
    paragraphs: yup
      .array(
        yup
          .object({
            subtitle: yup.string().defined(),
            text: yup.string().defined(),
          })
          .noUnknown()
          .required(),
      )
      .min(1)
      .required(),
    order: yup.number().required(),
  })
  .noUnknown();

export async function getFaqs() {
  const collection = await firebase.firestore().collection("page-faq").get();

  return collection.docs.reduce<Faq[]>((acc, doc) => {
    try {
      const validatedData = faqSchema.validateSync(doc.data());
      acc.push({ id: doc.id, ...validatedData });
    } catch (error) {
      console.dir(error);
    }

    return acc;
  }, []);
}
