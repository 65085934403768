import { Dashboard } from "../store/dashboards/dashboardsSlice";

const DASHBOARD_GRID_DEFAULT_WIDGET_POSITION = { x: 0, y: 0, h: 1, w: 1 };

const DASHBOARD_GRID_CELLS_PER_ROW = {
  large: 4,
  medium: 2,
  small: 1,
};

export const calculateWidgetLayoutPosition = ({
  widgetId,
  searchId,
  layout,
  layoutSize,
}: {
  widgetId: string;
  searchId: string;
  layout?: Dashboard["layouts"][LayoutSize];
  layoutSize?: LayoutSize;
}) => {
  const index = `${searchId}${widgetId}`;

  if (!layout || !layoutSize)
    return { ...DASHBOARD_GRID_DEFAULT_WIDGET_POSITION, i: index };

  const existingWidgetPositions = layout.find(({ i }) => i === index);

  if (existingWidgetPositions) return existingWidgetPositions;

  const { y: maxY, h: maxHeight, w: maxWidth } = layout.reduce(
    (acc, gridItem) => {
      if (acc.x < gridItem.x) {
        acc.x = gridItem.x;
      }

      if (acc.y < gridItem.y) {
        acc.y = gridItem.y;
      }

      if (acc.w < gridItem.w) {
        acc.w = gridItem.w;
      }

      if (acc.h < gridItem.h) {
        acc.h = gridItem.h;
      }

      return acc;
    },
    { ...DASHBOARD_GRID_DEFAULT_WIDGET_POSITION },
  );

  const calculateXAndY = (): { x: number; y: number } => {
    const sortedLayoutItems = [...layout].sort(
      (itemA, itemB) => itemB.y - itemA.y || itemB.x - itemA.x,
    );

    const lastItem = sortedLayoutItems[0];

    if (!lastItem) return { x: 0, y: 0 };

    if (lastItem.x + 1 + maxWidth > DASHBOARD_GRID_CELLS_PER_ROW[layoutSize])
      return {
        x: 0,
        y: maxY + 1,
      };

    return {
      x: lastItem.x + lastItem.w,
      y: maxY,
    };
  };

  const { x: calculatedX, y: calculatedY } = calculateXAndY();

  return {
    i: index,
    h: maxHeight,
    w: maxWidth,
    x: calculatedX,
    y: calculatedY,
  };
};
