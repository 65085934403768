import React, { forwardRef } from "react";
import cx from "classnames";

import styles from "./TextArea.module.scss";
import { InputClear } from "../../../icons";

type TextAreaProps = {
  className?: string;
  style?: React.CSSProperties;
  maxLength?: React.InputHTMLAttributes<HTMLTextAreaElement>["maxLength"];
  value?: string;
  changeHandler?: (value: string) => void;
  clearHandler?: () => void;
  placeholder?: string;
  isEnabled?: boolean;
  isReadOnly?: boolean;
  title?: string;
};

export const TextArea = forwardRef(
  (
    {
      className = "",
      style,
      maxLength = 120,
      value = "",
      changeHandler = () => "",
      clearHandler,
      placeholder = "Write here...",
      isEnabled = true,
      isReadOnly,
      title,
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement>,
  ) => {
    const fillTextareaClassName = value ? styles.fillTextarea : "";

    return (
      <div
        className={cx(styles.textareaWrapper, fillTextareaClassName, className)}
        style={style}
        title={title || ""}
      >
        <textarea
          ref={ref}
          style={isEnabled ? undefined : { cursor: "not-allowed" }}
          maxLength={maxLength}
          value={value}
          onChange={(e) => changeHandler(e.target.value)}
          placeholder={placeholder}
          disabled={!isEnabled}
          readOnly={isReadOnly}
          rows={5}
        />
        {!!clearHandler && value && <InputClear onClick={clearHandler} />}
      </div>
    );
  },
);
