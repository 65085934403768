import DATE_RANGES_WITH_OVERALL from "../../../data/dateRangesWithOverall";

// Inner imports
import { DEFAULT_DATE_RANGE } from "./constants";
import type { DATE_RANGES_TYPE } from "./types";

function prepareLineChartData(
  lineChart?: SentimentTrends.Data["lineChart"],
): SentimentTrends.LineChart.Data {
  if (!lineChart) return [];

  // GET DATA WITH DEFAULT VALUE --->
  const defaultData = lineChart[DEFAULT_DATE_RANGE];
  if (defaultData && !!Object.keys(defaultData)) {
    return defaultData;
  }
  // <--- GET DATA WITH DEFAULT VALUE

  // GET DATA WITH BIGGEST VALUE IF DEFAULT HAS NOT BEEN FOUND --->
  const biggestAvailablePeriod = findBiggestAvailablePeriod(lineChart);
  const theBiggestData = lineChart[biggestAvailablePeriod || "oneYear"];
  if (theBiggestData && !!Object.keys(theBiggestData)) {
    return theBiggestData;
  }
  // <--- GET DATA WITH BIGGEST VALUE IF DEFAULT HAS NOT BEEN FOUND

  return [];
}

function prepareLineChartDateRanges(
  lineChart?: SentimentTrends.Data["lineChart"],
): { label: string; value: string }[] {
  const filteredDateRanges = DATE_RANGES_WITH_OVERALL.filter(
    ({ value }) => !!lineChart?.[value]?.length,
  );

  return filteredDateRanges.map(({ value, label }) => ({
    value,
    label,
  }));
}

function findBiggestAvailablePeriod(
  lineChart: SearchTrend.Data["lineChart"],
): DATE_RANGES_TYPE {
  let biggestPeriod: DATE_RANGES_TYPE = DEFAULT_DATE_RANGE;

  const lineChartData = Object.entries(lineChart || {});

  for (let i = lineChartData.length; i >= 0; i--) {
    try {
      const [period, values] = lineChartData[i] || [];

      if (!!Object.keys(values || {}).length) {
        biggestPeriod = period as DATE_RANGES_TYPE;
        break;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return biggestPeriod;
}

export {
  prepareLineChartData,
  prepareLineChartDateRanges,
  findBiggestAvailablePeriod,
};
