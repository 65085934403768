import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./AttitudePreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
} from "../../../components";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";

// Inner import
import { WIDGET_ID, LABELS } from "./constants";
import { AttitudePieChart, AttitudeLineChartWithLabels } from "./components";
import { extractAttitudeBrandData } from "./utils";

export const AttitudePreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};
    const { id: mainBrandId = "" } = brands[0] || {};

    return {
      lineChartData: extractAttitudeBrandData(lineChart, mainBrandId),
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  const charts = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(pieChartData).length) {
      charts.push({
        type: "pieChart",
        chart: (
          <AttitudePieChart
            className={styles.chart}
            brands={brands}
            data={
              (
                pieChartData["oneYear"] ||
                pieChartData["sixMonths"] ||
                pieChartData["threeMonths"] ||
                pieChartData["twoMonths"] ||
                pieChartData["oneMonth"]
              )?.values
            }
            type="preview"
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        subType: "oneBrand",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={LABELS}
            data={lineChartData}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            axis={{
              yAxisLegend: t("w_attitude_linechart_legend"),
              yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });

      charts.push({
        type: "lineChart",
        chart: (
          <AttitudeLineChartWithLabels
            className={styles.lineChart}
            searchId={searchId}
            dashboardId={dashboardId}
            attitude={widgetData}
            type="widgetPreview"
          />
        ),
      });
    }

    return charts;
  }, [
    pieChartData,
    lineChartData,
    brands,
    searchId,
    t,
    dashboardId,
    widgetData,
  ]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon, buttonNumber }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  number={buttonNumber}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};

// Commented out until DEV-69219 is ready
// const { brands } = attitude || {};
// const [firstBrand] = brands || [];

// const isFirstBrandDataEmpty = useMemo(() => {
//   if (!firstBrand || !attitude) return;
//   let isDataEmpty: boolean = false;
//   const firstBrandData =
//     attitude?.pieChart?.oneYear?.values?.[firstBrand?.id];
//   if (firstBrandData) {
//     isDataEmpty = !Object.values(firstBrandData).filter(
//       (item: number) => item > 0,
//     ).length;
//   }
//   return isDataEmpty;
// }, [firstBrand, attitude]);

// const isBrandsLineChartDataEmpty: boolean = useMemo(
//   () =>
//     !Object.values(attitude?.lineChart || {}).some((data) =>
//       Object.values(data?.values || {}).some(
//         ({ negative, positive, neutral }) => negative || positive || neutral,
//       ),
//     ),
//   [attitude?.lineChart],
// );
