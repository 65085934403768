import firebase from "firebase/app";

const DASHBOARDS_CUSTOM_DATA_COLLECTION = "dashboards-custom-data";

export const getCustomDashboardData = async (dashboardId: string) => {
  try {
    const doc = (await firebase
      .firestore()
      .collection(DASHBOARDS_CUSTOM_DATA_COLLECTION)
      .doc(dashboardId)
      .get()) as firebase.firestore.QueryDocumentSnapshot<Store.DashboardCustomData>;

    if (doc.exists) {
      return doc.data();
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveCustomWidgetSettings = async (
  dashboardId: string,
  searchId: string,
  widgetSlug: string,
  settings: object,
) => {
  try {
    await firebase
      .firestore()
      .collection(DASHBOARDS_CUSTOM_DATA_COLLECTION)
      .doc(dashboardId)
      .set(
        {
          [widgetSlug]: {
            [searchId]: settings,
          },
        },
        { merge: true },
      );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
