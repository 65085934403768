import { Dispatch } from "redux";

import { getCustomDashboardData, saveCustomWidgetSettings } from "./api";
import actionTypes from "../actionTypes";

// Inner imports
import type {
  SetDashboardCustomDataAction,
  SetCustomWidgetText,
} from "./reducer";

type SetterProps = Omit<SetCustomWidgetText, "type">;

const setDashboardCustomData = ({
  dashboardId,
  customData,
}: Omit<SetDashboardCustomDataAction, "type">) => ({
  type: actionTypes.SET_DASHBOARD_CUSTOM_DATA,
  dashboardId,
  customData,
});

export const setCustomWidgetSettings = ({
  dashboardId,
  searchId,
  widgetId,
  settings,
}: SetterProps): SetCustomWidgetText => ({
  type: actionTypes.SET_CUSTOM_WIDGET_TEXT,
  dashboardId,
  searchId,
  widgetId,
  settings,
});

export const readDashboardCustomData = (dashboardId: string) => async (
  dispatch: Dispatch,
) => {
  const customData = await getCustomDashboardData(dashboardId);
  if (customData) {
    dispatch(setDashboardCustomData({ dashboardId, customData }));
  }
};

export const updateCustomWidgetText = ({
  dashboardId,
  searchId,
  widgetId,
  settings,
}: SetterProps) => {
  return async (dispatch: Dispatch) => {
    await saveCustomWidgetSettings(dashboardId, searchId, widgetId, settings);
    dispatch(
      setCustomWidgetSettings({ dashboardId, searchId, widgetId, settings }),
    );
  };
};
