import { texts } from "../ReviewsDataTexts";

const INFLUENCE_ON_WIDGETS = [
  "result-reputation-index",
  "result-reputation-sentiment",
  "result-reputation-distribution",
];

const WIDGET_ID = "result-reputation-index";

const ABOUT_THIS = texts[WIDGET_ID]?.aboutThis || "";

const ARTICLES_LIMIT = 200 as const;

const REVIEW_DATA_CATEGORY = "Reputation review data";

export {
  WIDGET_ID,
  ABOUT_THIS,
  INFLUENCE_ON_WIDGETS,
  ARTICLES_LIMIT,
  REVIEW_DATA_CATEGORY,
};
