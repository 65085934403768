import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SentimentDriversPreview.module.scss";
import {
  Preloader,
  ErrorPlaceholder,
  ChartButton,
  SentimentDriversTagCloud,
  BrandSelector,
} from "../../../components";

import { NoDataForSelectedBrandStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import {
  getFirstSuitableMonth,
  getLastSuitableMonth,
  prepareDataForTagCloud,
  calculateBrandDataByPeriod,
  getBiggestBrandDataItems,
  checkIfDataExists,
} from "./utils";
import { WIDGET_ID, INITIAL_MONTHS_COUNT } from "./constants";
import { useBrandIndex } from "./hooks";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const SentimentDriversPreview: FC<WidgetsView.Preview> = ({
  searchId,
  tileRef,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const [brandIndex, setBrandIndex] = useBrandIndex(widgetData);

  const brands: string[] = useMemo(
    () => widgetData?.brands?.map((el) => el.name) || [],
    [widgetData],
  );

  const selectedBrand = widgetData?.brands[brandIndex]?.id || "";

  const selectedBrandData = useMemo(
    () => widgetData?.chartsData[selectedBrand],
    [selectedBrand, widgetData?.chartsData],
  );

  const brandChangeHandler = (brandIndex: number): void => {
    setBrandIndex(brandIndex);
    setViewIndex(0);
  };

  const selectedBrandExcludedData = useMemo(() => {
    const { positive, negative, neutral } =
      widgetData?.hiddenWords?.[selectedBrand] || {};

    return {
      positive: positive?.map(({ word }) => word) || [],
      negative: negative?.map(({ word }) => word) || [],
      neutral: neutral?.map(({ word }) => word) || [],
    };
  }, [selectedBrand, widgetData?.hiddenWords]);

  const isBrandsSelectorShown: boolean = useMemo(() => brands.length > 1, [
    brands,
  ]);

  const isWidgetDataPresent: boolean = useMemo(
    () => checkIfDataExists(widgetData?.chartsData),
    [widgetData?.chartsData],
  );

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (!selectedBrandData?.length) return charts;

    const [firstSuitableMonth, lastSuitableMonth] = [
      getFirstSuitableMonth(selectedBrandData, INITIAL_MONTHS_COUNT),
      getLastSuitableMonth(selectedBrandData),
    ];

    const brandDataByPeriod = calculateBrandDataByPeriod({
      brandData: selectedBrandData,
      dateRange: {
        startDate: firstSuitableMonth?.date,
        endDate: lastSuitableMonth?.date,
      },
    });
    const biggestBrandDataItems = getBiggestBrandDataItems(brandDataByPeriod);

    if (!biggestBrandDataItems && !isWidgetDataPresent) return charts;

    const { positive, neutral, negative } = biggestBrandDataItems || {};

    Object.entries({ positive, neutral, negative }).forEach(([key, data]) => {
      if (!data?.length) return;

      const tags = prepareDataForTagCloud(data);
      const subType = key as "positive" | "neutral" | "negative";

      charts.push({
        type: "sentimentDrivers",
        subType,
        chart: (
          <SentimentDriversTagCloud
            ref={tileRef}
            className={styles.sentimentDriversTagCloud}
            tags={tags}
            excludedTags={selectedBrandExcludedData[subType]}
            type={subType}
          />
        ),
      });
    });

    if (
      isWidgetDataPresent &&
      !positive?.length &&
      !neutral?.length &&
      !negative?.length
    ) {
      charts.push({
        type: "sentimentDrivers",
        chart: (
          <div className={styles.error}>
            {t("w_trending_keywords_no_data_for_period_error")}
          </div>
        ),
      });
    }

    return charts;
  }, [
    isWidgetDataPresent,
    selectedBrandData,
    selectedBrandExcludedData,
    t,
    tileRef,
  ]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (!isWidgetDataPresent)
    return (
      <ErrorPlaceholder
        onMountCallBack={() =>
          onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
        }
      />
    );

  return (
    <div className={styles.widgetPreview}>
      <div className={styles.buttons}>
        {isBrandsSelectorShown && (
          <BrandSelector
            className={styles.brandSelector}
            brands={brands}
            currentBrandIndex={brandIndex}
            onBrandClicked={brandChangeHandler}
            isEventPropagationStopped
          />
        )}
        {views.map(({ buttonIcon, buttonNumber }, i) =>
          i !== viewIndex ? (
            <ChartButton
              number={buttonNumber}
              className={styles.button}
              icon={buttonIcon}
              onButtonClick={() => setViewIndex(i)}
              key={buttonIcon + i}
              isEventPropagationStopped
            />
          ) : null,
        )}
      </div>
      <div
        ref={(chartRef) => chartRef && setChartRef(chartRef)}
        className={styles.chartWrapper}
        onClick={onChartClicked}
      >
        {views[viewIndex]?.chart ?? <NoDataForSelectedBrandStub />}
      </div>
    </div>
  );
};
