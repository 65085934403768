const TABLE_HEIGHT = 550;

const CELL_HEIGHT = 50;

const COLUMN_WIDTH = 200;

const SMALL_COLUMN_WIDTH = 40;

// Pagination constants;
const ELEMENTS_PER_PAGE = 10;
const PAGINATION_PAGE_LIMIT = 10;
const PAGINATION_MAX_BUTTONS = 5;
const PAGINATION_SIZE = "xs";

// BarChart
const BAR_CHART_WIDTH = 300;
const BAR_CHART_HEIGHT = 150;
const BAR_CHART_FONT_SIZE = 10;
const BAR_CHART_TEXT_ANGLE = -15;
const BAR_CHART_BAR_FILL = "#68ADEE";

const GOOGLE_SEARCH_URL = "https://www.google.com/search";

export {
  TABLE_HEIGHT,
  CELL_HEIGHT,
  COLUMN_WIDTH,
  SMALL_COLUMN_WIDTH,
  ELEMENTS_PER_PAGE,
  PAGINATION_PAGE_LIMIT,
  PAGINATION_MAX_BUTTONS,
  PAGINATION_SIZE,
  BAR_CHART_WIDTH,
  BAR_CHART_HEIGHT,
  BAR_CHART_FONT_SIZE,
  BAR_CHART_TEXT_ANGLE,
  BAR_CHART_BAR_FILL,
  GOOGLE_SEARCH_URL,
};
