import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./ReputationSentimentPreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
} from "../../../components";
import reputationParameters from "../../../data/reputationParameters";

// Inner imports
import { ReputationSentimentPieChart } from "./components/piecharts/ReputationSentimentPieChart";

import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const ReputationSentimentPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const reputationSentimentLineChart = widgetData?.[0]?.brandData.lineChart;

    const lineChartData = reputationSentimentLineChart?.map(
      ({ date, values }) => ({
        date,
        values: Object.fromEntries(
          Object.entries(values).map(([label, value]) => [
            label,
            value["positive"],
          ]),
        ),
      }),
    );

    if (reputationSentimentLineChart) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            key={0}
            className={styles.chart}
            labels={reputationParameters.map(({ name, color }) => ({
              id: name,
              name,
              color,
            }))}
            data={lineChartData}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
              hasBackground: true,
            }}
            axis={{
              yAxisLegend: "Consideration",
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    const pieChart = widgetData?.[0]?.brandData.pieChart;

    if (pieChart) {
      charts.push({
        type: "pieChart",
        chart: <ReputationSentimentPieChart data={pieChart} />,
      });
    }

    return charts;
  }, [searchId, widgetData]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon, buttonNumber }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  number={buttonNumber}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
