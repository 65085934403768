import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./AttitudeLineChartWithLabels.module.scss";
import { LineChart } from "../../../../../components";
import { LineChartProps } from "../../../../../components/widgets/LineChart/types";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../../../constants";

// Inner imports
import { LABELS } from "../../constants";

export const AttitudeLineChartWithLabels: FC<{
  searchId: string;
  attitude?: Attitude.Data;
  className?: string;
  type?: LineChartProps["viewMode"];
  dashboardId?: string;
  timePlotterId?: string;
}> = ({
  searchId,
  attitude,
  className,
  type = "widgetPage",
  dashboardId,
  timePlotterId,
}) => {
  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] = useState<
    typeof LABELS[number]["name"]
  >("positive");

  const brands = useMemo(() => attitude?.brands || [], [attitude?.brands]);

  const chartData = useMemo(
    () =>
      attitude?.lineChart?.map(({ date, values }) => ({
        date,
        values:
          Object.fromEntries(
            Object.entries(values).map(([brandId, value]) => [
              brands?.find(({ id }) => id === brandId)?.name,
              value[activeFilter],
            ]),
          ) || {},
      })) || [],
    [activeFilter, attitude?.lineChart, brands],
  );

  return (
    <div
      className={cx(
        styles.attitudeLineChartWithLabels,
        styles[
          `attitudeLineChartWithLabels${
            type === "widgetPage" ? "Page" : "Preview"
          }`
        ],
      )}
    >
      <div className={styles.filters}>
        {LABELS.map(({ name, color }, i) => (
          <div
            className={cx(
              styles.filter,
              styles[name === activeFilter ? "filterActive" : ""],
            )}
            style={{ borderColor: color }}
            onClick={() => setActiveFilter(name)}
            key={i}
          >
            {name}
          </div>
        ))}
      </div>
      <LineChart
        className={className}
        labels={brands}
        data={chartData}
        dashboardId={dashboardId}
        searchId={searchId}
        timePlotterId={timePlotterId}
        viewMode={type}
        chartStyles={{
          graphHeight: "100%",
          lineStrokeWidth: 4,
        }}
        chartSettings={{
          hasTrendLine: true,
        }}
        axis={{
          yAxisLegend:
            type === "widgetPage" ? t("w_attitude_linechart_legend") : "",
          yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
          yAxisVerticalPadding: { top: 20, bottom: 0 },
        }}
      />
    </div>
  );
};
