import { FC, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cx from "classnames";

import styles from "./ShareOfVolume.module.scss";
import { INITIAL_DATE_RANGE } from "src/data/dateRanges";
import {
  PieChartWithInnerLabels,
  LineChart,
  WidgetFooterButtons,
  AboutThis,
  WidgetButtonsBar,
  Preloader,
  ErrorPlaceholder,
} from "src/components";
import { WidgetPendingStub } from "src/icons";
import { useCompetitorsPresence, useNameForDownloadFiles } from "src/hooks";
import { useWidgetFetching, useWidgetView } from "../../hooks";
import { WidgetChart } from "../../types";

// Inner imports
import { ShareOfVolumeTable } from "./components";
import {
  extractShareOfVolumeData,
  formatDateByPeriod,
  getDateRangeOptions,
} from "./utils";
import { WIDGET_ID, WIDGET_COLLECTION_ID } from "./constants";

export const ShareOfVolume: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const widgetsState = useSelector(
    ({ widgetsState }: RootState) => widgetsState,
  );

  const isWidgetCalculating = useMemo<boolean>(() => {
    const status =
      widgetsState[searchId]?.[WIDGET_COLLECTION_ID]?.isCalculating;

    return Boolean(status);
  }, [searchId, widgetsState]);

  const { widgetData, isLoading } = useWidgetFetching(
    searchId,
    WIDGET_COLLECTION_ID,
  );

  const searchesCompetitorsPresence = useCompetitorsPresence({
    searchesIds: [searchId],
    widgetId: WIDGET_COLLECTION_ID,
  });

  const isCompetitorsPresent: boolean | undefined = useMemo(
    () => searchesCompetitorsPresence[searchId || ""]?.isCompetitorsPresent,
    [searchId, searchesCompetitorsPresence],
  );

  // Date Range ---->
  const dateRanges = useMemo(() => {
    if (!widgetData) return [];

    return getDateRangeOptions(widgetData, t);
  }, [widgetData, t]);

  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    INITIAL_DATE_RANGE,
  );
  // <---- Date Range

  // Widget views ---->
  const dataFormattingForTable = useMemo(() => {
    if (!widgetData || !currentDateRange) return;

    return formatDateByPeriod(widgetData, currentDateRange);
  }, [currentDateRange, widgetData]);

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};

    return {
      lineChartData: extractShareOfVolumeData(lineChart),
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const isShareOfVolumeLineChartDataExist = lineChartData.length;
    const isShareOfVolumePieChartDataExist = Object.keys(pieChartData).length;

    if (isShareOfVolumeLineChartDataExist) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={brands}
            data={lineChartData}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              graphHeight: "100%",
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_search_volume_linechart_legend"),
              increaseYAxisWidthInPercentage: 10,
            }}
          />
        ),
      });
    }

    if (isShareOfVolumePieChartDataExist) {
      charts.push({
        type: "table",
        chart: (
          <div className={styles.tableViewWrapper}>
            <ShareOfVolumeTable tableData={dataFormattingForTable} />
            {isCompetitorsPresent && (
              <PieChartWithInnerLabels
                className={styles.pieChart}
                data={pieChartData[currentDateRange!]?.values}
              />
            )}
          </div>
        ),
      });
    }

    return charts;
  }, [
    lineChartData,
    pieChartData,
    brands,
    dashboardId,
    searchId,
    t,
    dataFormattingForTable,
    isCompetitorsPresent,
    currentDateRange,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const totalVolume = useMemo(() => {
    if (viewType === "table") {
      return widgetData?.pieChart[currentDateRange!]?.values.reduce(
        (acc, { additionalValue }) => (acc += additionalValue || 0),
        0,
      );
    }
    if (viewType === "lineChart") {
      return widgetData?.lineChart.reduce((acc, { additionalValues }) => {
        const values = +Object.values(additionalValues ?? {}).reduce(
          (a, b) => a + b,
          0,
        );
        if (isNaN(values)) return acc;
        return (acc += values);
      }, 0);
    }
  }, [currentDateRange, widgetData, viewType]);

  const conclusion = useMemo(() => {
    return !!totalVolume
      ? t("w_search_volume_main_tip", {
          totalVolume: totalVolume.toLocaleString(),
        })
      : "";
  }, [totalVolume, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget && !isWidgetCalculating) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "table"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />

        <div className={styles.widgetWrapperOuter}>
          {isWidgetCalculating ? (
            <div className={styles.pendingStub}>
              <WidgetPendingStub />
              {t("wt_widget_calculating")}
            </div>
          ) : (
            <>
              <div
                ref={widgetRef}
                className={cx(
                  styles.widgetWrapper,
                  styles[
                    viewType === "lineChart" ? "widgetWrapperLineChart" : ""
                  ],
                )}
              >
                {isLoading ? (
                  <Preloader className={styles.preloader} />
                ) : (
                  viewElement
                )}
              </div>
              <WidgetFooterButtons
                ref={widgetRef}
                searchId={searchId}
                downloadImageButtonProps={{
                  imageName: downloadedFileName,
                  widgetId: WIDGET_ID,
                }}
                downloadExcelButtonProps={{
                  widgetName: "share-of-search",
                  fileName: downloadedFileName,
                }}
              />
            </>
          )}
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
