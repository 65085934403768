import { FC } from "react";
import cx from "classnames";

import styles from "./TableItemToggler.module.scss";
import { ICON_SIZE } from "../TableCard/constants";
import { ChevronRight } from "../../../../icons";

// Inner imports
import { TableItemTogglerTypes } from "./types";

const TableItemToggler: FC<TableItemTogglerTypes> = ({
  isCollapsed,
  toggleCollapse,
}) => (
  <ChevronRight
    className={cx(
      styles.headerToggler,
      isCollapsed ? styles.isBodyCollapsed : "",
    )}
    size={ICON_SIZE}
    onClick={toggleCollapse}
  />
);

export default TableItemToggler;
