export const texts: {
  [widgetId: string]: {
    customWidgetName?: string;
    aboutThis?: string;
  };
} = {
  "result-sentiment-drivers": {
    customWidgetName: "mentions_custom_name",
    aboutThis: "mentions_about_this_text",
  },
  "result-top-trend-keywords": {
    customWidgetName: "rd_tk_custom_name",
    aboutThis: "rd_tk_about_this_text",
  },
  "result-esg-index": {
    customWidgetName: "rd_esg_custom_name",
    aboutThis: "rd_esg_about_this_text",
  },
  "result-business-drivers": {
    customWidgetName: "rd_interest_drivers_custom_name",
    aboutThis: "rd_interest_drivers_about_this_text",
  },
  "result-reputation-index": {
    customWidgetName: "rd_reputation_custom_name",
    aboutThis: "rd_reputation_about_this_text",
  },
};
