import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ActionsOptions.module.scss";
import * as icons from "../../../../icons";
import { useOutsideClickHandler } from "../../../../hooks";
import { OPEN_BUTTON_ID } from "../../../../containers/Home/Dashboards/components/DashboardContainer/components/Sidebar/constants";

const ActionsOptions: FC<ActionBar.Props["actionsOptions"]> = ({
  placeholder,
  options,
}) => {
  const { t } = useTranslation();
  const actionOptionsRef = useRef(null);

  const [isActionsOptionOpen, setIsActionsOptionOpen] = useState(false);

  const desktopIcons = useMemo(
    () => options.filter(({ isSeparatedOnDesktop }) => isSeparatedOnDesktop),
    [options],
  );

  const isDropdownMenuHiddenOnDesktop = useMemo(
    () => options.every(({ isSeparatedOnDesktop }) => isSeparatedOnDesktop),
    [options],
  );

  const renderActionsOptions = (actionsOptions: ActionBar.ActionsOption[]) => {
    return actionsOptions.map((option) => {
      const {
        id,
        text,
        icon,
        isSeparatedOnDesktop,
        onClickHandler,
        className,
        isDisabled,
      } = option;

      return (
        <li
          className={cx(
            styles.option,
            styles[isSeparatedOnDesktop ? "hiddenOnDesktop" : ""],
          )}
          key={id}
        >
          <button
            className={className}
            onClick={() => {
              onClickHandler();
              setIsActionsOptionOpen(false);
            }}
            disabled={isDisabled}
          >
            {renderIcon(icon)}
            <span>{text}</span>
          </button>
        </li>
      );
    });
  };

  function renderIcon(icon?: AppIcon) {
    const Icon = icon ? icons[icon] : null;
    return Icon && <Icon />;
  }

  useOutsideClickHandler(actionOptionsRef, () => setIsActionsOptionOpen(false));

  return (
    <div className={styles.actionsOptionsWrapper}>
      <div className={styles.desktopIcons}>
        {desktopIcons.map(
          ({ id, text, icon, onClickHandler, className, isDisabled }) => {
            const ID = id === OPEN_BUTTON_ID ? { id } : {};
            return (
              <button
                {...ID}
                className={className}
                data-text={text}
                onClick={onClickHandler}
                key={id}
                disabled={isDisabled}
              >
                {renderIcon(icon)}
              </button>
            );
          },
        )}
      </div>
      <div
        className={cx(
          styles.actionsOptions,
          styles[isActionsOptionOpen ? "actionsOptionsOpened" : ""],
          styles[isDropdownMenuHiddenOnDesktop ? "hiddenOnDesktop" : ""],
        )}
        ref={actionOptionsRef}
      >
        <button
          className={styles.actionsOptionsButton}
          onClick={() => setIsActionsOptionOpen((state) => !state)}
        >
          <div className={styles.dots}>
            <span />
            <span />
            <span />
          </div>
          {placeholder && t(placeholder)}
        </button>
        {isActionsOptionOpen && (
          <ul className={styles.dropDown}>{renderActionsOptions(options)}</ul>
        )}
      </div>
    </div>
  );
};

export { ActionsOptions };
