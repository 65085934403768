const TICK_COLORS = [
  "#7A0D3C", // -100
  "#931540", // -90
  "#DB314C", // -80
  "#FF4451", // -70
  "#FD221C", // -60
  "#FA4D1E", // -50
  "#F8601F", // -40
  "#F9701E", // -30
  "#FB911B", // -20
  "#FDB219", // -10
  "#FCD228", // 0
  "#FDE016", // 10
  "#FFEE05", // 20
  "#F8EB47", // 30
  "#E1EE5E", // 40
  "#CAF175", // 50
  "#A0F56E", // 60
  "#6FE15A", // 70
  "#3BC431", // 80
  "#18AD15", // 90
  "#008E03", // 100
] as const;

const TICK_SIZE = 32;
const TICK_TOP_VALUE = 16;

const MAX_CIRCLE_SIZE = 34;

const CIRCLE_BORDER_WIDTH = 3;

const SPEEDOMETER_WIDTH = 405;
const SPEEDOMETER_HEIGHT = 333;

const TEXT_TOP = 180;

const mainTicks = [...Array(5).keys()].map((i) => {
  const number = -100 + i * 50;
  return {
    deg: -30 + i * 60,
    number: (number > 0 ? "+" : "") + number,
  };
});

const ticks = [...Array(21).keys()].map((i) => {
  const number = -100 + i * 10;
  return {
    deg: -30 + i * 12,
    number: (number > 0 ? "+" : "") + number,
    color: TICK_COLORS[i],
  };
});

export {
  TICK_COLORS,
  TICK_SIZE,
  TICK_TOP_VALUE,
  MAX_CIRCLE_SIZE,
  CIRCLE_BORDER_WIDTH,
  TEXT_TOP,
  SPEEDOMETER_WIDTH,
  SPEEDOMETER_HEIGHT,
  mainTicks,
  ticks,
};
