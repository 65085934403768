import { isNumber } from "../utils";

export function numberFormatter(number: number): string {
  if (!isNumber(number)) return "Wrong format";

  const formatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
}
