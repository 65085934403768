import { FC } from "react";
import { PieChart } from "react-minimal-pie-chart";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";
import { GREEN, RED } from "../../../data/colors";

import styles from "./SentimentIndexPieCharts.module.scss";

type Props = {
  className?: string;
  type?: "Preview" | "Page";
  data: {
    brandName: string;
    score: number;
    pieChartData: {
      value: number;
      color: string;
      title?: string | number;
      key?: string | number;
    }[];
  }[];
};

const LABELS = [
  {
    label: "Positive",
    color: GREEN,
  },
  {
    label: "Negative",
    color: RED,
  },
];

export const SentimentIndexPieCharts: FC<Props> = ({
  className,
  type = "Preview",
  data,
}) => {
  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const size = Math.min(chartWrapperHeight, chartWrapperWidth);
  const elementQuantity = data.length;
  const isTwoLine = elementQuantity > 3;

  const isMobileOnPage = window.innerWidth <= 768 && type === "Page";

  const pieChartWrapperSize = getPieChartWrapperSize();

  function getPieChartWrapperSize() {
    const heighOfObserverPlug = 5;
    const gridGap = isTwoLine ? 20 : 0;
    const line = isTwoLine ? 2 : 1;
    const brandNameHeight = 39 * (isMobileOnPage ? 1 : line);

    return (
      (size - heighOfObserverPlug - gridGap - brandNameHeight) /
      (isMobileOnPage ? 1 : line)
    );
  }

  return (
    <>
      <div className={styles.labels}>
        {LABELS.map(({ label, color }) => (
          <div className={styles.label} key={label}>
            <div
              className={styles.labelIcon}
              style={{ backgroundColor: color }}
            />
            <div>{label}</div>
          </div>
        ))}
      </div>
      <div
        className={cx(
          styles.sentimentIndexPieCharts,
          styles[`sentimentIndexPieCharts${elementQuantity}Elements`],
          styles[`sentimentIndexPieCharts${type}`],
          className,
        )}
        ref={chartWrapperRef}
        style={{
          gridTemplateColumns: `repeat(${
            isTwoLine ? 3 : elementQuantity
          }, 1fr)`,
        }}
      >
        {data.map(({ brandName, score, pieChartData }) => (
          <div
            className={styles.pieChartWrapper}
            key={`${brandName}-${score}`}
            style={{
              maxHeight: pieChartWrapperSize,
              maxWidth: pieChartWrapperSize,
            }}
          >
            <PieChart
              className={styles.pieChart}
              lineWidth={30}
              data={pieChartData}
              totalValue={100}
              label={({ dataEntry }) => {
                return pieChartData[0]?.key !== "noData" ? dataEntry.value : "";
              }}
              labelPosition={84}
              labelStyle={{
                fontSize: 6,
                fontWeight: 600,
              }}
              startAngle={180}
              // eslint-disable-next-line react/no-children-prop
              children={
                <text
                  x="50%"
                  y="50%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={pieChartData[0]?.key === "noData" ? 8 : 16}
                >
                  {pieChartData[0]?.key === "noData"
                    ? "Not enough data"
                    : score}
                </text>
              }
            />
            <div className={styles.brandName}>{brandName}</div>
          </div>
        ))}
      </div>
    </>
  );
};
