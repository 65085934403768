import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ShareOfSearchFunnel.module.scss";
import {
  ErrorPlaceholder,
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
  HorizontalFunnelChart,
  VerticalFunnelChart,
} from "../../../components";
import DATE_RANGES, {
  DATE_RANGES_TYPE,
  INITIAL_DATE_RANGE,
} from "../../../data/dateRanges";
import funnelDescription from "../../../data/funnelDescription";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { getFunnelDataBrands } from "../../helpers";
import { Description, WidgetChart } from "../../types";
import { GRAPH_HEIGHT, WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const ShareOfSearchFunnel: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const [brandIndex, setBrandIndex] = useState<number>(0);

  // Date Range ---->
  const dateRanges = useMemo(() => {
    return DATE_RANGES.filter(({ value }) => widgetData?.[value]);
  }, [widgetData]);

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) setCurrentDateRange(INITIAL_DATE_RANGE);
  }, [dateRanges]);
  // <---- Date Range

  // DATA
  const currentDateRangeFunnel = useMemo(
    () => currentDateRange && widgetData?.[currentDateRange],
    [widgetData, currentDateRange],
  );

  const brands: string[] = useMemo(() => getFunnelDataBrands(widgetData), [
    widgetData,
  ]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const brandValues = currentDateRangeFunnel?.brandValues;
    const brandValue = brandValues?.[brandIndex]?.values;

    if (brandValue) {
      charts.push({
        type: "funnel",
        subType: "horizontal",
        chart: (
          <HorizontalFunnelChart
            data={brandValue.map((item) => ({
              ...item,
              description: t(
                funnelDescription.shareOfSearchFunnel[
                  item.label as keyof typeof funnelDescription.shareOfSearchFunnel
                ],
              ),
            }))}
            graphHeight={GRAPH_HEIGHT}
            widgetId={WIDGET_ID}
          />
        ),
      });
    }

    if (brandValues && brandValues?.length) {
      charts.push({
        type: "funnel",
        subType: "vertical",
        chart: (
          <div className={styles.verticalFunnelWidgets}>
            {brandValues.map(({ brandName, values }, i) => (
              <div className={styles.verticalFunnelChartWrapper} key={i}>
                <VerticalFunnelChart
                  title={brandName}
                  data={values}
                  graphHeight={GRAPH_HEIGHT}
                  widgetId={WIDGET_ID}
                />
              </div>
            ))}
          </div>
        ),
      });
    }

    return charts;
  }, [brandIndex, currentDateRangeFunnel?.brandValues, t]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const {
    id: viewId,
    type: viewType,
    subType: viewSubtype = "",
    chart: viewElement,
  } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const descriptions = useMemo(() => {
    const description: Description = {};

    const brandValues = currentDateRangeFunnel?.brandValues;

    const currentBrandValues = brandValues?.[brandIndex]!.values;

    const firstBrandValues = brandValues?.[0]!.values;

    const highestValues = (brandValues || []).reduce(
      (acc, { brandName, values }) => {
        const topOfMindValue = values[0]!.value.percentage;
        if (topOfMindValue > acc.topOfMind.value)
          acc.topOfMind = {
            brandName,
            value: topOfMindValue,
          };

        const considerationValue = values[1]!.value.percentage;
        if (considerationValue > acc.consideration.value)
          acc.consideration = {
            brandName,
            value: considerationValue,
          };

        return acc;
      },
      {
        topOfMind: {
          brandName: "",
          value: 0,
        },
        consideration: {
          brandName: "",
          value: 0,
        },
      },
    );

    description.horizontal = t("w_sos_funnel_your_brand_main_tip", {
      mainBrandName: brandValues?.[brandIndex]?.brandName,
      interestValue: currentBrandValues?.[0]!.value.percentage,
      buyingValue: currentBrandValues?.[1]!.value.percentage,
      prefersYourBrandValue: currentBrandValues?.[2]!.value.percentage,
      prefersCompetitorBrandValue: firstBrandValues?.[3]!.value.percentage,
    });

    if ((brandValues?.length || 0) > 1) {
      description.vertical = t("w_sos_funnel_competitors_brand_main_tip", {
        highestTopOfMindCompetitor:
          highestValues.topOfMind.brandName &&
          highestValues.topOfMind.brandName,
        highestConsiderationCompetitor:
          highestValues.consideration.brandName &&
          highestValues.consideration.brandName,
      });
    }

    return description;
  }, [currentDateRangeFunnel, brandIndex, t]);
  // <---- Descriptions

  const widgetRef = useRef<HTMLDivElement>(null);

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const isHorizontalFunnelOpen =
    viewType === "funnel" && viewSubtype === "horizontal";

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          timePeriodSelector={{
            periods: dateRanges,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
          brandSelector={
            isHorizontalFunnelOpen
              ? {
                  className: styles.brandSelector,
                  brands: brands,
                  currentBrandIndex: brandIndex,
                  onBrandClicked: setBrandIndex,
                }
              : undefined
          }
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis
          widgetId={WIDGET_ID}
          conclusion={descriptions[viewSubtype] || ""}
        />
      </div>
    </div>
  );
};
