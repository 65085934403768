import { FC, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./TopKeywordsPreview.module.scss";
import {
  Preloader,
  ChartButton,
  ErrorPlaceholder,
  BrandSelector,
} from "../../../components";

// Inner imports
import { formatWordsForWidget } from "./utils";
import {
  useLastAvailableMonthData,
  useTopKeywordsBrandIndex,
  useCurrentBrandIdAndName,
  useDataExistChecker,
} from "./hooks";
import { TopKeywordsWidget } from "./components";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import {
  getWidgetTopWordsData,
  getWidgetTrendingWordsData,
} from "./utils/getWidgetData";

export const TopKeywordsPreview: FC<WidgetsView.Preview> = ({
  searchId,
  tileRef,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const suggestions = useSelector(({ searches }: Store.State) => {
    const search = searches.find(({ id }) => id === searchId);
    return search?.suggestions;
  });

  const brands = useMemo(() => widgetData?.brands.map((el) => el.name) || [], [
    widgetData,
  ]);
  const [wrapperRef, setWrapperRef] = useState<HTMLElement | null>(null);
  const [lastAvailableMonthData] = useLastAvailableMonthData(
    widgetData,
    suggestions,
    "yearly",
  );
  const [brandIndex, setBrandIndex] = useTopKeywordsBrandIndex(
    lastAvailableMonthData,
    widgetData,
    suggestions,
  );

  const [currentBrandId, currentBrandName] = useCurrentBrandIdAndName(
    brandIndex,
    widgetData?.brands || [],
  );

  useEffect(() => {
    setWrapperRef(tileRef?.current || null);
  }, [tileRef]);

  const { isDataExist } = useDataExistChecker(widgetData, isLoading);

  const isBrandsSelectorShown: boolean = useMemo(() => brands.length > 1, [
    brands,
  ]);

  const topWords = useMemo<TopKeywords.TopWords[]>(
    () => getWidgetTopWordsData(widgetData, "yearly"),
    [widgetData],
  );

  const trendingWords = useMemo<TopKeywords.TrendingWords[]>(
    () => getWidgetTrendingWordsData(widgetData, "yearly"),
    [widgetData],
  );

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (!currentBrandId) return charts;

    const brandExcludedWords =
      suggestions?.[currentBrandName]?.topKeywordsExcludedWords || [];

    const {
      topWords: lastMonthTopWords,
      trendingWord: lastMonthTrendingWords,
    } = lastAvailableMonthData;

    // Check
    const isTopWordsDataAvailable = Boolean(topWords.length);
    const isTrendingWordsDataAvailable = Boolean(trendingWords.length);

    if (isTopWordsDataAvailable) {
      const data = formatWordsForWidget(
        "volume",
        lastMonthTopWords,
        currentBrandId,
        brandExcludedWords,
      );

      charts.push({
        type: "keywords",
        subType: "topWords",
        isError: !data.length,
        chart: !!data.length ? (
          <TopKeywordsWidget
            data={data}
            parent={wrapperRef ?? undefined}
            type="preview"
            className={styles.topKeywordsWidget}
          />
        ) : (
          <div className={styles.error}>
            {t("w_trending_keywords_no_data_for_period_error")}
          </div>
        ),
      });
    }

    if (isTrendingWordsDataAvailable) {
      const data = formatWordsForWidget(
        "trend",
        lastMonthTrendingWords,
        currentBrandId,
        brandExcludedWords,
      );

      const isTopKeywordsDataBroken = charts?.[0]?.isError === true;
      const chartsMethod: "unshift" | "push" = isTopKeywordsDataBroken
        ? "unshift"
        : "push";

      charts[chartsMethod]({
        type: "keywords",
        subType: "trendingWords",
        isError: !data.length,
        chart: !!data.length ? (
          <TopKeywordsWidget
            data={data}
            parent={wrapperRef ?? undefined}
            type="preview"
            customStyles={{
              tagColor: "white",
              tagBackgroundColor: "#0A99BC",
            }}
            className={styles.topKeywordsWidget}
          />
        ) : (
          <div className={styles.error}>
            {t("w_trending_keywords_no_data_for_period_error")}
          </div>
        ),
      });
    }

    return charts;
  }, [
    topWords,
    trendingWords,
    suggestions,
    currentBrandName,
    currentBrandId,
    lastAvailableMonthData,
    wrapperRef,
    t,
  ]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div
      className={styles.widgetPreview}
      ref={(chartRef) => chartRef && setChartRef(chartRef)}
    >
      {isDataExist ? (
        <>
          <div className={styles.buttons}>
            {isBrandsSelectorShown && (
              <BrandSelector
                className={styles.brandSelector}
                brands={brands}
                currentBrandIndex={brandIndex}
                onBrandClicked={setBrandIndex}
                isEventPropagationStopped
              />
            )}
            {views.map(({ buttonIcon, buttonNumber }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  number={buttonNumber}
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div className={styles.chartWrapper} onClick={onChartClicked}>
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
