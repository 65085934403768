import React, { useState, useMemo, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import classes from "./Speedometer.module.scss";
import { normalizeNumber } from "../../../../../utils";

// Inner imports
import {
  TICK_COLORS,
  TICK_SIZE,
  TICK_TOP_VALUE,
  MAX_CIRCLE_SIZE,
  CIRCLE_BORDER_WIDTH,
  TEXT_TOP,
  SPEEDOMETER_WIDTH,
  SPEEDOMETER_HEIGHT,
  mainTicks,
  ticks,
} from "./constants";

type Props = {
  className?: string;
  type?: "preview" | "page";
  style?: React.CSSProperties;
  data: readonly {
    brandName: string;
    values: {
      prev: number;
      current: number;
      delta: number;
    };
    color: string;
  }[];
  fitTo?: "width" | "height";
};

export const Speedometer = ({
  className = "",
  type = "preview",
  style,
  data,
  fitTo = "width",
}: Props) => {
  const ADDITIONAL_SIZE_FOR_CENTER_POINT = type === "preview" ? 18 : 0;

  const { t } = useTranslation();

  const [mainBrandDegree, setMainBrandDegree] = useState(-30);
  const [isRoundPointerHovered, setIsRoundPointerHovered] = useState(false);

  const mainBrandName = useMemo(() => data[0]?.brandName || "", [data]);

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const scale = useMemo(() => {
    if (fitTo === "width") return chartWrapperWidth / SPEEDOMETER_WIDTH;

    return Math.min(
      chartWrapperWidth / SPEEDOMETER_WIDTH,
      chartWrapperHeight / SPEEDOMETER_HEIGHT,
    );
  }, [chartWrapperWidth, chartWrapperHeight, fitTo]);

  const [circleSize, setCircleSize] = useState(0);

  const circlesWorkingAreaWidth = useMemo(() => {
    if (!chartWrapperWidth) return 0;
    const circleQuantity = data.length;

    const mainTickSize = TICK_SIZE + TICK_TOP_VALUE;
    const tickSize = TICK_SIZE + 6 + TICK_TOP_VALUE;
    const circleBordersWidth = circleQuantity * CIRCLE_BORDER_WIDTH;

    return 345 - mainTickSize - tickSize - circleBordersWidth;
  }, [chartWrapperWidth, data.length]);

  useEffect(() => {
    if (circleSize) return;
    const circleQuantity = data.length;

    const countedSize = circlesWorkingAreaWidth / circleQuantity;

    const size = countedSize > MAX_CIRCLE_SIZE ? MAX_CIRCLE_SIZE : countedSize;
    circlesWorkingAreaWidth && setCircleSize(size);
  }, [circlesWorkingAreaWidth, data.length, circleSize]);

  const brands = useMemo(() => {
    const dataByViewType = type === "preview" ? data.slice(0, 1) : data;
    return dataByViewType.map(({ values, color }, i, arr) => {
      const SIZE_INCREMENT = circleSize;
      const size = circleSize + SIZE_INCREMENT * i;
      const number = normalizeNumber(values.current, 100);
      return {
        degree: convertNumberToDegree(number),
        number: (number > 0 ? "+" : "") + number,
        offset: -size / 2,
        size,
        borderWidth: SIZE_INCREMENT / 2 - 1,
        zIndex: arr.length - i,
        color,
        lineLength: 115 - size / 2,
      };
    });
  }, [type, data, circleSize]);

  useEffect(() => {
    (async () => {
      setMainBrandDegree(-30);
      setTimeout(() => {
        const number = normalizeNumber(data[0]!.values.current, 100);
        const degree = convertNumberToDegree(number);
        setMainBrandDegree(degree);
      }, 10);
    })();
  }, [data]);

  function convertNumberToDegree(number: number) {
    return (number * 120) / 100 + 90;
  }

  const CurvedText = (text: string, angle = 0, color?: string) => {
    const LETTER_OFFSET_ANGLE = 1.9;
    const letters = text.split("").map((letter, i) => ({
      letter,
      deg:
        angle -
        (text.length / 2) * LETTER_OFFSET_ANGLE +
        i * LETTER_OFFSET_ANGLE,
    }));
    return letters.map(({ letter, deg }, i) => (
      <div
        className={classes.curvedLetter}
        style={{
          color,
          transform: `rotate(${deg}deg) translate(-${TEXT_TOP}px)`,
        }}
        key={i}
      >
        <div>{letter}</div>
      </div>
    ));
  };

  const getMainTickClassName = (index: number) => {
    if (index < 2) return "mainTickLeft";
    if (index > 2) return "mainTickRight";
    return "mainTickCenter";
  };

  return data.length ? (
    <div className={cx(classes.speedometer, className)} style={style}>
      {type === "preview" && (
        <div className={classes.headMessage}>
          <Trans
            i18nKey="marketometer_change_value_text_preview"
            values={{
              mainBrandName,
              change: data[0]!.values.delta,
            }}
          />
        </div>
      )}

      <div
        ref={chartWrapperRef}
        className={classes.widgetWrapperOuter}
        style={{ ...(fitTo === "width" && { paddingBottom: "73%" }) }}
      >
        <div className={classes.widgetWrapperInner}>
          <div
            className={classes.widget}
            style={{
              transform: `scale(${scale})`,
              height: SPEEDOMETER_HEIGHT,
              width: SPEEDOMETER_WIDTH,
            }}
          >
            <div className={classes.center}>
              {type === "page" && (
                <>
                  {CurvedText(
                    t("marketometer_market_is_slow_label"),
                    0,
                    TICK_COLORS[2],
                  )}
                  {CurvedText(
                    t("marketometer_slow_to_neutral_label"),
                    60,
                    TICK_COLORS[7],
                  )}
                  {CurvedText(
                    t("marketometer_neutral_to_quite_good_label"),
                    120,
                    TICK_COLORS[12],
                  )}
                  {CurvedText(
                    t("marketometer_good_to_fantastic_label"),
                    180,
                    TICK_COLORS[17],
                  )}
                </>
              )}
              {mainTicks.map(({ deg, number }, i) => (
                <div
                  className={cx(
                    classes.mainTick,
                    classes[getMainTickClassName(i)],
                  )}
                  style={{
                    transform: `rotate(${deg}deg) translate(-${TEXT_TOP}px)`,
                    width: TICK_SIZE,
                    height: TICK_SIZE,
                    top: -TICK_TOP_VALUE,
                  }}
                  key={i}
                >
                  <div style={{ transform: `rotate(${-deg}deg)` }}>
                    {number}
                  </div>
                </div>
              ))}
              {ticks.map(({ deg, number, color }, i) => (
                <div
                  className={classes.tick}
                  style={{
                    backgroundColor: color,
                    transform: `rotate(${deg}deg) translate(-145px)`,
                    width: TICK_SIZE + 6,
                    height: 30,
                    top: -15,
                  }}
                  key={i}
                >
                  {type === "page" && (
                    <div style={{ transform: `rotate(${-deg}deg)` }}>
                      {number}
                    </div>
                  )}
                </div>
              ))}
              <div
                onMouseEnter={() => setIsRoundPointerHovered(true)}
                onMouseLeave={() => setIsRoundPointerHovered(false)}
              >
                {brands
                  .slice(1)
                  .map(
                    (
                      {
                        degree,
                        number,
                        offset,
                        size,
                        borderWidth,
                        zIndex,
                        color,
                        lineLength,
                      },
                      i,
                    ) => (
                      <div
                        className={classes.roundPointer}
                        style={{
                          left: offset,
                          top: offset,
                          width: size,
                          height: size,
                          borderColor: color,
                          zIndex,
                          transform: `rotate(${degree}deg)`,
                        }}
                        key={i}
                      >
                        <div style={{ borderWidth, borderColor: color }} />
                        <div style={{ transform: `rotate(${-degree}deg)` }}>
                          {number}
                        </div>
                        <div
                          style={{
                            left: -lineLength,
                            width: lineLength,
                            backgroundColor: color,
                          }}
                        />
                        <div
                          style={{
                            left: -lineLength - 10,
                            backgroundColor: color,
                          }}
                        />
                      </div>
                    ),
                  )}
              </div>
              <div
                className={cx(
                  classes.arrowPointer,
                  classes[type === "preview" ? "arrowPointerPreview" : ""],
                )}
                style={{
                  left: brands[0]!.offset,
                  top: brands[0]!.offset,
                  width: brands[0]!.size + ADDITIONAL_SIZE_FOR_CENTER_POINT,
                  height: brands[0]!.size + ADDITIONAL_SIZE_FOR_CENTER_POINT,
                  backgroundColor: brands[0]!.color,
                  zIndex: brands[0]!.zIndex,
                  transform: `rotate(${mainBrandDegree}deg)`,
                }}
              >
                <div
                  style={{
                    color: "#20263b",
                    transform: `rotate(${-mainBrandDegree}deg)`,
                  }}
                >
                  {brands[0]!.number}
                </div>
                <div
                  style={{
                    left: -brands[0]!.lineLength - 16,
                    borderRightWidth: brands[0]!.lineLength + 20,
                    borderRightColor: brands[0]!.color,
                    opacity: isRoundPointerHovered ? 0.5 : 1,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        {/* {type === "page" && (
          <div className={classes.message}>
            <Trans
              i18nKey="marketometer_change_value_text"
              values={{
                change: data[0]!.values.delta,
              }}
            />
          </div>
        )} */}
        <div
          className={classes.explanation}
          style={{
            width: "70%",
          }}
        >
          <div>{t("marketometer_started_from_text")}</div>
          <div>{t("marketometer_middle_text")}</div>
          <div>{t("marketometer_end_text")}</div>
        </div>
      </div>
    </div>
  ) : null;
};
