import { FC, useContext, useMemo, useRef, isValidElement } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ActionBar.module.scss";
import SidebarToggler from "../SidebarToggler/SidebarToggler";
import { Breadcrumbs, ScrollToButton, LastSyncDataBar } from "../../components";
import context from "../../context";

// Inner imports
import {
  ActionsOptions,
  AdditionalButton,
  ActionBarSearchBar,
  MultiSelectButton,
} from "./components/";

const ActionBar: FC<ActionBar.Props> = ({
  title,
  lastSync,
  breadcrumbs,
  multiSelectButton,
  additionalButton,
  searchBar,
  actionsOptions,
}) => {
  const { t } = useTranslation();

  const rightBarRef = useRef<HTMLDivElement>(null);

  const { isPresentation } = useContext(context);

  const isAuthenticated: boolean = useSelector(
    ({ user }: Store.State) => !!user.id,
  );

  const isActionBarEmpty = useMemo(
    () => !title && !breadcrumbs && !actionsOptions,
    [breadcrumbs, actionsOptions, title],
  );

  const isFootSectionEmpty = useMemo(() => !breadcrumbs?.length && !lastSync, [
    breadcrumbs?.length,
    lastSync,
  ]);

  const renderTitle = useMemo(() => {
    if (typeof title === "string") {
      return (
        <p className={styles.title}>
          {t(title, {
            keySeparator: false,
          })}
        </p>
      );
    }

    return title;
  }, [t, title]);

  const renderAdditionalButton = useMemo(() => {
    switch (true) {
      case !additionalButton:
        return null;
      case isValidElement(additionalButton):
        return additionalButton;
      case additionalButton && "onClickHandler" in additionalButton:
        return (
          <AdditionalButton
            {...(additionalButton as ActionBar.AdditionalButton)}
          />
        );
      default:
        return null;
    }
  }, [additionalButton]);

  if (isActionBarEmpty) return null;

  return (
    <div className={styles.actionBar}>
      <div className={styles.content}>
        <span className={styles.plug} />
        <div className={styles.actions}>
          <div className={styles.leftSide}>
            {!isPresentation && isAuthenticated && <SidebarToggler />}
            {renderTitle}
          </div>
          <div className={styles.rightSide} ref={rightBarRef}>
            {searchBar && <ActionBarSearchBar {...searchBar} />}
            {renderAdditionalButton}
            {multiSelectButton && <MultiSelectButton {...multiSelectButton} />}
            {!isPresentation && !!actionsOptions?.options.length && (
              <ActionsOptions {...actionsOptions} />
            )}
          </div>
        </div>
      </div>
      {!isFootSectionEmpty && !isPresentation && (
        <div className={styles.footSection}>
          {!!breadcrumbs?.length && (
            <Breadcrumbs options={breadcrumbs} className={styles.breadcrumbs} />
          )}
          {lastSync && <LastSyncDataBar {...lastSync} />}
          <ScrollToButton className={styles.scrollButton} />
        </div>
      )}
    </div>
  );
};

export default ActionBar;
