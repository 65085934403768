import actionTypes from "../actionTypes";

const initialState: Store.KeyWordsWidgetsUpdateDate = {
  keyWordsWidgetsUpdateDate: "",
};

const setKeyWordsWidgetsUpdateDate = (action: {
  keyWordsWidgetsUpdateDate: Store.KeyWordsWidgetsUpdateDate;
}) => {
  return action.keyWordsWidgetsUpdateDate;
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_KEYWORDS_WIDGETS_UPDATE_DATE:
      return setKeyWordsWidgetsUpdateDate(action);
    default:
      return state;
  }
};

export default reducer;
