import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react";
import cx from "classnames";
import styles from "./Page.module.scss";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Page = forwardRef(
  ({ className, children }: Props, ref: React.Ref<HTMLDivElement>) => (
    <div className={cx(styles.page, className)} ref={ref}>
      {children}
    </div>
  ),
);
