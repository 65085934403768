import actionTypes from "../actionTypes";

const initialState: Store.Searches = [];

const setSearches = (action: { searches: Store.Searches }) => {
  return action.searches;
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SEARCHES:
      return setSearches(action);
    default:
      return state;
  }
};

export default reducer;
