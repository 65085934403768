/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

export default React.createContext({
  modalElement: null as React.ReactNode,
  setModalElement: (
    value: React.ReactNode,
    isWithCloseButton: boolean = true,
  ) => {},
  isGlobalPreloaderShown: false,
  isWithCloseButton: true,
  setIsGlobalPreloaderShown: (value: boolean) => {},
  isDarkTheme: false,
  setIsDarkTheme: (value: boolean) => {},
  isSidebarOpen: false,
  setIsSidebarOpen: (value: boolean) => {},
  isPresentation: false,
  setIsPresentation: (value: boolean) => {},
  actionBarProps: {},
  setActionBarProps: ({}: ActionBar.Props, merge: boolean = false) => {},
  keywordToolLastUpdateDate: "",
  setKeywordToolLastUpdateDate: (value: string) => {},
});
