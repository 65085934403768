import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./TopKeywordsListView.module.scss";
import * as icons from "src/icons";

// Inner imports
import { roundNumberToDecimals, sortWords } from "./utils";
import type {
  TopKeywordsListViewProps,
  TrendingWordFormatted,
  TopWordFormatted,
} from "./types";

export const TopKeywordsListView = memo(
  ({ className, topWords, topTrendingWords }: TopKeywordsListViewProps) => {
    const { t } = useTranslation();

    const [topWordsSortBy, setTopWordsSortBy] = useState<
      keyof TopWordFormatted
    >("count");
    const [
      topWordsSortDirection,
      setTopWordsSortDirection,
    ] = useState<SortDirection>("DESC");

    const [trendingWordsSortBy, setTrendingWordsSortBy] = useState<
      keyof TrendingWordFormatted
    >("count");
    const [
      trendingWordsSortDirection,
      setTrendingWordsSortDirection,
    ] = useState<SortDirection>("DESC");

    const sortedTopWords = useMemo(() => {
      if (!topWords) return [];

      if (!topWordsSortBy || !topWordsSortDirection) return [];

      return [...topWords].sort((a, b) =>
        sortWords(a, b, topWordsSortBy, topWordsSortDirection),
      );
    }, [topWords, topWordsSortBy, topWordsSortDirection]);

    const sortedTopTrendingWords = useMemo(() => {
      if (!topTrendingWords) return [];

      if (!trendingWordsSortBy || !trendingWordsSortDirection) return [];

      return [...topTrendingWords].sort((a, b) =>
        sortWords(a, b, trendingWordsSortBy, trendingWordsSortDirection),
      );
    }, [topTrendingWords, trendingWordsSortBy, trendingWordsSortDirection]);

    const setTopWordsSort = (sortBy: keyof TopWordFormatted): void => {
      const isSameSortBy = topWordsSortBy === sortBy;

      if (isSameSortBy) {
        setTopWordsSortDirection((state) => (state === "ASC" ? "DESC" : "ASC"));
      } else {
        setTopWordsSortBy(sortBy);
        setTopWordsSortDirection("DESC");
      }
    };

    const setTrendingWordsSort = (
      sortBy: keyof TrendingWordFormatted,
    ): void => {
      const isSameSortBy = trendingWordsSortBy === sortBy;

      if (isSameSortBy) {
        setTrendingWordsSortDirection((state) =>
          state === "ASC" ? "DESC" : "ASC",
        );
      } else {
        setTrendingWordsSortBy(sortBy);
        setTrendingWordsSortDirection("DESC");
      }
    };

    return (
      <div className={cx(styles.listWrapper, className)}>
        {!!sortedTopWords.length ? (
          <table>
            <thead>
              <tr>
                <th className={styles.index} />
                <th align="left">{t("w_tk_list_top_words")}</th>
                <th align="right" className={styles.value}>
                  <button
                    onClick={() => setTopWordsSort("count")}
                    className={cx(
                      styles.sortButton,
                      topWordsSortDirection === "DESC" && styles.descending,
                    )}
                  >
                    <icons.Triangle size={8} />
                    {t("w_tk_list_search_volume")}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedTopWords.map(({ value, count }, i) => (
                <tr key={`${value}_${count}`}>
                  <td>{i + 1}.</td>
                  <td>{value}</td>
                  <td align="right">{roundNumberToDecimals(count, 1)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.stub}>
            {t("w_list_no_data_for_period_error", { type: "Top words" })}
          </div>
        )}
        {!!sortedTopTrendingWords.length ? (
          <table>
            <thead>
              <tr>
                <th className={styles.index} />
                <th align="left">{t("w_tk_list_trending_words")}</th>
                <th align="right" className={styles.value}>
                  <button
                    onClick={() => setTrendingWordsSort("volume")}
                    className={cx(
                      styles.sortButton,
                      trendingWordsSortDirection === "DESC" &&
                        styles.descending,
                    )}
                  >
                    {trendingWordsSortBy === "volume" && (
                      <icons.Triangle size={8} />
                    )}
                    {t("w_tk_list_volume")}
                  </button>
                </th>
                <th align="right" className={styles.value}>
                  <button
                    onClick={() => setTrendingWordsSort("count")}
                    className={cx(
                      styles.sortButton,
                      trendingWordsSortDirection === "DESC" &&
                        styles.descending,
                    )}
                  >
                    {trendingWordsSortBy === "count" && (
                      <icons.Triangle size={8} />
                    )}
                    {t("w_tk_list_trend")}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedTopTrendingWords.map(({ value, volume, count }, i) => (
                <tr key={`${value}_${count}`}>
                  <td>{i + 1}.</td>
                  <td>{value}</td>
                  <th align="right">
                    {volume ? roundNumberToDecimals(volume, 1) : "-"}
                  </th>
                  <td
                    align="right"
                    style={{
                      color: count > 0 ? "#41B167" : "#ef336a",
                    }}
                  >
                    {`${roundNumberToDecimals(count, 1)}%`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.stub}>
            {t("w_list_no_data_for_period_error", { type: "Trending words" })}
          </div>
        )}
      </div>
    );
  },
);
