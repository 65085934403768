import reputationParameters from "../../../data/reputationParameters";

const WIDGET_ID = "result-reputation-index";

const LINE_CHART_LABELS = reputationParameters.map(({ name, color }) => ({
  id: name,
  name,
  color,
}));

export { WIDGET_ID, LINE_CHART_LABELS };
