const requirements = {
  notEnoughCharacters: "pe_min_characters",
  oneLowercaseCharacter: "pe_lowercase_character",
  oneUppercaseCharacter: "pe_uppercase_character",
  oneNumber: "pe_numbers",
};

const REGEXPS = {
  passwordLengthRegex: new RegExp(".{8,}"),
  oneLowercaseCharacterRegex: new RegExp("(?=.*?[a-z])"),
  oneUppercaseCharacterRegex: new RegExp("(?=.*?[A-Z])"),
  atLeastOneNumberRegex: new RegExp("(?=.*?[0-9])"),
  atLeastOneSpecialSymbol: new RegExp("(?=.*?[#?!@$%^&*-])"),
};

export { requirements, REGEXPS };
