import { memo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ChangeSearchWarningModal.module.scss";

// inner imports
import type { ChangeSearchWarningModalProps } from "./types";

export const ChangeSearchWarningModal = memo(
  ({ onNoClickHandler, onYesClickHandler }: ChangeSearchWarningModalProps) => {
    const { t } = useTranslation();
    return (
      <div className={styles.switchWarningWrapper}>
        <div className={styles.switchWarning}>
          <p>{t("rd_without_saving_modal_title")}</p>
          <div className={styles.switchWarningButtons}>
            <button onClick={onNoClickHandler}>
              {t("rd_without_saving_modal_no_btn")}
            </button>
            <button onClick={onYesClickHandler}>
              {t("rd_without_saving_modal_yes_btn")}
            </button>
          </div>
        </div>
      </div>
    );
  },
);
