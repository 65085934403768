import { memo, ReactElement, useEffect } from "react";
import cx from "classnames";
import styles from "./ErrorPlaceholder.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  customClass?: string;
  customText?: string;
  onMountCallBack?: () => void;
};

const memoFunction = (prevState: Props, nextState: Props) => {
  const {
    customClass: prevStateCustomClass,
    customText: prevStateCustomText,
  } = prevState;
  const {
    customClass: nextStateCustomClass,
    customText: nextStateCustomText,
  } = nextState;

  const isCustomClassTheSame = prevStateCustomClass === nextStateCustomClass;
  const isCustomTextTheSame = prevStateCustomText === nextStateCustomText;

  return isCustomClassTheSame && isCustomTextTheSame;
};

export const ErrorPlaceholder = memo(
  ({ customText, customClass = "", onMountCallBack }: Props): ReactElement => {
    const { t } = useTranslation();

    useEffect(() => onMountCallBack && onMountCallBack(), [onMountCallBack]);

    return (
      <div className={cx(styles.ErrorPlaceholder, customClass)}>
        <span>{customText ?? t("wt_no_enough_data_error_new")}</span>
      </div>
    );
  },
  memoFunction,
);
