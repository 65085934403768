import { FC } from "react";
import DatePicker from "react-datepicker";
import cx from "classnames";

import styles from "./TimePlotterDatePicker.module.scss";
import { BookDemo } from "src/icons";

// inner imports
import { DATE_FORMAT } from "../../constants";
import { popperModifiers } from "./constants";
import { TimePlotterDatePickerProps } from "./types";

const TimePlotterDatePicker: FC<TimePlotterDatePickerProps> = ({
  onChange,
  value,
  placeholder,
  selectedValue,
  popperPlacement,
  minDate,
}) => (
  <div className={styles.wrapper}>
    <DatePicker
      popperPlacement={popperPlacement}
      className={cx(styles.input, value ? styles.fillInput : "")}
      wrapperClassName={styles.datePickerWrapper}
      calendarClassName={styles.datePickerCalendar}
      selected={selectedValue}
      onChange={onChange}
      value={value}
      showYearDropdown
      dateFormat={DATE_FORMAT}
      placeholderText={placeholder}
      yearDropdownItemNumber={1}
      dateFormatCalendar="MMMM"
      minDate={minDate}
      popperModifiers={popperModifiers}
      onChangeRaw={(e) => e.preventDefault()}
    />
    <BookDemo className={styles.icon} />
  </div>
);

export default TimePlotterDatePicker;
