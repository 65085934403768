import { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./SubregionsMultiSelect.module.scss";
import { Trash, Triangle } from "src/icons";
import { useOutsideClickHandler } from "src/hooks";

// Inner imports
import { Props, OpeningDirection } from "./types";
import { MAX_REGIONS_NUMBER, ICONS_SIZE } from "./constants";

export const SubregionsMultiSelect = ({
  inputRef,
  className = "",
  style,
  options = [],
  initialSelectedOptions = [],
  doneSelectingHandler = () => [],
  placeholder = "Select here...",
  isEnabled = true,
}: Props) => {
  const { t } = useTranslation();

  const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
    "down",
  );
  const [isErrorMessageShow, setErrorMessageShow] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    initialSelectedOptions,
  );

  useEffect(() => {
    if (!options.length) {
      setSelectedOptions([]);
    }
  }, [options]);

  const filteredOptions = options.filter((option) => {
    const selectedOption = !selectedOptions.find(
      (el) => el.label.toLowerCase() === option.label.toLowerCase(),
    );
    const filteredOption = option.label
      .toLowerCase()
      .includes(filter.trim().toLowerCase());
    return selectedOption && filteredOption;
  });

  const allSelected: boolean =
    !selectedOptions.length || selectedOptions.length === options.length;

  const selectRef = useRef<HTMLDivElement>(null);

  const onSelect = (option: Option): void => {
    setSelectedOptions((state) => [...state, option]);
  };

  const onDeselect = (option: Option): void => {
    const newState = selectedOptions.filter((el) => el.label !== option.label);
    setSelectedOptions(newState);
    setErrorMessageShow(false);
  };

  useOutsideClickHandler(selectRef, () => {
    doneSelectingHandler(selectedOptions);
    setIsOpen(false);
  });

  const onHandleCheck = (option: Option): void =>
    selectedOptions.length < MAX_REGIONS_NUMBER
      ? onSelect(option)
      : setErrorMessageShow(true);

  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);

  useEffect(() => {
    const _setOpeningDirection = () => {
      const clientHeight = document.documentElement.clientHeight;
      const selectTop = selectRef.current?.getBoundingClientRect().top!;
      setOpeningDirection(selectTop > clientHeight / 2 ? "up" : "down");
    };
    _setOpeningDirection();
    window.addEventListener("resize", _setOpeningDirection);
    return () => {
      window.removeEventListener("resize", _setOpeningDirection);
    };
  }, []);

  const listOfSelectedOptions: string = useMemo(
    () => selectedOptions.map((el) => el.label).join(", "),
    [selectedOptions],
  );

  return (
    <div
      className={cx(styles.subregionsMultiSelect, className)}
      style={style}
      ref={selectRef}
    >
      <div
        ref={inputRef}
        className={styles.input}
        style={isEnabled ? undefined : { cursor: "not-allowed" }}
        onClick={
          isEnabled ? () => setIsOpen((prevIsOpen) => !prevIsOpen) : undefined
        }
        tabIndex={0}
      >
        {!!selectedOptions.length ? (
          <div className={styles.label} title={listOfSelectedOptions}>
            {listOfSelectedOptions}
          </div>
        ) : (
          <div className={styles.placeholder} title={placeholder}>
            {placeholder}
          </div>
        )}
        <div className={styles.arrowIconWrapper}>
          <Triangle
            style={{ ...(!isOpen && { transform: "rotate(180deg)" }) }}
            size={10}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className={cx(
            styles.popup,
            styles[openingDirection === "up" ? "popupUp" : "popupDown"],
          )}
          tabIndex={1}
        >
          <div className={styles.popupOptions}>
            {options.length > MAX_REGIONS_NUMBER && (
              <input
                className={styles.filterInput}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={t("dc_regions_filter_placeholder")}
              />
            )}
            {options.length ? (
              <div className={styles.options}>
                {allSelected && (
                  <div
                    className={cx(styles.option, styles.allSelected)}
                    title={t("dc_regions_placeholder")}
                  >
                    <input type="checkbox" checked={true} onChange={() => {}} />
                    {t("dc_regions_placeholder")}
                  </div>
                )}
                {isErrorMessageShow && (
                  <div className={styles.errorMessage}>
                    {t("dc_regions_max_regions_have_chosen_message", {
                      maxRegionsNumber: MAX_REGIONS_NUMBER,
                    })}
                  </div>
                )}
                {filteredOptions.map((option, i) => (
                  <button
                    type="button"
                    className={styles.option}
                    onClick={() => onHandleCheck(option)}
                    title={option.label}
                    key={i}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            ) : (
              <div className={styles.noOptions}>
                {t("dc_regions_no_results_found_message")}
              </div>
            )}
          </div>
          <div className={styles.popupSelectedOptions}>
            <div className={styles.wrapper}>
              {selectedOptions.map((option, i) => (
                <button
                  type="button"
                  className={styles.selectedOption}
                  onClick={() => onDeselect(option)}
                  title={option.label}
                  key={i}
                >
                  <Trash width={ICONS_SIZE.TRASH} />
                  <span>{option.label}</span>
                </button>
              ))}
            </div>

            <button
              type="button"
              className={styles.doneBtn}
              onClick={() => {
                doneSelectingHandler(selectedOptions);
                setIsOpen(false);
              }}
            >
              {t("dc_regions_done_btn_text")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
