import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ShareOfEarnedMedia.module.scss";
import {
  PieChartWithInnerLabels,
  LineChart,
  ErrorPlaceholder,
  WidgetButtonsBar,
  WidgetFooterButtons,
  Preloader,
  AboutThis,
} from "../../../components";
import DATE_RANGES from "../../../data/dateRanges";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import {
  checkIfPieChartDataByTimePeriodExist,
  checkIfLineChartDataExist,
} from "./utils";
import { WIDGET_ID, VIEW_LINE_CHART } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

type DATE_RANGES_TYPE = typeof DATE_RANGES[number]["value"] | undefined;

export const ShareOfEarnedMedia: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const dateRanges = useMemo(() => {
    return DATE_RANGES.filter(({ value }) => widgetData?.pieChart[value]);
  }, [widgetData]);

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    if (!widgetData || currentDateRange) return;
    const initialDateRange = dateRanges[dateRanges.length - 1]?.value;
    setCurrentDateRange(initialDateRange);
  }, [dateRanges, currentDateRange, widgetData]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { brands, lineChart, pieChart } = widgetData || {};

    if (Object.keys(pieChart || {}).length) {
      const pieChartData: ShareOfEarnedMedia.PieChart.Value[] =
        pieChart?.[currentDateRange!]?.values || [];

      charts.push({
        type: "pieChart",
        chart: checkIfPieChartDataByTimePeriodExist(pieChartData) ? (
          <PieChartWithInnerLabels
            className={styles.pieChart}
            data={pieChartData}
            fitTo="height"
          />
        ) : (
          <ErrorPlaceholder />
        ),
      });
    }

    if (lineChart?.length) {
      charts.push({
        type: "lineChart",
        chart: checkIfLineChartDataExist(lineChart) ? (
          <LineChart
            className={styles.lineChart}
            labels={brands}
            data={lineChart}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              graphHeight: VIEW_LINE_CHART.GRAPH_HEIGHT,
              lineStrokeWidth: VIEW_LINE_CHART.STROKE_WIDTH,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_som_widget_name"),
              yAxisUnit: VIEW_LINE_CHART.Y_AXIS_UNIT,
              yAxisVerticalPadding: VIEW_LINE_CHART.VERTICAL_PADDING,
            }}
          />
        ) : (
          <ErrorPlaceholder />
        ),
      });
    }

    return charts;
  }, [currentDateRange, widgetData, dashboardId, searchId, t]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Description ---->
  const firstBrand = widgetData?.brands[0];

  const descriptionInsertion = widgetData?.pieChart[
    currentDateRange!
  ]?.values.find(({ brandName }) => brandName === firstBrand!.name)?.value;

  const conclusion = useMemo(() => {
    return typeof descriptionInsertion === "number"
      ? t("w_som_main_tip", {
          mainBrandName: firstBrand?.name,
          descriptionInsertion,
        })
      : "";
  }, [descriptionInsertion, firstBrand?.name, t]);
  // <----- Description

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={cx(styles.widget, styles.shareOfEarnedMedia)}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "lineChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            downloadExcelButtonProps={{
              widgetName: "share-of-media",
              fileName: downloadedFileName,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
