export const getWidgetTopWordsData = (
  widgetData?: TopKeywords.Data,
  timeRangeType: TopKeywords.TimeRangeType = "monthly",
): TopKeywords.TopWords[] => {
  const topWords = widgetData?.topWords || [];

  if (Array.isArray(topWords)) return topWords;

  const topWordsPerTimeRangeType = topWords[timeRangeType];

  if (Array.isArray(topWordsPerTimeRangeType)) return topWordsPerTimeRangeType;

  return [
    {
      date: "",
      values: topWordsPerTimeRangeType,
    },
  ];
};

export const getWidgetTrendingWordsData = (
  widgetData?: TopKeywords.Data,
  timeRangeType: TopKeywords.TimeRangeType = "monthly",
): TopKeywords.TrendingWords[] => {
  const trendingWords = widgetData?.trendingWords || [];

  if (Array.isArray(trendingWords)) return trendingWords;

  const trendingWordsPerTimeRangeType = trendingWords[timeRangeType];

  if (Array.isArray(trendingWordsPerTimeRangeType))
    return trendingWordsPerTimeRangeType;

  return [
    {
      date: "",
      values: trendingWordsPerTimeRangeType,
    },
  ];
};
