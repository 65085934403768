import { GRAY, GREEN, RED } from "../../../data/colors";

// Inner imports
import {
  FULL_DATA_IN_PERCENTAGE,
  PIE_CHART_DATA_BRANDS_LIMIT,
} from "./constants";
import { PieChartData } from "./types";

const preparePieChartData = (
  values: {
    [brandId: string]: SentimentIndex.BrandValue;
  },
  brands: Store.Brands,
) => {
  if (brands.length > PIE_CHART_DATA_BRANDS_LIMIT) return [];

  const brandsNames = brands.map(({ name }) => name);

  return Object.entries(values || {})
    .map(([brandName, { positive, negative, score }]) => {
      const { name } = brands.find(({ id }) => id === brandName) || {};

      const pieChartData: PieChartData = [];

      if (positive) {
        pieChartData.push({
          title: "Positive",
          key: "positive",
          value: positive,
          color: GREEN,
        });
      }

      if (negative) {
        pieChartData.push({
          title: "Negative",
          key: "negative",
          value: negative,
          color: RED,
        });
      }

      if (!positive && !negative) {
        pieChartData.push({
          title: "Not enough data",
          key: "noData",
          value: FULL_DATA_IN_PERCENTAGE,
          color: GRAY,
        });
      }

      return {
        brandName: name ?? brandName,
        score,
        pieChartData,
      };
    })
    .sort(
      (a, b) =>
        brandsNames.indexOf(a.brandName) - brandsNames.indexOf(b.brandName),
    );
};

export { preparePieChartData };
