import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./AssociatedWords.module.scss";
import {
  RadarChart,
  AboutThis,
  ErrorPlaceholder,
  WidgetButtonsBar,
  WidgetFooterButtons,
  Preloader,
} from "../../../components";
import { getGeneralAttributesDescriptionInsertion } from "../../../utils";
import DATE_RANGE_LABELS from "../../../data/dateRangeLabels";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { WidgetChart } from "../../types";
import { WIDGET_ID, DATE_RANGES } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

// TEMPORARY REDUNDANT
export const AssociatedWords: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  // Brands ---->
  const [brandIndex, setBrandIndex] = useState<number>(0);

  const brands: Store.Brands = useMemo(() => widgetData?.brands || [], [
    widgetData,
  ]);

  const brandsOptions = useMemo(() => {
    if (!brands.length) return [];
    const brandsName = brands?.map(({ name }) => name);
    return ["all", ...brandsName];
  }, [brands]);

  const currentBrandId = useMemo(() => {
    if (brandsOptions[brandIndex] === "all") return "all";
    return brands[brandIndex - 1]?.id;
  }, [brandIndex, brands, brandsOptions]);
  // <---- Brands

  const generalAttributesData = useMemo(() => {
    return widgetData?.radarChart[currentBrandId || "all"] || {};
  }, [widgetData, currentBrandId]);

  // ----> Date ranges
  const dateRanges = useMemo(() => {
    return DATE_RANGES.filter((dateRange) => generalAttributesData[dateRange]);
  }, [generalAttributesData]);

  const [currentDateRange, setCurrentDateRange] = useState<
    typeof dateRanges[number] | undefined
  >();

  useEffect(() => {
    if (currentDateRange) return;
    setCurrentDateRange(dateRanges[dateRanges.length - 1]);
  }, [currentDateRange, dateRanges, widgetData?.brands]);

  const dateRangeOptions = dateRanges.map((dateRange) => ({
    value: dateRange,
    label: t(DATE_RANGE_LABELS[dateRange]),
  }));

  const chartData = useMemo(() => {
    if (!currentDateRange) return;
    return generalAttributesData[currentDateRange]?.data;
  }, [currentDateRange, generalAttributesData]);
  // <---- Date ranges

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (chartData) {
      charts.push({
        type: "radarChart",
        chart: (
          <RadarChart
            className={styles.radarChart}
            brands={brands}
            data={chartData}
          />
        ),
      });
    }

    return charts;
  }, [chartData, brands]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isEmptyWidget = !isLoading && !Object.keys(charts).length;
  // <---- Widget views

  // ----> Descriptions
  const firstBrand = useMemo(() => widgetData?.brands[0], [widgetData]);

  const descriptionInsertion = useMemo(() => {
    if (!currentDateRange || !firstBrand) return;

    return getGeneralAttributesDescriptionInsertion(
      generalAttributesData[currentDateRange]?.data.map(
        ({ attribute, values }) => ({
          attribute,
          value: values[firstBrand.id],
        }),
      ),
    );
  }, [currentDateRange, firstBrand, generalAttributesData]);

  const conclusion = useMemo(() => {
    if (!descriptionInsertion) return "";

    return t("w_tpw_main_tip", {
      mainBrandName: firstBrand!.name,
      descriptionInsertion,
    });
  }, [descriptionInsertion, firstBrand, t]);
  // <---- Descriptions

  // Download name ---->
  const currentBrandName = useMemo(() => brandsOptions?.[brandIndex] || "", [
    brandIndex,
    brandsOptions,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          timePeriodSelector={{
            periods: dateRangeOptions,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
          brandSelector={{
            className: styles.brandSelector,
            brands: brandsOptions,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => setBrandIndex(brandIndex),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              selectedView?.chart
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
