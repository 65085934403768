import { useMemo, useContext, FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ReviewDataModal.module.scss";
import context from "src/context";
import * as icons from "src/icons";
import { WIDGETS_COMPONENTS } from "src/widgets/widgets";
import { selectAvailableWidgetsIds } from "src/store/selectors";

// Inner imports
import type { REVIEW_DATA_WIDGETS } from "./types";
import { REVIEW_DATA_OPTIONS } from "./constants";
import { texts } from "./ReviewsDataTexts";

type Props = {
  searchId: string;
};

export const ReviewDataModal: FC<Props> = ({ searchId }) => {
  const { t } = useTranslation();
  const { setModalElement } = useContext(context);

  const searches = useSelector(({ searches }: Store.State) => searches);
  const widgetsData = useSelector(({ widgetsData }: RootState) => widgetsData);
  const availableWidgetsIds = useSelector(selectAvailableWidgetsIds);
  const currentSearch = searches.find(({ id }) => id === searchId);

  const widgets = useMemo(() => {
    const options: { id: WIDGET_IDS_TYPES; component: JSX.Element }[] = [];

    if (!currentSearch || !availableWidgetsIds.length) return options;

    for (const option of REVIEW_DATA_OPTIONS) {
      const { widgetId, relatedWidgets, component: Component } = option;

      const isOptionAllowed = availableWidgetsIds.some((id) =>
        relatedWidgets.includes(id as REVIEW_DATA_WIDGETS),
      );

      if (isOptionAllowed) {
        options.push({
          id: widgetId,
          component: (
            <Component initialSearch={currentSearch} openedFromSideBar />
          ),
        });
      }
    }

    return options;
  }, [currentSearch, availableWidgetsIds]);

  const renderIcon = (iconName?: string) => {
    const Icon = icons[iconName as AppIcon];
    if (Icon) return <Icon width={100} />;
  };

  const renderElement = (
    widgetId: WIDGET_IDS_TYPES,
    component: JSX.Element,
  ) => {
    const widgetIconName = WIDGETS_COMPONENTS[widgetId]?.icon;

    if (!widgetIconName) return null;

    const { customWidgetName } = texts[widgetId] || {};
    const widgetInitialName =
      widgetsData.entities[widgetId]?.placeHolderOnUi || "";

    return (
      <div
        key={widgetId}
        className={styles.item}
        onClick={() => setModalElement(component)}
      >
        {renderIcon(widgetIconName)}
        {t(customWidgetName || widgetInitialName)}
      </div>
    );
  };

  return (
    <div className={styles.reviewDataModal}>
      <div className={styles.searchSelect}>
        {typeof currentSearch !== "undefined" && (
          <div className={styles.searchName}>{currentSearch?.name}</div>
        )}
      </div>
      {!!widgets.length ? (
        <div className={styles.itemsWrapper}>
          {widgets.map(({ id, component }) => renderElement(id, component))}
        </div>
      ) : (
        <div className={styles.error}>{t("rd_modal_no_data_error")}</div>
      )}
    </div>
  );
};
