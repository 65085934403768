import { useState } from "react";
import classes from "./QuadrantChart.module.scss";
import COLORS from "../../../../../data/colors";

interface IProps {
  className?: string;
  style?: object;
  brands?: Store.Brands;
  axes?: string[];
  data?: {
    [brandId: string]: {
      x: number;
      y: number;
    };
  };
}

export const QuadrantChart = ({
  className = "",
  style = {},
  brands = [],
  axes = [],
  data = {},
}: IProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);

  const modifiedAxes = ["", "", "", ""].map((axis, i) => {
    const initialAxis = axes[i];
    if (typeof initialAxis !== "string") return axis;
    return initialAxis;
  });

  const shownBrands = brands.filter(({ id: brandId }) => brandId in data);

  const modifiedData = shownBrands.map(({ id, name }, i, arr) => ({
    brand: name,
    values: {
      x: data[id]!.x,
      y: data[id]!.y,
      z: arr.length - i,
    },
  }));

  function calculateCoordinate(number: number) {
    if (number < 0) {
      return (number + 100) / 2;
    } else {
      return 50 + number / 2;
    }
  }

  return modifiedData.length ? (
    <div className={`${classes.quadrantChart} ${className}`} style={style}>
      <div className={classes.top}>
        <div className={classes.label}>{modifiedAxes[0]}</div>
      </div>
      <div className={classes.right}>
        <div className={classes.label}>{modifiedAxes[1]}</div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.label}>{modifiedAxes[2]}</div>
      </div>
      <div className={classes.left}>
        <div className={classes.label}>{modifiedAxes[3]}</div>
      </div>
      <div className={classes.main}>
        <div className={classes.horizontalLine} />
        <div className={classes.verticalLine} />
        {modifiedData.map((item, i) => (
          <div
            className={classes.mark}
            style={{
              left: calculateCoordinate(item.values.x) + "%",
              bottom: calculateCoordinate(item.values.y) + "%",
              backgroundColor: COLORS[i],
              opacity:
                hoveredItemIndex > -1 && hoveredItemIndex !== i ? 0.3 : 1,
              zIndex: item.values.z,
            }}
            onMouseEnter={() => setHoveredItemIndex(i)}
            onMouseLeave={() => setHoveredItemIndex(-1)}
            key={i}
          >
            <div
              className={classes.markLabel}
              style={{
                ...(calculateCoordinate(item.values.x) > 75
                  ? { right: 4 }
                  : { left: 4 }),
                ...(calculateCoordinate(item.values.y) > 75
                  ? { top: 4 }
                  : { bottom: 4 }),
              }}
            >
              {item.brand}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
