import { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./LastSyncDataBar.module.scss";
import { DatePickerIcon } from "src/icons";
import { formatToPartialDate } from "src/utils";

// Inner imports
import { Props } from "./types";

export const LastSyncDataBar = memo(({ searchId, className }: Props) => {
  const { t } = useTranslation();

  const search = useSelector(({ searches }: Store.State) =>
    searches.find((search) => search.id === searchId),
  );

  const dataOfLastSync: string = useMemo(() => search?.lastSyncDate ?? "", [
    search,
  ]);

  const widgetsLasUpdateDate: string = useMemo(
    () => formatToPartialDate(dataOfLastSync),
    [dataOfLastSync],
  );

  // <-------- DEPRECATION: see ticket DEV-77176 -------->
  // const widgetsData = useSelector(
  //   ({ widgetsData }: RootState) => widgetsData,
  // );

  // const companySubscriptionPlan: SubscriptionPlan | undefined = useSelector(
  //   selectCompanySubscriptionPlan,
  // );

  // const keyWordsWidgetsUpdateDate: string = useSelector(
  //   ({ widgetsSettings }: Store.State) =>
  //     widgetsSettings?.keyWordsWidgetsUpdateDate || "",
  // );

  // const updateFrequencyInWeeks: number = useMemo(
  //   () => companySubscriptionPlan?.updateFrequencyInWeeks || 0,
  //   [companySubscriptionPlan?.updateFrequencyInWeeks],
  // );

  // const dateOfNextUpdate: Date = useMemo(() => {
  //   const numberOfCurrentWeek: number = getWeek(new Date()) + 1;
  //   const weeksBeforeUpdate: number =
  //     numberOfCurrentWeek % updateFrequencyInWeeks || 0;
  //   const dataOfUpdateWeek: Date = addWeeks(new Date(), weeksBeforeUpdate);
  //   return nextFriday(new Date(dataOfUpdateWeek));
  // }, [updateFrequencyInWeeks]);

  // const isWidgetKeyWordDependent: boolean = useMemo(
  //   () => widgetsData.entities[widgetId]?.isKeyWordDepended || false,
  //   [widgetsData, widgetId],
  // );

  // const widgetsUpdateDate: string = useMemo(() => {
  //   const nextUpdateDateString = isWidgetKeyWordDependent
  //     ? keyWordsWidgetsUpdateDate
  //     : formatToPartialDate(dateOfNextUpdate);
  //   return nextUpdateDateString;
  // }, [dateOfNextUpdate, isWidgetKeyWordDependent, keyWordsWidgetsUpdateDate]);

  // const isNextUpdateFieldShow: boolean = useMemo(
  //   () => !!updateFrequencyInWeeks && !!widgetsUpdateDate.length,
  //   [updateFrequencyInWeeks, widgetsUpdateDate],
  // );

  if (!dataOfLastSync) return null;

  return (
    <div className={cx(styles.datesBar, className)}>
      <div title={t("last_updated_new")}>
        <div className={styles.title}>
          <DatePickerIcon /> <span>{t("last_updated_new")}</span>
        </div>
        {widgetsLasUpdateDate}
      </div>
      {/*{isNextUpdateFieldShow && (*/}
      {/*  <div title={t("next_update_new")}>*/}
      {/*    <div className={styles.title}>*/}
      {/*      <DatePickerIcon /> <span>{t("next_update_new")}</span>*/}
      {/*    </div>*/}
      {/*    {widgetsUpdateDate}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});
