import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./Marketometer.module.scss";
import { Speedometer } from "./components/Speedometer/Speedometer";
import { CompetitivenessTable } from "./components/Table/CompetitivenessTable";
import DATE_RANGES_WITH_OVERALL from "../../../data/dateRangesWithOverall";
import { WIDGET_ID } from "../../../widgets/components/sentimentTrends/constants";

// Inner imports
import type { MarketometerDataItem } from "./types";
import { parseRangeData } from "./utils";

type SelectorType = {
  brands?: Store.Brands;
  marketometerChart?: Marketometer.Data;
};

type Props = {
  currentDateRange:
    | typeof DATE_RANGES_WITH_OVERALL[number]["value"]
    | undefined;
};

export const Marketometer: FC<Props> = ({ currentDateRange }) => {
  const history = useHistory();
  const getSearchId = history.location.state as { searchId: string };
  const searchId = getSearchId?.searchId;

  const { brands, marketometerChart } = useSelector<Store.State, SelectorType>(
    ({ widgets }: Store.State) => {
      const data: SelectorType = {};

      const widgetData = widgets[searchId]?.[WIDGET_ID];
      if (widgetData) {
        const { brands, speedometerChart } = widgetData;
        data.brands = brands;
        data.marketometerChart = speedometerChart;
      }

      return data;
    },
  );

  const dateRanges = useMemo(() => {
    if (!marketometerChart || !brands) return [];

    const currentDataItem = marketometerChart.thisMonth;

    return DATE_RANGES_WITH_OVERALL.reduce(
      (acc, { label, additionalValue }) => {
        const previousDataItem = marketometerChart[additionalValue];

        if (previousDataItem) {
          const rangeData = parseRangeData(
            currentDataItem,
            previousDataItem,
            brands,
          );

          if (rangeData.length) {
            acc.push({
              label,
              currentDataItem,
              previousDataItem,
              widgetData: rangeData,
            });
          }
        }

        return acc;
      },
      [] as {
        label: string;
        currentDataItem: MarketometerDataItem;
        previousDataItem: MarketometerDataItem;
        widgetData: ReturnType<typeof parseRangeData>;
      }[],
    );
  }, [marketometerChart, brands]);

  const lastAvailablePeriod = dateRanges[0];

  const firstBrand = brands?.[0];

  return (
    <>
      {!!lastAvailablePeriod ? (
        <>
          <div className={styles.widgetsWrapperOuter}>
            <Speedometer
              type="page"
              className={styles.speedometer}
              data={lastAvailablePeriod.widgetData}
            />
            <CompetitivenessTable
              dateRanges={dateRanges}
              mainBrandName={firstBrand?.name || ""}
              currentDateRange={currentDateRange}
              className={styles.table}
            />
          </div>
        </>
      ) : null}
    </>
  );
};
