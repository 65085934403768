import differenceInDays from "date-fns/differenceInDays";
import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import { TFunction } from "i18next";

import { ChartItem, ChartTimePlotter } from "./types";
import COLORS from "src/data/colors";
import {
  getMaxNumberOfDigitsAfterDecimal,
  calculateTrendLine,
  showDevelopmentError,
} from "src/utils";
import { GREY_COLOR } from "./constants";

const getTickStep = (minTick: number, maxTick: number) => {
  const minTickStep = 10;
  const difference = maxTick - minTick;

  const convertedNumber = Math.floor(Math.log10(difference));

  return Math.max(Math.pow(10, convertedNumber) / 10, minTickStep);
};

const getModifiedKeyObject = (
  object: {
    [key: string]: number;
  },
  label: string,
): { [key: string]: number } => {
  const result: { [key: string]: number } = {};

  for (const key in object) {
    const value = object[key];

    if (!value) continue;

    result[`${key} (${label})`] = value;
  }

  return result;
};

const calculateNewDataPoint = (time: number, allData: ChartItem[]) => {
  const [firstDataPoint, lastDataPoint] = getNearestDataPointsToDate(
    time,
    allData,
  );

  if (!firstDataPoint || !lastDataPoint) return;

  return calculatePointBetweenTwoPoints(time, firstDataPoint, lastDataPoint);
};

const getEnhancedDataWithTimePlotters = (
  data: ChartItem[],
  timePlotters?: ChartTimePlotter[],
): ChartItem[] => {
  if (!timePlotters?.length) return data;

  const newDates = new Set<number>();
  const newDataPoints = new Set<ChartItem>();

  for (const timePlotter of timePlotters) {
    const { startDate, endDate } = timePlotter;

    const [isStartDatePresent, isEndDatePresent] = [
      getIsDatePresent(data, startDate),
      getIsDatePresent(data, endDate),
    ];

    if (!isStartDatePresent) newDates.add(startDate);
    if (!isEndDatePresent) newDates.add(endDate);
  }

  for (const time of newDates) {
    const newDataPoint = calculateNewDataPoint(time, data);

    if (newDataPoint) newDataPoints.add(newDataPoint);
  }

  return [...data, ...newDataPoints].sort((a, b) => a.time - b.time);
};

const getFilteredDataWithTimePlotter = (
  data: ChartItem[],
  timePlotter?: ChartTimePlotter,
): ChartItem[] => {
  if (!timePlotter) return data;

  const { startDate, endDate } = timePlotter;

  return data.filter(({ time }) => {
    const [formattedTime, formattedStartDate, formattedEndDate] = [
      new Date(format(time, "yyyy-MM-dd")).getTime(),
      new Date(format(startDate, "yyyy-MM-dd")).getTime(),
      new Date(format(endDate, "yyyy-MM-dd")).getTime(),
    ];

    return (
      formattedTime >= formattedStartDate && formattedTime <= formattedEndDate
    );
  });
};

const getUniqColor = (usedColors: Set<string>): string =>
  COLORS.filter((color) => !usedColors.has(color))[0] || GREY_COLOR;

const getTimePlotterLabel = (
  { name, budget }: Store.TimePlot,
  t: TFunction,
) => {
  const labelKey = budget
    ? "line_chart_event_budget_label"
    : "line_chart_event_label";

  return t(labelKey, {
    name,
    budget,
  });
};

const getTrendLineValues = (
  data: ChartItem[],
  excludedBrands: string[] = [],
): Record<number, number> => {
  const formattedData: { date: number; value: number }[] = [];

  for (const { time, values } of data) {
    for (const brandId in values) {
      if (excludedBrands.includes(brandId)) continue;

      const value = values[brandId] || 0;

      formattedData.push({ date: time, value });
    }
  }

  try {
    const trendLineData = calculateTrendLine(formattedData, "date", "value");

    const trendLineMap = new Map<number, number>();

    for (const { date, value } of trendLineData) {
      trendLineMap.set(date, value);
    }

    return Object.fromEntries(trendLineMap);
  } catch (error) {
    showDevelopmentError({
      additionalTexts: ["TREND LINE CALCULATION ERROR"],
      error,
    });

    return {};
  }
};

const getTrendLineMinMaxValues = (
  data: Record<number, number>,
): [number, number] => {
  const values = Object.values(data);

  return [Math.min(...values), Math.max(...values)];
};

export {
  getTickStep,
  calculateNewDataPoint,
  getModifiedKeyObject,
  getEnhancedDataWithTimePlotters,
  getFilteredDataWithTimePlotter,
  getUniqColor,
  getTimePlotterLabel,
  getTrendLineValues,
  getTrendLineMinMaxValues,
};

function getNearestDataPointsToDate(
  time: number,
  allData: ChartItem[],
): [ChartItem?, ChartItem?] {
  const filteredLeftDataPoints = allData.filter((point) => point.time < time);
  const leftNearestDataPoint =
    filteredLeftDataPoints[filteredLeftDataPoints.length - 1];

  const filteredRightDataPoints = allData.filter((point) => point.time > time);
  const rightNearestDataPoint = filteredRightDataPoints[0];

  return [leftNearestDataPoint, rightNearestDataPoint];
}

function calculatePointBetweenTwoPoints(
  time: number,
  firstDataPoint: ChartItem,
  lastDataPoint: ChartItem,
): ChartItem {
  const brandIds = Object.keys(firstDataPoint.values);

  const [
    {
      time: startTime,
      values: startValues,
      additionalValues: startAdditionalValues = {},
    },
    {
      time: endTime,
      values: endValues,
      additionalValues: endAdditionalValues = {},
    },
  ] = [firstDataPoint, lastDataPoint];

  const calculatedValues: [string, number][] = [];
  const calculatedAdditionalValues: [string, number][] = [];

  const values = [
    {
      start: startValues,
      end: endValues,
      result: calculatedValues,
    },
    {
      start: startAdditionalValues,
      end: endAdditionalValues,
      result: calculatedAdditionalValues,
    },
  ];

  for (const { start, end, result } of values) {
    if (!Object.values(start).length || !Object.values(end).length) continue;

    for (const brandId of brandIds) {
      const brandStartValue = start[brandId] || 0;
      const brandEndValue = end[brandId] || 0;

      const calculatedValue = calculateAverageValue(
        time,
        {
          time: startTime,
          value: brandStartValue,
        },
        {
          time: endTime,
          value: brandEndValue,
        },
      );

      result.push([brandId, calculatedValue]);
    }
  }

  return {
    ...firstDataPoint,
    time,
    values: Object.fromEntries(calculatedValues),
    ...(calculatedAdditionalValues.length
      ? { additionalValues: Object.fromEntries(calculatedAdditionalValues) }
      : {}),
  };
}

function calculateAverageValue(
  time: number,
  start: { value: number; time: number },
  end: { value: number; time: number },
): number {
  const dataDaysDifference = differenceInDays(end.time, start.time) || 1;
  const dataValuesDifference = end.value - start.value;
  const dataDifferencePerDay = dataValuesDifference / dataDaysDifference;
  const pointDaysDifference = differenceInDays(time, start.time);
  const averageValue = dataDifferencePerDay * pointDaysDifference + start.value;

  const numberOfDigitsAfterDecimal = getMaxNumberOfDigitsAfterDecimal([
    start.value,
    end.value,
  ]);

  return +averageValue.toFixed(numberOfDigitsAfterDecimal);
}

function getIsDatePresent(data: ChartItem[], time: number): boolean {
  const dateIndex = data.findIndex((dataItem) => {
    const [formattedTimePlotterTime, formattedPeriodTime] = [
      format(time, "yyyy-MM-dd"),
      format(dataItem.time, "yyyy-MM-dd"),
    ];

    return (
      compareAsc(
        new Date(formattedTimePlotterTime).getTime(),
        new Date(formattedPeriodTime).getTime(),
      ) === 0
    );
  });

  return dateIndex >= 0;
}
