import axios from "../axios";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

type Body = {
  name: string;
  listId?: number;
  text_content?: { [error: string]: unknown };
  assignees?: string[];
  tags?: string[];
  status?: string;
};

export const createClickUpTask = async ({
  name,
  listId,
  text_content,
  assignees,
  tags,
  status,
}: Body) => {
  try {
    await axios.post(`clickup-service-${urlInsertion}-api/new`, {
      name,
      ...(listId ? { listId } : {}),
      ...(text_content ? { text_content } : {}),
      ...(assignees ? { assignees } : {}),
      ...(tags ? { tags } : {}),
      ...(status ? { status } : {}),
    });
  } catch (err) {
    console.error("ClickUp task did`t created:", err);
  }
};
