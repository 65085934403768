import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./SentimentTrendsPreview.module.scss";
import {
  Preloader,
  LineChart,
  ChartButton,
  ErrorPlaceholder,
  Speedometer,
} from "../../../components";

import { parseRangeData } from "../../../components/widgets/Marketometer/utils";

// Inner imports
import { WIDGET_ID } from "./constants";
import { prepareLineChartData } from "./utils";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const SentimentTrendsPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { brands, lineChart, speedometerChart } = widgetData || {};

  const dataFilteredByPeriod = useMemo(() => prepareLineChartData(lineChart), [
    lineChart,
  ]);

  const charts = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (speedometerChart) {
      const lastAvailablePeriod =
        speedometerChart.oneMonthAgo ||
        speedometerChart.twoMonthsAgo ||
        speedometerChart.threeMonthsAgo ||
        speedometerChart.sixMonthsAgo ||
        speedometerChart.oneYearAgo;

      if (lastAvailablePeriod && brands) {
        charts.push({
          type: "speedometer",
          chart: (
            <Speedometer
              className={styles.chart}
              data={parseRangeData(
                speedometerChart.thisMonth,
                lastAvailablePeriod,
                brands,
              )}
              fitTo="height"
            />
          ),
        });
      }
    }

    if (!!dataFilteredByPeriod.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            data={dataFilteredByPeriod}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    return charts;
  }, [brands, dataFilteredByPeriod, searchId, speedometerChart]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
