import { Dispatch, SetStateAction, useMemo, useState, useEffect } from "react";

type PaginationProps<T> = {
  items: T[];
  initialPageNumber?: number;
};

type PaginationReturnType<T> = {
  paginationData: {
    slicedItems: T[];
    pageNumber: number;
    pagesCount: number;
    totalItemsCount: number;
    itemsPerPage: number;
    firstItemIndex: number;
    lastItemIndex: number;
  };
  paginationMutation: {
    setItemsPerPage: Dispatch<SetStateAction<number>>;
    setPageNumber: Dispatch<SetStateAction<number>>;
  };
};

const PAGINATION_MIN_ITEMS_COUNT = 10 as const;

const usePagination = <T extends {}>({
  items,
  initialPageNumber = 0,
}: PaginationProps<T>): PaginationReturnType<T> => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    PAGINATION_MIN_ITEMS_COUNT,
  );
  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);

  const totalItemsCount: number = useMemo(() => items.length, [items]);

  const firstItemIndex: number = useMemo(() => pageNumber * itemsPerPage, [
    itemsPerPage,
    pageNumber,
  ]);

  const lastItemIndex: number = useMemo(() => {
    const expectedLastItemIndex = firstItemIndex + itemsPerPage;

    return expectedLastItemIndex > totalItemsCount
      ? totalItemsCount
      : expectedLastItemIndex;
  }, [firstItemIndex, itemsPerPage, totalItemsCount]);

  const slicedItems: T[] = useMemo(() => {
    if (items.length <= PAGINATION_MIN_ITEMS_COUNT) return items;

    return items.slice(firstItemIndex, firstItemIndex + itemsPerPage);
  }, [firstItemIndex, items, itemsPerPage]);

  const pagesCount: number = useMemo(() => {
    if (!itemsPerPage) return 0;

    return Math.ceil(totalItemsCount / itemsPerPage);
  }, [itemsPerPage, totalItemsCount]);

  useEffect(() => {
    if (!slicedItems.length && pageNumber > 0)
      setPageNumber((state) => state - 1);
  }, [pageNumber, slicedItems.length]);

  const paginationData = {
    slicedItems,
    pageNumber,
    pagesCount,
    totalItemsCount,
    itemsPerPage,
    firstItemIndex,
    lastItemIndex,
  };

  const paginationMutation = {
    setItemsPerPage,
    setPageNumber,
  };

  return { paginationData, paginationMutation };
};

export { usePagination, PAGINATION_MIN_ITEMS_COUNT };
