import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./ScrollToButton.module.scss";

export type Props = {
  className?: string;
};

export const ScrollToButton = ({ className }: Props) => {
  const scrolledComponent = document.getElementById("home-main");

  const { t } = useTranslation();
  const history = useHistory();

  const { state } = history.location as any;
  const { position } = state || {};

  const [isCanShow, setIsCanShow] = useState<boolean>(false);

  useEffect(() => {
    const check =
      !!scrolledComponent && typeof position === "number" && position > 0;
    setIsCanShow(check);
  }, [position, scrolledComponent]);

  const onScrollHandler = () => {
    if (!scrolledComponent) return;
    scrolledComponent.scrollTop = position;
    const newLocationState = { ...state };
    delete newLocationState.position;
    history.replace({ ...history.location, state: newLocationState });
    setIsCanShow(false);
  };

  if (!isCanShow) return null;

  return (
    <button
      className={cx(className, styles.scrollToButton)}
      onClick={onScrollHandler}
    >
      {t("scroll_button_text")}
    </button>
  );
};
