import { useCallback, useMemo } from "react";

// Inner imports
import type { LastAvailableMonthData, Words } from "../types";
import { filterBrandExcludedWords } from "../utils";
import {
  getWidgetTopWordsData,
  getWidgetTrendingWordsData,
} from "../utils/getWidgetData";

export const useLastAvailableMonthData = (
  data?: TopKeywords.Data,
  suggestions?: Store.Search["suggestions"],
  timeRangeType: TopKeywords.TimeRangeType = "monthly",
): [LastAvailableMonthData] => {
  const checkIfWordsExist = useCallback(
    (
      values?: {
        [brandName: string]: Words;
      },
      brands?: Store.Brands,
    ) => {
      if (!values) return false;

      for (const brandId in values) {
        const brandWords = values[brandId] || [];
        const brandName = brands?.find(({ id }) => id === brandId)?.name || "";
        const brandExcludedWords =
          suggestions?.[brandName]?.topKeywordsExcludedWords || [];

        const words = filterBrandExcludedWords(brandWords, brandExcludedWords);

        if (words.length) return true;
      }

      return false;
    },
    [suggestions],
  );

  const lastAvailableMonthData = useMemo(() => {
    const lastAvailableMonthData: LastAvailableMonthData = {};

    if (!data) return lastAvailableMonthData;

    const { brands } = data;

    const [topWords, trendingWords] = [
      getWidgetTopWordsData(data, timeRangeType),
      getWidgetTrendingWordsData(data, timeRangeType),
    ];

    const monthQuantity = topWords.length;

    for (let i = 0; i <= monthQuantity; i++) {
      const { values: topWordsValues } = topWords[i] || {};
      const { values: trendingWordsValues } = trendingWords[i] || {};

      if (!topWordsValues && !trendingWordsValues) continue;

      const [isTopWordsExistOnThisMonth, isTrendingWordsExistOnThisMonth] = [
        checkIfWordsExist(topWordsValues, brands),
        checkIfWordsExist(trendingWordsValues, brands),
      ];

      if (isTopWordsExistOnThisMonth)
        lastAvailableMonthData.topWords = topWords[i];

      if (isTrendingWordsExistOnThisMonth)
        lastAvailableMonthData.trendingWord = trendingWords[i];

      if (
        lastAvailableMonthData.topWords ||
        lastAvailableMonthData.trendingWord
      )
        break;
    }

    return lastAvailableMonthData;
  }, [data, checkIfWordsExist, timeRangeType]);

  return [lastAvailableMonthData];
};
