import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showToastNotification } from "src/components/ToastNotification/utils";
import {
  changeIsWidgetHasToBeReloadStatus,
  setWidget,
} from "src/store/actions";
import {
  getShareOfSearchWidget,
  getTopKeywordsWidget,
  getShareOfEarnedMediaWidget,
  getSentimentDriversWidget,
  getBusinessDriversWidget,
  getRobustnessWidget,
  getAwarenessWidget,
  getReputationIndexWidget,
  getReputationSentimentWidget,
  getReputationDistributionWidget,
  getEsgIndexWidget,
  getEsgDistributionWidget,
  getEsgSentimentWidget,
  getFunnelWidget,
  getSentimentIndexWidget,
  getSearchTrendWidget,
  getShareOfSearchFunnelWidget,
  getSentimentTrendsWidget,
  getAttitudeWidget,
  getInterestDriverComparisonWidget,
  getAssociatedWordsWidget,
  getBrands,
} from "src/store/widgets/api";
import { showDevelopmentError } from "src/utils";

const FETCH_FUNCTIONS = {
  "result-search-share": getShareOfSearchWidget,
  "result-volume-share": getShareOfSearchWidget,
  "result-attitude": getAttitudeWidget,
  "result-awareness": getAwarenessWidget,
  "result-business-drivers": getBusinessDriversWidget,
  "result-esg-index": getEsgIndexWidget,
  "result-esg-sentiment": getEsgSentimentWidget,
  "result-esg-distribution": getEsgDistributionWidget,
  "result-funnel": getFunnelWidget,
  "result-key-areas": getBusinessDriversWidget,
  "result-media-share": getShareOfEarnedMediaWidget,
  "result-position-vs-competition": getInterestDriverComparisonWidget,
  "result-reputation-index": getReputationIndexWidget,
  "result-reputation-sentiment": getReputationSentimentWidget,
  "result-reputation-distribution": getReputationDistributionWidget,
  "result-robustness-score": getRobustnessWidget,
  "result-share-of-search-funnel": getShareOfSearchFunnelWidget,
  "result-search-trends": getSearchTrendWidget,
  "result-sentiment-drivers": getSentimentDriversWidget,
  "result-sentiment-index": getSentimentIndexWidget,
  "result-sentiment-trends": getSentimentTrendsWidget,
  "result-top-trend-keywords": getTopKeywordsWidget,
  "result-associated-words": getAssociatedWordsWidget,
};

export const useWidgetFetching = <T extends WIDGET_IDS_TYPES>(
  searchId: string,
  widgetId: T,
): {
  loadingStatus: LoadingStatus;
  widgetData: Store.Widgets[T] | undefined;
  isLoading: boolean;
  isCalculating: boolean;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const widgetData = useSelector(
    ({ widgets }: Store.State) => widgets[searchId]?.[widgetId],
  );

  const widgetCollectionId = useSelector(
    ({ widgetsData }: RootState) =>
      widgetsData.entities[widgetId]?.resultCollection,
  );

  const searchBrands = useSelector(
    ({ searches }: RootState) =>
      searches.find(({ id }) => id === searchId)?.brandsTableNames || [],
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    Boolean(widgetData) ? "succeeded" : "idle",
  );

  const { isCalculating, isHaveToBeReloaded } = useWidgetState(
    searchId,
    widgetCollectionId,
  );

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading" || loadingStatus === "idle",
    [loadingStatus],
  );

  useEffect(() => {
    if (loadingStatus === "idle") return;

    if (isHaveToBeReloaded) {
      dispatch(changeIsWidgetHasToBeReloadStatus(searchId, widgetId, false));
      setLoadingStatus("idle");
    }
  }, [searchId, widgetId, isHaveToBeReloaded, loadingStatus, dispatch]);

  useEffect(() => {
    if (loadingStatus !== "idle" || isCalculating) return;

    if (loadingStatus === "idle") setLoadingStatus("loading");

    (async () => {
      try {
        let brands: Store.Brands = searchBrands;

        if (!brands.length) {
          brands = await getBrands(searchId);
        }

        const fetchFunction = FETCH_FUNCTIONS[widgetId];
        const fetchedWidgetData = (await fetchFunction(
          searchId,
          brands,
        )) as Store.Widgets[T];

        if (fetchedWidgetData && widgetCollectionId) {
          dispatch(setWidget(widgetCollectionId, fetchedWidgetData, searchId));
        }

        setLoadingStatus("succeeded");
      } catch (error) {
        setLoadingStatus("failed");
        showDevelopmentError({
          additionalTexts: [
            "ERROR IN FETCHING WIDGET DATA.",
            `WIDGET ID: ${widgetId}, SEARCH ID: ${searchId}`,
          ],
          error,
        });
        showToastNotification({
          id: "widget_fetch_error",
          type: "error",
          text: t("widget_fetch_error"),
        });
      }
    })();
  }, [
    searchId,
    widgetId,
    widgetCollectionId,
    isCalculating,
    searchBrands,
    loadingStatus,
    dispatch,
    t,
  ]);

  return {
    widgetData,
    loadingStatus,
    isLoading,
    isCalculating,
  };
};

function useWidgetState(
  searchId: string,
  widgetId?: WIDGET_IDS_TYPES,
): {
  isHaveToBeReloaded: boolean;
  isCalculating: boolean;
} {
  const widgetState = useSelector(
    ({ widgetsState }: Store.State) => widgetsState[searchId]?.[widgetId || ""],
  );

  const isHaveToBeReloaded = useMemo<boolean>(
    () => Boolean(widgetState?.isHaveToBeReloaded),
    [widgetState?.isHaveToBeReloaded],
  );

  const isCalculating = useMemo<boolean>(
    () => Boolean(widgetState?.isCalculating),
    [widgetState?.isCalculating],
  );

  return {
    isHaveToBeReloaded,
    isCalculating,
  };
}
