import React, { FC, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { sub, isAfter, compareAsc } from "date-fns";

import {
  getDifferencePercentage,
  getMaxNumberOfDigitsAfterDecimal,
  isNumber,
} from "../utils";

// Inner imports
import {
  SummaryHighestDriverProps,
  SummaryTimePeriodProps,
  SummaryYearlyAverageProps,
} from "./types";
import {
  NUMBER_MEASUREMENT_PERCENTAGE,
  SUMMARY_WIDGET_INSERTION_TEXT,
  SUMMARY_WIDGET_MEASUREMENT_UNIT,
} from "./constants";

const BOLD_TEXT: JSX.Element = <span style={{ fontWeight: 500 }} />;

const POSITIVE_HIGHLIGHTED_TEXT: JSX.Element = (
  <span style={{ color: "#6bc143", fontWeight: 500 }} />
);

const NEGATIVE_HIGHLIGHTED_TEXT: JSX.Element = (
  <span style={{ color: "#ff4451", fontWeight: 500 }} />
);

const NEUTRAL_HIGHLIGHTED_TEXT: JSX.Element = (
  <span style={{ color: "#ff8d5c", fontWeight: 500 }} />
);

export const SummaryTimePeriod: FC<SummaryTimePeriodProps> = ({
  data,
  widgetId,
  selectedBrand: { name: selectedBrandName },
}) => {
  const { t } = useTranslation();

  const [startValue, endValue] = useMemo<[number, number]>(() => {
    const startValue = +(data[0] || 0).toFixed(2);
    const endValue = +(data[data.length - 1] || 0).toFixed(2);

    if (isNaN(startValue) || isNaN(endValue)) return [0, 0];

    return [startValue, endValue];
  }, [data]);

  const percentage = useMemo<number>(() => {
    if (!startValue) return endValue;
    if (!endValue) return -startValue;

    return getDifferencePercentage(startValue, endValue, 2);
  }, [endValue, startValue]);

  const [textInsertionKey, measurementUnit] = useMemo<[string, string]>(
    () => [
      SUMMARY_WIDGET_INSERTION_TEXT[widgetId] || "",
      SUMMARY_WIDGET_MEASUREMENT_UNIT[widgetId] || "",
    ],
    [widgetId],
  );

  const resultMeasurementUnit = useMemo<string>(
    () =>
      !startValue || !endValue
        ? measurementUnit
        : NUMBER_MEASUREMENT_PERCENTAGE,
    [endValue, measurementUnit, startValue],
  );

  if (data?.length <= 1 || isNaN(percentage) || !isFinite(percentage))
    return null;

  const getLocalizationKey = (value: number): string => {
    switch (true) {
      case value > 0:
        return "tp_summary_time_period_sentence_positive_new";
      case value < 0:
        return "tp_summary_time_period_sentence_negative_new";
      default:
        return "tp_summary_time_period_sentence_neutral_new";
    }
  };

  const getHighlightComponent = (value: number): JSX.Element => {
    switch (true) {
      case value > 0:
        return POSITIVE_HIGHLIGHTED_TEXT;
      case value < 0:
        return NEGATIVE_HIGHLIGHTED_TEXT;
      default:
        return NEUTRAL_HIGHLIGHTED_TEXT;
    }
  };

  return (
    <span>
      <Trans
        i18nKey={getLocalizationKey(percentage)}
        values={{
          brand: selectedBrandName,
          result: Math.abs(percentage),
          widgetTextInsertion: t(textInsertionKey),
          numberMeasurementUnit: measurementUnit,
          resultMeasurementUnit,
          startValue,
          endValue,
        }}
        components={[BOLD_TEXT, getHighlightComponent(percentage)]}
      />
    </span>
  );
};

export const SummaryYearlyAverage: FC<SummaryYearlyAverageProps> = ({
  data,
  widgetId,
  selectedBrand: { name: selectedBrandName },
}) => {
  const getYearlyAverageValue = useCallback(
    (data: { date: string; value: number }[]): number => {
      const latestDataItem = [...data].sort((a, b) =>
        compareAsc(new Date(b.date), new Date(a.date)),
      )[0];

      const maxDate = latestDataItem?.date || new Date();

      const minDate = sub(new Date(maxDate), {
        years: 1,
      });

      const { totalValue, values } = data.reduce(
        (acc, { date, value }) => {
          if (isNumber(value) && isAfter(new Date(date), minDate)) {
            acc.values.push(value);
            acc.totalValue += value;
          }

          return acc;
        },
        { values: [], totalValue: 0 } as {
          values: number[];
          totalValue: number;
        },
      );

      const averageValue = totalValue / values.length;

      if (!isNumber(averageValue)) return 0;

      const numberOfDigitsAfterDecimal = getMaxNumberOfDigitsAfterDecimal(
        values,
      );

      return +averageValue.toFixed(numberOfDigitsAfterDecimal);
    },
    [],
  );

  const measurementUnit = useMemo<string>(
    () => SUMMARY_WIDGET_MEASUREMENT_UNIT[widgetId] || "",
    [widgetId],
  );

  const value = useMemo<number>(
    () => (Array.isArray(data) ? getYearlyAverageValue(data) : data),
    [data, getYearlyAverageValue],
  );

  return (
    <span>
      <Trans
        i18nKey={"tp_summary_yearly_sentence_new"}
        values={{
          brand: selectedBrandName,
          number: value,
          numberMeasurementUnit: measurementUnit,
        }}
        components={[BOLD_TEXT]}
      />
    </span>
  );
};

export const SummaryHighestDriver: FC<SummaryHighestDriverProps> = ({
  data,
  selectedBrand: { name: selectedBrandName },
}) => {
  const highestDriver = useMemo<string>(() => {
    const totalValuesByDrivers = data.reduce((acc, { values }) => {
      const keys = Object.keys(values);

      keys.forEach((key) => {
        acc[key] = (acc[key] || 0) + (values[key] || 0);
      });

      return acc;
    }, {} as { [key: string]: number });

    return Object.entries(totalValuesByDrivers).reduce(
      (prev, current) => (current[1] > prev[1] ? current : prev),
      ["", 0],
    )[0];
  }, [data]);

  if (!highestDriver) return null;

  return (
    <span>
      <Trans
        i18nKey={"tp_summary_highest_driver_new"}
        values={{
          brand: selectedBrandName,
          driver: highestDriver.toLowerCase(),
        }}
        components={[BOLD_TEXT]}
      />
    </span>
  );
};
