import { FC, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import context from "src/context";
import { ConfirmModalWrapper } from "src/components";
import { selectSearchesLimit } from "src/store/selectors";
import { ROUTS } from "src/data/routs";

export const SearchesLimitReachedModal: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setModalElement } = useContext(context);

  const { searchesLimit } = useSelector(selectSearchesLimit);

  return (
    <ConfirmModalWrapper
      type="warning"
      cancelButton={{
        text: t("dc_cancel"),
        onClicked: () => setModalElement(null),
      }}
      acceptButton={{
        text: t("dc_check_plans"),
        onClicked: () => {
          setModalElement(null);
          history.push(ROUTS.pricingPage);
        },
      }}
      title={t("dc_title_reached_limit")}
    >
      <p>
        <Trans
          i18nKey="dc_tracker_limit_modal_text_new"
          components={[<b />, <br />]}
          values={{
            count: searchesLimit,
          }}
        />
      </p>
    </ConfirmModalWrapper>
  );
};
