import { FC } from "react";
import { useSelector } from "react-redux";

import styles from "./PreReleaseComponent.module.scss";
import { PreReleaseLabel } from "./components/PreReleaseLabel/PreReleaseLabel";

// Inner imports
import { Props } from "./types";
import { VIEW_TYPES } from "./constants";

export const PreReleaseComponent: FC<Props> = ({
  widgetId,
  viewType = VIEW_TYPES.default,
}) => {
  const preReleaseLabelContent = useSelector(
    ({ widgetsData }: RootState) =>
      widgetsData.entities[widgetId]?.preReleaseLabel,
  );

  if (!preReleaseLabelContent || !widgetId) return null;

  return (
    <div className={styles.preReleaseWrapper}>
      <PreReleaseLabel content={preReleaseLabelContent} viewType={viewType} />
    </div>
  );
};
