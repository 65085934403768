import { useContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./ReviewDataButtonBlock.module.scss";
import context from "../../../../context";
import {
  TopTrendKeywordsReviewData,
  SentimentDriversReviewData,
  ESGReviewData,
  BusinessDriversReviewData,
  ReputationReviewData,
  ButtonWithTransparentBackground,
} from "../../..";

// Inner imports
import type { ReviewDataButtonType } from "../../types";

export const ReviewDataButtonBlock = ({
  searchId,
  type,
  isButtonDisabled,
}: ReviewDataButtonType) => {
  const { t } = useTranslation();
  const { setModalElement } = useContext(context);

  const search = useSelector(({ searches }: Store.State) =>
    searches.find(({ id }) => id === searchId),
  );

  if (!search) return null;

  const options: {
    [type: string]: ReactNode;
  } = {
    "sentiment drivers": <SentimentDriversReviewData initialSearch={search} />,
    "top trend keywords": <TopTrendKeywordsReviewData initialSearch={search} />,
    esg: <ESGReviewData initialSearch={search} />,
    "business drivers": <BusinessDriversReviewData initialSearch={search} />,
    reputation: <ReputationReviewData initialSearch={search} />,
  };

  return (
    <ButtonWithTransparentBackground
      className={styles.reviewDataWrapper}
      text={t("review_data_button")}
      icon="Check"
      handleClick={() => setModalElement(options[type])}
      isButtonDisabled={isButtonDisabled}
    />
  );
};
