import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ShareOfVolumeTablePreview.module.scss";
import { useCompetitorsPresence } from "src/hooks";
import { useWidgetFetching } from "src/widgets/hooks";

// Inner imports
import { formatDateByPeriod, getColorClass } from "../../utils";
import { WIDGET_COLLECTION_ID } from "../../constants";
import { getAverageValue } from "./utils";

type Props = { searchId: string };

export const ShareOfVolumeTablePreview: FC<Props> = ({ searchId }) => {
  const { t } = useTranslation();

  const { widgetData } = useWidgetFetching(searchId, WIDGET_COLLECTION_ID);

  const searchesCompetitorsPresence = useCompetitorsPresence({
    searchesIds: [searchId || ""],
    widgetId: WIDGET_COLLECTION_ID,
  });

  const isCompetitorsPresent = useMemo<boolean | undefined>(
    () => searchesCompetitorsPresence[searchId || ""]?.isCompetitorsPresent,
    [searchId, searchesCompetitorsPresence],
  );

  const mainBrandName = useMemo<string>(() => {
    const { brands = [] } = widgetData || {};
    const { name: mainBrandName = "" } = brands[0] || {};

    return mainBrandName;
  }, [widgetData]);

  const formattedData = useMemo(() => {
    if (!widgetData) return [];

    return formatDateByPeriod(widgetData, "oneYear");
  }, [widgetData]);

  const { mainBrandSearchVolume, mainBrandValueChange } = useMemo(() => {
    const { change = 0, searchVolume = 0 } =
      formattedData.find(({ brand }) => brand === mainBrandName) || {};

    return {
      mainBrandSearchVolume: searchVolume,
      mainBrandValueChange: change,
    };
  }, [formattedData, mainBrandName]);

  const competitorsAverageChange = useMemo<number>(() => {
    const competitorsValueChanges = formattedData.reduce<number[]>(
      (acc, { brand, change }) => {
        if (brand === mainBrandName) return acc;

        acc.push(change);

        return acc;
      },
      [],
    );

    return getAverageValue(competitorsValueChanges);
  }, [formattedData, mainBrandName]);

  return (
    <div className={styles.tableWrapperPreview}>
      <div className={styles.tableWrapperPreviewSection}>
        <div className={styles.tableWrapperPreviewTitle}>
          <Trans
            i18nKey="w_search_volume_preview_table_title"
            values={{
              brandName: mainBrandName,
            }}
            components={[<span className={styles.brandName} />]}
          />
        </div>
        <div
          className={cx(
            styles.tableWrapperPreviewTitleValue,
            styles[getColorClass(mainBrandValueChange)],
          )}
        >
          {mainBrandValueChange}%
        </div>
      </div>
      <div className={styles.tableWrapperPreviewSubTitle}>
        {isCompetitorsPresent && (
          <div className={styles.tableWrapperPreviewSubTitleLeftWrapper}>
            <div className={styles.tableWrapperPreviewSubTitleLeft}>
              {t("w_search_volume_preview_table_average")}
            </div>
            <div
              className={cx(
                styles.tableWrapperPreviewSubTitleLeftValue,
                styles[getColorClass(competitorsAverageChange)],
              )}
            >
              {competitorsAverageChange.toFixed(1)}%
            </div>
          </div>
        )}
        <div className={styles.tableWrapperPreviewSubTitleRightWrapper}>
          <div className={styles.tableWrapperPreviewSubTitleRight}>
            {t("w_search_volume_preview_table_search_volume")}
          </div>
          <div className={styles.tableWrapperPreviewSubTitleRightValue}>
            {mainBrandSearchVolume}
          </div>
        </div>
      </div>
    </div>
  );
};
