import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./AwarenessPreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
} from "../../../components";
import { AwarenessPieChart } from "../../../components";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";

// Inner imports
import { WIDGET_ID } from "./constants";

export const AwarenessPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { lineChartData, pieChartData, brands } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};

    return {
      lineChartData: lineChart,
      pieChartData: pieChart,
      brands,
    };
  }, [widgetData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(pieChartData).length) {
      charts.push({
        type: "pieChart",
        chart: (
          <AwarenessPieChart
            className={styles.chart}
            data={
              (
                pieChartData["oneYear"] ||
                pieChartData["sixMonths"] ||
                pieChartData["threeMonths"] ||
                pieChartData["twoMonths"] ||
                pieChartData["oneMonth"]
              )?.values
            }
            key={0}
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            labels={brands}
            data={lineChartData}
            searchId={searchId}
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_awareness_linechart_legend"),
              yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
              increaseYAxisWidthInPercentage: 10,
            }}
          />
        ),
      });
    }

    return charts;
  }, [pieChartData, lineChartData, brands, searchId, t]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div className={styles.chartWrapper} onClick={onChartClicked}>
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
