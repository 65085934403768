import { memo, forwardRef, useLayoutEffect, useState, useContext } from "react";
import context from "../../context";

import styles from "./WidgetFooterButtons.module.scss";

// Inner imports
import type { Props } from "./types";
import {
  ReviewDataButtonBlock,
  DownloadExcelButton,
  DownloadImageButton,
} from "./components";

export const WidgetFooterButtons = memo(
  forwardRef<HTMLElement, Props>(
    (
      {
        searchId,
        downloadImageButtonProps,
        downloadExcelButtonProps,
        reviewDataButtonProps,
      },
      ref,
    ) => {
      const { isPresentation } = useContext(context);

      const [
        parentComponent,
        setParentComponent,
      ] = useState<HTMLElement | null>(null);

      useLayoutEffect(() => {
        if (typeof ref === "function" || !ref?.current) return;
        setParentComponent(ref.current);
      }, [ref]);

      return (
        <div className={styles.downloadButtons}>
          {reviewDataButtonProps && !!searchId && !isPresentation && (
            <ReviewDataButtonBlock
              searchId={searchId}
              type={reviewDataButtonProps.type}
            />
          )}
          {downloadImageButtonProps && parentComponent && (
            <DownloadImageButton
              parentComponent={parentComponent}
              {...downloadImageButtonProps}
            />
          )}
          {downloadExcelButtonProps && !!searchId && (
            <DownloadExcelButton
              searchId={searchId}
              {...downloadExcelButtonProps}
            />
          )}
        </div>
      );
    },
  ),
);
