import firebase from "firebase/app";
import * as yup from "yup";

import { showDevelopmentError } from "../../utils";
import { Industry } from "./industriesSlice";

const industrySchema = yup
  .object({
    title: yup.string().required(),
  })
  .noUnknown();

export async function getIndustries() {
  const collection = await firebase.firestore().collection("industries").get();

  return collection.docs.reduce<Industry[]>((acc, doc) => {
    try {
      const { title } = industrySchema.validateSync(doc.data());
      acc.push({ id: doc.id, label: title });
    } catch (error) {
      showDevelopmentError({
        additionalTexts: ["INDUSTRIES FETCHING OR VALIDATE ERROR"],
        error,
      });
    }

    return acc;
  }, []);
}
