import { useTranslation } from "react-i18next";
import styles from "./TermsAndConditionsOfUse.module.scss";

const TermsAndConditionsOfUse = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.termsAndConditionsOfUse}>
      <div className={styles.content}>
        <div className={styles.title}>{t("terms_and_condition_title")}</div>
        <div className={styles.text}>
          {t("terms_and_conditions_text1")}
          <br />
          <br />
          {t("terms_and_conditions_text2")}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsOfUse;
