import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { selectDashboardViewSettings } from "src/store/selectors";
import { fetchDashboardViewSettingsById } from "src/store/dashboards/dashboardsSlice";

export const useReadDashboardViewSettings = (dashboardId: string): void => {
  const dispatch = useDispatch();

  const dashboard = useSelector(
    ({ dashboards }: RootState) => dashboards.entities[dashboardId],
  );

  const dashboardViewSettings = useSelector(
    selectDashboardViewSettings(dashboardId),
  );

  useEffect(() => {
    if (dashboard && dashboardViewSettings) return;

    (async () => {
      try {
        await dispatch(fetchDashboardViewSettingsById(dashboardId));
      } catch (err) {
        console.error(err);
      }
    })();
  }, [dashboard, dashboardId, dashboardViewSettings, dispatch]);
};
