// Order of widgets in Sidebar is restricted. Ticket: DEV-8983
const SIDEBAR_WIDGET_IDS = [
  "share of volume",
  "share of search",
  "top trend keywords",
  "search trends",
  "sentiment trends",
  "share of earned media",
  "attitude",
  "sentiment drivers",
  "business drivers",
  "key areas",
  "sentiment index",
  "share of search funnel",
  "position vs competition",
  "data robustness score",
  "funnel",
  "esg index",
  "esg sentiment",
  "esg distribution",
  "reputation index",
  "reputation sentiment",
  "reputation distribution",
  "awareness",
];

const OPEN_BUTTON_ID = "addWidgetsToDashboard";

export { SIDEBAR_WIDGET_IDS, OPEN_BUTTON_ID };
