import {
  createEntityAdapter,
  createSlice,
  Dictionary,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
};

export const syncsAdapter = createEntityAdapter<Store.Sync>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = syncsAdapter.getInitialState<InitialState>({
  status: "idle",
  error: null,
});

const syncsSlice = createSlice({
  name: "syncs",
  initialState,
  reducers: {
    upsertSyncs: syncsAdapter.upsertMany,
    upsertSyncsUniqBySearchId: (state, action: PayloadAction<Store.Sync[]>) => {
      const currentSyncs = state.entities;
      const newSyncs = action.payload;

      const syncIdsToRemove = getSyncIdsToRemove(currentSyncs, newSyncs);

      syncsAdapter.removeMany(state, syncIdsToRemove);
      syncsAdapter.upsertMany(state, action);
    },
    updateSyncsStatus: (
      state,
      action: PayloadAction<InitialState["status"]>,
    ) => {
      state.status = action.payload;
    },
    removeAllSyncs: (state) => {
      state.status = "idle";
      syncsAdapter.removeAll(state);
    },
  },
});

export const {
  upsertSyncs,
  upsertSyncsUniqBySearchId,
  removeAllSyncs,
  updateSyncsStatus,
} = syncsSlice.actions;
export default syncsSlice.reducer;

function getSyncIdsToRemove(
  currentSyncs: Dictionary<Store.Sync>,
  newSyncs: Store.Sync[],
): Store.Sync["id"][] {
  const searchNewSyncIdMap = new Map<Store.Search["id"], Store.Sync["id"]>();
  const syncIdsToRemove = new Set<string>();

  for (const { id: syncId, searchId } of newSyncs)
    searchNewSyncIdMap.set(searchId, syncId);

  for (const syncId in currentSyncs) {
    const sync = currentSyncs[syncId];

    if (!sync) continue;

    const { searchId, id: currentSyncId } = sync;
    const newSyncId = searchNewSyncIdMap.get(searchId);

    if (newSyncId && newSyncId !== currentSyncId)
      syncIdsToRemove.add(currentSyncId);
  }

  return Array.from(syncIdsToRemove);
}
