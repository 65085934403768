import firebase from "firebase/app";

import axios from "src/axios";
import { showDevelopmentError } from "src/utils";
import syncSchema, { SyncSchemaType } from "./syncsSchema";

const SYNC_COLLECTIONS: Record<DataSource, string> = {
  meltwater: "sync",
  socialgist: "syncs",
};

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

export const subscribeOnSyncs = (
  searchIds: string[],
  dataSource: DataSource,
  callback: (syncs: Store.Sync[]) => void,
  errorCallback: () => void,
): Function => {
  const unsubscriptions = searchIds.map((searchId) =>
    getSyncRef(searchId, dataSource).onSnapshot(
      ({ docs }) => {
        const syncs: Store.Sync[] = [];

        for (const doc of docs) {
          try {
            const validatedSync = syncSchema.validateSync(doc.data());
            const sync = formatSync(validatedSync);

            syncs.push({
              ...sync,
              id: doc.id,
            });
          } catch (error) {
            showDevelopmentError({
              additionalTexts: ["SYNC VALIDATION ERROR"],
              error,
            });
          }
        }

        callback(syncs);
      },
      (error) => {
        showDevelopmentError({
          additionalTexts: ["SYNCS SUBSCRIPTION ERROR"],
          error,
        });

        errorCallback();
      },
    ),
  );

  return () => unsubscriptions.map((unsubscribe) => unsubscribe());
};

export const startNewSync = ({
  searchId,
  userId,
  mainDataSource,
}: {
  searchId: string;
  userId: string;
  mainDataSource: DataSource;
}): Promise<void> => {
  if (mainDataSource === "socialgist")
    return axios.post(`syncs-service-${urlInsertion}-api/v1/new`, {
      brandId: searchId,
      isCreatedByScheduler: true,
    });

  return axios.post(`syncs-${urlInsertion}-api/sync/new`, {
    brandId: searchId,
    profileId: userId,
    isCreatedByScheduler: true,
  });
};

function getSyncRef(
  searchId: string,
  dataSource: DataSource,
): firebase.firestore.Query<firebase.firestore.DocumentData> {
  return firebase
    .firestore()
    .collection(SYNC_COLLECTIONS[dataSource])
    .where("brandId", "==", searchId)
    .orderBy("createdAt", "desc")
    .limit(1);
}

function formatSync(sync: SyncSchemaType): Omit<Store.Sync, "id"> {
  const { brandId, ...rest } = sync;

  return {
    ...rest,
    searchId: brandId,
  };
}
