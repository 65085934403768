export function getCompetitivenessDescriptionInsertion(
  data?: (number | undefined)[],
): [string, string, string] | void {
  if (!data) return;
  const modifiedData: number[] = data.filter(
    (item) => item !== undefined,
  ) as number[];
  if (modifiedData.length < 2) return;
  const firstValue = modifiedData[0];
  const lastValue = modifiedData[modifiedData.length - 1];
  return lastValue! > firstValue!
    ? ["gaining", "positive", "increasing"]
    : lastValue! < firstValue!
    ? ["losing", "negative", "decreasing"]
    : ["keeping", "flat", "keeping"];
}

export function getAttitudeDescriptionInsertion(data?: {
  [key: string]: number;
}): string | void {
  if (!data) return;
  const highestValue = Math.max(...Object.values(data));
  const highestKey = Object.keys(data).find(
    (key) => data[key as keyof typeof data] === highestValue,
  );
  // if (highestKey) return `${highestKey.toLowerCase()} (highest value ${highestValue}%)` [highestKey.toLowerCase(), highestValue ];
  if (highestKey) return highestKey.toLowerCase();
}

export function getKeyAreasDescriptionInsertion(
  data?: {
    label: string;
    values: {
      impact: number;
      potential: number;
    };
  }[],
): string | void {
  if (!data || !data.length) return;
  const highestValue = Math.max(
    ...data.map(({ values }) => values.impact + values.potential),
  );
  const highestLabel = data.find(
    ({ values }) => values.impact + values.potential === highestValue,
  )?.label;
  if (highestLabel) return highestLabel.toLowerCase();
}

export function getGeneralAttributesDescriptionInsertion(
  data?: { attribute: string; value?: number }[],
): string | void {
  if (!data || !data.length) return;
  const modifiedData = data.filter(({ value }) => value !== undefined) as {
    attribute: string;
    value: number;
  }[];
  if (!modifiedData.length) return;
  const highestValue = Math.max(...modifiedData.map(({ value }) => value));
  const highestAttribute = modifiedData.find(
    ({ value }) => value === highestValue,
  )?.attribute!;
  return highestAttribute;
}

export function getPositionVsCompetitionDescriptionInsertion(data?: {
  attributes: string[];
  values?: { x: number; y: number };
}): [string, string] | void {
  if (!(data && data.attributes.length >= 4 && data.values)) return;
  const attribute1 =
    data.values.x > 0 ? data.attributes[1] : data.attributes[3];
  const attribute2 =
    data.values.y > 0 ? data.attributes[0] : data.attributes[2];
  return [attribute1!, attribute2!];
}
