import {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useMemo,
  FC,
} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./Home.module.scss";
import context from "../../../context";
import ActionBar from "../../../components/ActionBar/ActionBar";
import { logOut } from "../../../store/actions";
import { updateUserLastActiveAt } from "../../../store/api";
import { Sidebar, Preloader } from "../../../components";
import { ROUTS } from "../../../data/routs";

// Inner imports
import {
  // Main data loaders ->
  useUserDataLoader,
  useCompanyDataLoader,
  useSearchesDataLoader,
  useSubscriptionPlanLoader,
  useWidgetsDataLoader,
  // Additional data loaders ->
  useMarketsAndIndustriesLoader,
  useKeyWordsWidgetsUpdateDateLoader,
  useTimePlotsLoader,
  // Subscriptions ->
  useSubscriptionOnSearchWidgetStateChange,
  useSubscriptionOnHardReload,
  useSubscriptionOnSubscriptionPlan,
  // Additional hooks ->
  useInitialRedirectLink,
  useSendUserDataToGTM,
  useReturnBackModal,
  useKeywordToolSettingsLoader,
} from "./hooks";

// Pages imports --->
const SettingsPage = lazy(() => import("../Settings/Settings"));
const EnterpriseDashboards = lazy(
  () => import("../EnterpriseDashboards/EnterpriseDashboards"),
);
const DashboardsPage = lazy(() => import("../Dashboards/Dashboards"));
const TimePlotterPage = lazy(() => import("../TimePlotter/TimePlotter"));
const SearchesPage = lazy(() => import("../Searches"));
const DemoBooking = lazy(() => import("../DemoBooking/DemoBooking"));
const FaqPage = lazy(() => import("../Faq/Faq"));
const LogOut = lazy(() => import("../LogOut/LogOut"));
// const WelcomePage = lazy(() => import("../WelcomePage/WelcomePage"));
// const FunnelReviewData = lazy(
//   () => import("./FunnelReviewData/FunnelReviewData"),
// );
// <--- Pages imports

const Home: FC = () => {
  const { actionBarProps } = useContext(context);

  // SELECTORS --->
  const userId = useSelector(({ user }: Store.State) => user.id);
  const isUserHasEnterpriseDashboards = useSelector(
    ({ company }: Store.State) => Boolean(company.enterpriseDashboards.length),
  );

  // LOAD MAIN DATA FOR APP --->
  const { loadingStatus: userLoadingStatus, companyId } = useUserDataLoader();

  const { loadingStatus: companyLoadingStatus } = useCompanyDataLoader(
    companyId,
  );

  const { loadingStatus: searchesLoadingStatus } = useSearchesDataLoader(
    companyId,
  );

  const {
    loadingStatus: subscriptionPlanLoadingStatus,
  } = useSubscriptionPlanLoader();

  const { loadingStatus: widgetsDataLoadingStatus } = useWidgetsDataLoader();
  // <--- LOAD MAIN DATA FOR APP

  // LOAD SECONDARY DATA FOR APP --->
  useMarketsAndIndustriesLoader();
  useKeyWordsWidgetsUpdateDateLoader();
  useTimePlotsLoader();
  useKeywordToolSettingsLoader();
  // <--- LOAD SECONDARY DATA FOR APP

  // SUBSCRIPTIONS ---->
  useSubscriptionOnSearchWidgetStateChange();
  useSubscriptionOnHardReload();
  useSubscriptionOnSubscriptionPlan();
  // <---- SUBSCRIPTIONS

  const isMainDataLoaded = useMemo(() => {
    return (
      userLoadingStatus === "succeeded" &&
      companyLoadingStatus === "succeeded" &&
      searchesLoadingStatus === "succeeded" &&
      subscriptionPlanLoadingStatus === "succeeded" &&
      widgetsDataLoadingStatus === "succeeded"
    );
  }, [
    companyLoadingStatus,
    searchesLoadingStatus,
    subscriptionPlanLoadingStatus,
    userLoadingStatus,
    widgetsDataLoadingStatus,
  ]);

  // ADDITIONAL HOOKS ---->
  const initialRedirectLink = useInitialRedirectLink();
  useReturnBackModal(companyId);
  useSendUserDataToGTM(isMainDataLoaded);
  // <---- ADDITIONAL HOOKS

  // Log out user if some of main data broken;
  useEffect(() => {
    if (
      userLoadingStatus === "failed" ||
      companyLoadingStatus === "failed" ||
      searchesLoadingStatus === "failed" ||
      subscriptionPlanLoadingStatus === "failed" ||
      widgetsDataLoadingStatus === "failed"
    ) {
      logOut();
    }
  }, [
    companyLoadingStatus,
    searchesLoadingStatus,
    subscriptionPlanLoadingStatus,
    userLoadingStatus,
    widgetsDataLoadingStatus,
  ]);

  useEffect(() => {
    updateUserLastActiveAt(userId);
  }, [userId]);

  const mainRef = useRef<HTMLDivElement>(null);

  if (!isMainDataLoaded) {
    return <Preloader modifier="global" className={styles.initialPreloader} />;
  }

  return (
    <div className={styles.home}>
      <Sidebar />
      <div ref={mainRef} className={styles.main} id="home-main">
        {actionBarProps && <ActionBar {...actionBarProps} />}
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={ROUTS.settingsPage}>
              <SettingsPage />
            </Route>
            <Route path={ROUTS.dashboardsPage}>
              <DashboardsPage />
            </Route>
            <Route path={ROUTS.eventsPage}>
              <TimePlotterPage />
            </Route>
            {isUserHasEnterpriseDashboards && (
              <Route path={ROUTS.enterpriseDashboardsPage}>
                <EnterpriseDashboards />
              </Route>
            )}
            {/* <Route path={ROUTS.funnelPage}>
                <FunnelReviewData />
              </Route> */}
            <Route path={ROUTS.trackersPage}>
              <SearchesPage />
            </Route>
            <Route exact path={ROUTS.demoBookingPage}>
              <DemoBooking />
            </Route>
            <Route exact path={ROUTS.faqPage}>
              <FaqPage wrapperRef={mainRef} />
            </Route>
            {/* <Route exact path={ROUTS.welcomePage}>
              <WelcomePage />
            </Route> */}
            <Route exact path={ROUTS.logout}>
              <LogOut />
            </Route>
            <Redirect to={initialRedirectLink} />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
