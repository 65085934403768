import { GREEN, RED, ORANGE } from "../../../data/colors";

const WIDGET_ID = "result-attitude";

const DATE_RANGES = [
  "oneYear",
  "sixMonths",
  "threeMonths",
  "twoMonths",
  "oneMonth",
] as const;

const LABELS = [
  { name: "positive", color: GREEN },
  { name: "negative", color: RED },
  { name: "neutral", color: ORANGE },
] as const;

const TOOLTIP_POSITION = { vertical: -175, horizontal: -15 } as const;

const LINE_CHART = {
  STROKE_WIDTH: 3,
  VERTICAL_PADDING: { top: 10, bottom: 0 },
} as const;

export { WIDGET_ID, DATE_RANGES, LABELS, TOOLTIP_POSITION, LINE_CHART };
