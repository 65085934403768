import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import * as yup from "yup";

import actionTypes from "../actionTypes";
import { postUser } from "./api";
import { executeCapterra, sendDataToGTM } from "../../utils";
import { createClickUpTask } from "../../utils/createClickUpTask";
import type { PostUserPayload } from "./api";

const notImportantStringSchema = yup.string().notRequired().default("");

const userFirebaseCollectionSchema = yup
  .object({
    firstName: notImportantStringSchema,
    lastName: notImportantStringSchema,
    settings: yup
      .object({
        department: notImportantStringSchema,
        role: notImportantStringSchema,
        title: notImportantStringSchema,
        phone: notImportantStringSchema,
      })
      .notRequired()
      .default({ department: "", role: "", title: "", phone: "" }),
    isVerified: yup.boolean().notRequired().default(false),
    isFirstSearchCreated: yup.boolean().notRequired().default(true),
  })
  .required();

// MUTATIONS
export const setUserData = (userData: Partial<Store.User>) => {
  return {
    type: actionTypes.SET_USER_DATA,
    userData,
  };
};

export const removeUserData = () => {
  return {
    type: actionTypes.REMOVE_USER_DATA,
  };
};

// ACTIONS
export const setAuthObserver = () => {
  return async (dispatch: Function): Promise<void> => {
    await new Promise<void>((resolve) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        user?.reload();
        if (user) {
          const { uid, email } = user;
          const creationTime = user?.metadata?.creationTime || "";

          dispatch(
            setUserData({
              id: uid,
              email: email ?? "",
              userCreatedAt: creationTime,
            }),
          );
        } else {
          dispatch({
            type: "user/loggedOut",
          });
        }
        resolve();
      });
    });
  };
};

export const signUp = (
  payload: PostUserPayload,
  isUserSignUpThroughCompanyLink: boolean,
): Function => {
  return async (): Promise<{
    userId?: string;
  }> => {
    let data: any;
    try {
      const response = await postUser(payload);
      sendDataToGTM("Sign-up", { isUserSignUpThroughCompanyLink });

      data = response.data;
    } catch (err) {
      const statusCode = err?.response?.status;
      const errorMessage =
        err?.response?.data?.error || err.message || String(err);

      const isBackEndError = String(statusCode)[0] === "5";

      const isProd =
        process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_ENV === "prod";

      if (
        typeof statusCode === "number" &&
        typeof errorMessage === "string" &&
        isBackEndError &&
        isProd
      ) {
        await createClickUpTaskIfRegistrationFailed(statusCode, errorMessage);
      }

      throw err;
    }
    if (
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_ENV === "prod"
    ) {
      try {
        executeCapterra();
      } catch (error) {
        console.error(error);
      }
    }

    return {
      userId: data.id,
    };
  };
};

export const logIn = async (
  email: string,
  password: string,
  isRemembered: boolean | undefined = true,
) => {
  const persistence = isRemembered ? "local" : "session";

  await firebase
    .auth()
    .setPersistence(persistence)
    .then(() => {
      sendDataToGTM("Log-in");
      return firebase.auth().signInWithEmailAndPassword(email, password);
    })
    .catch((error) => {
      throw error;
    });
};

export const logInByToken = async (token: string) => {
  await firebase
    .auth()
    .setPersistence("local")
    .then(() => {
      sendDataToGTM("Log-in");
      return firebase.auth().signInWithCustomToken(token);
    })
    .catch((error) => {
      throw error;
    });
};

export const reAuthenticate = (password: string): Function => {
  return async (): Promise<void> => {
    const user = firebase.auth().currentUser;
    if (user && user.email) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password,
      );
      await user.reauthenticateWithCredential(credential).catch((error) => {
        throw error.message;
      });
    }
  };
};

export const updateEmail = (email: string, userId: string): Function => {
  return async (dispatch: Function): Promise<void> => {
    const isUserExistsWithSuchEmail = await checkIfUserExistsWithSuchEmail(
      email,
    );

    if (isUserExistsWithSuchEmail) {
      throw "User with such email already exists";
    }

    const user = firebase.auth().currentUser;
    if (user) {
      await user.updateEmail(email).catch((error) => {
        throw error.message;
      });
      await updateUserEmailInFirebaseCollection(email, userId);
      dispatch(setUserData({ email, isVerified: false }));
    }
  };
};

export const updatePassword = (password: string): Function => {
  return async (): Promise<void> => {
    const user = firebase.auth().currentUser;
    if (user) {
      await user.updatePassword(password).catch((error) => {
        throw error.message;
      });
    }
  };
};

export const resetPassword = (email: string): Function => {
  return async (): Promise<void> => {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch((error) => {
        console.dir(error);
        throw error.message;
      });
  };
};

export const logOut = async () => {
  await firebase.auth().signOut();
};

export const readUserProfile = (): ThunkAction<
  Promise<{ companyId: string }>,
  Store.State,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    let companyId = "";
    const { user } = getState();

    const db = firebase.firestore();
    const docRef = db.collection("userProfiles").doc(user.id);
    const doc = await docRef.get();

    const userData = doc.data();

    if (userData) {
      companyId = userData?.companies[0] || "";

      try {
        const validatedData = userFirebaseCollectionSchema.validateSync(
          userData,
        );

        dispatch(
          setUserData({
            // {id, email, userCreatedAt} already added by setAuthObserver function
            firstName: validatedData.firstName,
            lastName: validatedData.lastName,
            role: validatedData.settings.role,
            department: validatedData.settings.department,
            phone: validatedData.settings.phone,
            isVerified: validatedData.isVerified,
            isFirstSearchCreated: validatedData.isFirstSearchCreated,
          }),
        );
      } catch (err) {
        console.error(err);
      }
    } else {
      throw "Can't find user profile";
    }

    return { companyId };
  };
};

export const updateUserProfileNames = (names: {
  firstName?: string;
  lastName?: string;
}): Function => {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    const { user } = getState();
    const db = firebase.firestore();
    const docRef = db.collection("userProfiles").doc(user.id);
    await docRef.update(names);
    if (names.firstName) {
      await changeUserDisplayNameInFirebaseAuth(names.firstName);
    }
    dispatch(setUserData(names));
  };
};

export const updateUserProfileSettings = (settings: {
  role?: string;
  department?: string;
  phone?: string;
}): Function => {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    const { user } = getState();
    const db = firebase.firestore();
    const docRef = db.collection("userProfiles").doc(user.id);
    await docRef.set({ settings }, { merge: true });
    dispatch(setUserData(settings));
  };
};

export const updateIsUserMadeFirstSearch = (): Function => {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    const { user } = getState();
    const db = firebase.firestore();
    const docRef = db.collection("userProfiles").doc(user.id);
    await docRef.set({ isFirstSearchCreated: true }, { merge: true });
    dispatch(
      setUserData({
        isFirstSearchCreated: true,
      }),
    );
  };
};

export const hardReload = (userId: string): Function => {
  const db = firebase.firestore();
  const docRef = db.collection("userProfiles").doc(userId);

  const subscription = docRef.onSnapshot((snap: any) => {
    const snapData = snap.data();

    if (!snapData) return;

    const whetherToReboot = snapData.hardReload;

    if (whetherToReboot) {
      if (whetherToReboot.whetherItNeedsUpdate) {
        const newData = {
          whetherItNeedsUpdate: false,
          lastUpdate: new Date().toISOString(),
        };

        db.collection("userProfiles")
          .doc(userId)
          .set({ hardReload: newData }, { merge: true })
          .then(() => {
            window.location.reload(true);
          });
      }
    }
  });

  return subscription;
};

async function changeUserDisplayNameInFirebaseAuth(displayName: string) {
  const user = firebase.auth().currentUser;

  if (!user) return console.error("Can not update user data in firebase auth");

  try {
    user.updateProfile({ ...user, displayName });
  } catch (err) {
    console.error(err);
  }
}

async function createClickUpTaskIfRegistrationFailed(
  statusCode: number,
  errorMessage: string,
) {
  const SERGEY_LEKONTSEV_EMAIL = "sergey@gravizlabs.com";
  const YURII_HRECHENIUK_EMAIL = "yuriy@mytelescope.io";

  await createClickUpTask({
    name: `Registration failed with ${statusCode} status code`,
    assignees: [SERGEY_LEKONTSEV_EMAIL, YURII_HRECHENIUK_EMAIL],
    text_content: {
      error: errorMessage,
    },
  });
}

async function checkIfUserExistsWithSuchEmail(email: string) {
  const signInMethod = await firebase.auth().fetchSignInMethodsForEmail(email);

  return !!signInMethod.length;
}

async function updateUserEmailInFirebaseCollection(
  email: string,
  userId: string,
) {
  const db = firebase.firestore();
  const docRef = db.collection("userProfiles").doc(userId);
  await docRef.update({
    email,
    isVerified: false,
    verifyEmailAttemptsNumber: 0,
  });
}
