import actionTypes from "../actionTypes";

export const initialState: Store.WidgetsState = {};

const setWidgetsState = (state: Store.WidgetsState, action: any) => {
  const { searchId, widgetsState } = action;
  const currentWidgetState = state[searchId];

  if (!currentWidgetState) {
    return {
      ...state,
      [searchId]: widgetsState,
    };
  }

  const widgetState = action.widgetsState;

  return {
    ...state,
    [searchId]: { ...currentWidgetState, ...widgetState },
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_WIDGETS_STATE:
      return setWidgetsState(state, action);
    default:
      return state;
  }
};

export default reducer;
