import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";

import styles from "./RobustnessScorePreview.module.scss";
import { Preloader, ErrorPlaceholder } from "../../../components";

// Inner imports
import { WIDGET_ID } from "./constants";
import { DataSourceScoreCellChart } from "../../../components";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const RobustnessScorePreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const mainBrand = widgetData?.brands[0];

  const size = Math.min(chartWrapperWidth - 32, chartWrapperHeight - 16);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const widgetCellChartData = widgetData?.cellChart?.[mainBrand?.id || ""];

    if (!widgetCellChartData) return charts;

    const dataWithoutRobustnessValue = Object.entries(
      widgetCellChartData,
    ).filter(([property]) => property !== "robustness");

    if (
      dataWithoutRobustnessValue.some(
        (cellChartsElement) => cellChartsElement[1] > 0,
      )
    ) {
      charts.push({
        type: "cellChart",
        chart: (
          <div className={styles.cellChartsWrapper}>
            {dataWithoutRobustnessValue.map(([property, value]) => (
              <DataSourceScoreCellChart
                title={formatDataSourceScoreCellChartTitle(
                  property as keyof Robustness.Item,
                )}
                value={value}
                style={{
                  width: size / 4,
                }}
                cellStyles={{
                  width: size / 40 - 2,
                  height: size / 40 - 2,
                }}
                key={property}
                className={styles.dataSourceScoreCellChart}
              />
            ))}
          </div>
        ),
      });
    }

    return charts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainBrand?.id, widgetData?.cellChart, size]);

  const { widgetViews: views } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  function formatDataSourceScoreCellChartTitle(title: keyof Robustness.Item) {
    switch (title) {
      case "blogs":
        return t("w_dd_blogs_label");
      case "search":
        return t("w_dd_search_label");
      case "videos":
        return t("w_dd_videos_label");
      case "robustness":
        return t("w_dd_robustness_label");
      case "forums":
        return t("w_dd_forums_label");
      case "newsAndEditorial":
        return t("w_dd_newsAndEditorial_label");
      case "productReviews":
        return t("w_dd_productReviews_label");
      case "socialNetworks":
        return t("w_dd_socialNetworks_label");
      default:
        return title;
    }
  }

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.robustnessPreview} ref={chartWrapperRef}>
      {views.length ? (
        <>
          <div
            className={styles.chartWrapper}
            onClick={onChartClicked}
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
          >
            {views[0]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
