import format from "date-fns/format";
import add from "date-fns/add";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

export const formatToMonthDayYearDate = (date: string | number | Date) =>
  formatDate(date, "MMM d, yy");

export const formatToMonthYearDate = (date: string | number | Date) =>
  formatDate(date, "MMM, yy");

export const formatToDayMonthYearDate = (date: string | number | Date) =>
  formatDate(date, "dd.MM.yy");

export const formatToDayMonthFullYearDate = (date: string | number | Date) =>
  formatDate(date, "dd.MM.yyyy");

export const formatToDayMonthFullYearTimeDate = (
  date: string | number | Date,
) => formatDate(date, "dd.MM.yyyy, HH:mm");

export const formatToPartialDate = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd");

export const formatToCompleteDate = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd HH:mm:ss");

// Sun Jan 01 2023 00:00:00 GMT+0200 -> Sun Jan 01 2023 02:00:00 GMT+0200
export const resetUtcTime = (_date: string | number | Date): Date => {
  try {
    const date = new Date(_date);

    const timezoneOffset = date.getTimezoneOffset();

    if (timezoneOffset > 0) {
      date.setMinutes(24 * 60 - (timezoneOffset + 1));
    } else {
      date.setMinutes(-timezoneOffset);
    }

    date.setUTCHours(0, 0, 0, 0);

    return date;
  } catch (err) {
    console.error(err);

    return new Date();
  }
};

export const getDateWithMonthOffset = (monthOffset: number = 12) =>
  add(new Date(), {
    months: monthOffset,
  });

export const convertSecondsToIsoDate = (value?: number | string): string => {
  if (!value) return "";

  if (typeof value === "string" && Date.parse(value))
    return new Date(value).toISOString().toString();

  const number = Number(value) * 1000;

  if (isNaN(number) || !isFinite(number)) return "";

  return new Date(number).toISOString();
};

export const getDifferenceInMilliseconds = (
  firstDate: string | number | Date,
  secondDate: string | number | Date,
): number => {
  try {
    const [_firstDate, _secondDate] = [
      new Date(firstDate),
      new Date(secondDate),
    ];

    return differenceInMilliseconds(_firstDate, _secondDate);
  } catch (err) {
    console.error(err);

    return 0;
  }
};

function formatDate(_date: string | number | Date, template: string): string {
  try {
    const date = new Date(_date);

    return format(date, template);
  } catch (err) {
    console.error(err);

    return "";
  }
}
