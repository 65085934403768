import { FC, useMemo } from "react";
import Table from "rsuite/Table";

import { isNumber, numberFormatter } from "../../../../utils";

// Inner imports
import type { TotalVolumeCellProps } from "../../types";

const { Cell } = Table;

export const TotalVolumeCell: FC<TotalVolumeCellProps> = ({
  rowData,
  dataKey,
  ...props
}) => {
  const totalVolume: number = useMemo(() => {
    const isSuitable = isNumber(rowData?.totalVolume);

    return isSuitable ? rowData.totalVolume : 0;
  }, [rowData?.totalVolume]);

  return (
    <Cell {...props} dataKey={dataKey}>
      {numberFormatter(totalVolume)}
    </Cell>
  );
};
