const GENERAL_EDUCATE_ME_LINK =
  "https://help.mytelescope.io/en/collections/2810586-tutorials-on-marketing-models";

const SEARCH_SENTIMENT_EDUCATE_ME_LINK =
  "https://help.mytelescope.io/en/articles/5151413-search-sentiment-trend-a-how-to-guide";

const SEARCH_TRENDS_EDUCATION_ME_LINK =
  "https://help.mytelescope.io/en/articles/6491793-search-trend-widget-description";

const EDUCATE_ME_LINKS: { [widgetId: string]: string } = {
  "result-search-trends": SEARCH_TRENDS_EDUCATION_ME_LINK,
  "result-sentiment-trends": SEARCH_SENTIMENT_EDUCATE_ME_LINK,
  "result-search-share": GENERAL_EDUCATE_ME_LINK,
  "result-volume-share": GENERAL_EDUCATE_ME_LINK,
  "result-media-share": GENERAL_EDUCATE_ME_LINK,
  "result-associated-words": GENERAL_EDUCATE_ME_LINK,
  "result-position-vs-competition": GENERAL_EDUCATE_ME_LINK,
};

export { EDUCATE_ME_LINKS };
