import React, { FC } from "react";
import { ToastContainer, Zoom } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./ToastNotification.module.scss";

// Inner imports
import { TOAST_NOTIFICATIONS_LIMIT } from "./constants";

const ToastNotification: FC = () => (
  <ToastContainer
    position="bottom-center"
    icon={false}
    closeButton={false}
    hideProgressBar
    limit={TOAST_NOTIFICATIONS_LIMIT}
    transition={Zoom}
  />
);

export default ToastNotification;
