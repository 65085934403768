export default {
  funnel: {
    interest: "w_funnel_description_interest",
    consideration: "w_funnel_description_consideration",
    preference: "w_funnel_description_preference",
    "purchase intent": "w_funnel_description_purchase_intent",
  },
  shareOfSearchFunnel: {
    "share of search": "w_sos_funnel_description_share_of_search",
    consideration: "w_sos_funnel_description_consideration",
    preference: "w_sos_funnel_description_preference",
    "purchase intent": "w_sos_funnel_description_purchase_intent",
  },
} as const;
