import { FC, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import styles from "./DashboardDetailsModal.module.scss";
import { Info } from "../../../icons";
import context from "../../../context";

// Inner imports
import { ICON_COLOR, DATE_FORMAT } from "./constants";

type Props = { dashboardId: string };

export const DashboardDetailsModal: FC<Props> = ({ dashboardId }) => {
  const { t } = useTranslation();

  const { setModalElement } = useContext(context);

  const dashboard = useSelector(
    ({ dashboards }: RootState) => dashboards.entities[dashboardId],
  );

  const widgetsData = useSelector(({ widgetsData }: RootState) => widgetsData);

  const userId = useSelector(({ user }: Store.State) => user.id);
  const company = useSelector(({ company }: Store.State) => company);
  const searches = useSelector(({ searches }: RootState) => searches);

  const companyMembers = useMemo(() => company.members, [company]);

  const dashboardCreated = useMemo(() => {
    try {
      const date = new Date(dashboard?.createdAt || "");

      return format(date, DATE_FORMAT);
    } catch (err) {
      console.error(err);
    }

    return;
  }, [dashboard]);

  const dashboardOwner = useMemo(() => {
    const dashboardsOwnerId = dashboard?.userId;

    if (userId === dashboardsOwnerId) {
      return t("ddm_you_are_owner");
    }

    const owner = companyMembers.find((member) => {
      return member.id === dashboardsOwnerId;
    });

    return owner?.fullName;
  }, [companyMembers, dashboard?.userId, userId, t]);

  const dashboardVisibility = useMemo(() => {
    const { isPrivate } = dashboard || {};

    if (typeof isPrivate === "undefined") return;

    return t(isPrivate ? "ddm_is_private" : "ddm_not_private");
  }, [dashboard, t]);

  const usedSearches = useMemo(() => {
    const usedSearches: {
      searchName: string;
      usedWidgets: string;
    }[] = [];

    Object.entries(dashboard?.tiles || {}).forEach(
      ([searchId, widgetList], i) => {
        const searchName =
          searches.find(({ id }) => id === searchId)?.name ||
          t("ddm_initial_search_name", { number: i + 1 }) ||
          "";

        const usedWidgets = Object.keys(widgetList).reduce((acc, widget) => {
          const { placeHolderOnUi } = widgetsData.entities[widget] || {};

          placeHolderOnUi && acc.push(t(placeHolderOnUi));

          return acc;
        }, [] as string[]);

        if (!!usedWidgets.length) {
          usedSearches.push({
            searchName,
            usedWidgets: usedWidgets.join(", "),
          });
        }
      },
    );

    return usedSearches;
  }, [dashboard?.tiles, searches, widgetsData, t]);

  if (!dashboard) return <div>{t("request_error")}</div>;

  return (
    <div className={styles.dashboardDetailsModal}>
      <div className={styles.title}>
        <Info color={ICON_COLOR} />
        {dashboard.name}
      </div>
      <div className={styles.content}>
        {dashboardCreated && (
          <div className={styles.option}>
            <span>{t("ddm_created_label")}</span> {dashboardCreated}
          </div>
        )}
        {dashboardOwner && (
          <div className={styles.option}>
            <span>{t("ddm_owner_label")}</span> {dashboardOwner}
          </div>
        )}
        {dashboardVisibility && (
          <div className={styles.option}>
            <span>{t("ddm_visibility_label")}</span> {dashboardVisibility}
          </div>
        )}
        {!!usedSearches.length && (
          <div className={styles.option}>
            <span>{t("ddm_searches_label")}</span>
            <ul className={styles.usedSearches}>
              {usedSearches.map(({ searchName, usedWidgets }) => (
                <li key={searchName}>
                  <span>{searchName}</span>
                  {`(${usedWidgets})`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <button
        className={styles.doneButton}
        onClick={() => setModalElement(null)}
      >
        {t("ddm_done_btn")}
      </button>
    </div>
  );
};
