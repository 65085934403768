import firestore from "../../firestore";

export const writeWidgetStateStructure = async (
  searchId: string,
  widgetStateStructure: Store.WidgetsState[string],
) => {
  const doc = firestore().collection("searches-state").doc(searchId);

  await doc.set({ widgets: widgetStateStructure }, { merge: true });

  return widgetStateStructure;
};
