import { useState, useRef, useEffect, useMemo, FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SearchTrends.module.scss";
import DATE_RANGES_WITH_OVERALL from "../../../data/dateRangesWithOverall";
import {
  LineChart,
  WidgetButtonsBar,
  Preloader,
  ErrorPlaceholder,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { getCompetitivenessDescriptionInsertion } from "../../../utils";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { WIDGET_ID } from "./constants";
import { DATE_RANGES_TYPE } from "./types";
import { DEFAULT_DATE_RANGE } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const SearchTrend: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { lineChart } = widgetData || {};

  // Date Range ---->
  const dateRanges = useMemo(() => {
    if (lineChart) {
      return DATE_RANGES_WITH_OVERALL.filter(
        ({ value }) => !!lineChart[value]?.length,
      ).map(({ value, label }) => ({
        value,
        label,
      }));
    }

    return DATE_RANGES_WITH_OVERALL.map(({ value, label }) => ({
      value,
      label,
    }));
  }, [lineChart]);

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) {
      const isDefaultRangeExistInData = dateRanges.some(
        ({ value }) => value === DEFAULT_DATE_RANGE,
      );

      const dateRangesLength = dateRanges.length;

      const initialDateRange = isDefaultRangeExistInData
        ? DEFAULT_DATE_RANGE
        : dateRanges[dateRangesLength - 1]?.value;

      setCurrentDateRange(initialDateRange);
    }
  }, [dateRanges]);
  // <---- Date Range

  const firstBrand = widgetData?.brands[0];

  const dataFilteredByPeriod = useMemo(() => {
    if (!currentDateRange) return [];

    if (lineChart) {
      return lineChart[currentDateRange] || [];
    }

    return [];
  }, [lineChart, currentDateRange]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (dataFilteredByPeriod.length >= 2) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            data={dataFilteredByPeriod}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_st_linechart_legend"),
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    return charts;
  }, [dataFilteredByPeriod, searchId, dashboardId, t]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const descriptionInsertion =
    firstBrand &&
    getCompetitivenessDescriptionInsertion(
      widgetData?.lineChart?.[currentDateRange || DEFAULT_DATE_RANGE]?.map(
        (item) => item.values[firstBrand!.name],
      ),
    );

  const conclusion = useMemo(() => {
    if (!descriptionInsertion) return "";

    return t("w_st_main_tip", {
      brandName: firstBrand!.name,
      descriptionInsertion: descriptionInsertion[1],
    });
  }, [descriptionInsertion, firstBrand, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          timePeriodSelector={{
            periods: dateRanges,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              selectedView?.chart
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            downloadExcelButtonProps={{
              widgetName: "search-trends",
              fileName: downloadedFileName,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
