import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./EditTitle.module.scss";
import { TextArea } from "../../../inputs/TextArea/TextArea";

// Inner imports
import { MAX_SYMBOLS_QUANTITY } from "./constants";

type Props = {
  customTitle?: string;
  onSaveHandler: (text: string) => void;
  onCancelHandler: () => void;
};

const EditTitle: FC<Props> = ({
  customTitle,
  onSaveHandler,
  onCancelHandler,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(customTitle || "");

  return (
    <form className={styles.form}>
      <TextArea
        className={styles.textarea}
        value={text}
        changeHandler={setText}
        maxLength={MAX_SYMBOLS_QUANTITY}
      />
      <div className={styles.buttonRow}>
        <button
          className={styles.redButton}
          onClick={onCancelHandler}
          type="button"
        >
          {t("edit_title_cancel")}
        </button>
        <button
          className={styles.greenButton}
          onClick={() => onSaveHandler(text)}
          type="button"
        >
          {t("edit_title_save")}
        </button>
      </div>
    </form>
  );
};

export { EditTitle };
