import { useDispatch } from "react-redux";

import {
  updateOneDashboardViewSettings,
  WidgetViewSettings,
} from "../../store/dashboards/dashboardsSlice";

type UseUpdateDashboardViewSettings = {
  dashboardId: string;
  searchId?: string;
  widgetId?: WIDGET_IDS_TYPES;
};

type UseUpdateDashboardViewSettingsResult = (
  changes: WidgetViewSettings,
) => void;

export const useUpdateDashboardViewSettings = ({
  dashboardId,
  searchId,
  widgetId,
}: UseUpdateDashboardViewSettings): UseUpdateDashboardViewSettingsResult => {
  const dispatch = useDispatch();

  const updateDashboardViewSettings = async (changes: WidgetViewSettings) => {
    if (!searchId || !widgetId || !dashboardId) return;

    try {
      await dispatch(
        updateOneDashboardViewSettings({
          searchId,
          widgetId,
          dashboardId,
          changes,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  return updateDashboardViewSettings;
};
