import { FC, useEffect, useMemo, useState } from "react";
import Pagination from "react-paginate";
import cx from "classnames";
import omit from "lodash/omit";
import isNaN from "lodash/isNaN";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useTranslation } from "react-i18next";

import styles from "./SearchTable.module.scss";
import { Input, Preloader, Select } from "../../components";
import {
  Triangle,
  Trash,
  Magnifier,
  CheckBoxChecked,
  CheckBoxEmpty,
  ChevronLeft,
  ChevronRight,
} from "../../icons";
import { COLUMNS_FILTER_TYPES } from "../../containers/Home/Searches/components/Data/constants";
import { stripHTMLFromString } from "../../utils";

// Inner imports
import { Columns, Props, TableRow, Sorting, TableColumnsFilter } from "./types";
import {
  BREAK_LABEL,
  CHECKBOX_CHECKED_COLOR,
  ELEMENTS_PER_PAGE,
  INPUT_MAX_LENGTH,
  MAGNIFIER_ICON_SIZE,
  BLOCKED_CHECKBOX_COLOR,
} from "./constants";

export const SearchTable: FC<Props> = ({
  className,
  tableClassName = "",
  style,
  status,
  columns,
  rows = [],
  hasFilter,
  filterPlaceholder,
  filteringFields,
  hasRowSelection,
  selectedRowIds = {},
  onSelectedRowIdsChanged,
  hasRowDeletion,
  onRowDeleted,
  loadMoreHandler,
  noRowsText,
  canLoadMore,
  columnsFilterOptions,
  isColumnsReversed,
  defaultSort,
}) => {
  const { t } = useTranslation();

  // FILTERING
  const [filter, setFilter] = useState<string>("");

  const {
    value: initialColumnsFilterValue = "",
    type: initialColumnsFilterType = "",
  } = columnsFilterOptions?.[0] || {};

  const [columnsFilter, setColumnsFilter] = useState<TableColumnsFilter>({
    value: initialColumnsFilterValue,
    type: initialColumnsFilterType,
  });

  const filteredRows: TableRow[] = useMemo(() => {
    if (!filteringFields?.length) return rows;

    const filterInLowerCase = filter.toLowerCase();

    return rows.filter((row) => {
      const joinedValues = filteringFields.reduce((acc, field) => {
        const value = row[field] ? String(row[field]).toLowerCase() : "";
        return acc + value;
      }, "");

      return joinedValues.includes(filterInLowerCase);
    });
  }, [filteringFields, filter, rows]);

  const hasColumnsFilter: boolean = !!columnsFilterOptions?.length;

  const filteredColumns: Columns = useMemo(() => {
    const { type: filterType, value } = columnsFilter;

    if (!hasColumnsFilter || !columnsFilter) return columns;

    const infoColumn = columns.slice(0, 1);
    let dataColumns = [];

    if (filterType === COLUMNS_FILTER_TYPES.columnsCount) {
      const columnsCount = +value;

      if (isNaN(columnsCount)) return columns;

      dataColumns = columns.slice(1, columnsCount + 1);
    } else {
      dataColumns = columns.filter(({ label }) => label.includes(value));
    }

    if (isColumnsReversed) dataColumns.reverse();

    return infoColumn.concat(dataColumns);
  }, [columns, columnsFilter, hasColumnsFilter, isColumnsReversed]);

  // SORTING
  const [sorting, setSorting] = useState<Sorting>(null);

  const sortedRows: TableRow[] = useMemo(() => {
    if (!sorting)
      return defaultSort
        ? [...filteredRows].sort((a, b) => defaultSort(a, b, columnsFilter))
        : filteredRows;

    const { field, direction } = sorting;

    return [...filteredRows].sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];
      let compareResult = 0;

      if (typeof valueA === "string" && typeof valueB === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        compareResult =
          direction === "desc"
            ? valueB.localeCompare(valueA)
            : valueA.localeCompare(valueB);
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        if (isNaN(valueA)) valueA = 0;
        if (isNaN(valueB)) valueB = 0;
        compareResult =
          direction === "desc" ? valueB - valueA : valueA - valueB;
      }

      const [isASelected, isBSelected] = [
        !!selectedRowIds[a.id],
        !!selectedRowIds[b.id],
      ];

      return Number(isBSelected) - Number(isASelected) || compareResult;
    });
  }, [sorting, defaultSort, filteredRows, columnsFilter, selectedRowIds]);

  function onSortableFieldClicked(field: string): void {
    const previousField = sorting?.field;

    if (field === previousField) {
      const previousDirection = sorting?.direction;
      setSorting(
        previousDirection === "desc"
          ? {
              field,
              direction: "asc",
            }
          : null,
      );
    } else {
      setSorting({
        field,
        direction: "desc",
      });
    }
  }

  // PAGINATION
  const [page, setPage] = useState<number>(0);

  const pageQuantity: number = useMemo(
    () => Math.ceil(filteredRows.length / ELEMENTS_PER_PAGE),
    [filteredRows],
  );

  const pageRows: TableRow[] = useMemo(
    () =>
      sortedRows.slice(
        ELEMENTS_PER_PAGE * page,
        ELEMENTS_PER_PAGE * page + ELEMENTS_PER_PAGE,
      ),
    [sortedRows, page],
  );

  const isLastPage: boolean = useMemo(() => page + 1 === pageQuantity, [
    page,
    pageQuantity,
  ]);

  useEffect(() => {
    if (page >= pageQuantity) setPage(0);
  }, [page, pageQuantity]);

  // SELECTION
  const isEachPageRowSelected: boolean = useMemo(() => {
    return !!pageRows.length && pageRows.every(({ id }) => selectedRowIds[id]);
  }, [pageRows, selectedRowIds]);

  function onBlankMultiCheckboxClicked(): void {
    if (!(onSelectedRowIdsChanged && pageRows.length)) return;

    const newSelectedRowIds = { ...selectedRowIds };
    pageRows.forEach(({ id }) => (newSelectedRowIds[id] = true));

    onSelectedRowIdsChanged(newSelectedRowIds);
  }

  function onMultiCheckboxClicked(): void {
    const filteredRows = pageRows.filter(
      ({ isBlocked }) => !Boolean(isBlocked),
    );

    onSelectedRowIdsChanged?.call(
      null,
      omit(
        selectedRowIds,
        filteredRows.map(({ id }) => id),
      ),
    );
  }

  function onRemoveClicked(id: string): void {
    onRowDeleted?.call(null, id);
  }

  function onBlankCheckboxClicked(id: string): void {
    onSelectedRowIdsChanged?.call(null, { ...selectedRowIds, [id]: true });
  }

  function onCheckboxClicked(id: string): void {
    onSelectedRowIdsChanged?.call(null, omit(selectedRowIds, [id]));
  }

  // MISC
  function toHTML(value: unknown): string {
    switch (typeof value) {
      case "string":
        return value;
      case "number":
        return value.toLocaleString();
      default:
        return "";
    }
  }

  function toDateDistance(value: unknown): string {
    if (typeof value !== "number" || isNaN(value)) return "";
    return formatDistanceToNow(value, {
      addSuffix: true,
    });
  }

  function RenderColumn({
    field,
    fieldId,
    isDate,
    isExpandable,
    isWrapped,
    dropdown,
    maxWidth,
  }: {
    field?: string | number | boolean;
    fieldId?: string;
    isDate?: boolean;
    isExpandable?: boolean;
    isWrapped?: boolean;
    dropdown?: {
      options: Option[];
      isDropdownChecked: (value: string, fieldId?: string) => void;
    };
    maxWidth?: number;
  }): JSX.Element {
    const parsedField = isDate ? toDateDistance(field) : toHTML(field);

    switch (true) {
      case typeof dropdown !== "undefined":
        return (
          <td
            style={{
              ...(maxWidth
                ? {
                    maxWidth: `${maxWidth}px`,
                    overflow: "auto",
                  }
                : {}),
            }}
          >
            <Select
              className={styles.selectWrapper}
              options={dropdown?.options}
              changeHandler={(value) =>
                dropdown?.isDropdownChecked(value, fieldId)
              }
              value={String(field)}
            />
          </td>
        );
      default:
        return (
          <td
            title={stripHTMLFromString(parsedField)}
            className={cx({
              [styles.expandableCell!]: isExpandable,
              [styles.isNoWrap!]: isWrapped,
            })}
            style={{
              ...(maxWidth
                ? {
                    maxWidth: `${maxWidth}px`,
                    overflow: "auto",
                  }
                : {}),
            }}
            dangerouslySetInnerHTML={{
              __html: parsedField,
            }}
          />
        );
    }
  }

  function RenderCheckBox(fields: TableRow) {
    if (Boolean(fields["isBlocked"])) {
      const tip = fields["blockedTooltip"];

      return (
        <td>
          {selectedRowIds[fields.id] ? (
            <CheckBoxChecked
              title={tip}
              className={styles.clockedCheckboxIcon}
              color={BLOCKED_CHECKBOX_COLOR}
            />
          ) : (
            <CheckBoxEmpty
              className={styles.checkboxIcon}
              onClick={() => onBlankCheckboxClicked(fields.id)}
            />
          )}
        </td>
      );
    }

    if (hasRowDeletion) {
      return (
        <td>
          <Trash
            className={styles.trashIcon}
            onClick={() => onRemoveClicked(fields.id)}
          />
        </td>
      );
    }

    if (hasRowSelection) {
      return (
        <td style={{ fontSize: 0 }}>
          {selectedRowIds[fields.id] ? (
            <CheckBoxChecked
              className={styles.checkboxIcon}
              color={CHECKBOX_CHECKED_COLOR}
              onClick={() => onCheckboxClicked(fields.id)}
            />
          ) : (
            <CheckBoxEmpty
              className={styles.checkboxIcon}
              onClick={() => onBlankCheckboxClicked(fields.id)}
            />
          )}
        </td>
      );
    }
    return null;
  }

  return (
    <div className={className} style={style}>
      <div className={styles.filtersWrapper}>
        {hasFilter && (
          <div className={styles.filter}>
            <Input
              className={styles.input}
              value={filter}
              changeHandler={(value) => setFilter(value)}
              placeholder={filterPlaceholder ?? t("dc_st_select_search_terms")}
              maxLength={INPUT_MAX_LENGTH}
            />
            <Magnifier
              className={styles.filterIcon}
              size={MAGNIFIER_ICON_SIZE}
              opacity={filter ? 1 : 0.25}
            />
          </div>
        )}
        {hasColumnsFilter && (
          <Select
            options={columnsFilterOptions}
            changeHandler={(value) => {
              const filterType =
                columnsFilterOptions?.find((column) => column.value === value)
                  ?.type || "";

              setColumnsFilter({
                value,
                type: filterType,
              });
            }}
            value={columnsFilter.value}
            openingDirection="down"
          />
        )}
      </div>
      <div className={styles.main}>
        {status === "loading" && (
          <Preloader className={styles.preloader} type="bar" />
        )}
        {status === "succeeded" &&
          (rows.length ? (
            <>
              <div className={styles.tableWrapper}>
                <table className={styles[tableClassName]}>
                  <thead>
                    <tr>
                      {hasRowDeletion && <th className={styles.rowDeletion} />}
                      {hasRowSelection && (
                        <th className={styles.rowSelection}>
                          {isEachPageRowSelected ? (
                            <CheckBoxChecked
                              className={styles.checkboxIcon}
                              color={CHECKBOX_CHECKED_COLOR}
                              onClick={onMultiCheckboxClicked}
                            />
                          ) : (
                            <CheckBoxEmpty
                              className={styles.checkboxIcon}
                              onClick={onBlankMultiCheckboxClicked}
                            />
                          )}
                        </th>
                      )}
                      {filteredColumns.map(
                        ({ label, field, isSortable, isSqueezed }, i) => (
                          <th
                            style={{
                              width: isSqueezed ? 1 : undefined,
                              cursor: isSortable ? "pointer" : "auto",
                            }}
                            onClick={
                              isSortable
                                ? () => onSortableFieldClicked(field)
                                : undefined
                            }
                            key={i}
                          >
                            {sorting?.field === field && (
                              <Triangle
                                className={cx(styles.sortingIcon, {
                                  [styles.sortingIconDown!]:
                                    sorting?.direction === "desc",
                                })}
                                size={8}
                              />
                            )}
                            {label}
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {pageRows.map((fields, i) => (
                      <tr key={i}>
                        <>
                          {RenderCheckBox(fields)}
                          {filteredColumns.map(
                            (
                              {
                                field,
                                isDate,
                                isExpandable,
                                isWrapped,
                                maxWidth,
                                dropdown,
                              },
                              k,
                            ) => (
                              <RenderColumn
                                field={fields[field]}
                                fieldId={fields.id}
                                isDate={isDate}
                                isExpandable={isExpandable}
                                isWrapped={isWrapped}
                                dropdown={dropdown}
                                maxWidth={maxWidth}
                                key={k}
                              />
                            ),
                          )}
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!filteredRows.length && (
                  <div className={styles.warning}>{t("dc_st_no_entries")}</div>
                )}
              </div>
              <div className={styles.paginationWrapper}>
                {!!pageQuantity && (
                  <Pagination
                    previousLabel={<ChevronLeft />}
                    nextLabel={<ChevronRight />}
                    breakLabel={BREAK_LABEL}
                    pageCount={pageQuantity}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    forcePage={page}
                    onPageChange={({ selected }) => setPage(selected)}
                    containerClassName={styles.pagination}
                    activeLinkClassName={styles.activeLink}
                  />
                )}
              </div>
              {isLastPage && canLoadMore && loadMoreHandler && (
                <div className={styles.loadMoreButtonWrapper}>
                  <button onClick={() => loadMoreHandler()}>
                    {t("dc_st_load_more")}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className={styles.message}>
              {noRowsText || t("dc_st_no_suggestions")}
            </div>
          ))}
        {status === "failed" && (
          <div className={styles.message}>{t("dc_st_failed_to_fetch")}</div>
        )}
      </div>
    </div>
  );
};
