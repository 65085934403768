import { useContext, useMemo } from "react";
import classes from "./EsgIndexPieChartView.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import context from "../../../../../context";
import esgParameters from "../../../../../data/esgParameters";
import clamp from "lodash/clamp";

interface Props {
  data: EsgIndex.PieChart.Data;
}

const EsgIndexPieCharts = ({ data }: Props) => {
  const { isDarkTheme } = useContext(context);

  const modifiedData = useMemo(() => {
    return esgParameters.map(({ name, color }) => {
      const fixedValue = +data.values[name]?.toFixed()! || 0;

      return {
        label: name,
        data: [
          {
            value: fixedValue,
            color: fixedValue > 0 ? color : "transparent",
          },
          {
            value: 100 - clamp(fixedValue, 0, 100),
            color: isDarkTheme ? "#313b5c" : "#f7f7f7",
          },
        ],
        color,
      };
    });
  }, [data, isDarkTheme]);

  return (
    <div className={classes.esgIndexPieCharts}>
      <div className={classes.widget}>
        {modifiedData.map(({ label, data, color }, i) => (
          <div
            className={classes.chartArea}
            style={{ gridArea: "pie" + i }}
            key={i}
          >
            <div className={classes.chartWrapperOuter}>
              <div className={classes.chartWrapperInner}>
                <PieChart
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  data={data}
                  lineWidth={20}
                  label={({ dataEntry, dataIndex }) =>
                    dataIndex === 0 ? dataEntry.value : ""
                  }
                  labelPosition={0}
                  labelStyle={{
                    fill: color,
                    fontSize: 20,
                  }}
                  startAngle={-90}
                />
              </div>
              <div className={classes.label}>{label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EsgIndexPieCharts;
