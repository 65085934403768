import { FC, useContext, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./PresentationLastPage.module.scss";
import context from "src/context";
import { withTooltip } from "src/hocs";
import { Image } from "src/components";
import { selectDashboardsLimits } from "src/store/selectors";
import presentationBackground from "src/images/presentationBackground.png";

// Inner imports
import { AddToDashboardButton } from "./components/AddToDashboardButton";

const AddToDashboardButtonWithTooltip = withTooltip(AddToDashboardButton);

const PresentationLastPage: FC = () => {
  const { t } = useTranslation();

  const { setActionBarProps } = useContext(context);

  useEffect(() => {
    setActionBarProps({});
  }, [setActionBarProps]);

  const isAuthenticated = useSelector(({ user }: Store.State) => !!user.id);

  const { dashboardsLimit, isDashboardsLimitReached } = useSelector(
    selectDashboardsLimits,
  );

  const params = useParams() as { dashboardId: string };

  const isDashboardCanBeAdded = useMemo(() => !isDashboardsLimitReached, [
    isDashboardsLimitReached,
  ]);

  const { dashboardId } = useParams<{ dashboardId: string }>();

  const dashboardIdWithoutParams = useMemo(
    () => dashboardId.split("?")[0] || "",
    [dashboardId],
  );

  const links = useMemo(() => {
    if (isAuthenticated) {
      return (
        <AddToDashboardButtonWithTooltip
          isDashboardCanBeAdded={isDashboardCanBeAdded}
          className={styles.redLink}
          dashboardId={dashboardIdWithoutParams}
          tooltip={t("presentation_last_page_dashboards_limit", {
            count: dashboardsLimit,
          })}
          isTooltipActive={!isDashboardCanBeAdded}
        />
      );
    }

    return (
      <>
        <Link className={styles.transparentLink} to={"/login"}>
          {t("presentation_sign_in_btn")}
        </Link>
        <Link
          className={styles.redLink}
          to={{
            pathname: "/signup",
            state: { sharedDashboardId: params.dashboardId || "" },
          }}
        >
          {t("presentation_sign_up_btn")}
        </Link>
      </>
    );
  }, [
    dashboardIdWithoutParams,
    isDashboardCanBeAdded,
    params.dashboardId,
    dashboardsLimit,
    isAuthenticated,
    t,
  ]);

  const description = useMemo(() => {
    return isAuthenticated
      ? t("presentation_last_page_regestred_text")
      : t("presentation_last_page_not_regestred_text");
  }, [isAuthenticated, t]);

  return (
    <div
      className={styles.lastPresentationScreen}
      style={{ backgroundImage: `url(${presentationBackground})` }}
    >
      <div className={styles.content}>
        <div className={styles.textsAndButtons}>
          <h2 className={styles.dashboardName}>
            {t("presentation_last_page_title")}
          </h2>
          <p className={styles.description}>{description}</p>
          <div className={styles.buttons}>
            {links}
            <div className={styles.goBackLink}>
              <span className={styles.separator}>or</span>
              <Link
                to={{
                  pathname: `/presentation/${dashboardId}`,
                  state: {
                    dashboardId,
                  },
                }}
              >
                {t("presentation_review_btn")}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <Image className={styles.image} name="presentationImage" />
        </div>
      </div>
    </div>
  );
};

export { PresentationLastPage };
