import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./KeyBusinessDriversPreview.module.scss";
import { Preloader, ErrorPlaceholder } from "../../../components";

// Inner imports
import { KeyBusinessDriversChart } from "./KeyBusinessDriversChart";
import { onErrorHandler } from "../../helpers";
import { WIDGET_COLLECTION_ID } from "./constants";
import { useWidgetFetching } from "../../hooks";

export const KeyBusinessDriversPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(
    searchId,
    WIDGET_COLLECTION_ID,
  );

  const keyBusinessDrivers = useMemo(() => {
    if (!widgetData) return;
    return widgetData[0]?.brandData.quadrantChart;
  }, [widgetData]);

  const chartData = (
    keyBusinessDrivers?.oneYear ||
    keyBusinessDrivers?.sixMonths ||
    keyBusinessDrivers?.threeMonths ||
    keyBusinessDrivers?.twoMonths ||
    keyBusinessDrivers?.oneMonth
  )?.data;

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.keyBusinessDriversPreview}>
      {chartData ? (
        <>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            <KeyBusinessDriversChart
              className={styles.chart}
              data={chartData}
            />
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(
              setChartRef,
              searchId,
              WIDGET_COLLECTION_ID,
              dispatch,
            )
          }
        />
      )}
    </div>
  );
};
