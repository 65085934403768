import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./ActionBarSearchBar.module.scss";
import { Dandruff } from "../../../../icons";
import { ROUTS } from "../../../../data/routs";
import { useSearchingData } from "../../../../hooks/useSearchingData";
import { SearchBar } from "../../../../components";
import { useOutsideClickHandler, useWindowWidth } from "../../../../hooks";

type Element = { value: string; label: string };

export const ActionBarSearchBar: FC<ActionBar.Props["searchBar"]> = memo(
  ({ type, placeholder, options }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const ref = useRef(null);
    const [isSearchBarShow, setTsSearchBarShow] = useState<boolean>(false);

    const [data, setElement, deleteElement] = useSearchingData(type);

    const windowWidth = useWindowWidth();
    const isMobileView = useMemo(() => {
      setTsSearchBarShow(false);
      return windowWidth <= 991;
    }, [windowWidth]);

    const onViewResultsHandler = useCallback(
      (element: Element) => {
        const link = getRedirectLink(type, element);
        history.push(link);
        setElement(element);
        setTsSearchBarShow(false);
      },
      [history, type, setElement],
    );

    const redirectToHomePage = useCallback((): void => {
      switch (type) {
        case "events":
          return history.push(ROUTS.eventsHome);
        case "dashboards":
          return history.push(ROUTS.dashboardsHomePage);
        case "trackers":
          return history.push(ROUTS.trackersHomePage);
        default:
          return;
      }
    }, [type, history]);

    const searchBarOptions = useMemo(
      () =>
        options.map((option) => ({
          ...option,
          onClickHandler: onViewResultsHandler,
        })),
      [options, onViewResultsHandler],
    );

    const timePlotterSearchingData = useMemo(
      () =>
        data.map((element) => ({
          ...element,
          onClickHandler: onViewResultsHandler,
        })),
      [data, onViewResultsHandler],
    );

    const isCanShowSearchBar = useMemo(() => {
      if (!isMobileView) return true;

      return isSearchBarShow;
    }, [isMobileView, isSearchBarShow]);

    function getRedirectLink(
      type: ActionBar.SearchBar.DataType,
      element: Element,
    ) {
      let route = "";
      const queryParam = element.value === element.label ? `name` : "id";

      switch (type) {
        case "events":
          route = ROUTS.eventsSearch;
          break;
        case "dashboards":
          route = ROUTS.dashboardsSearch;
          break;
        case "trackers":
          route = ROUTS.trackersSearch;
          break;
        default:
          break;
      }

      return `${route}?${queryParam}=${element.value}`;
    }

    useOutsideClickHandler(ref, () => setTsSearchBarShow(false));

    return (
      <div className={styles.actionBarWrapper} ref={ref}>
        <button
          className={styles.iconWrapper}
          onClick={() => setTsSearchBarShow((state) => !state)}
        >
          <Dandruff className={styles.dandruffIcon} />
        </button>
        {isCanShowSearchBar && (
          <SearchBar
            className={styles.actionBar}
            placeholder={t(placeholder || "")}
            options={searchBarOptions}
            history={timePlotterSearchingData}
            onDeleteItemFromHistoryHandler={deleteElement}
            onViewResultsHandler={onViewResultsHandler}
            onInputClean={redirectToHomePage}
          />
        )}
      </div>
    );
  },
);
