import { getSentimentDriversWidget } from "../../../../store/api";

const fetchSentimentDriverData = async (
  searchId: string,
  brands: Store.Brands,
) => {
  try {
    const sentimentDriversWidget = await getSentimentDriversWidget(
      searchId,
      brands,
    );

    return sentimentDriversWidget;
  } catch (err) {
    throw Error(err);
  }
};

export { fetchSentimentDriverData };
