import firebase from "firebase/app";
import * as yup from "yup";
import { showDevelopmentError } from "../../utils";
import { Market } from "./marketsSlice";

const marketSchema = yup
  .object({
    code: yup.string().required(),
    label: yup.string().required(),
  })
  .noUnknown();

export async function getMarkets() {
  const collection = await firebase.firestore().collection("markets").get();

  return collection.docs.reduce<Market[]>((acc, doc) => {
    try {
      const docData = doc.data();
      const { subRegions, enabled, languageCode } = docData;

      const validatedData = marketSchema.validateSync(docData);

      enabled &&
        acc.push({
          ...validatedData,
          subRegions: (subRegions as string[]) || [],
          languageCode,
        });
    } catch (error) {
      showDevelopmentError({
        additionalTexts: ["MARKETS FETCHING OR VALIDATE ERROR"],
        error,
      });
    }

    return acc;
  }, []);
}

export async function getTrackedMarkets() {
  const formattedData: {
    countriesCode: string[];
    entries: {
      [marketCode: string]: {
        isCountryImMW: boolean;
        timesSelected: number;
        label: string;
      };
    };
  } = {
    countriesCode: [],
    entries: {},
  };

  try {
    const docData = await firebase
      .firestore()
      .collection("used-markets")
      .doc("data")
      .get();
    const data = docData.data();

    if (data) {
      const { countriesCode: gotCountriesCode, entities: gotEntities } = data;
      if (gotCountriesCode && Array.isArray(gotCountriesCode)) {
        formattedData["countriesCode"] = gotCountriesCode;
      }
      if (gotEntities && typeof gotEntities === "object") {
        formattedData["entries"] = gotEntities;
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    return formattedData;
  }
}

export async function setTrackedMarkets(market: {
  code: string;
  label: string;
  isCountryInMW: boolean;
}) {
  const { code, label, isCountryInMW } = market;
  const trackedMarkets = await getTrackedMarkets();
  const isMarketHaveWrote = trackedMarkets.entries[code];

  let data = {};

  if (isMarketHaveWrote) {
    data = {
      entities: {
        [code]: {
          timesSelected: isMarketHaveWrote.timesSelected + 1,
        },
      },
    };
  } else {
    data = {
      countriesCode: [...trackedMarkets.countriesCode, code],
      entities: {
        [code]: {
          timesSelected: 1,
          isCountryInMW,
          label,
        },
      },
    };
  }

  try {
    await firebase
      .firestore()
      .collection("used-markets")
      .doc("data")
      .set(data, { merge: true });
  } catch (err) {
    console.error(err);
  }
}
