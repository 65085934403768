import { createClickUpTask } from "./createClickUpTask";

const groupStyles = "color: #f5de4c; font-size: 14px";
const DIVIDER = "------------------------------";
const ASSIGNEES = ["qa@mytelescope.io"];

type Props = {
  additionalTexts?: string[];
  error?: any;
  // If don't pass "clickUpProps", ClickUp task won't created
  clickUpProps?: Parameters<typeof createClickUpTask>[0];
};

export const showDevelopmentError = ({
  additionalTexts = [],
  error,
  clickUpProps,
}: Props) => {
  console.group("%cDEVELOPMENT WARNING", groupStyles);

  if (!!additionalTexts.length) {
    for (const customError of additionalTexts) {
      console.log(`${customError}\n${DIVIDER}`);
    }
  }

  if (error) {
    console.log("%c↓↓↓ ERROR ↓↓↓", "color: red");
    console.log(error);
  }

  if (
    clickUpProps &&
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_ENV === "prod"
  ) {
    createClickUpTask({ ...clickUpProps, assignees: ASSIGNEES });
  }

  console.groupEnd();
};
