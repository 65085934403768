import { GridItem } from "../store/dashboards/dashboardsSlice";

const sortWidgetsOnDashboard = (gridItem: GridItem[]) => {
  return [...gridItem].sort(function (a, b) {
    const n = a.y - b.y;
    if (n !== 0) {
      return n;
    }

    return a.x - b.x;
  });
};

export { sortWidgetsOnDashboard };
