import firestore from "../../firestore";

export const getLocalization = async () => {
  const res = await firestore().collection("localization").doc("eng").get();
  let formattedData: Errors = {};

  if (res.exists) {
    const data = res.data();

    if (typeof data === "object") {
      Object.values(data).forEach((fields) => {
        if (typeof fields === "object") {
          formattedData = { ...formattedData, ...fields };
        }
      });
    }
  }
  return formattedData;
};
