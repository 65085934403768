export const sortArrayByAlphabet = (arr: string[] | undefined) => {
  if (!arr || !arr.length) return [];
  const numberInTheEnd = [...arr].reduce(
    (acc, word) => {
      if (/^\d+/.test(word)) {
        acc.withNumbers.push(word);
      } else {
        acc.withoutNumbers.push(word);
      }

      return acc;
    },
    {
      withNumbers: [],
      withoutNumbers: [],
    } as {
      withNumbers: string[];
      withoutNumbers: string[];
    },
  );
  return [
    ...numberInTheEnd.withoutNumbers.sort(),
    ...numberInTheEnd.withNumbers.sort(),
  ];
};
