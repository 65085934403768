import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import context from "../context";
import { ConfirmRedirectionModal } from "../components";

const BEFOREUNLOAD_FUNC = (evt: BeforeUnloadEvent) => {
  // Cancel the event (if necessary)
  evt.preventDefault();

  // Google Chrome requires returnValue to be set
  evt.returnValue = "";
  return null;
};

const useBlockRedirection = (
  isRedirectionShouldBeBlocked: boolean = false,
  setRedirectionState?: (path: string) => Object,
) => {
  const history = useHistory();

  const { setModalElement } = useContext(context);

  const [redirectionPathName, setRedirectionPathName] = useState<string>("");

  const unblockRedirection = useCallback(() => history.block(() => {}), [
    history,
  ]);

  useEffect(() => {
    // Needed for ask user if reload page if he/she made changes;
    const setEvent = () =>
      window.addEventListener("beforeunload", BEFOREUNLOAD_FUNC, true);
    const cleanEvent = () =>
      window.removeEventListener("beforeunload", BEFOREUNLOAD_FUNC, true);

    isRedirectionShouldBeBlocked ? setEvent() : cleanEvent();

    return () => {
      cleanEvent();
    };
  }, [isRedirectionShouldBeBlocked]);

  useEffect(() => {
    if (isRedirectionShouldBeBlocked) {
      return history.block(({ pathname, hash = "", state: locationState }) => {
        if (pathname === history.location.pathname) return "true";

        setRedirectionPathName(pathname);

        const state = setRedirectionState?.(pathname) || locationState || {};

        setModalElement(
          <ConfirmRedirectionModal path={pathname} hash={hash} state={state} />,
          false,
        );

        return "true";
      });
    } else {
      unblockRedirection();
    }

    return () => {
      unblockRedirection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRedirectionShouldBeBlocked,
    history,
    unblockRedirection,
    setModalElement,
  ]);

  return { redirectionPathName, unblockRedirection };
};

export { useBlockRedirection };
