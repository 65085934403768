import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useCompetitorsPresence } from "src/hooks";
import { isNumber } from "src/utils";
import { selectDashboardViewSettings } from "src/store/selectors";
import { WidgetViewSettings } from "src/store/dashboards/dashboardsSlice";
import { WidgetButtonsBarProps } from "src/components/WidgetButtonsBar/types";

// Inner imports
import { WidgetChart, WidgetView } from "../types";
import { WIDGET_CHART_ICON } from "../widgets";
import { useUpdateDashboardViewSettings } from "./";

type WidgetViewsType = {
  isAvailableForOneBrand: boolean;
  isDefault: boolean;
  order: number;
  type: WIDGET_CHARTS_TYPES;
  subType?: string;
}[];

type UseWidgetView = {
  dashboardId?: string;
  searchId?: string;
  widgetId: WIDGET_IDS_TYPES;
  charts: WidgetChart[];
};

type UseWidgetViewResult = {
  widgetViewsOptions: WidgetButtonsBarProps["options"];
  selectedView?: WidgetView;
  setCurrentWidgetView: (view: WidgetsView.WidgetChartTypeAndSubType) => void;
};

type UseWidgetPreview = UseWidgetView;

type UseWidgetPreviewResult = {
  widgetViews: WidgetView[];
  viewIndex: number;
  setViewIndex: (viewIndex: number) => void;
};

type UseWidgetViewData = Required<UseWidgetView>;

type UseWidgetViewDataResult = {
  widgetAvailableViews?: WidgetViewsType;
  widgetViewSettings?: WidgetViewSettings;
  isCompetitorsPresent?: boolean;
  allWidgetViews: WidgetView[];
  filteredWidgetViews: WidgetView[];
};

type UseWidgetViewIndex = {
  widgetViews: WidgetView[];
  widgetViewSettings?: WidgetViewSettings;
  searchId: string;
  widgetId: WIDGET_IDS_TYPES;
  dashboardId: string;
};

type UseWidgetViewIndexResult = [number, (viewIndex: number) => void];

export const useWidgetView = ({
  charts,
  dashboardId = "",
  searchId = "",
  widgetId,
}: UseWidgetView): UseWidgetViewResult => {
  const { t } = useTranslation();

  const {
    widgetAvailableViews,
    widgetViewSettings,
    isCompetitorsPresent,
    allWidgetViews,
    filteredWidgetViews,
  } = useWidgetViewData({
    charts,
    dashboardId,
    searchId,
    widgetId,
  });

  const updateDashboardViewSettings = useUpdateDashboardViewSettings({
    dashboardId,
    searchId,
    widgetId,
  });

  const [currentWidgetViewId, setCurrentWidgetViewId] = useState<string>("");

  const selectedView: WidgetView | undefined = useMemo(() => {
    const widgetView = filteredWidgetViews.find(
      ({ id }) => id === currentWidgetViewId,
    );

    if (!widgetView) return filteredWidgetViews[0];

    return widgetView;
  }, [currentWidgetViewId, filteredWidgetViews]);

  const widgetViewsOptions: WidgetButtonsBarProps["options"] = useMemo(
    () =>
      allWidgetViews.map((view) => {
        const { id, type, subType, buttonIcon } = view;

        const disableStatus = getDisabledStatus({
          view: { type, subType },
          availableCharts: widgetAvailableViews,
          isCompetitorsPresent,
        });

        const disabledStatusLabel = t(disableStatus);
        const isDisabled = !!disableStatus;

        return {
          id,
          icon: buttonIcon,
          handleClick: (widgetViewId) => {
            updateDashboardViewSettings({ selectedView: { type, subType } });
            setCurrentWidgetViewId(widgetViewId);
          },
          isDisabled,
          buttonNumber: getUniqButtonNumbersToView(view, allWidgetViews),
          ...(isDisabled
            ? {
                withTooltip: {
                  customPosition: { vertical: -20, horizontal: -10 },
                  toolTipContent: disabledStatusLabel,
                },
              }
            : {}),
        };
      }),
    [
      allWidgetViews,
      widgetAvailableViews,
      isCompetitorsPresent,
      t,
      updateDashboardViewSettings,
    ],
  );

  useEffect(() => {
    const isWidgetViewsInitialized = !!currentWidgetViewId;

    if (isWidgetViewsInitialized) return;

    const { type, subType } = widgetViewSettings?.selectedView || {};

    const selectedWidgetViewId = type ? getWidgetChartId(type, subType) : "";

    if (selectedWidgetViewId) {
      setCurrentWidgetViewId(selectedWidgetViewId);
    } else {
      const firstChartView = filteredWidgetViews[0]?.id || "";
      setCurrentWidgetViewId(firstChartView);
    }
  }, [
    filteredWidgetViews,
    currentWidgetViewId,
    widgetViewSettings?.selectedView,
  ]);

  const setCurrentWidgetView = ({
    type,
    subType,
  }: WidgetsView.WidgetChartTypeAndSubType) => {
    const widgetViewId = getWidgetChartId(type, subType);

    updateDashboardViewSettings({ selectedView: { type, subType } });

    setCurrentWidgetViewId(widgetViewId);
  };

  return {
    widgetViewsOptions,
    selectedView,
    setCurrentWidgetView,
  };
};

export const useWidgetPreview = ({
  charts,
  dashboardId = "",
  searchId = "",
  widgetId,
}: UseWidgetPreview): UseWidgetPreviewResult => {
  const { widgetViewSettings, filteredWidgetViews } = useWidgetViewData({
    charts,
    dashboardId,
    widgetId,
    searchId,
  });

  const [viewIndex, setViewIndex] = useWidgetViewIndex({
    widgetViews: filteredWidgetViews,
    widgetViewSettings,
    dashboardId,
    searchId,
    widgetId,
  });

  return {
    widgetViews: filteredWidgetViews,
    viewIndex,
    setViewIndex,
  };
};

function useWidgetViewIndex({
  widgetViews,
  widgetViewSettings,
  dashboardId,
  searchId,
  widgetId,
}: UseWidgetViewIndex): UseWidgetViewIndexResult {
  const [viewIndex, _setViewIndex] = useState<number>(0);

  const updateDashboardViewSettings = useUpdateDashboardViewSettings({
    dashboardId,
    searchId,
    widgetId,
  });

  useEffect(() => {
    if (!widgetViews.length || !widgetViewSettings?.selectedView) return;

    const { type, subType } = widgetViewSettings?.selectedView;

    const selectedWidgetId = getWidgetChartId(type, subType);

    const _selectedChartIndex = widgetViews.findIndex(
      (chart) => chart.id === selectedWidgetId,
    );

    const selectedChartIndex =
      _selectedChartIndex < 0 ? 0 : _selectedChartIndex;

    _setViewIndex(selectedChartIndex);
  }, [widgetViews, widgetViewSettings?.selectedView]);

  const setViewIndex = (viewIndex: number) => {
    const selectedView = widgetViews[viewIndex];

    if (!selectedView) return _setViewIndex(0);

    const { type, subType } = selectedView;

    updateDashboardViewSettings({
      selectedView: {
        type,
        subType,
      },
    });

    _setViewIndex(viewIndex);
  };

  return [viewIndex, setViewIndex];
}

function useWidgetViewData({
  widgetId,
  searchId,
  dashboardId,
  charts,
}: UseWidgetViewData): UseWidgetViewDataResult {
  const widgetAvailableViews = useSelector(({ widgetsData }: RootState):
    | WidgetViewsType
    | undefined => {
    const { views } = widgetsData.entities[widgetId] || {};

    return views;
  });

  const dashboardViewSettings = useSelector(
    selectDashboardViewSettings(dashboardId),
  );

  const widgetViewSettings = useMemo(
    () => dashboardViewSettings?.[searchId]?.[widgetId],
    [dashboardViewSettings, searchId, widgetId],
  );

  const searchesCompetitorsPresence = useCompetitorsPresence({
    searchesIds: [searchId],
    widgetId,
  });

  const isCompetitorsPresent: boolean = useMemo(
    () =>
      searchesCompetitorsPresence[searchId]?.isWidgetHasCompetitors || false,
    [searchId, searchesCompetitorsPresence],
  );

  const allWidgetViews: WidgetView[] = useMemo(() => {
    const formattedCharts = charts.map((chart) => ({
      ...chart,
      id: getWidgetChartId(chart.type, chart.subType),
      buttonIcon: getWidgetChartIcon(chart.type, chart.subType),
      order: getChartOrder(chart.type, chart.subType, widgetAvailableViews),
    }));

    return formattedCharts.sort((a, b) => (a?.order || 0) - (b.order || 0));
  }, [charts, widgetAvailableViews]);

  const filteredWidgetViews: WidgetView[] = useMemo(
    () =>
      filterOutUnavailableViews(
        allWidgetViews,
        widgetAvailableViews,
        isCompetitorsPresent,
      ),
    [allWidgetViews, widgetAvailableViews, isCompetitorsPresent],
  );

  return {
    widgetAvailableViews,
    widgetViewSettings,
    isCompetitorsPresent,
    allWidgetViews,
    filteredWidgetViews,
  };
}

function getWidgetChartId(
  chartType: WIDGET_CHARTS_TYPES,
  chartSubtype?: string,
): string {
  if (chartType && chartSubtype) return `${chartType}_${chartSubtype}`;

  if (chartType) return chartType;

  return "";
}

function getWidgetChartIcon(
  chartType: WIDGET_CHARTS_TYPES,
  chartSubtype: string = "default",
): AppIcon {
  const icon = WIDGET_CHART_ICON[chartType][chartSubtype];

  if (!icon) return WIDGET_CHART_ICON[chartType].default;

  return icon;
}

function getChartOrder(
  chartType: WIDGET_CHARTS_TYPES,
  chartSubtype: string = "",
  availableCharts?: WidgetViewsType,
): number {
  const { order = 0 } =
    availableCharts?.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        chartType === availableChartType &&
        chartSubtype === availableChartSubType,
    ) || {};

  return order;
}

function filterOutUnavailableViews(
  views: WidgetView[],
  availableCharts?: WidgetViewsType,
  isCompetitorsPresent?: boolean,
): WidgetView[] {
  if (!availableCharts) return [];
  const filteredViews: WidgetView[] = [];

  for (const view of views) {
    const { type, subType = "" } = view;

    const widgetView = availableCharts.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        type === availableChartType && subType === availableChartSubType,
    );

    if (
      widgetView &&
      (isCompetitorsPresent || widgetView.isAvailableForOneBrand)
    ) {
      filteredViews.push({
        ...view,
        order: widgetView.order,
        buttonNumber: getUniqButtonNumbersToView(view, views),
      });
    }
  }

  return filteredViews;
}

function getUniqButtonNumbersToView(
  view: WidgetView,
  views: WidgetView[],
): number | undefined {
  const { buttonIcon, buttonNumber, id } = view;

  if (isNumber(buttonNumber)) return buttonNumber;

  const viewsWithSameButtonIcon = views.filter(
    (item) => item.buttonIcon === buttonIcon,
  );

  if (viewsWithSameButtonIcon.length < 2) return;

  const newButtonNumber = viewsWithSameButtonIcon.findIndex(
    (item) => item.id === id,
  );

  if (newButtonNumber < 0) return;

  return newButtonNumber + 1;
}

function getDisabledStatus({
  view: { type, subType = "" },
  availableCharts,
  isCompetitorsPresent,
}: {
  view: WidgetsView.WidgetChartTypeAndSubType;
  availableCharts?: WidgetViewsType;
  isCompetitorsPresent?: boolean;
}) {
  const chart = availableCharts?.find(
    ({ type: availableChartType, subType: availableChartSubType = "" }) =>
      type === availableChartType && subType === availableChartSubType,
  );

  if (!chart) return "widget_chart_disabled";

  if (!chart.isAvailableForOneBrand && !isCompetitorsPresent)
    return "widget_competitors_required";

  return "";
}
