import { isNumber } from "./isNumber";

export const getDifferencePercentage = (
  startValue: number,
  endValue: number,
  fractionDigits: number = 0,
): number => {
  let difference: number;

  switch (true) {
    case startValue === 0 && endValue > 0:
      difference = 1;
      break;
    case startValue === 0 && endValue < 0:
      difference = -1;
      break;
    case endValue !== 0 && startValue !== 0:
      difference = (endValue - startValue) / Math.abs(startValue);
      break;
    default:
      difference = 0;
  }

  const differenceInPercentage = Number(
    (difference * 100).toFixed(fractionDigits),
  );

  if (!isNumber(differenceInPercentage)) return 0;

  return differenceInPercentage;
};
