import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { showToastNotification } from "src/components/ToastNotification/utils";

// Inner imports
import type { ChartLabel, ChartItem } from "../types";
import { CATEGORY_TREND_LINE } from "../constants";
import { getUniqColor } from "../helpers";

type UseLabels = {
  data: ChartItem[];
  initialLabels?: readonly ChartLabel[];
  isHasTrendLine: boolean;
};

type UseLabelsResult = {
  labels: ChartLabel[];
  inactiveLabels: string[];
  onLabelClickHandle: (name: string) => void;
};

export const useLabels = ({
  initialLabels = [],
  data,
  isHasTrendLine,
}: UseLabels): UseLabelsResult => {
  const { t } = useTranslation();

  const [inactiveLabels, setInactiveLabels] = useState<string[]>([]);

  const dataLabels = useMemo<Set<string>>(() => {
    const dataLabels = new Set<string>([]);

    for (const { values } of data) {
      for (const brandName in values) {
        if (dataLabels.has(brandName)) continue;

        dataLabels.add(brandName);
      }
    }

    return dataLabels;
  }, [data]);

  const { labels, brandNames } = useMemo<{
    labels: ChartLabel[];
    brandNames: string[];
  }>(() => {
    const usedColors = new Set<string>();
    const labelsMap = new Map<string, Omit<ChartLabel, "name">>();
    const brandsSet = new Set<string>();

    if (isHasTrendLine) {
      usedColors.add(CATEGORY_TREND_LINE.color);
      labelsMap.set(CATEGORY_TREND_LINE.label, {
        color: CATEGORY_TREND_LINE.color,
        priority: 0,
      });
    }

    if (initialLabels.length) {
      for (const { name, color: _color } of initialLabels) {
        const color = _color || getUniqColor(usedColors);

        usedColors.add(color);
        labelsMap.set(name, {
          color,
          priority: labelsMap.size + 1,
        });
        brandsSet.add(name);
      }
    }

    // We have to add all missed brands that we paste from "initialLabels" if we have it;
    const isInitialLabelDifferent =
      dataLabels.size !== initialLabels.length ||
      initialLabels.some(({ name }) => !dataLabels.has(name));

    if (isInitialLabelDifferent) {
      dataLabels.forEach((brandName) => {
        const color = getUniqColor(usedColors);

        const isLabelAlreadyAdded = labelsMap.has(brandName);

        if (!isLabelAlreadyAdded) {
          usedColors.add(color);
          labelsMap.set(brandName, {
            color,
            priority: labelsMap.size + 1,
          });
          brandsSet.add(brandName);
        }
      });
    }

    return {
      labels: Array.from(labelsMap, ([brandName, data]) => ({
        ...data,
        name: brandName,
      })).sort(
        ({ priority: aPriority = -1 }, { priority: bPriority = -1 }) =>
          aPriority - bPriority,
      ),
      brandNames: Array.from(brandsSet),
    };
  }, [isHasTrendLine, initialLabels, dataLabels]);

  const onLabelClickHandle = (name: string) => {
    setInactiveLabels((state) => {
      const isNamePresent = state.includes(name);

      const updatedInactiveLabels = isNamePresent
        ? state.filter((x) => x !== name)
        : [...state, name];

      const isEveryBrandUnselected = brandNames.every((name) =>
        updatedInactiveLabels.includes(name),
      );

      if (isEveryBrandUnselected) {
        showToastNotification({
          id: "line-chart-unselect-warning",
          type: "info",
          text: t("line_chart_brands_selection_warning"),
        });

        return state;
      }

      return updatedInactiveLabels;
    });
  };

  return { labels, inactiveLabels, onLabelClickHandle };
};
