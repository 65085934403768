import { forwardRef, useMemo, useState, memo } from "react";
import { TagCloud } from "react-tagcloud";
import cx from "classnames";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";

import styles from "./SentimentDriversTagCloud.module.scss";
import { capitalizeString, getAverage } from "../../../utils";
import {
  PositiveFaceIcon,
  NegativeFaceIcon,
  NeutralFaceIcon,
} from "../../../icons";

// Inner imports
import {
  CHART_COLORS,
  ICON_WIDTH,
  NEEDED_ADDITIONAL_SPACE_FOR_POPPER,
} from "./constants";
import { Tag, Props } from "./types";

export const SentimentDriversTagCloud = memo(
  forwardRef<HTMLElement, Props>(
    (
      {
        color,
        tags,
        excludedTags,
        type,
        className,
        shouldShowRelatedWords = false,
        viewType = "preview",
      }: Props,
      ref,
    ) => {
      const { t } = useTranslation();

      const {
        ref: chartWrapperRef,
        width: chartWrapperWidth = 0,
        height: chartWrapperHeight = 0,
      } = useResizeObserver<HTMLDivElement>();

      const gotRef = ref as { current?: HTMLElement } | undefined;
      const parentPosition = gotRef?.current?.getBoundingClientRect();

      const relatedWordsColor: string = useMemo(() => {
        if (color) return color;
        const { POSITIVE, NEUTRAL, NEGATIVE, DEFAULT } = CHART_COLORS;
        switch (type) {
          case "positive":
            return POSITIVE;
          case "neutral":
            return NEUTRAL;
          case "negative":
            return NEGATIVE;
          default:
            return DEFAULT;
        }
      }, [color, type]);

      const ICON = useMemo(() => {
        switch (type) {
          case "positive":
            return <PositiveFaceIcon width={ICON_WIDTH} />;
          case "neutral":
            return <NeutralFaceIcon width={ICON_WIDTH} />;
          case "negative":
            return <NegativeFaceIcon width={ICON_WIDTH} />;
          default:
            return null;
        }
      }, [type]);

      const size = useMemo(
        () =>
          viewType === "preview"
            ? Math.min(chartWrapperHeight, chartWrapperWidth)
            : chartWrapperWidth,
        [chartWrapperHeight, chartWrapperWidth, viewType],
      );

      const averageOfTagsCount = useMemo(() => {
        const allTagsCountValue = tags.map(({ count }) => count);

        return getAverage(allTagsCountValue);
      }, [tags]);

      const filteredTags = useMemo(
        () =>
          tags.filter(
            ({ value }) =>
              !excludedTags.some(
                (excludedTag) =>
                  excludedTag.toLowerCase() === value.toLowerCase(),
              ),
          ),
        [excludedTags, tags],
      );

      const CustomRenderer = ({ value, count }: Tag) => {
        const [isOpened, setIsOpened] = useState<boolean>(false);
        const [overflowingParent, setOverflowingParent] = useState<boolean>(
          false,
        );

        const [
          relatedVerticalWordsWrapperPosition,
          setRelatedVerticalWordsWrapperPosition,
        ] = useState<"top" | "bottom">("bottom");

        const [
          relatedHorizontalWordsWrapperPosition,
          setRelatedHorizontalWordsWrapperPosition,
        ] = useState<"left" | "right" | "center">("center");

        const {
          ref: wordsWrapperRef,
          height: wordsWrapperHeight = 0,
        } = useResizeObserver<HTMLDivElement>();

        const { relatedWords } =
          tags.find(({ value: word }) => word === value) || {};

        const valueText = capitalizeString(value);

        const parentData = parentPosition
          ? {
              top: parentPosition.y,
              bottom: parentPosition.y + parentPosition.height,
              left: parentPosition.x,
              right: parentPosition.x + parentPosition.width,
            }
          : {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            };

        const checkIfOverflowing = (element: HTMLElement | null) => {
          if (!element || !parentPosition) return;

          const position = element.getBoundingClientRect();

          const childrenData = {
            top: position.y,
            bottom: position.y + position.height,
            left: position.x,
            right: position.x + position.width,
          };

          const bottomIsOk = parentData.bottom > childrenData.bottom + 20;

          if (!bottomIsOk) {
            setOverflowingParent(true);
          }
        };

        const setHorizontalAndVerticalPosition = (
          element: HTMLElement | null,
        ) => {
          if (!element || !parentPosition) return;

          const getPopperHorizontalPosition = (
            parentLeft: number,
            parentRight: number,
            childrenLeft: number,
            childrenRight: number,
          ): "left" | "right" | "center" | undefined => {
            const isLeftSideSuits =
              parentLeft + NEEDED_ADDITIONAL_SPACE_FOR_POPPER < childrenLeft;
            const isRightSideSuits =
              childrenRight + NEEDED_ADDITIONAL_SPACE_FOR_POPPER < parentRight;

            if (!isLeftSideSuits) return "right";

            if (!isRightSideSuits) return "left";

            return "center";
          };

          const position = element.getBoundingClientRect();

          const childrenData = {
            top: position.y,
            bottom: position.y + position.height,
            left: position.x,
            right: position.x + position.width,
          };

          const windowHeight = window.innerHeight || 0;

          const verticalPosition =
            windowHeight < childrenData.bottom + wordsWrapperHeight
              ? "top"
              : "bottom";

          const horizontalPosition = getPopperHorizontalPosition(
            parentData.left,
            parentData.right,
            childrenData.left,
            childrenData.right,
          );

          setRelatedVerticalWordsWrapperPosition(verticalPosition);
          setRelatedHorizontalWordsWrapperPosition(
            horizontalPosition || "center",
          );
        };

        const isRelatedWordsShouldShow = relatedWords && isOpened;

        const standardTextSize = 16;
        const percentage = count / averageOfTagsCount;
        const initialFontSize = standardTextSize * percentage * (size / 150);

        const maxFontSize = size / 7;
        const minFontSize = size / 15;

        let fontSize = 16;

        if (initialFontSize > maxFontSize) {
          fontSize = maxFontSize;
        } else if (initialFontSize < minFontSize) {
          fontSize = minFontSize;
        } else {
          fontSize = initialFontSize;
        }

        return (
          <span
            key={value}
            className={cx(
              styles.tag,
              styles[shouldShowRelatedWords ? "widthRelatedWords" : ""],
            )}
            style={{
              color: relatedWordsColor,
              ...(viewType === "preview"
                ? { opacity: overflowingParent ? "0" : "1" }
                : {}),
            }}
            onMouseEnter={() => shouldShowRelatedWords && setIsOpened(true)}
            onMouseLeave={() => shouldShowRelatedWords && setIsOpened(false)}
            ref={(element) => {
              checkIfOverflowing(element);
              setHorizontalAndVerticalPosition(element);
            }}
          >
            <span
              className={styles.value}
              style={{
                fontSize: `${fontSize}px`,
              }}
            >
              {valueText}
            </span>

            {isRelatedWordsShouldShow && (
              <div
                className={cx(
                  styles.relatedWordsWrapper,
                  styles[relatedVerticalWordsWrapperPosition],
                  styles[relatedHorizontalWordsWrapperPosition],
                )}
                ref={wordsWrapperRef}
              >
                <div className={styles.value} title={valueText}>
                  {ICON}
                  <span>{valueText}</span>
                </div>
                <div className={styles.tip}>
                  {t("w_sd_widget_word_associated_tag")}
                </div>
                <ul className={styles.relatedWords}>
                  {relatedWords!.map((relatedWord) => (
                    <li
                      key={`${type}_${relatedWord}`}
                      className={styles.relatedWord}
                      title={capitalizeString(relatedWord)}
                    >
                      {capitalizeString(relatedWord)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </span>
        );
      };

      return (
        <div>
          {viewType === "page" && (
            <div className={styles.signature}>
              {t("w_sd_signature", { type: type })}
            </div>
          )}
          <div ref={chartWrapperRef} className={cx(styles.wrapper, className)}>
            <TagCloud
              tags={filteredTags}
              // ----> this two properties doesn't influence of anything,
              // because we render words by using customRenderer, but TypeScript requires them
              minSize={-Infinity}
              maxSize={Infinity}
              // <----
              renderer={(tag: Tag) => (
                <CustomRenderer key={tag.value} {...tag} />
              )}
              className={styles.tagCloud}
              shuffle={false}
            />
          </div>
        </div>
      );
    },
  ),
);
