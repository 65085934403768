import { readDashboards } from "../store/dashboards/dashboardsApi";
import { sendDataToGTM } from ".";

import type { SubscriptionPlan } from "../store/subscriptionPlans/subscriptionPlansSlice";

type Payload = {
  user: Store.User;
  company: Store.Company;
  searches: Store.Searches;
  subscriptionPlan: SubscriptionPlan;
};

export const sendDataAboutUserToGTM = async (payload: Payload) => {
  const dataAboutUser = await formatDataAboutUser(payload);

  sendDataToGTM("SetUserData", dataAboutUser);
};

async function formatDataAboutUser({
  user,
  company,
  searches,
  subscriptionPlan,
}: Payload) {
  const data = {
    userId: "",
    userName: "",
    userEmail: "",
    createdAt: "",
    companyName: "Not set yet",
    companyId: "",
    workFor: "",
    primaryFocus: "",
    subscriptionPlan: "trial",
    isUserHasSearch: "Can not get",
    dashboardQuantity: 0,
    searchesQuantity: 0,
    siteVersion: "new",
  };

  const {
    id: userId,
    firstName: userName,
    email: userEmail,
    userCreatedAt,
  } = user;

  const { id: companyId, name: companyName, workFor, primaryFocus } = company;

  const { name: subscriptionName } = subscriptionPlan;

  const userSearchesQuantity = getUserSearchesQuantity(searches, user.id);
  const isUserHasSearch = userSearchesQuantity > 0;

  const dashboardQuantity = await getUserDashboardsQuantity(companyId);

  const _primaryFocus = formatPrimaryFocus(primaryFocus);

  // Set fields values ---->
  userId && (data["userId"] = userId);
  userName && (data["userName"] = userName);
  userEmail && (data["userEmail"] = userEmail);
  userCreatedAt && (data["createdAt"] = userCreatedAt);
  companyName && (data["companyName"] = companyName);
  companyId && (data["companyId"] = companyId);
  workFor && (data["workFor"] = workFor);
  _primaryFocus && (data["primaryFocus"] = _primaryFocus);

  subscriptionName && (data["subscriptionPlan"] = subscriptionName);
  data["isUserHasSearch"] = isUserHasSearch
    ? "user has search"
    : "user has not search";
  dashboardQuantity && (data["dashboardQuantity"] = dashboardQuantity);
  userSearchesQuantity && (data["searchesQuantity"] = userSearchesQuantity);
  // <---- Set fields values

  return data;
}

function formatPrimaryFocus(primaryFocus?: string[]) {
  if (!primaryFocus || !Boolean(primaryFocus.length)) return "";

  return primaryFocus.join(", ");
}

function getUserSearchesQuantity(searches: Store.Searches, userId: string) {
  const searchesAreOwnedByTheUser = searches.filter(({ owner }) => {
    const { id: ownerId } = owner;

    return ownerId === userId;
  });

  return searchesAreOwnedByTheUser.length;
}

async function getUserDashboardsQuantity(companyId: string) {
  const companyDashboards = await readDashboards(companyId);

  return companyDashboards.length;
}
