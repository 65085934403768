import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setCustomWidgetSettings } from "../store/dashboardsCustomData/actions";
import * as api from "../store/dashboardsCustomData/api";

// TODO: Return this property from context after implementation of presentation page
const isPresentation = false;

export const useCustomWidgetText = (
  searchId: string = "",
  dashboardId: string,
  widgetSlug: string,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAllNeededDataExist = useMemo(() => {
    return !!searchId && !!dashboardId && !!widgetSlug;
  }, [dashboardId, searchId, widgetSlug]);

  const customWidgetSettings = useSelector(
    ({ dashboardsCustomData }: Store.State) =>
      dashboardsCustomData?.[dashboardId]?.[widgetSlug]?.[searchId],
  );

  const saveCustomWidgetSettings = useCallback(
    async (newCustomWidgetSettings: { [setting: string]: string }) => {
      // Store the current custom widget text
      const oldCustomWidgetText = customWidgetSettings || {};
      // Optimistically set the new custom widget text in the store
      dispatch(
        setCustomWidgetSettings({
          dashboardId,
          searchId,
          widgetId: widgetSlug,
          settings: newCustomWidgetSettings,
        }),
      );
      // Save the new custom widget text
      const success = await api.saveCustomWidgetSettings(
        dashboardId,
        searchId,
        widgetSlug,
        newCustomWidgetSettings,
      );
      // If we fail to store the new custom widget text, set it back to the old one
      if (!success) {
        dispatch(
          setCustomWidgetSettings({
            dashboardId,
            searchId,
            widgetId: widgetSlug,
            settings: oldCustomWidgetText,
          }),
        );
      }
    },
    [customWidgetSettings, dashboardId, dispatch, searchId, widgetSlug],
  );

  // Disable editing if we fail to get dashboardId or widgetId
  if (!isAllNeededDataExist) {
    console.error(t("widget_title_fetch_fail_message"));
    return {
      customWidgetText: "",
      saveCustomWidgetSettings: () => console.error("failed"),
      editingEnabled: false,
    };
  }

  return {
    customWidgetSettings,
    saveCustomWidgetSettings,
    editingEnabled: !isPresentation && isAllNeededDataExist,
  };
};
