import firebase from "firebase/app";
import TagManager from "react-gtm-module";
import { SubscriptionPlan } from "./subscriptionPlansSlice";

import axios from "src/axios";

// Inner imports
import { DEFAULT_MAX_NUMBER_OF_COMPETITORS } from "../../containers/Home/Searches/constants";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

export async function getSubscriptionPlan(subscriptionPlanId: string) {
  const doc = await firebase
    .firestore()
    .collection("subscription-plans")
    .doc(subscriptionPlanId)
    .get();

  const data = doc.data();

  if (!data) throw new Error("Subscription plan doesn't exist");

  const {
    name,
    prices,
    pricesBilledAnually,
    searchesLimit,
    dashboardsLimit,
    marketsLimit,
    teamLimit,
    companyAdminsLimit,
    additionalNamesLimit,
    availableWidgetsMap,
    visibleBlocks,
    textFields,
    mainDataSource,
    competitorLimit,
    hideUnavailableWidgets,
    socialgistSourcesMap,
    updateFrequencyInWeeks,
  } = data;

  if (
    typeof name === "string" &&
    typeof prices?.gbp === "number" &&
    typeof prices?.sek === "number" &&
    typeof pricesBilledAnually?.gbp === "number" &&
    typeof pricesBilledAnually?.sek === "number" &&
    typeof searchesLimit === "number" &&
    typeof dashboardsLimit === "number" &&
    typeof marketsLimit === "number" &&
    typeof teamLimit === "number" &&
    typeof companyAdminsLimit === "number" &&
    typeof updateFrequencyInWeeks === "number" &&
    typeof availableWidgetsMap === "object" &&
    !Array.isArray(availableWidgetsMap) &&
    Array.isArray(visibleBlocks)
  ) {
    return {
      id: doc.id,
      name,
      prices: {
        gbp: prices.gbp,
        sek: prices.sek,
      },
      pricesBilledAnually: {
        gbp: pricesBilledAnually.gbp,
        sek: pricesBilledAnually.sek,
      },
      searchesLimit,
      dashboardsLimit,
      marketsLimit,
      additionalNamesLimit: additionalNamesLimit ?? 3,
      competitorLimit: competitorLimit ?? DEFAULT_MAX_NUMBER_OF_COMPETITORS,
      company: { membersLimit: teamLimit, adminsLimit: companyAdminsLimit },
      availableWidgets: availableWidgetsMap,
      visibleBlocks: visibleBlocks.filter((x) => typeof x === "number"),
      textFields: textFields || {},
      mainDataSource,
      hideUnavailableWidgets,
      socialgistSourcesMap,
      updateFrequencyInWeeks,
    };
  } else {
    throw new Error("Subscription plan isn't valid");
  }
}

export async function getSubscriptionPlans() {
  const collection = await firebase
    .firestore()
    .collection("subscription-plans")
    .where("isSelectable", "==", true)
    .get();

  return collection.docs.reduce<SubscriptionPlan[]>((acc, doc) => {
    const {
      name,
      prices,
      pricesBilledAnually,
      searchesLimit,
      dashboardsLimit,
      marketsLimit,
      teamLimit,
      companyAdminsLimit,
      additionalNamesLimit,
      availableWidgetsMap,
      visibleBlocks,
      textFields,
      competitorLimit,
      updateFrequencyInWeeks,
    } = doc.data();

    if (
      typeof name === "string" &&
      typeof prices?.gbp === "number" &&
      typeof prices?.sek === "number" &&
      typeof pricesBilledAnually?.gbp === "number" &&
      typeof pricesBilledAnually?.sek === "number" &&
      typeof searchesLimit === "number" &&
      typeof dashboardsLimit === "number" &&
      typeof marketsLimit === "number" &&
      typeof teamLimit === "number" &&
      typeof companyAdminsLimit === "number" &&
      typeof updateFrequencyInWeeks === "number" &&
      typeof availableWidgetsMap === "object" &&
      !Array.isArray(availableWidgetsMap) &&
      Array.isArray(visibleBlocks)
    ) {
      acc.push({
        id: doc.id,
        name,
        prices: {
          gbp: prices.gbp,
          sek: prices.sek,
        },
        pricesBilledAnually: {
          gbp: pricesBilledAnually.gbp,
          sek: pricesBilledAnually.sek,
        },
        searchesLimit,
        dashboardsLimit,
        marketsLimit,
        additionalNamesLimit: additionalNamesLimit ?? 3,
        company: { membersLimit: teamLimit, adminsLimit: companyAdminsLimit },
        availableWidgets: availableWidgetsMap,
        visibleBlocks: visibleBlocks.filter((x) => typeof x === "number"),
        textFields: textFields || {},
        competitorLimit: competitorLimit ?? DEFAULT_MAX_NUMBER_OF_COMPETITORS,
        updateFrequencyInWeeks,
      });
    }

    return acc;
  }, []);
}

export const sendUpgradeSubPlanEmail = (
  userId: Store.User["id"],
  subscriptionPlanId: SubscriptionPlan["id"],
): Promise<void> =>
  axios.post(`email-service-${urlInsertion}-api/upgrade-request-email`, {
    userId,
    subscriptionPlanId,
  });

export const watchSubscriptionPlan = (companyId: string) => {
  const db = firebase.firestore();
  const docRef = db.collection("companies").doc(companyId);

  return docRef.onSnapshot(async (snap: any) => {
    const { subscriptionPlan } = snap.data() || {};

    if (subscriptionPlan) {
      const { name } = await getSubscriptionPlan(subscriptionPlan);
      TagManager.dataLayer({
        dataLayer: {
          event: "UserSubscriptionPlan",
          subscriptionPlan: name || "can't get",
        },
      });
    }
  });
};
