import { memo, useState, useMemo, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./WidgetsSidebar.module.scss";
import * as icons from "src/icons";
import context from "src/context";
import { formatToPartialDate, isTouchDevice } from "src/utils";
import { useCompetitorsPresence, useOutsideClickHandler } from "src/hooks";
import { withTooltip } from "src/hocs";
import {
  PreReleaseComponent,
  Select,
  UnblockWidgetModal,
} from "src/components";
import {
  WIDGETS_COMPONENTS,
  HIDE_WIDGETS_IF_NOT_ALLOWED_IN_PLAN,
} from "src/widgets/widgets";
import {
  selectUserDashboardPermissions,
  selectCompanySubscriptionPlan,
  selectAvailableDashboardById,
  selectAvailableWidgetsIds,
} from "src/store/selectors";
import {
  selectAvailableWidgetsIdsForOneBrandSearch,
  WidgetData,
} from "src/store/widgetsData/widgetsDataSlice";

// Inner imports
import {
  WidgetsSidebarProps,
  WidgetSidebarTile,
  WidgetSidebarTilesSection,
  AccumulatedSidebarTiles,
} from "./types";
import { OPEN_BUTTON_ID } from "./constants";
import { onAddWidgetToDashboard } from "../../../helpers";

const { Lock, WarningTriangle } = icons;

const LockWithTooltip = withTooltip(Lock);
const WarningTriangleWithTooltip = withTooltip(WarningTriangle);

export const WidgetsSidebar = memo(
  ({
    dashboardId,
    isSideBarOpened,
    setIsSideBarOpened,
    dashboardContentRef,
  }: WidgetsSidebarProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const ref = useRef<HTMLDivElement>(null);

    const { modalElement, setModalElement } = useContext(context);

    const widgets = useSelector(({ widgetsData }: RootState) => widgetsData);
    const _dashboard = useSelector(selectAvailableDashboardById(dashboardId));
    const availableWidgets = useSelector(selectAvailableWidgetsIds);
    const { canUserEditDashboard } = useSelector(
      selectUserDashboardPermissions(dashboardId),
    );

    const widgetsAvailableForOneBrand: string[] = useSelector(
      selectAvailableWidgetsIdsForOneBrandSearch,
    );

    const dashboard = useMemo(() => {
      if (!_dashboard) return;

      const { createdAt, isOwnedByUser, userFullName } = _dashboard;

      return {
        ..._dashboard,
        createdAt: formatToPartialDate(createdAt),
        userFullName: isOwnedByUser ? t("d_if_you_owner") : userFullName,
      };
    }, [_dashboard, t]);

    useOutsideClickHandler(ref, (e) => {
      try {
        const clickTarget = e.target as HTMLElement;
        const closestButtonId = clickTarget.closest("button")?.id;

        if (closestButtonId === OPEN_BUTTON_ID || modalElement) return;
      } catch (err) {
        console.error(err);
      }

      setIsSideBarOpened(false);
    });

    const { hideUnavailableWidgets = false } =
      useSelector(selectCompanySubscriptionPlan) || {};

    const searchOptions = useSelector(({ searches }: Store.State) =>
      searches.map(({ id = "", name = "" }) => ({ value: id, label: name })),
    );

    const [searchId, setSearchId] = useState(searchOptions[0]?.value || "");

    useEffect(() => {
      try {
        const { lastUsedSearchId = "", tiles = {} } = dashboard || {};
        const initialSearchId = lastUsedSearchId || Object.keys(tiles)[0] || "";

        setSearchId(initialSearchId);
      } catch (err) {
        console.warn(err);
      }
    }, [dashboard]);

    const searchesCompetitorsPresence = useCompetitorsPresence({
      searchesIds: [searchId || ""],
    });

    const isCompetitorsPresent: boolean | undefined = useMemo(
      () => searchesCompetitorsPresence[searchId || ""]?.isCompetitorsPresent,
      [searchId, searchesCompetitorsPresence],
    );

    const widgetsEntities = useMemo(
      () =>
        Object.values(widgets.entities).reduce((acc, item) => {
          if (item) {
            acc.push(item);
          }
          return acc;
        }, [] as WidgetData[]),
      [widgets.entities],
    );

    const tiles: WidgetSidebarTile[] = useMemo(() => {
      if (!(dashboard && searchId)) return [];

      const availableWidgetIds = new Set(availableWidgets ?? []);

      const filteredWidgetsIds = widgetsEntities.filter(({ id: widgetId }) => {
        const widgetNotAllowedIfNotInPlan = HIDE_WIDGETS_IF_NOT_ALLOWED_IN_PLAN.some(
          (hiddenWidgetId) => hiddenWidgetId === widgetId,
        );
        if (widgetNotAllowedIfNotInPlan) return false;
        return widgetId;
      });

      const widgets = filteredWidgetsIds
        .filter(({ id }) => !dashboard.tiles[searchId]?.[id])
        .map(({ id, placeHolderOnUi }) => {
          let icon;
          icon = WIDGETS_COMPONENTS[id]?.icon;

          if (icon) {
            const Icon = icons[icon];
            icon = <Icon className={styles.widgetIcon} />;
          } else {
            icon = null;
          }

          return {
            widgetId: id,
            title: t(placeHolderOnUi) ?? "",
            icon,
            isAvailable: availableWidgetIds.has(id),
            isCompetitorRequired:
              !isCompetitorsPresent &&
              !widgetsAvailableForOneBrand.includes(id),
          };
        });

      if (hideUnavailableWidgets) {
        return widgets.filter(({ isAvailable }) => isAvailable);
      }

      return widgets;
    }, [
      availableWidgets,
      dashboard,
      hideUnavailableWidgets,
      isCompetitorsPresent,
      searchId,
      widgetsEntities,
      widgetsAvailableForOneBrand,
      t,
    ]);

    const {
      available,
      requireCompetitor,
      locked,
    }: AccumulatedSidebarTiles = useMemo(
      () =>
        tiles.reduce(
          (acc, tile) => {
            const { isAvailable, isCompetitorRequired } = tile;

            switch (true) {
              case isAvailable && !isCompetitorRequired:
                acc.available.tiles.push(tile);
                break;
              case isAvailable && isCompetitorRequired:
                acc.requireCompetitor.tiles.push(tile);
                break;
              case !isAvailable:
                acc.locked.tiles.push(tile);
                break;
              default:
                return acc;
            }

            return acc;
          },
          {
            available: {
              title: t("d_sidebar_title_available"),
              tiles: [],
            },
            requireCompetitor: {
              title: t("d_sidebar_title_require_competitor"),
              tiles: [],
            },
            locked: {
              title: t("d_sidebar_title_locked"),
              tiles: [],
            },
          } as AccumulatedSidebarTiles,
        ),
      [t, tiles],
    );

    const onAddWidgetClick = (widgetId: WIDGET_IDS_TYPES) => {
      const callback = () => {
        const lastAddedWidgetTileToDashboard = document.getElementById(
          "last-added-widget-tile",
        );

        if (lastAddedWidgetTileToDashboard)
          lastAddedWidgetTileToDashboard.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
      };

      return onAddWidgetToDashboard({
        dispatch,
        t,
        contentRef: dashboardContentRef,
        dashboard,
        widgetId,
        searchId,
        addedFrom: "sidebar",
        callback,
      });
    };

    const renderTiles = (
      {
        widgetId,
        icon: widgetIcon,
        title,
        isAvailable: isAvailableInSubPlan,
        isCompetitorRequired,
      }: WidgetSidebarTile,
      i: number,
    ) => {
      const isUnlocked = isAvailableInSubPlan && !isCompetitorRequired;
      const isDraggable = isUnlocked && !isTouchDevice;

      return (
        <div
          className={styles.tile}
          draggable={isDraggable}
          onDragStart={(e) => {
            if (!isDraggable) e.preventDefault();

            if (searchId) {
              e.dataTransfer.setData("searchId", searchId);
              e.dataTransfer.setData("widgetId", widgetId);
              e.dataTransfer.setData("droppedFrom", "sidebar");
            }
          }}
          key={i}
          style={{
            cursor: isUnlocked ? "pointer" : "default",
          }}
        >
          <div className={styles.sideBarPreReleaseWrapper}>
            <PreReleaseComponent widgetId={widgetId} viewType="compact" />
          </div>
          <div
            className={styles.tileContent}
            style={{
              opacity: isUnlocked ? 1 : 0.25,
            }}
          >
            {widgetIcon}
            <div className={styles.widgetTitle} title={t(title)}>
              {t(title)}
            </div>
          </div>
          {isUnlocked &&
            (isTouchDevice ? (
              <icons.PlusInCircleOutline
                className={styles.addWidgetIcon}
                onClick={() => onAddWidgetClick(widgetId)}
              />
            ) : (
              <icons.Drag className={styles.dragIcon} />
            ))}
          {isAvailableInSubPlan && isCompetitorRequired && (
            <div className={styles.lockBar}>
              <WarningTriangleWithTooltip
                tooltip={t("widget_competitors_required")}
                tooltipPosition="right"
                isTooltipActive
              />
            </div>
          )}
          {!isAvailableInSubPlan && (
            <div
              className={styles.lockBar}
              onClick={() => setModalElement(<UnblockWidgetModal />, false)}
            >
              <LockWithTooltip
                tooltip={t("d_sidebar_available_on_upgrade")}
                tooltipPosition="right"
                isTooltipActive
              />
            </div>
          )}
        </div>
      );
    };

    const renderTilesSections = (
      tilesSections: WidgetSidebarTilesSection[],
    ) => {
      const isAtLeastOneTilePresent = tilesSections.some(
        (section) => !!section.tiles.length,
      );

      if (!isAtLeastOneTilePresent) {
        return (
          <div className={styles.tip}>
            <icons.EmptySelectionIcon />
            {t("d_sidebar_all_allowed_widgets_added")}
          </div>
        );
      }

      return tilesSections.map(
        ({ title, tiles }) =>
          !!tiles.length && (
            <div className={styles.tilesSection} key={title}>
              <p className={styles.tilesSectionTitle}>{title}</p>
              {tiles.map(renderTiles)}
            </div>
          ),
      );
    };

    if (!canUserEditDashboard) return null;

    return (
      <div
        ref={ref}
        className={cx(styles.widgetsSidebar, {
          [styles.widgetsSidebarOpen!]: isSideBarOpened,
        })}
      >
        <div className={styles.dropDown}>
          {isSideBarOpened && (
            <div className={styles.content}>
              <div className={styles.selectWrapper}>
                <Select
                  value={searchId}
                  changeHandler={setSearchId}
                  options={searchOptions}
                />
              </div>
              <div className={styles.tiles}>
                {renderTilesSections([available, requireCompetitor, locked])}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
