const DEFAULT_NUMBER_OF_COMPETITORS = 0 as const;
const DEFAULT_MAX_NUMBER_OF_COMPETITORS = 4 as const;
const MAX_NUMBER_OF_COMPETITORS = 9 as const;
const OTHER_INDUSTRY_ID = "Dp6E7KRcGr7AJODCznKC";

const SEARCHES_TABS = {
  recentSearches: "recent_tab",
  savedSearches: "all_tab",
} as const;

const SEARCHES_DEFAULT_TAB = "savedSearches" as const;

export {
  DEFAULT_NUMBER_OF_COMPETITORS,
  DEFAULT_MAX_NUMBER_OF_COMPETITORS,
  MAX_NUMBER_OF_COMPETITORS,
  OTHER_INDUSTRY_ID,
  SEARCHES_TABS,
  SEARCHES_DEFAULT_TAB,
};
