const ELEMENTS_PER_PAGE = 10 as const;

const INPUT_MAX_LENGTH = 60 as const;

const MAGNIFIER_ICON_SIZE = 20 as const;

const BREAK_LABEL = "..." as const;

const CHECKBOX_CHECKED_COLOR = "#41b167" as const;

const BLOCKED_CHECKBOX_COLOR = "#6C6D75" as const;

export {
  ELEMENTS_PER_PAGE,
  INPUT_MAX_LENGTH,
  MAGNIFIER_ICON_SIZE,
  BREAK_LABEL,
  CHECKBOX_CHECKED_COLOR,
  BLOCKED_CHECKBOX_COLOR,
};
