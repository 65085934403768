// Inner imports
import { RowDataType } from "./types";

function getAllKeyWordsRecursion(children: RowDataType[], node: Set<string>) {
  for (const child of children) {
    const isChildHasChildren = !!child?.children?.length;

    if (isChildHasChildren) {
      getAllKeyWordsRecursion(child.children!, node);
    } else {
      node.add(child.value);
    }
  }
}

function debounce(this: Function, callBack: () => void, delay: number) {
  this.prototype["time"] = new Date().getTime();

  const timeOut = setTimeout(() => {
    const timeDifference = new Date().getTime() - this.prototype["time"];
    if (timeDifference > delay - 50) {
      callBack();
    } else {
      clearTimeout(timeOut);
    }
  }, delay);
}

export { getAllKeyWordsRecursion, debounce };
