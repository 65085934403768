import * as yup from "yup";

export const keywordToolSettingsSchema = yup
  .object({
    _updatedAt: yup.string().default(""),
  })
  .noUnknown();

export type KeywordToolSettingsType = yup.InferType<
  typeof keywordToolSettingsSchema
>;
