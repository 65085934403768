import { Trans } from "react-i18next";

import { WhatDoesItMean as WhatDoesItMeanType } from "../components/AboutThis/types";
import reputationParameters from "../data/reputationParameters";
import esgParameters from "../data/esgParameters";
import businessDriversParameters from "../data/businessDriversParameters";

import * as previewComponents from "./components/widgetsPreviewTile";
import * as pageComponents from "./components/widgetsPageTile";
import * as timePlotterComponents from "./components/widgetsTimePlotterTile";

export const WIDGET_IDS = [
  "result-search-share",
  "result-volume-share",
  "result-attitude",
  "result-awareness",
  "result-business-drivers",
  "result-esg-index",
  "result-esg-sentiment",
  "result-esg-distribution",
  "result-funnel",
  "result-key-areas",
  "result-media-share",
  "result-position-vs-competition",
  "result-reputation-index",
  "result-reputation-sentiment",
  "result-reputation-distribution",
  "result-robustness-score",
  "result-search-trends",
  "result-share-of-search-funnel",
  "result-sentiment-drivers",
  "result-sentiment-index",
  "result-sentiment-trends",
  "result-top-trend-keywords",
  "result-associated-words",
  // ----> NOT ACTIVE
  // "result-competitiveness",
  // "result-search-funnel",
] as const;

export const WIDGET_CHARTS = [
  "lineChart",
  "pieChart",
  "quadrantChart",
  "barChart",
  "cellChart",
  "radarChart",
  "funnel",
  "speedometer",
  "keywords",
  "sentimentDrivers",
  "table",
] as const;

export const WIDGETS_COMPONENTS: WidgetsView.WidgetsStoreObject = {
  "result-search-share": {
    icon: "ShareOfSearch",
    previewComponent: previewComponents["ShareOfSearchPreview"],
    pageComponent: pageComponents["ShareOfSearch"],
    timePlotterComponent: timePlotterComponents["ShareOfSearchSummary"],
  },
  "result-volume-share": {
    icon: "ShareOfVolume",
    previewComponent: previewComponents["ShareOfVolumePreview"],
    pageComponent: pageComponents["ShareOfVolume"],
    timePlotterComponent: timePlotterComponents["ShareOfVolumeSummary"],
  },
  "result-attitude": {
    icon: "Attitude",
    previewComponent: previewComponents["AttitudePreview"],
    pageComponent: pageComponents["Attitude"],
    timePlotterComponent: timePlotterComponents["AttitudeSummary"],
  },
  "result-awareness": {
    icon: "Awareness",
    previewComponent: previewComponents["AwarenessPreview"],
    pageComponent: pageComponents["Awareness"],
  },
  "result-business-drivers": {
    icon: "BusinessDrivers",
    previewComponent: previewComponents["BusinessDriversPreview"],
    pageComponent: pageComponents["BusinessDrivers"],
    timePlotterComponent: timePlotterComponents["BusinessDriversSummary"],
  },
  "result-esg-index": {
    icon: "esgTrend",
    previewComponent: previewComponents["EsgIndexPreview"],
    pageComponent: pageComponents["EsgIndex"],
    timePlotterComponent: timePlotterComponents["EsgIndexSummary"],
  },
  "result-esg-sentiment": {
    icon: "esgSentiment",
    previewComponent: previewComponents["EsgSentimentPreview"],
    pageComponent: pageComponents["EsgSentiment"],
    timePlotterComponent: timePlotterComponents["EsgSentimentSummary"],
  },
  "result-esg-distribution": {
    icon: "esgDistribution",
    previewComponent: previewComponents["EsgDistributionPreview"],
    pageComponent: pageComponents["EsgDistribution"],
  },
  "result-funnel": {
    icon: "Funnel",
    previewComponent: previewComponents["FunnelPreview"],
    pageComponent: pageComponents["Funnel"],
  },
  "result-key-areas": {
    icon: "KeyBusinessDrivers",
    previewComponent: previewComponents["KeyBusinessDriversPreview"],
    pageComponent: pageComponents["KeyBusinessDrivers"],
  },
  "result-media-share": {
    icon: "ShareOfEarnedMedia",
    previewComponent: previewComponents["ShareOfEarnedMediaPreview"],
    pageComponent: pageComponents["ShareOfEarnedMedia"],
    timePlotterComponent: timePlotterComponents["ShareOfEarnedMediaSummary"],
  },
  "result-position-vs-competition": {
    icon: "BusinessDriverPositioning",
    previewComponent: previewComponents["BusinessDriverPositioningPreview"],
    pageComponent: pageComponents["BusinessDriverPositioning"],
  },
  "result-reputation-index": {
    icon: "DigitalReputationTrend",
    previewComponent: previewComponents["ReputationIndexPreview"],
    pageComponent: pageComponents["ReputationIndex"],
    timePlotterComponent: timePlotterComponents["ReputationIndexSummary"],
  },
  "result-reputation-sentiment": {
    icon: "DigitalReputationSentiment",
    previewComponent: previewComponents["ReputationSentimentPreview"],
    pageComponent: pageComponents["ReputationSentiment"],
    timePlotterComponent: timePlotterComponents["ReputationSentimentSummary"],
  },
  "result-reputation-distribution": {
    icon: "DigitalReputationDistribution",
    previewComponent: previewComponents["ReputationDistributionPreview"],
    pageComponent: pageComponents["ReputationDistribution"],
  },
  "result-robustness-score": {
    icon: "DataRobustnessScore",
    previewComponent: previewComponents["RobustnessScorePreview"],
    pageComponent: pageComponents["Robustness"],
  },
  "result-share-of-search-funnel": {
    icon: "ShareOfSearchFunnel",
    previewComponent: previewComponents["ShareOfSearchFunnelPreview"],
    pageComponent: pageComponents["ShareOfSearchFunnel"],
  },
  "result-search-trends": {
    icon: "SearchTrends",
    previewComponent: previewComponents["SearchTrendPreview"],
    pageComponent: pageComponents["SearchTrend"],
    timePlotterComponent: timePlotterComponents["SearchTrendsSummary"],
  },
  "result-sentiment-drivers": {
    icon: "Mentions",
    previewComponent: previewComponents["SentimentDriversPreview"],
    pageComponent: pageComponents["SentimentDrivers"],
  },
  "result-sentiment-index": {
    icon: "SentimentIndex",
    previewComponent: previewComponents["SentimentIndexPreview"],
    pageComponent: pageComponents["SentimentIndex"],
    timePlotterComponent: timePlotterComponents["SentimentIndexSummary"],
  },
  "result-sentiment-trends": {
    icon: "Competitiveness",
    previewComponent: previewComponents["SentimentTrendsPreview"],
    pageComponent: pageComponents["SentimentTrends"],
    timePlotterComponent: timePlotterComponents["SentimentTrendsSummary"],
  },
  "result-top-trend-keywords": {
    icon: "TrendingWordsWidget",
    previewComponent: previewComponents["TopKeywordsPreview"],
    pageComponent: pageComponents["TopKeywords"],
  },
  "result-associated-words": {
    icon: "AssociatedWords",
    previewComponent: previewComponents["AssociatedWordsPreview"],
    pageComponent: pageComponents["AssociatedWords"],
  },
};

const ICON_PLACEHOLDER: AppIcon = "Pie";

export const WIDGET_CHART_ICON: {
  [chart in WIDGET_CHARTS_TYPES]: {
    [subType: string]: AppIcon;
    default: AppIcon;
  };
} = {
  lineChart: {
    default: "Timeline",
  },
  pieChart: {
    default: "Pie",
  },
  quadrantChart: {
    default: ICON_PLACEHOLDER,
  },
  barChart: {
    default: "Bar",
  },
  cellChart: {
    default: ICON_PLACEHOLDER,
  },
  radarChart: {
    default: ICON_PLACEHOLDER,
  },
  table: {
    default: "List",
  },
  speedometer: {
    default: "Marketometer",
  },
  sentimentDrivers: {
    all: "AllFacesIcon",
    positive: "PositiveFaceIcon",
    neutral: "NeutralFaceIcon",
    negative: "NegativeFaceIcon",
    default: "AllFacesIcon",
  },
  funnel: {
    horizontal: "FunnelHorizontal",
    vertical: "FunnelVertical",
    default: "FunnelVertical",
  },
  keywords: {
    topWords: "TopWords",
    trendingWords: "TrendingWords",
    default: "TopWords",
  },
};

export const HIDE_WIDGETS_IF_NOT_ALLOWED_IN_PLAN = ["result-awareness"];

// export const AUTO_GENERATE_WIDGETS_LIST = [
//   "result-search-share",
//   "result-volume-share",
//   "result-top-trend-keywords",
//   "result-search-trends",
// ];

export const WIDGETS_TITLES: {
  [widgetId in WIDGET_IDS_TYPES]: {
    secondaryTitle: {
      [type: string]: string;
      default: string;
    };
    aboutTheMetric: {
      [type: string]: string | JSX.Element;
      default: string | JSX.Element;
    };
    whatDoesItMean?: WhatDoesItMeanType;
  };
} = {
  "result-search-share": {
    secondaryTitle: {
      default: "w_share_of_search_secondary_title",
    },
    aboutTheMetric: {
      default: "w_share_of_search_bottom_tip",
    },
  },
  "result-volume-share": {
    secondaryTitle: {
      default: "w_search_volume_secondary_title",
    },
    aboutTheMetric: {
      default: "w_search_volume_bottom_tip",
    },
  },
  "result-attitude": {
    secondaryTitle: {
      default: "w_attitude_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "w_attitude_bottom_tip",
    },
  },
  "result-awareness": {
    secondaryTitle: {
      default: "w_awareness_secondary_title",
    },
    aboutTheMetric: {
      default: "w_awareness_bottom_tip",
    },
  },
  "result-business-drivers": {
    secondaryTitle: {
      default: "w_interest_drivers_secondary_title",
    },
    aboutTheMetric: {
      default: "w_interest_drivers_about_this_text",
    },
    whatDoesItMean: businessDriversParameters,
  },
  "result-esg-index": {
    secondaryTitle: {
      default: "w_esg_t_secondary_title",
    },
    aboutTheMetric: {
      default: "w_esg_t_about_this_text",
    },
    whatDoesItMean: esgParameters,
  },
  "result-esg-sentiment": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_esg_s_about_this_text",
    },
    whatDoesItMean: esgParameters,
  },
  "result-esg-distribution": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_esg_d_about_this_text",
    },
    whatDoesItMean: esgParameters,
  },
  "result-funnel": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_funnel_bottom_tip",
    },
  },
  "result-key-areas": {
    secondaryTitle: {
      default: "w_kid_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "w_kid_bottom_tip",
    },
  },
  "result-media-share": {
    secondaryTitle: {
      default: "w_som_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "w_som_bottom_tip",
    },
  },
  "result-position-vs-competition": {
    secondaryTitle: {
      default: "w_idc_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "w_idc_bottom_tip",
    },
  },
  "result-reputation-index": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_drt_about_this_text",
    },
    whatDoesItMean: reputationParameters,
  },
  "result-reputation-sentiment": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_drs_about_this_text",
    },
    whatDoesItMean: reputationParameters,
  },
  "result-reputation-distribution": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_drd_about_this_text",
    },
    whatDoesItMean: reputationParameters,
  },
  "result-robustness-score": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_dd_main_tip",
    },
  },
  "result-share-of-search-funnel": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "w_sos_funnel_bottom_tip",
    },
  },
  "result-search-trends": {
    secondaryTitle: {
      default: "w_st_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "",
    },
  },
  "result-sentiment-drivers": {
    secondaryTitle: {
      default: "w_sd_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "w_sd_main_tip",
    },
  },
  "result-sentiment-index": {
    secondaryTitle: {
      default: "w_se_widget_secondary_title",
    },
    aboutTheMetric: {
      default: "",
    },
  },
  "result-sentiment-trends": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: "",
      speedometer: "marketometer_about_this_text",
    },
  },
  "result-top-trend-keywords": {
    secondaryTitle: {
      default: "",
    },
    aboutTheMetric: {
      default: (
        <Trans i18nKey="w_top_keywords_about_this_text" components={[<br />]} />
      ),
    },
  },
  "result-associated-words": {
    secondaryTitle: {
      default: "w_tpw_secondary_title",
    },
    aboutTheMetric: {
      default: "w_tpw_bottom_tip",
    },
  },
};

export const AUTO_ADDED_WIDGETS_IF_COMPETITORS_PRESENT = [
  "result-search-share",
];
