import { GOLD, BLUE, VIOLET, CYAN, PINK, GREEN } from "./colors";

export default [
  {
    name: "w_interest_labels_product_title",
    key: "prd",
    description: "w_interest_labels_product_text",
    color: GOLD,
  },
  {
    name: "w_interest_labels_price_title",
    key: "prc",
    description: "w_interest_labels_price_text",
    color: BLUE,
  },
  {
    name: "w_interest_labels_service_title",
    key: "ppl",
    description: "w_interest_labels_service_text",
    color: VIOLET,
  }, // earlier People
  {
    name: "w_interest_labels_preference_title",
    key: "prf",
    description: "w_interest_labels_preference_text",
    color: CYAN,
  },
  {
    name: "w_interest_labels_offer_title",
    key: "prp",
    description: "w_interest_labels_offer_text",
    color: PINK,
  }, // earlier Proposal
  {
    name: "w_interest_labels_communication_title",
    key: "prm",
    description: "w_interest_labels_communication_text",
    color: GREEN,
  }, // earlier Promotion
];
