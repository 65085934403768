import { FC, useState, useMemo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./KeyBusinessDrivers.module.scss";
import {
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
  ErrorPlaceholder,
} from "../../../components";
import DATE_RANGE_LABELS from "../../../data/dateRangeLabels";
import { getKeyAreasDescriptionInsertion } from "../../../utils";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { KeyBusinessDriversChart } from "./KeyBusinessDriversChart";
import { WidgetChart } from "../../types";
import { WIDGET_ID, DATE_RANGES, WIDGET_COLLECTION_ID } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const KeyBusinessDrivers: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(
    searchId,
    WIDGET_COLLECTION_ID,
  );

  const quadrantChart = widgetData?.[0]?.brandData.quadrantChart;

  // ----> Date ranges
  const dateRanges = useMemo(() => {
    return DATE_RANGES.filter((dateRange) => quadrantChart?.[dateRange]);
  }, [quadrantChart]);

  const [currentDateRange, setCurrentDateRange] = useState<
    typeof DATE_RANGES[number]
  >("oneYear");

  useEffect(() => {
    const dateRange = dateRanges[dateRanges.length - 1];
    dateRange && setCurrentDateRange(dateRange);
  }, [dateRanges]);

  const dateRangeOptions = dateRanges.map((dateRange) => ({
    value: dateRange,
    label: t(DATE_RANGE_LABELS[dateRange]),
  }));
  // <---- Date ranges

  const chartData = quadrantChart?.[currentDateRange!]?.data;

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (chartData) {
      charts.push({
        type: "quadrantChart",
        chart: (
          <KeyBusinessDriversChart
            className={styles.keyBusinessDriversChart}
            data={chartData}
          />
        ),
      });
    }

    return charts;
  }, [chartData]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const descriptionInsertion = getKeyAreasDescriptionInsertion(chartData);

  const conclusion = useMemo(() => {
    const mainBrandName = widgetData?.[0]?.brandName;
    return descriptionInsertion
      ? t("w_kid_main_tip", {
          descriptionInsertion: t(descriptionInsertion),
          brandName: mainBrandName,
        })
      : "";
  }, [widgetData, descriptionInsertion, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          timePeriodSelector={{
            periods: dateRangeOptions,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                viewElement
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
