import { RefObject } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { Layout } from "react-grid-layout";
import { cloneDeep, isEqual, set } from "lodash";

import { sendDataToGTM } from "../../../../utils";
import {
  Dashboard,
  updateOneDashboard,
} from "../../../../store/dashboards/dashboardsSlice";
import { showToastNotification } from "../../../../components/ToastNotification/utils";
import { calculateWidgetLayoutPosition } from "../../../../utils/calculateWidgetLayoutPosition";

type CommonProps = {
  contentRef: RefObject<HTMLDivElement>;
  dispatch: AppDispatch;
  t: TFunction;
  dashboard?: Dashboard;
};

type OnAddWidgetToDashboardProps = CommonProps & {
  searchId: string;
  widgetId: WIDGET_IDS_TYPES;
  addedFrom: string;
  callback?: () => void;
};

type OnDropWidgetToDashboardProps = CommonProps & {
  layout: Layout[];
  layoutItem: Layout;
  event: DragEvent;
};

type OnDashboardLayoutChangeProps = CommonProps & {
  layout: Layout[];
  oldLayoutItem: Layout;
  newLayoutItem: Layout;
};

const getBreakpoint = (contentRef: RefObject<HTMLDivElement>) => {
  const contentWidth = contentRef.current?.clientWidth;

  if (!contentWidth) return "small";

  if (contentWidth >= 1200) {
    return "large";
  } else if (contentWidth >= 528) {
    return "medium";
  } else {
    return "small";
  }
};

export const onAddWidgetToDashboard = async ({
  searchId,
  widgetId,
  addedFrom,
  contentRef,
  dispatch,
  t,
  dashboard,
  callback,
}: OnAddWidgetToDashboardProps) => {
  try {
    const breakpoint: LayoutSize = getBreakpoint(contentRef);

    if (!dashboard) throw new Error(t("d_drag_error_no_dashboard"));
    if (!breakpoint) throw new Error(t("d_drag_error_can_not_get_breakpoint"));

    const tiles = cloneDeep(dashboard.tiles);
    set(tiles, [searchId, widgetId], true);

    const updatedLayouts = Object.entries({ ...dashboard.layouts }).reduce(
      (acc, [layoutSize, layout]) => {
        const newWidgetItem = calculateWidgetLayoutPosition({
          widgetId,
          searchId,
          layoutSize: layoutSize as LayoutSize,
          layout,
        });

        return { ...acc, [layoutSize]: [...layout, newWidgetItem] };
      },
      { ...dashboard.layouts },
    );

    const result = await dispatch(
      updateOneDashboard({
        id: dashboard.id,
        changes: {
          tiles,
          lastUsedSearchId: searchId,
          layouts: updatedLayouts,
        },
      }),
    );

    unwrapResult(result);

    if (addedFrom === "sidebar")
      sendDataToGTM("UserAddsNewWidget", {
        isWidgetAddedFromSidebar: true,
      });

    callback?.();
  } catch (error) {
    console.error(error);
    showToastNotification({
      type: "error",
      text: t("request_error"),
    });
  }
};

export const onDropWidgetToDashboard = async ({
  layout: _layout,
  layoutItem,
  event,
  contentRef,
  dispatch,
  t,
  dashboard,
}: OnDropWidgetToDashboardProps) => {
  try {
    const { dataTransfer } = event || {};

    const searchId = dataTransfer?.getData("searchId");
    const widgetId = dataTransfer?.getData("widgetId");
    const addedFrom = dataTransfer?.getData("addedFrom");

    const breakpoint: LayoutSize = getBreakpoint(contentRef);

    if (!dashboard) throw new Error(t("d_drag_error_no_dashboard"));
    if (!searchId) throw new Error(t("d_drag_error_searchId_missing"));
    if (!widgetId) throw new Error(t("d_drag_error_widgetId_missing"));
    if (!breakpoint) throw new Error(t("d_drag_error_can_not_get_breakpoint"));

    const tiles = cloneDeep(dashboard.tiles);
    set(tiles, [searchId, widgetId], true);

    const updatedLayouts = Object.entries({ ...dashboard.layouts }).reduce(
      (acc, [layoutSize, layout]) => {
        if (breakpoint === layoutSize) {
          const newWidgetItem = {
            ...layoutItem,
            i: searchId + widgetId,
          };

          const parsedLayout = JSON.parse(
            JSON.stringify([...cloneDeep(_layout.slice(0, -1)), newWidgetItem]),
          );

          return {
            ...acc,
            [layoutSize]: parsedLayout,
          };
        }

        const newWidgetItem = calculateWidgetLayoutPosition({
          widgetId,
          searchId,
          layoutSize: layoutSize as LayoutSize,
          layout,
        });

        return { ...acc, [layoutSize]: [...layout, newWidgetItem] };
      },
      { ...dashboard.layouts },
    );

    const result = await dispatch(
      updateOneDashboard({
        id: dashboard.id,
        changes: {
          tiles,
          lastUsedSearchId: searchId,
          layouts: updatedLayouts,
        },
      }),
    );

    unwrapResult(result);

    if (addedFrom === "sidebar")
      sendDataToGTM("UserAddsNewWidget", {
        isWidgetAddedFromSidebar: true,
      });
  } catch (error) {
    console.error(error);
    showToastNotification({
      type: "error",
      text: t("request_error"),
    });
  }
};

export const onDashboardLayoutChange = async ({
  layout,
  oldLayoutItem,
  newLayoutItem,
  contentRef,
  dispatch,
  t,
  dashboard,
}: OnDashboardLayoutChangeProps) => {
  if (isEqual(oldLayoutItem, newLayoutItem)) return;

  try {
    const breakpoint = getBreakpoint(contentRef);

    if (!dashboard) throw new Error(t("d_drag_error_no_dashboard"));
    if (!breakpoint) throw new Error(t("d_drag_error_can_not_get_breakpoint"));

    const updatedLayouts = {
      ...dashboard.layouts,
      [breakpoint]: JSON.parse(JSON.stringify(layout)),
    };

    const result = await dispatch(
      updateOneDashboard({
        id: dashboard.id,
        changes: {
          layouts: updatedLayouts,
        },
      }),
    );

    unwrapResult(result);
  } catch (error) {
    console.error(error);
    showToastNotification({
      type: "error",
      text: t("request_error"),
    });
  }
};
