type GridItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

type Template = {
  [widgetId: string]: {
    x: number;
    y: number;
    w: number;
  };
};

const CELLS_PER_ROW = {
  lg: 4,
  md: 2,
  sm: 1,
};

const WIDGET_WIDTH = {
  lg: 2,
  md: 1,
  sm: 1,
};

const generateTemplateForScreen = (
  size: "lg" | "md" | "sm",
  widgetList: string[],
): Template => {
  const widgetWidth = WIDGET_WIDTH[size] || 1;
  const cellsPerRow = CELLS_PER_ROW[size] || 1;
  const widgetsPerRow = Math.floor(cellsPerRow / widgetWidth) || 1;

  return widgetList.reduce((template, widgetId, index) => {
    const xPosition =
      cellsPerRow === 1
        ? index * widgetWidth
        : (index * widgetWidth) % cellsPerRow;

    const yPosition = Math.floor(index / widgetsPerRow);

    return {
      ...template,
      [widgetId]: {
        y: yPosition,
        x: xPosition,
        w: widgetWidth,
      },
    };
  }, {});
};

export const createDashboardGrid = (
  searchId: string,
  widgetList: string[],
  layoutSize: "large" | "medium" | "small",
): GridItem[] => {
  return widgetList.reduce((acc, widgetId, i) => {
    const isOdd = i % 2 === 1;

    const standardValues = {
      i: searchId + widgetId,
      h: 1,
    };

    const setGridItem = (
      Template: Template,
      id: string,
      defaultValue: GridItem,
    ) => {
      const isWidgetHavePosition: boolean = widgetList.some(
        (autoGenerateWidgetId: string) => autoGenerateWidgetId === id,
      );
      if (isWidgetHavePosition) {
        return {
          ...standardValues,
          y: Template[id]?.y || 0,
          x: Template[id]?.x || 0,
          w: Template[id]?.w || 1,
        };
      }
      return defaultValue;
    };

    const defaultValueForSmallLayout: GridItem = {
      ...standardValues,
      x: 0,
      y: i,
      w: 1,
    };

    const defaultValueForMediumLayout: GridItem = {
      ...standardValues,
      x: isOdd ? 0 : 1,
      y: Math.floor(i / 2),
      w: 1,
    };

    const defaultValueForLargeLayout: GridItem = {
      ...standardValues,
      x: isOdd ? 0 : 2,
      y: Math.floor(i / 2),
      w: 2,
    };

    const gridItemForSmallLayout = setGridItem(
      generateTemplateForScreen("sm", widgetList),
      widgetId,
      defaultValueForSmallLayout,
    );

    const gridItemForMediumLayout = setGridItem(
      generateTemplateForScreen("md", widgetList),
      widgetId,
      defaultValueForMediumLayout,
    );

    const gridItemForLargeLayout = setGridItem(
      generateTemplateForScreen("lg", widgetList),
      widgetId,
      defaultValueForLargeLayout,
    );

    switch (layoutSize) {
      case "small":
        acc.push(gridItemForSmallLayout);
        break;
      case "medium":
        acc.push(gridItemForMediumLayout);
        break;
      case "large":
        acc.push(gridItemForLargeLayout);
        break;
    }

    return acc;
  }, [] as GridItem[]);
};
