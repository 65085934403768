import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectCompanySubscriptionPlan } from "../subscriptionPlans/selectors";
import { selectUserStatus } from "../user/selectors";

export const selectCompany = createDraftSafeSelector(
  ({ company }: RootState) => company,
  (company) => company,
);

export const selectCompanyMembersCount = createDraftSafeSelector(
  selectCompany,
  ({ members }) => {
    const membersCount = members.length;

    const adminsCount = members.filter(
      ({ isAdmin, isOwner }) => isAdmin && !isOwner,
    ).length;

    return {
      membersCount,
      adminsCount,
    };
  },
);

export const selectCompanyMembersLimits = createDraftSafeSelector(
  selectCompanySubscriptionPlan,
  selectCompanyMembersCount,
  (subscriptionPlan, { membersCount, adminsCount }) => {
    const { adminsLimit = 0, membersLimit = 0 } =
      subscriptionPlan?.company || {};

    const [isMembersLimitReached, isAdminsLimitReached] = [
      membersCount >= membersLimit,
      adminsCount >= adminsLimit,
    ];

    return {
      membersLimit,
      adminsLimit,
      isMembersLimitReached,
      isAdminsLimitReached,
    };
  },
);

export const selectUserCompanyPermissions = createDraftSafeSelector(
  selectUserStatus,
  ({ isUserCompanyOwnerOrAdmin }) => {
    const canUserEditCompany = isUserCompanyOwnerOrAdmin;

    return {
      canUserEditCompany,
    };
  },
);
