import { FC, useContext, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./DashboardSyncModal.module.scss";
import context from "src/context";
import { showDevelopmentError } from "src/utils";
import { ConfirmModalWrapper } from "src/components";
import { startNewSync } from "src/store/syncs/syncsApi";
import {
  selectUserId,
  selectCompanySubscriptionPlanDataSource,
  selectDashboardSearches,
} from "src/store/selectors";
import { showToastNotification } from "src/components/ToastNotification/utils";

type Props = {
  dashboardId: string;
};

export const DashboardSyncModal: FC<Props> = ({ dashboardId }) => {
  const { t } = useTranslation();

  const { setModalElement, setIsGlobalPreloaderShown } = useContext(context);

  const dataSource = useSelector(selectCompanySubscriptionPlanDataSource);
  const dashboardSearches = useSelector(selectDashboardSearches(dashboardId));
  const userId = useSelector(selectUserId);

  const SearchNames: JSX.Element = useMemo(
    () => (
      <ul className={styles.searchesList}>
        {dashboardSearches.map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ul>
    ),
    [dashboardSearches],
  );

  const onCancelClick = () => setModalElement(null);

  const onAcceptClick = async () => {
    try {
      setIsGlobalPreloaderShown(true);

      const promises = dashboardSearches.map(({ id: searchId }) =>
        startNewSync({ searchId, userId, mainDataSource: dataSource }),
      );

      await Promise.all(promises);

      showToastNotification({
        type: "success",
        text: t("d_sync_modal_update_requested"),
      });
    } catch (error) {
      showDevelopmentError({
        additionalTexts: ["NEW SYNCS ERROR"],
        error,
      });

      showToastNotification({
        type: "error",
        text: t("request_error"),
      });
    } finally {
      setIsGlobalPreloaderShown(false);

      setModalElement(null);
    }
  };

  return (
    <ConfirmModalWrapper
      type="info"
      title={t("d_sync_modal_title")}
      cancelButton={{
        text: t("d_sync_modal_cancel"),
        onClicked: onCancelClick,
      }}
      acceptButton={{
        text: t("d_sync_modal_accept"),
        onClicked: onAcceptClick,
      }}
    >
      <div className={styles.modalBody}>
        <Trans i18nKey="d_sync_modal_body" components={[SearchNames]} />
      </div>
    </ConfirmModalWrapper>
  );
};
