import { FC, useContext, useMemo, useState } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ShareDashboardModal.module.scss";
import context from "../../../context";
import { ConfirmModalWrapper, Input, Label } from "../../../components";
import { withError } from "../../../hocs";
import { useTemporaryErrors } from "../../../hooks";
import { sendDataToGTM, validateEmail } from "../../../utils";
import { showToastNotification } from "../../ToastNotification/utils";

// Inner imports
import type { ShareDashboardModalProps } from "./types";

const InputWithError = withError(Input);

export const ShareDashboardModal: FC<ShareDashboardModalProps> = ({
  dashboardId,
  layoutSize,
}) => {
  const { t } = useTranslation();
  const { setModalElement } = useContext(context);

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [email, setEmail] = useState<string>("");

  const dashboardName = useSelector(
    ({ dashboards }: RootState) =>
      dashboards.entities[dashboardId]?.name || "Dashboard",
  );

  const invitationLink = useMemo(() => {
    const { origin } = window.location;
    return `${origin}/presentation/${dashboardId}?size=${layoutSize}`;
  }, [dashboardId, layoutSize]);

  const shareDashboardEmailHref = useMemo(() => {
    const formattedBody = `Hi,
Here is a presentation on ${dashboardName} that I really think you'll find interesting.
To view the presentation, click the link below.

${invitationLink}

-----------------------------------------------------------------------------------------------------------------
Search data has a proven correlation with market share and sales. Created using https://dashboard.mytelescope.io/
`;

    const subject = `Presentation on ${dashboardName}`;
    const body = encodeURIComponent(formattedBody);

    return `${email.trim()}?subject=${subject}&body=${body}`;
  }, [email, dashboardName, invitationLink]);

  const shareTheLink = () => {
    sendDataToGTM("UserSharedPresentation", {
      isPresentationSharedThroughEmail: false,
    });
    copy(invitationLink);
    showToastNotification({
      id: "copy_dashboard_share_link",
      type: "info",
      text: t("cp_link_has_been_copied"),
    });
  };

  const onSubmitHandler = () => {
    const isHasErrors = validate();
    if (isHasErrors) return;

    sendDataToGTM("UserSharedPresentation", {
      isPresentationSharedThroughEmail: true,
    });
    const link = document.createElement("a");
    link.href = `mailto:${shareDashboardEmailHref}`;
    link.click();

    setModalElement(null);
  };

  const onDoneClicked = () => setModalElement(null);

  function validate() {
    const errors: Errors = {};

    if (!email.length) {
      errors["email"] = t("sd_modal_required_message");
    }

    if (!validateEmail(email)) {
      errors["email"] = t("sd_modal_invalid_email_message");
    }

    setErrors(errors);

    return !!Object.keys(errors).length;
  }

  return (
    <ConfirmModalWrapper
      type="success"
      className={styles.shareDashboardModal}
      title={t("sd_modal_title")}
      acceptButton={{
        onClicked: onSubmitHandler,
        text: t("sd_modal_send_invite_button"),
        disabled: !email.length,
        color: "#3F9DF3",
      }}
      cancelButton={{
        onClicked: onDoneClicked,
        text: t("sd_modal_cancel_button"),
      }}
      extraButtons={[
        {
          className: styles.copyLinkButton,
          text: t("sd_modal_copy_link_button"),
          icon: "CopyLink",
          onClicked: shareTheLink,
          color: "transparent",
          disabled: !invitationLink,
        },
      ]}
    >
      <div className={styles.email}>
        <Label leftText={t("sd_modal_emeil_label")} className={styles.label} />
        <InputWithError
          value={email}
          changeHandler={(value) => setEmail(value.trim())}
          className={styles.input}
          error={errors.email}
        />
      </div>
    </ConfirmModalWrapper>
  );
};
