import type { Dispatch } from "redux";
import { changeIsWidgetBrokenStatus, setWidget } from "../../../store/actions";
import {
  getShareOfSearchWidget,
  getTopKeywordsWidget,
  getShareOfEarnedMediaWidget,
  getSentimentDriversWidget,
  getBusinessDriversWidget,
  getRobustnessWidget,
  getAwarenessWidget,
  getReputationIndexWidget,
  getReputationSentimentWidget,
  getReputationDistributionWidget,
  getEsgIndexWidget,
  getEsgDistributionWidget,
  getEsgSentimentWidget,
  getFunnelWidget,
  getSentimentIndexWidget,
  getSearchTrendWidget,
  getShareOfSearchFunnelWidget,
  getSentimentTrendsWidget,
  getAttitudeWidget,
  getAssociatedWordsWidget,
  getInterestDriverComparisonWidget,
} from "../../../store/api";

import * as checkFunctions from "./checkIfWidgetDataOk";

type Props = {
  searchId: string;
  brands: Store.Brands;
  dispatch: Dispatch<any>;
};

const shareOfSearchFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-search-share";

  const widgetData = await getShareOfSearchWidget(searchId, brands);
  const isDataOk = checkFunctions.checkShareOfSearchData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const topKeywordsFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-top-trend-keywords";

  const widgetData = await getTopKeywordsWidget(searchId, brands);

  const isDataOk = checkFunctions.checkTopKeywordsData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const shareOfEarnedMediaFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-media-share";

  const widgetData = await getShareOfEarnedMediaWidget(searchId, brands);
  const isDataOk = checkFunctions.checkShareOfEarnedMediaData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const attitudeFetchFunction = async ({ searchId, brands, dispatch }: Props) => {
  const widgetId = "result-attitude";

  const widgetData = await getAttitudeWidget(searchId, brands);
  const isDataOk = checkFunctions.checkAttitudeData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const sentimentDriversFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-sentiment-drivers";

  const widgetData = await getSentimentDriversWidget(searchId, brands);
  const isDataOk = checkFunctions.checkSentimentDriversData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const businessDriversFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-business-drivers";

  const widgetData = await getBusinessDriversWidget(searchId, brands);
  const isDataOk = checkFunctions.checkBusinessDriversData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const generalAttributesFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-associated-words";

  const widgetData = await getAssociatedWordsWidget(searchId, brands);
  const isDataOk = checkFunctions.checkGeneralAttributesData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const positionVsCompetitionFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-position-vs-competition";

  const widgetData = await getInterestDriverComparisonWidget(searchId, brands);
  const isDataOk = checkFunctions.checkPositionVsCompetitionData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const dataRobustnessScoreFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-robustness-score";

  const widgetData = await getRobustnessWidget(searchId, brands);
  const isDataOk = checkFunctions.checkDataRobustnessScoreData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const awarenessFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-awareness";

  const widgetData = await getAwarenessWidget(searchId, brands);
  const isDataOk = checkFunctions.checkAwarenessData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const reputationIndexFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-reputation-index";

  const widgetData = await getReputationIndexWidget(searchId, brands);
  const isDataOk = checkFunctions.checkReputationIndexData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const reputationSentimentFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-reputation-sentiment";

  const widgetData = await getReputationSentimentWidget(searchId, brands);
  const isDataOk = checkFunctions.checkReputationSentimentData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const reputationDistributionFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-reputation-distribution";

  const widgetData = await getReputationDistributionWidget(searchId, brands);
  const isDataOk = checkFunctions.checkReputationDistributionData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const esgIndexFetchFunction = async ({ searchId, brands, dispatch }: Props) => {
  const widgetId = "result-esg-index";

  const widgetData = await getEsgIndexWidget(searchId, brands);
  const isDataOk = checkFunctions.checkEsgIndexData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const esgSentimentFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-esg-sentiment";

  const widgetData = await getEsgSentimentWidget(searchId, brands);
  const isDataOk = checkFunctions.checkEsgSentimentData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const esgDistributionFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-esg-distribution";

  const widgetData = await getEsgDistributionWidget(searchId, brands);
  const isDataOk = checkFunctions.checkEsgDistributionData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const funnelFetchFunction = async ({ searchId, brands, dispatch }: Props) => {
  const widgetId = "result-funnel";

  const widgetData = await getFunnelWidget(searchId, brands);
  const isDataOk = checkFunctions.checkFunnelData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const sentimentIndexFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-sentiment-index";

  const widgetData = await getSentimentIndexWidget(searchId, brands);
  const isDataOk = checkFunctions.checkSentimentIndexData(widgetData);

  if (isDataOk) {
    widgetData && dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const searchTrendsFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-search-trends";

  const widgetData = await getSearchTrendWidget(searchId, brands);
  const isDataOk = checkFunctions.checkSearchTrendsData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const shareOfSearchFunnelFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-share-of-search-funnel";

  const fetchWidgetData = await getShareOfSearchFunnelWidget(searchId, brands);
  const widgetData = fetchWidgetData || {};
  const isDataOk = checkFunctions.checkShareOfSearchFunnelData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const sentimentTrendsFetchFunction = async ({
  searchId,
  brands,
  dispatch,
}: Props) => {
  const widgetId = "result-sentiment-trends";

  const fetchWidgetData = await getSentimentTrendsWidget(searchId, brands);
  const widgetData = fetchWidgetData || {};
  const isDataOk = checkFunctions.checkSentimentTrendsData(widgetData);

  if (isDataOk) {
    dispatch(setWidget(widgetId, widgetData, searchId));
  } else {
    dispatch(changeIsWidgetBrokenStatus(searchId, widgetId, true));
  }
};

const FETCH_FUNCTIONS: {
  [widgetId: string]: ({ searchId, brands, dispatch }: Props) => Promise<void>;
} = {
  "result-search-share": shareOfSearchFetchFunction,
  "result-volume-share": shareOfSearchFetchFunction,
  "result-top-trend-keywords": topKeywordsFetchFunction,
  "result-media-share": shareOfEarnedMediaFetchFunction,
  "result-attitude": attitudeFetchFunction,
  "result-sentiment-drivers": sentimentDriversFetchFunction,
  "result-business-drivers": businessDriversFetchFunction,
  "result-key-areas": businessDriversFetchFunction,
  "result-associated-words": generalAttributesFetchFunction,
  "result-position-vs-competition": positionVsCompetitionFetchFunction,
  "result-robustness-score": dataRobustnessScoreFetchFunction,
  "result-awareness": awarenessFetchFunction,
  "result-reputation-index": reputationIndexFetchFunction,
  "result-reputation-sentiment": reputationSentimentFetchFunction,
  "result-reputation-distribution": reputationDistributionFetchFunction,
  "result-esg-index": esgIndexFetchFunction,
  "result-esg-sentiment": esgSentimentFetchFunction,
  "result-esg-distribution": esgDistributionFetchFunction,
  "result-funnel": funnelFetchFunction,
  "result-sentiment-index": sentimentIndexFetchFunction,
  "result-search-trends": searchTrendsFetchFunction,
  "result-share-of-search-funnel": shareOfSearchFunnelFetchFunction,
  "result-sentiment-trends": sentimentTrendsFetchFunction,
};

export { FETCH_FUNCTIONS };
