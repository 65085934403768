import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./PasswordInput.module.scss";
import { Input } from "../../../components";
import { CrossedEye, Eye } from "../../../icons";
import { withError } from "../../../hocs";

const InputWithError = withError(Input);

// Inner imports
import { requirements, REGEXPS } from "./constants";
import type { PasswordInputProps } from "./types";

export const PasswordInput: FC<PasswordInputProps> = ({
  className = "",
  value,
  onChangeHandler,
  error,
  placeholder,
  isPasswordRequirementsShow = false,
}) => {
  const { t } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isRequirementsShow, setIsRequirementsShow] = useState<boolean>(false);

  const [errors, setErrors] = useState<Errors>({});

  const onPasswordChangeHandler = (value: string) => {
    if (!isPasswordRequirementsShow) {
      onChangeHandler(value);
    }

    const errors: Errors = {};

    const {
      passwordLengthRegex,
      oneLowercaseCharacterRegex,
      oneUppercaseCharacterRegex,
      atLeastOneNumberRegex,
      atLeastOneSpecialSymbol,
    } = REGEXPS;

    if (!passwordLengthRegex.test(value)) {
      const key = "notEnoughCharacters";
      errors[key] = requirements[key];
    }

    if (!oneLowercaseCharacterRegex.test(value)) {
      const key = "oneLowercaseCharacter";
      errors[key] = requirements[key];
    }

    if (!oneUppercaseCharacterRegex.test(value)) {
      const key = "oneUppercaseCharacter";
      errors[key] = requirements[key];
    }

    if (
      !atLeastOneNumberRegex.test(value) &&
      !atLeastOneSpecialSymbol.test(value)
    ) {
      const key = "oneNumber";
      errors[key] = requirements[key];
    }

    onChangeHandler(value, errors);
    setErrors(errors);
  };

  return (
    <div
      className={cx(styles.inputWrapper, className)}
      onFocus={() => setIsRequirementsShow(true)}
      onBlur={() => setIsRequirementsShow(false)}
    >
      <InputWithError
        className={styles.input}
        type={isPasswordShown ? "text" : "password"}
        value={value}
        changeHandler={onPasswordChangeHandler}
        placeholder={placeholder}
        error={error}
        isAutoComplete={false}
      />
      <button
        className={styles.showButton}
        type="button"
        onClick={() => setIsPasswordShown((state) => !state)}
      >
        {isPasswordShown ? <CrossedEye /> : <Eye />}
      </button>
      {isPasswordRequirementsShow && isRequirementsShow && (
        <div className={styles.passwordRequirements}>
          <span>{t("pe_title")}</span>
          <ul className={styles.passwordRequirementsList}>
            {Object.entries(requirements).map(([error, text]) => (
              <li
                key={error}
                className={styles[value && !errors[error] ? "done" : ""]}
              >
                {t(text)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
