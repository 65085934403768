import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./EsgDistributionPreview.module.scss";
import { Preloader, BarChart, ErrorPlaceholder } from "../../../components";
import esgParameters from "../../../data/esgParameters";

// Inner imports
import { LIGHT_BLUE } from "../../../data/colors";
import { onErrorHandler } from "../../helpers";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching } from "../../hooks";

export const EsgDistributionPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const barChart = useMemo(() => {
    if (!widgetData) return;
    return widgetData[0]?.brandData.barChart;
  }, [widgetData]);

  const chartData = useMemo(() => {
    if (barChart) {
      const data =
        barChart.oneYear ||
        barChart.sixMonths ||
        barChart.threeMonths ||
        barChart.twoMonths ||
        barChart.oneMonth;

      return esgParameters.slice(1).map(({ name }) => ({
        label: name,
        value: data?.values[name] || 0,
      }));
    }
  }, [barChart]);

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {chartData ? (
        <>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            <BarChart
              className={styles.chart}
              data={chartData}
              color={{
                border: "#8e8e63",
                background: LIGHT_BLUE,
                label: "#8e8e63",
              }}
            />
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
