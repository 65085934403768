import React, { CSSProperties, useState, useRef, useEffect } from "react";
import classes from "./DataSourceScoreCellChart.module.scss";

interface Props {
  className?: string;
  style?: CSSProperties;
  cellStyles?: CSSProperties;
  title?: string;
  articlesQuantity?: number;
  value?: number;
}

export const DataSourceScoreCellChart = ({
  className,
  style,
  cellStyles,
  title,
  articlesQuantity,
  value = 0,
}: Props) => {
  const [score, setScore] = useState(0);

  const timeout = useRef<number>();

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      setScore(value);
    }, 10);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [value]);

  return (
    <div className={className} style={style}>
      {(title || typeof articlesQuantity === "number") && (
        <div className={classes.titleWrapper}>
          <div className={classes.title} title={title}>
            {title}
          </div>
          <div className={classes.articlesQuantity}>
            {typeof articlesQuantity === "number" && (
              <>
                <span>{articlesQuantity.toLocaleString()}</span>
                <span> article{articlesQuantity !== 1 ? "s" : ""}</span>
              </>
            )}
          </div>
        </div>
      )}
      <div className={classes.cellsOuterWrapper}>
        <div className={classes.cellsInnerWrapper}>
          <div className={classes.cells}>
            {[...Array(100).keys()].map((i) => (
              <div
                className={classes.cell}
                style={{
                  backgroundColor: i + 1 > 100 - score ? "#CECE84" : undefined,
                  ...cellStyles,
                }}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
