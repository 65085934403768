import { memo, FC } from "react";
import cx from "classnames";

import styles from "./Labels.module.scss";

// Inner imports
import type { LabelsProps } from "../../types";

export const Labels: FC<LabelsProps> = memo(
  ({ labels = [], inactiveLabels, inactiveLabelClickHandler }) => (
    <div className={styles.labels}>
      {labels.map(({ name, color }, i) => (
        <div
          className={cx(
            styles.label,
            inactiveLabels.includes(name) ? styles.labelInactive : "",
          )}
          onClick={() => inactiveLabelClickHandler(name)}
          title={name}
          key={i}
        >
          <div style={{ backgroundColor: color }} />
          <div>{name}</div>
        </div>
      ))}
    </div>
  ),
  memoFunction,
);

function memoFunction(prevProps: LabelsProps, nextProps: LabelsProps): boolean {
  const {
    labels: prevPropsLabels = [],
    inactiveLabels: prevPropsInactiveLabels,
  } = prevProps;
  const {
    labels: nextPropsLabels = [],
    inactiveLabels: nextPropsInactiveLabels,
  } = nextProps;

  const sortedPrevPropsLabels = [...prevPropsLabels].sort();
  const sortedNextPropsLabels = [...nextPropsLabels].sort();

  const isLabelsTheSame =
    JSON.stringify(sortedPrevPropsLabels) ===
    JSON.stringify(sortedNextPropsLabels);

  const isInactiveLabelsTheSame =
    prevPropsInactiveLabels.length === nextPropsInactiveLabels.length;

  return isLabelsTheSame && isInactiveLabelsTheSame;
}
