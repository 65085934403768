import {
  FC,
  FormEvent,
  useState,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import styles from "./DashboardToFolderModal.module.scss";
import context from "../../../context";
import { Check } from "../../../icons";
import { Select, Input, Label } from "../../../components";
import { withError } from "../../../hocs";
import { useTemporaryErrors } from "../../../hooks";
import {
  addOneFolder,
  Folder,
  updateOneFolder,
} from "../../../store/folders/foldersSlice";
import { showToastNotification } from "../../ToastNotification/utils";
import {
  getFolderById,
  removeDashboardFromFolder,
} from "../../../containers/Home/Dashboards/utils";

// Inner imports
import type { DashboardToFolderModalProps } from "./types";

const InputWithError = withError(Input);

export const DashboardToFolderModal: FC<DashboardToFolderModalProps> = ({
  folderId = "",
  dashboardId,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialFolderId = useRef("");

  const { setModalElement, setIsGlobalPreloaderShown } = useContext(context);
  const { errors, setErrors } = useTemporaryErrors(3000);

  const folders = useSelector(({ folders }: RootState) =>
    Object.values(folders.entities).filter(
      (dashboard: Folder | undefined): dashboard is Folder => !!dashboard,
    ),
  );

  const [folderName, setFolderName] = useState<string>("");
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");

  useEffect(() => {
    initialFolderId.current = folderId;
    setSelectedFolderId(folderId);
  }, [folderId]);

  useEffect(() => {
    const modalWrapperRef = document.getElementById("modal-wrapper");
    if (!modalWrapperRef) return;

    const modalWrapperChildrenWrapper = modalWrapperRef
      .childNodes[0]! as HTMLElement;

    modalWrapperChildrenWrapper.style.overflow = "initial";

    return () => {
      modalWrapperChildrenWrapper.style.overflow = "hidden";
    };
  }, []);

  const title = useMemo(() => {
    if (type === "move to another folder")
      return t("atf_move_to_another_folder_title");

    return t("atf_add_to_folder_title");
  }, [type, t]);

  const foldersOptions = useMemo(
    () =>
      Object.values(folders).map((folder) => {
        const { id: value, name: label } = folder;

        return { value, label };
      }),
    [folders],
  );

  const allFoldersName = useMemo(() => {
    const names: string[] = [];

    Object.values(folders).forEach((folder) => {
      const folderName = folder?.name;
      const isThisFolder = folderId === folder?.id;
      if (folderName && !isThisFolder) names.push(folderName);
    });

    return names;
  }, [folders, folderId]);

  const onSetFolderNameHandler = (value: string) => {
    setSelectedFolderId("");
    setFolderName(value);
  };

  const onSetFolderId = (value: string) => {
    setFolderName("");
    setSelectedFolderId(value);
  };

  const onSubmitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate();

    if (!!Object.keys(errors).length) return;

    if (!selectedFolderId) {
      // User are creating new folder
      await createNewFolderWithTheDashboard();
    } else {
      // User are modifying existing folder
      await modifyFolder(selectedFolderId);
    }
  };

  const onResetFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setModalElement(false);
  };

  async function createNewFolderWithTheDashboard() {
    const dashboardPreviousFolder = folders.find(({ dashboardIds }) =>
      dashboardIds.includes(dashboardId),
    );

    setIsGlobalPreloaderShown(true);

    await Promise.all([
      dashboardPreviousFolder &&
        removeDashboardFromFolder(
          dashboardId,
          dispatch,
          dashboardPreviousFolder,
        ),
      dispatch(addOneFolder({ name: folderName, dashboardIds: [dashboardId] })),
    ])
      .then(() => {
        showToastNotification({
          type: "success",
          text: t("d_table_folder_create_success"),
        });
      })
      .catch((err) => {
        showToastNotification({ type: "error", text: t("request_error") });
        console.error(err);
      })
      .finally(() => {
        setIsGlobalPreloaderShown(false);
        setModalElement(null);
      });
  }

  async function modifyFolder(folderId: string) {
    const startFolderId = initialFolderId.current;

    try {
      setIsGlobalPreloaderShown(true);
      if (startFolderId) {
        // Move the dashboard from one folder to another
        await moveDashboardFromOneFolderToAnother(startFolderId, folderId);
      } else {
        // Add dashboard to existing folder
        await addDashboardToFolder(folderId);
      }
    } catch (err) {
      showToastNotification({ type: "error", text: t("request_error") });
      console.error(err);
    } finally {
      setIsGlobalPreloaderShown(false);
      setModalElement(null);
    }
  }

  async function moveDashboardFromOneFolderToAnother(
    oldDashboardFolderId: string,
    newDashboardFolderId: string,
  ) {
    const folder = getFolderById(folders, oldDashboardFolderId);

    if (oldDashboardFolderId === newDashboardFolderId) return;

    await Promise.all([
      removeDashboardFromFolder(dashboardId, dispatch, folder),
      addDashboardToFolder(newDashboardFolderId),
    ]);
  }

  async function addDashboardToFolder(folderId: string) {
    const folder = getFolderById(folders, folderId);
    if (!folder) throw Error("Folder not found");

    const newDashboardsIds = [
      ...new Set([...folder.dashboardIds, dashboardId]),
    ];

    await dispatch(
      updateOneFolder({
        id: folderId,
        changes: { dashboardIds: newDashboardsIds },
      }),
    );
  }

  function validate() {
    const errors: Errors = {};

    const trimmedName = folderName.trim().toLowerCase();

    if (!trimmedName && !selectedFolderId) {
      errors["empty"] = "";
      showToastNotification({
        type: "error",
        text: t("atf_new_folder_modal_creation_error"),
      });
    }

    if (
      allFoldersName.some((name) => name.trim().toLowerCase() === trimmedName)
    ) {
      errors["name"] = t("atf_folder_already_exist_error");
    }

    setErrors(errors);
    return errors;
  }

  return (
    <div className={styles.dashboardToFolderModal}>
      <div className={styles.head}>
        <Check />
        {title}
      </div>
      <form
        className={styles.editFolderForm}
        onSubmit={onSubmitFormHandler}
        onReset={onResetFormHandler}
      >
        <div>
          <Label leftText={t("atf_folders_label")} />
          <Select
            placeholder={t("atf_add_to_folder_placeholder")}
            options={foldersOptions}
            value={selectedFolderId}
            changeHandler={onSetFolderId}
          />
        </div>
        <div className={styles.separator}>
          <span>{t("atf_separator")}</span>
        </div>
        <div>
          <Label leftText={t("atf_new_folder_label")} />
          <InputWithError
            placeholder={t("atf_new_folder_placeholder")}
            value={folderName}
            changeHandler={onSetFolderNameHandler}
            error={errors["name"]}
          />
        </div>

        <div className={styles.buttonsWrapper}>
          <button type="reset">{t("atf_reset_button")}</button>
          <button type="submit">{t("atf_submit_button")}</button>
        </div>
      </form>
    </div>
  );
};
