import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RenameModal.module.scss";
import { Input } from "../../../components";
import { withError } from "../../../hocs";

type Props = {
  initialName?: string;
  alreadyWoreNames: string[];
  onSaveClicked: (trimmedName: string) => void;
};

const InputWithError = withError(Input);

let TIMER: NodeJS.Timeout | null;

export const RenameModal: FC<Props> = ({
  initialName = "",
  alreadyWoreNames,
  onSaveClicked,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(initialName);
  const [isErrorShow, setIsErrorShow] = useState(false);

  useEffect(() => {
    setIsErrorShow(false);

    return () => {
      TIMER && clearTimeout(TIMER);
    };
  }, [name]);

  const onSaveHandler = () => {
    const isNameAlreadyWrote = alreadyWoreNames.some(
      (alreadyWoreName) => alreadyWoreName === name,
    );
    if (isNameAlreadyWrote) {
      setIsErrorShow(true);

      TIMER = setTimeout(() => {
        setIsErrorShow(false);
        TIMER && clearTimeout(TIMER);
      }, 3000);
    } else {
      onSaveClicked(name.trim());
    }
  };

  const inputError = isErrorShow ? t("rd_modal_name_already_exist_error") : "";
  return (
    <div className={styles.renameModal}>
      <div className={styles.label}>{t("rd_modal_label")}</div>
      <div className={styles.inputWrapper}>
        <InputWithError
          className={styles.input}
          value={name}
          placeholder=""
          changeHandler={(value) => setName(value)}
          error={inputError}
        />
      </div>
      <button className={styles.greenButton} onClick={onSaveHandler}>
        {t("rd_modal_button_text")}
      </button>
    </div>
  );
};
