import { FC, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./GeneralFormButtons.module.scss";
import context from "../../../context";
import { ConfirmModalWrapper, DiscardModal } from "../../../components";
import { Refresh } from "../../../icons";

type Props = {
  className?: string;
  isButtonsActive?: boolean;
  isCancelButton?: boolean;
  buttonTexts?: {
    saveButtonText?: string;
    cancelButtonText?: string;
    resetButtonText?: string;
  };
  onSubmitHandler?: () => void;
  onResetHandler?: () => void;
};

export const GeneralFormButtons: FC<Props> = ({
  className = "",
  isButtonsActive = false,
  isCancelButton = true,
  buttonTexts,
  onSubmitHandler,
  onResetHandler,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setModalElement } = useContext(context);

  const {
    saveButtonText = "",
    cancelButtonText = "",
    resetButtonText = "",
  } = useMemo(() => buttonTexts || {}, [buttonTexts]);

  const onCancel = () => {
    if (!isButtonsActive) return history.goBack();

    setModalElement(<DiscardModal />, false);
  };

  const onReset = () => {
    setModalElement(
      <ConfirmModalWrapper
        type="warning"
        title={t("rcmw_title")}
        cancelButton={{
          text: t("rcmw_cancel_btn"),
          onClicked: () => setModalElement(null),
        }}
        acceptButton={{
          text: t("rcmw_ok_btn"),
          onClicked: () => {
            onResetHandler?.();
            setModalElement(null);
          },
        }}
      >
        <p>{t("rcmw_text")}</p>
      </ConfirmModalWrapper>,
    );
  };

  return (
    <div className={cx(styles.formButtons, className)}>
      {isCancelButton && (
        <button
          className={styles.cancelButton}
          type={"button"}
          onClick={onCancel}
        >
          {cancelButtonText || t("general_form_cancel_button")}
        </button>
      )}

      {isButtonsActive && onResetHandler && (
        <button className={styles.resetButton} type="button" onClick={onReset}>
          <Refresh />
          <span>{resetButtonText || t("general_form_reset_button")}</span>
        </button>
      )}

      <button
        className={styles.submitButton}
        disabled={!isButtonsActive}
        type={onSubmitHandler ? "button" : "submit"}
        onClick={onSubmitHandler && onSubmitHandler}
      >
        {saveButtonText || t("general_form_save_button")}
      </button>
    </div>
  );
};
