import { texts } from "../ReviewsDataTexts";

const WIDGET_ID = "result-esg-index";

const INFLUENCE_ON_WIDGETS = [
  "result-esg-index",
  "result-esg-sentiment",
  "result-esg-distribution",
];

const ABOUT_THIS = texts[WIDGET_ID]?.aboutThis || "";

const ARTICLES_LIMIT = 200 as const;

const ARTICLES_PARAMETERS = [
  "all",
  "governance",
  "social",
  "environmental",
] as const;

const REVIEW_DATA_CATEGORY = "ESG review data";

export {
  WIDGET_ID,
  ABOUT_THIS,
  INFLUENCE_ON_WIDGETS,
  ARTICLES_LIMIT,
  ARTICLES_PARAMETERS,
  REVIEW_DATA_CATEGORY,
};
