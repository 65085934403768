import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import compareAsc from "date-fns/compareAsc";

import context from "src/context";
import { formatToDayMonthYearDate, stringFilter } from "src/utils";
import { useQueryParams } from "src/hooks";
import { Table, ConfirmModalWrapper } from "src/components";
import { getRecentItemsIds } from "src/components/Table/utils";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { ROUTS } from "src/data/routs";
import { allCountries } from "src/data/allCountries";
import { selectSearches } from "src/store/selectors";
import { deleteTimePlot } from "src/store/timePlots/actions";

// Inner imports
import {
  TIME_PLOTTER_TABLE_DEFAULT_SORT,
  TIME_PLOTTER_TABLE_COLUMNS,
} from "./constants";
import { TimePlotterTableProps } from "./types";

const TimePlotterTable: FC<TimePlotterTableProps> = ({
  type = "all",
  isConcise = false,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const query = useQueryParams();

  const {
    setModalElement,
    setIsGlobalPreloaderShown,
    setActionBarProps,
  } = useContext(context);

  const { data: fetchedTimePlots } = useSelector(
    ({ timePlots }: Store.State) => timePlots,
  );
  const searches = useSelector(selectSearches);
  const user = useSelector(({ user }: Store.State) => user);
  const company = useSelector(({ company }: Store.State) => company);
  const companyMembers = useMemo(() => company.members, [company]);

  const isRecentTable = useMemo(() => type === "recent", [type]);

  const recentTimePlotIds: string[] = useMemo(
    () => getRecentItemsIds(fetchedTimePlots),
    [fetchedTimePlots],
  );

  const allTimePlots: Store.TimePlot[] = useMemo(() => {
    switch (type) {
      case "all":
        return fetchedTimePlots;
      case "recent":
        return fetchedTimePlots.filter(({ id }) =>
          recentTimePlotIds.includes(id),
        );
      default:
        return fetchedTimePlots;
    }
  }, [fetchedTimePlots, recentTimePlotIds, type]);

  const [filterKey, filterValue]: [string, string] = useMemo(() => {
    const [filterKey = "", filterValue = ""] = Object.entries(query)[0] || [];

    return [filterKey, filterValue];
  }, [query]);

  const filteredTimePlots: Store.TimePlot[] = useMemo(() => {
    if (!filterKey || !filterValue || isConcise) return allTimePlots;

    return allTimePlots.filter((tp) => {
      const timePlotterFilterKey = filterKey as keyof Store.TimePlot;
      const value = tp?.[timePlotterFilterKey]?.toString() || "";

      return stringFilter(value, filterValue);
    });
  }, [allTimePlots, filterKey, filterValue, isConcise]);

  const getMemberFullName = useCallback(
    (memberId): string =>
      companyMembers.find(({ id }) => memberId === id)?.fullName || "",
    [companyMembers],
  );

  const formattedTimePlots: Store.TimePlot[] = useMemo(
    () =>
      filteredTimePlots.map((timePlotter) => {
        const {
          dateFrom,
          dateTo,
          searchId,
          country,
          userId: timePlotterUserId,
          name,
        } = timePlotter;

        const search = searches.find(({ id }) => id === searchId);
        const isSearchDeleted = searchId && !search;
        const _searchName = search?.name || "";

        const isOwnTimePlotter = timePlotterUserId === user.id;

        const memberFullName = isOwnTimePlotter
          ? ""
          : getMemberFullName(timePlotterUserId);

        const timePlotterName =
          !isOwnTimePlotter && memberFullName
            ? t("event_name_with_user_name", {
                name,
                userName: memberFullName,
              })
            : name;

        const searchName = isSearchDeleted
          ? t("tp_searchId_deleted", {
              searchName: _searchName,
            })
          : _searchName;

        const countryName =
          allCountries.find(({ value }) => value === country)?.label || "";

        return {
          ...timePlotter,
          name: timePlotterName,
          countryName,
          searchName,
          dateFromFormatted: formatToDayMonthYearDate(dateFrom),
          dateToFormatted: formatToDayMonthYearDate(dateTo),
        };
      }),
    [filteredTimePlots, searches, t, user.id, getMemberFullName],
  );

  const redirectToCreation = useCallback(
    (): void => history.push(ROUTS.eventsNew),
    [history],
  );

  const searchBarOptions = useMemo(() => {
    const timePlotsData = fetchedTimePlots || [];

    return timePlotsData.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [fetchedTimePlots]);

  const emptyTableLabel: string | JSX.Element = useMemo(() => {
    switch (true) {
      case !searches.length:
        return (
          <Trans
            i18nKey="d_table_no_searches"
            components={[<Link to={ROUTS.createTracker} />]}
          />
        );
      case !allTimePlots.length:
        return (
          <Trans
            i18nKey="events_table_empty"
            components={[<Link to={ROUTS.eventsNew} />]}
          />
        );
      case filterKey && filterValue && !isConcise && !filteredTimePlots.length:
        return t("events_filtered_table_empty");
      default:
        return t("no_results_found");
    }
  }, [
    searches.length,
    allTimePlots.length,
    filteredTimePlots.length,
    filterKey,
    filterValue,
    isConcise,
    t,
  ]);

  useEffect(() => {
    if (isConcise) return;

    setActionBarProps({
      title: t("events_title"),
      additionalButton: {
        title: t("events_header_create_button_lb"),
        icon: "PlusInCircle",
        onClickHandler: redirectToCreation,
      },
      searchBar: {
        type: "events",
        placeholder: "events_search_bar_placeholder",
        options: searchBarOptions,
      },
    });
  }, [searchBarOptions, redirectToCreation, setActionBarProps, t, isConcise]);

  const openSummaryTimePlotModal = useCallback(
    (timePlotId: string): void => {
      if (!timePlotId) return;

      history.push(`${ROUTS.eventsHome}/summary/${timePlotId}`);
    },
    [history],
  );

  const TIME_PLOTTER_TABLE_COLUMNS_WITH_CLICK_EVENT = useMemo(
    () =>
      TIME_PLOTTER_TABLE_COLUMNS.map((column) => {
        if (column.key === "name") {
          return {
            ...column,
            onClick: (item: any) => openSummaryTimePlotModal(item.id),
          };
        }
        return column;
      }),
    [openSummaryTimePlotModal],
  );

  const tableColumns = useMemo(() => {
    if (!isConcise) {
      return TIME_PLOTTER_TABLE_COLUMNS_WITH_CLICK_EVENT;
    }

    return TIME_PLOTTER_TABLE_COLUMNS_WITH_CLICK_EVENT.filter(
      ({ isInShortTable }) => isInShortTable,
    );
  }, [TIME_PLOTTER_TABLE_COLUMNS_WITH_CLICK_EVENT, isConcise]);

  const onTimePlotDelete = useCallback(
    async (id: string): Promise<void> => {
      try {
        setModalElement(null);
        setIsGlobalPreloaderShown(true);
        await dispatch(deleteTimePlot(id));
        showToastNotification({
          type: "success",
          text: t("event_table_time_plot_delete_success"),
        });
      } catch (err) {
        console.error(err.message);
        showToastNotification({
          type: "error",
          text: t("request_error"),
        });
      } finally {
        setIsGlobalPreloaderShown(false);
      }
    },
    [dispatch, setIsGlobalPreloaderShown, setModalElement, t],
  );

  const openDeleteTimePlotModal = useCallback(
    (timePlotId: string): void => {
      setModalElement(
        <ConfirmModalWrapper
          type="error"
          acceptButton={{
            text: t("event_delete_confirm_modal_yes"),
            onClicked: () => onTimePlotDelete(timePlotId),
          }}
          cancelButton={{
            text: t("event_delete_confirm_modal_no"),
            onClicked: () => {
              setModalElement(null);
            },
          }}
          title={t("event_delete_confirm_modal_title")}
        >
          <p>
            <Trans
              i18nKey="event_delete_confirm_modal_body"
              components={[<br />]}
            />
          </p>
        </ConfirmModalWrapper>,
        false,
      );
    },
    [onTimePlotDelete, setModalElement, t],
  );

  const openEditTimePlot = useCallback(
    (timePlotId: string): void =>
      history.push(`${ROUTS.eventsHome}/edit/${timePlotId}`),
    [history],
  );

  const getActionMenuOptions = useCallback(
    (timePlot: Store.TimePlot) => {
      const { id } = timePlot;

      return isConcise
        ? []
        : [
            {
              icon: "TimePlotterSummary" as AppIcon,
              label: t("tp_table_th_summary"),
              onClick: () => openSummaryTimePlotModal(id),
            },
            {
              icon: "Edit" as AppIcon,
              label: t("tp_table_th_edit"),
              onClick: () => openEditTimePlot(id),
            },
            {
              icon: "Trash" as AppIcon,
              label: t("tp_table_th_delete"),
              onClick: () => openDeleteTimePlotModal(id),
            },
          ];
    },
    [
      isConcise,
      openDeleteTimePlotModal,
      openEditTimePlot,
      openSummaryTimePlotModal,
      t,
    ],
  );

  const afterSort = useCallback(
    (a: Store.TimePlot, b: Store.TimePlot) =>
      isRecentTable
        ? recentTimePlotIds.indexOf(a.id) - recentTimePlotIds.indexOf(b.id)
        : compareAsc(Date.parse(b.createdAt), Date.parse(a.createdAt)),
    [isRecentTable, recentTimePlotIds],
  );

  return (
    <Table
      type="timePlotter"
      items={formattedTimePlots}
      tableColumns={tableColumns}
      isTableShort={isConcise}
      isTableScrollable
      isTableExpandable
      emptyTableLabel={emptyTableLabel}
      getActionMenuOptions={getActionMenuOptions}
      defaultSort={TIME_PLOTTER_TABLE_DEFAULT_SORT}
      customAfterSort={afterSort}
    />
  );
};

export default TimePlotterTable;
