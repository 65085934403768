import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./WordsWrapper.module.scss";
import { Input, KeyWordButton } from "../../../../../components";
import { capitalizeString } from "../../../../../utils";

type Props = {
  keyWordButtonClassName?: string;
  filterValue: string;
  filterPlaceholder: string;
  filterChangeHandle: (value: string) => void;
  words: string[];
  wordsNotImpact?: string[];
  onWordsClickHandler: (id: string) => void;
};

export const WordsWrapper = ({
  keyWordButtonClassName,
  filterValue,
  filterPlaceholder,
  filterChangeHandle,
  words,
  wordsNotImpact,
  onWordsClickHandler,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.words}>
      <div className={styles.filterWrapper}>
        <p className={styles.title}>{t("rd_tk_words_to_exclude")}</p>
        <Input
          className={styles.filter}
          placeholder={filterPlaceholder}
          value={filterValue}
          changeHandler={filterChangeHandle}
        />
      </div>
      {!!words.length &&
        words.map((word: string) => (
          <KeyWordButton
            className={cx(styles.word, keyWordButtonClassName)}
            text={capitalizeString(word)}
            id={word}
            key={word}
            clickHandler={({ id }) => onWordsClickHandler(id || "")}
          />
        ))}
      {!!wordsNotImpact?.length &&
        wordsNotImpact.map((word: string) => (
          <KeyWordButton
            className={cx(
              styles.word,
              styles.wordNotImpact,
              keyWordButtonClassName,
            )}
            text={capitalizeString(word)}
            id={word}
            key={word}
            clickHandler={({ id }) => onWordsClickHandler(id || "")}
          />
        ))}
    </div>
  );
};
