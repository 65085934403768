import styles from "./WordsBlock.module.scss";
import { Label, Preloader, KeyWordButton } from "../../../../../components";

type Props = {
  status: "loading" | "succeeded" | "failed";
  labelText: string;
  noWordsText: string;
  words: string[];
  onExcludeWordClicked: (text: string) => void;
};

export const WordsBlock = ({
  status,
  labelText,
  noWordsText,
  words,
  onExcludeWordClicked,
}: Props) => {
  return (
    <div className={styles.words}>
      <Label leftText={labelText} className={styles.boldLabel} />

      <div className={styles.wordsWrapper}>
        {status === "loading" ? (
          <Preloader type="bar" className={styles.loader} />
        ) : (
          <>
            {}
            {!words.length ? (
              <small>{noWordsText}</small>
            ) : (
              words.map((word, index) => (
                <KeyWordButton
                  text={word}
                  id={word}
                  key={index}
                  clickHandler={({ text }) => onExcludeWordClicked(text)}
                  className={styles.word}
                />
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};
