import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./WhatDoesItMean.module.scss";

// Inner imports
import type { WhatDoesItMean as WhatDoesItMeanType } from "../../types";

type Props = {
  data: WhatDoesItMeanType;
};

export const WhatDoesItMean: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const filteredData = useMemo(() => {
    return data.filter(
      ({ name, description, color }) => name && description && color,
    );
  }, [data]);

  return (
    <div className={styles.whatDoesItMean}>
      <p>{t("about_this_what_does_it_mean")}</p>
      <div className={styles.parameters}>
        {filteredData.map(({ name, description, color, key }, i) => (
          <div className={styles.parameter} key={key || i}>
            <div
              className={styles.parameterDot}
              style={{ backgroundColor: color }}
            />
            <div className={styles.text}>
              <span className={styles.parameterName} style={{ color: color }}>
                {t(name)}:
              </span>{" "}
              <span>{t(description)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
