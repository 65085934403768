import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import * as api from "./foldersApi";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
};

export type Folder = {
  id: string;
  name: string;
  dashboardIds: string[];
  createdAt: string;
  updatedAt: string;
};

const foldersAdapter = createEntityAdapter<Folder>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = foldersAdapter.getInitialState<InitialState>({
  status: "idle",
  error: null,
});

export const addOneFolder = createAsyncThunk<
  Folder,
  Pick<Folder, "name" | "dashboardIds">,
  { state: RootState }
>("folders/addOne", async (payload, { getState }) => {
  const { user } = getState();
  const userId = user.id;

  const { id, createdAt, updatedAt } = await api.createFolder({
    ...payload,
    userId,
  });

  return {
    ...payload,
    id,
    userId,
    createdAt,
    updatedAt,
  };
});

export const fetchAllFolders = createAsyncThunk<
  Folder[],
  void,
  { state: RootState }
>("folders/fetchAll", async (_, { getState }) => {
  const { user } = getState();
  const userId = user.id;

  return await api.readFolders(userId);
});

export const updateOneFolder = createAsyncThunk<
  UpdateEntity<Folder>,
  UpdateEntity<Folder>
>("folders/updateOne", async (payload) => {
  await api.updateFolder(payload);
  return payload;
});

export const removeOneFolder = createAsyncThunk<Folder["id"], Folder["id"]>(
  "folders/removeOne",
  async (payload) => {
    await api.deleteFolder(payload);
    return payload;
  },
);

const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addOneFolder.fulfilled, foldersAdapter.addOne);

    builder.addCase(fetchAllFolders.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(fetchAllFolders.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      foldersAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchAllFolders.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });

    builder.addCase(updateOneFolder.fulfilled, foldersAdapter.updateOne);

    builder.addCase(removeOneFolder.fulfilled, foldersAdapter.removeOne);
  },
});

export const {
  selectAll: selectAllFolders,
} = foldersAdapter.getSelectors<RootState>(({ folders }) => folders);

export default foldersSlice.reducer;
