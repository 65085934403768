import * as yup from "yup";
import firebase from "firebase";

import { SYNC_STATUS } from "src/data/syncStatus";

const Timestamp = firebase.firestore.Timestamp;

const syncSchema = yup
  .object({
    status: yup
      .mixed()
      .oneOf([...SYNC_STATUS])
      .required(),
    brandId: yup.string().required(),
    profileId: yup.string().required(),
    createdAt: yup.mixed().transform(formatSyncDate).required(),
  })
  .noUnknown()
  .required();

export type SyncSchemaType = yup.InferType<typeof syncSchema>;
export default syncSchema;

function formatSyncDate(value: unknown): string {
  if (!value) return "";

  if (typeof value === "string") return value;

  if (value instanceof Timestamp) return value.toDate()?.toISOString() || "";

  return "";
}
