import { HTMLAttributes, useState, useRef, useEffect, forwardRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./MenuDropdown.module.scss";
import * as icons from "../../../icons";
import { useOutsideClickHandler } from "../../../hooks";

type Props = HTMLAttributes<HTMLDivElement> & {
  parent?: HTMLElement;
  options: {
    icon?: AppIcon;
    label: string;
    onClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
  isEventPropagationStopped?: boolean;
};

export const MenuDropdown = forwardRef<HTMLDivElement, Props>(
  ({ className, options, isEventPropagationStopped, ...props }, ref) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [openingDirection, setOpeningDirection] = useState<{
      x?: "right" | "left";
      y?: "down" | "up";
    }>({
      x: undefined,
      y: undefined,
    });

    const parentRef = ref as { current?: HTMLElement } | undefined;

    const menuDropdownButtonRef = useRef<HTMLDivElement>(null);
    const menuDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const _setOpeningDirection = () => {
        const { current: parentElement } = parentRef || {};

        const selectHeight = menuDropdownRef.current?.clientHeight;
        const selectWidth = menuDropdownRef.current?.clientWidth;
        const selectBottom = menuDropdownButtonRef.current?.getBoundingClientRect()
          .bottom!;
        const selectRight = menuDropdownButtonRef.current?.getBoundingClientRect()
          .right!;

        if (!selectWidth || !selectHeight)
          return setOpeningDirection({
            x: undefined,
            y: undefined,
          });

        if (parentElement) {
          const parentBottomPosition = parentElement.getBoundingClientRect()
            .bottom;
          const parentRightPosition = parentElement.getBoundingClientRect()
            .right;

          const menuBottomPosition = menuDropdownRef?.current?.getBoundingClientRect()
            .bottom!;
          const menuRightPosition = menuDropdownRef?.current?.getBoundingClientRect()
            .right!;

          return setOpeningDirection({
            x: parentRightPosition > menuRightPosition ? "right" : "left",
            y: parentBottomPosition > menuBottomPosition ? "down" : "up",
          });
        }

        const clientHeight = document.documentElement.clientHeight;
        const clientWidth = document.documentElement.clientWidth;

        return setOpeningDirection({
          x: clientWidth > selectRight + selectWidth ? "right" : "left",
          y: clientHeight > selectBottom + selectHeight ? "down" : "up",
        });
      };
      _setOpeningDirection();
      window.addEventListener("resize", _setOpeningDirection);
      return () => {
        window.removeEventListener("resize", _setOpeningDirection);
      };
    }, [isOpen, parentRef]);

    useOutsideClickHandler(menuDropdownButtonRef, () => setIsOpen(false));

    return (
      <div
        className={cx(
          styles.menuDropdown,
          styles[isOpen ? "open" : ""],
          className,
        )}
        {...props}
        ref={menuDropdownButtonRef}
        onClick={(e) => isEventPropagationStopped && e.stopPropagation()}
      >
        <button
          className={styles.dots}
          onClick={() => setIsOpen((state) => !state)}
          type="button"
        >
          <span />
          <span />
          <span />
        </button>
        {isOpen && (
          <div
            className={cx(
              styles.menu,
              styles[openingDirection?.x || ""],
              styles[openingDirection?.y || ""],
            )}
            style={{ display: isOpen ? "block" : "none" }}
            ref={menuDropdownRef}
          >
            {!!options.length ? (
              options.map(({ icon, label, onClicked }, i) => {
                const Icon = icon && icons[icon];
                return (
                  <button
                    className={styles.option}
                    onClick={(e) => {
                      onClicked(e);
                      setIsOpen(false);
                    }}
                    key={i}
                  >
                    {Icon && <Icon className={styles.icon} />}
                    <span className={styles.label} title={label}>
                      {label}
                    </span>
                  </button>
                );
              })
            ) : (
              <div className={styles.noOptions}>
                <span className={styles.label}>{t("no_options")}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
