import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showDevelopmentError } from "src/utils";
import context from "src/context";
import {
  readKeyWordsWidgetsUpdateDate,
  readTimePlots,
} from "src/store/actions";
import { fetchAllIndustries } from "src/store/industries/industriesSlice";
import { fetchAllMarkets } from "src/store/markets/marketsSlice";
import { getKeywordToolSettings } from "src/store/widgetsSettings/api";

export const useMarketsAndIndustriesLoader = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllMarkets());
    dispatch(fetchAllIndustries());
  }, [dispatch]);
};

export const useKeyWordsWidgetsUpdateDateLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(readKeyWordsWidgetsUpdateDate());
    } catch (error) {
      showDevelopmentError({
        additionalTexts: ["KEYWORDS WIDGET UPDATE DATE FETCHING ERROR"],
        error,
      });
    }
  }, [dispatch]);
};

export const useTimePlotsLoader = () => {
  const dispatch = useDispatch();

  const companyId = useSelector(({ company }: Store.State) => company.id);

  useEffect(() => {
    if (!companyId) return;
    try {
      dispatch(readTimePlots());
    } catch (error) {
      showDevelopmentError({
        additionalTexts: ["KEYWORDS WIDGET UPDATE DATE FETCHING ERROR"],
        error,
      });
    }
  }, [companyId, dispatch]);
};

export const useKeywordToolSettingsLoader = () => {
  const dispatch: AppDispatch = useDispatch();

  const { setKeywordToolLastUpdateDate } = useContext(context);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus !== "idle") return;

    setLoadingStatus("loading");

    getKeywordToolSettings()
      .then(({ updatedAt }) => {
        setLoadingStatus("succeeded");

        setKeywordToolLastUpdateDate(updatedAt);
      })
      .catch((error) => {
        showDevelopmentError({
          additionalTexts: ["KEYWORD TOOL SETTINGS FETCHING ERROR"],
          error,
        });

        setLoadingStatus("failed");
      });
  }, [loadingStatus, dispatch, setKeywordToolLastUpdateDate]);
};
