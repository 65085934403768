import { Privacy } from "../../../../../../../icons";

import styles from "./DashboardTitle.module.scss";

// Inner imports
import { Props } from "./types";

export const DashboardTitle = ({
  dashboardName,
  isPrivate,
}: Props): JSX.Element => (
  <div className={styles.dashboardTitle}>
    <div className={styles.dashboardTitleName}>{dashboardName}</div>
    {isPrivate && <Privacy />}
  </div>
);
