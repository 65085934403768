import { FC } from "react";
import { ResponsiveContainer, AreaChart, Area } from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

// Inner imports
import {
  CHART_WIDTH,
  CHART_HEIGHT,
  AREA_COLOR,
  CHART_MARGINS,
} from "./constants";

export const SmallLineChart: FC<CategoricalChartProps> = (props) => (
  <ResponsiveContainer width={CHART_WIDTH} height={CHART_HEIGHT}>
    <AreaChart {...props} margin={CHART_MARGINS}>
      <Area
        isAnimationActive={false}
        type="monotone"
        dataKey="volume"
        stroke={AREA_COLOR}
        fill={AREA_COLOR}
        fillOpacity={0.3}
        strokeWidth={2}
      />
    </AreaChart>
  </ResponsiveContainer>
);
