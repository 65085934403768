import DATE_RANGES from "./dateRanges";

export default [
  ...DATE_RANGES,
  {
    value: "overall",
    additionalValue: "allTime",
    label: "All time",
  },
] as const;
