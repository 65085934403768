import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./ReputationDistribution.module.scss";
import {
  WidgetButtonsBar,
  ErrorPlaceholder,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { useNameForDownloadFiles } from "../../../hooks";
import DATE_RANGES, {
  DATE_RANGES_TYPE,
  INITIAL_DATE_RANGE,
} from "../../../data/dateRanges";

// Inner imports
import ReputationDistributionBarChartView from "./ReputationDistributionBarChartView";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const ReputationDistribution: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // Brands ---->
  const [brandIndex, setBrandIndex] = useState<number>(0);

  useEffect(() => {
    setBrandIndex(0);
  }, [widgetData]);

  const brands = useMemo(
    () => widgetData?.map(({ brandName }) => brandName) || [],
    [widgetData],
  );

  const brandData = useMemo(
    () => widgetData?.[brandIndex]?.brandData.barChart,
    [widgetData, brandIndex],
  );
  // <---- Brands

  // Date Range ---->
  const dateRanges = useMemo(() => {
    return DATE_RANGES.filter(({ value }) => brandData?.[value]);
  }, [brandData]);

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) setCurrentDateRange(INITIAL_DATE_RANGE);
  }, [dateRanges]);
  // <---- Date Range

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (brandData) {
      charts.push({
        type: "barChart",
        chart: (
          <ReputationDistributionBarChartView
            data={brandData}
            currentDateRange={currentDateRange}
          />
        ),
      });
    }

    return charts;
  }, [brandData, currentDateRange]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Download name ---->
  const currentBrandName = useMemo(() => brands?.[brandIndex] || "", [
    brandIndex,
    brands,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          timePeriodSelector={{
            periods: dateRanges,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
          brandSelector={{
            className: styles.brandSelector,
            brands: brands,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => setBrandIndex(brandIndex),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              selectedView?.chart
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            reviewDataButtonProps={{ type: "reputation" }}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} />
      </div>
    </div>
  );
};
