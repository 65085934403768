import { FC, memo } from "react";
import cx from "classnames";

import styles from "./ButtonWithTransparentBackground.module.scss";
import * as icons from "../../../icons";

// Inner imports
import { Props } from "./types";

export const ButtonWithTransparentBackground: FC<Props> = memo(
  ({ className = "", text, icon, handleClick, isButtonDisabled }) => {
    if (!text && !icon) return null;

    const Icon = icon ? icons[icon] : null;

    return (
      <button
        className={cx(styles.button, className)}
        onClick={handleClick}
        disabled={isButtonDisabled}
      >
        {text && text}
        {Icon && <Icon />}
      </button>
    );
  },
);
