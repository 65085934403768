import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./CustomTooltip.module.scss";

// Inner imports
import { Payload, CustomTooltipProps, PreparedPayload } from "./types";
import { CATEGORY_TREND_LINE } from "../../constants";

export const CustomTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  formatDateHandler,
  yAxisUnit,
}) => {
  const { t } = useTranslation();

  const preparedPayload = useMemo<PreparedPayload>(
    () => preparePayload(payload),
    [payload],
  );

  if (!active) return null;

  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipTitle}>{formatDateHandler?.(label)}</div>
      {preparedPayload.map(({ dataKey, value, additionalValue }, i: number) => (
        <div className={styles.tooltipRow} key={i}>
          {t("line_chart_custom_tooltip", {
            key: dataKey,
            value,
            unit: yAxisUnit,
            additionalValue:
              additionalValue !== undefined ? `(${additionalValue})` : "",
          })}
        </div>
      ))}
    </div>
  );
};

function preparePayload(
  payload: CustomTooltipProps["payload"],
): PreparedPayload {
  if (!payload) return [];

  return payload.reduce((acc, data, index, payload) => {
    const { dataKey: _dataKey = "", payload: innerPayload, value } = data;

    if (checkInnerPayloadData(data, index, payload)) {
      const dataKey = String(_dataKey).replace(" (prediction)", "");
      const additionalValue = innerPayload?.additionalValues?.[dataKey];

      acc.push({ dataKey, value, additionalValue });
    }

    return acc;
  }, [] as PreparedPayload);
}

function checkInnerPayloadData(
  data: Payload[number],
  index: number,
  payload: Payload,
): boolean {
  const { dataKey: _dataKey = "" } = data;

  if (!_dataKey) return false;

  const dataKey = String(_dataKey);
  const isCategoryTrend = dataKey === CATEGORY_TREND_LINE.label;
  const isPredictionData = dataKey.includes(" (prediction)");
  const isDuplicatedData = payload
    .filter((_, k) => k !== index)
    .some((row) => dataKey.includes(String(row.dataKey || "")));

  return !isCategoryTrend && !(isPredictionData && isDuplicatedData);
}
