export const SubscriptionPlansWithStripe: {
  ids: string[];
  entities: {
    [subscriptionPlanId: string]: {
      invoiceLink?: string;
      stripeLink: string;

      // ------------------------------------------------------------------------------
      // DEPRECATED STRUCTURE ------> 18.10.22 [DEV-71127] task
      // stripeLinks: {
      //   currency: string;
      //   link?: string;
      // }[];
    };
  };
} = {
  ids: [
    "OEnDKCoK4o0oNjZ403pM",
    "VOtme9ORvY01bUQlOgEO",
    "h31YufFISOYiRthDwMcq",

    // ------------------------------------------------------------------------------
    // DEPRECATED SPs ------> 18.10.22 [DEV-71127] task
    // "xJFLM8saMc6cHqg1QevA",
    // "4YTo5X7AxcvPC5C7xGCT",
    // "wiCOBuDs0RhRe2l2tCem",
    // "9uIkMxMEwySGZvFarZYp",
    // "94YniOD9TXpYNUpPglwl",
  ],
  entities: {
    // Starter
    OEnDKCoK4o0oNjZ403pM: {
      invoiceLink: "https://www.mytelescope.io/upgrade-plan",
      stripeLink: process.env.REACT_APP_STRIPE_STARTER!,
    },
    // Pro 2022
    VOtme9ORvY01bUQlOgEO: {
      invoiceLink: "https://www.mytelescope.io/upgrade-plan",
      stripeLink: process.env.REACT_APP_STRIPE_PRO_2022!,
    },
    // Pro
    h31YufFISOYiRthDwMcq: {
      invoiceLink: "https://www.mytelescope.io/upgrade-plan",
      stripeLink: process.env.REACT_APP_STRIPE_PRO!,
    },
    // ------------------------------------------------------------------------------
    // DEPRECATED SPs ------> 18.10.22 [DEV-71127] task
    // All Marketing Metrics
    // xJFLM8saMc6cHqg1QevA: {
    //   stripeLinks: [
    //     {
    //       currency: "USD",
    //       link: process.env.REACT_APP_STRIPE_ALL_MARKETING,
    //     },
    //   ],
    // },
    // // All Marketing + PR Metrics
    // "4YTo5X7AxcvPC5C7xGCT": {
    //   stripeLinks: [
    //     {
    //       currency: "USD",
    //       link: process.env.REACT_APP_STRIPE_ALL_MARKETING_PR_METRICS,
    //     },
    //   ],
    // },
    // // Pro
    // wiCOBuDs0RhRe2l2tCem: {
    //   invoiceLink: "https://www.mytelescope.io/request-ultimate-invoice",
    //   stripeLinks: [
    //     {
    //       currency: "USD",
    //       link: process.env.REACT_APP_STRIPE_PRO_USD,
    //     },
    //     {
    //       currency: "SEK",
    //       link: process.env.REACT_APP_STRIPE_PRO_SEK,
    //     },
    //     {
    //       currency: "GBP",
    //       link: process.env.REACT_APP_STRIPE_PRO_GBP,
    //     },
    //   ],
    // },
    // // Premium
    // "9uIkMxMEwySGZvFarZYp": {
    //   invoiceLink: "https://mytelescope.io/upgrade-marketing-pr-metrics",
    //   stripeLinks: [
    //     {
    //       currency: "USD",
    //       link: process.env.REACT_APP_STRIPE_PREMIUM_USD,
    //     },
    //     {
    //       currency: "SEK",
    //       link: process.env.REACT_APP_STRIPE_PREMIUM_SEK,
    //     },
    //     {
    //       currency: "GBP",
    //       link: process.env.REACT_APP_STRIPE_PREMIUM_GBP,
    //     },
    //   ],
    // },
    // // Ultimate
    // "94YniOD9TXpYNUpPglwl": {
    //   invoiceLink: "https://mytelescope.io/request-ultimate-invoice",
    //   stripeLinks: [
    //     {
    //       currency: "USD",
    //       link: process.env.REACT_APP_STRIPE_ULTIMATE_USD,
    //     },
    //     {
    //       currency: "SEK",
    //       link: process.env.REACT_APP_STRIPE_ULTIMATE_SEK,
    //     },
    //     {
    //       currency: "GBP",
    //       link: process.env.REACT_APP_STRIPE_ULTIMATE_GBP,
    //     },
    //   ],
    // },
  },
};
