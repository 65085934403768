const AUTH = {
  login: "/login",
  signUp: "/signup",
  passwordReset: "/password-reset",
  verifyEmailPage: "/verify/:userId",
  verifyToken: "/verify/:userId/:token",
  termsAndCondition: "/terms-and-conditions-of-use",
} as const;

const PRESENTATION_PAGE = {
  presentationFirstPage: "/presentation/:dashboardId",
  presentationMain: "/presentation/:dashboardId/:widgetSlug",
  presentationLastPage: "/presentation-end/:dashboardId",
} as const;

const DASHBOARD_ROUTS = {
  dashboardsHomePage: "/dashboards",
  createDashboardPage: "/dashboards/new",
  editDashboardPage: "/dashboards/edit/:dashboardId",
  dashboardPage: "/dashboards/:dashboardId",
  singleWidgetPage: "/dashboards/:dashboardId/:widgetSlug",
  dashboardsSearch: "/dashboards/search",
} as const;

const EVENTS_ROUTES = {
  eventsHome: "/events",
  eventsNew: "/events/new",
  eventsEdit: "/events/edit/:timePlotId",
  eventsSearch: "/events/search",
  eventsSummary: "/events/summary/:timePlotId",
} as const;

const TRACKERS_ROUTS = {
  trackersHomePage: "/trackers",
  createTracker: "/trackers/new",
  editTracker: "/trackers/:id",
  trackersSearch: "/trackers/search",
} as const;

const SIDEBAR_ROUTS = {
  settingsPage: "/settings",
  createTrackerPage: TRACKERS_ROUTS.createTracker,
  trackersPage: TRACKERS_ROUTS.trackersHomePage,
  dashboardsPage: DASHBOARD_ROUTS.dashboardsHomePage,
  eventsPage: EVENTS_ROUTES.eventsHome,
  enterpriseDashboardsPage: "/enterprise-dashboards",
  pricingPage: "/settings#subscriptionPlans",
  demoBookingPage: "/demo-booking",
  faqPage: "/faq",
  logout: "/logout",
} as const;

const WELCOME_PAGE = {
  welcomePage: "/welcome",
} as const;

const FUNNEL_REVIEW_DATA = {
  funnelHomePage: "/funnel-review-data",
  funnelPage: "/funnel-review-data/:searchId",
} as const;

export const ROUTS = {
  ...SIDEBAR_ROUTS,
  ...DASHBOARD_ROUTS,
  ...TRACKERS_ROUTS,
  ...EVENTS_ROUTES,
  ...AUTH,
  ...PRESENTATION_PAGE,
  ...WELCOME_PAGE,
  ...FUNNEL_REVIEW_DATA,
} as const;
