import { ReactElement, memo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { Label } from "../../components";
import styles from "./CheckboxSection.module.scss";

type Props = {
  className?: string;
  options: Option[];
  activeValue?: string;
  userCallBack: (value: string) => void;
};

export const CheckboxSection = memo(
  ({
    options,
    className = "",
    activeValue,
    userCallBack,
  }: Props): ReactElement => {
    const { t } = useTranslation();
    return (
      <div className={styles.checkboxSection}>
        <div className={cx(styles.checkboxItemList, className)}>
          {options.map(({ value, label }) => (
            <div className={styles.checkboxItem} key={value}>
              <input
                className={styles.checkboxInput}
                type="checkbox"
                checked={value === activeValue}
                onChange={() => userCallBack(value)}
              />
              <Label leftText={t(label)} />
            </div>
          ))}
        </div>
      </div>
    );
  },
);
