import { useMemo } from "react";

import { isNumber } from "src/utils";
import {
  ChartDataItem,
  ChartItem,
  LineChartProps,
  ChartTimePlotter,
} from "../types";
import {
  getEnhancedDataWithTimePlotters,
  getFilteredDataWithTimePlotter,
} from "../helpers";

// Inner imports
import { useGetTimePlotters } from "./index";

type UsePrepareLineChartData = {
  data: ChartDataItem[];
  searchId: string;
  timePlotterId?: string;
  viewMode?: LineChartProps["viewMode"];
};

type UsePrepareLineChartDataResult = {
  data: ChartItem[];
  timePlotters: ChartTimePlotter[];
  timePlotter?: ChartTimePlotter;
};

const LINE_CHART_DATA_MIN_LENGTH = 2 as const;

export const usePrepareLineChartData = ({
  data: _data,
  searchId,
  timePlotterId,
  viewMode = "widgetPage",
}: UsePrepareLineChartData): UsePrepareLineChartDataResult => {
  const data = useMemo<ChartItem[]>(
    () =>
      _data
        .reduce((acc, dataPoint) => {
          try {
            const { date } = dataPoint;
            const time = Date.parse(date);

            if (isNumber(time))
              acc.push({
                ...dataPoint,
                time,
              });
          } catch (err) {
            console.error(err);
          }

          return acc;
        }, [] as ChartItem[])
        .sort((a, b) => a.time - b.time),
    [_data],
  );

  const { timePlotter, timePlotters } = useGetTimePlotters({
    data,
    searchId,
    timePlotterId,
  });

  const filteredData = useMemo<ChartItem[]>(() => {
    switch (viewMode) {
      case "widgetPreview":
        return data;

      case "timePlotterSummary": {
        if (!timePlotter) return data;

        const enhancedData = getEnhancedDataWithTimePlotters(data, [
          timePlotter,
        ]);

        const filteredData = getFilteredDataWithTimePlotter(
          enhancedData,
          timePlotter,
        );

        if (filteredData.length < LINE_CHART_DATA_MIN_LENGTH) return [];

        return filteredData;
      }

      case "widgetPage":
      default:
        return getEnhancedDataWithTimePlotters(data, timePlotters);
    }
  }, [data, timePlotter, timePlotters, viewMode]);

  return {
    data: filteredData,
    timePlotters,
    timePlotter,
  };
};
