import firebase from "firebase/app";
import axios from "../../axios";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

export type PostUserPayload = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  companyName: string;
  marketCode: string;
  workFor?: string;
  env?: string;
  primaryFocus?: string[];
  whiteLabelProject?: string;
  isFirstSearchCreated?: false;
};

export const postUser = (payload: PostUserPayload) =>
  axios.post(`users-service-${urlInsertion}-api/new`, payload);

export const getUserEmailsByIds = (ids: string[]) =>
  axios.get(`users-service-${urlInsertion}-api/emails`, {
    params: {
      ids,
    },
  });

export const updateUserLastActiveAt = async (userId: string) => {
  try {
    await firebase
      .firestore()
      .collection("userProfiles")
      .doc(userId)
      .update({ "_timestamps.lastActiveAt": new Date().toISOString() });
  } catch (error) {
    console.dir(error);
  }
};

export const sendVerifyEmail = async (userId: string) => {
  const envProperty = process.env.REACT_APP_ENV
    ? {
        env: process.env.REACT_APP_ENV,
      }
    : {};

  const whiteLabelProjectProperty = process.env.REACT_APP_PROJECT
    ? { whiteLabelProject: process.env.REACT_APP_PROJECT }
    : {};

  try {
    await axios.post(`email-service-${urlInsertion}-api/verify-email`, {
      userId,
      ...envProperty,
      ...whiteLabelProjectProperty,
    });
  } catch (err) {
    throw err;
  }
};

export const verifyEmailByJWTToken = async (token: string): Promise<string> => {
  let userLogInToken = "";
  try {
    const response = await axios.post(
      `users-service-${urlInsertion}-api/verify-email`,
      {
        token,
      },
    );

    const gotUserLogInToken = response.data?.token;
    if (typeof gotUserLogInToken === "string") {
      userLogInToken = gotUserLogInToken;
    }
  } catch (err) {
    const { error } = err?.response?.data || {};
    throw error || "Error";
  }

  return userLogInToken;
};

export const getUserDataByEmail = async (email: string) => {
  const db = firebase.firestore();
  const docRef = db
    .collection("userProfiles")
    .where("email", "==", email)
    .get();

  const foundUsers = await docRef;

  return foundUsers.docs.map((userData) => userData.data())[0];
};

export const getUserIdByEmail = async (email: string) => {
  const db = firebase.firestore();
  const docRef = db
    .collection("userProfiles")
    .where("email", "==", email)
    .get();

  const foundUsers = await docRef;

  return foundUsers.docs.map((userData) => userData.id)[0];
};

export const getUserById = async (userId: string) => {
  const user = await firebase
    .firestore()
    .collection("userProfiles")
    .doc(userId)
    .get();

  return user.data();
};
