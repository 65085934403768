import { TFunction } from "i18next";

import { TIME_PLOTTER_LIMIT_PER_COMPANY, WEEK_IN_DAYS } from "./constants";
import { NewTimePlot } from "../../types";
import { ROUTS } from "../../../../../data/routs";
import { formatToDayMonthYearDate } from "../../../../../utils";

export const checkTimePlotterBeforeAction = (
  timePlot: Partial<Store.TimePlot>,
  timePlots: Store.TimePlot[],
): string | null => {
  const existingTimePlotIds = timePlots.map(({ id }) => id);

  const allTimePlots = [...new Set([...existingTimePlotIds, timePlot.id])];

  if (allTimePlots.length > TIME_PLOTTER_LIMIT_PER_COMPANY) {
    return "event_form_max_count_error";
  }

  const sameNameTimePlot = timePlots.find(
    ({ name, id }) => name === timePlot.name && id !== timePlot.id,
  );

  if (sameNameTimePlot) {
    return "event_form_same_name_error";
  }

  const sameDateRangeAndSearchTimePlot = timePlots.find(
    ({ id, dateFrom, dateTo, searchId }) =>
      id !== timePlot.id &&
      dateFrom === timePlot.dateFrom &&
      dateTo === timePlot.dateTo &&
      searchId === timePlot.searchId,
  );

  if (sameDateRangeAndSearchTimePlot) {
    return "tp_form_same_date_range_error";
  }

  return null;
};

export const getTimePlotterDateToPlaceholder = () => {
  const dateNow = new Date();
  const dateOneWeekAhead = dateNow.getDate() + WEEK_IN_DAYS;
  const resultDate = dateNow.setDate(dateOneWeekAhead);

  return formatToDayMonthYearDate(resultDate);
};

export const trimTimePlotTextFields = (
  timePlot: Store.TimePlot | NewTimePlot,
): void =>
  Object.keys(timePlot).forEach((field) => {
    const timePlotField = field as keyof NewTimePlot;
    const value = timePlot[timePlotField];

    if (typeof value !== "string") return;

    timePlot[timePlotField] = value.trim();
  });

export const clearEmptyFieldsFromObject = (
  object: NewTimePlot,
): Partial<NewTimePlot> =>
  Object.fromEntries(Object.entries(object).filter(([, v]) => v !== ""));

export const getBreadcrumbs = (
  title: string,
  backToBreadcrumb: ActionBar.BreadcrumbOption | undefined,
  t: TFunction,
): ActionBar.BreadcrumbOption[] => {
  const breadcrumbs: ActionBar.BreadcrumbOption[] = [{ title }];

  if (backToBreadcrumb) {
    breadcrumbs.unshift(backToBreadcrumb);
  } else {
    breadcrumbs.unshift({
      title: t("events_title"),
      linkOptions: {
        href: ROUTS.eventsHome,
      },
    });
  }

  return breadcrumbs;
};
