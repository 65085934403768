import TagManager from "react-gtm-module";
import type { DataLayerArgs } from "react-gtm-module";

export const sendDataToGTM = (
  event: string,
  dataLayerObject: DataLayerArgs["dataLayer"] = {},
) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...dataLayerObject,
    },
  });
};
