const yougovBrands = [
  {
    id: "500665",
    name: "3  ",
    sector: 533,
    active: true,
  },
  {
    id: "500666",
    name: "Boxer",
    sector: 533,
    active: true,
  },
  {
    id: "500667",
    name: "Bredbandsbolaget",
    sector: 533,
    active: false,
  },
  {
    id: "500668",
    name: "Canal Digital",
    sector: 533,
    active: true,
  },
  {
    id: "500669",
    name: "Com Hem",
    sector: 533,
    active: true,
  },
  {
    id: "500674",
    name: "Halebop",
    sector: 533,
    active: true,
  },
  {
    id: "500677",
    name: "Skype",
    sector: 533,
    active: true,
  },
  {
    id: "500682",
    name: "Telenor",
    sector: 533,
    active: true,
  },
  {
    id: "500685",
    name: "Viasat",
    sector: 533,
    active: true,
  },
  {
    id: "500687",
    name: "Apple",
    sector: 533,
    active: true,
  },
  {
    id: "500696",
    name: "LG",
    sector: 533,
    active: true,
  },
  {
    id: "500697",
    name: "Microsoft",
    sector: 533,
    active: true,
  },
  {
    id: "500701",
    name: "Nokia",
    sector: 533,
    active: true,
  },
  {
    id: "500709",
    name: "Samsung",
    sector: 533,
    active: true,
  },
  {
    id: "500712",
    name: "Sony Ericsson",
    sector: 533,
    active: false,
  },
  {
    id: "501196",
    name: "Telia",
    sector: 533,
    active: true,
  },
  {
    id: "601607",
    name: "Tele2",
    sector: 533,
    active: true,
  },
  {
    id: "601608",
    name: "Comviq",
    sector: 533,
    active: true,
  },
  {
    id: "601609",
    name: "HTC",
    sector: 533,
    active: true,
  },
  {
    id: "601633",
    name: "Android",
    sector: 533,
    active: true,
  },
  {
    id: "603314",
    name: "Panasonic",
    sector: 533,
    active: true,
  },
  {
    id: "603315",
    name: "Nikon",
    sector: 533,
    active: true,
  },
  {
    id: "603316",
    name: "Canon",
    sector: 533,
    active: true,
  },
  {
    id: "603317",
    name: "Sony",
    sector: 533,
    active: true,
  },
  {
    id: "604177",
    name: "Huawei",
    sector: 533,
    active: true,
  },
  {
    id: "1001330",
    name: "Hallon",
    sector: 533,
    active: true,
  },
  {
    id: "1003698",
    name: "Sonos",
    sector: 533,
    active: true,
  },
  {
    id: "1003699",
    name: "Beats by Dr. Dre",
    sector: 533,
    active: true,
  },
  {
    id: "1003700",
    name: "Samsung SHAPE",
    sector: 533,
    active: true,
  },
  {
    id: "1003701",
    name: "Bose",
    sector: 533,
    active: true,
  },
  {
    id: "500716",
    name: "Axa",
    sector: 535,
    active: false,
  },
  {
    id: "500717",
    name: "Barilla",
    sector: 535,
    active: false,
  },
  {
    id: "500721",
    name: "Ekströms",
    sector: 535,
    active: true,
  },
  {
    id: "500722",
    name: "Felix",
    sector: 535,
    active: true,
  },
  {
    id: "500723",
    name: "Findus",
    sector: 535,
    active: true,
  },
  {
    id: "500725",
    name: "Heinz",
    sector: 535,
    active: true,
  },
  {
    id: "500728",
    name: "Kellogg's",
    sector: 535,
    active: true,
  },
  {
    id: "500729",
    name: "Kungsörnen",
    sector: 535,
    active: true,
  },
  {
    id: "500730",
    name: "Lithells",
    sector: 535,
    active: true,
  },
  {
    id: "500732",
    name: "Nestlé",
    sector: 535,
    active: false,
  },
  {
    id: "500734",
    name: "Pågen",
    sector: 535,
    active: true,
  },
  {
    id: "500735",
    name: "Scan",
    sector: 535,
    active: true,
  },
  {
    id: "500737",
    name: "Skogaholm",
    sector: 535,
    active: true,
  },
  {
    id: "500738",
    name: "Wasabröd",
    sector: 535,
    active: true,
  },
  {
    id: "500741",
    name: "Yes",
    sector: 535,
    active: false,
  },
  {
    id: "500743",
    name: "Jokk",
    sector: 535,
    active: false,
  },
  {
    id: "501227",
    name: "FUN Light",
    sector: 535,
    active: true,
  },
  {
    id: "501297",
    name: "BOB",
    sector: 535,
    active: true,
  },
  {
    id: "501298",
    name: "Dafgårds",
    sector: 535,
    active: true,
  },
  {
    id: "501301",
    name: "Svenska Spel",
    sector: 535,
    active: false,
  },
  {
    id: "501369",
    name: "God Morgon",
    sector: 535,
    active: true,
  },
  {
    id: "501370",
    name: "Bravo",
    sector: 535,
    active: true,
  },
  {
    id: "501371",
    name: "Tropicana",
    sector: 535,
    active: true,
  },
  {
    id: "601428",
    name: "Paulúns",
    sector: 535,
    active: true,
  },
  {
    id: "603241",
    name: "Tulip",
    sector: 535,
    active: true,
  },
  {
    id: "603242",
    name: "GØL",
    sector: 535,
    active: true,
  },
  {
    id: "603324",
    name: "Pärsons",
    sector: 535,
    active: true,
  },
  {
    id: "603325",
    name: "Lönneberga",
    sector: 535,
    active: true,
  },
  {
    id: "604077",
    name: "Rynkeby",
    sector: 535,
    active: true,
  },
  {
    id: "1000984",
    name: "Frödinge",
    sector: 535,
    active: true,
  },
  {
    id: "1000985",
    name: "Kalles",
    sector: 535,
    active: true,
  },
  {
    id: "1002827",
    name: "Pastejköket",
    sector: 535,
    active: true,
  },
  {
    id: "1002828",
    name: "Ridderheims",
    sector: 535,
    active: true,
  },
  {
    id: "1002829",
    name: "Charkdelikatesser",
    sector: 535,
    active: false,
  },
  {
    id: "1004575",
    name: "Arboga",
    sector: 535,
    active: false,
  },
  {
    id: "1009831",
    name: "Anamma",
    sector: 535,
    active: true,
  },
  {
    id: "1013828",
    name: "Quorn",
    sector: 535,
    active: true,
  },
  {
    id: "1013829",
    name: "VegMe",
    sector: 535,
    active: true,
  },
  {
    id: "1013830",
    name: "Oumph",
    sector: 535,
    active: true,
  },
  {
    id: "1013831",
    name: "Hälsans Kök",
    sector: 535,
    active: true,
  },
  {
    id: "500715",
    name: "Ariel",
    sector: 536,
    active: true,
  },
  {
    id: "500724",
    name: "Grumme",
    sector: 536,
    active: true,
  },
  {
    id: "500740",
    name: "Via",
    sector: 536,
    active: true,
  },
  {
    id: "500744",
    name: "Abba",
    sector: 536,
    active: true,
  },
  {
    id: "500745",
    name: "Arla",
    sector: 536,
    active: true,
  },
  {
    id: "500748",
    name: "Carlsberg",
    sector: 536,
    active: true,
  },
  {
    id: "500749",
    name: "Cloetta",
    sector: 536,
    active: true,
  },
  {
    id: "500750",
    name: "Coca-Cola",
    sector: 536,
    active: true,
  },
  {
    id: "500752",
    name: "Fazer",
    sector: 536,
    active: true,
  },
  {
    id: "500754",
    name: "Gevalia",
    sector: 536,
    active: true,
  },
  {
    id: "500756",
    name: "Läkerol",
    sector: 536,
    active: true,
  },
  {
    id: "500758",
    name: "Löfbergs Lila",
    sector: 536,
    active: true,
  },
  {
    id: "500759",
    name: "Malaco",
    sector: 536,
    active: true,
  },
  {
    id: "500760",
    name: "Marabou",
    sector: 536,
    active: true,
  },
  {
    id: "500761",
    name: "Norrmejerier",
    sector: 536,
    active: true,
  },
  {
    id: "500763",
    name: "Pepsi",
    sector: 536,
    active: true,
  },
  {
    id: "500766",
    name: "Risifrutti",
    sector: 536,
    active: true,
  },
  {
    id: "500768",
    name: "Spendrups",
    sector: 536,
    active: false,
  },
  {
    id: "500770",
    name: "Valio",
    sector: 536,
    active: true,
  },
  {
    id: "500771",
    name: "Zoégas",
    sector: 536,
    active: true,
  },
  {
    id: "501228",
    name: "Skånemejerier",
    sector: 536,
    active: true,
  },
  {
    id: "501275",
    name: "ProViva",
    sector: 536,
    active: true,
  },
  {
    id: "501299",
    name: "Billy's",
    sector: 536,
    active: true,
  },
  {
    id: "501300",
    name: "Grandiosa",
    sector: 536,
    active: true,
  },
  {
    id: "603908",
    name: "Hjordnära",
    sector: 536,
    active: false,
  },
  {
    id: "604024",
    name: "Västerbottensost",
    sector: 536,
    active: true,
  },
  {
    id: "604025",
    name: "Allerum ost",
    sector: 536,
    active: true,
  },
  {
    id: "604026",
    name: "Falbygdens Ost",
    sector: 536,
    active: true,
  },
  {
    id: "604027",
    name: "Kvibille ost",
    sector: 536,
    active: true,
  },
  {
    id: "604028",
    name: "Wernersson ost",
    sector: 536,
    active: false,
  },
  {
    id: "604623",
    name: "Coca-Cola Light",
    sector: 536,
    active: true,
  },
  {
    id: "604624",
    name: "Pepsi Max",
    sector: 536,
    active: true,
  },
  {
    id: "1002830",
    name: "Yes",
    sector: 536,
    active: true,
  },
  {
    id: "1008108",
    name: "Oatly",
    sector: 536,
    active: true,
  },
  {
    id: "1011542",
    name: "Norrlandsguld",
    sector: 536,
    active: true,
  },
  {
    id: "500773",
    name: "Alecta",
    sector: 537,
    active: true,
  },
  {
    id: "500774",
    name: "American Express",
    sector: 537,
    active: true,
  },
  {
    id: "500775",
    name: "AMF",
    sector: 537,
    active: true,
  },
  {
    id: "500780",
    name: "Eurocard",
    sector: 537,
    active: true,
  },
  {
    id: "500781",
    name: "Folksam",
    sector: 537,
    active: false,
  },
  {
    id: "500783",
    name: "Handelsbanken",
    sector: 537,
    active: true,
  },
  {
    id: "500784",
    name: "ICA Banken",
    sector: 537,
    active: true,
  },
  {
    id: "500785",
    name: "If",
    sector: 537,
    active: false,
  },
  {
    id: "500786",
    name: "Ikano Bank",
    sector: 537,
    active: true,
  },
  {
    id: "500788",
    name: "Länsförsäkringar",
    sector: 537,
    active: false,
  },
  {
    id: "500789",
    name: "Mastercard",
    sector: 537,
    active: true,
  },
  {
    id: "500791",
    name: "Nordea",
    sector: 537,
    active: true,
  },
  {
    id: "500794",
    name: "SEB",
    sector: 537,
    active: true,
  },
  {
    id: "500795",
    name: "Skandia",
    sector: 537,
    active: true,
  },
  {
    id: "500796",
    name: "Skandiabanken",
    sector: 537,
    active: false,
  },
  {
    id: "500797",
    name: "SPP",
    sector: 537,
    active: true,
  },
  {
    id: "500798",
    name: "Swedbank",
    sector: 537,
    active: true,
  },
  {
    id: "500799",
    name: "Trygg-Hansa",
    sector: 537,
    active: false,
  },
  {
    id: "500801",
    name: "VISA",
    sector: 537,
    active: true,
  },
  {
    id: "501248",
    name: "FOREX Bank",
    sector: 537,
    active: true,
  },
  {
    id: "501249",
    name: "X-change",
    sector: 537,
    active: false,
  },
  {
    id: "501250",
    name: "SBAB Bank",
    sector: 537,
    active: true,
  },
  {
    id: "501276",
    name: "Gjensidige",
    sector: 537,
    active: false,
  },
  {
    id: "501302",
    name: "Posten",
    sector: 537,
    active: false,
  },
  {
    id: "501365",
    name: "Danske Bank",
    sector: 537,
    active: true,
  },
  {
    id: "601659",
    name: "Moderna Försäkringar",
    sector: 537,
    active: false,
  },
  {
    id: "603246",
    name: "KPA Pension",
    sector: 537,
    active: true,
  },
  {
    id: "603328",
    name: "Nordnet",
    sector: 537,
    active: true,
  },
  {
    id: "603329",
    name: "Avanza",
    sector: 537,
    active: true,
  },
  {
    id: "604178",
    name: "Riksgälden",
    sector: 537,
    active: false,
  },
  {
    id: "1001388",
    name: "Bank Norwegian",
    sector: 537,
    active: true,
  },
  {
    id: "1001389",
    name: "Marginalen Bank",
    sector: 537,
    active: true,
  },
  {
    id: "1001390",
    name: "Resurs Bank",
    sector: 537,
    active: true,
  },
  {
    id: "1001391",
    name: "Santander Consumer Bank",
    sector: 537,
    active: true,
  },
  {
    id: "1006807",
    name: "Masterpass",
    sector: 537,
    active: true,
  },
  {
    id: "1006808",
    name: "PayPal",
    sector: 537,
    active: true,
  },
  {
    id: "1006809",
    name: "Klarna",
    sector: 537,
    active: true,
  },
  {
    id: "1006810",
    name: "Swish",
    sector: 537,
    active: true,
  },
  {
    id: "1011605",
    name: "Lendo",
    sector: 537,
    active: true,
  },
  {
    id: "1011606",
    name: "Bynk",
    sector: 537,
    active: true,
  },
  {
    id: "1011607",
    name: "Zmarta",
    sector: 537,
    active: true,
  },
  {
    id: "500806",
    name: "Apollo",
    sector: 538,
    active: true,
  },
  {
    id: "500808",
    name: "British Airways",
    sector: 538,
    active: true,
  },
  {
    id: "500809",
    name: "Finnair",
    sector: 538,
    active: true,
  },
  {
    id: "500811",
    name: "Fritidsresor",
    sector: 538,
    active: false,
  },
  {
    id: "500814",
    name: "Lufthansa",
    sector: 538,
    active: true,
  },
  {
    id: "500815",
    name: "Malmö Aviation",
    sector: 538,
    active: false,
  },
  {
    id: "500816",
    name: "Norwegian",
    sector: 538,
    active: true,
  },
  {
    id: "500818",
    name: "Ryanair",
    sector: 538,
    active: true,
  },
  {
    id: "500819",
    name: "SAS",
    sector: 538,
    active: true,
  },
  {
    id: "500821",
    name: "SJ",
    sector: 538,
    active: true,
  },
  {
    id: "500824",
    name: "Stena Line",
    sector: 538,
    active: true,
  },
  {
    id: "500827",
    name: "Ticket",
    sector: 538,
    active: true,
  },
  {
    id: "500829",
    name: "Viking Line",
    sector: 538,
    active: true,
  },
  {
    id: "500830",
    name: "Ving",
    sector: 538,
    active: true,
  },
  {
    id: "501253",
    name: "Tallink Silja Line",
    sector: 538,
    active: true,
  },
  {
    id: "501254",
    name: "Nordic Choice Hotels",
    sector: 538,
    active: true,
  },
  {
    id: "501255",
    name: "Radisson Blu",
    sector: 538,
    active: true,
  },
  {
    id: "501256",
    name: "Scandic",
    sector: 538,
    active: true,
  },
  {
    id: "501309",
    name: "Resia",
    sector: 538,
    active: true,
  },
  {
    id: "501311",
    name: "Travellink",
    sector: 538,
    active: true,
  },
  {
    id: "501312",
    name: "MrJet",
    sector: 538,
    active: true,
  },
  {
    id: "501313",
    name: "First Hotels",
    sector: 538,
    active: true,
  },
  {
    id: "604469",
    name: "Arlanda",
    sector: 538,
    active: true,
  },
  {
    id: "604470",
    name: "Sturup",
    sector: 538,
    active: false,
  },
  {
    id: "604471",
    name: "Københavns Lufthavne",
    sector: 538,
    active: false,
  },
  {
    id: "1001306",
    name: "Øresundsbron",
    sector: 538,
    active: false,
  },
  {
    id: "1001392",
    name: "Posten",
    sector: 538,
    active: false,
  },
  {
    id: "1005202",
    name: "Flygbolaget BRA",
    sector: 538,
    active: true,
  },
  {
    id: "1005415",
    name: "Solresor",
    sector: 538,
    active: true,
  },
  {
    id: "1005416",
    name: "Trivago",
    sector: 538,
    active: true,
  },
  {
    id: "1005417",
    name: "Momondo",
    sector: 538,
    active: true,
  },
  {
    id: "1005418",
    name: "Expedia",
    sector: 538,
    active: true,
  },
  {
    id: "1005419",
    name: "eBeach",
    sector: 538,
    active: true,
  },
  {
    id: "1005420",
    name: "airbnb",
    sector: 538,
    active: true,
  },
  {
    id: "1006089",
    name: "TUI",
    sector: 538,
    active: true,
  },
  {
    id: "1006172",
    name: "Thomas Cook Airlines",
    sector: 538,
    active: true,
  },
  {
    id: "1007894",
    name: "Uber",
    sector: 538,
    active: true,
  },
  {
    id: "1007895",
    name: "Taxi Stockholm",
    sector: 538,
    active: true,
  },
  {
    id: "1007896",
    name: "MyTaxi",
    sector: 538,
    active: true,
  },
  {
    id: "1008618",
    name: "BIG Travel",
    sector: 538,
    active: true,
  },
  {
    id: "500832",
    name: "E.ON",
    sector: 539,
    active: true,
  },
  {
    id: "500833",
    name: "Fortum",
    sector: 539,
    active: true,
  },
  {
    id: "500834",
    name: "GodEl",
    sector: 539,
    active: true,
  },
  {
    id: "500836",
    name: "INGO",
    sector: 539,
    active: true,
  },
  {
    id: "500841",
    name: "St1",
    sector: 539,
    active: true,
  },
  {
    id: "500842",
    name: "OKQ8",
    sector: 539,
    active: true,
  },
  {
    id: "500843",
    name: "Preem",
    sector: 539,
    active: true,
  },
  {
    id: "500845",
    name: "Shell",
    sector: 539,
    active: true,
  },
  {
    id: "500846",
    name: "Statoil",
    sector: 539,
    active: false,
  },
  {
    id: "500848",
    name: "Storuman Energi",
    sector: 539,
    active: true,
  },
  {
    id: "500849",
    name: "Telge Energi",
    sector: 539,
    active: true,
  },
  {
    id: "500851",
    name: "Vattenfall",
    sector: 539,
    active: true,
  },
  {
    id: "500852",
    name: "Öresundskraft",
    sector: 539,
    active: true,
  },
  {
    id: "501239",
    name: "Energibolaget i Sverige",
    sector: 539,
    active: false,
  },
  {
    id: "501241",
    name: "Skellefteå Kraft",
    sector: 539,
    active: true,
  },
  {
    id: "501242",
    name: "Göteborg Energi",
    sector: 539,
    active: true,
  },
  {
    id: "603776",
    name: "Bixia",
    sector: 539,
    active: true,
  },
  {
    id: "603777",
    name: "Luleå energi",
    sector: 539,
    active: true,
  },
  {
    id: "603778",
    name: "Sundsvalls energi",
    sector: 539,
    active: true,
  },
  {
    id: "603779",
    name: "Mälarenergi",
    sector: 539,
    active: true,
  },
  {
    id: "603780",
    name: "Jämtkraft",
    sector: 539,
    active: true,
  },
  {
    id: "1000787",
    name: "Kraftringen",
    sector: 539,
    active: true,
  },
  {
    id: "1005363",
    name: "SverigesEnergi",
    sector: 539,
    active: true,
  },
  {
    id: "1005494",
    name: "Enkla Elbolaget",
    sector: 539,
    active: true,
  },
  {
    id: "1005495",
    name: "Dalakraft",
    sector: 539,
    active: true,
  },
  {
    id: "1005541",
    name: "Circle K",
    sector: 539,
    active: true,
  },
  {
    id: "1007113",
    name: "Ellevio",
    sector: 539,
    active: true,
  },
  {
    id: "500854",
    name: "Alfa Romeo",
    sector: 540,
    active: true,
  },
  {
    id: "500855",
    name: "Audi",
    sector: 540,
    active: true,
  },
  {
    id: "500856",
    name: "BMW",
    sector: 540,
    active: true,
  },
  {
    id: "500857",
    name: "Chevrolet",
    sector: 540,
    active: true,
  },
  {
    id: "500858",
    name: "Chrysler",
    sector: 540,
    active: true,
  },
  {
    id: "500859",
    name: "Citroën",
    sector: 540,
    active: true,
  },
  {
    id: "500860",
    name: "Fiat",
    sector: 540,
    active: true,
  },
  {
    id: "500861",
    name: "Ford",
    sector: 540,
    active: true,
  },
  {
    id: "500862",
    name: "Honda",
    sector: 540,
    active: true,
  },
  {
    id: "500863",
    name: "Hyundai",
    sector: 540,
    active: true,
  },
  {
    id: "500864",
    name: "KIA",
    sector: 540,
    active: true,
  },
  {
    id: "500865",
    name: "Lexus",
    sector: 540,
    active: true,
  },
  {
    id: "500866",
    name: "Mazda",
    sector: 540,
    active: true,
  },
  {
    id: "500867",
    name: "Mercedes-Benz",
    sector: 540,
    active: true,
  },
  {
    id: "500868",
    name: "Mitsubishi",
    sector: 540,
    active: true,
  },
  {
    id: "500869",
    name: "Nissan",
    sector: 540,
    active: true,
  },
  {
    id: "500870",
    name: "Opel",
    sector: 540,
    active: true,
  },
  {
    id: "500871",
    name: "Peugeot",
    sector: 540,
    active: true,
  },
  {
    id: "500872",
    name: "Renault",
    sector: 540,
    active: true,
  },
  {
    id: "500874",
    name: "Seat",
    sector: 540,
    active: true,
  },
  {
    id: "500875",
    name: "Skoda",
    sector: 540,
    active: true,
  },
  {
    id: "500876",
    name: "Subaru",
    sector: 540,
    active: true,
  },
  {
    id: "500877",
    name: "Suzuki",
    sector: 540,
    active: true,
  },
  {
    id: "500878",
    name: "Toyota",
    sector: 540,
    active: true,
  },
  {
    id: "500879",
    name: "Volkswagen",
    sector: 540,
    active: true,
  },
  {
    id: "500880",
    name: "Volvo",
    sector: 540,
    active: true,
  },
  {
    id: "604191",
    name: "Mini",
    sector: 540,
    active: true,
  },
  {
    id: "1003903",
    name: "Infiniti",
    sector: 540,
    active: true,
  },
  {
    id: "1005206",
    name: "Dacia",
    sector: 540,
    active: true,
  },
  {
    id: "1005207",
    name: "Tesla",
    sector: 540,
    active: true,
  },
  {
    id: "264029",
    name: "City Gross",
    sector: 541,
    active: true,
  },
  {
    id: "500881",
    name: "Burger King",
    sector: 541,
    active: true,
  },
  {
    id: "500882",
    name: "Clas Ohlson",
    sector: 541,
    active: false,
  },
  {
    id: "500883",
    name: "Coop",
    sector: 541,
    active: true,
  },
  {
    id: "500885",
    name: "Cervera",
    sector: 541,
    active: false,
  },
  {
    id: "500888",
    name: "H & M",
    sector: 541,
    active: true,
  },
  {
    id: "500889",
    name: "Hemköp",
    sector: 541,
    active: true,
  },
  {
    id: "500890",
    name: "Hemtex",
    sector: 541,
    active: true,
  },
  {
    id: "500891",
    name: "ICA",
    sector: 541,
    active: true,
  },
  {
    id: "500892",
    name: "IKEA",
    sector: 541,
    active: true,
  },
  {
    id: "500894",
    name: "Intersport",
    sector: 541,
    active: true,
  },
  {
    id: "500899",
    name: "Max",
    sector: 541,
    active: true,
  },
  {
    id: "500900",
    name: "McDonald's",
    sector: 541,
    active: true,
  },
  {
    id: "500901",
    name: "Mio",
    sector: 541,
    active: true,
  },
  {
    id: "500904",
    name: "Stadium",
    sector: 541,
    active: true,
  },
  {
    id: "500906",
    name: "Systembolaget",
    sector: 541,
    active: true,
  },
  {
    id: "500909",
    name: "Willys",
    sector: 541,
    active: true,
  },
  {
    id: "500910",
    name: "Åhléns",
    sector: 541,
    active: true,
  },
  {
    id: "501264",
    name: "7-Eleven",
    sector: 541,
    active: true,
  },
  {
    id: "501265",
    name: "Apoteket",
    sector: 541,
    active: true,
  },
  {
    id: "501266",
    name: "Sibylla",
    sector: 541,
    active: true,
  },
  {
    id: "501284",
    name: "Expert",
    sector: 541,
    active: false,
  },
  {
    id: "501286",
    name: "Elgiganten",
    sector: 541,
    active: true,
  },
  {
    id: "501287",
    name: "SIBA",
    sector: 541,
    active: false,
  },
  {
    id: "501304",
    name: "Media Markt",
    sector: 541,
    active: true,
  },
  {
    id: "501305",
    name: "JYSK",
    sector: 541,
    active: false,
  },
  {
    id: "501306",
    name: "Pressbyrån",
    sector: 541,
    active: true,
  },
  {
    id: "501307",
    name: "NetOnNet",
    sector: 541,
    active: true,
  },
  {
    id: "604014",
    name: "Pølsemannnen",
    sector: 541,
    active: true,
  },
  {
    id: "1001329",
    name: "Lidl",
    sector: 541,
    active: true,
  },
  {
    id: "1002831",
    name: "Team Sportia",
    sector: 541,
    active: true,
  },
  {
    id: "1002832",
    name: "XXL",
    sector: 541,
    active: true,
  },
  {
    id: "1002833",
    name: "Sportamore",
    sector: 541,
    active: true,
  },
  {
    id: "1003730",
    name: "Sova",
    sector: 541,
    active: true,
  },
  {
    id: "1004184",
    name: "Netto",
    sector: 541,
    active: true,
  },
  {
    id: "500911",
    name: "Aftonbladet",
    sector: 542,
    active: true,
  },
  {
    id: "500912",
    name: "blocket",
    sector: 542,
    active: true,
  },
  {
    id: "500915",
    name: "Dagens Industri",
    sector: 542,
    active: true,
  },
  {
    id: "500917",
    name: "Dagens Nyheter",
    sector: 542,
    active: true,
  },
  {
    id: "500918",
    name: "Eniro",
    sector: 542,
    active: true,
  },
  {
    id: "500919",
    name: "Expressen",
    sector: 542,
    active: true,
  },
  {
    id: "500920",
    name: "Google",
    sector: 542,
    active: true,
  },
  {
    id: "500921",
    name: "Göteborgs-Posten",
    sector: 542,
    active: true,
  },
  {
    id: "500922",
    name: "Hitta.se",
    sector: 542,
    active: true,
  },
  {
    id: "500923",
    name: "Kanal 5",
    sector: 542,
    active: true,
  },
  {
    id: "500924",
    name: "Metro",
    sector: 542,
    active: true,
  },
  {
    id: "500926",
    name: "MSN",
    sector: 542,
    active: false,
  },
  {
    id: "500931",
    name: "Sveriges Radio",
    sector: 542,
    active: true,
  },
  {
    id: "500932",
    name: "Svenska Dagbladet",
    sector: 542,
    active: true,
  },
  {
    id: "500936",
    name: "TV3",
    sector: 542,
    active: true,
  },
  {
    id: "500937",
    name: "TV4",
    sector: 542,
    active: true,
  },
  {
    id: "501244",
    name: "Facebook",
    sector: 542,
    active: true,
  },
  {
    id: "601619",
    name: "Filmstaden",
    sector: 542,
    active: true,
  },
  {
    id: "601620",
    name: "Sveriges Television",
    sector: 542,
    active: true,
  },
  {
    id: "601621",
    name: "Sydsvenskan",
    sector: 542,
    active: true,
  },
  {
    id: "601622",
    name: "Tradera",
    sector: 542,
    active: true,
  },
  {
    id: "601623",
    name: "Veckans Affärer",
    sector: 542,
    active: false,
  },
  {
    id: "603363",
    name: "PriceRunner",
    sector: 542,
    active: true,
  },
  {
    id: "603690",
    name: "Google+",
    sector: 542,
    active: false,
  },
  {
    id: "604069",
    name: "Monster",
    sector: 542,
    active: false,
  },
  {
    id: "604070",
    name: "careerbuilder",
    sector: 542,
    active: false,
  },
  {
    id: "604071",
    name: "StepStone",
    sector: 542,
    active: false,
  },
  {
    id: "604086",
    name: "Metrojobb",
    sector: 542,
    active: false,
  },
  {
    id: "604087",
    name: "Blocket Jobb",
    sector: 542,
    active: false,
  },
  {
    id: "604432",
    name: "Vi i Villa",
    sector: 542,
    active: false,
  },
  {
    id: "1003729",
    name: "Disney",
    sector: 542,
    active: true,
  },
  {
    id: "1009316",
    name: "Spotify",
    sector: 542,
    active: true,
  },
  {
    id: "1009317",
    name: "YouTube",
    sector: 542,
    active: true,
  },
  {
    id: "1009318",
    name: "HBO Nordic",
    sector: 542,
    active: true,
  },
  {
    id: "1009319",
    name: "ViaPlay",
    sector: 542,
    active: true,
  },
  {
    id: "1009320",
    name: "Netflix",
    sector: 542,
    active: true,
  },
  {
    id: "1009698",
    name: "Indeed",
    sector: 542,
    active: true,
  },
  {
    id: "1009699",
    name: "Jobbsafari",
    sector: 542,
    active: true,
  },
  {
    id: "1009700",
    name: "LinkedIn",
    sector: 542,
    active: true,
  },
  {
    id: "1013385",
    name: "Hemnet",
    sector: 542,
    active: true,
  },
  {
    id: "601582",
    name: "Landsorganisationen i Sverige, LO",
    sector: 657,
    active: false,
  },
  {
    id: "601583",
    name: "Tjänstemännens Centralorganisation, TCO",
    sector: 657,
    active: false,
  },
  {
    id: "601584",
    name: "Sveriges Akademikers Centralorganisation, SACO",
    sector: 657,
    active: false,
  },
  {
    id: "601585",
    name: "Unionen",
    sector: 657,
    active: false,
  },
  {
    id: "601586",
    name: "Hyresgästföreningen",
    sector: 657,
    active: false,
  },
  {
    id: "601587",
    name: "Röda Korset",
    sector: 657,
    active: false,
  },
  {
    id: "601588",
    name: "Svenskt Näringsliv",
    sector: 657,
    active: false,
  },
  {
    id: "601589",
    name: "Företagarna",
    sector: 657,
    active: false,
  },
  {
    id: "601590",
    name: "Arbetarnas Bildningsförbund, ABF",
    sector: 657,
    active: false,
  },
  {
    id: "601591",
    name: "Pensionärernas Riks Organisation, PRO",
    sector: 657,
    active: false,
  },
  {
    id: "601592",
    name: "SOS Barnbyar",
    sector: 657,
    active: false,
  },
  {
    id: "601593",
    name: "Rädda Barnen",
    sector: 657,
    active: false,
  },
  {
    id: "601594",
    name: "Världsnaturfonden",
    sector: 657,
    active: false,
  },
  {
    id: "601595",
    name: "Svenska Kyrkan",
    sector: 657,
    active: false,
  },
  {
    id: "601596",
    name: "Friskis & Svettis",
    sector: 657,
    active: false,
  },
  {
    id: "601597",
    name: "Cancerfonden",
    sector: 657,
    active: false,
  },
  {
    id: "601598",
    name: "Sveriges Pensionärsförbund",
    sector: 657,
    active: false,
  },
  {
    id: "601599",
    name: "UNICEF",
    sector: 657,
    active: false,
  },
  {
    id: "601600",
    name: "Aktiespararna",
    sector: 657,
    active: false,
  },
  {
    id: "601601",
    name: "Plan",
    sector: 657,
    active: false,
  },
  {
    id: "601602",
    name: "Naturskyddsföreningen",
    sector: 657,
    active: false,
  },
  {
    id: "601603",
    name: "Svenska Turistföreningen",
    sector: 657,
    active: false,
  },
  {
    id: "601604",
    name: "IOGT-NTO",
    sector: 657,
    active: false,
  },
  {
    id: "601605",
    name: "Villaägarna",
    sector: 657,
    active: false,
  },
  {
    id: "601606",
    name: "Kooperativa Förbundet, KF",
    sector: 657,
    active: false,
  },
  {
    id: "604192",
    name: "Michelin",
    sector: 750,
    active: false,
  },
  {
    id: "604193",
    name: "Goodyear",
    sector: 750,
    active: false,
  },
  {
    id: "604194",
    name: "Bridgestone",
    sector: 750,
    active: false,
  },
  {
    id: "604195",
    name: "Nokian",
    sector: 750,
    active: false,
  },
  {
    id: "604196",
    name: "Continental",
    sector: 750,
    active: false,
  },
  {
    id: "604197",
    name: "Pirelli",
    sector: 750,
    active: false,
  },
  {
    id: "604198",
    name: "Däckia",
    sector: 750,
    active: false,
  },
  {
    id: "604199",
    name: "Däckteam",
    sector: 750,
    active: false,
  },
  {
    id: "604200",
    name: "Vianor",
    sector: 750,
    active: false,
  },
  {
    id: "604201",
    name: "Euromaster",
    sector: 750,
    active: false,
  },
  {
    id: "604202",
    name: "BytHjul.com",
    sector: 750,
    active: false,
  },
  {
    id: "1004177",
    name: "Däckpartner",
    sector: 750,
    active: false,
  },
  {
    id: "1004178",
    name: "FirstStop",
    sector: 750,
    active: false,
  },
  {
    id: "604299",
    name: "Listerine",
    sector: 758,
    active: false,
  },
  {
    id: "604300",
    name: "SB12",
    sector: 758,
    active: false,
  },
  {
    id: "604301",
    name: "Colgate Multiprotection Mouthwash",
    sector: 758,
    active: false,
  },
  {
    id: "604302",
    name: "Retardex",
    sector: 758,
    active: false,
  },
  {
    id: "604303",
    name: "Flux",
    sector: 758,
    active: false,
  },
  {
    id: "1013562",
    name: "Adidas",
    sector: 1490,
    active: true,
  },
  {
    id: "1013563",
    name: "Asos",
    sector: 1490,
    active: true,
  },
  {
    id: "1013564",
    name: "Bikbok",
    sector: 1490,
    active: true,
  },
  {
    id: "1013565",
    name: "Boozt",
    sector: 1490,
    active: true,
  },
  {
    id: "1013566",
    name: "Brothers",
    sector: 1490,
    active: true,
  },
  {
    id: "1013567",
    name: "Carlings",
    sector: 1490,
    active: true,
  },
  {
    id: "1013568",
    name: "Cubus",
    sector: 1490,
    active: true,
  },
  {
    id: "1013569",
    name: "Dressmann",
    sector: 1490,
    active: true,
  },
  {
    id: "1013570",
    name: "Ellos",
    sector: 1490,
    active: true,
  },
  {
    id: "1013571",
    name: "Gina Tricot",
    sector: 1490,
    active: true,
  },
  {
    id: "1013572",
    name: "H&M",
    sector: 1490,
    active: true,
  },
  {
    id: "1013573",
    name: "Intersport",
    sector: 1490,
    active: true,
  },
  {
    id: "1013574",
    name: "Jack & Jones",
    sector: 1490,
    active: true,
  },
  {
    id: "1013575",
    name: "Kappahl",
    sector: 1490,
    active: true,
  },
  {
    id: "1013576",
    name: "Lindex",
    sector: 1490,
    active: true,
  },
  {
    id: "1013577",
    name: "Mango",
    sector: 1490,
    active: true,
  },
  {
    id: "1013578",
    name: "MQ",
    sector: 1490,
    active: true,
  },
  {
    id: "1013579",
    name: "Nelly",
    sector: 1490,
    active: true,
  },
  {
    id: "1013580",
    name: "Nike",
    sector: 1490,
    active: true,
  },
  {
    id: "1013581",
    name: "Puma",
    sector: 1490,
    active: true,
  },
  {
    id: "1013582",
    name: "Sportamore",
    sector: 1490,
    active: true,
  },
  {
    id: "1013583",
    name: "Stadium",
    sector: 1490,
    active: true,
  },
  {
    id: "1013584",
    name: "Stayhard",
    sector: 1490,
    active: true,
  },
  {
    id: "1013585",
    name: "Uniqlo",
    sector: 1490,
    active: true,
  },
  {
    id: "1013586",
    name: "Vero Moda",
    sector: 1490,
    active: true,
  },
  {
    id: "1013587",
    name: "XXL",
    sector: 1490,
    active: true,
  },
  {
    id: "1013588",
    name: "Zalando",
    sector: 1490,
    active: true,
  },
  {
    id: "1013589",
    name: "Zara",
    sector: 1490,
    active: true,
  },
  {
    id: "1013590",
    name: "Åhlens",
    sector: 1490,
    active: true,
  },
];

export default yougovBrands;
