import { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./List.module.scss";
import { Triangle, Edit } from "../../../icons";
import { capitalizeString } from "../../../utils";

type General = {
  wordButtonText: string;
  onWordButtonTextClick: (wordData: SentimentDrivers.Value) => void;
};

type Props = General & {
  className?: string;
  label?: string;
  data: SentimentDrivers.Value[];
  labelClassName?: string;
};

const Word = ({
  isCanClickOnText,
  wordData,
  wordButtonText,
  onWordButtonTextClick,
}: General & {
  wordData: SentimentDrivers.Value;
  isCanClickOnText: boolean;
}) => {
  const { word, relatedWords } = wordData;
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div key={word} className={styles.listItem}>
      <div className={styles.word}>
        <button
          className={cx(styles.openCloseButton, styles[isOpen ? "opened" : ""])}
          onClick={() => setIsOpen((state) => !state)}
        >
          <Triangle size={10} /> {capitalizeString(word)}
        </button>
        {isCanClickOnText && (
          <button onClick={() => onWordButtonTextClick(wordData)}>
            {wordButtonText}
          </button>
        )}
      </div>
      {isOpen && (
        <div className={styles.relatedWords}>
          <small> {t("w_sd_widget_word_associated_list")}</small>
          {relatedWords
            .map((relatedWord) => capitalizeString(relatedWord))
            .join(", ")}
        </div>
      )}
    </div>
  );
};

export const List = ({
  className,
  label,
  data,
  wordButtonText,
  onWordButtonTextClick,
  labelClassName,
}: Props) => {
  const [isCanClickOnText, setIsCanClickOnText] = useState(false);

  return (
    <div className={cx(styles.list, className)}>
      <div className={cx(styles.label, labelClassName)}>
        <span>{label}</span>
        <button
          className={styles.editButton}
          onClick={() => setIsCanClickOnText((state) => !state)}
        >
          <Edit width={16} />
        </button>
      </div>
      <div className={styles.content}>
        {data.map((wordData) => (
          <Word
            isCanClickOnText={isCanClickOnText}
            key={wordData.word}
            wordData={wordData}
            wordButtonText={wordButtonText}
            onWordButtonTextClick={onWordButtonTextClick}
          />
        ))}
      </div>
    </div>
  );
};
