import { useEffect, useState, useCallback, useMemo } from "react";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import { set, update } from "lodash";
import { BiSearch as Search } from "react-icons/bi";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./ESGReviewData.module.scss";
import { Input, Label, SearchTable, KeyWordButton } from "../..";
import { capitalizeString, sendDataToGTM } from "../../../utils";
import { ReviewDataHeader } from "../ReviewDataHeader";
import { texts } from "../ReviewsDataTexts";
import {
  fetchESGArticles,
  recalculateESGWidget,
  writeESGExcludedData,
  getChangedParameters,
  setParameters,
  subscribeOnWidgetCalculationStatus,
  setWidgetStatus,
} from "../../../store/api";
import { showToastNotification } from "../../ToastNotification/utils";

import {
  updateSearchESGExcludedData,
  formateESGWidgetData,
  setWidget,
} from "../../../store/actions";
import { useWidgetCalculatingStatus } from "../../../hooks/useWidgetCalculatingStatus";
import { getPlug } from "../utils";

// Inner imports
import {
  WIDGET_ID,
  ABOUT_THIS,
  INFLUENCE_ON_WIDGETS,
  ARTICLES_LIMIT,
  ARTICLES_PARAMETERS,
  REVIEW_DATA_CATEGORY,
} from "./constants";
import type {
  Props,
  Article,
  Brand,
  Data,
  LoadingStatus,
  ExcludedElement,
  BrandParameters,
  ChangedParameters,
} from "./types";

export const ESGReviewData = ({ initialSearch, openedFromSideBar }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const widget = useSelector(
    ({ widgetsData }: RootState) => widgetsData.entities[WIDGET_ID],
  );

  const TITLE =
    t(texts[WIDGET_ID]?.customWidgetName || "") ||
    t(widget?.placeHolderOnUi || "") ||
    "";

  const notifyAboutError = () =>
    showToastNotification({ type: "error", text: t("request_error") });

  const [data, updateData] = useImmer<Data>({});
  const [initialData, updateInitialData] = useImmer<Data>({});
  const [
    changedParameters,
    updateChangedParameters,
  ] = useImmer<ChangedParameters>({});

  const [
    initialParameters,
    updateInitialParameters,
  ] = useImmer<ChangedParameters>({});

  const searches = useSelector(({ searches }: Store.State) => searches);
  const widgets = useSelector(({ widgets }: Store.State) => widgets);

  const [currentSearchId, setCurrentSearchId] = useState(initialSearch.id);
  const [currentBrand, setCurrentBrand] = useState<
    | Store.Brand
    | {
        id?: string;
        name?: string;
      }
  >({});
  const [filterValue, setFilterValue] = useState("");
  const [articlesParameter, setArticlesParameter] = useState<
    typeof ARTICLES_PARAMETERS[number]
  >("all");
  const [canLoadMoreArticles, setCanLoadMoreArticles] = useState(false);
  const [articlesOffset, setArticlesOffset] = useState(0);
  const [status, setStatus] = useState<LoadingStatus>("loading");
  const [
    loadMoreArticlesStatus,
    setLoadMoreArticlesStatus,
  ] = useState<LoadingStatus | null>(null);
  const [switchWarningIsShown, setSwitchWarningIsShown] = useState(false);
  const [
    parametersChangedModalShown,
    setParametersChangedModalShown,
  ] = useState(false);

  const [isWidgetCalculating] = useWidgetCalculatingStatus(
    currentSearchId,
    INFLUENCE_ON_WIDGETS,
  );

  const brands = useMemo(
    () =>
      searches.find(({ id }) => id === currentSearchId)?.brandsTableNames || [],
    [searches, currentSearchId],
  );

  const parameterDropdownOptions = ARTICLES_PARAMETERS.filter(
    (option) => option !== "all",
  ).map((filteredOption) => ({
    label: capitalizeString(filteredOption),
    value: filteredOption,
  }));

  const initialSearchParameters = initialParameters[currentSearchId] || {};
  const changedSearchParameters = changedParameters[currentSearchId] || {};

  const dataHaveNotChanged = checkIfInitialStateEqualCurrentState(
    initialData,
    data,
  );

  const parametersHaveNotChanged = checkIfInitialStateEqualCurrentState(
    initialSearchParameters,
    changedSearchParameters,
  );

  const currentBrandId = currentBrand.id;
  const currentBrandName = currentBrand.name;

  const brandArticles = data[currentSearchId]?.[currentBrandId || ""]?.articles;

  const currentSearchSuggestions = searches.find(
    (el) => el.id === currentSearchId,
  )?.suggestions[currentBrandName || ""];

  const initialExcludedESGArticles = useMemo(() => {
    if (!currentSearchSuggestions?.excludedESGArticles) return [];
    return currentSearchSuggestions.excludedESGArticles;
  }, [currentSearchSuggestions]);

  const initialExcludedESGSources = useMemo(() => {
    if (!currentSearchSuggestions?.excludedESGSources) return [];
    return currentSearchSuggestions.excludedESGSources;
  }, [currentSearchSuggestions]);

  const excludedArticles = useMemo(() => {
    return (
      data[currentSearchId]?.[currentBrandId || ""]?.excludedArticles || []
    );
  }, [currentBrandId, currentSearchId, data]);

  const excludedSources = useMemo(() => {
    return data[currentSearchId]?.[currentBrandId || ""]?.excludedSources || [];
  }, [currentBrandId, currentSearchId, data]);

  const searchChangedParameters = useMemo(() => {
    return changedParameters[currentSearchId]?.[currentBrandId || ""] || {};
  }, [changedParameters, currentBrandId, currentSearchId]);

  const currentBrandArticles = useMemo(() => {
    if (!brandArticles || !brandArticles.length) return [];

    const articlesWithoutExcluded = filterArticles(
      brandArticles,
    ).removeExcluded(excludedArticles);

    const filteredBySource = filterArticles(
      articlesWithoutExcluded,
    ).removeNotSuitableBySources(excludedSources);

    const filteredByParameter = filterArticles(
      filteredBySource,
    ).removeNotSuitableByParameter(
      searchChangedParameters,
      articlesParameter as string,
    );

    const filteredByText = filterArticles(
      filteredByParameter,
    ).removeNotSuitableByFilterInput(filterValue);

    return filteredByText;
  }, [
    articlesParameter,
    brandArticles,
    excludedArticles,
    excludedSources,
    filterValue,
    searchChangedParameters,
  ]);

  const getChangedParameter = useCallback(
    (parameterId: string) => {
      return changedParameters[currentSearchId]?.[currentBrandId || ""]?.[
        parameterId
      ]?.parameter;
    },
    [changedParameters, currentBrandId, currentSearchId],
  );

  const mutatedArticles = useMemo(() => {
    return currentBrandArticles.map((filteredArticle) => {
      const { id, title, source, parameter, date, url } = filteredArticle;

      return {
        id,
        source,
        link: url,
        date: new Date(date).getTime(),
        title: `<a href=${url} target='_blank' rel="noreferrer">${title}</a>`,
        parameter: getChangedParameter(id) ?? parameter,
      };
    });
  }, [currentBrandArticles, getChangedParameter]);

  const currentBrandSources = useMemo(() => {
    const allSources =
      data[currentSearchId]?.[currentBrandId || ""]?.sources || [];

    return allSources.filter(
      (source) =>
        !excludedSources.some(
          (excludedSource) =>
            source.toLocaleLowerCase() === excludedSource.toLocaleLowerCase(),
        ),
    );
  }, [currentBrandId, currentSearchId, data, excludedSources]);

  const noData =
    !initialExcludedESGArticles.length &&
    !initialExcludedESGSources.length &&
    !currentBrandArticles.length &&
    !currentBrandSources.length &&
    !excludedArticles.length &&
    !excludedSources.length;

  const fetchArticles = useCallback(
    async (brandId: string, offset?: number) => {
      const mappedExcludedESGArticles = initialExcludedESGArticles.map(
        (el) => el.id,
      );

      const articles = await fetchESGArticles({
        searchId: currentSearchId,
        brandId,
        excludedArticles: mappedExcludedESGArticles,
        excludedSources: initialExcludedESGSources,
        limit: ARTICLES_LIMIT,
        offset: offset ?? 0,
      });

      if (!checkIfResponseStatusIsSuccessful(articles?.status))
        throw Error(`Something gone wrong. Status code: ${articles?.status}`);

      return articles?.data || [];
    },
    [currentSearchId, initialExcludedESGArticles, initialExcludedESGSources],
  );

  const getSourcesFromArticles = useCallback((articles: Article[]) => {
    const formatted = articles.map((el) => el.source);
    const uniqueSources = new Set(formatted);

    return Array.from(uniqueSources);
  }, []);

  const getArticles = useCallback(
    async (
      currentBrandId: string,
      currentSearchId: string,
      initialExcludedESGArticles: Article[],
      initialExcludedESGSources: string[],
      offset?: number,
      loadMore?: boolean,
    ) => {
      try {
        loadMore ? setLoadMoreArticlesStatus("loading") : setStatus("loading");

        const oldArticles =
          data?.[currentSearchId]?.[currentBrandId]?.articles || [];

        const gotArticles = await fetchArticles(currentBrandId, offset);
        const gotSources = getSourcesFromArticles([
          ...oldArticles,
          ...gotArticles,
        ]);

        setCanLoadMoreArticles(gotArticles.length >= ARTICLES_LIMIT);

        const formatData = (brandData: Brand) => {
          return {
            articles: [...(brandData?.articles || []), ...gotArticles],
            excludedArticles: [
              ...(brandData?.excludedArticles || []),
              ...(loadMore ? [] : initialExcludedESGArticles),
            ],
            sources: gotSources,
            excludedSources: [
              ...(brandData?.excludedSources || []),
              ...(loadMore ? [] : initialExcludedESGSources),
            ],
          };
        };

        updateData((diff) =>
          update(
            diff,
            [currentSearchId, currentBrandId],
            (brandData: Brand) => ({
              ...brandData,
              ...formatData(brandData),
            }),
          ),
        );

        updateInitialData((diff) =>
          update(diff, [currentSearchId, currentBrandId], (brandData) => ({
            ...brandData,
            ...formatData(brandData),
          })),
        );

        loadMore
          ? setLoadMoreArticlesStatus("succeeded")
          : setStatus("succeeded");
      } catch (err) {
        console.error(err);
        loadMore ? setLoadMoreArticlesStatus("failed") : setStatus("failed");
      }
    },
    [
      data,
      fetchArticles,
      getSourcesFromArticles,
      updateData,
      updateInitialData,
    ],
  );

  const getParameters = useCallback(
    async (currentSearchId) => {
      const parameters = await getChangedParameters(currentSearchId);

      updateChangedParameters((diff) => {
        set(diff, [currentSearchId], parameters);
      });

      updateInitialParameters((diff) => {
        set(diff, [currentSearchId], parameters);
      });
    },
    [updateChangedParameters, updateInitialParameters],
  );

  useEffect(() => {
    // GET AND WRITE A ARTICLES TO DATA
    if (!currentBrandId) return setStatus("loading");

    if (brandArticles) return setStatus("succeeded");

    (async () => {
      await getArticles(
        currentBrandId,
        currentSearchId,
        initialExcludedESGArticles,
        initialExcludedESGSources,
      );
    })();
  }, [
    currentBrandId,
    currentSearchId,
    brandArticles,
    initialExcludedESGArticles,
    initialExcludedESGSources,
    getArticles,
  ]);

  useEffect(() => {
    if (changedParameters[currentSearchId]) return;
    (async () => {
      await getParameters(currentSearchId);
    })();
  }, [changedParameters, currentSearchId, getParameters]);

  const [temporarySearchId, setTemporarySearchId] = useState("");

  function searchChangeHandler(searchId: string, forceChange: boolean = false) {
    if (currentSearchId === searchId) return notifyAboutError();

    if (!dataHaveNotChanged && !forceChange) {
      setTemporarySearchId(searchId);
      return setSwitchWarningIsShown(true);
    }

    if (forceChange) {
      setSwitchWarningIsShown(false);
    }

    setCurrentBrand({});
    setCurrentSearchId(searchId);

    const initialSearchData = initialData?.[currentSearchId] || {};
    updateData((diff) => set(diff, [currentSearchId], initialSearchData));
    updateInitialData((diff) =>
      set(diff, [currentSearchId], initialSearchData),
    );
    updateChangedParameters(() => initialParameters);
  }

  const brandChangeHandler = useCallback(
    (brand: Store.Brand) => {
      setCurrentBrand(brand);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSearchId],
  );

  function resetHandler() {
    updateData(() => initialData);
    updateChangedParameters(() => initialParameters);
  }

  function addArticleToExcluded(id: string) {
    const article = currentBrandArticles.find(
      ({ id: articleId }) => articleId === id,
    );

    if (!article || !currentBrandId) return;

    updateData((diff) =>
      update(
        diff,
        [currentSearchId, currentBrandId, "excludedArticles"],
        (excludedArticles) => [...excludedArticles, article],
      ),
    );
  }

  function removeArticleFromExcluded({ id }: ExcludedElement) {
    if (!currentBrandId) return notifyAboutError();

    const filteredArticle = excludedArticles.filter(
      ({ id: excludedArticleId }) => excludedArticleId !== id,
    );

    updateData((diff) =>
      set(
        diff,
        [currentSearchId, currentBrandId, "excludedArticles"],
        filteredArticle,
      ),
    );
  }

  function addSourceToExcluded({ id }: ExcludedElement) {
    if (!currentBrandId) return notifyAboutError();

    updateData((diff) =>
      update(
        diff,
        [currentSearchId, currentBrandId, "excludedSources"],
        (excludedSources) => [...excludedSources, id],
      ),
    );
  }

  function removeSourceFromExcluded({ id }: ExcludedElement) {
    if (!currentBrandId) return notifyAboutError();

    const filteredSources = excludedSources.filter(
      (excludedSource) => excludedSource !== id,
    );

    updateData((diff) =>
      set(
        diff,
        [currentSearchId, currentBrandId, "excludedSources"],
        filteredSources,
      ),
    );
  }

  function parameterChangeHandler(value: string, fieldId?: string) {
    if (!fieldId || !currentBrandId) return notifyAboutError();

    const gotArticlesData = data[currentSearchId]?.[
      currentBrandId || ""
    ]?.articles.find(({ id }) => id === fieldId);

    const { sentence = "", parameter = "" } = gotArticlesData || {};

    const checkIfParameterAddedToInitialData =
      initialParameters[currentSearchId]?.[currentBrandId]?.[fieldId] !==
      undefined;

    if (!checkIfParameterAddedToInitialData) {
      updateInitialParameters((diff) => {
        update(diff, [currentSearchId, currentBrandId], (parameters) => {
          const gotSentence = parameters?.[fieldId]?.sentence ?? sentence;
          return {
            ...(parameters || {}),
            [fieldId]: {
              parameter,
              sentence: gotSentence,
            },
          };
        });
      });
    }

    updateChangedParameters((diff) => {
      update(diff, [currentSearchId, currentBrandId], (parameters) => {
        const gotSentence = parameters?.[fieldId]?.sentence ?? sentence;
        return {
          ...(parameters || {}),
          [fieldId]: {
            parameter: value,
            sentence: gotSentence,
          },
        };
      });
    });
  }

  async function loadMoreArticlesHandler() {
    if (!currentBrandId || !currentSearchId) return notifyAboutError();

    (async () => {
      await getArticles(
        currentBrandId,
        currentSearchId,
        initialExcludedESGArticles,
        initialExcludedESGSources,
        articlesOffset + ARTICLES_LIMIT,
        true,
      );
    })();

    setArticlesOffset((offset) => offset + ARTICLES_LIMIT);
  }

  async function onExcludeClicked() {
    if (!currentSearchId || !currentBrandId || !currentBrandName)
      return notifyAboutError();

    sendDataToGTM("UserUsesReviewData", {
      reviewDataCategory: REVIEW_DATA_CATEGORY,
    });

    if (!parametersHaveNotChanged) {
      // User have changed parameters
      setStatus("loading");

      try {
        const searchParameters = removeParametersThatWasChangedBack(
          initialSearchParameters,
          changedSearchParameters,
        );

        await setParameters(currentSearchId, searchParameters);

        updateInitialParameters(() => changedParameters);
        setStatus("succeeded");
      } catch (err) {
        console.error(err);
        setStatus("failed");
      }

      //  Parameters changed only. So we changed them in FireBase but didn't start recalculation/
      if (dataHaveNotChanged) return setParametersChangedModalShown(true);
    }

    // Main data changed
    await setWidgetStatus(currentSearchId, INFLUENCE_ON_WIDGETS);

    const brand = data?.[currentSearchId] || {};

    for (let brandId in brand) {
      const _thisBrand = brand[brandId];
      const brandName = brands.find(({ id }) => id === brandId)?.name;

      const articles = _thisBrand?.excludedArticles || [];
      const sources =
        _thisBrand?.excludedSources.map((el) => el.toLocaleLowerCase()) || [];

      if (brandName) {
        try {
          await writeESGExcludedData(
            currentSearchId,
            brandId,
            articles,
            sources,
          );
          dispatch(
            updateSearchESGExcludedData(
              currentSearchId,
              brandName,
              articles,
              sources,
            ),
          );
        } catch (err) {
          setStatus("failed");
        }
      }
    }

    const dataAfterRecalculating = recalculateESGWidget(currentSearchId);

    const stopSearchWatcher = subscribeOnWidgetCalculationStatus(
      currentSearchId,
      INFLUENCE_ON_WIDGETS,
      (isAllWidgetsFinishCalculation) => {
        if (isAllWidgetsFinishCalculation) {
          showToastNotification({
            type: "success",
            text: t("rd_recalculation_ended_message_new"),
          });
          dataAfterRecalculating.then((gotData) => {
            const ESGData = gotData.data;
            const ifIndexExist = checkIfDashboardHasESGWidget(
              currentSearchId,
              "result-esg-index",
            );
            const ifSentimentExist = checkIfDashboardHasESGWidget(
              currentSearchId,
              "result-esg-sentiment",
            );
            const ifDistributionExist = checkIfDashboardHasESGWidget(
              currentSearchId,
              "result-esg-distribution",
            );
            ESGData.forEach((ESGWidget: any) => {
              const { index, sentiment, distribution } = ESGWidget || {};
              if (index && ifIndexExist) {
                const esgIndex = formateESGWidgetData(brands).index(index);
                dispatch(
                  setWidget("result-esg-index", esgIndex, currentSearchId),
                );
              }
              if (sentiment && ifSentimentExist) {
                const esgSentiment = formateESGWidgetData(brands).sentiment(
                  sentiment,
                );
                dispatch(
                  setWidget(
                    "result-esg-sentiment",
                    esgSentiment,
                    currentSearchId,
                  ),
                );
              }
              if (distribution && ifDistributionExist) {
                const esgDistribution = formateESGWidgetData(
                  brands,
                ).distribution(distribution);
                dispatch(
                  setWidget(
                    "result-esg-distribution",
                    esgDistribution,
                    currentSearchId,
                  ),
                );
              }
            });
            updateInitialData(() => data);
            stopSearchWatcher();
          });
        }
      },
    );
  }

  // Helpers
  function filterArticles(articles: Article[]) {
    function removeExcluded(excludedArticles: Article[]) {
      return articles.filter(
        ({ id: elementsId }) =>
          !excludedArticles.some(
            ({ id: excludedElementsId }) => excludedElementsId === elementsId,
          ),
      );
    }

    function removeNotSuitableByParameter(
      changedParameters: BrandParameters[number],
      parameter: string,
    ) {
      return articles.filter(({ id, parameter: articlesParameter }) => {
        const _parameter =
          changedParameters[id]?.parameter || articlesParameter;
        if (parameter === "all") return true;
        return _parameter === parameter;
      });
    }

    function removeNotSuitableBySources(sources: string[]) {
      return articles.filter(
        (article) =>
          !sources.some(
            (source) =>
              source.toLocaleLowerCase() === article.source.toLocaleLowerCase(),
          ),
      );
    }

    function removeNotSuitableByFilterInput(filterValue: string) {
      return articles.filter(({ title }) =>
        title.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()),
      );
    }

    return {
      removeExcluded,
      removeNotSuitableByParameter,
      removeNotSuitableBySources,
      removeNotSuitableByFilterInput,
    };
  }

  function checkIfDashboardHasESGWidget(
    searchId: string,
    widgetType:
      | "result-esg-index"
      | "result-esg-sentiment"
      | "result-esg-distribution",
  ) {
    const widget = widgets[searchId]?.[widgetType];
    return Boolean(widget);
  }

  function checkIfResponseStatusIsSuccessful(statusCode?: number) {
    // All 200(Successful responses) status codes will return true;
    return String(statusCode)[0] === "2";
  }

  function checkIfInitialStateEqualCurrentState(
    initialState: any,
    currentState: any,
  ) {
    return JSON.stringify(initialState) === JSON.stringify(currentState);
  }

  function removeParametersThatWasChangedBack(
    initialParameters: BrandParameters,
    changedParameters: BrandParameters,
  ) {
    const parameters: BrandParameters = {};

    for (const brand in changedParameters) {
      const brandChangedParameters = changedParameters[brand];

      Object.entries(brandChangedParameters || {}).forEach(
        ([articleId, data]) => {
          const { parameter, sentence } = data;
          const thisParameterInInitialState =
            initialParameters[brand]?.[articleId]?.parameter;

          if (thisParameterInInitialState !== parameter) {
            parameters[brand] = {
              ...parameters[brand],
              [articleId]: {
                parameter,
                sentence,
              },
            };
          }
        },
      );
    }

    return parameters;
  }

  // Renderers
  const Plug = useMemo(() => {
    const Plug = getPlug(status, noData, isWidgetCalculating);

    return Plug && <Plug />;
  }, [status, noData, isWidgetCalculating]);

  function renderInnerModal() {
    if (switchWarningIsShown) {
      return (
        <div className={styles.innerModal}>
          <div className={styles.innerModalWrapper}>
            <p>{t("rd_without_saving_modal_title")}</p>
            <div className={styles.switchWarningButtons}>
              <button onClick={() => setSwitchWarningIsShown(false)}>
                {t("rd_without_saving_modal_no_btn")}
              </button>
              <button
                onClick={() => searchChangeHandler(temporarySearchId, true)}
              >
                {t("rd_without_saving_modal_yes_btn")}
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (parametersChangedModalShown) {
      return (
        <div className={styles.innerModal}>
          <div className={styles.innerModalWrapper}>
            <p>{t("rd_parameter_tip_text")}</p>
            <div className={styles.parametersButtons}>
              <button onClick={() => setParametersChangedModalShown(false)}>
                {t("rd_without_saving_modal_ok_btn")}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.esgReviewData}>
      <ReviewDataHeader
        currentSearchId={currentSearchId}
        className={styles.reviewData}
        widgetNameForTitle={TITLE}
        aboutThisText={t(ABOUT_THIS)}
        openedFromSideBar={openedFromSideBar}
        onSearchChangeHandler={searchChangeHandler}
        onBrandChangeHandler={brandChangeHandler}
        onResetHandler={resetHandler}
      >
        {renderInnerModal()}
        {Plug ?? (
          <div className={styles.content}>
            <Label
              leftText={t("rd_esg_sources_label")}
              className={styles.boldLabel}
            />
            <div className={styles.sources}>
              {currentBrandSources.map((source) => {
                return (
                  <KeyWordButton
                    className={styles.sourcesItem}
                    text={source}
                    id={source}
                    key={source}
                    clickHandler={addSourceToExcluded}
                  />
                );
              })}
            </div>
            <div className={styles.articles}>
              <div className={styles.parameters}>
                <Label
                  leftText={t("rd_esg_choose_parameter_label")}
                  className={styles.boldLabel}
                />
                {ARTICLES_PARAMETERS.map((el) => (
                  <button
                    key={el}
                    onClick={() => setArticlesParameter(el)}
                    className={cx(
                      styles.parameter,
                      articlesParameter === el ? styles.parameterActive : "",
                    )}
                  >
                    {capitalizeString(el)}
                  </button>
                ))}
              </div>
              <div className={styles.filter}>
                <Search size={24} />
                <Input
                  value={filterValue}
                  changeHandler={(value) => setFilterValue(value)}
                  placeholder={t("rd_esg_search_words_filter_placeholder")}
                  className={styles.input}
                />
              </div>
              {!parametersHaveNotChanged && (
                <p className={styles.changedParametersTip}>
                  {`* ${t("rd_parameter_tip_text")}`}
                </p>
              )}
              <SearchTable
                className={styles.searchTable}
                columns={[
                  {
                    label: t("rd_esg_table_article_label"),
                    field: "title",
                    isExpandable: true,
                    isSortable: true,
                    isWrapped: true,
                  },
                  {
                    label: t("rd_esg_table_source_label"),
                    field: "source",
                    isSqueezed: true,
                    isSortable: true,
                  },
                  {
                    label: t("rd_esg_table_parameter_label"),
                    field: "parameter",
                    isSqueezed: true,
                    dropdown: {
                      isDropdownChecked: (value: string, fieldId?: string) =>
                        parameterChangeHandler(value, fieldId),
                      options: parameterDropdownOptions,
                    },
                  },
                  {
                    label: t("rd_esg_table_date_label"),
                    field: "date",
                    isSortable: true,
                    isDate: true,
                    isSqueezed: true,
                  },
                ]}
                status={loadMoreArticlesStatus ?? status}
                canLoadMore={canLoadMoreArticles}
                rows={mutatedArticles}
                hasRowDeletion
                onRowDeleted={(id) => addArticleToExcluded(id)}
                loadMoreHandler={() => loadMoreArticlesHandler()}
                noRowsText={t("rd_no_articles_found")}
              />
            </div>
            <div className={styles.excluded}>
              <div className={styles.excludedWrapper}>
                <div className={styles.excludedArticles}>
                  <Label
                    leftText={t("rd_esg_selected_articles_to_exclude")}
                    className={styles.label}
                  />
                  {excludedArticles.map(({ id, title }) => {
                    return (
                      <KeyWordButton
                        className={styles.excludedItem}
                        text={title}
                        id={id}
                        key={`${title}_${id}`}
                        clickHandler={removeArticleFromExcluded}
                      />
                    );
                  })}
                </div>
                <div className={styles.excludedSources}>
                  <Label
                    leftText={t("rd_esg_selected_sources_to_exclude")}
                    className={styles.label}
                  />
                  {excludedSources.map((source) => {
                    return (
                      <KeyWordButton
                        className={styles.excludedItem}
                        text={source}
                        id={source}
                        key={source}
                        clickHandler={removeSourceFromExcluded}
                      />
                    );
                  })}
                </div>
              </div>
              <button
                className={styles.excludeButton}
                onClick={onExcludeClicked}
                disabled={dataHaveNotChanged && parametersHaveNotChanged}
              >
                {t("rd_exclude_button")}
              </button>
            </div>
          </div>
        )}
      </ReviewDataHeader>
    </div>
  );
};
