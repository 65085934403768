import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { readWidgetsData } from "./widgetsDataApi";

export type WidgetData = {
  id: WIDGET_IDS_TYPES;
  name: string;
  isAvailableForOneBrand: boolean;
  resultCollection: WIDGET_IDS_TYPES;
  placeHolderOnUi: string;
  order: number;
  views: {
    isAvailableForOneBrand: boolean;
    isDefault: boolean;
    order: number;
    type: WIDGET_CHARTS_TYPES;
    subType?: string;
  }[];
  isActive: boolean;
  isImmediatelyAvailable: boolean;
  isKeyWordDepended: boolean;
  preReleaseLabel?: string;
};

type InitialState = {
  status: LoadingStatus;
};

const widgetsDataAdapter = createEntityAdapter<WidgetData>({
  selectId: (widget) => widget.id,
  sortComparer: (a, b) => a.order - b.order,
});

const initialState = widgetsDataAdapter.getInitialState<InitialState>({
  status: "idle",
});

export const fetchAllWidgetsData = createAsyncThunk<
  WidgetData[],
  void,
  { state: RootState }
>("widgetsData/fetchAll", async () => {
  return await readWidgetsData();
});

const widgetsDataSlice = createSlice({
  name: "widgetsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllWidgetsData.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(fetchAllWidgetsData.fulfilled, (state, action) => {
      state.status = "succeeded";
      widgetsDataAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchAllWidgetsData.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {
  selectAll: selectAllWidgets,
} = widgetsDataAdapter.getSelectors<RootState>(
  ({ widgetsData }) => widgetsData,
);

export const selectAvailableWidgetsIdsForOneBrandSearch = createDraftSafeSelector(
  selectAllWidgets,
  (widgetData) => {
    return Object.values(widgetData).reduce((acc, widgetData) => {
      const { id, isAvailableForOneBrand } = widgetData;
      if (isAvailableForOneBrand) {
        acc.push(id);
      }
      return acc;
    }, [] as string[]);
  },
);

export default widgetsDataSlice.reducer;
