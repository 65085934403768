import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import * as api from "./faqsApi";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  // error: string | null;
};

export type Faq = {
  id: string;
  title: string;
  paragraphs: {
    subtitle: string;
    text: string;
  }[];
  order: number;
};

const faqsAdapter = createEntityAdapter<Faq>({
  sortComparer: (a, b) => a.order - b.order,
});

const initialState = faqsAdapter.getInitialState<InitialState>({
  status: "idle",
  // error: null,
});

export const fetchAllFaqs = createAsyncThunk<Faq[]>(
  "faqs/fetchAll",
  async () => {
    return await api.getFaqs();
  },
);

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllFaqs.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllFaqs.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.error = null;
      faqsAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchAllFaqs.rejected, (state) => {
      state.status = "failed";
      // state.error = "Can't fetch faqs";
    });
  },
});

export const { selectAll: selectAllFaqs } = faqsAdapter.getSelectors<RootState>(
  ({ faqs }) => faqs,
);

export default faqsSlice.reducer;
