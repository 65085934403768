import { useState, useEffect } from "react";
import styles from "./ReturnBackModal.module.scss";
import { changeCompanySubscriptionPlanFromInactiveToFremium } from "../../../store/company/api";
import cx from "classnames";

type Props = {
  companyId: string;
  withSearch: boolean;
};

export const ReturnBackModal = ({
  companyId = "",
  withSearch = false,
}: Props) => {
  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    if (companyId) {
      (async () => {
        await changeCompanySubscriptionPlanFromInactiveToFremium(companyId);
        setCanClose(true);
      })();
    }
  }, [companyId]);

  return (
    <div
      className={cx(
        styles.returnBackModal,
        withSearch ? "" : styles.withoutSearch,
      )}
    >
      <button
        onClick={() => window.location.reload()}
        disabled={!canClose}
        className={styles.closeIcon}
      ></button>
      <div className={styles.whiteSide}>
        <div>Glad to see you again!</div>
        <div>
          {withSearch
            ? "We’re collecting and processing data again to create your dashboard"
            : "Just a few steps to get your insights about your brand and your competition. Choose the brand you want to track, then at least one competitor. Then what market and finally a suitable industry for your brand."}
        </div>
      </div>
      {withSearch && (
        <div className={styles.pinkSide}>
          <b>48 to 72 hours</b>
          Collecting data for the first model
          <br />
          <br />
          <b>72 to 144 hours</b>
          Building a more robust model
        </div>
      )}
    </div>
  );
};
