import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectCompanySubscriptionPlan } from "../subscriptionPlans/selectors";
import {
  GetUserStatusResult,
  selectUser,
  selectUserStatus,
} from "../user/selectors";

export const selectSearches = createDraftSafeSelector(
  ({ searches }: RootState) => searches,
  (searches) => searches,
);

export const selectSearchesLimit = createDraftSafeSelector(
  ({ company }: RootState) => company,
  selectSearches,
  selectCompanySubscriptionPlan,
  (company, searches, currentSubPlan) => {
    const customSearchesLimit = company.customSearchesLimit;
    const subPlanSearchesLimit = currentSubPlan?.searchesLimit;

    const searchesLimit = (customSearchesLimit ?? subPlanSearchesLimit) || 1;

    return {
      isSearchesLimitReached: searches.length >= searchesLimit,
      searchesLimit,
    };
  },
);

export const selectSearchById = (searchId: string) =>
  createDraftSafeSelector(selectSearches, (searches) =>
    searches.find(({ id }) => id === searchId),
  );

export const selectUserSearchPermissions = (searchId: string) =>
  createDraftSafeSelector(
    selectUser,
    selectUserStatus,
    selectSearchById(searchId),
    getUserSearchPermissions,
  );

export const selectSearchBrands = (searchId: string) =>
  createDraftSafeSelector(selectSearches, (searches) => {
    const search = searches.find(({ id }) => id === searchId);

    return search?.brandsTableNames || [];
  });

export function getUserSearchPermissions(
  user: Store.User,
  { isUserCompanyOwnerOrAdmin }: Partial<GetUserStatusResult>,
  search?: Store.Search,
) {
  if (!search) return {};

  const canUserEditSearch =
    isUserCompanyOwnerOrAdmin || search.owner.id === user.id;

  return {
    canUserEditSearch,
  };
}
