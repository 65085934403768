import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./WidgetTitle.module.scss";
import { PreReleaseComponent } from "../../components";
import { useCustomWidgetText } from "../../hooks";

// Inner imports
import { EditTitle } from "./components";

interface Props {
  searchId: string;
  dashboardId: string;
  widgetId: string;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

export const WidgetTitle = memo(
  ({ searchId, dashboardId, widgetId, isEditing, setIsEditing }: Props) => {
    const { t } = useTranslation();

    const widgetName = useSelector(({ widgetsData }: RootState) => {
      const defaultName = "widget_title_initial_value";
      const widget = widgetsData.entities[widgetId];

      return t(widget?.placeHolderOnUi || defaultName);
    });

    const {
      customWidgetSettings,
      saveCustomWidgetSettings,
    } = useCustomWidgetText(searchId, dashboardId, widgetId);

    const customWidgetTitle = useMemo(() => customWidgetSettings?.title, [
      customWidgetSettings?.title,
    ]);

    const onSaveCustomTitleHandler = (title: string) => {
      saveCustomWidgetSettings(
        customWidgetSettings ? { ...customWidgetSettings, title } : { title },
      );
      setIsEditing(false);
    };

    return (
      <div className={styles.widgetTitle}>
        {isEditing ? (
          <EditTitle
            customTitle={customWidgetTitle}
            onSaveHandler={onSaveCustomTitleHandler}
            onCancelHandler={() => setIsEditing(false)}
          />
        ) : (
          <div className={styles.content}>
            <div className={styles.texts}>
              <h2>{customWidgetTitle || widgetName}</h2>
              {!!widgetId && <PreReleaseComponent widgetId={widgetId} />}
            </div>
          </div>
        )}
      </div>
    );
  },
);
