import { useState, useRef, useEffect, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./MultiSelect.module.scss";
import {
  CheckBoxEmpty,
  CheckBoxChecked,
  Triangle,
  Lock,
  Info,
} from "../../../icons";
import { useOutsideClickHandler } from "../../../hooks";
import * as icons from "../../../icons";

// Inner imports
import type {
  MultiselectOption,
  OpeningDirection,
  MultiSelectProps,
} from "./types";
import { CHECKED_ICON_COLOR } from "./constants";

export const MultiSelect = ({
  inputRef,
  className = "",
  style,
  options = [],
  selectedOptions = [],
  showFilter = false,
  showSelectAllButton = false,
  customInputText,
  selectAllButtonText = "Select all",
  placeholder = "Select here...",
  allUnselectedText,
  isEnabled = true,
  openDirection,
  onSelectAllHandler,
  onCheckHandler,
}: MultiSelectProps) => {
  const { t } = useTranslation();

  const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
    openDirection || "down",
  );
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");

  const filteredOptions: MultiselectOption[] = options.filter((option) =>
    option.label.toLowerCase().includes(filter.trim().toLowerCase()),
  );

  const isAllOptionSelected = useMemo(() => {
    const availableOptions = options.filter(({ isDisabled }) => !isDisabled);

    const availableSelectedOptions = selectedOptions.filter(
      ({ value, isDisabled }) => {
        const isExistingOption = options?.some(
          (option) => option.value === value,
        );

        return isExistingOption && !isDisabled;
      },
    );

    return availableOptions.length === availableSelectedOptions.length;
  }, [options, selectedOptions]);

  const selectRef = useRef<HTMLDivElement>(null);

  useOutsideClickHandler(selectRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);

  useEffect(() => {
    if (openDirection) return;

    const _setOpeningDirection = () => {
      const clientHeight = document.documentElement.clientHeight;
      const selectTop = selectRef.current?.getBoundingClientRect().top!;
      setOpeningDirection(selectTop > clientHeight / 2 ? "up" : "down");
    };
    _setOpeningDirection();
    window.addEventListener("resize", _setOpeningDirection);
    return () => {
      window.removeEventListener("resize", _setOpeningDirection);
    };
  }, [openDirection]);

  const inputValue = useMemo<string>(() => {
    if (customInputText) return customInputText;

    if (isAllOptionSelected) return t("all_selected");

    const filteredSelectedOptionLabels = new Set<string>();

    for (const { label, value } of selectedOptions) {
      const isExistingOption = options?.some(
        (option) => option.value === value,
      );

      if (isExistingOption) filteredSelectedOptionLabels.add(label);
    }

    return Array.from(filteredSelectedOptionLabels).join(", ");
  }, [isAllOptionSelected, selectedOptions, customInputText, t, options]);

  function renderCheckBoxOption(option: MultiselectOption, i: number) {
    const { label, value, isDisabled = false, tooltip = "", icon } = option;

    const Icon = icon ? icons[icon] : null;

    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === value,
    );

    function renderIcon(isDisabled: boolean, isChecked: boolean) {
      switch (true) {
        case isChecked:
          return <CheckBoxChecked color="#41b167" />;
        case !!Icon:
          return !!Icon && <Icon />;
        case isDisabled && !isSelected:
          return <Lock />;
        default:
          return <CheckBoxEmpty />;
      }
    }

    return (
      <button
        type="button"
        className={styles.option}
        onClick={() => onCheckHandler(option)}
        title={tooltip || label}
        key={i}
        disabled={isDisabled && !isSelected}
      >
        {renderIcon(isDisabled, isSelected)}

        <span>{option.label}</span>
      </button>
    );
  }

  return (
    <div
      className={cx(styles.multiSelect, className)}
      style={style}
      ref={selectRef}
    >
      <div
        ref={inputRef}
        className={styles.input}
        style={isEnabled ? undefined : { cursor: "not-allowed" }}
        onClick={
          isEnabled ? () => setIsOpen((prevIsOpen) => !prevIsOpen) : undefined
        }
        tabIndex={0}
      >
        {!!selectedOptions.length ? (
          <div className={styles.label} title={inputValue}>
            {inputValue}
          </div>
        ) : (
          <div className={styles.placeholder} title={placeholder}>
            {placeholder}
          </div>
        )}
        <div className={styles.arrowIconWrapper}>
          <Triangle
            style={{ ...(!isOpen && { transform: "rotate(180deg)" }) }}
            size={10}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className={`${styles.popup} ${
            styles[openingDirection === "up" ? "popupUp" : "popupDown"]
          }`}
          tabIndex={1}
        >
          <div className={styles.popupOptions}>
            {showFilter && (
              <input
                className={styles.filterInput}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Type to filter"
              />
            )}
            {!!filteredOptions.length ? (
              <div className={styles.options}>
                {showSelectAllButton && !filter.length && (
                  <button
                    type="button"
                    className={cx(styles.option, styles.allSelected)}
                    title={t("all_selected")}
                    onClick={onSelectAllHandler}
                  >
                    {isAllOptionSelected ? (
                      <CheckBoxChecked color={CHECKED_ICON_COLOR} />
                    ) : (
                      <CheckBoxEmpty />
                    )}
                    <span>{selectAllButtonText}</span>
                  </button>
                )}
                {filteredOptions.map((option, i) =>
                  renderCheckBoxOption(option, i),
                )}
                {!selectedOptions.length && allUnselectedText && (
                  <div className={styles.allUnselectedText}>
                    <Info />
                    <span>{allUnselectedText}</span>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noOptions}>{t("no_results_found")}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
