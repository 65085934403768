import { FC, useMemo } from "react";

import styles from "./ShareOfVolumePreview.module.scss";
import {
  Preloader,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { useWidgetFetching } from "../../hooks";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import { WIDGET_COLLECTION_ID, WIDGET_ID } from "./constants";
import { extractShareOfVolumeData } from "./utils";
import { WidgetSummaryProps } from "../../types";
import {
  SummaryTimePeriod,
  SummaryYearlyAverage,
} from "../../descriptionInsertionComponents";

export const ShareOfVolumeSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_COLLECTION_ID,
  );

  const { lineChartData, brands } = useMemo(() => {
    const { lineChart = [], brands = [] } = widgetData || {};
    const { name: mainBrandName = "" } = brands[0] || {};

    return {
      lineChartData: extractShareOfVolumeData(lineChart),
      brands,
      mainBrandName,
    };
  }, [widgetData]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const brandValues = useMemo<number[]>(
    () =>
      filteredData?.map(({ values }) => values[selectedBrand.name] || 0) || [],
    [filteredData, selectedBrand],
  );

  const brandLineChartData: { date: string; value: number }[] = useMemo(
    () =>
      lineChartData.map(({ date, values }) => ({
        date,
        value: values?.[selectedBrand.name] || 0,
      })),
    [lineChartData, selectedBrand],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <p className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={brandValues}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
        <SummaryYearlyAverage
          data={brandLineChartData}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
      </p>
      <div className={styles.chartWrapper}>
        <LineChart
          className={styles.chart}
          labels={brands}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: 3,
          }}
          chartSettings={{
            hasTrendLine: true,
          }}
          axis={{
            increaseYAxisWidthInPercentage: 10,
          }}
        />
      </div>
    </div>
  );
};
