import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";

import styles from "./ErrorPage.module.scss";
import context from "../../../../context";
import { _404 } from "../../../../icons";

export const ErrorPage = () => {
  const { t } = useTranslation();

  const { setActionBarProps } = useContext(context);

  useEffect(() => {
    setActionBarProps({});
  }, [setActionBarProps]);

  return (
    <div className={styles.errorPage}>
      <div className={styles.content}>
        <div className={styles.text}>
          <h1>{t("presentation_404_title")}</h1>
          <p>{t("presentation_404_text")}</p>
        </div>
        <_404 className={styles.image} />
      </div>
    </div>
  );
};
