import clamp from "lodash/clamp";
import colors from "../../../data/colors";

import { MarketometerDataItem } from "./types";

const parseRangeData = (
  currentDataItem: MarketometerDataItem,
  previousDataItem: MarketometerDataItem,
  brands: Store.Brands,
) =>
  brands.reduce(
    (acc, brand, i) => {
      let currentValue = currentDataItem.values[brand.name];
      let prevValue = previousDataItem.values[brand.name];

      if (typeof currentValue === "number" && typeof prevValue === "number") {
        currentValue = +clamp(currentValue, 100).toFixed();
        prevValue = +clamp(prevValue, 100).toFixed();

        acc.push({
          brandName: brand.name,
          values: {
            current: currentValue,
            prev: prevValue,
            delta: currentValue - prevValue,
          },
          color: colors[i]!,
        });
      }

      return acc;
    },
    [] as {
      brandName: string;
      values: {
        prev: number;
        current: number;
        delta: number;
      };
      color: string;
    }[],
  );

export { parseRangeData };
