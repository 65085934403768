import { FC, useMemo } from "react";
import styles from "./AssociatedWordsPreview.module.scss";
import { Preloader, RadarChart, ErrorPlaceholder } from "../../../components";

// Inner imports
import { WIDGET_ID } from "./constants";
import { useWidgetFetching } from "../../hooks";

// TEMPORARY REDUNDANT
export const AssociatedWordsPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
}) => {
  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const initialBrand = useMemo(() => {
    if (!widgetData?.radarChart) return;
    return Object.keys(widgetData?.radarChart)[0];
  }, [widgetData?.radarChart]);

  const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } =
    widgetData?.radarChart?.["all" || initialBrand] || {};

  const chartData = (
    oneYear ||
    sixMonths ||
    threeMonths ||
    twoMonths ||
    oneMonth
  )?.data;

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.associatedWordsPreview}>
      {chartData && widgetData?.brands ? (
        <>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            <RadarChart
              className={styles.chart}
              brands={widgetData.brands}
              data={chartData}
              fitTo="height"
            />
          </div>
        </>
      ) : (
        <ErrorPlaceholder onMountCallBack={() => setChartRef(undefined)} />
      )}
    </div>
  );
};
