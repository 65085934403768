import React, { FC, useState, useMemo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./SentimentIndex.module.scss";
import {
  LineChart,
  SentimentIndexPieCharts,
  AboutThis,
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
  ErrorPlaceholder,
} from "../../../components";
import DATE_RANGE_LABELS from "../../../data/dateRangeLabels";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { WIDGET_ID } from "./constants";
import { preparePieChartData } from "./utils";
import { DATE_RANGES, PIE_CHART_DATA_BRANDS_LIMIT } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const SentimentIndex: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  // Date Range ---->
  const dateRanges = useMemo(() => {
    const pieChart = widgetData?.pieChart;
    if (!pieChart) return [];

    const dateRanges: {
      value: Partial<DateRange>;
      label: string;
    }[] = [];

    for (let range of DATE_RANGES) {
      if (!pieChart[range]) continue;

      dateRanges.push({
        value: range,
        label: t(DATE_RANGE_LABELS[range]),
      });
    }
    return dateRanges;
  }, [t, widgetData?.pieChart]);

  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    "oneYear",
  );
  // <---- Date Range

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { brands = [], pieChart, lineChart } = widgetData || {};

    const mappedBrands = brands?.map((brand) => ({
      ...brand,
      name: brand.name + " sentiment score",
    }));

    if (Object.keys(pieChart || {}).length) {
      const { values } = pieChart?.[currentDateRange || "oneYear"] || {};

      const data = values ? preparePieChartData(values, brands) : [];

      if (!!data.length) {
        charts.push({
          type: "pieChart",
          chart: (
            <SentimentIndexPieCharts
              type="Page"
              className={styles.sentimentIndexPieCharts}
              data={data}
            />
          ),
        });
      }
    }

    if (!!lineChart?.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={mappedBrands}
            data={lineChart?.map(
              ({ date, values }: { date: string; values: any }) => ({
                date,
                values: values || {},
              }),
            )}
            searchId={searchId}
            dashboardId={dashboardId}
            chartStyles={{
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_se_linechart_legend"),
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    return charts;
  }, [widgetData, currentDateRange, dashboardId, searchId, t]);

  const {
    widgetViewsOptions,
    selectedView,
    setCurrentWidgetView,
  } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  useEffect(() => {
    const { brands = [] } = widgetData || {};

    if (
      brands.length > PIE_CHART_DATA_BRANDS_LIMIT &&
      selectedView?.type !== "lineChart"
    )
      setCurrentWidgetView({ type: "lineChart" });
  }, [widgetData, setCurrentWidgetView, selectedView]);

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // ----> Descriptions
  const [mainBrandId, mainBrandName] = useMemo(() => {
    const { id = "", name = "" } = widgetData?.brands[0] || {};
    return [id, name];
  }, [widgetData?.brands]);

  const descriptionInsertion = useMemo(() => {
    return widgetData?.["pieChart"]?.[currentDateRange || "oneYear"]?.values[
      mainBrandId
    ]?.score;
  }, [mainBrandId, widgetData, currentDateRange]);

  const conclusion = useMemo(() => {
    if (typeof descriptionInsertion !== "number") return "";

    return t("w_se_main_tip", {
      brandName: mainBrandName,
      descriptionInsertion,
    });
  }, [descriptionInsertion, mainBrandName, t]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;
  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "lineChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            downloadExcelButtonProps={{
              widgetName: "sentiment-index",
              fileName: downloadedFileName,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
