import firebase from "firebase/app";

export const getTimePlots = async (companyId: Store.Company["id"]) => {
  const result = await firebase
    .firestore()
    .collection("time-plots")
    .where("companyId", "==", companyId)
    .get();

  return result.docs.reduce((acc, doc) => {
    const timePlotId = doc.id;
    const timePlot = doc.data() as Store.TimePlot;

    if (timePlotId) {
      acc.push({ ...timePlot, id: timePlotId });
    }

    return acc;
  }, [] as Store.TimePlot[]);
};

export const addTimePlot = async (
  timePlot: Omit<Store.TimePlot, "id" | "createdAt">,
): Promise<Store.TimePlot> => {
  const dateNow = new Date().toISOString();

  const collection = firebase.firestore().collection("time-plots");

  const doc = await collection.add({
    ...timePlot,
    createdAt: dateNow,
    updatedAt: dateNow,
  });

  return {
    ...timePlot,
    id: doc.id,
    createdAt: dateNow,
    updatedAt: dateNow,
  };
};

export const updateTimePlot = async (
  id: string,
  changes: Partial<Omit<Store.TimePlot, "id" | "createdAt">>,
) =>
  await firebase
    .firestore()
    .collection("time-plots")
    .doc(id)
    .set(changes, { merge: true });

export const deleteTimePlot = async (id: Store.TimePlot["id"]) => {
  await firebase.firestore().collection("time-plots").doc(id).delete();
};
