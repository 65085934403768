import axios from "../../axios";
import firestore from "../../firestore";
import yougovBrands from "../../data/yougovBrands";
import { getSlugifiedString } from "../../utils";
import { showToastNotification } from "../../components/ToastNotification/utils";

// Inner imports
import {
  ArticlesResultDataType,
  DownloadKeywordsCSVType,
  FetchArticlesPayloadType,
  KeywordsResultDataType,
  ParsedArticlesDataType,
  SocialGistFetchArticlesPayloadType,
} from "./types";
import { keyWordsSchema } from "./schemas";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

export const getSearches = (companyId: string) =>
  axios.get(`companies-${urlInsertion}-api/${companyId}/searches`);

export const postSearch = (payload: Parameters<typeof prepareSearchData>[0]) =>
  axios.post(
    `brands-${urlInsertion}-api/searches/new`,
    prepareSearchData(payload),
  );

export const putSearch = (
  searchId: string,
  payload: Parameters<typeof prepareSearchData>[0],
) => {
  return axios.put(
    `brands-${urlInsertion}-api/${searchId}`,
    prepareSearchData(payload),
  );
};

export const deleteSearch = (userId: string, searchId: string) =>
  axios.delete(`brands-${urlInsertion}-api/${searchId}/${userId}`);

export const recalculateWidgetsDependentOnKeywords = ({
  companyId,
  searchId: brandId,
}: {
  companyId: string;
  searchId: string;
  isRecalculating?: string;
}) => {
  sessionStorage.setItem("calculatingSearchID", brandId);
  return axios.post(
    `widgets-service-${urlInsertion}-api/recalculate/dependent-on-keywords`,
    {
      companyId,
      brandId,
    },
  );
};

export const getKeywords = async (
  brandName: string,
  marketCode: string,
  additionalBrandNames: string[] = [],
  subRegions?: string[],
): Promise<KeywordsResultDataType> => {
  const slugifyBrandName = getSlugifiedString(brandName);

  const additionalNames = additionalBrandNames.map((tableName) =>
    getSlugifiedString(tableName),
  );

  const data = await axios
    .get(`keywords-service-${urlInsertion}-api/all`, {
      params: {
        brandNames: [brandName, ...additionalBrandNames],
        tableIds: [slugifyBrandName, ...additionalNames],
        market: marketCode,
        ...(subRegions ? { subRegions } : {}),
      },
    })
    .then((response) => {
      const responseData = response.data;

      if (responseData?.notice)
        showToastNotification({ type: "error", text: responseData.notice });

      let keyWords: KeywordsResultDataType = [];

      try {
        const validatedKeyWords = keyWordsSchema.validateSync(
          responseData.data,
        );

        keyWords = validatedKeyWords;
      } catch (err) {
        console.error(err);
      }

      return keyWords;
    });

  return data;
};

export const calculateImmediatelyAvailableWidgets = ({
  companyId,
  searchId: brandId,
}: {
  companyId: string;
  searchId: string;
}) => {
  sessionStorage.setItem("calculatingSearchID", brandId);

  return axios.post(
    `widgets-service-${urlInsertion}-api/calculate-immediately-available-widgets`,
    {
      companyId,
      brandId,
    },
  );
};

export const socialGistFetchLatestArticles = async (
  payload: SocialGistFetchArticlesPayloadType,
): Promise<ParsedArticlesDataType> => {
  const { data } = await axios.post(
    `socialgist-service-${urlInsertion}-api/v1/query-mentions`,
    payload,
  );

  return filterArticlesData(data);
};

export const fetchLatestArticles = async (
  payload: FetchArticlesPayloadType,
) => {
  const { data } = await axios.post(
    `articles-service-${urlInsertion}-api/latest`,
    payload,
  );

  return filterArticlesData(data);
};

export const writeSearchData = (
  searchId: Store.Search["id"],
  changes: Partial<Pick<Store.Search, "isPinned" | "name">>,
) => firestore().collection("brands").doc(searchId).update(changes);

export const downloadKeywordsCSV = async ({
  searchData: { market, subRegions, selectedKeywords },
  brandName,
  brandAdditionalNames,
  exactMatch,
}: DownloadKeywordsCSVType) => {
  const brandNames = [brandName, ...brandAdditionalNames];
  const tableIds = brandNames.map((name) => getSlugifiedString(name));

  return axios.post(
    `service-export-${urlInsertion}-api/keywords/export/excel`,
    {
      exactMatch,
      brandNames,
      tableIds,
      market,
      subRegions,
      selectedKeywords,
    },
    {
      responseType: "arraybuffer",
    },
  );
};

export async function writeSearchKeyWords(
  searchId: string,
  brandName: string,
  keywords: string[],
) {
  await firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandName}.keywords`]: keywords,
    });
}

export function watchSearch(
  searchId: string,
  callback: (doc: firestore.DocumentData | undefined) => void,
) {
  const doc = firestore().collection("brands").doc(searchId);

  return doc.onSnapshot(
    (doc) => {
      callback(doc.data());
    },
    (error) => {
      console.dir(error);
    },
  );
}

export async function checkIfSearchDeleted(searchId: string): Promise<boolean> {
  const gotSearch = await firestore().collection("brands").doc(searchId).get();

  const search = gotSearch.data() as any;

  return typeof gotSearch === "undefined" || search?.isDeleted;
}

function prepareSearchData({
  userId,
  name,
  mainBrand,
  competitors,
  additionalBrandNames,
  industryId,
  industry,
  marketCode,
  subRegions,
  suggestions,
  calculatingImmediatelyAvailableWidgets,
}: {
  userId: string;
  name: string;
  mainBrand: string;
  competitors: string[];
  additionalBrandNames: {
    [name: string]: string[];
  };
  industryId: string;
  industry?: ValueOf<RootState["industries"]["entities"]>;
  marketCode: string;
  subRegions: string[];
  suggestions: Store.Search["suggestions"];
  calculatingImmediatelyAvailableWidgets: string;
}) {
  const selectedYougovBrands: any = {};
  [mainBrand, ...competitors].forEach((brand) => {
    const slugifiedBrand = getSlugifiedString(brand);
    const yougovBrand = yougovBrands.find(
      (yougovBrand) => getSlugifiedString(yougovBrand.name) === slugifiedBrand,
    );
    if (yougovBrand) {
      selectedYougovBrands[slugifiedBrand] = { ...yougovBrand, region: "se" };
    }
  });

  const selectedMeltwaterBrands: any = {};

  const additionalMainBrandNames = additionalBrandNames[mainBrand] || [];
  selectedMeltwaterBrands[getSlugifiedString(mainBrand)] = {
    base: mainBrand,
    brandNames: [
      { name: mainBrand, tableName: getSlugifiedString(mainBrand) },
      ...additionalMainBrandNames.map((el) => ({
        name: el,
        tableName: getSlugifiedString(el),
      })),
    ],
    // ppl: industry?.meltwaterQueries.ppl,
    // prc: industry?.meltwaterQueries.prc,
    // prd: industry?.meltwaterQueries.prd,
    // prf: industry?.meltwaterQueries.prf,
    // prm: industry?.meltwaterQueries.prm,
    // prp: industry?.meltwaterQueries.prp,
    // plc: industry?.meltwaterQueries.plc,
    include: suggestions[mainBrand]?.include ?? [],
    exclude: suggestions[mainBrand]?.exclude ?? [],
    keywords: suggestions[mainBrand]?.keywords ?? [],
    aiTrainerExcludedWords:
      suggestions[mainBrand]?.aiTrainerExcludedWords ?? [],
    excludedArticles: suggestions[mainBrand]?.excludedArticles ?? [],
    useAllKeywords: suggestions[mainBrand]?.useAllKeywords ?? false,
  };

  competitors.forEach((competitor) => {
    const additionalCompetitorNames = additionalBrandNames[competitor] || [];
    selectedMeltwaterBrands[getSlugifiedString(competitor)] = {
      base: competitor,
      brandNames: [
        { name: competitor, tableName: getSlugifiedString(competitor) },
        ...additionalCompetitorNames.map((el) => ({
          name: el,
          tableName: getSlugifiedString(el),
        })),
      ],
      include: suggestions[competitor]?.include ?? [],
      exclude: suggestions[competitor]?.exclude ?? [],
      keywords: suggestions[competitor]?.keywords ?? [],
      aiTrainerExcludedWords:
        suggestions[competitor]?.aiTrainerExcludedWords ?? [],
      excludedArticles: suggestions[competitor]?.excludedArticles ?? [],
      useAllKeywords: suggestions[competitor]?.useAllKeywords ?? false,
    };
  });

  return {
    uid: userId,
    brand: {
      name: getSlugifiedString(mainBrand),
      displayName: mainBrand,
      industry: industry?.label || "",
      industryId: industryId,
      searchName: name,
      marketId: marketCode,
      subRegions,
    },
    competitors: competitors.map((competitor) =>
      getSlugifiedString(competitor),
    ),
    yougov: selectedYougovBrands,
    meltwater: selectedMeltwaterBrands,
    calculatingImmediatelyAvailableWidgets,
  };
}

function filterArticlesData(
  data: ArticlesResultDataType,
): ParsedArticlesDataType {
  if (!Array.isArray(data)) return [];

  return data.reduce((acc, { id, title, url, source, category, date }) => {
    acc.push({
      id,
      title,
      link: `<a href='${url}' target='_blank'>${title}</a>`,
      source,
      category,
      date: Date.parse(date),
    });

    return acc;
  }, [] as ParsedArticlesDataType);
}
