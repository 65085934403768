import { useMemo } from "react";
import styles from "./ReputationSentimentPieChartView.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import reputationParameters from "../../../../../data/reputationParameters";
import { GREEN, RED, VIOLET } from "../../../../../data/colors";
import DATE_RANGES from "../../../../../data/dateRanges";

interface Props {
  data: ReputationSentiment.PieChart.Data;
  currentDateRange?: typeof DATE_RANGES[number]["value"];
}

export const ReputationSentimentPieChartView = ({
  data,
  currentDateRange,
}: Props) => {
  const rangeData = useMemo(() => {
    if (currentDateRange) return data[currentDateRange];
  }, [data, currentDateRange]);

  const modifiedData = useMemo(
    () =>
      reputationParameters.map(({ name, description }) => ({
        label: name,
        description,
        data: [
          {
            value: Number(rangeData?.values[name]?.positive.toFixed()) || 0,
            color: GREEN,
          },
          {
            value: Number(rangeData?.values[name]?.negative.toFixed()) || 0,
            color: RED,
          },
          {
            value: Number(rangeData?.values[name]?.neutral.toFixed()) || 0,
            color: VIOLET,
          },
        ],
      })),
    [rangeData],
  );

  return (
    <div className={styles.reputationIndexPieCharts}>
      <div className={styles.widgetArea}>
        <div className={styles.widget}>
          {modifiedData.map(({ label, description, data }, i) => (
            <div
              className={styles.chartArea}
              style={{ gridArea: "pie" + i }}
              key={i}
            >
              <div className={styles.chartWrapperOuter}>
                <div className={styles.chartWrapperInner}>
                  <PieChart
                    animate
                    animationDuration={500}
                    animationEasing="ease-out"
                    data={data}
                    label={(props) => {
                      const value = props.dataEntry.value;
                      return value ? value + "%" : "";
                    }}
                    labelStyle={{
                      fill: "currentColor",
                      fontSize: 8,
                      fontWeight: 500,
                    }}
                    startAngle={-90}
                  />
                </div>
                <div className={styles.label} title={description}>
                  {label}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
