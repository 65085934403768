import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ShareOfEarnedMediaPreview.module.scss";
import {
  Preloader,
  ChartButton,
  PieChartWithInnerLabels,
  LineChart,
  ErrorPlaceholder,
} from "../../../components";

// Inner imports
import { checkIfLineChartDataExist, checkIfPieChartDataExist } from "./utils";
import { PREVIEW_LINE_CHART, WIDGET_ID } from "./constants";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const ShareOfEarnedMediaPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isLineChartDataExist: boolean = useMemo(
    () => checkIfLineChartDataExist(widgetData?.lineChart),
    [widgetData?.lineChart],
  );

  const isPieChartDataExist: boolean = useMemo(
    () => checkIfPieChartDataExist(widgetData?.pieChart),
    [widgetData?.pieChart],
  );

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { brands, lineChart, pieChart } = widgetData || {};

    if (Object.keys(pieChart || {}).length) {
      const pieChartData: ShareOfEarnedMedia.PieChart.Value[] =
        (
          pieChart?.["oneYear"] ||
          pieChart?.["sixMonths"] ||
          pieChart?.["threeMonths"] ||
          pieChart?.["twoMonths"] ||
          pieChart?.["oneMonth"]
        )?.values || [];

      charts.push({
        type: "pieChart",
        chart: isPieChartDataExist ? (
          <PieChartWithInnerLabels
            className={styles.chart}
            data={pieChartData}
            fitTo="height"
          />
        ) : (
          <ErrorPlaceholder />
        ),
      });
    }

    if (lineChart?.length) {
      charts.push({
        type: "lineChart",
        chart: isLineChartDataExist ? (
          <LineChart
            className={styles.chart}
            labels={brands}
            data={lineChart}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: PREVIEW_LINE_CHART.STROKE_WIDTH,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_som_widget_name"),
              yAxisUnit: PREVIEW_LINE_CHART.Y_AXIS_UNIT,
              yAxisVerticalPadding: PREVIEW_LINE_CHART.VERTICAL_PADDING,
            }}
          />
        ) : (
          <ErrorPlaceholder />
        ),
      });
    }

    return charts;
  }, [widgetData, isPieChartDataExist, isLineChartDataExist, t, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isEmptyWidget: boolean =
    !isLoading && !isLineChartDataExist && !isPieChartDataExist;

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isEmptyWidget)
    return (
      <ErrorPlaceholder
        onMountCallBack={() =>
          onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
        }
      />
    );

  return (
    <div className={styles.widgetPreview}>
      <div className={styles.buttons}>
        {views.map(({ buttonIcon }, i) =>
          i !== viewIndex ? (
            <ChartButton
              className={styles.button}
              icon={buttonIcon}
              onButtonClick={() => setViewIndex(i)}
              key={buttonIcon + i}
              isEventPropagationStopped
            />
          ) : null,
        )}
      </div>
      <div
        ref={(chartRef) => chartRef && setChartRef(chartRef)}
        className={styles.chartWrapper}
        onClick={onChartClicked}
      >
        {views[viewIndex]?.chart}
      </div>
    </div>
  );
};
