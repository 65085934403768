import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "../DashboardContainer.module.scss";
import context from "src/context";
import { sortWidgetsOnDashboard } from "src/utils";
import {
  DashboardDetailsModal,
  ShareDashboardModal,
  DashboardSyncModal,
} from "src/components";
import { ROUTS } from "src/data/routs";
import { SEARCH_ID_LENGTH } from "src/data/searchIdLength";
import {
  selectCompanySubscriptionPlan,
  selectDashboardSearchIds,
  selectUserDashboardPermissions,
} from "src/store/selectors";
import { selectAvailableWidgetsIdsForOneBrandSearch } from "src/store/widgetsData/widgetsDataSlice";
import {
  Dashboard,
  GridItem,
  updateOneDashboard,
} from "src/store/dashboards/dashboardsSlice";

// Inner imports
import { DashboardTitle } from "../components";
import { OPEN_BUTTON_ID } from "../components/Sidebar/constants";
import { useDashboardSyncButton } from "../hooks";

export const useSetDashboardActionBar = (
  layoutSize: keyof Dashboard["layouts"],
  dashboard?: Dashboard,
): [boolean, (value: boolean) => void] => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const dashboardId: string = useMemo(() => dashboard?.id || "", [
    dashboard?.id,
  ]);

  const [isSideBarOpened, setIsSideBarOpened] = useState<boolean>(false);

  const {
    setModalElement,
    setIsGlobalPreloaderShown,
    setActionBarProps,
  } = useContext(context);

  const searches = useSelector(({ searches }: Store.State) => searches);
  const widgetsState = useSelector(
    ({ widgetsState }: Store.State) => widgetsState,
  );
  const availableForOneBrandWidgets = useSelector(
    selectAvailableWidgetsIdsForOneBrandSearch,
  );
  const { canUserEditDashboard } = useSelector(
    selectUserDashboardPermissions(dashboardId),
  );
  const dashboardSearchIds = useSelector(selectDashboardSearchIds(dashboardId));
  const subPlan = useSelector(selectCompanySubscriptionPlan);

  const {
    label: syncButtonLabel,
    isLoading: isSyncLoading,
    isDisabled: isSyncDisabled,
  } = useDashboardSyncButton(dashboardId);

  const isCopiedDashboard: boolean = useMemo(
    () => !!dashboard?.isCopiedDashboard,
    [dashboard],
  );

  const isAllUsedSearchesOnDashboardDeleted: boolean = useMemo(() => {
    const isSomeSuitableSearchHasFound = dashboardSearchIds.some((searchId) =>
      searches.some(({ id }) => id === searchId),
    );

    return !isSomeSuitableSearchHasFound;
  }, [dashboardSearchIds, searches]);

  const [
    firstPresentationWidgetSlug,
    firstPresentationWidgetSearchId,
  ] = useMemo<[string, string]>(() => {
    if (!dashboard || !layoutSize) return ["", ""];

    const widgets = dashboard.layouts[layoutSize];

    const suitableWidgetForPresentation = filterBrokenAndUnavailableWidgets(
      widgets,
      widgetsState,
      searches,
      availableForOneBrandWidgets,
    );

    const sortedWidgetsOnDashboard = sortWidgetsOnDashboard(
      suitableWidgetForPresentation,
    );

    const firstPresentationWidgetId =
      sortedWidgetsOnDashboard[0]?.i.slice(SEARCH_ID_LENGTH) || "";

    const firstPresentationWidgetSearchId =
      sortedWidgetsOnDashboard[0]?.i.slice(0, SEARCH_ID_LENGTH) || "";

    return [firstPresentationWidgetId, firstPresentationWidgetSearchId];
  }, [
    dashboard,
    layoutSize,
    searches,
    availableForOneBrandWidgets,
    widgetsState,
  ]);

  const sharingOptions: ActionBar.ActionsOption[] = useMemo(() => {
    const sharingOptions: ActionBar.ActionsOption[] = [];

    if (!dashboardId) return sharingOptions;

    if (!isAllUsedSearchesOnDashboardDeleted) {
      sharingOptions.push({
        id: "dashboardSharePresentation",
        text: t("presentation_share_dashboard_btn"),
        icon: "ShareIcon",
        isSeparatedOnDesktop: true,
        onClickHandler: () => {
          setModalElement(
            <ShareDashboardModal
              dashboardId={dashboardId}
              layoutSize={layoutSize}
            />,
            false,
          );
        },
      });

      if (
        firstPresentationWidgetSlug &&
        dashboardId &&
        firstPresentationWidgetSearchId
      ) {
        sharingOptions.push({
          id: "dashboardPreviewPresentation",
          text: t("presentation_preview_btn"),
          icon: "SlideShow",
          isSeparatedOnDesktop: true,
          onClickHandler: () => {
            history.push(
              `/dashboards/${dashboardId}/${firstPresentationWidgetSlug}`,
              {
                searchId: firstPresentationWidgetSearchId,
                dashboardId,
              },
            );
          },
        });
      }
    }

    return sharingOptions;
  }, [
    dashboardId,
    firstPresentationWidgetSearchId,
    firstPresentationWidgetSlug,
    history,
    isAllUsedSearchesOnDashboardDeleted,
    layoutSize,
    setModalElement,
    t,
  ]);

  const updateSyncDataOptions: ActionBar.ActionsOption[] = useMemo(() => {
    if (!subPlan?.updateFrequencyInWeeks || !dashboardSearchIds.length)
      return [];

    return [
      {
        id: "dashboardSync",
        text: syncButtonLabel,
        icon: "SyncCircle",
        isSeparatedOnDesktop: true,
        onClickHandler: () =>
          setModalElement(
            <DashboardSyncModal dashboardId={dashboardId} />,
            false,
          ),
        className: isSyncLoading ? styles.syncButtonLoading : "",
        isDisabled: isSyncDisabled,
      },
    ];
  }, [
    dashboardId,
    isSyncDisabled,
    isSyncLoading,
    setModalElement,
    subPlan?.updateFrequencyInWeeks,
    syncButtonLabel,
    dashboardSearchIds.length,
  ]);

  const actionsOptions: ActionBar.ActionsOption[] = useMemo(() => {
    const actionsOptions: ActionBar.ActionsOption[] = [];

    const isPrivate = dashboard?.isPrivate;

    if (!dashboardId || isCopiedDashboard) return actionsOptions;

    if (!!updateSyncDataOptions.length) {
      actionsOptions.push(...updateSyncDataOptions);
    }

    if (!!sharingOptions.length) {
      actionsOptions.push(...sharingOptions);
    }

    if (canUserEditDashboard) {
      actionsOptions.push({
        id: "editDashboard",
        text: t("Edit"),
        icon: "Edit",
        isSeparatedOnDesktop: true,
        onClickHandler: () =>
          history.push(`/dashboards/edit/${dashboardId}`, {
            isBreadcrumbToDashboard: true,
          }),
      });

      actionsOptions.push({
        id: OPEN_BUTTON_ID,
        text: t("d_sidebar_title"),
        icon: "Widgets",
        isSeparatedOnDesktop: true,
        onClickHandler: () => setIsSideBarOpened((state) => !state),
      });
    }

    actionsOptions.push({
      id: "dashboardDetails",
      text: t("d_details_option"),
      icon: "Details",
      onClickHandler: () => {
        setModalElement(<DashboardDetailsModal dashboardId={dashboardId} />);
      },
    });

    if (canUserEditDashboard) {
      actionsOptions.push({
        id: "dashboardPrivacySwitcher",
        text: t(
          isPrivate
            ? "d_make_it_not_private_option"
            : "d_make_it_private_option",
        ),
        icon: "Privacy" as AppIcon,
        onClickHandler: () => {
          (async () => {
            setIsGlobalPreloaderShown(true);
            await dispatch(
              updateOneDashboard({
                id: dashboardId,
                changes: {
                  isPrivate: !isPrivate,
                },
              }),
            );
            setIsGlobalPreloaderShown(false);
          })();
        },
      });
    }

    return actionsOptions;
  }, [
    dashboard,
    dashboardId,
    isCopiedDashboard,
    sharingOptions,
    canUserEditDashboard,
    t,
    history,
    setModalElement,
    setIsGlobalPreloaderShown,
    dispatch,
    updateSyncDataOptions,
  ]);

  const breadcrumbs = useMemo(() => {
    const { name: dashboardName } = dashboard || {};

    return [
      {
        title: t("menu_dashboards"),
        linkOptions: {
          href: ROUTS.dashboardsPage,
        },
      },
      ...(dashboardName ? [{ title: dashboardName }] : []),
    ];
  }, [dashboard, t]);

  useEffect(() => {
    if (!breadcrumbs.length) {
      return;
    }

    const { name = "", isPrivate } = dashboard || {};

    const dashboardTitleData = {
      dashboardName: name || t("d_title"),
      isPrivate,
    };

    setActionBarProps({
      title: DashboardTitle(dashboardTitleData),
      actionsOptions: {
        options: actionsOptions,
      },
      breadcrumbs,
    });
  }, [dashboard, actionsOptions, breadcrumbs, setActionBarProps, t]);

  return [isSideBarOpened, setIsSideBarOpened];
};

function filterBrokenAndUnavailableWidgets(
  gridItem: GridItem[],
  widgetsState: Store.WidgetsState,
  searches: Store.Search[],
  availableForOneBrandWidgets: string[],
) {
  return gridItem.filter(({ i }) => {
    const widgetSearchId = i.slice(0, SEARCH_ID_LENGTH);
    const widgetId = i.replace(widgetSearchId, "");

    const isWidgetRequireCompetitors =
      !availableForOneBrandWidgets.includes(widgetId) &&
      !searches.find(({ id }) => id === widgetSearchId)?.brands?.competitors
        ?.length;

    if (isWidgetRequireCompetitors) return false;

    const { isWidgetBroken } = widgetsState[widgetSearchId]?.[widgetId] || {};

    return !isWidgetBroken;
  });
}
