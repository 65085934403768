import { texts } from "../ReviewsDataTexts";

const WIDGET_ID = "result-business-drivers" as const;

const ABOUT_THIS = texts[WIDGET_ID]?.aboutThis || "";

const ARTICLES_LIMIT = 200 as const;

const ARTICLES_PARAMETERS = [
  "all",
  "product",
  "communication",
  "service",
  "price",
  "preference",
  "offer",
] as const;

const REVIEW_DATA_CATEGORY = "Interest drivers review data" as const;

export {
  WIDGET_ID,
  ABOUT_THIS,
  ARTICLES_LIMIT,
  ARTICLES_PARAMETERS,
  REVIEW_DATA_CATEGORY,
};
