import { FC, HTMLAttributes } from "react";
import styles from "./Label.module.scss";
import cx from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  leftText: string;
  rightText?: string;
  isOptional?: boolean;
}

export const Label: FC<Props> = ({
  leftText,
  rightText,
  className,
  isOptional,
  ...props
}) => {
  const optionalLabel = isOptional ? "(Optional)" : "";

  return (
    <div className={cx(styles.label, className)} {...props}>
      <div
        className={styles.leftText}
        title={leftText}
        data-optional-label={optionalLabel}
      >
        {leftText}
      </div>
      {rightText && <div className={styles.rightText}>{rightText}</div>}
    </div>
  );
};
