import { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./VerifyEmailPage.module.scss";
import { sendVerifyEmail } from "../../../store/api";
import { WebsiteLogoVertical } from "../../../icons";
import { GreenButton } from "../../../components";
import context from "../../../context";
import { showToastNotification } from "../../../components/ToastNotification/utils";

const VerifyEmailPage = () => {
  const { t } = useTranslation();
  const { setIsGlobalPreloaderShown } = useContext(context);
  const params = useParams() as { userId?: string };

  const { userId } = params || {};

  const onResendConfirmationLink = async () => {
    setIsGlobalPreloaderShown(true);
    if (userId) {
      try {
        await sendVerifyEmail(userId);
        showToastNotification({
          type: "success",
          text: t("email_confirmation_email_sent"),
        });
      } catch (err) {
        console.error(err.message);
      } finally {
        setIsGlobalPreloaderShown(false);
      }
    } else {
      setIsGlobalPreloaderShown(false);
      showToastNotification({
        type: "error",
        text: t("request_error"),
      });
    }
  };

  return (
    <div className={styles.verifyEmailPage}>
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <WebsiteLogoVertical className={styles.logo} />
        </div>
        <p className={styles.text}>{t("email_confirmation_page_title")}</p>
        <GreenButton onClick={() => onResendConfirmationLink()}>
          {t("email_confirmation_resend_button_text")}
        </GreenButton>
        <Link to="/login" className={styles.backToLogInPage}>
          {t("email_confirmation_back_to_login")}
        </Link>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
