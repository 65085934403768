import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDashboardSearchIds,
  selectCompanySubscriptionPlan,
  selectCompanySubscriptionPlanDataSource,
  selectDashboardById,
  selectSearches,
  selectSyncsStatus,
} from "src/store/selectors";
import { subscribeOnSyncs } from "src/store/syncs/syncsApi";
import {
  upsertSyncsUniqBySearchId,
  removeAllSyncs,
  updateSyncsStatus,
} from "src/store/syncs/syncsSlice";

// Observe all dashboard trackers latest sync (1 tracker = 1 latest sync)
export const useDashboardSyncObserver = (dashboardId: string) => {
  const dispatch = useDispatch();

  const searches = useSelector(selectSearches);
  const dashboard = useSelector(selectDashboardById(dashboardId));
  const subPlan = useSelector(selectCompanySubscriptionPlan);
  const subPlanDataSource = useSelector(
    selectCompanySubscriptionPlanDataSource,
  );
  const syncsStatus = useSelector(selectSyncsStatus);

  const [callbackCounter, setCallbackCounter] = useState<number>(0);

  const dashboardSearchIds: string[] = useMemo(
    () => getDashboardSearchIds(dashboard?.tiles, searches),
    [dashboard?.tiles, searches],
  );

  const updateFrequencyInWeeks: number = useMemo(
    () => subPlan?.updateFrequencyInWeeks || 0,
    [subPlan?.updateFrequencyInWeeks],
  );

  const isSyncsStatusLoading: boolean = useMemo(
    () => syncsStatus === "idle" || syncsStatus === "loading",
    [syncsStatus],
  );

  const isEverySyncSubscriptionCalled: boolean = useMemo(
    () =>
      Boolean(callbackCounter) && callbackCounter >= dashboardSearchIds.length,
    [dashboardSearchIds.length, callbackCounter],
  );

  useEffect(() => {
    if (isSyncsStatusLoading && isEverySyncSubscriptionCalled)
      dispatch(updateSyncsStatus("succeeded"));
  }, [isSyncsStatusLoading, dispatch, isEverySyncSubscriptionCalled]);

  useEffect(() => {
    if (!updateFrequencyInWeeks) return;

    const callback = (syncs: Store.Sync[]) => {
      setCallbackCounter((count) => count + 1);
      dispatch(upsertSyncsUniqBySearchId(syncs));
    };

    const errorCallback = () => dispatch(updateSyncsStatus("failed"));

    const unsubscribe = subscribeOnSyncs(
      dashboardSearchIds,
      subPlanDataSource,
      callback,
      errorCallback,
    );

    return () => {
      unsubscribe();
      setCallbackCounter(0);
      dispatch(removeAllSyncs());
    };
  }, [dashboardSearchIds, dispatch, subPlanDataSource, updateFrequencyInWeeks]);
};
