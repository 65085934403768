import { useMemo } from "react";
import {
  getWidgetTopWordsData,
  getWidgetTrendingWordsData,
} from "../utils/getWidgetData";

export const useDataExistChecker = (
  data: TopKeywords.Data | undefined,
  isLoading: boolean,
): { isDataExist: boolean } => {
  const topWords = useMemo<TopKeywords.TopWords[]>(
    () => getWidgetTopWordsData(data),
    [data],
  );

  const trendingWords = useMemo<TopKeywords.TrendingWords[]>(
    () => getWidgetTrendingWordsData(data),
    [data],
  );

  const isDataExist: boolean = useMemo(() => {
    if (isLoading) return true;
    if (!data) return false;

    const isTopWordsDataExist = topWords.some(({ values }) => {
      const brandsValues = Object.values(values);
      return brandsValues.some((brandsValue) => !!brandsValue.length);
    });

    const isTrendingWordsDataExist = trendingWords.some(({ values }) => {
      const brandsValues = Object.values(values);
      return brandsValues.some((brandsValue) => !!brandsValue.length);
    });

    return Boolean(isTopWordsDataExist || isTrendingWordsDataExist);
  }, [data, isLoading, topWords, trendingWords]);

  return { isDataExist };
};
