import { useMemo, CSSProperties } from "react";
import { PieChart } from "react-minimal-pie-chart";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./PieChartWithInnerLabels.module.scss";
import colors from "../../../data/colors";

interface Props {
  className?: string;
  style?: CSSProperties;
  data?: {
    brandName: string;
    value: number;
  }[];
  units?: string;
  fitTo?: "width" | "height";
}

export const PieChartWithInnerLabels = ({
  className = "",
  style,
  data = [],
  units = "%",
  fitTo = "width",
}: Props) => {
  const preparedPieChartData = useMemo(
    () =>
      [...data]
        .map(({ brandName, value }, i) => ({
          brandName,
          value,
          formattedValue: +value.toFixed(),
          color: colors[i]!,
        }))
        .sort((a, b) => b.value - a.value),
    [data],
  );

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const size =
    fitTo === "width"
      ? "100%"
      : Math.min(chartWrapperHeight, chartWrapperWidth);

  return preparedPieChartData.length ? (
    <div
      ref={chartWrapperRef}
      className={cx(styles.pieChartWithInnerLabels, className)}
      style={style}
    >
      <div
        className={styles.chartWrapper}
        style={{
          height: size,
          width: size,
        }}
      >
        <div className={styles.labels}>
          {preparedPieChartData.map((item, i) => (
            <div className={styles.label} title={item.brandName} key={i}>
              <div
                className={styles.labelIcon}
                style={{ backgroundColor: item.color }}
              >
                {i + 1}
              </div>
              <div className={styles.labelValue}>
                {item.formattedValue}
                {units}
              </div>
              <div className={styles.labelText}>{item.brandName}</div>
            </div>
          ))}
        </div>
        <PieChart
          className={styles.chart}
          animate
          animationDuration={500}
          animationEasing="ease-out"
          data={preparedPieChartData}
          lineWidth={20}
          label={(props) => {
            const value = props.dataEntry.formattedValue;
            return value ? value + units : "";
          }}
          labelPosition={90}
          labelStyle={{
            fill: "#efe9ea",
            fontSize: 4,
            fontWeight: 500,
          }}
          startAngle={-90}
        />
      </div>
    </div>
  ) : null;
};
