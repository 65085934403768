import { useMemo } from "react";
import cx from "classnames";

import styles from "./SwitcherButton.module.scss";

type Props = {
  leftSideText?: string;
  rightSideText?: string;
  isInitialSwitcherActive?: boolean;
  switcherSize?: {
    width: number;
    height: number;
  };
  className?: string;
  onChangeHandler: (isActive: boolean) => void;
};

export const SwitcherButton = ({
  leftSideText,
  rightSideText,
  isInitialSwitcherActive,
  switcherSize,
  onChangeHandler,
}: Props) => {
  const circleSize =
    switcherSize && Math.min(switcherSize.width, switcherSize.height);

  const onSwitcherClicked = () => {
    onChangeHandler(!isInitialSwitcherActive);
  };

  const circleStyles = useMemo(() => {
    return circleSize
      ? {
          width: circleSize - 5,
          height: circleSize - 5,
        }
      : {};
  }, [circleSize]);

  return (
    <div className={styles.switcherButton}>
      {leftSideText && <small className={styles.text}>{leftSideText}</small>}
      <button
        className={cx(
          styles.toggleButton,
          styles[isInitialSwitcherActive ? "Active" : ""],
        )}
        style={switcherSize ? switcherSize : {}}
        onClick={onSwitcherClicked}
      >
        <div className={styles.circle} style={circleStyles} />
      </button>
      {rightSideText && <small className={styles.text}>{rightSideText}</small>}
    </div>
  );
};
