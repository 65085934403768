import { FC } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./Breadcrumbs.module.scss";

// Inner imports
import type { BreadcrumbsProps } from "./types";

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  className = "",
  options,
}) => {
  const { t } = useTranslation();

  const renderOption = (option: ActionBar.BreadcrumbOption) => {
    const { title, linkOptions } = option;

    if (!linkOptions)
      return t(title, {
        keySeparator: false,
      });

    const { href, state = {} } = linkOptions;

    return (
      <Link
        to={{
          pathname: href,
          state,
        }}
      >
        {t(title, {
          keySeparator: false,
        })}
      </Link>
    );
  };

  return (
    <div id="breadcrumbs" className={cx(styles.breadcrumbs, className)}>
      {options.map((option) => (
        <span className={styles.breadcrumb} key={option.title}>
          {renderOption(option)}
        </span>
      ))}
    </div>
  );
};
