import { FC, useMemo } from "react";
import Table from "rsuite/Table";
import cx from "classnames";

import styles from "./CheckCell.module.scss";
import {
  CheckBoxEmpty,
  CheckBoxPartial,
  CheckBoxChecked,
} from "../../../../icons";

// Inner imports
import type { RowDataType, CheckCellProps, CheckedType } from "../../types";
import { getAllKeyWordsRecursion } from "../../utils";

const { Cell } = Table;

const CheckCell: FC<CheckCellProps> = ({
  rowData,
  onChecked,
  checkedKeys,
  ...props
}) => {
  const { label, value, children } = useMemo<RowDataType>(() => rowData, [
    rowData,
  ]);

  const isCheckBoxIsGroup = useMemo<boolean>(() => !!children?.length, [
    children?.length,
  ]);

  const allValues = useMemo<Set<string>>(() => {
    const allValues: Set<string> = new Set();

    if (isCheckBoxIsGroup) {
      getAllKeyWordsRecursion(children!, allValues);
    } else {
      allValues.add(value);
    }

    return allValues;
  }, [isCheckBoxIsGroup, children, value]);

  const checkedType = useMemo<CheckedType>(() => {
    if (isCheckBoxIsGroup) {
      let isAllChecked = true;
      let isSomeChecked = false;

      allValues.forEach((keyWord) => {
        if (!checkedKeys[keyWord]) {
          isAllChecked = false;
          return;
        } else {
          isSomeChecked = true;
        }
      });

      if (isAllChecked) return "checked";
      if (isSomeChecked) return "partial";
    }

    return checkedKeys[value] ? "checked" : "unchecked";
  }, [isCheckBoxIsGroup, checkedKeys, value, allValues]);

  const handleCheck = () => {
    if (isCheckBoxIsGroup) {
      if (checkedType === "checked") {
        onChecked([...allValues]);
      } else {
        const uncheckedGroupKeywords: Set<string> = new Set();

        allValues.forEach((keyWord) => {
          if (!checkedKeys[keyWord]) {
            uncheckedGroupKeywords.add(keyWord);
          }
        });

        onChecked([...uncheckedGroupKeywords]);
      }
    } else {
      onChecked([value]);
    }
  };

  const RenderIcon = (): JSX.Element => {
    switch (checkedType) {
      case "checked":
        return <CheckBoxChecked />;
      case "partial":
        return <CheckBoxPartial />;
      default:
        return <CheckBoxEmpty />;
    }
  };

  return (
    <Cell {...props} style={{ padding: 0 }} rowData={rowData}>
      <div
        className={cx(
          styles.checkBoxCell,
          styles[isCheckBoxIsGroup ? "group" : ""],
        )}
      >
        <button onClick={() => handleCheck()}>{RenderIcon()}</button>
        <div
          className={cx(
            styles.labelWrapper,
            styles[isCheckBoxIsGroup ? "group" : ""],
          )}
          title={label}
        >
          {label}
        </div>
      </div>
    </Cell>
  );
};

export { CheckCell };
