import { useState, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./AboutThis.module.scss";
import { Triangle } from "../../icons";
import { WIDGETS_TITLES } from "../../widgets/widgets";

// Inner imports
import type { WhatDoesItMean as WhatDoesItMeanType } from "./types";
import { WhatDoesItMean } from "./components/WhatDoesItMean/WhatDoesItMean";

type Props = {
  widgetId: WIDGET_IDS_TYPES;
  widgetView?: string;
  className?: string;
  conclusion?: string;
};

export const AboutThis = memo(
  ({ widgetId, widgetView = "", className = "", conclusion = "" }: Props) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const aboutTheMetric: string | JSX.Element = useMemo(() => {
      const _aboutTheMetric =
        WIDGETS_TITLES[widgetId]?.aboutTheMetric?.[widgetView] ||
        WIDGETS_TITLES[widgetId]?.aboutTheMetric?.default ||
        "";

      if (typeof _aboutTheMetric === "string") return t(_aboutTheMetric);

      return _aboutTheMetric;
    }, [t, widgetId, widgetView]);

    const title: string = useMemo(
      () =>
        t(
          WIDGETS_TITLES[widgetId]?.secondaryTitle?.[widgetView] ||
            WIDGETS_TITLES[widgetId]?.secondaryTitle?.default ||
            "",
        ),
      [t, widgetId, widgetView],
    );

    const whatDoesItMean: WhatDoesItMeanType = useMemo(
      () => WIDGETS_TITLES[widgetId]?.whatDoesItMean || [],
      [widgetId],
    );

    const isCanShow = !!(
      title ||
      conclusion ||
      aboutTheMetric ||
      whatDoesItMean.length
    );

    if (!isCanShow) return null;

    return (
      <div
        className={cx(
          styles.aboutThis,
          styles[className],
          styles[isOpen ? "aboutThisOpened" : ""],
        )}
      >
        <button
          className={styles.dropDownButton}
          onClick={() => setIsOpen((state) => !state)}
        >
          <span>{t("about_this_title")}</span>
          <Triangle size={10} />
        </button>
        {isOpen && (
          <div className={styles.aboutThisContent}>
            {title && <h3 className={styles.title}>{title}</h3>}
            {conclusion && (
              <div className={styles.conclusion}>
                <p>{t("about_this_conclusion")}</p>
                <span>{conclusion}</span>
              </div>
            )}
            {aboutTheMetric && (
              <div className={styles.conclusion}>
                <p>{t("about_this_about_metric")}</p>
                <span>{aboutTheMetric}</span>
              </div>
            )}
            {!!whatDoesItMean?.length && (
              <WhatDoesItMean data={whatDoesItMean} />
            )}
          </div>
        )}
      </div>
    );
  },
);
