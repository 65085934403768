import isPlainObject from "lodash/isPlainObject";
import firestore from "../../firestore";
import axios from "../../axios";
import {
  // normalizeNumber,
  // parseInteger,
  // parseNonEmptyStringsArray,
  getLineChartDataForOneYear,
  isNumber,
} from "../../utils";
import {
  formateBusinessDriversWidgetData,
  formateReputationWidgetData,
} from "../actions";
// import { initialState } from "./reducer";
import DATE_RANGES from "../../data/dateRanges";
import DATE_RANGES_WITH_OVERALL from "../../data/dateRangesWithOverall";
import esgParameters from "../../data/esgParameters";
import funnelDescription from "../../data/funnelDescription";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

export const getBrands = async (searchId: string) => {
  const doc = await firestore().collection("brands").doc(searchId).get();
  let brands: Store.Brands = [];

  if (doc.exists) {
    const data = doc.data();
    if (data) {
      const mainBrand = {
        id: data.name || "mainBrand",
        name: data.displayName || "YOUR BRAND",
      };
      const competitorBrands = data.index
        // .slice(0, 5)
        .map((brandId: string, i: number) => {
          return {
            id: brandId || "competitorBrand" + i,
            name:
              data.meltwater?.[brandId]?.base ||
              data.yougov?.[brandId]?.name ||
              "YOUR COMPETITOR BRAND " + i,
          };
        });
      brands = [mainBrand, ...competitorBrands];
    }
  }

  return brands;
};

export async function getShareOfSearchWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-search-share"]> {
  const doc = await firestore()
    .collection("result-search-share")
    .doc(searchId)
    .get();
  const data = doc.data();

  return parseShareOfSearchWidget(data, brands);
}

export async function calculateShareOfSearchWidget(searchId: string) {
  return axios.post(`search-share-widget-${urlInsertion}-api/update-one`, {
    brandId: searchId,
  });
}

export function parseShareOfSearchWidget(
  data: firestore.DocumentData | undefined,
  brands: Store.Brands,
) {
  const parsedData: ShareOfSearch.Data = {
    brands,
    pieChart: {},
    lineChart: [],
    lastUpdate: "",
  };

  DATE_RANGES.forEach(({ value: range }) => {
    const rangeData = data?.pieChart?.[range];

    const values: {
      brandName: string;
      value: number;
    }[] = [];

    brands.forEach((brand) => {
      const value =
        rangeData?.values[brand.id]?.percentage ?? rangeData?.values[brand.id];
      if (typeof value !== "number") return;

      const additionalValue = rangeData?.values[brand.id]?.volume;

      values.push({
        brandName: brand.name,
        value: +value.toFixed(2),
        ...(typeof additionalValue === "number" && { additionalValue }),
      });
    });

    if (!values.length) return;

    parsedData.pieChart[range] = {
      startDate: rangeData?.startDate || "",
      endDate: rangeData?.endDate || "",
      values,
    };
  });

  const lineChartData = data?.lineChart;

  if (Array.isArray(lineChartData)) {
    lineChartData.forEach((item) => {
      const date = item?.date;
      if (!Date.parse(date)) return;

      const values: { [brandName: string]: number } = {};
      const additionalValues: { [brandName: string]: number } = {};

      brands.forEach(({ id, name }) => {
        const value = item?.values?.[id]?.percentage;
        if (typeof value !== "number") return;

        values[name] = value;

        const additionalValue = item?.values[id]?.volume;

        additionalValues[name] = additionalValue;
      });

      if (!Object.keys(values).length) return;

      parsedData.lineChart.push({
        date,
        values,
        ...(Object.keys(additionalValues).length && { additionalValues }),
      });
    });
  }

  return {
    ...parsedData,
    lineChart: parsedData.lineChart.reverse(),
    lastUpdate: data?._updatedAt || "",
  };
}

export async function getShareOfEarnedMediaWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-media-share"]> {
  const doc = await firestore()
    .collection("result-media-share")
    .doc(searchId)
    .get();

  const data = doc.data();

  const parsedData: ShareOfEarnedMedia.Data = {
    brands,
    pieChart: {},
    lineChart: [],
    _updatedAt: "",
  };

  DATE_RANGES.forEach(({ value: range }) => {
    const rangeData = data?.pieChart?.[range];

    const values: {
      brandName: string;
      value: number;
    }[] = [];

    brands.forEach((brand) => {
      const value =
        rangeData?.values[brand.id]?.percentage || rangeData?.values[brand.id];
      if (typeof value !== "number") return;

      const additionalValue = rangeData?.values[brand.id]?.volume;

      values.push({
        brandName: brand.name,
        value: +value.toFixed(2),
        ...(typeof additionalValue === "number" && { additionalValue }),
      });
    });

    if (!values.length) return;

    parsedData.pieChart[range] = {
      startDate: rangeData?.startDate || "",
      endDate: rangeData?.endDate || "",
      values,
    };
  });

  const lineChartData = data?.lineChart;

  if (Array.isArray(lineChartData)) {
    lineChartData.forEach((item) => {
      const date = item?.date;
      if (!Date.parse(date)) return;

      const values: { [brandName: string]: number } = {};
      const additionalValues: { [brandName: string]: number } = {};

      brands.forEach(({ id, name }) => {
        const value = item?.values?.[id]?.percentage || item?.values?.[id];
        if (!isNumber(value)) return;

        values[name] = value;

        const additionalValue = item?.values[id]?.volume;
        if (!isNumber(additionalValue)) return;

        additionalValues[name] = additionalValue;
      });

      if (!Object.keys(values).length) return;

      parsedData.lineChart.push({
        date,
        values,
        ...(Object.keys(additionalValues).length && { additionalValues }),
      });
    });
  }

  if (data?._updatedAt) {
    parsedData._updatedAt = data?._updatedAt;
  }

  return {
    ...parsedData,
    // lineChart: getLineChartDataForOneYear(parsedData.lineChart),
    lineChart: parsedData.lineChart.reverse(),
  };
}

export async function getBusinessDriversWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-business-drivers"]> {
  const doc = await firestore()
    .collection("result-business-drivers")
    .doc(searchId)
    .get();

  const data = doc.data();

  return formateBusinessDriversWidgetData(brands, data);
}

export async function getAwarenessWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-awareness"]> {
  const doc = await firestore()
    .collection("result-awareness")
    .doc(searchId)
    .get();

  const data = doc.data();

  const parsedData: Awareness.Data = {
    brands,
    pieChart: {},
    lineChart: [],
  };

  if (data) {
    const mainBrand = brands[0];

    DATE_RANGES.forEach(({ value: range }) => {
      const rangeData = data?.pieChart?.[range];

      const mainBrandValue = rangeData?.values[mainBrand?.id!];
      if (typeof mainBrandValue !== "number") return;

      const values = [
        {
          brandName: mainBrand!.name,
          value: +mainBrandValue.toFixed(2),
        },
      ];

      brands.slice(1).forEach((brand) => {
        const brandValue = rangeData?.values[brand.id];
        if (typeof brandValue !== "number") return;

        values.push({
          brandName: brand.name,
          value: +brandValue.toFixed(2),
        });
      });

      parsedData.pieChart[range] = {
        startDate: rangeData?.startDate || "",
        endDate: rangeData?.endDate || "",
        values,
      };
    });

    const lineChartData = data?.lineChart;

    if (Array.isArray(lineChartData)) {
      lineChartData.forEach((item) => {
        const date = item?.date;
        if (!Date.parse(date)) return;

        const values: { [brandName: string]: number } = {};

        brands.forEach(({ id, name }) => {
          const brandValue = item?.values?.[id];
          if (typeof brandValue !== "number") return;

          values[name] = brandValue;
        });
        if (!Object.keys(values).length) return;

        parsedData.lineChart.push({
          date,
          values,
        });
      });
    }
  }

  return {
    ...parsedData,
    lineChart: getLineChartDataForOneYear(parsedData.lineChart),
  };
}

export async function getReputationIndexWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-reputation-index"]> {
  const doc = await firestore()
    .collection("result-reputation-index")
    .doc(searchId)
    .get();

  const data = doc.data();

  return formateReputationWidgetData(brands).index(data);
}

export async function getReputationSentimentWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-reputation-sentiment"]> {
  const doc = await firestore()
    .collection("result-reputation-sentiment")
    .doc(searchId)
    .get();

  const data = doc.data();

  return formateReputationWidgetData(brands).sentiment(data);
}

export async function getReputationDistributionWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-reputation-distribution"]> {
  const doc = await firestore()
    .collection("result-reputation-distribution")
    .doc(searchId)
    .get();

  const data = doc.data();

  return formateReputationWidgetData(brands).distribution(data);
}

export async function getEsgIndexWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-esg-index"]> {
  const parsedData: Store.Widgets["result-esg-index"] = [];

  const doc = await firestore()
    .collection("result-esg-index")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (data) {
    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      const parsedBrandData: Store.Widgets["result-esg-index"][number]["brandData"] = {};

      // LINECHART
      const parsedLineChartData: Store.Widgets["result-esg-index"][number]["brandData"]["lineChart"] = [];

      const lineChartData = data[id]?.lineChart;

      if (Array.isArray(lineChartData)) {
        lineChartData.forEach((item) => {
          if (isNaN(Date.parse(item?.date))) return;

          const values: { [label: string]: number } = {};

          labels.forEach(({ name, key }) => {
            const value = item?.values?.[key];

            if (!Number.isFinite(value)) return;

            values[name] = value;
          });

          if (!Object.keys(values).length) return;

          parsedLineChartData.push({
            date: item.date,
            values,
          });
        });
      }

      // const oneYearData = getLineChartDataForOneYear(parsedLineChartData);

      // if (oneYearData.length) {
      //   parsedBrandData.lineChart = oneYearData;
      // }

      if (parsedLineChartData.length) {
        parsedBrandData.lineChart = parsedLineChartData.reverse();
      }

      // PIECHART
      const brandPieChartData = data[id]?.pieChart;

      const startDate = !isNaN(Date.parse(brandPieChartData?.startDate))
        ? brandPieChartData.startDate
        : undefined;
      const endDate = !isNaN(Date.parse(brandPieChartData?.endDate))
        ? brandPieChartData.endDate
        : undefined;

      const values: { [label: string]: number } = {};

      labels.forEach(({ name, key }) => {
        const value = brandPieChartData?.values?.[key];

        if (!Number.isFinite(value)) return;

        values[name] = value;
      });

      if (startDate && endDate && Object.keys(values).length) {
        parsedBrandData.pieChart = {
          startDate,
          endDate,
          values,
        };
      }

      if (Object.keys(parsedBrandData).length) {
        parsedData.push({
          brandName: name,
          brandData: parsedBrandData,
          _updatedAt: data?._updatedAt,
        });
      }
    });
  }

  return parsedData;
}

export async function getEsgSentimentWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-esg-sentiment"]> {
  const parsedData: Store.Widgets["result-esg-sentiment"] = [];

  const doc = await firestore()
    .collection("result-esg-sentiment")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (data) {
    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      const parsedBrandData: Store.Widgets["result-esg-sentiment"][number]["brandData"] = {};

      // LINECHART
      const parsedLineChartData: Store.Widgets["result-esg-sentiment"][number]["brandData"]["lineChart"] = [];

      const lineChartData = data[id]?.lineChart;
      if (Array.isArray(lineChartData)) {
        lineChartData.forEach((item) => {
          if (isNaN(Date.parse(item?.date))) return;

          const values: {
            [label: string]: {
              positive: number;
              negative: number;
              neutral: number;
            };
          } = {};
          labels.forEach(({ name, key }) => {
            const positive = item.values?.[key]?.positive;
            const negative = item.values?.[key]?.negative;
            const neutral = item.values?.[key]?.neutral;

            if (
              Number.isFinite(positive) &&
              Number.isFinite(negative) &&
              Number.isFinite(neutral)
            ) {
              values[name] = {
                positive,
                negative,
                neutral,
              };
            }
          });

          if (!Object.keys(values).length) return;

          parsedLineChartData.push({
            date: item.date,
            values,
          });
        });

        // const oneYearData = getLineChartDataForOneYear(parsedLineChartData);

        // if (oneYearData.length) {
        //   parsedBrandData.lineChart = oneYearData;
        // }

        if (parsedLineChartData.length) {
          parsedBrandData.lineChart = parsedLineChartData.reverse();
        }
      }

      // PIECHART
      const parsedPieChartData: Store.Widgets["result-esg-sentiment"][number]["brandData"]["pieChart"] = {};

      const pieChartData = data[id]?.pieChart;
      if (pieChartData) {
        DATE_RANGES.forEach(({ value: range }) => {
          const rangeData = pieChartData[range];
          if (!rangeData) return;

          const startDate = !isNaN(Date.parse(rangeData.startDate))
            ? rangeData.startDate
            : undefined;
          const endDate = !isNaN(Date.parse(rangeData.endDate))
            ? rangeData.endDate
            : undefined;

          const values: {
            [label: string]: {
              positive: number;
              negative: number;
              neutral: number;
            };
          } = {};

          labels.forEach(({ name, key }) => {
            const positive = rangeData.values?.[key]?.positive;
            const negative = rangeData.values?.[key]?.negative;
            const neutral = rangeData.values?.[key]?.neutral;

            if (
              Number.isFinite(positive) &&
              positive >= 0 &&
              Number.isFinite(negative) &&
              negative >= 0 &&
              Number.isFinite(neutral) &&
              neutral >= 0
            ) {
              values[name] = {
                positive,
                negative,
                neutral,
              };
            }
          });

          if (startDate && endDate && Object.keys(values).length) {
            parsedPieChartData[range] = {
              startDate,
              endDate,
              values,
            };
          }
        });

        if (Object.keys(parsedPieChartData).length) {
          parsedBrandData.pieChart = parsedPieChartData;
        }
      }

      const lastSyncData = data?.updatedAt || data?._updatedAt;

      if (Object.keys(parsedBrandData).length) {
        parsedData.push({
          brandName: name,
          brandData: parsedBrandData,
          _updatedAt: lastSyncData,
        });
      }
    });
  }

  return parsedData;
}

export async function getEsgDistributionWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-esg-distribution"]> {
  const parsedData: Store.Widgets["result-esg-distribution"] = [];

  const doc = await firestore()
    .collection("result-esg-distribution")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (data) {
    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      //BARCHART
      const brandData = data[id]?.barChart;

      if (brandData) {
        const parsedBarChartData: Store.Widgets["result-esg-distribution"][number]["brandData"]["barChart"] = {};

        DATE_RANGES.forEach(({ value: range }) => {
          const rangeData = brandData[range];
          if (!rangeData) return;

          const startDate = !isNaN(Date.parse(rangeData.startDate))
            ? rangeData.startDate
            : undefined;
          const endDate = !isNaN(Date.parse(rangeData.endDate))
            ? rangeData.endDate
            : undefined;

          const values: { [label: string]: number } = {};
          labels.forEach(({ name, key }) => {
            const value = rangeData?.values?.[key];

            if (!Number.isFinite(value)) return;

            values[name] = value;
          });

          if (startDate && endDate && Object.keys(values).length) {
            parsedBarChartData[range] = {
              startDate,
              endDate,
              values,
            };
          }
        });

        if (Object.keys(parsedBarChartData).length) {
          parsedData.push({
            brandName: name,
            brandData: {
              barChart: parsedBarChartData,
            },
          });
        }
      }
    });
  }

  return parsedData;
}

// export async function getDataScoreWidget(
//   searchId: string,
// ): Promise<Store.Widgets["dataScore"]> {
//   const doc = await firestore()
//     .collection("result-robustness-score")
//     .doc(searchId)
//     .get();

//   const data = doc.data();

//   if (!data) return initialState["dataStructure"]!.dataScore;

//   return {
//     total: {
//       robustness: {
//         percentage: normalizeNumber(
//           parseInteger(data.total?.robustness?.percentage) || 0,
//           100,
//           0,
//         ),
//       },
//       socialNetworks: {
//         percentage: parseInteger(data.total?.socialNetworks?.percentage) || 0,
//         quantity: parseInteger(data.total?.socialNetworks?.quantity) || 0,
//         links:
//           parseNonEmptyStringsArray(data.total?.socialNetworks?.links) || [],
//       },
//       blogs: {
//         percentage: parseInteger(data.total?.blogs?.percentage) || 0,
//         quantity: parseInteger(data.total?.blogs?.quantity) || 0,
//         links: parseNonEmptyStringsArray(data.total?.blogs?.links) || [],
//       },
//       newsAndEditorial: {
//         percentage: parseInteger(data.total?.newsAndEditorial?.percentage) || 0,
//         quantity: parseInteger(data.total?.newsAndEditorial?.quantity) || 0,
//         links:
//           parseNonEmptyStringsArray(data.total?.newsAndEditorial?.links) || [],
//       },
//       forums: {
//         percentage: parseInteger(data.total?.forums?.percentage) || 0,
//         quantity: parseInteger(data.total?.forums?.quantity) || 0,
//         links: parseNonEmptyStringsArray(data.total?.forums?.links) || [],
//       },
//       search: {
//         percentage: parseInteger(data.total?.search?.percentage) || 0,
//       },
//       videos: {
//         percentage: parseInteger(data.total?.videos?.percentage) || 0,
//         quantity: parseInteger(data.total?.videos?.quantity) || 0,
//         links: parseNonEmptyStringsArray(data.total?.videos?.links) || [],
//       },
//       opinionPolls: {
//         percentage: parseInteger(data.total?.opinionPolls?.percentage) || 0,
//       },
//       productReviews: {
//         percentage: parseInteger(data.total?.productReviews?.percentage) || 0,
//         quantity: parseInteger(data.total?.productReviews?.quantity) || 0,
//         links:
//           parseNonEmptyStringsArray(data.total?.productReviews?.links) || [],
//       },
//     },
//     timeline: Array.isArray(data.timeline)
//       ? data.timeline.reduce((acc, item) => {
//           const date = !isNaN(Date.parse(item.date)) ? item.date : undefined;

//           const values = Object.entries(item.values).reduce(
//             (acc, [dataType, percentage]) => {
//               if (
//                 dataType in initialState["dataStructure"]!.dataScore.total &&
//                 Number.isInteger(percentage)
//               )
//                 acc.push([dataType, percentage as number]);
//               return acc;
//             },
//             [] as [string, number][],
//           );

//           if (date && values.length)
//             acc.push({
//               date,
//               values: Object.fromEntries(values),
//             });

//           return acc;
//         }, [])
//       : [],
//     startDate: !isNaN(Date.parse(data.startDate)) ? data.startDate : undefined,
//     endDate: !isNaN(Date.parse(data.endDate)) ? data.endDate : undefined,
//   };
// }

export async function getRobustnessWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-robustness-score"]> {
  const parsedData: Store.Widgets["result-robustness-score"] = {
    brands,
    cellChart: {},
    lineChart: {},
    dateFrom: "",
    dateTo: "",
  };

  const doc = await firestore()
    .collection("result-robustness-score")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (!data) return parsedData;

  const { dateFrom, dateTo } = data;

  brands.forEach((brand) => {
    const brandData = data[brand.id];
    if (brandData) {
      parsedData.cellChart![brand.id] = brandData.cellChart;
      parsedData.lineChart![brand.id] = brandData.lineChart;
    }
  });

  parsedData.dateFrom = dateFrom;
  parsedData.dateTo = dateTo;

  return parsedData;
}

function parseFunnelValues(
  brandData: any,
  type: "funnel" | "shareOfSearchFunnel",
) {
  if (!brandData) return;

  const labels = Object.keys(funnelDescription[type]);

  const values = labels.reduce(
    (acc, label, i) => {
      const value = brandData["section" + (i + 1)];

      if (isNumber(value)) {
        acc.push({
          label,
          value: {
            percentage: value,
          },
        });
      }

      if (typeof value === "object" && !Array.isArray(value)) {
        const { percentage, volume } = value;

        if (isNumber(percentage)) {
          acc.push({
            label,
            value: {
              percentage,
              ...(isNumber(volume) ? { volume } : {}),
            },
          });
        }
      }

      return acc;
    },
    [] as {
      label: typeof labels[number];
      value: {
        percentage: number;
        volume?: number;
      };
    }[],
  );

  return values;
}

export function formattingFunnelWidgetData(values: any, brands: Store.Brands) {
  const parsedData: Store.Widgets["result-funnel"] = {};
  const mainBrand = brands[0];

  if (!values) return parsedData;

  DATE_RANGES.forEach(({ value: range }) => {
    const rangeData = values[range];
    if (!rangeData) return;

    if (isNaN(Date.parse(rangeData.startDate))) return;

    if (isNaN(Date.parse(rangeData.endDate))) return;

    const mainBrandValues = parseFunnelValues(
      rangeData.values?.[mainBrand?.id!],
      "funnel",
    );
    if (!mainBrandValues) return;

    const brandValues = [
      {
        brandName: mainBrand!.name,
        values: mainBrandValues,
      },
    ];

    brands.slice(1).forEach((brand) => {
      const values = parseFunnelValues(rangeData.values?.[brand.id], "funnel");
      if (!values) return;

      brandValues.push({
        brandName: brand.name,
        values,
      });
    });

    parsedData[range] = {
      startDate: rangeData.startDate,
      endDate: rangeData.endDate,
      brandValues,
    };
  });

  return parsedData;
}

export async function getFunnelWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-funnel"]> {
  const doc = await firestore().collection("result-funnel").doc(searchId).get();

  const data = doc.data();

  const values = data?.values;

  return formattingFunnelWidgetData(values, brands);
}

export function formattingShareOfSearchFunnelWidgetData(
  values: any,
  brands: Store.Brands,
) {
  const parsedData: Store.Widgets["result-share-of-search-funnel"] = {};
  const mainBrand = brands[0];

  if (typeof values !== "object" || values === null) return parsedData;

  DATE_RANGES.forEach(({ value: range }) => {
    const rangeData = values[range];
    if (!rangeData) return;

    if (isNaN(Date.parse(rangeData.dateFrom))) return;

    if (isNaN(Date.parse(rangeData.dateTo))) return;

    const mainBrandValues = parseFunnelValues(
      rangeData.values?.[mainBrand?.id!],
      "shareOfSearchFunnel",
    );

    if (!mainBrandValues) return;

    const brandValues = [
      {
        brandName: mainBrand!.name,
        values: mainBrandValues,
      },
    ];

    brands.slice(1).forEach((brand) => {
      const values = parseFunnelValues(
        rangeData.values?.[brand.id],
        "shareOfSearchFunnel",
      );
      if (!values) return;

      brandValues.push({
        brandName: brand.name,
        values,
      });
    });

    parsedData[range] = {
      startDate: rangeData.dateFrom,
      endDate: rangeData.dateTo,
      brandValues,
    };
  });

  return parsedData;
}

export async function getShareOfSearchFunnelWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-share-of-search-funnel"]> {
  const doc = await firestore()
    .collection("result-share-of-search-funnel")
    .doc(searchId)
    .get();

  const { data } = doc.data() || {};

  const values = data?.funnelChart;

  return formattingShareOfSearchFunnelWidgetData(values, brands);
}

export async function getTopKeywordsWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-top-trend-keywords"]> {
  const parsedData: TopKeywords.Data = {
    brands,
    dateFrom: "",
    dateTo: "",
    topWords: [],
    trendingWords: [],
  };

  const doc = await firestore()
    .collection("result-top-trend-keywords")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (data) {
    try {
      const { dateFrom, dateTo, topWords, trendingWords } = data;

      if (typeof dateFrom === "string") {
        parsedData.dateFrom = dateFrom;
      }

      if (typeof dateTo === "string") {
        parsedData.dateTo = dateTo;
      }

      if (Array.isArray(topWords) || isPlainObject(topWords)) {
        parsedData.topWords = topWords;
      }

      if (Array.isArray(trendingWords) || isPlainObject(trendingWords)) {
        parsedData.trendingWords = trendingWords;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return parsedData;
}

export async function getSentimentIndexWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-sentiment-index"]> {
  const parsedData: Store.Widgets["result-sentiment-index"] = {
    brands,
    lineChart: [],
    pieChart: {},
    _updatedAt: "",
  };

  const doc = await firestore()
    .collection("result-sentiment-index")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (data) {
    const { lineChart, pieChart, _updatedAt } = data || {};
    lineChart &&
      (parsedData["lineChart"] = reSortSentimentIndexLineChart(
        lineChart,
        brands,
      ));
    pieChart &&
      (parsedData["pieChart"] = pieChart) &&
      (parsedData["_updatedAt"] = _updatedAt);
  }

  return parsedData;
}

function parseDataItem(item: any, brands: Store.Brands) {
  const date = item?.date;
  if (!Date.parse(date)) return;

  const values: { [brandName: string]: number } = {};

  brands.forEach(({ id, name }) => {
    const brandValue = item?.values?.[id];
    if (!Number.isFinite(brandValue)) return;

    values[name] = brandValue as number;
  });

  if (!Object.keys(values).length) return;

  return {
    date,
    values,
    isPartial: Boolean(item?.isPartial),
  };
}

export function parseSearchTrendsData(parsingData: any, brands: Store.Brands) {
  const parsedData: Store.Widgets["result-search-trends"] = {
    brands,
  };

  const lineChartData = parseLineChart(brands, parsingData?.data);

  if (lineChartData) {
    parsedData.lineChart = lineChartData;
  }

  function parseLineChart(brands: Store.Brands, data: any) {
    if (typeof data === "object" && !Array.isArray(data)) {
      const parsedLineChartData: SearchTrend.Data["lineChart"] = {};

      try {
        const newSearchTrendData = data?.periodChartsMap;

        DATE_RANGES_WITH_OVERALL.forEach(({ value }) => {
          const formattedData: SearchTrend.LineChart.Data = [];

          const dataForPeriod = newSearchTrendData?.[value]?.lineChart;

          if (Array.isArray(dataForPeriod)) {
            dataForPeriod.forEach((item) => {
              const parsedItem = parseDataItem(item, brands);

              if (parsedItem) {
                formattedData.push(parsedItem);
              }
            });
          }

          parsedLineChartData[value] = formattedData;
        });
      } catch (err) {
        console.error(err);
      }

      return parsedLineChartData;
    }
  }

  return parsedData;
}

export async function getSearchTrendWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-sentiment-trends"]> {
  const doc = await firestore()
    .collection("result-search-trends")
    .doc(searchId)
    .get();

  const result = doc.data();
  //  parsing data for lineChart
  return parseSearchTrendsData(result, brands);
}

export async function getSentimentDriversWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-sentiment-drivers"]> {
  const doc = await firestore()
    .collection("result-sentiment-drivers")
    .doc(searchId)
    .get();

  const res = doc.data();
  const resData = res?.data;
  const resHiddenWords = res?.hiddenWords;

  const parsedData: Store.Widgets["result-sentiment-drivers"] = {
    brands,
    chartsData: {},
    hiddenWords: {},
  };

  if (resData) {
    if (typeof resData === "object" && !!Object.keys(resData).length) {
      for (let brandName in resData) {
        const brandData = resData[brandName];

        if (Array.isArray(brandData)) {
          parsedData.chartsData[brandName] = brandData || [];
        }
      }
    }
  }

  if (resHiddenWords) {
    if (
      typeof resHiddenWords === "object" &&
      !!Object.keys(resHiddenWords).length
    ) {
      parsedData.hiddenWords = resHiddenWords;
    }
  }

  return parsedData;
}

export async function getSentimentTrendsWidget(
  searchId: string,
  brands: Store.Brands,
): Promise<Store.Widgets["result-sentiment-trends"]> {
  const parsedData: Store.Widgets["result-sentiment-trends"] = {
    brands,
  };

  const doc = await firestore()
    .collection("result-sentiment-trends")
    .doc(searchId)
    .get();

  const res = doc.data();
  const data = res?.data;

  // linechart
  const lineChartData = parseLineChart(brands, data?.periodLineChartMap);

  if (lineChartData) {
    parsedData.lineChart = lineChartData;
  }

  // marketometer
  const marketometerChartData = data?.speedometerChart;

  if (marketometerChartData) {
    const thisMonthItem = parseMarketometerDataItem(
      marketometerChartData.thisMonth,
    );

    const oneMonthAgoItem = parseMarketometerDataItem(
      marketometerChartData.oneMonthAgo,
    );
    const twoMonthsAgoItem = parseMarketometerDataItem(
      marketometerChartData.twoMonthsAgo,
    );
    const threeMonthsAgoItem = parseMarketometerDataItem(
      marketometerChartData.threeMonthsAgo,
    );
    const sixMonthsAgoItem = parseMarketometerDataItem(
      marketometerChartData.sixMonthsAgo,
    );
    const oneYearAgoItem = parseMarketometerDataItem(
      marketometerChartData.oneYearAgo,
    );
    const allTimeItem = parseMarketometerDataItem(
      marketometerChartData.overall,
    );

    const lastAvailablePeriodItem =
      oneMonthAgoItem ||
      twoMonthsAgoItem ||
      threeMonthsAgoItem ||
      sixMonthsAgoItem ||
      oneYearAgoItem ||
      allTimeItem;

    if (thisMonthItem && typeof lastAvailablePeriodItem !== "undefined") {
      parsedData.speedometerChart = {
        thisMonth: thisMonthItem,
        oneMonthAgo: parseMarketometerDataItem(
          marketometerChartData.oneMonthAgo,
        ),
        twoMonthsAgo: parseMarketometerDataItem(
          marketometerChartData.twoMonthsAgo,
        ),
        threeMonthsAgo: parseMarketometerDataItem(
          marketometerChartData.threeMonthsAgo,
        ),
        sixMonthsAgo: parseMarketometerDataItem(
          marketometerChartData.sixMonthsAgo,
        ),
        oneYearAgo: parseMarketometerDataItem(marketometerChartData.oneYearAgo),
        allTime: parseMarketometerDataItem(marketometerChartData.overall),
      };
    }
  }

  return parsedData;

  // utils
  function parseMarketometerDataItem(item: any) {
    const date = item?.date;
    if (!Date.parse(date)) return;

    const values: { [brandName: string]: number } = {};

    brands.forEach(({ id, name }) => {
      const brandValue = item?.values?.[id];
      if (!Number.isFinite(brandValue)) return;

      values[name] = brandValue as number;
    });

    if (!Object.keys(values).length) return;

    return {
      date,
      values,
    };
  }

  function parseLineChart(brands: Store.Brands, data: any) {
    if (typeof data === "object" && !Array.isArray(data)) {
      const parsedLineChartData: SearchTrend.Data["lineChart"] = {};
      try {
        DATE_RANGES_WITH_OVERALL.forEach(({ value }) => {
          const formattedData: SearchTrend.LineChart.Data = [];

          const dataForPeriod = data?.[value];

          if (Array.isArray(dataForPeriod)) {
            dataForPeriod.forEach((item) => {
              const parsedItem = parseDataItem(item, brands);

              if (parsedItem) {
                formattedData.push(parsedItem);
              }
            });
          }

          parsedLineChartData[value] = formattedData;
        });
      } catch (err) {
        console.error(err);
      }

      return parsedLineChartData;
    }
  }
}

export async function getAttitudeWidget(
  searchId: string,
  brands: Store.Brands,
) {
  const parsedData: Store.Widgets["result-attitude"] = {
    brands,
    pieChart: {},
    lineChart: [],
  };

  const doc = await firestore()
    .collection("result-attitude")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (doc.exists && typeof data === "object" && !Array.isArray(data)) {
    try {
      const { pieChart, lineChart, __updatedAt } = data;

      const isPieChartDataExist =
        pieChart?.[
          "oneMonth" || "twoMonths" || "threeMonths" || "sixMonths" || "oneYear"
        ];

      if (isPieChartDataExist) {
        parsedData.pieChart = data["pieChart"];
      }

      if (Array.isArray(lineChart)) {
        parsedData.lineChart = lineChart;
      }

      if (typeof __updatedAt === "string") {
        parsedData._updatedAt = __updatedAt;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return parsedData;
}

export async function getAssociatedWordsWidget(
  searchId: string,
  brands: Store.Brands,
) {
  const parsedData: Store.Widgets["result-associated-words"] = {
    brands,
    radarChart: {},
  };

  const doc = await firestore()
    .collection("result-associated-words")
    .doc(searchId)
    .get();

  const data = doc.data();

  if (doc.exists && typeof data === "object" && !Array.isArray(data)) {
    const brandsOptions = Object.keys(data?.radarChart) || [];

    const DATE_RANGES: string[] = [
      "oneMonth",
      "twoMonths",
      "threeMonths",
      "sixMonths",
      "oneYear",
    ];

    const getData = (
      item: GeneralAttributes.ResponseItem,
    ): GeneralAttributes.Item | undefined => {
      if (!item?.data) return;

      const itemData: GeneralAttributes.ItemDataValue[] = [];
      const initialItemData = item?.data;
      if (Array.isArray(initialItemData)) {
        initialItemData.forEach(
          ({ attribute, values, descriptor: initialWords }) => {
            const words: string[] = [];
            if (Array.isArray(initialWords)) {
              initialWords.forEach((initialWord) => {
                if (initialWord && typeof initialWord === "string")
                  words.push(initialWord.toUpperCase());
              });
            }
            itemData.push({
              attribute: attribute.toUpperCase(),
              values: typeof values !== "string" ? { ...values } : {},
              words: words.slice(0, 3),
            });
          },
        );
      }

      if (itemData.length < 4) return;
      return {
        startDate: item.dateFrom || "",
        endDate: item.dateTo || "",
        data: itemData,
      };
    };

    const setBrandItemValue = (periodsArray: string[], brandItem: string) => {
      let result: GeneralAttributes.BrandDataItem = {};
      periodsArray.forEach((period: string) => {
        result = {
          ...result,
          [period]: getData(data.radarChart[brandItem][period]),
        };
      });

      return result;
    };

    const formateData = (brandsOptions: string[]) => {
      let result: GeneralAttributes.DataItem = {};
      brandsOptions.forEach((brandItem: string) => {
        result = {
          ...result,
          [brandItem]: setBrandItemValue(DATE_RANGES, brandItem),
        };
      });

      return result;
    };

    parsedData.radarChart = formateData(brandsOptions);
    parsedData._updatedAt = data?._updatedAt;
  }

  return parsedData;
}

export async function getInterestDriverComparisonWidget(
  searchId: string,
  brands: Store.Brands,
) {
  type DateRange =
    | "oneMonth"
    | "twoMonths"
    | "threeMonths"
    | "sixMonths"
    | "oneYear";

  const parsedData: Store.Widgets["result-position-vs-competition"] = {
    brands,
  };

  const doc = await firestore()
    .collection("result-position-vs-competition")
    .doc(searchId)
    .get();

  const res = doc.data();

  if (doc.exists && typeof res === "object" && !Array.isArray(res)) {
    const quadrantChartData = res.quadrantChart ? res.quadrantChart : res;

    const normalizeValue = (number: number): number => {
      if (number < -100) return -100;
      if (number > 100) return 100;
      return number;
    };

    const getData = (
      dateRange: DateRange,
    ): PositionVsCompetition.Item | undefined => {
      const rangeData = quadrantChartData[dateRange];
      const initialAttributes = quadrantChartData[dateRange]?.attributes;
      const attributes: string[] = [];

      if (Array.isArray(initialAttributes)) {
        initialAttributes.forEach((attribute) => {
          if (attribute && typeof attribute === "string") {
            const modifiedAttribute = attribute
              .replace(/People/i, "Service")
              .replace(/Proposal/i, "Offer")
              .replace(/Promotion/i, "Communication");
            attributes.push(modifiedAttribute.toUpperCase());
          }
        });
      }
      const values: {
        [brandId: string]: PositionVsCompetition.BrandValue;
      } = {};
      let initialValues = quadrantChartData[dateRange]?.values;
      if (typeof initialValues !== "string") {
        initialValues = { ...initialValues };
      }
      for (const brandId in initialValues) {
        const initialBrandValue = initialValues[brandId];
        const initialX = initialBrandValue.x;
        const initialY = initialBrandValue.y;
        if (Number.isFinite(initialX) && Number.isFinite(initialY)) {
          values[brandId] = {
            x: normalizeValue(initialX),
            y: normalizeValue(initialY),
          };
        }
      }
      if (attributes.length < 4 || !Object.keys(values).length) return;
      return {
        startDate: rangeData?.startDate || rangeData?.dateFrom || "",
        endDate: rangeData?.endDate || rangeData?.dateTo || "",
        attributes: attributes.slice(0, 4),
        values,
      };
    };

    parsedData.oneMonth = getData("oneMonth");
    parsedData.twoMonths = getData("twoMonths");
    parsedData.threeMonths = getData("threeMonths");
    parsedData.sixMonths = getData("sixMonths");
    parsedData.oneYear = getData("oneYear");
  }

  return parsedData;
}

export const downloadCSV = async (searchId: string, widget: string) =>
  await axios.get(
    `service-export-${urlInsertion}-api/${widget}/export/excel/${searchId}`,
    { responseType: "arraybuffer" },
  );

function reSortSentimentIndexLineChart(
  lineChart: any,
  brands: Store.Brands,
): Store.Widgets["result-sentiment-index"]["lineChart"] {
  const chart: Store.Widgets["result-sentiment-index"]["lineChart"] = [];
  if (lineChart && Array.isArray(lineChart)) {
    lineChart.forEach((el) => {
      const date = el?.date;
      const values = el?.values;
      const sortedValues = Object.entries(
        typeof values === "object" ? values : {},
      ).map(([brandName, values]: [string, any]) => {
        const score = values?.score;
        const { name } = brands.find(({ id }) => id === brandName) || {};
        return [name + " sentiment score", score ?? 0];
      });

      chart.push({
        date: typeof date === "string" ? date : "",
        values: Object.fromEntries(sortedValues),
      });
    });
  }

  return chart;
}
