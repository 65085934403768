import firestore from "../../firestore";
import axios from "../../axios";

const urlInsertion = process.env.REACT_APP_URL_INSERTION;

// Sentiment Drivers ---->
export const fetchRelatedArticles = (payload: {
  table: string;
  brandId: string;
  excludedArticles: string[];
  relatedWords: string[];
  excludedWords: string[];
  limit?: number;
  offset?: number;
}) =>
  axios.post(`articles-service-${urlInsertion}-api/related-to-words`, {
    ...payload,
    includeSentence: true,
  });

export async function getSentimentDriversChangedParameters(searchId: string) {
  const gotData = await firestore()
    .collection("sentiment-drivers-review-results")
    .doc(searchId)
    .get();

  return gotData?.data() || {};
}

export async function setSentimentDriversParameters(
  searchId: string,
  parameters: {
    [brandId: string]: {
      [articleId: string]: {
        sentence: string;
        parameter: string;
      };
    };
  },
) {
  await firestore()
    .collection("sentiment-drivers-review-results")
    .doc(searchId)
    .set(parameters, { merge: true });
}

export async function writeSearchExcludedSuggestions(
  searchId: string,
  brandId: string,
  excludedSuggestions: string[],
  mergedExcludedSuggestion?: string[],
  excludedArticles?: {
    id: string;
    title: string;
  }[],
) {
  await firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      recalculatingWidgets: new Date().toISOString(),
      [`meltwater.${brandId}.aiTrainerExcludedWords`]: excludedSuggestions,
      ...(mergedExcludedSuggestion
        ? {
            [`meltwater.${brandId}.exclude`]: mergedExcludedSuggestion,
          }
        : {}),
      ...(excludedArticles
        ? {
            [`meltwater.${brandId}.excludedArticles`]: excludedArticles,
          }
        : {}),
    });
}

export const recalculateWidgetsDependentOnExcludes = (
  companyId: string,
  brandId: string,
) =>
  axios.post(
    `widgets-service-${urlInsertion}-api/recalculate/dependent-on-excludes`,
    { companyId, brandId },
  );
// <---- Sentiment Drivers

// Top Trend Keywords ---->
export async function recalculateTopKeywordsWidget(searchId: string) {
  const topKeywordsWidget: TopKeywords.Item = {
    dateFrom: "",
    dateTo: "",
    _updatedAt: "",
    topWords: [],
    trendingWords: [],
  };

  try {
    const response = await axios.post(
      `top-trend-keywords-widget-${urlInsertion}-api/update-one`,
      {
        brandId: searchId,
      },
    );

    const responseData = response.data;

    const { dateFrom, dateTo, topWords, trendingWords, _updatedAt } =
      responseData || {};

    if (dateFrom && typeof dateFrom === "string") {
      topKeywordsWidget["dateFrom"] = dateFrom;
    }

    if (dateTo && typeof dateTo === "string") {
      topKeywordsWidget["dateTo"] = dateTo;
    }

    if (_updatedAt && typeof _updatedAt === "string") {
      topKeywordsWidget["_updatedAt"] = _updatedAt;
    }

    if (topWords && Array.isArray(topWords)) {
      topKeywordsWidget["topWords"] = topWords;
    }

    if (trendingWords && Array.isArray(trendingWords)) {
      topKeywordsWidget["trendingWords"] = trendingWords;
    }
  } catch (error) {
    console.error(error);
  }

  return topKeywordsWidget;
}

export async function writeTopWordsExcludedWords(
  searchId: string,
  brandId: string,
  words: string[],
) {
  await firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandId}.excludedKeywords`]: words,
    });
}

export async function writeTypeOfShownKeywords(
  searchId: string,
  brandId: string,
  useAllKeywords: boolean,
) {
  await firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandId}.useAllKeywords`]: useAllKeywords,
    });
}
// <---- Top Trend Keywords

// ESG Widgets ---->
export const fetchESGArticles = ({
  searchId: brandId,
  brandId: table,
  excludedArticles,
  excludedSources,
  param,
  limit = 200,
  offset = 1,
}: {
  searchId: string;
  brandId: string;
  excludedArticles: string[];
  excludedSources: string[];
  param?: "all" | "social" | "governance" | "environmental";
  limit?: number;
  offset?: number;
}): Promise<
  | {
      data: {
        date: string;
        id: string;
        parameter: string;
        sentence: string;
        source: string;
        sourceId: string;
        title: string;
        url: string;
      }[];
      status?: number;
    }
  | undefined
> =>
  axios.post(`articles-service-${urlInsertion}-api/esg-related-articles`, {
    brandId,
    table,
    excludedArticles,
    excludedSources,
    limit,
    offset,
    ...(param ? { param } : {}),
  });

export async function getChangedParameters(searchId: string) {
  const gotData = await firestore()
    .collection("esg-review-results")
    .doc(searchId)
    .get();

  return gotData?.data() || {};
}

export async function setParameters(
  searchId: string,
  // articleId: string,
  parameters: {
    [brandId: string]: {
      [articleId: string]: {
        sentence: string;
        parameter: string;
      };
    };
  },
) {
  await firestore()
    .collection("esg-review-results")
    .doc(searchId)
    .set(parameters, { merge: true });
}

export const recalculateESGWidget = (searchId: string) =>
  axios.post(`esg-widget-${urlInsertion}-api/update-one`, {
    brandId: searchId,
  });

export async function writeESGExcludedData(
  searchId: string,
  brandId: string,
  articles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  sources: string[],
) {
  return firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandId}.excludedESGArticles`]: articles,
      [`meltwater.${brandId}.excludedESGSources`]: sources,
    });
}
// <---- ESG Widgets

// Business Drivers ---->
export const fetchBusinessDriversArticles = ({
  searchId: brandId,
  brandId: table,
  excludedArticles,
  excludedSources,
  param,
  limit = 200,
  offset = 1,
}: {
  searchId: string;
  brandId: string;
  excludedArticles: string[];
  excludedSources: string[];
  param?:
    | "all"
    | "product"
    | "communication"
    | "service"
    | "price"
    | "preference"
    | "offer";
  limit?: number;
  offset?: number;
}): Promise<
  | {
      data: {
        date: string;
        id: string;
        parameter: string;
        sentence: string;
        source: string;
        sourceId: string;
        title: string;
        url: string;
      }[];
      status?: number;
    }
  | undefined
> =>
  axios.post(
    `articles-service-${urlInsertion}-api/business-drivers-related-articles`,
    {
      brandId,
      table,
      excludedArticles,
      excludedSources,
      limit,
      offset,
      ...(param ? { param } : {}),
    },
  );

export async function getBusinessDriversChangedParameters(searchId: string) {
  const gotData = await firestore()
    .collection("business-drivers-review-results")
    .doc(searchId)
    .get();

  return gotData?.data() || {};
}

export async function setBusinessDriversParameters(
  searchId: string,
  // articleId: string,
  parameters: {
    [brandId: string]: {
      [articleId: string]: {
        sentence: string;
        parameter: string;
      };
    };
  },
) {
  await firestore()
    .collection("business-drivers-review-results")
    .doc(searchId)
    .set(parameters, { merge: true });
}

export const recalculateBusinessDriversWidget = (searchId: string) =>
  axios.post(`business-drivers-widget-${urlInsertion}-api/update-one`, {
    brandId: searchId,
  });

export const recalculateTopTrendKeywordsWidget = ({
  searchId: brandId,
  isRecalculating = "",
}: {
  searchId: string;
  isRecalculating?: string;
}) => {
  sessionStorage.setItem("calculatingSearchID", brandId);
  sessionStorage.setItem(
    "isWidgetsDependentOnKeywordsRecalculated",
    isRecalculating,
  );

  const result = axios
    .post(`top-trend-keywords-widget-${urlInsertion}-api/update-one`, {
      brandId,
    })
    .then((response) => {
      sessionStorage.removeItem("isWidgetsDependentOnKeywordsRecalculated");
      return response;
    });

  return result;
};

export async function writeBusinessDriversExcludedData(
  searchId: string,
  brandId: string,
  articles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  sources: string[],
) {
  return firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandId}.excludedBusinessDriversArticles`]: articles,
      [`meltwater.${brandId}.excludedBusinessDriversSources`]: sources,
    });
}
// <---- Business Drivers

// ----> Reputation Widgets

export const reputationParams = [
  "all",
  "citizenship",
  "governance",
  "innovation",
  "performance",
  "products",
  "workplace",
] as const;

export const fetchReputationArticles = ({
  searchId: brandId,
  brandId: table,
  excludedArticles,
  excludedSources,
  param,
  limit = 200,
  offset = 1,
}: {
  searchId: string;
  brandId: string;
  excludedArticles: string[];
  excludedSources: string[];
  param?: typeof reputationParams[number];
  limit?: number;
  offset?: number;
}): Promise<
  | {
      data: {
        date: string;
        id: string;
        parameter: string;
        sentence: string;
        source: string;
        sourceId: string;
        title: string;
        url: string;
      }[];
      status?: number;
    }
  | undefined
> =>
  axios.post(
    `articles-service-${urlInsertion}-api/reputation-related-articles`,
    {
      brandId,
      table,
      excludedArticles,
      excludedSources,
      limit,
      offset,
      ...(param ? { param } : {}),
    },
  );

export async function getReputationChangedParameters(searchId: string) {
  const gotData = await firestore()
    .collection("reputation-review-results")
    .doc(searchId)
    .get();

  return gotData?.data() || {};
}

export async function seReputationParameters(
  searchId: string,
  parameters: {
    [brandId: string]: {
      [articleId: string]: {
        sentence: string;
        parameter: string;
      };
    };
  },
) {
  await firestore()
    .collection("reputation-review-results")
    .doc(searchId)
    .set(parameters, { merge: true });
}

export const recalculateReputationWidget = (searchId: string) =>
  axios.post(`reputation-widget-${urlInsertion}-api/update-one`, {
    brandId: searchId,
  });

export async function writeReputationExcludedData(
  searchId: string,
  brandId: string,
  articles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  sources: string[],
) {
  return firestore()
    .collection("brands")
    .doc(searchId)
    .update({
      [`meltwater.${brandId}.excludedReputationArticles`]: articles,
      [`meltwater.${brandId}.excludedReputationSources`]: sources,
    });
}
// <---- Reputation Widgets

export async function setWidgetStatus(
  searchId: string,
  widgetsCollectionId: string[],
) {
  try {
    const doc = firestore().collection("searches-state").doc(searchId);

    const res = await doc.get();
    const { widgets } = res.data() || {};

    const thisDate = new Date().toISOString();
    const formateWidgets = widgetsCollectionId.reduce(
      (acc, widgetCollectionId) => {
        acc[widgetCollectionId] = {
          isCalculating: true,
          _timestamp: thisDate,
        };
        return acc;
      },
      {} as {
        [widgetCollectionId: string]: {
          isCalculating: true;
          _timestamp: string;
        };
      },
    );

    const newWidgetData = {
      ...widgets,
      ...formateWidgets,
    };

    await doc.set({ widgets: newWidgetData }, { merge: true });
  } catch (err) {
    console.error(err);
  }
}

export function subscribeOnWidgetCalculationStatus(
  searchId: string,
  widgetIds: string[],
  callback: (isAllWidgetsFinishCalculation: boolean) => void,
) {
  const doc = firestore().collection("searches-state").doc(searchId);

  return doc.onSnapshot(
    (doc) => {
      let isAllWidgetsFinishCalculation: boolean = false;

      if (!widgetIds.length) {
        isAllWidgetsFinishCalculation = true;
      } else {
        const widgetsStatus = widgetIds.map((widget) => {
          const widgetStatus = doc.data()?.widgets?.[widget]?.isCalculating;
          return Boolean(widgetStatus);
        });

        isAllWidgetsFinishCalculation = widgetsStatus.every(
          (widgetStatus) => widgetStatus === false,
        );
      }
      callback(isAllWidgetsFinishCalculation);
    },
    (error) => {
      console.error(error);
    },
  );
}
