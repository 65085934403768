import { FC, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import styles from "./WidgetPage.module.scss";
import context from "src/context";
import { sortWidgetsOnDashboard } from "src/utils";
import { useCompetitorsPresence, useQueryParams } from "src/hooks";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { WIDGETS_COMPONENTS } from "src/widgets/widgets";
import { ROUTS } from "src/data/routs";
import { SEARCH_ID_LENGTH } from "src/data/searchIdLength";
import { readDashboardCustomData } from "src/store/actions";
import { selectAvailableWidgetsIds } from "src/store/selectors";
import { selectAvailableWidgetsIdsForOneBrandSearch } from "src/store/widgetsData/widgetsDataSlice";

// Inner imports
import { SliderArrow } from "./components/SliderArrow/SliderArrow";
import type { Props } from "./types";
import { filterBrokenWidget } from "./utils";
import { useWidgetActionBar } from "./hooks";
import { useReadDashboardViewSettings } from "../Dashboards/components/DashboardContainer/hooks";

export const WidgetPage: FC<Props> = ({ layoutSize = "large" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { state } = history.location as any;
  const {
    dashboardId: locationStateDashboardId,
    searchId: locationStateSearchId,
    homePosition,
  } = state || {};

  const { isPresentation } = useContext(context);

  const { searchId: queryParamsSearchId } = useQueryParams();

  const { widgetSlug = "", dashboardId: paramsDashboardId } = useParams<{
    widgetSlug?: WIDGET_IDS_TYPES;
    dashboardId?: string;
  }>();

  const widgetData = useSelector(
    ({ widgetsData }: RootState) => widgetsData.entities[widgetSlug],
  );

  const availableWidgets = useSelector(selectAvailableWidgetsIds);

  const widgetsAvailableForOneBrand: string[] = useSelector(
    selectAvailableWidgetsIdsForOneBrandSearch,
  );

  const searchId: string = useMemo(
    () => locationStateSearchId || queryParamsSearchId || "",
    [locationStateSearchId, queryParamsSearchId],
  );

  const dashboardId: string = useMemo(
    () => locationStateDashboardId || paramsDashboardId || "",
    [locationStateDashboardId, paramsDashboardId],
  );

  const dashboards = useSelector(({ dashboards }: RootState) => dashboards);
  const dashboardsCustomData = useSelector(
    ({ dashboardsCustomData }: RootState) => dashboardsCustomData,
  );
  const widgetsState = useSelector(
    ({ widgetsState }: Store.State) => widgetsState,
  );

  useReadDashboardViewSettings(dashboardId);

  const searchesCompetitorsPresence = useCompetitorsPresence({
    searchesIds: [searchId],
    widgetId: widgetData?.id,
  });

  const isCompetitorsPresent: boolean | undefined = useMemo(
    () => searchesCompetitorsPresence[searchId || ""]?.isCompetitorsPresent,
    [searchId, searchesCompetitorsPresence],
  );

  useEffect(() => {
    if (!dashboardId) return;

    const dashboardCustomData = dashboardsCustomData[dashboardId];

    if (dashboardCustomData) return;

    (async () => {
      try {
        if (dashboardId) {
          await dispatch(readDashboardCustomData(dashboardId));
        }
      } catch (error) {
        console.dir(error);
      }
    })();
  }, [dashboardId, dashboardsCustomData, dispatch]);

  const unavailableForOneBrandWidgetsToOmit: string[] = useMemo(() => {
    if (Boolean(isCompetitorsPresent)) return [];

    return availableWidgets.filter(
      (id) => !widgetsAvailableForOneBrand.includes(id),
    );
  }, [availableWidgets, isCompetitorsPresent, widgetsAvailableForOneBrand]);

  const dashboardData = useMemo(() => dashboards.entities[dashboardId], [
    dashboardId,
    dashboards.entities,
  ]);

  const dashboard = useMemo(() => {
    const { layouts, tiles } = dashboards.entities[dashboardId] || {};
    if (!layouts || !tiles || !layoutSize) return;
    const gridItem = layouts[layoutSize];

    const suitableWidgetForCarousel = filterBrokenWidget(
      gridItem,
      tiles,
      widgetsState,
      unavailableForOneBrandWidgetsToOmit,
    );

    const filteredWidgetsBySubPlan = suitableWidgetForCarousel.filter(
      ({ i }) => {
        const widgetSearchId = i.slice(0, SEARCH_ID_LENGTH);
        const widgetId = i.replace(widgetSearchId, "");

        return (
          dashboardData?.isCopiedDashboard ||
          isPresentation ||
          availableWidgets.includes(widgetId)
        );
      },
    );

    return sortWidgetsOnDashboard(filteredWidgetsBySubPlan);
  }, [
    dashboards.entities,
    dashboardId,
    layoutSize,
    widgetsState,
    unavailableForOneBrandWidgetsToOmit,
    dashboardData,
    isPresentation,
    availableWidgets,
  ]);

  const redirectLink: string = useMemo(
    () => (dashboardId ? `/dashboards/${dashboardId}` : ROUTS.dashboardsPage),
    [dashboardId],
  );

  const widgetId: WIDGET_IDS_TYPES | undefined = useMemo(() => widgetData?.id, [
    widgetData,
  ]);

  const isWidgetUnavailableToShow: boolean = useMemo(
    () =>
      !availableWidgets.includes(widgetId || "") &&
      !dashboardData?.isCopiedDashboard &&
      !isPresentation,
    [availableWidgets, widgetId, dashboardData, isPresentation],
  );

  const isWidgetAvailableForOneBrand: boolean = useMemo(() => {
    if (isCompetitorsPresent) return true;
    return widgetsAvailableForOneBrand.includes(widgetData?.id || "");
  }, [isCompetitorsPresent, widgetsAvailableForOneBrand, widgetData?.id]);

  useEffect(() => {
    if (isWidgetUnavailableToShow) {
      showToastNotification({
        type: "info",
        text: t("wt_available_at_upgrade"),
        closeButton: {
          onClick: () => history.push(ROUTS.pricingPage),
          text: t("text_upgrade"),
        },
        autoClose: false,
      });

      return history.push(redirectLink);
    }

    if (!isWidgetAvailableForOneBrand) {
      showToastNotification({
        type: "warning",
        text: t("widget_competitors_required"),
        closeButton: {
          text: t("text_ok"),
        },
        autoClose: false,
      });

      return history.push(redirectLink);
    }
  }, [
    history,
    isWidgetAvailableForOneBrand,
    isWidgetUnavailableToShow,
    redirectLink,
    t,
  ]);

  useEffect(() => {
    if (!dashboardId) return;

    const dashboardCustomData = dashboardsCustomData[dashboardId];

    if (dashboardCustomData) return;

    (async () => {
      try {
        if (dashboardId) {
          await dispatch(readDashboardCustomData(dashboardId));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dashboardId, dashboardsCustomData, dispatch]);

  const widget = useMemo(() => {
    if (!widgetSlug) return;

    const Widget = WIDGETS_COMPONENTS[widgetSlug]?.pageComponent;

    if (Widget) {
      return (
        <Widget
          key={`${widgetSlug}-${searchId}`}
          searchId={searchId}
          dashboardId={dashboardId}
          redirectLink={redirectLink}
        />
      );
    }
  }, [redirectLink, searchId, widgetSlug, dashboardId]);

  useWidgetActionBar(searchId, dashboardId, widgetId);

  const gridItemId = useMemo(() => {
    return searchId + widgetSlug;
  }, [searchId, widgetSlug]);

  const previousWidget = useMemo(() => {
    if (!dashboard) return;
    const currentGridItemIndex = dashboard.findIndex(
      ({ i }) => i === gridItemId,
    );

    const previousWidget = dashboard[currentGridItemIndex - 1];

    if (typeof previousWidget === "undefined") {
      // Last element;
      return null;
    }

    return previousWidget;
  }, [dashboard, gridItemId]);

  const [previousWidgetId, previousWidgetSearchId] = useMemo(() => {
    const previousWidgetId = previousWidget?.i.slice(SEARCH_ID_LENGTH) || "";
    const previousWidgetSearchId =
      previousWidget?.i.replace(previousWidgetId, "") || "";

    if (!previousWidgetId) {
      // Something broken
      return [undefined, undefined];
    }

    return [previousWidgetId, previousWidgetSearchId];
  }, [previousWidget]);

  const nextWidget = useMemo(() => {
    if (!dashboard) return;
    const currentGridItemIndex = dashboard.findIndex(
      ({ i }) => i === gridItemId,
    );

    const nextWidget = dashboard[currentGridItemIndex + 1];

    if (typeof nextWidget === "undefined") {
      // Last element;
      return null;
    }

    return nextWidget;
  }, [dashboard, gridItemId]);

  const [nextWidgetId, nextWidgetSearchId] = useMemo(() => {
    const nextWidgetId = nextWidget?.i.slice(SEARCH_ID_LENGTH) || "";
    const nextWidgetSearchId = nextWidget?.i.replace(nextWidgetId, "") || "";

    if (!nextWidgetId || !nextWidgetSearchId) {
      // Something broken
      return [undefined, undefined];
    }

    return [nextWidgetId, nextWidgetSearchId];
  }, [nextWidget]);

  const isPreviousArrowShow = useMemo(() => {
    if (isPresentation) return true;

    return Boolean(previousWidgetSearchId);
  }, [isPresentation, previousWidgetSearchId]);

  const isNextArrowShow = useMemo(() => {
    if (isPresentation) return true;

    return Boolean(nextWidgetSearchId);
  }, [isPresentation, nextWidgetSearchId]);

  return (
    <div className={styles.widgetContainer}>
      <div className={styles.content}>
        {isPreviousArrowShow && (
          <SliderArrow
            className={styles.previousWidgetButton}
            layoutSize={layoutSize}
            type={"previous"}
            slug={previousWidgetId}
            dashboardId={dashboardId}
            searchId={previousWidgetSearchId}
            homePosition={homePosition}
          />
        )}

        {isNextArrowShow && (
          <SliderArrow
            className={styles.nextWidgetButton}
            layoutSize={layoutSize}
            type={"next"}
            slug={nextWidgetId}
            dashboardId={dashboardId}
            searchId={nextWidgetSearchId}
            homePosition={homePosition}
          />
        )}
        {widget || <div className={styles.error}>{t("no_widget_found")}</div>}
      </div>
    </div>
  );
};
