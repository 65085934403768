const LINE_STROKE_WIDTH = 4;

const Y_AXIS_VERTICAL_TOP_PADDING = 10;
const Y_AXIS_VERTICAL_BOTTOM_PADDING = 10;

export {
  LINE_STROKE_WIDTH,
  Y_AXIS_VERTICAL_TOP_PADDING,
  Y_AXIS_VERTICAL_BOTTOM_PADDING,
};
