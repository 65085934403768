import { FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { omit } from "lodash";

import context from "src/context";
import {
  readDashboardById,
  readDashboards,
} from "src/store/dashboards/dashboardsApi";
import {
  addOneDashboard,
  removeDashboardFromStore,
} from "src/store/dashboards/dashboardsSlice";
import { sendDataToGTM } from "src/utils";
import { showToastNotification } from "src/components/ToastNotification/utils";

type Props = {
  className?: string;
  isDashboardCanBeAdded: boolean;
  dashboardId: string;
};

const AddToDashboardButton: FC<Props> = ({
  className = "",
  isDashboardCanBeAdded,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const { setIsGlobalPreloaderShown } = useContext(context);

  //#region CHECK COMPANY ---->
  const companyId = useSelector(({ company }: Store.State) => company.id);

  // Remove possibility for user to add someone else's dashboard if doesn't have company;
  useEffect(() => {
    if (!companyId) {
      history.push(`/presentation-start/${dashboardId}`, {
        dashboardId,
      });
    }
  }, [companyId, dashboardId, history]);
  //#endregion CHECK COMPANY;

  const onClickHandler = async () => {
    setIsGlobalPreloaderShown(true);

    // Check if user try to add his own dashboard ---->
    const companyDashboards = await readDashboards(companyId);

    const isDashboardAlreadyAdded = companyDashboards.some(
      ({ id }) => id === dashboardId,
    );

    if (isDashboardAlreadyAdded) {
      setIsGlobalPreloaderShown(false);

      return showToastNotification({
        type: "warning",
        text: t("presentation_already_added_warning"),
      });
    }
    // <---- Check if user is trying to add his own dashboard;

    // Get viewed dashboard and prepare payload for copy ---->
    const dashboard = await readDashboardById(dashboardId);
    const payload = omit({ ...dashboard }, ["id", "createdAt", "updatedAt"]);
    // <---- Get viewed dashboard and prepare payload for copy;

    try {
      // Add copied dashboard to user's company;
      const res = await dispatch(
        addOneDashboard({
          ...payload,
          companyId,
          isCopiedDashboard: true,
        }),
      );

      const newDashboard = unwrapResult(res);

      // Remove viewed dashboard from user redux store
      dispatch(removeDashboardFromStore(dashboardId));

      if (dashboard.id) {
        sendDataToGTM("UserAddedSharedDashboard", {
          sharedDashboardId: dashboard.id,
        });
      }

      history.push(`/dashboards/${newDashboard.id}`);
    } catch (error) {
      showToastNotification({ type: "error", text: t("request_error") });
    } finally {
      setIsGlobalPreloaderShown(false);
    }
  };

  return (
    <button
      className={className}
      onClick={onClickHandler}
      disabled={!isDashboardCanBeAdded}
    >
      {t("presentation_add_to_dashboard_btn")}
    </button>
  );
};

export { AddToDashboardButton };
