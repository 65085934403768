import {
  memo,
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import styles from "./CustomizedLabel.module.scss";
import { Triangle } from "src/icons";

// Inner imports
import { CustomizedLabelProps } from "./types";
import { getTimePlotterLabel } from "../../helpers";

export const CustomizedLabel = memo(
  forwardRef(
    (
      { timePlotter, onClick, formatDateHandler, color }: CustomizedLabelProps,
      ref: ForwardedRef<HTMLDivElement>,
    ) => {
      const {
        id,
        name,
        startDate: _startDate,
        endDate: _endDate,
      } = timePlotter;

      const { t } = useTranslation();

      const [chartWidth, setChartWidth] = useState<number>(0);

      const [startDate, endDate] = useMemo<[string, string]>(
        () => [formatDateHandler(_startDate), formatDateHandler(_endDate)],
        [_endDate, formatDateHandler, _startDate],
      );

      useEffect(() => {
        _setChartWidth();

        window.addEventListener("resize", _setChartWidth);

        return () => window.removeEventListener("resize", _setChartWidth);

        function _setChartWidth() {
          if (ref && "current" in ref)
            setChartWidth(ref.current?.offsetWidth || 0);
        }
      }, [ref]);

      const label = useMemo<string>(() => getTimePlotterLabel(timePlotter, t), [
        t,
        timePlotter,
      ]);

      return (
        <foreignObject
          onClick={() => onClick(id)}
          className={styles.customizedLabelWrapper}
        >
          <div
            title={name}
            className={styles.customizedLabel}
            style={{ width: chartWidth }}
          >
            <div className={styles.customizedLabelDate}>
              {startDate}
              <Triangle color={color} />
            </div>
            <div className={styles.customizedLabelName}>{label}</div>
            <div className={styles.customizedLabelDate}>
              <Triangle color={color} />
              {endDate}
            </div>
          </div>
        </foreignObject>
      );
    },
  ),
);
