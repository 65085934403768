const actionTypes = {
  SET_USER_DATA: "SET_USER_DATA",
  REMOVE_USER_DATA: "REMOVE_USER_DATA",
  SET_COMPANY_DATA: "SET_COMPANY_DATA",
  SET_COMPANY_MEMBERS_EMAIL: "SET_COMPANY_MEMBERS_EMAIL",
  REMOVE_COMPANY_DATA: "REMOVE_COMPANY_DATA",
  SET_SEARCHES: "SET_SEARCHES",
  SET_WIDGET: "SET_WIDGET",
  SET_WIDGETS_STATE: "SET_WIDGETS_STATE",
  SET_WIDGETS_INFO: "SET_WIDGETS_INFO",
  SET_TIME_PLOTS: "SET_TIME_PLOTS",
  SET_DASHBOARD_CUSTOM_DATA: "SET_DASHBOARD_CUSTOM_DATA",
  SET_CUSTOM_WIDGET_TEXT: "SET_CUSTOM_WIDGET_TEXT",
  SET_KEYWORDS_WIDGETS_UPDATE_DATE: "SET_KEYWORDS_WIDGETS_UPDATE_DATE",
  SET_SOS_FUNNEL_FILTERS: "SET_SOS_FUNNEL_FILTERS",
};

export default actionTypes;
