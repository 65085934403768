import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ConfirmModalWrapper } from "../ConfirmModalWrapper/ConfirmModalWrapper";
import { ROUTS } from "../../../data/routs";
import context from "../../../context";

export const UnblockWidgetModal: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setModalElement } = useContext(context);

  return (
    <ConfirmModalWrapper
      type="info"
      title={t("wt_unblock_widget_modal_title")}
      cancelButton={{
        text: t("wt_unblock_widget_modal_no_btn"),
        onClicked: () => setModalElement(null),
      }}
      acceptButton={{
        text: t("wt_unblock_widget_modal_yes_btn"),
        onClicked: () => {
          history.push(`${ROUTS.settingsPage}#subscriptionPlans`);
          setModalElement(null);
        },
      }}
    >
      <p>{t("wt_unblock_widget_modal_body")}</p>
    </ConfirmModalWrapper>
  );
};
