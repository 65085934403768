import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useWidgetCalculatingStatus = (
  searchId: string,
  widgetCollectionIds: string[],
): [boolean] => {
  const searchWidgetsStatus = useSelector(
    ({ widgetsState }: Store.State) => widgetsState?.[searchId],
  );

  const isWidgetCalculating = useMemo(() => {
    const isCalculating = widgetCollectionIds.reduce(
      (acc, widgetCollectionId) => {
        const { isCalculating } =
          searchWidgetsStatus?.[widgetCollectionId] || {};

        acc.push(Boolean(isCalculating));
        return acc;
      },
      [] as boolean[],
    );

    return isCalculating.some((widgetStatus) => widgetStatus === true);
  }, [searchWidgetsStatus, widgetCollectionIds]);

  return [isWidgetCalculating];
};
