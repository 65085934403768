import { useMemo, FC } from "react";

import styles from "./AttitudePreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";

// Inner imports
import { AttitudeLineChartWithLabels } from "./components";
import { WIDGET_ID, LABELS, LINE_CHART } from "./constants";
import { WidgetChart, WidgetSummaryProps } from "../../types";
import { SummaryTimePeriod } from "../../descriptionInsertionComponents";
import { extractAttitudeBrandData } from "./utils";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

export const AttitudeSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
  selectedBrand,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData } = useMemo(() => {
    const { lineChart = [] } = widgetData || {};

    return {
      lineChartData: extractAttitudeBrandData(lineChart, selectedBrand.id),
    };
  }, [widgetData, selectedBrand]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const charts = useMemo<WidgetChart[]>(() => {
    const charts: WidgetChart[] = [];

    if (!lineChartData.length) return charts;

    charts.push({
      type: "lineChart",
      subType: "oneBrand",
      chart: (
        <LineChart
          className={styles.chart}
          labels={LABELS}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: LINE_CHART.STROKE_WIDTH,
          }}
          axis={{
            yAxisVerticalPadding: LINE_CHART.VERTICAL_PADDING,
            yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
          }}
        />
      ),
    });

    charts.push({
      type: "lineChart",
      chart: (
        <AttitudeLineChartWithLabels
          searchId={searchId}
          attitude={widgetData}
          timePlotterId={timePlot.id}
          className={styles.chart}
          type="timePlotterSummary"
        />
      ),
    });

    return charts;
  }, [filteredData, lineChartData.length, searchId, timePlot.id, widgetData]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
  });

  const mainBrandValues = useMemo<number[]>(
    () => filteredData.map(({ values }) => values.positive || 0),
    [filteredData],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={mainBrandValues}
          widgetId={WIDGET_ID}
          selectedBrand={selectedBrand}
        />
      </div>
      <div className={styles.buttons}>
        {views.map(({ buttonIcon, buttonNumber }, i) =>
          i !== viewIndex ? (
            <ChartButton
              className={styles.button}
              icon={buttonIcon}
              number={buttonNumber}
              onButtonClick={() => setViewIndex(i)}
              key={buttonIcon + i}
            />
          ) : null,
        )}
      </div>
      <div className={styles.chartWrapper}>{charts[viewIndex]?.chart}</div>
    </div>
  );
};

// Commented out until DEV-69219 is ready
// const { brands } = attitude || {};
// const [firstBrand] = brands || [];

// const isFirstBrandDataEmpty: boolean = useMemo(() => {
//   if (!firstBrand || !attitude) return false;
//   let isDataEmpty: boolean = false;
//   const firstBrandData = attitude.pieChart.oneYear?.values?.[firstBrand?.id];
//   if (firstBrandData) {
//     isDataEmpty = !Object.values(firstBrandData).filter(
//       (item: number) => item > 0,
//     ).length;
//   }
//   return isDataEmpty;
// }, [firstBrand, attitude]);

// const isBrandsLineChartDataEmpty: boolean = useMemo(
//   () =>
//     !Object.values(attitude?.lineChart || {}).some((data) =>
//       Object.values(data?.values || {}).some(
//         ({ negative, positive, neutral }) => negative || positive || neutral,
//       ),
//     ),
//   [attitude?.lineChart],
// );
