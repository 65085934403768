import { createDraftSafeSelector } from "@reduxjs/toolkit";

export const selectSubscriptionPlans = createDraftSafeSelector(
  ({ subscriptionPlans }: RootState) => subscriptionPlans,
  (subscriptionPlans) => subscriptionPlans,
);

export const selectSubscriptionPlansEntities = createDraftSafeSelector(
  selectSubscriptionPlans,
  (subscriptionPlans) => subscriptionPlans.entities,
);

export const selectCompanySubscriptionPlanId = createDraftSafeSelector(
  ({ company }: Store.State) => company,
  (company) => company.subscriptionPlanId,
);

export const selectCompanySubscriptionPlan = createDraftSafeSelector(
  selectSubscriptionPlansEntities,
  selectCompanySubscriptionPlanId,
  (subscriptionPlans, companySubscriptionPlanId) =>
    subscriptionPlans[companySubscriptionPlanId],
);

export const selectCompanySubscriptionPlanDataSource = createDraftSafeSelector(
  selectCompanySubscriptionPlan,
  (subscriptionPlan) => subscriptionPlan?.mainDataSource || "meltwater",
);
