import { useMemo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ShareOfVolumeTable.module.scss";

// Inner imports
import type { TableData, TableDataItem } from "./types";
import { getColorClass } from "../../utils";

export const ShareOfVolumeTable = ({
  tableData = [],
}: TableData): ReactElement => {
  const { t } = useTranslation();

  const columnsItems: string[] = [
    t("w_s_v_table_column_#"),
    t("w_s_v_table_column_brands"),
    t("w_s_v_table_column_trend_change"),
    t("w_s_v_table_column_change"),
    t("w_s_v_table_column_search_volume"),
  ];

  const sortedData: TableDataItem[] = useMemo(
    () =>
      [...tableData].sort((a, b) => b.searchPercentage - a.searchPercentage),
    [tableData],
  );

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.shareOfVolumeTable}>
        <thead>
          <tr className={styles.tableTitleWrapper}>
            {columnsItems.map((columnName: string, index: number) => (
              <th className={styles.tableTitleItem} key={index}>
                <div className={styles.tableTitleArrowWrapper}>
                  {columnName}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData &&
            sortedData?.map(
              ({ brand, change, trendChange, searchVolume }, index: number) => (
                <tr key={index}>
                  <td className={styles.tableListItemIndex}>{index + 1}.</td>
                  <td className={cx(styles.tableListItem, styles.brandName)}>
                    {brand}
                  </td>
                  <td
                    className={cx(
                      styles.tableListItem,
                      styles[getColorClass(trendChange)],
                    )}
                  >
                    {trendChange}%
                  </td>
                  <td
                    className={cx(
                      styles.tableListItem,
                      styles[getColorClass(change)],
                    )}
                  >
                    {change}%
                  </td>
                  <td className={styles.tableListItem}>{searchVolume}</td>
                </tr>
              ),
            )}
        </tbody>
      </table>
    </div>
  );
};
