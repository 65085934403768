const INITIAL_MONTHS_COUNT: number = 12 as const;
const MAX_WORDS_QUANTITY: number = 20 as const;
const MAX_MOBILE_WIDTH_FOR_CHART: number = 620 as const;

const WIDGET_ID = "result-sentiment-drivers" as const;

export {
  INITIAL_MONTHS_COUNT,
  MAX_WORDS_QUANTITY,
  WIDGET_ID,
  MAX_MOBILE_WIDTH_FOR_CHART,
};
