import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";

import styles from "../../Sidebar.module.scss";
import context from "../../../../context";

// Inner imports
import type { LinkType } from "../../types";

type Props = LinkType & {
  className?: string;
  hasActiveStyles?: boolean;
  onClick?: () => void;
};

const Link: FC<Props> = ({
  icon: Icon,
  title,
  url,
  className,
  hasActiveStyles = true,
  onClick,
}) => {
  const { setIsSidebarOpen } = useContext(context);

  const onClickHandler = () => {
    setIsSidebarOpen(false);
    onClick?.();
  };

  return (
    <NavLink
      className={className}
      activeClassName={hasActiveStyles ? styles.activeItem : ""}
      title={title}
      to={url}
      key={title}
      onClick={onClickHandler}
    >
      <Icon />
      <span>{title}</span>
    </NavLink>
  );
};

export { Link };
