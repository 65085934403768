import { useTranslation, Trans } from "react-i18next";
import classes from "./KeyBusinessDriversChart.module.scss";

interface IProps {
  className?: string;
  style?: object;
  data?: KeyAreasData;
}

export const KeyBusinessDriversChart = ({
  className = "",
  style = {},
  data = [],
}: IProps) => {
  const { t } = useTranslation();
  return data.length ? (
    <div
      className={`${classes.keyBusinessDriversChart} ${className}`}
      style={style}
    >
      <div className={classes.top}>
        <div />
        <div className={classes.topLabel}>{t("w_kid_high_impact_today")}</div>
        <div className={classes.topLine} />
      </div>
      <div className={classes.right}>
        <div className={classes.rightLine} />
        <div className={classes.rightLabel}>
          {t("w_kid_high_future_potential")}
        </div>
        <div />
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomLine} />
        <div className={classes.bottomLabel}>{t("w_kid_low_impact")}</div>
        <div />
      </div>
      <div className={classes.left}>
        <div />
        <div className={classes.leftLabel}>{t("w_kid_low_potential")}</div>
        <div className={classes.leftLine} />
      </div>
      <div className={classes.main}>
        <div className={classes.square}>{t("w_kid_left_top_angle_text")}</div>
        <div className={classes.square}>{t("w_kid_right_top_angle_text")}</div>
        <div className={classes.square}>
          <Trans
            i18nKey="w_kid_left_bottom_angle_text"
            components={[<br></br>]}
          />
        </div>
        <div className={classes.square}>
          <Trans
            i18nKey="w_kid_right_bottom_angle_text"
            components={[<br></br>]}
          />
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.verticalLine} />
        {data.map((item, i) => (
          <div
            className={classes.mark}
            style={{
              left: item.values.potential + "%",
              bottom: item.values.impact + "%",
              zIndex: data.length - i,
            }}
            key={i}
          >
            <div
              className={classes.markLabel}
              style={{
                ...(item.values.potential > 75 ? { right: 4 } : { left: 4 }),
                ...(item.values.impact > 75 ? { top: 4 } : { bottom: 4 }),
              }}
            >
              {t(item.label)}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
