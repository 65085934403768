import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import compareAsc from "date-fns/compareAsc";
import add from "date-fns/add";
import isValid from "date-fns/isValid";

import { formatToDayMonthFullYearTimeDate } from "src/utils";
import context from "src/context";
import {
  selectCompanySubscriptionPlan,
  selectDashboardSearches,
  selectDashboardSyncs,
  selectSyncsStatus,
} from "src/store/selectors";
import { SubscriptionPlan } from "src/store/subscriptionPlans/subscriptionPlansSlice";

export const useDashboardSyncButton = (dashboardId: string) => {
  const { t } = useTranslation();

  const { keywordToolLastUpdateDate } = useContext(context);

  const dashboardSearches = useSelector(selectDashboardSearches(dashboardId));
  const syncs = useSelector(selectDashboardSyncs(dashboardId));
  const syncsStatus = useSelector(selectSyncsStatus);
  const subPlan = useSelector(selectCompanySubscriptionPlan);

  const isLoading = useMemo<boolean>(
    () => syncsStatus === "idle" || syncsStatus === "loading",
    [syncsStatus],
  );

  const oldestSyncDate = useMemo<Date | null>(() => {
    const oldestSync = getOldestSync(syncs);

    const date = new Date(oldestSync?.createdAt || "");

    if (!isValid(date)) return null;

    return date;
  }, [syncs]);

  const nextUpdateDate = useMemo<Date | null>(() => {
    if (!oldestSyncDate) return null;

    return add(oldestSyncDate, {
      weeks: subPlan?.updateFrequencyInWeeks || 0,
    });
  }, [oldestSyncDate, subPlan?.updateFrequencyInWeeks]);

  const formattedKeywordToolLastUpdateDate = useMemo<Date | null>(() => {
    if (!keywordToolLastUpdateDate) return null;

    const date = new Date(keywordToolLastUpdateDate);

    if (!isValid(date)) return null;

    return date;
  }, [keywordToolLastUpdateDate]);

  const isUpdateFrequencyPresent = useMemo<boolean>(
    () => checkIsUpdateFrequencyAvailable(subPlan),
    [subPlan],
  );

  const isAtLeastOneSearchFirstSyncReady = useMemo<boolean>(
    () => getIsAtLeastOneSearchFirstSyncReady(dashboardSearches, syncs),
    [dashboardSearches, syncs],
  );

  const isAtLeastOneLatestSyncExist = useMemo<boolean>(
    () => getIsAtLeastOneLatestSyncExist(syncs),
    [syncs],
  );

  const isEverySearchLatestSyncFinished = useMemo<boolean>(
    () => getIsEverySyncFinished(syncs),
    [syncs],
  );

  const isKeywordUpdateAvailable = useMemo<boolean>(
    () =>
      getIsKeywordUpdateAvailable(
        oldestSyncDate,
        formattedKeywordToolLastUpdateDate,
      ),
    [oldestSyncDate, formattedKeywordToolLastUpdateDate],
  );

  const isNextUpdateAvailable = useMemo<boolean>(
    () => getIsNextUpdateAvailable(nextUpdateDate),
    [nextUpdateDate],
  );

  const isUpdateAvailable = useMemo<boolean>(() => {
    // Unavailable if company SP has no update frequency
    if (!isUpdateFrequencyPresent) return false;

    // Unavailable if there are no searches with isFirstSyncDone equal true
    if (!isAtLeastOneSearchFirstSyncReady) return false;

    // Available if no searches latest syncs found. Indicates that company SP
    // changed the data source from Meltwater to Socialgist
    if (!isAtLeastOneLatestSyncExist) return true;

    // Unavailable if at least one search latest sync is unfinished
    if (!isEverySearchLatestSyncFinished) return false;

    // Available if keywordToolLastUpdateDate is after lastUpdateDate
    if (isKeywordUpdateAvailable) return true;

    // Available if currentDate is after nextUpdateDate
    return isNextUpdateAvailable;
  }, [
    isUpdateFrequencyPresent,
    isAtLeastOneSearchFirstSyncReady,
    isAtLeastOneLatestSyncExist,
    isEverySearchLatestSyncFinished,
    isKeywordUpdateAvailable,
    isNextUpdateAvailable,
  ]);

  const label = useMemo<string>(() => {
    if (!syncs.length) return t("d_sync_update");

    if (!isEverySearchLatestSyncFinished) return t("d_sync_pending");

    if (!isUpdateAvailable && nextUpdateDate)
      return t("d_sync_next_update", {
        date: formatToDayMonthFullYearTimeDate(nextUpdateDate),
      });

    if (oldestSyncDate)
      return t("d_sync_last_update", {
        date: formatToDayMonthFullYearTimeDate(oldestSyncDate),
      });

    return t("d_sync_update");
  }, [
    isEverySearchLatestSyncFinished,
    isUpdateAvailable,
    oldestSyncDate,
    nextUpdateDate,
    syncs.length,
    t,
  ]);

  return {
    label,
    isLoading: !isEverySearchLatestSyncFinished,
    isDisabled: isLoading || !isUpdateAvailable,
  };
};

// HELPERS
function getIsEverySyncFinished(syncs: Store.Sync[]): boolean {
  if (!syncs.length) return true;

  return syncs.every(({ status }) =>
    ["PENDING_REVIEW", "FINISHED"].includes(status),
  );
}

function getIsAtLeastOneSearchFirstSyncReady(
  searches: Store.Search[],
  syncs: Store.Sync[],
): boolean {
  return searches.some(
    (search) => getIsSearchSyncExists(search, syncs) || search.isFirstSyncDone,
  );
}

function getIsSearchSyncExists(
  { id }: Store.Search,
  syncs: Store.Sync[],
): boolean {
  return syncs.some(({ searchId }) => searchId === id);
}

function getOldestSync(syncs: Store.Sync[]): Store.Sync | null {
  return (
    syncs.sort((a, b) => a.createdAt.localeCompare(b.createdAt))[0] || null
  );
}

function checkIsUpdateFrequencyAvailable(subPlan?: SubscriptionPlan): boolean {
  return Boolean(subPlan?.updateFrequencyInWeeks);
}

function getIsAtLeastOneLatestSyncExist(syncs: Store.Sync[]): boolean {
  return Boolean(syncs.length);
}

function getIsKeywordUpdateAvailable(
  lastUpdateDate: Date | null,
  keywordLastDateUpdate: Date | null,
): boolean {
  if (!lastUpdateDate || !keywordLastDateUpdate) return false;

  return compareAsc(keywordLastDateUpdate, lastUpdateDate) > 0;
}

function getIsNextUpdateAvailable(nextUpdateDate: Date | null): boolean {
  if (!nextUpdateDate) return false;

  const currentDate = new Date();

  return compareAsc(currentDate, nextUpdateDate) > 0;
}
