import { useMemo } from "react";

export const useCurrentBrandIdAndName = (
  brandIndex: number,
  brands: Store.Brands,
): [string, string] => {
  const brandIdAndName: [string, string] = useMemo(() => {
    const currentBrandId = brands[brandIndex]?.id || "";
    const currentBrandName = brands[brandIndex]?.name || "";

    return [currentBrandId, currentBrandName];
  }, [brandIndex, brands]);

  return brandIdAndName;
};
