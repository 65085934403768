import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { WIDGETS_IN_REVIEW_DATA_BLOCK } from "src/components/ReviewData/constants";
import { REVIEW_DATA_WIDGETS } from "src/components/ReviewData/types";
import { selectCompanySubscriptionPlan } from "../subscriptionPlans/selectors";

export const selectAvailableWidgetsIds = createDraftSafeSelector(
  selectCompanySubscriptionPlan,
  (currentSubPlan) => {
    const availableWidgets = currentSubPlan?.availableWidgets || {};

    const availableWidgetsIds: string[] = [];

    for (const widgetId in availableWidgets) {
      const isWidgetAvailable = availableWidgets[widgetId];

      if (!isWidgetAvailable) continue;

      availableWidgetsIds.push(widgetId);
    }

    return availableWidgetsIds;
  },
);

export const selectUserReviewDataPermissions = createDraftSafeSelector(
  selectAvailableWidgetsIds,
  (availableWidgetsIds) => {
    const canUserReviewData = availableWidgetsIds.some(
      (widgetId) =>
        WIDGETS_IN_REVIEW_DATA_BLOCK[widgetId as REVIEW_DATA_WIDGETS],
    );

    return {
      canUserReviewData,
    };
  },
);
