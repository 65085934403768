import firebase from "firebase/app";
import * as yup from "yup";
import mapValues from "lodash/mapValues";

import actionTypes from "../actionTypes";
import { writeWidgetStateStructure } from "./api";

const widgetStateSchema = yup.lazy((obj) =>
  yup.object(
    mapValues(obj, () =>
      yup.object({
        isCalculating: yup.boolean(),
        _timestamp: yup.string(),
      }),
    ),
  ),
);

// MUTATIONS
const setWidgetsState = (
  searchId: string,
  widgetsState: Store.WidgetsState[number],
) => {
  return {
    type: actionTypes.SET_WIDGETS_STATE,
    searchId,
    widgetsState,
  };
};

export const subscribeSearchWidgetStateChange = (searchId: string) => {
  return (dispatch: Function, getState: () => Store.State) => {
    const db = firebase.firestore();
    const docRef = db.collection("searches-state").doc(searchId);

    const subscription = docRef.onSnapshot((snap: any) => {
      const { widgets } = snap.data() || {};

      const { widgetsState } = getState();

      if (widgets && typeof widgets === "object" && !Array.isArray(widgets)) {
        try {
          const validatedData = widgetStateSchema.validateSync(
            widgets,
          ) as Store.WidgetsState[string];

          const currentWidgetState = widgetsState[searchId];

          const newWidgetState = prepareWidgetStateData(
            validatedData,
            currentWidgetState,
          );

          dispatch(setWidgetsState(searchId, newWidgetState));
        } catch (err) {
          console.error(err);
          dispatch(setWidgetsState(searchId, {}));
        }
      }
    });

    return subscription;
  };
};

export const writeWidgetState = (searchId: string): Function => {
  return async (dispatch: Function, getState: () => RootState) => {
    const { widgetsData } = getState();

    const widgetStructure = createInitialStateShape(widgetsData);

    await writeWidgetStateStructure(searchId, widgetStructure);

    dispatch(setWidgetsState(searchId, widgetStructure));
  };
};

export const changeIsWidgetHasToBeReloadStatus = (
  searchId: string,
  widgetId: string,
  isHaveToBeReloaded: boolean,
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const state = getState() as Store.State;

    const { widgetsState } = state;
    const oldWidgetState = widgetsState[searchId]?.[widgetId];
    const { isHaveToBeReloaded: oldIsHaveToBeReloaded } = oldWidgetState || {};

    if (isHaveToBeReloaded !== oldIsHaveToBeReloaded) {
      const widgetState = oldWidgetState
        ? {
            ...oldWidgetState,
            isHaveToBeReloaded,
          }
        : {
            isCalculating: false,
            isHaveToBeReloaded,
            _timestamp: new Date().toISOString(),
          };

      dispatch(setWidgetsState(searchId, { [widgetId]: widgetState }));
    }
  };
};

export const changeIsWidgetBrokenStatus = (
  searchId: string,
  widgetId: string,
  isWidgetBroken: boolean,
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const state = getState() as RootState;

    const { widgetsState, widgetsData } = state;

    const widgetCollectionId = widgetsData.entities[widgetId]?.resultCollection;

    if (widgetCollectionId) {
      const oldWidgetState = widgetsState[searchId]?.[widgetId];
      const { isWidgetBroken: oldIsWidgetBroken } = oldWidgetState || {};

      if (isWidgetBroken !== oldIsWidgetBroken) {
        const widgetState = oldWidgetState
          ? {
              ...oldWidgetState,
              isWidgetBroken,
            }
          : {
              isCalculating: false,
              isWidgetBroken,
              _timestamp: new Date().toISOString(),
            };
        dispatch(setWidgetsState(searchId, { [widgetId]: widgetState }));
      }
    }
  };
};

export const makeAllWidgetsOfSearchAsBroken = (searchId: string): Function => {
  return async (dispatch: Function, getState: () => RootState) => {
    const widgetState: Store.WidgetsState["searchId"] = {};

    const { widgetsData } = getState();

    widgetsData.ids.forEach((widgetCollectionId) => {
      widgetState[widgetCollectionId] = {
        isCalculating: false,
        isWidgetBroken: true,
        _timestamp: new Date().toISOString(),
      };
    });

    dispatch(setWidgetsState(searchId, widgetState));
  };
};

// Helpers
function createInitialStateShape(widgetsData: RootState["widgetsData"]) {
  const widgetStructure: Store.WidgetsState[string] = {};

  for (const widgetData of Object.values(widgetsData.entities)) {
    const { resultCollection } = widgetData || {};

    if (resultCollection && !widgetStructure[resultCollection]) {
      widgetStructure[resultCollection] = {
        isCalculating: true,
        _timestamp: "",
      };
    }
  }

  return widgetStructure;
}

function prepareWidgetStateData(
  widgets: Store.WidgetsState[string],
  currentState?: Store.WidgetsState[string],
) {
  return Object.entries(widgets).reduce((acc, [widgetId, widgetData]) => {
    // Current widget state --->
    const currentWidgetStateData = currentState?.[widgetId];
    const isWidgetCalculatingRightNow = currentWidgetStateData?.isCalculating;
    // <--- Current widget state

    // New widget state --->
    const newWidgetCalculatingStatus = widgetData?.isCalculating;
    const widgetTimeStamp = widgetData?._timestamp;
    // New widget state --->

    const isHaveToBeReloaded =
      isWidgetCalculatingRightNow && newWidgetCalculatingStatus === false;

    acc[widgetId] = {
      ...(currentWidgetStateData || {}),
      isCalculating: Boolean(newWidgetCalculatingStatus),
      _timestamp: widgetTimeStamp || "",
      ...(isHaveToBeReloaded
        ? {
            isHaveToBeReloaded: true,
          }
        : {}),
    };

    return acc;
  }, {} as Store.WidgetsState[string]);
}
