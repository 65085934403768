import { FC } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./ChartButtonsBlock.module.scss";
import { ChartButton, WithTooltip } from "../../../../components";

// Inner imports
import type { ChartButtons } from "../../types";

export const ChartButtonsBlock: FC<{
  options: ChartButtons;
  activeChartsButtonId: string;
}> = ({ options, activeChartsButtonId }) => {
  const { t } = useTranslation();

  const ChartButtonElement = (props: ChartButtons[number]) => {
    const { id, icon, buttonNumber, handleClick, isDisabled } = props;

    return (
      <ChartButton
        className={cx(
          styles.chartsButton,
          activeChartsButtonId === id ? styles.chartsButtonActive : "",
        )}
        icon={icon}
        number={buttonNumber}
        onButtonClick={() => handleClick && handleClick(id)}
        key={id}
        isDisabled={isDisabled}
      />
    );
  };

  return (
    <div className={styles.chartsButtonsWrapper}>
      <p>{t("charts_buttons_title")}</p>
      <div className={styles.options}>
        {options.map((option) => {
          if (option.withTooltip) {
            return (
              <WithTooltip {...option.withTooltip} key={option.id}>
                {ChartButtonElement(option)}
              </WithTooltip>
            );
          }
          return ChartButtonElement(option);
        })}
      </div>
    </div>
  );
};
