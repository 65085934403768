import React, {
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useTranslation, Trans } from "react-i18next";

import styles from "./ReviewDataHeader.module.scss";
import context from "../../context";
import * as icons from "../../icons";
import { ReviewDataModal } from "./ReviewDataModal";
import { Select, Label, Image } from "..";
import { useWindowWidth } from "../../hooks";

type Props = {
  children: ReactNode;
  className?: string;
  currentSearchId: string;
  widgetNameForTitle?: string;
  aboutThisText?: string;
  openedFromSideBar?: boolean;
  onSearchChangeHandler: (searchId: string) => void;
  onBrandChangeHandler: (brand: Store.Brand) => void;
  onResetHandler: () => void;
};

export const ReviewDataHeader = React.memo(
  ({
    children,
    className,
    currentSearchId,
    widgetNameForTitle = "",
    aboutThisText,
    openedFromSideBar = false,
    onSearchChangeHandler,
    onBrandChangeHandler,
    onResetHandler,
  }: Props) => {
    const { t } = useTranslation();

    const { setModalElement } = useContext(context);

    const searches: Store.Searches = useSelector(
      ({ searches }: RootState) => searches,
    );

    const currentSearch: Store.Search | undefined = searches.find(
      ({ id }) => id === currentSearchId,
    );

    const [aboutThisOpened, setAboutThisOpened] = useState(false);
    const [currentCheckedBrandId, setCurrentCheckedBrandId] = useState(0);
    const windowWidth = useWindowWidth();

    const searchBrands = useMemo(() => currentSearch?.brandsTableNames || [], [
      currentSearch?.brandsTableNames,
    ]);

    const searchesOptions = useMemo(() => {
      return searches.map((el) => {
        const { name, id } = el;
        return {
          label: name,
          value: id,
        };
      });
    }, [searches]);

    const brandsOptions = useMemo(() => {
      return searchBrands.map(({ name: label, id: value }) => ({
        label,
        value,
      }));
    }, [searchBrands]);

    useEffect(() => {
      const gotCurrentSearchBrands = searchBrands[0];
      if (gotCurrentSearchBrands) {
        onBrandChangeHandler(gotCurrentSearchBrands);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBrands]);

    function handleSearchChange(value: string) {
      if (currentSearchId === value) return;

      setCurrentCheckedBrandId(0);
      onSearchChangeHandler(value);
    }

    function onBrandChanged({
      name,
      id,
      i,
    }: {
      name: string;
      id: string;
      i: number;
    }) {
      setCurrentCheckedBrandId(i);
      onBrandChangeHandler({ name, id });
    }

    function goBackButtonHandler() {
      setModalElement(
        openedFromSideBar ? (
          <ReviewDataModal searchId={currentSearchId} />
        ) : null,
      );
    }

    const isDesktop = windowWidth > 768;

    return (
      <>
        {isDesktop ? (
          <div className={cx(styles.reviewData, className ?? "")}>
            <button
              className={styles.goBackButton}
              onClick={goBackButtonHandler}
            >
              {t("rd_go_back_button")}
            </button>
            <div className={styles.header}>
              <h3>
                <icons.MagnifierPlus width={24} />
                <Trans
                  i18nKey="rd_review_data_title"
                  values={{ widgetNameForTitle }}
                />
              </h3>
              <div className={styles.headerButtons}>
                <button
                  className={styles.resetChangesBtn}
                  onClick={onResetHandler}
                >
                  <icons.Reload width={18} />
                  <span>{t("rd_reset_button")}</span>
                </button>
                {aboutThisText && (
                  <button
                    className={cx(
                      styles.aboutThisBtn,
                      aboutThisOpened ? styles.active : "",
                    )}
                    onClick={() => setAboutThisOpened((state) => !state)}
                  >
                    <p>
                      <icons.Triangle size={12} />
                      {t("rd_about_this")}
                    </p>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{ __html: aboutThisText }}
                      />
                    </div>
                  </button>
                )}
              </div>
            </div>
            <div className={styles.searchAndBrandSelector}>
              <div className={styles.searchSelect}>
                {currentSearchId ? (
                  <>
                    <Label
                      leftText={t("rd_modal_search_label")}
                      className={styles.boldLabel}
                    />
                    <div className={styles.searchName}>
                      {currentSearch?.name}
                    </div>
                  </>
                ) : (
                  <>
                    <Label
                      leftText={t("rd_choose_search_label")}
                      className={styles.boldLabel}
                    />
                    <Select
                      options={searchesOptions}
                      changeHandler={(value) => handleSearchChange(value)}
                      value={currentSearchId}
                      className={styles.select}
                    />
                  </>
                )}
              </div>
              <div className={styles.brandSelector}>
                <Label
                  leftText={t("rd_choose_brand_label")}
                  className={styles.boldLabel}
                />
                {!!brandsOptions.length ? (
                  <div className={styles.buttons}>
                    {brandsOptions.map(({ label, value }, i) => (
                      <button
                        className={cx(styles.brandButton, {
                          [styles.brandButtonActive!]:
                            i === currentCheckedBrandId,
                        })}
                        onClick={() => {
                          onBrandChanged({ name: label, id: value, i });
                        }}
                        key={value}
                      >
                        {label}
                      </button>
                    ))}
                  </div>
                ) : (
                  <div>{t("rd_no_brands_found_error")}</div>
                )}
              </div>
            </div>
            {children}
          </div>
        ) : (
          <div className={styles.isMobile}>
            <p>{t("rd_mobile_error")}</p>
            <Image name={"birdAI"} className={styles.image} />
          </div>
        )}
      </>
    );
  },
);
