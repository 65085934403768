import React from "react";
import images from "./images";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  name: ImageName;
  clickHandler?: () => void;
}

export const Image = ({
  className = "",
  style = {},
  name,
  clickHandler = () => {},
}: IProps) => {
  return (
    <img
      className={className}
      style={{ ...style, objectFit: "contain" }}
      src={images[name]}
      alt=""
      onClick={() => clickHandler()}
    />
  );
};
