import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./ReputationIndexPreview.module.scss";
import {
  Preloader,
  ChartButton,
  LineChart,
  ErrorPlaceholder,
} from "../../../components";
import reputationParameters from "../../../data/reputationParameters";

// Inner imports
import { ReputationIndexPieChart } from "./components/ReputationIndexPieChart/ReputationIndexPieChart";
import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const ReputationIndexPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const mainBrandData = useMemo(() => {
    if (!widgetData) return;
    return widgetData[0]?.brandData;
  }, [widgetData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const lineChart = mainBrandData?.lineChart;

    if (lineChart) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            labels={reputationParameters.map(({ name, color }) => ({
              id: name,
              name,
              color,
            }))}
            data={lineChart}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasBackground: true,
            }}
            axis={{
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    const pieChart = mainBrandData?.pieChart;

    if (pieChart) {
      charts.push({
        type: "pieChart",
        chart: <ReputationIndexPieChart data={pieChart} />,
      });
    }

    return charts;
  }, [mainBrandData, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
