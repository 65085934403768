import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import styles from "./PresentationFirstPage.module.scss";
import presentationBackground from "../../../../images/presentationBackground.png";
import context from "../../../../context";
import { Image } from "../../../../components";
import {
  fetchAllDashboards,
  fetchDashboardById,
} from "../../../../store/dashboards/dashboardsSlice";
import type {
  Dashboard,
  GridItem,
} from "../../../../store/dashboards/dashboardsSlice";

import {
  readDashboardCustomData,
  subscribeSearchWidgetStateChange,
  makeAllWidgetsOfSearchAsBroken,
  readTimePlots,
} from "../../../../store/actions";
import { getBrands, checkIfSearchDeleted } from "../../../../store/api";
import { FETCH_FUNCTIONS } from "../../helpers/widgetFetchFunctions";
import { fetchSubscriptionPlanById } from "../../../../store/subscriptionPlans/subscriptionPlansSlice";
import {
  fetchAllWidgetsData,
  selectAvailableWidgetsIdsForOneBrandSearch,
} from "../../../../store/widgetsData/widgetsDataSlice";
import { sortWidgetsOnDashboard, sendDataToGTM } from "../../../../utils";
import { SEARCH_ID_LENGTH } from "../../../../data/searchIdLength";
import { useCompetitorsPresence } from "../../../../hooks";
import { useReadDashboardViewSettings } from "../../../Home/Dashboards/components/DashboardContainer/hooks";

// Inner imports
import { ErrorPage } from "../index";

type Props = {
  layoutSize?: keyof Dashboard["layouts"];
  dashboardId?: string;
};

export const PresentationFirstPage: FC<Props> = ({
  layoutSize = "large",
  dashboardId = "",
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isGlobalPreloaderShown,
    setIsGlobalPreloaderShown,
    setActionBarProps,
  } = useContext(context);

  useEffect(() => {
    setActionBarProps({});
  }, [setActionBarProps]);

  // If userId exist it means that user authenticated;
  const userId = useSelector(({ user }: Store.State) => user.id);
  const dashboards = useSelector(({ dashboards }: RootState) => dashboards);
  const widgets = useSelector(({ widgets }: RootState) => widgets);
  const companySubscriptionPlanId = useSelector(
    ({ company }: Store.State) => company?.subscriptionPlanId,
  );
  const availableForOneBrandWidgets: string[] = useSelector(
    selectAvailableWidgetsIdsForOneBrandSearch,
  );
  const isCompanyLoaded = useSelector(({ user, company }: Store.State) => {
    const userId = user.id;
    const companyId = company.id;
    if (!userId) return true;
    return !!userId && !!companyId;
  });

  const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);
  const [isDashboardBroken, setIsDashboardBroken] = useState(false);
  const [isWidgetFetched, setIsWidgetFetched] = useState(false);
  const [isSubscriptionPlanFetched, setIsSubscriptionPlanFetched] = useState(
    false,
  );

  const dashboard = useMemo(() => dashboards.entities[dashboardId], [
    dashboardId,
    dashboards.entities,
  ]);

  const searchesIds: string[] = useMemo(
    () => Object.keys(dashboard?.tiles || {}),
    [dashboard?.tiles],
  );

  const widgetsState = useSelector(
    ({ widgetsState }: Store.State) => widgetsState,
  );

  useReadDashboardViewSettings(dashboardId);

  const searchesCompetitorsPresence = useCompetitorsPresence({ searchesIds });

  useEffect(() => {
    // Fetch subscription plan if user logged in
    if (!companySubscriptionPlanId) return;
    const _dispatch: AppDispatch = dispatch;

    (async () => {
      try {
        const result = await _dispatch(
          fetchSubscriptionPlanById(companySubscriptionPlanId),
        );
        unwrapResult(result);
      } catch (err) {
        console.error(err);
      } finally {
        setIsSubscriptionPlanFetched(true);
      }
    })();
  }, [companySubscriptionPlanId, setIsGlobalPreloaderShown, dispatch]);

  useEffect(() => {
    // Fetch dashboards

    // Dashboards already loaded
    if (dashboard || !isCompanyLoaded) {
      setIsDashboardBroken(false);
      setIsDashboardLoaded(true);
      return;
    }

    (async () => {
      await dispatch(fetchAllDashboards());

      if (dashboardId) {
        await Promise.all([
          dispatch(fetchDashboardById(dashboardId)),
          dispatch(readDashboardCustomData(dashboardId)),
          dispatch(fetchAllWidgetsData()),
        ]).then((res) => {
          const dashboardFetchResult = res[0] as {
            meta?: {
              requestStatus?: "rejected" | "fulfilled";
            };
          };

          const { requestStatus } = dashboardFetchResult?.meta || {};

          setIsDashboardBroken(requestStatus === "rejected");
          setIsDashboardLoaded(true);
        });
      }
    })();
  }, [isCompanyLoaded, dashboardId, dispatch, dashboard]);

  useEffect(() => {
    if (!dashboard) return;
    const subscriptions = searchesIds.map((searchId) => {
      const dispatchForWatch: AppDispatch = dispatch;
      return dispatchForWatch(subscribeSearchWidgetStateChange(searchId));
    });

    return () => {
      subscriptions.forEach((subscription) => subscription());
    };
  }, [dashboard, searchesIds, dispatch]);

  useEffect(() => {
    if (!dashboard) return;

    if (!!Object.keys(widgets).length && isDashboardLoaded) {
      return setIsWidgetFetched(true);
    }

    const widgetsForFetch = Object.entries(dashboard.tiles).reduce(
      (acc, item) => {
        const [searchId, widgets] = item;

        Object.keys(widgets).forEach((widgetId) =>
          acc.push({ searchId, widgetId }),
        );
        return acc;
      },
      [] as { searchId: string; widgetId: string }[],
    );

    const fetchedBrands: { [searchId: string]: Store.Brands } = {};

    (async () => {
      const deletedSearchesList: string[] = await getDeletedSearchesList(
        searchesIds,
      );

      if (deletedSearchesList.length) {
        deletedSearchesList.forEach((searchId) =>
          dispatch(makeAllWidgetsOfSearchAsBroken(searchId)),
        );
      }
    })();

    (async () => {
      await Promise.all(
        widgetsForFetch.map(async ({ searchId, widgetId }) => {
          if (!fetchedBrands[searchId]) {
            const brands = await getBrands(searchId);
            fetchedBrands[searchId] = brands;
          }

          const widgetFetchFunction = FETCH_FUNCTIONS[widgetId];

          if (!widgetFetchFunction) return;
          return await widgetFetchFunction({
            searchId,
            brands: fetchedBrands[searchId]!,
            dispatch,
          });
        }),
      );

      setIsWidgetFetched(true);
    })();
  }, [dashboard, searchesIds, widgets, isDashboardLoaded, dispatch]);

  useEffect(() => {
    // Remove loader if all necessary data has been loaded;
    const isUserSubscriptionPlanLoaded = !userId || isSubscriptionPlanFetched;

    setIsGlobalPreloaderShown(true);

    if (
      isDashboardBroken ||
      (isWidgetFetched && isUserSubscriptionPlanLoaded)
    ) {
      setIsGlobalPreloaderShown(false);
    }
  }, [
    isDashboardBroken,
    isSubscriptionPlanFetched,
    isWidgetFetched,
    userId,
    setIsGlobalPreloaderShown,
  ]);

  useEffect(() => {
    if (!dashboard?.companyId) return;

    (async () => {
      try {
        await dispatch(readTimePlots(dashboard.companyId));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dashboard?.companyId, dispatch]);

  const [
    firstPresentationWidgetSlug,
    firstPresentationWidgetSearchId,
  ] = useMemo<[string, string]>(() => {
    const standardValue: [string, string] = ["", ""];
    if (!dashboard || !layoutSize) return standardValue;

    const widgetsGridItem = dashboard?.layouts[layoutSize];

    const suitableWidgetForPresentation = filterBrokenAndUnavailableWidgets({
      widgetsGridItem,
      widgetsState,
      availableForOneBrandWidgets,
      searchesCompetitorsPresence,
    });

    if (!suitableWidgetForPresentation) return standardValue;

    const sortedWidgetsOnDashboard = sortWidgetsOnDashboard(
      suitableWidgetForPresentation,
    );

    const firstPresentationWidgetId =
      sortedWidgetsOnDashboard[0]?.i.slice(SEARCH_ID_LENGTH) || "";

    const firstPresentationWidgetSearchId =
      sortedWidgetsOnDashboard[0]?.i.replace(firstPresentationWidgetId, "") ||
      "";

    return [firstPresentationWidgetId, firstPresentationWidgetSearchId];
  }, [
    dashboard,
    layoutSize,
    searchesCompetitorsPresence,
    availableForOneBrandWidgets,
    widgetsState,
  ]);

  async function getDeletedSearchesList(searches: string[]) {
    const deletedSearchesList: string[] = [];

    await Promise.all(
      searches.map((searchId) => checkIfSearchDeleted(searchId)),
    ).then((values) => {
      values.forEach((isSearchDeleted, index) => {
        if (isSearchDeleted) {
          const searchId = searches[index]!;
          deletedSearchesList.push(searchId);
        }
      });
    });

    return deletedSearchesList;
  }

  function filterBrokenAndUnavailableWidgets({
    widgetsGridItem,
    widgetsState,
    availableForOneBrandWidgets,
    searchesCompetitorsPresence,
  }: {
    widgetsGridItem: GridItem[];
    widgetsState: Store.WidgetsState;
    availableForOneBrandWidgets: string[];
    searchesCompetitorsPresence: {
      [searchId: string]: {
        isCompetitorsPresent?: boolean;
      };
    };
  }) {
    try {
      const filteredWidgets = widgetsGridItem?.filter(({ i }) => {
        const widgetSearchId = i.slice(0, SEARCH_ID_LENGTH);
        const widgetId = i.replace(widgetSearchId, "");

        const isCompetitorsPresent =
          searchesCompetitorsPresence[widgetSearchId]?.isCompetitorsPresent;

        const isWidgetRequiresCompetitors =
          !isCompetitorsPresent &&
          !availableForOneBrandWidgets.includes(widgetId);

        if (isWidgetRequiresCompetitors) return false;

        const { isWidgetBroken } =
          widgetsState[widgetSearchId]?.[widgetId] || {};

        return !isWidgetBroken;
      });

      return filteredWidgets;
    } catch (err) {
      console.error(err);
      setIsDashboardBroken(true);
    }
  }

  if (!isDashboardLoaded || isGlobalPreloaderShown) return null;

  if (isDashboardBroken) return <ErrorPage />;

  return (
    <div
      className={styles.firstPresentationScreen}
      style={{ backgroundImage: `url(${presentationBackground})` }}
    >
      <div className={styles.content}>
        <div className={styles.texts}>
          <h1 className={styles.dashboardName}>{dashboard?.name}</h1>
          <p className={styles.description}>
            {t("presentation_first_page_text")}
          </p>
        </div>
        <Link
          className={styles.link}
          onClick={() => {
            try {
              sendDataToGTM("UserWatchPresentation");
            } catch (err) {
              console.error(err);
            }
          }}
          to={{
            pathname: `/presentation/${dashboardId}/${firstPresentationWidgetSlug}`,
            state: {
              searchId: firstPresentationWidgetSearchId,
              dashboardId,
              layoutSize,
            },
          }}
        >
          {t("presentation_go_to_presentation_btn")}
        </Link>
        <Image className={styles.image} name="presentationImage" />
      </div>
    </div>
  );
};
