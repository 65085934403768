import compareAsc from "date-fns/compareAsc";
import isValidDate from "date-fns/isValid";

// Inner imports
import { SortValue } from "./types";

const RECENT_ITEMS_MAX_COUNT = 10 as const;

export const getRecentItemsIds = <
  T extends { id: string; createdAt?: string; updatedAt?: string }
>(
  items: T[],
): string[] =>
  items
    .map(({ id, createdAt, updatedAt }) => ({
      id,
      sortDate: updatedAt || createdAt || "",
    }))
    .sort((a, b) => compareAsc(Date.parse(b.sortDate), Date.parse(a.sortDate)))
    .map(({ id }) => id)
    .slice(0, RECENT_ITEMS_MAX_COUNT);

export const sortByCreatedAt = <T extends { createdAt?: string }>(
  a?: T,
  b?: T,
): number =>
  compareAsc(Date.parse(b?.createdAt || ""), Date.parse(a?.createdAt || ""));

export const sortByCreatedAtOrUpdatedAt = <
  T extends { updatedAt?: string; createdAt?: string }
>(
  a?: T,
  b?: T,
): number =>
  compareAsc(
    Date.parse(b?.updatedAt || b?.createdAt || ""),
    Date.parse(a?.updatedAt || a?.createdAt || ""),
  );

export const sortValues = (
  valueA: SortValue,
  valueB: SortValue,
  sortDirection: "asc" | "desc",
): number => {
  let compareResult: number = 0;

  let [firstValue, secondValue] =
    sortDirection === "asc" ? [valueA, valueB] : [valueB, valueA];

  try {
    if (typeof firstValue === "string" && typeof secondValue === "string") {
      const isFirstValueDate = isValidDate(firstValue);
      const isSecondValueDate = isValidDate(secondValue);

      if (isFirstValueDate && isSecondValueDate) {
        firstValue = Date.parse(firstValue);
        secondValue = Date.parse(secondValue);

        compareResult = compareAsc(firstValue, secondValue);
      }

      if (!isFirstValueDate && !isSecondValueDate) {
        firstValue = firstValue.toLowerCase();
        secondValue = secondValue.toLowerCase();

        compareResult = firstValue.localeCompare(secondValue);
      }
    }

    if (typeof firstValue === "boolean" && typeof secondValue === "boolean") {
      firstValue = +firstValue;
      secondValue = +secondValue;

      compareResult = firstValue - secondValue;
    }

    if (typeof firstValue === "number" && typeof secondValue === "number") {
      compareResult = firstValue - secondValue;
    }
  } catch (err) {
    console.error(`Error in Table sorting. Values: ${valueA}, ${valueB}`);
  }

  return compareResult;
};
