import { useState } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import styles from "./SentimentDriversListView.module.scss";
import { List } from "./List";
import { Triangle } from "../../../icons";
import { useTranslation } from "react-i18next";
import {
  hideSentimentDriversWord,
  revealSentimentDriversWord,
} from "../../../store/actions";

type Props = {
  searchId: string;
  brandName: string;
  data: Omit<SentimentDrivers.Item, "date">;
  hiddenWords?: SentimentDrivers.HiddenWords["string"];
};

export const SentimentDriversListView = ({
  searchId,
  brandName,
  data,
  hiddenWords,
}: Props) => {
  const dispatch = useDispatch();

  const { positive, neutral, negative } = data;
  const { t } = useTranslation();

  const {
    positive: positiveHiddenWords,
    neutral: neutralHiddenWords,
    negative: negativeHiddenWords,
  } = hiddenWords || {};
  const [isHiddenWordsShown, setIsHiddenWordsShown] = useState(false);

  const WORD_HIDE_BUTTON_TEXT = t("w_sd_widget_list_hide_word_button");

  const WORD_UN_HIDE_BUTTON_TEXT = t("w_sd_widget_list_unhide_word_button");

  const onHideWordHandler = (
    wordData: SentimentDrivers.Value,
    type: "positive" | "negative" | "neutral",
  ) => {
    dispatch(hideSentimentDriversWord(searchId, brandName, type, wordData));
  };

  const onRevealWordHandler = (
    wordData: SentimentDrivers.Value,
    type: "positive" | "negative" | "neutral",
  ) => {
    dispatch(revealSentimentDriversWord(searchId, brandName, type, wordData));
  };

  function filterData(
    data: SentimentDrivers.Value[],
    hiddenWords: SentimentDrivers.HiddenWords["string"],
    type: "positive" | "negative" | "neutral",
  ) {
    return data?.filter(
      ({ word }) =>
        !hiddenWords[type]?.some(
          ({ word: hiddenWord }) =>
            hiddenWord.toLowerCase() === word.toLowerCase(),
        ),
    );
  }

  return (
    <div className={styles.sentimentDriversListView}>
      <div
        className={cx(
          styles.hiddenWords,
          styles[isHiddenWordsShown ? "opened" : ""],
        )}
      >
        <button onClick={() => setIsHiddenWordsShown((state) => !state)}>
          <Triangle size={10} />
          <span className={styles.hiddenWordButtonContext}>
            {t("w_sd_widget_list_view_button")}
          </span>
        </button>
        {isHiddenWordsShown && (
          <div className={styles.hiddenWordsList}>
            {!!positiveHiddenWords?.length ? (
              <List
                className={styles.hiddenList}
                label={t("w_sd_widget_list_positive_hidden_words")}
                data={positiveHiddenWords}
                wordButtonText={WORD_UN_HIDE_BUTTON_TEXT}
                onWordButtonTextClick={(wordData) =>
                  onRevealWordHandler(wordData, "positive")
                }
                labelClassName={styles.positiveWordsLabel}
              />
            ) : (
              <div>{t("w_sd_widget_list_no_hidden_words")}</div>
            )}
            {!!neutralHiddenWords?.length ? (
              <List
                className={styles.hiddenList}
                label={t("w_sd_widget_list_neutral_hidden_words")}
                data={neutralHiddenWords}
                wordButtonText={WORD_UN_HIDE_BUTTON_TEXT}
                onWordButtonTextClick={(wordData) =>
                  onRevealWordHandler(wordData, "neutral")
                }
                labelClassName={styles.neutralWordsLabel}
              />
            ) : (
              <div>{t("w_sd_widget_list_no_neutral_hidden_words")}</div>
            )}
            {!!negativeHiddenWords?.length ? (
              <List
                className={styles.hiddenList}
                label={t("w_sd_widget_list_negative_hidden_words")}
                data={negativeHiddenWords}
                wordButtonText={WORD_UN_HIDE_BUTTON_TEXT}
                onWordButtonTextClick={(wordData) =>
                  onRevealWordHandler(wordData, "negative")
                }
                labelClassName={styles.negativeWordsLabel}
              />
            ) : (
              <div>{t("w_sd_widget_list_no_negative_hidden_words")}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.listsWrapper}>
        {!!filterData(positive, hiddenWords || {}, "positive")?.length && (
          <List
            label={t("w_sd_widget_list_positive_words")}
            data={filterData(positive, hiddenWords || {}, "positive")}
            wordButtonText={WORD_HIDE_BUTTON_TEXT}
            onWordButtonTextClick={(wordData) =>
              onHideWordHandler(wordData, "positive")
            }
            labelClassName={styles.positiveWordsLabel}
          />
        )}
        {!!filterData(neutral, hiddenWords || {}, "neutral")?.length && (
          <List
            label={t("w_sd_widget_list_neutral_words")}
            data={filterData(neutral, hiddenWords || {}, "neutral")}
            wordButtonText={WORD_HIDE_BUTTON_TEXT}
            onWordButtonTextClick={(wordData) =>
              onHideWordHandler(wordData, "neutral")
            }
            labelClassName={styles.neutralWordsLabel}
          />
        )}
        {!!filterData(negative, hiddenWords || {}, "negative")?.length && (
          <List
            label={t("w_sd_widget_list_negative_words")}
            data={filterData(negative, hiddenWords || {}, "negative")}
            wordButtonText={WORD_HIDE_BUTTON_TEXT}
            onWordButtonTextClick={(wordData) =>
              onHideWordHandler(wordData, "negative")
            }
            labelClassName={styles.negativeWordsLabel}
          />
        )}
      </div>
    </div>
  );
};
