import domToImage from "dom-to-image-more";

import { sendDataToGTM } from "./googleTagManagerSender";

export const SNAPSHOT_FILTERED_CLASS = "filterFromSnapshot" as const;

type Props = {
  element: HTMLElement;
  options?: Parameters<typeof domToImage.toPng>[1];
  imageName?: string;
  widgetId?: string;
  beforeActive?: () => void;
  afterComplete?: () => void;
};

export const downloadDomAsImage = async ({
  element,
  options,
  imageName = "Screenshot",
  widgetId,
  beforeActive,
  afterComplete,
}: Props) => {
  element.classList.add("safeFont");
  if (!!beforeActive) beforeActive();

  const dataUrl = await domToImage.toPng(element, {
    ...options,
    filter: (node: Node & { className?: string }): boolean => {
      const classNames = node?.className?.split?.(" ") ?? [];

      if (classNames?.length > 0) {
        return classNames.indexOf(SNAPSHOT_FILTERED_CLASS) === -1;
      }
      return true;
    },
  });

  if (widgetId) {
    sendDataToGTM("UserDownloadedImage", { downloadedImageName: widgetId });
  }

  element.classList.remove("safeFont");
  const link = document.createElement("a");
  if (!!afterComplete) afterComplete();
  link.download = imageName + ".png";
  link.href = dataUrl;
  link.click();
};
