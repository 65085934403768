import { FC, FormEvent, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./EditFolderModal.module.scss";
import context from "src/context";
import { Check } from "src/icons";
import { withError } from "src/hocs";
import { useTemporaryErrors } from "src/hooks";
import { MultiSelect, Input, Label } from "src/components";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { selectAvailableDashboards } from "src/store/selectors";
import {
  updateOneFolder,
  removeOneFolder,
} from "src/store/folders/foldersSlice";

// Types
import type { MultiselectOption } from "src/components/inputs/MultiSelect/types";
import type { Dashboard } from "src/store/dashboards/dashboardsSlice";

// Inner imports
import type { EditFolderModalProps } from "./types";

const InputWithError = withError(Input);
const MultiSelectWithError = withError(MultiSelect);

export const EditFolderModal: FC<EditFolderModalProps> = ({ folderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModalElement, setIsGlobalPreloaderShown } = useContext(context);
  const { errors, setErrors } = useTemporaryErrors(3000);

  const folders = useSelector(({ folders }: RootState) => folders);
  const folder = useSelector(
    ({ folders }: RootState) => folders.entities[folderId],
  );
  const dashboards = useSelector(selectAvailableDashboards);

  const [folderName, setFolderName] = useState<string>(folder?.name || "");
  const [selectedDashboards, setSelectedDashboards] = useState<
    MultiselectOption[]
  >([]);

  useEffect(() => {
    const modalWrapperRef = document.getElementById("modal-wrapper");
    if (!modalWrapperRef) return;

    const modalWrapperChildrenWrapper = modalWrapperRef
      .childNodes[0]! as HTMLElement;

    modalWrapperChildrenWrapper.style.overflow = "initial";
  }, []);

  useEffect(() => {
    const selectedDashboards: MultiselectOption[] = [];

    folder?.dashboardIds.forEach((dashboardId) => {
      const dashboardName = dashboards.entities[dashboardId]?.name;

      if (dashboardName) {
        selectedDashboards.push({
          label: dashboardName,
          value: dashboardId,
        });
      }
    });

    setSelectedDashboards(selectedDashboards);
  }, [dashboards.entities, folder]);

  const allFoldersName = useMemo(() => {
    const names: string[] = [];

    Object.values(folders.entities).forEach((folder) => {
      const folderName = folder?.name;
      const isThisFolder = folderId === folder?.id;
      if (folderName && !isThisFolder) names.push(folderName);
    });

    return names;
  }, [folders.entities, folderId]);

  const dashboardsInFolderIds = useMemo(() => {
    const thisFolderDashboards = folders.entities[folderId]?.dashboardIds || [];

    return Object.values(folders.entities).reduce(
      (dashboardsIds, folderData) => {
        const dashboardsInFolder = folderData?.dashboardIds || [];

        const dashboardsNotInThisFolder = dashboardsInFolder.filter(
          (dashboardId) => !thisFolderDashboards.includes(dashboardId),
        );

        dashboardsIds.push(...dashboardsNotInThisFolder);
        return dashboardsIds;
      },
      [] as string[],
    );
  }, [folders.entities, folderId]);

  const allowedForChoseDashboards = useMemo(() => {
    const suitableDashboards = Object.values(dashboards.entities).filter(
      (dashboardData) => {
        if (!dashboardData) return false;
        const { id } = dashboardData;

        return !dashboardsInFolderIds.includes(id);
      },
    ) as Dashboard[];

    return suitableDashboards.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [dashboards, dashboardsInFolderIds]);

  const onDashboardCheckHandler = (option: Option) => {
    const optionValue = option.value;
    const isDashboardChecked = selectedDashboards.some(
      ({ value }) => value === optionValue,
    );

    if (!isDashboardChecked)
      return setSelectedDashboards((state) => [...state, option]);

    const newSelectedDashboards = selectedDashboards.filter(
      ({ value }) => value !== optionValue,
    );
    setSelectedDashboards(newSelectedDashboards);
  };

  const onSubmitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate();

    if (!!Object.keys(errors).length) return;

    const dashboardIds = selectedDashboards.map(({ value }) => value);
    const isAllDashboardsUnchecked = !dashboardIds.length;

    try {
      setIsGlobalPreloaderShown(true);

      if (isAllDashboardsUnchecked) {
        await dispatch(removeOneFolder(folderId));

        showToastNotification({
          type: "success",
          text: t("d_table_folder_delete_success"),
        });
      } else {
        await dispatch(
          updateOneFolder({
            id: folderId,
            changes: { name: folderName, dashboardIds },
          }),
        );
      }
    } catch (err) {
      showToastNotification({
        type: "error",
        text: t("request_error"),
      });
      console.error(err);
    } finally {
      setIsGlobalPreloaderShown(false);
      setModalElement(null);
    }
  };

  const onResetFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setModalElement(false);
  };

  function validate() {
    const errors: Errors = {};

    const trimmedName = folderName.trim().toLowerCase();

    if (!trimmedName) {
      errors["name"] = t("ef_required_error");
    }

    if (
      allFoldersName.some((name) => name.trim().toLowerCase() === trimmedName)
    ) {
      errors["name"] = t("ef_folder_already_exist_error");
    }

    if (!selectedDashboards.length) {
      errors["dashboards"] = t("cf_required_error");
    }

    setErrors(errors);
    return errors;
  }

  return (
    <div className={styles.editFolderModal}>
      <div className={styles.head}>
        <Check />
        {t("ef_title")}
      </div>
      <form
        className={styles.editFolderForm}
        onSubmit={onSubmitFormHandler}
        onReset={onResetFormHandler}
      >
        <div>
          <Label leftText={t("ef_folder_name_label")} />
          <InputWithError
            placeholder={t("ef_folder_name_placeholder")}
            value={folderName}
            changeHandler={setFolderName}
            error={errors["name"]}
          />
        </div>
        <div>
          <Label leftText={t("ef_dashboards_label")} />
          <MultiSelectWithError
            showFilter
            placeholder={t("ef_dashboards_placeholder")}
            customInputText={t("cf_dashboards_text", {
              count: selectedDashboards.length,
            })}
            openDirection={"down"}
            allUnselectedText={t("ef_all_dashboards_unselected_text")}
            options={allowedForChoseDashboards}
            selectedOptions={selectedDashboards}
            onCheckHandler={onDashboardCheckHandler}
            error={errors["dashboards"]}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <button type="reset">{t("ef_reset_button")}</button>
          <button type="submit">{t("ef_submit_button")}</button>
        </div>
      </form>
    </div>
  );
};
