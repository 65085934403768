import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { syncsAdapter } from "./syncsSlice";
import { selectDashboardSearchIds } from "../dashboards/selectors";

export const {
  selectAll: selectSyncs,
  selectEntities: selectSyncsEntities,
} = syncsAdapter.getSelectors<RootState>(({ syncs }) => syncs);

export const selectSyncsStatus = createDraftSafeSelector(
  ({ syncs }: RootState) => syncs,
  ({ status }) => status,
);

export const selectDashboardSyncs = (dashboardId: string) =>
  createDraftSafeSelector(
    selectDashboardSearchIds(dashboardId),
    selectSyncsEntities,
    (searchIds, syncs) => {
      const allSyncs = Object.values(syncs);
      const filteredSyncs: Store.Sync[] = [];

      for (const searchId of searchIds) {
        const sync = allSyncs.find((sync) => sync?.searchId === searchId);

        if (sync) filteredSyncs.push(sync);
      }

      return filteredSyncs;
    },
  );
