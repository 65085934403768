import { useState, useMemo, useRef, useCallback, useContext, FC } from "react";
import {
  ResponsiveContainer,
  LineChart as Chart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  Label,
} from "recharts";
import Switch from "react-switch";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import styles from "./LineChart.module.scss";
import context from "src/context";
import { formatToMonthDayYearDate, getPercentageValue } from "src/utils";
import { SNAPSHOT_FILTERED_CLASS } from "src/utils/downloadDomAsImage";
import { BlackButton } from "src/components";
import { ROUTS } from "src/data/routs";
import { selectDashboardById } from "src/store/selectors";
import { toggleIsTimePlottersShown } from "src/store/timePlots/actions";
import { Dashboard } from "src/store/dashboards/dashboardsSlice";

// Inner imports
import {
  SWITCHER_STYLES,
  TICK_STYLES,
  CATEGORY_TREND_LINE,
  GREY_COLOR,
} from "./constants";
import type {
  LineChartProps,
  FormattedChartItem,
  ChartItem,
  ChartTimePlotter,
} from "./types";
import {
  getModifiedKeyObject,
  getTickStep,
  getTimePlotterLabel,
  getTrendLineMinMaxValues,
  getTrendLineValues,
} from "./helpers";
import { CustomizedLabel, CustomTooltip, Labels } from "./components";
import {
  useFilterLineChartData,
  usePrepareLineChartData,
  useLabels,
} from "./hooks";

export const LineChart: FC<LineChartProps> = ({
  className = "",
  style,
  labels: _labels = [],
  data: rawData = [],
  filteredData: preparedData = [],
  dashboardId: _dashboardId = "",
  searchId = "",
  timePlotterId = "",
  viewMode = "widgetPage",
  chartStyles: { graphHeight, lineStrokeWidth = 2 } = {},
  chartSettings: { hasTrendLine = false, hasBackground } = {},
  axis: {
    yAxisLegend,
    yAxisUnit = "",
    yAxisVerticalPadding = { top: 0, bottom: 0 },
    increaseYAxisWidthInPercentage = 0,
  } = {},
  formatDateHandler = formatToMonthDayYearDate,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const params: { [property: string]: string } | undefined = useParams();

  const chartWrapperRef = useRef<HTMLDivElement>(null);

  const { isPresentation } = useContext(context);

  const dashboardId = useMemo<string>(
    () => _dashboardId || params?.dashboardId || "",
    [_dashboardId, params?.dashboardId],
  );

  const dashboard: Dashboard | undefined = useSelector(
    selectDashboardById(dashboardId),
  );

  const widgetsData = useSelector(
    ({ widgetsData }: RootState) => widgetsData.entities,
  );

  const _isShowTimePlotters: boolean = useSelector(
    ({ timePlots }: Store.State) => timePlots.isShownInLineChart,
  );

  const [yAxisWidth, setYAxisWidth] = useState<null | number>(null);
  const [isShowTimePlotters, setIsShowTimePlotters] = useState<boolean>(
    _isShowTimePlotters,
  );

  const { data: _data, timePlotters } = usePrepareLineChartData({
    data: rawData,
    searchId,
    timePlotterId,
    viewMode,
  });

  const data = useMemo<ChartItem[]>(() => {
    if (preparedData?.length) return preparedData;

    return _data;
  }, [preparedData, _data]);

  const {
    filteredData,
    selectedTimePlotter,
    setSelectedTimePlotterId,
  } = useFilterLineChartData({
    data,
    timePlotters,
    timePlotterId,
  });

  // LABELS ---->
  const { labels, inactiveLabels, onLabelClickHandle } = useLabels({
    initialLabels: _labels,
    data,
    isHasTrendLine: hasTrendLine,
  });
  // <---- LABELS

  // TREND LINE ---->
  const trendLineData = useMemo<Record<number, number>>(() => {
    if (!hasTrendLine) return {};

    return getTrendLineValues(data, inactiveLabels);
  }, [hasTrendLine, data, inactiveLabels]);

  const isTrendLineVisible = useMemo<boolean>(
    () => hasTrendLine && !inactiveLabels.includes(CATEGORY_TREND_LINE.label),
    [hasTrendLine, inactiveLabels],
  );
  // <---- TREND LINE

  const widgetId = useMemo<string>(() => params?.widgetSlug || "", [
    params?.widgetSlug,
  ]);

  const backToLineChart = useMemo<ActionBar.BreadcrumbOption>(() => {
    const dashboardName = dashboard?.name || "";
    const widgetName = t(widgetsData[widgetId]?.placeHolderOnUi || "");

    const title = `${dashboardName} - ${widgetName}`;

    return {
      title,
      linkOptions: {
        href: history.location.pathname,
        state: {
          searchId,
        },
      },
    };
  }, [dashboard?.name, history.location, searchId, t, widgetId, widgetsData]);

  const isTimePlotterButtonActive = useMemo<boolean>(
    () => !dashboard?.isCopiedDashboard,
    [dashboard?.isCopiedDashboard],
  );

  const { isTimePlotterSummaryMode, isWidgetViewMode } = useMemo<{
    [key: string]: boolean;
  }>(
    () => ({
      isWidgetViewMode: viewMode === "widgetPage",
      isTimePlotterSummaryMode: viewMode === "timePlotterSummary",
    }),
    [viewMode],
  );

  const isTooltipAvailable = useMemo<boolean>(() => {
    const labelsLength = labels.length - (hasTrendLine ? 1 : 0);
    const inactiveLabelsLength = inactiveLabels.filter(
      (label) => label !== CATEGORY_TREND_LINE.label,
    ).length;

    return labelsLength !== inactiveLabelsLength;
  }, [labels, inactiveLabels, hasTrendLine]);

  const { pastData, predictionData, partialData } = useMemo(() => {
    const pastData: FormattedChartItem[] = [];
    const predictionData: FormattedChartItem[] = [];
    const partialData: FormattedChartItem[] = [];

    const currentTime = new Date().getTime();

    const initialPastData = filteredData.filter(
      ({ time }) => time <= currentTime,
    );

    const divisionTime = initialPastData.pop()?.time || currentTime;

    let isFirstPartialElement = true;

    filteredData.forEach(({ time, values, isPartial, additionalValues }) => {
      const modifiedValues: typeof values = {};
      const trendLineValues: typeof values = {};

      if (isTrendLineVisible) {
        trendLineValues[CATEGORY_TREND_LINE.label] = trendLineData[time] || 0;
      }

      for (const key in values) {
        if (inactiveLabels.includes(key)) continue;

        const value = values[key] || 0;

        modifiedValues[key] = Number(value.toFixed(2));
      }

      switch (true) {
        case isPartial: {
          partialData.push({
            ...getModifiedKeyObject(modifiedValues, "partial"),
            ...trendLineValues,
            date: time,
            additionalValues,
          });

          if (isFirstPartialElement) {
            isFirstPartialElement = false;
            pastData.push({
              ...modifiedValues,
              ...trendLineValues,
              date: time,
              additionalValues,
            });
          }

          break;
        }

        case time < divisionTime: {
          pastData.push({
            ...modifiedValues,
            ...trendLineValues,
            date: time,
            additionalValues,
          });

          break;
        }

        case time === divisionTime: {
          pastData.push({
            ...modifiedValues,
            ...trendLineValues,
            ...getModifiedKeyObject(modifiedValues, "prediction"),
            date: time,
            additionalValues,
          });

          break;
        }

        default: {
          predictionData.push({
            date: time,
            ...trendLineValues,
            ...getModifiedKeyObject(modifiedValues, "prediction"),
            additionalValues,
          });
        }
      }
    });

    return { pastData, predictionData, partialData };
  }, [filteredData, inactiveLabels, isTrendLineVisible, trendLineData]);

  const allData = useMemo<FormattedChartItem[]>(
    () => [...pastData, ...predictionData, ...partialData],
    [pastData, predictionData, partialData],
  );

  // TICKS
  const yAxisTicks = useMemo<number[]>(() => {
    let [minValue, maxValue] = [0, 0];

    const isTrendLineActive = !inactiveLabels.includes(
      CATEGORY_TREND_LINE.label,
    );

    if (isTrendLineActive) {
      const [minTrendLineValue, maxTrendLineValue] = getTrendLineMinMaxValues(
        trendLineData,
      );

      minValue = Math.min(minValue, minTrendLineValue);
      maxValue = Math.max(maxValue, maxTrendLineValue);
    }

    for (const { values } of data) {
      const trackers = Object.entries(values);

      for (const [trackerName, value] of trackers) {
        if (inactiveLabels.includes(trackerName)) continue;

        if (value < minValue) minValue = value;
        if (value > maxValue) maxValue = value;
      }
    }

    // If all data values equal zero - change min and max tick values for a proper
    // event area rendering
    if (maxValue === 0 && minValue === 0) {
      minValue = -1;
      maxValue = 1;
    }

    const [minValueRemainder, maxValueRemainder] = [
      minValue % 20,
      maxValue % 20,
    ];

    const [minTick, maxTick] = [
      minValueRemainder
        ? minValue > 0
          ? minValue - minValueRemainder
          : minValue - (20 + minValueRemainder)
        : minValue,
      maxValueRemainder
        ? maxValue > 0
          ? maxValue - maxValueRemainder + 20
          : maxValue - maxValueRemainder
        : maxValue,
    ];

    const ticks: number[] = [];

    const tickStep = getTickStep(minTick, maxTick);

    for (let i = minTick; i <= maxTick; i += tickStep) {
      ticks.push(i);
    }

    return ticks;
  }, [inactiveLabels, trendLineData, data]);

  const xDomain = useMemo<[number, number] | undefined>(() => {
    if (!allData.length) return;

    const { minValue, maxValue } = allData.reduce(
      (acc, { date }) => {
        if (date < acc?.minValue) acc.minValue = date;
        if (date > acc?.maxValue) acc.maxValue = date;
        return acc;
      },
      { minValue: allData[0]?.date || 0, maxValue: 0 },
    );

    return [minValue, maxValue];
  }, [allData]);

  const yDomain = useMemo<[number, number]>(() => {
    const minValue = yAxisTicks[0] || 0;
    const maxValue = yAxisTicks[yAxisTicks.length - 1] || 0;

    return [
      minValue,
      maxValue + getPercentageValue(maxValue, increaseYAxisWidthInPercentage),
    ];
  }, [yAxisTicks, increaseYAxisWidthInPercentage]);

  // BACKGROUND
  const Background = useCallback(
    (opacity?: number) => {
      if (!hasBackground) return;

      const { negativeColors, positiveColors } = yAxisTicks.slice(0, -1).reduce(
        (acc, tick) => {
          tick + 10 < 0
            ? acc.negativeColors.push(
                `rgba(239, 51, 106, ${
                  0.05 + acc.negativeColors.length * 0.05
                } )`,
              )
            : acc.positiveColors.push(
                `rgba(61, 159, 98, ${
                  0.05 + acc.positiveColors.length * 0.05
                } )`,
              );
          return acc;
        },
        { negativeColors: [] as string[], positiveColors: [] as string[] },
      );

      return (
        <div className={styles.background} style={{ opacity }}>
          {[...positiveColors.reverse(), ...negativeColors].map((color, i) => (
            <div style={{ backgroundColor: color }} key={i} />
          ))}
        </div>
      );
    },
    [hasBackground, yAxisTicks],
  );

  const getAxisWidth = (el: any) => {
    const container = el?.container as Element | null | undefined;

    if (!container) return;
    const yAxis = container?.querySelector(".recharts-yAxis");

    const gotYAxisWidth = yAxis?.getBoundingClientRect().width;

    !yAxisWidth && gotYAxisWidth && setYAxisWidth(gotYAxisWidth);
  };

  const onTimePlotterClick = (id: string): void =>
    setSelectedTimePlotterId((activeId) => (id === activeId ? "" : id));

  const onTimePlotterTogglerChange = (value: boolean): void => {
    if (!value) setSelectedTimePlotterId("");
    setIsShowTimePlotters(value);
    dispatch(toggleIsTimePlottersShown());
  };

  const timePlottersToRender = useMemo<ChartTimePlotter[]>(() => {
    if (!isWidgetViewMode || !isShowTimePlotters) return [];

    if (selectedTimePlotter?.id) return [selectedTimePlotter];

    return timePlotters;
  }, [isShowTimePlotters, isWidgetViewMode, selectedTimePlotter, timePlotters]);

  if (!allData.length) return null;

  return (
    <div className={cx(styles.lineChart, className)} style={style}>
      <div className={styles.head}>
        <Labels
          labels={labels}
          inactiveLabels={inactiveLabels}
          inactiveLabelClickHandler={onLabelClickHandle}
        />
      </div>
      <div className={styles.chartWrapperOuter} style={{ height: graphHeight }}>
        <div className={styles.legendAndTogglerWrapper}>
          {yAxisLegend && (
            <div className={styles.yAxisLegend} title={yAxisLegend}>
              {yAxisLegend}
            </div>
          )}
          <div className={styles.togglerWrapperRightSide}>
            {isWidgetViewMode && !!timePlotters.length && (
              <div
                className={styles.timePlotterToggler}
                style={{ width: isPresentation ? "auto" : "" }}
              >
                <span>{t("events_toggler_button_text")}</span>
                <Switch
                  checked={isShowTimePlotters || false}
                  onChange={onTimePlotterTogglerChange}
                  handleDiameter={SWITCHER_STYLES.HANDLER_DIAMETER}
                  offColor={SWITCHER_STYLES.COLOR_OFF}
                  onColor={SWITCHER_STYLES.COLOR_ON}
                  height={SWITCHER_STYLES.HEIGHT}
                  width={SWITCHER_STYLES.WIDTH}
                  activeBoxShadow={SWITCHER_STYLES.ACTIVE_BOX_SHADOW}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            )}
            {isTimePlotterButtonActive && !isPresentation && isWidgetViewMode && (
              <div className={styles.timePlotterModalWrapper}>
                <BlackButton
                  text={t("event_form_plotter_modal_add_btn")}
                  onClickHandler={() =>
                    history.push(ROUTS.eventsNew, backToLineChart)
                  }
                  additionalClassName={SNAPSHOT_FILTERED_CLASS}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <div className={styles.chartWrapperInner} ref={chartWrapperRef}>
            {!!pastData.length && (
              <div
                className={styles.backgroundWrapper}
                style={{ flex: pastData.length - 1 }}
              >
                {!!predictionData.length && (
                  <div
                    className={cx(styles.title, {
                      [styles.titleShifted!]: isShowTimePlotters,
                    })}
                    style={{ paddingRight: 12 }}
                  >
                    <div className={styles.titleLine} />
                    <div className={styles.titleText}>PAST</div>
                  </div>
                )}
                {Background()}
              </div>
            )}

            {!!pastData.length && !!predictionData.length && (
              <div
                className={cx(styles.dividerVertical, {
                  [styles.dividerVerticalShifted!]: isShowTimePlotters,
                })}
              />
            )}

            {!!predictionData.length && (
              <div
                className={styles.backgroundWrapper}
                style={{ flex: predictionData.length }}
              >
                {!!pastData.length && (
                  <div
                    className={cx(styles.title, {
                      [styles.titleShifted!]: isShowTimePlotters,
                    })}
                    style={{ paddingLeft: 12 }}
                  >
                    <div className={styles.titleText}>PREDICTION</div>
                    <div className={styles.titleLine} />
                  </div>
                )}
                {Background(0.3)}
              </div>
            )}

            {partialData.length > 1 && (
              <div
                className={styles.backgroundWrapper}
                style={{ flex: partialData.length }}
              >
                {!!partialData.length && (
                  <div className={styles.title} style={{ paddingLeft: 12 }}>
                    <div className={styles.titleText}>PARTIAL</div>
                    <div className={styles.titleLine} />
                  </div>
                )}
                {Background(0.3)}
              </div>
            )}
            <div className={styles.chart}>
              <ResponsiveContainer>
                <Chart
                  data={allData}
                  style={{ cursor: "inherit" }}
                  ref={getAxisWidth}
                >
                  {!hasBackground && (
                    <CartesianGrid
                      stroke={GREY_COLOR}
                      opacity={0.25}
                      vertical={false}
                    />
                  )}
                  <XAxis
                    type="number"
                    dataKey="date"
                    stroke={GREY_COLOR}
                    tick={TICK_STYLES}
                    scale="time"
                    domain={xDomain}
                    tickFormatter={(time) => `${formatDateHandler(time)}`}
                    interval="preserveStartEnd"
                  />
                  <YAxis
                    tick={TICK_STYLES}
                    width={yAxisWidth ?? 60}
                    tickFormatter={(tick) => `${tick}${yAxisUnit}`}
                    domain={yDomain}
                    type="number"
                    padding={
                      isShowTimePlotters
                        ? { top: 20, bottom: 0 }
                        : yAxisVerticalPadding
                    }
                  />
                  {isTooltipAvailable && (
                    <Tooltip
                      content={
                        <CustomTooltip
                          formatDateHandler={formatDateHandler}
                          yAxisUnit={yAxisUnit}
                        />
                      }
                    />
                  )}
                  {yAxisWidth
                    ? labels.map(({ name, color }, i) => (
                        <Line
                          type="monotone"
                          dataKey={name}
                          stroke={color}
                          strokeWidth={lineStrokeWidth}
                          strokeDasharray="100 0"
                          dot={false}
                          connectNulls
                          key={i}
                        />
                      ))
                    : null}
                  {yAxisWidth
                    ? labels.map(({ name, color }, i) => (
                        <Line
                          key={`${i}prediction`}
                          type="monotone"
                          dataKey={`${name} (prediction)`}
                          stroke={color}
                          strokeWidth={lineStrokeWidth}
                          strokeDasharray="4 2"
                          dot={false}
                          connectNulls
                        />
                      ))
                    : null}
                  {yAxisWidth
                    ? labels.map(({ name, color }, i) => (
                        <Line
                          key={`${i}partial`}
                          type="monotone"
                          dataKey={`${name} (partial)`}
                          stroke={color}
                          strokeWidth={lineStrokeWidth}
                          strokeDasharray="4 2"
                          dot={false}
                          connectNulls
                        />
                      ))
                    : null}
                  {timePlottersToRender.map((timePlotter) => {
                    const { id, startDate, endDate, color } = timePlotter;

                    const label = getTimePlotterLabel(timePlotter, t);

                    return (
                      <ReferenceArea
                        key={id}
                        isFront
                        x1={startDate}
                        x2={endDate}
                        y1={yDomain[0]}
                        y2={yDomain[1]}
                        fill={color}
                        strokeOpacity={0.8}
                        onClick={() => onTimePlotterClick(id)}
                        className={cx(
                          !isTimePlotterSummaryMode
                            ? styles.timePlotterArea
                            : "",
                          !!selectedTimePlotter?.id
                            ? styles.timePlotterAreaActive
                            : "",
                          styles.timePlotterLabelName,
                        )}
                      >
                        <Label
                          {...(selectedTimePlotter?.id
                            ? {
                                content: (
                                  <CustomizedLabel
                                    timePlotter={timePlotter}
                                    onClick={onTimePlotterClick}
                                    formatDateHandler={formatDateHandler}
                                    color={color}
                                    ref={chartWrapperRef}
                                  />
                                ),
                              }
                            : {})}
                          position="top"
                          value={label}
                          className={styles.timePlotterLabel}
                          onClick={() => onTimePlotterClick(id)}
                          offset={6}
                        />
                      </ReferenceArea>
                    );
                  })}
                </Chart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
