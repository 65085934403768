import actionTypes from "../actionTypes";
// import awarenessData from "../../data/dummy/awareness";

export const initialState: {
  [searchId: string]: Store.Widgets;
} = {};

const setWidget = (
  state: {
    [searchId: string]: Store.Widgets;
  },
  action: any,
) => {
  return {
    ...state,
    [action.searchId]: {
      ...state[action.searchId],
      [action.widgetName]: action.widgetData,
    },
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_WIDGET:
      return setWidget(state, action);
    default:
      return state;
  }
};

export default reducer;
