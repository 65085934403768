import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./BusinessDrivers.module.scss";
import {
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
} from "src/components";
import { ALL_TIME_RANGE, INITIAL_DATE_RANGE } from "src/data/dateRanges";
import { useNameForDownloadFiles } from "src/hooks";

// Inner imports
import {
  BusinessDriversBarChart,
  BusinessDriversLineChart,
} from "./components";
import type { BUSINESS_DRIVERS_DATE_RANGERS_TYPE } from "./types";
import { BUSINESS_DRIVERS_DATE_RANGES, WIDGET_ID } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const BusinessDrivers: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // ----> Brand selector
  const [brandIndex, setBrandIndex] = useState(0);

  useEffect(() => {
    setBrandIndex(0);
  }, [widgetData]);

  const brandsName = useMemo(
    () => widgetData?.map(({ brandName }) => brandName) || [],
    [widgetData],
  );
  // <---- Brand selector

  const brandData = useMemo(() => widgetData?.[brandIndex]?.brandData, [
    widgetData,
    brandIndex,
  ]);

  const { lineChartData, barChartData } = useMemo(() => {
    const { lineChart = [], barChart = {} } =
      widgetData?.[brandIndex]?.brandData || {};

    return {
      lineChartData: lineChart,
      barChartData: barChart,
    };
  }, [brandIndex, widgetData]);

  // Date Range ---->
  const [
    currentDateRange,
    setCurrentDateRange,
  ] = useState<BUSINESS_DRIVERS_DATE_RANGERS_TYPE>(INITIAL_DATE_RANGE);
  // <---- Date Range

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (Object.keys(barChartData)) {
      charts.push({
        type: "barChart",
        chart: (
          <BusinessDriversBarChart
            data={barChartData}
            dateRange={currentDateRange}
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <BusinessDriversLineChart
            data={lineChartData}
            searchId={searchId}
            dateRange={currentDateRange}
            dashboardId={dashboardId}
          />
        ),
      });
    }

    return charts;
  }, [barChartData, currentDateRange, dashboardId, lineChartData, searchId]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};
  // <---- Widget views

  // Init Date Range ---->
  const dateRanges = useMemo(() => {
    if (viewType === "barChart") {
      return BUSINESS_DRIVERS_DATE_RANGES.filter(
        ({ value }) => brandData?.barChart?.[value],
      );
    }

    return [...BUSINESS_DRIVERS_DATE_RANGES, ALL_TIME_RANGE];
  }, [brandData, viewType]);

  useEffect(() => {
    const dateRangesLength = dateRanges.length;
    const isDateRangesInitialized = !!dateRangesLength;
    if (isDateRangesInitialized) setCurrentDateRange(INITIAL_DATE_RANGE);
  }, [dateRanges]);
  // <---- Init Date Range

  // Download name ---->
  const currentBrandName = useMemo(() => brandsName?.[brandIndex] || "", [
    brandIndex,
    brandsName,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          brandSelector={{
            className: styles.brandSelector,
            brands: brandsName,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => setBrandIndex(brandIndex),
          }}
          timePeriodSelector={{
            periods: dateRanges,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "barChart" ? "widgetWrapperBar" : ""],
            )}
          >
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                viewElement
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            reviewDataButtonProps={{ type: "business drivers" }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} />
      </div>
    </div>
  );
};
