export const SYNC_STATUS = [
  "INITIAL",
  "NEW",
  "INPROGRESS",
  "pauseINPROGRESS",
  "POPULATE_KEYWORDS",
  "pausePOPULATE_KEYWORDS",
  "TRANSLATE_BRAND_NAMES",
  "START_CALC_WIDGETS",
  "CALC_WIDGETS",
  "PENDING_REVIEW",
  "FINISHED",
  "CANCELED",
] as const;
