import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";

import { usePreviousState } from "../../../../hooks";
import { getFilteredDataWithTimePlotter } from "../helpers";
import { ChartItem, ChartTimePlotter } from "../types";

type UseFilterLineChartData = {
  data: ChartItem[];
  timePlotters: ChartTimePlotter[];
  timePlotterId?: string;
};

type UseFilterLineChartDataResult = {
  filteredData: ChartItem[];
  selectedTimePlotter?: ChartTimePlotter;
  setSelectedTimePlotterId: Dispatch<SetStateAction<string>>;
};

export const useFilterLineChartData = ({
  data,
  timePlotters,
  timePlotterId = "",
}: UseFilterLineChartData): UseFilterLineChartDataResult => {
  const previousData = usePreviousState(data);

  const [selectedTimePlotterId, setSelectedTimePlotterId] = useState<string>(
    timePlotterId,
  );

  const selectedTimePlotter = useMemo<ChartTimePlotter | undefined>(() => {
    if (!selectedTimePlotterId) return;

    return timePlotters.find(({ id }) => id === selectedTimePlotterId);
  }, [selectedTimePlotterId, timePlotters]);

  const filteredData = useMemo<ChartItem[]>(
    () => getFilteredDataWithTimePlotter(data, selectedTimePlotter),
    [data, selectedTimePlotter],
  );

  useEffect(() => {
    if (previousData && !isEqual(data, previousData))
      setSelectedTimePlotterId("");
  }, [data, previousData, setSelectedTimePlotterId]);

  return {
    filteredData,
    selectedTimePlotter,
    setSelectedTimePlotterId,
  };
};
