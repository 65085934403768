import firebase from "firebase/app";
import "firebase/firestore";
import actionTypes from "../actionTypes";

// MUTATIONS
export const setWidget = <T extends keyof Store.Widgets>(
  widgetName: T,
  widgetData: Store.Widgets[T],
  searchId: string,
) => {
  return {
    type: actionTypes.SET_WIDGET,
    widgetName,
    widgetData,
    searchId,
  };
};

// ACTIONS

export const hideSentimentDriversWord = (
  searchId: string,
  brandName: string,
  type: "positive" | "negative" | "neutral",
  wordData: SentimentDrivers.Value,
) => {
  return async (dispatch: Function, getState: Function) => {
    const db = firebase.firestore();

    const { widgets } = getState() as Store.State;

    const sentimentDrivers = widgets[searchId]?.["result-sentiment-drivers"];

    if (!sentimentDrivers) return;
    const hiddenWords = sentimentDrivers.hiddenWords?.[brandName]?.[type] || [];

    await db
      .collection("result-sentiment-drivers")
      .doc(searchId)
      .update({
        [`hiddenWords.${brandName}.${type}`]: [...hiddenWords, wordData],
      });

    dispatch(
      setWidget(
        "result-sentiment-drivers",
        {
          ...sentimentDrivers,
          hiddenWords: {
            ...sentimentDrivers.hiddenWords,
            [brandName]: {
              ...(sentimentDrivers.hiddenWords?.[brandName] || {}),
              [type]: [
                ...(sentimentDrivers.hiddenWords?.[brandName]?.[type] || []),
                wordData,
              ],
            },
          },
        },
        searchId,
      ),
    );
  };
};

export const revealSentimentDriversWord = (
  searchId: string,
  brandName: string,
  type: "positive" | "negative" | "neutral",
  wordData: SentimentDrivers.Value,
) => {
  return async (dispatch: Function, getState: Function) => {
    const db = firebase.firestore();

    const { widgets } = getState() as Store.State;

    const sentimentDrivers = widgets[searchId]?.["result-sentiment-drivers"];

    if (!sentimentDrivers) return;
    const hiddenWords = sentimentDrivers.hiddenWords?.[brandName]?.[type] || [];

    const filteredHiddenWords = hiddenWords.filter(
      ({ word }) => word.toLowerCase() !== wordData.word.toLowerCase(),
    );

    await db
      .collection("result-sentiment-drivers")
      .doc(searchId)
      .update({
        [`hiddenWords.${brandName}.${type}`]: filteredHiddenWords,
      });

    dispatch(
      setWidget(
        "result-sentiment-drivers",
        {
          ...sentimentDrivers,
          hiddenWords: {
            ...sentimentDrivers.hiddenWords,
            [brandName]: {
              ...(sentimentDrivers.hiddenWords?.[brandName] || {}),
              [type]: filteredHiddenWords,
            },
          },
        },
        searchId,
      ),
    );
  };
};
