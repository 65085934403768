import { useMemo } from "react";

import classes from "./ReputationDistributionBarChartView.module.scss";
import { BarChart } from "../../../components";
import reputationParameters from "../../../data/reputationParameters";
import { LIGHT_BLUE } from "../../../data/colors";
import DATE_RANGES from "../../../data/dateRanges";

interface Props {
  data: ReputationDistribution.BarChart.Data;
  currentDateRange?: typeof DATE_RANGES[number]["value"];
}

const ReputationDistributionBarChart = ({ data, currentDateRange }: Props) => {
  const rangeData = useMemo(() => currentDateRange && data[currentDateRange], [
    data,
    currentDateRange,
  ]);

  const modifiedData = useMemo(
    () =>
      reputationParameters.slice(1).map(({ name }) => ({
        label: name,
        value: rangeData?.values[name] || 0,
      })),
    [rangeData],
  );

  return (
    <div className={classes.reputationDistributionBarChart}>
      <BarChart
        className={classes.barChart}
        data={modifiedData}
        color={{
          border: "#8e8e63",
          background: LIGHT_BLUE,
          label: "#8e8e63",
        }}
      />
    </div>
  );
};

export default ReputationDistributionBarChart;
