const SEARCH_ACCURACY = 3 as const;

const escapeHyphen = (str: string): string => str.replace("-", "\\-");

export const deepStringCheck = (value: string, filter: string) => {
  const pattern = filter
    .split("")
    .map((x) => `/[.*+?=^${escapeHyphen(x)}()|[\]\\]/g`)
    .join("");

  const regex = new RegExp(`${pattern}`, "gi");

  return value.match(regex);
};

export const stringFilter = (value: string, filter: string) => {
  const filterSubstring = filter.toLowerCase().substring(0, SEARCH_ACCURACY);

  const valueSubstring =
    value.substring(0, SEARCH_ACCURACY).toLowerCase() || "";

  return (
    value.toLowerCase().includes(filterSubstring) ||
    deepStringCheck(valueSubstring, filterSubstring)
  );
};
