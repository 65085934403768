import type { Dashboard } from "../../store/dashboards/dashboardsSlice";

const getLayoutSize = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const layoutSize = urlParams.get("size") as
    | keyof Dashboard["layouts"]
    | undefined;

  return layoutSize || "large";
};

export { getLayoutSize };
