import { FC, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { isNull } from "lodash";
import cx from "classnames";

import styles from "./SliderArrow.module.scss";
import { ArrowRight } from "../../../../../icons";
import context from "../../../../../context";
import { Dashboard } from "../../../../../store/dashboards/dashboardsSlice";

type Props = {
  className?: string;
  layoutSize?: keyof Dashboard["layouts"];
  type: "previous" | "next";
  slug?: string | null;
  dashboardId: string;
  searchId?: string;
  homePosition: string;
};

export const SliderArrow: FC<Props> = ({
  className = "",
  layoutSize,
  type,
  slug,
  dashboardId,
  searchId,
  homePosition,
}) => {
  const history = useHistory();
  const { isPresentation } = useContext(context);

  const { state } = history.location as any;
  const _layoutSize = state?.layoutSize || layoutSize || "";

  const page = useMemo(() => {
    const dashboardLink = "dashboards";
    const presentationLink = "presentation";
    return isPresentation ? presentationLink : dashboardLink;
  }, [isPresentation]);

  const pathName = useMemo(() => {
    if (!slug) {
      const firstPageUrl = `/${page}/${dashboardId}?size=${_layoutSize}`;
      const lastPageUrl = `/presentation-end/${dashboardId}?size=${_layoutSize}`;

      return type === "previous" ? firstPageUrl : lastPageUrl;
    }

    return `/${page}/${dashboardId}/${slug}`;
  }, [page, type, dashboardId, slug, _layoutSize]);

  if (!isPresentation && !searchId) return null;

  return (
    <Link
      className={cx(className, isNull(slug) ? styles.disabled : "")}
      to={{
        pathname: pathName,
        state: {
          searchId,
          dashboardId,
          homePosition,
          _layoutSize,
        },
      }}
    >
      <ArrowRight className={styles.arrow} />
    </Link>
  );
};
