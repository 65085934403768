import { MouseEvent, useMemo, useState, forwardRef } from "react";
import { BarChart, YAxis, XAxis, Bar } from "recharts";
import { format } from "date-fns";
import * as yup from "yup";
import Table from "rsuite/Table";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./LineChartCell.module.scss";

import { SmallLineChart } from "../../../../components/SmallLineChart/SmallLineChart";
import { numberFormatter } from "../../../../utils";

// Inner imports
import type { LineChartCellProps } from "../../types";
import {
  BAR_CHART_WIDTH,
  BAR_CHART_HEIGHT,
  BAR_CHART_FONT_SIZE,
  BAR_CHART_TEXT_ANGLE,
  BAR_CHART_BAR_FILL,
} from "../../constants";

const { Cell } = Table;

const schema = yup
  .object({
    date: yup.string().required(),
    volume: yup.number().required(),
  })
  .required();

export const LineChartCell = forwardRef<HTMLDivElement, LineChartCellProps>(
  ({ rowData, dataKey, ...props }, parentRef) => {
    const { t } = useTranslation();

    const [isBarChartShown, setBarChartShown] = useState<boolean>(false);
    const [barChartPosition, setBarChartPosition] = useState<"bottom" | "top">(
      "bottom",
    );

    const { current: parentRefElement } = parentRef as {
      current: HTMLDivElement;
    };

    const lineChartData = useMemo(() => {
      const data: Array<yup.TypeOf<typeof schema>> = [];

      const { values = [] } = rowData || {};

      for (const value of values) {
        try {
          const validatedValue = schema.validateSync(value);
          const { volume, date } = validatedValue;

          const formattedDate = format(new Date(date), "MMM yyyy");

          data.push({
            volume,
            date: formattedDate,
          });
        } catch (err) {
          console.error(err);
        }
      }

      return data;
    }, [rowData]);

    const mouseEnterHandler = (e: MouseEvent<HTMLDivElement>) => {
      try {
        const elementTarget = e.target as Element;

        const parentBottomPosition = getElementBottomPosition(parentRefElement);
        const elementBottomPosition = getElementBottomPosition(elementTarget);

        if (elementBottomPosition + BAR_CHART_HEIGHT >= parentBottomPosition) {
          setBarChartPosition("top");
        } else {
          setBarChartPosition("bottom");
        }
      } catch (err) {
        setBarChartPosition("bottom");
      } finally {
        setBarChartShown(true);
      }
    };

    function getElementBottomPosition(element: Element) {
      const { bottom } = element.getBoundingClientRect();

      return bottom;
    }

    function YAxisTicFormatter(value: number | string) {
      if (typeof value === "string") return value;

      return numberFormatter(value);
    }

    if (!lineChartData.length)
      return (
        <Cell {...props} dataKey={dataKey}>
          {t("dc_keywords_table_linechart_cell_error")}
        </Cell>
      );

    return (
      <Cell {...props} dataKey={dataKey} className={styles.cell}>
        <div className={styles.wrapper}>
          <div
            className={styles.lineChartWrapper}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={() => setBarChartShown(false)}
          >
            <SmallLineChart data={lineChartData} />
          </div>

          {isBarChartShown && (
            <div
              className={cx(styles.barChartWrapper, styles[barChartPosition])}
            >
              <BarChart
                width={BAR_CHART_WIDTH}
                height={BAR_CHART_HEIGHT}
                data={lineChartData}
              >
                <YAxis
                  fontSize={BAR_CHART_FONT_SIZE}
                  tickFormatter={YAxisTicFormatter}
                />
                <XAxis
                  dataKey="date"
                  fontSize={BAR_CHART_FONT_SIZE}
                  angle={BAR_CHART_TEXT_ANGLE}
                />
                <Bar
                  dataKey="volume"
                  fill={BAR_CHART_BAR_FILL}
                  isAnimationActive={false}
                />
              </BarChart>
            </div>
          )}
        </div>
      </Cell>
    );
  },
);
