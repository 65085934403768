import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Plugs.module.scss";
import { Preloader, Image } from "../../index";

const DataLoadingPlug: FC = () => (
  <div className={styles.loader}>
    <Preloader modifier="inner" className={styles.initialPreloader} />
  </div>
);

const LoadingFailedPlug: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <p>{t("rd_no_enough_data_to_conduct_error")}</p>
      <Image name={"birdAI2"} className={styles.image} />
    </div>
  );
};

const EmptyDataPlug: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <p>{t("rd_no_enough_data_to_conduct_error")}</p>
      <Image name={"birdAI2"} className={styles.image} />
    </div>
  );
};

const WidgetIsCalculatingPlug: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <p>{t("rd_calculating_widget_error")}</p>
      <Image name={"birdAI2"} className={styles.image} />
    </div>
  );
};

export {
  DataLoadingPlug,
  LoadingFailedPlug,
  EmptyDataPlug,
  WidgetIsCalculatingPlug,
};
