import { Dispatch, SetStateAction, useEffect, useState } from "react";

// Inner imports
import type { LastAvailableMonthData } from "../types";
import { filterBrandExcludedWords } from "../utils";

const useTopKeywordsBrandIndex = (
  lastAvailableMonthData: LastAvailableMonthData,
  topKeywords?: TopKeywords.Data,
  suggestions?: Store.Search["suggestions"],
): [number, Dispatch<SetStateAction<number>>] => {
  const [brandIndex, setBrandIndex] = useState(0);

  useEffect(() => {
    // GET INITIAL BRAND INDEX;
    let initialBrandIndex: undefined | number;

    const { brands } = topKeywords || {};

    if (!brands || !brands.length) return setBrandIndex(initialBrandIndex ?? 0);

    const { topWords, trendingWord } = lastAvailableMonthData;

    brands.forEach(({ id: brandId }, index) => {
      if (typeof initialBrandIndex === "number") return;
      const brandName = brands?.find(({ id }) => id === brandId)?.name || "";
      const brandSuggestion =
        suggestions?.[brandName]?.topKeywordsExcludedWords;

      const topWordsWithoutRemovedWords = filterBrandExcludedWords(
        topWords?.values[brandId],
        brandSuggestion,
      );

      const trendingWordWithoutRemovedWords = filterBrandExcludedWords(
        trendingWord?.values[brandId],
        brandSuggestion,
      );

      const isBrandSuits =
        !!topWordsWithoutRemovedWords.length ||
        !!trendingWordWithoutRemovedWords.length;

      if (isBrandSuits) {
        initialBrandIndex = index;
      }
    });

    if (!brandIndex) setBrandIndex(initialBrandIndex ?? 0);
  }, [brandIndex, lastAvailableMonthData, suggestions, topKeywords]);

  return [brandIndex, setBrandIndex];
};

export { useTopKeywordsBrandIndex };
