import {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./SearchBar.module.scss";
import { Input } from "../../components";
import { Dandruff, CloseInCircle, Recent } from "../../icons";
import { useOutsideClickHandler } from "../../hooks";
import { stringFilter } from "../../utils";

// Inner imports
import { List } from "./components/List";
import { SEARCH_LIMIT } from "./constants";

export const SearchBar: FC<ActionBar.SearchBar.Props> = ({
  className = "",
  placeholder,
  options,
  history,
  onDeleteItemFromHistoryHandler,
  onViewResultsHandler,
  onInputClean,
}) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  const [value, setValue] = useState<string>("");
  const [isFormInFocus, setIsFormInFocus] = useState<boolean>(false);

  useEffect(() => setValue(""), [placeholder]);

  useEffect(() => {
    if (onInputClean && !value) onInputClean();
  }, [onInputClean, value]);

  const isCanShowHistoryList = useMemo(
    () => isFormInFocus && !value.length && !!history?.length,
    [isFormInFocus, value, history],
  );

  const isCanShowResultList = useMemo(() => isFormInFocus && !!value.length, [
    isFormInFocus,
    value,
  ]);

  const filteredOptions = useMemo(() => {
    const filteredOptions = options.filter(({ label }) =>
      stringFilter(label, value),
    );

    return filteredOptions.slice(0, SEARCH_LIMIT);
  }, [options, value]);

  const openList = useCallback(() => setIsFormInFocus(true), [
    setIsFormInFocus,
  ]);

  const onListItemSelect = useCallback(({ label }: { label: string }) => {
    setValue(label);
    setIsFormInFocus(false);
  }, []);

  const viewResults = () => {
    setIsFormInFocus(false);
    value &&
      onViewResultsHandler &&
      onViewResultsHandler({ value, label: value });
  };

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const activeElement = document.activeElement as HTMLInputElement;
    if (activeElement.blur) activeElement.blur();
    setIsFormInFocus(false);
    viewResults();
  };

  useOutsideClickHandler(ref, () => setIsFormInFocus(false));

  return (
    <div className={cx(styles.searchBar, className)} ref={ref}>
      <form onClick={openList} onFocus={openList} onSubmit={onSubmitHandler}>
        <div className={styles.inputWrapper}>
          <Dandruff className={styles.dandruffIcon} />
          <Input
            placeholder={placeholder}
            value={value}
            changeHandler={setValue}
            className={styles.input}
          />
          {!!value && (
            <button
              type="button"
              className={styles.cleanButton}
              onClick={() => setValue("")}
            >
              <CloseInCircle />
            </button>
          )}
        </div>
      </form>
      {(isCanShowResultList || isCanShowHistoryList) && (
        <div className={styles.dropDownWrapper}>
          {isCanShowHistoryList && (
            <div className={styles.dropDown}>
              <List
                options={history!}
                titleText={t("sb_search_history")}
                listIcon={<Recent />}
                {...(onDeleteItemFromHistoryHandler && {
                  additionalButton: {
                    icon: <CloseInCircle />,
                    onClickHandler: (value: string) =>
                      onDeleteItemFromHistoryHandler(value),
                  },
                })}
                onSelect={onListItemSelect}
              />
            </div>
          )}
          {isCanShowResultList && (
            <div className={styles.dropDown}>
              {!!filteredOptions.length ? (
                <div className={styles.resultListWrapper}>
                  <List
                    options={filteredOptions}
                    titleText={t("sb_search_result")}
                    listIcon={<Dandruff className={styles.dandruffIcon} />}
                    onSelect={onListItemSelect}
                  />
                  <button
                    className={styles.viewResultsButton}
                    onClick={viewResults}
                  >
                    {t("sb_view_results_btn")}
                  </button>
                </div>
              ) : (
                <p className={styles.noResults}>{t("sb_no_results")}</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
