import firebase from "firebase/app";
import * as yup from "yup";

import type { WidgetData } from "./widgetsDataSlice";
import { WIDGET_IDS, WIDGET_CHARTS } from "../../widgets/widgets";
import { showDevelopmentError } from "../../utils";

const widgetDataSchema = yup
  .object({
    name: yup.string().required(),
    isAvailableForOneBrand: yup.boolean().required(),
    resultCollection: yup.string().required(),
    order: yup.number().required(),
    views: yup
      .array()
      .of(
        yup.object({
          isAvailableForOneBrand: yup.boolean().required(),
          isDefault: yup.boolean().required(),
          order: yup.number().required(),
          type: yup.string().required(),
          subType: yup
            .string()
            .notRequired()
            .nullable()
            .transform((value) => value ?? ""),
        }),
      )
      .required(),
    dataSources: yup.array().of(yup.string()).required(),
    preReleaseLabel: yup.string(),
    placeHolderOnUi: yup.string().default(""),
    isActive: yup.boolean().default(false),
    isImmediatelyAvailable: yup.boolean().default(false),
  })
  .noUnknown();

const widgetDataViewsSchema = yup.object({
  isAvailableForOneBrand: yup.boolean().required(),
  isDefault: yup.boolean().required(),
  order: yup.number().required(),
  type: yup
    .string()
    .oneOf([...WIDGET_CHARTS])
    .required(),
  subType: yup.string().notRequired(),
});

export async function readWidgetsData(): Promise<WidgetData[]> {
  const collection = await firebase.firestore().collection("widgets").get();

  const data: WidgetData[] = [];

  if (collection.empty) {
    throw Error("Widget data collection empty");
  }

  collection.forEach((doc) => {
    try {
      const { id: widgetId } = doc;

      const parsedData = parseWidgetData(doc.data());

      const errors = validate(widgetId, parsedData);

      if (!!errors.length) {
        errors.forEach((err) => {
          showDevelopmentError({
            additionalTexts: [err],
          });
        });
      }

      if (parsedData.isActive && !errors.length) {
        data.push({ id: widgetId as WIDGET_IDS_TYPES, ...parsedData });
      }
    } catch (error) {
      showDevelopmentError({
        additionalTexts: [
          `Widget parsing error. ${doc.id} has missed some property`,
        ],
        error,
      });
    }
  });

  if (!data.length) {
    throw Error("None of the widgets passed validation");
  }

  return data;
}

function parseWidgetData(
  data: firebase.firestore.DocumentData,
): Omit<WidgetData, "id"> {
  const validatedData = widgetDataSchema.validateSync(data);

  const {
    name,
    isAvailableForOneBrand,
    resultCollection,
    views,
    dataSources,
    placeHolderOnUi,
    isActive,
    isImmediatelyAvailable,
    preReleaseLabel,
    order,
  } = validatedData;

  const validatedViews: WidgetData["views"] = [];
  const isKeyWordDepended = dataSources.includes("keywordtool");

  views.forEach((view) => {
    try {
      const validatedView = widgetDataViewsSchema.validateSync(
        view,
      ) as WidgetData["views"][number];

      validatedViews.push(validatedView);
    } catch (error) {
      showDevelopmentError({
        additionalTexts: [
          `Error in ${name} widget`,
          `${view.type} doesn't included in WIDGET_CHARTS union`,
        ],
        error,
      });
    }
  }, []);

  return {
    name,
    isAvailableForOneBrand,
    resultCollection: resultCollection as WIDGET_IDS_TYPES,
    views: validatedViews,
    placeHolderOnUi,
    isKeyWordDepended,
    isActive,
    isImmediatelyAvailable,
    preReleaseLabel,
    order,
  };
}

function validate(widgetId: string, widgetData: Omit<WidgetData, "id">) {
  const errors: string[] = [];

  const isWidgetIdSuits = checkIdWidgetIdSuits(widgetId);
  const isWidgetResultCollectionSuits = checkIdWidgetIdSuits(
    widgetData.resultCollection,
  );

  if (
    !isWidgetIdSuits &&
    !isWidgetResultCollectionSuits &&
    widgetData.isActive
  ) {
    errors.push(`"${widgetId}" not included in general WIDGET_IDS union`);
  }

  return errors;
}

function checkIdWidgetIdSuits(widgetId: string) {
  return WIDGET_IDS.includes(widgetId as WIDGET_IDS_TYPES);
}
