import { TFunction } from "i18next";

import type { GeneralDataType } from "./types";
import { MAX_NUMBER_QUANTITY_AFTER_DOT } from "./constants";

const formatFunnelNumbers = (number: number): string => {
  return Number.isInteger(number)
    ? number.toFixed()
    : number.toFixed(MAX_NUMBER_QUANTITY_AFTER_DOT);
};

const calcShapesSize = (data: GeneralDataType[]) => {
  return data.reduce((acc, item, index, array) => {
    const prevItemValue = acc[index - 1];
    const { percentage: itemValue } = item.value;
    const { percentage: nextItemValue } = array[index + 1]?.value || {};

    if (typeof prevItemValue === "undefined") {
      // Seems it's first element of array (INTEREST);

      try {
        const nextValueInPercentage = nextItemValue! / 100;
        const decimalValue = formatFunnelNumbers(
          itemValue * nextValueInPercentage,
        );
        const value = Number(decimalValue);
        acc.push(isNaN(value) ? 0 : value);
      } catch (err) {
        acc.push(0);
        showExtendedErrorMessage("Error in first element (INTEREST) >>>", err);
      }
    } else {
      try {
        const nextValueInPercentage = nextItemValue! / 100;
        const decimalValue = formatFunnelNumbers(
          prevItemValue * nextValueInPercentage,
        );
        const value = Number(decimalValue);
        acc.push(isNaN(value) ? 0 : value);
      } catch (err) {
        acc.push(0);
        showExtendedErrorMessage(
          "Error in second or third element (CONSIDERATION / PREFERENCE) >>>",
          err,
        );
      }
    }

    function showExtendedErrorMessage(sign: string, err: any) {
      console.group("Error in shapesHeightCalculation");
      console.error(sign);
      console.error(err);
      console.groupEnd();
    }

    return acc;
  }, [] as number[]);
};

const calcShapeBorderSize = ({
  value,
  shapeHeight,
  blockSize,
  prevValue,
}: {
  value: number;
  shapeHeight: number;
  blockSize: number;
  prevValue?: number;
}): string => {
  if (typeof prevValue === "undefined") {
    // Seems it's first element of array (INTEREST);
    return formatFunnelNumbers(
      ((value - shapeHeight) / 100 / 2 / 2) * blockSize,
    );
  }

  return formatFunnelNumbers(
    ((prevValue - shapeHeight) / 100 / 2 / 2) * blockSize,
  );
};

const getLabel = (label: string, t: TFunction): string => {
  switch (label) {
    case "interest":
      return t("w_funnel_interest_label");
    case "consideration":
      return t("w_funnel_consideration_label");
    case "preference":
      return t("w_funnel_preference_label");
    case "purchase intent":
      return t("w_funnel_purchase_intent_label");
    default:
      return label;
  }
};

export { formatFunnelNumbers, getLabel, calcShapesSize, calcShapeBorderSize };
