import { createDraftSafeSelector } from "@reduxjs/toolkit";

export const selectIndustries = createDraftSafeSelector(
  ({ industries }: RootState) => industries,
  (industries) => industries,
);

export const selectIndustriesEntities = createDraftSafeSelector(
  selectIndustries,
  (industries) => industries.entities,
);
