import firebase from "firebase";

import { showDevelopmentError } from "src/utils";
import { keywordToolSettingsSchema, KeywordToolSettingsType } from "./schema";

export const getKeywordToolSettings = async (): Promise<Store.KeywordToolSettings> => {
  const result = await firebase
    .firestore()
    .collection("app")
    .doc("keywordtool-settings")
    .get();

  try {
    const validatedResult = keywordToolSettingsSchema.validateSync(
      result.data(),
    );

    return formatKeywordToolSettings(validatedResult);
  } catch (error) {
    showDevelopmentError({
      additionalTexts: ["KEYWORD TOOL SETTINGS ERROR"],
      error,
    });

    return {
      updatedAt: "",
    };
  }
};

function formatKeywordToolSettings({
  _updatedAt,
  ...rest
}: KeywordToolSettingsType): Store.KeywordToolSettings {
  return { ...rest, updatedAt: _updatedAt };
}
