import { useState, useEffect, useMemo, useRef, FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./SentimentDrivers.module.scss";
import { capitalizeString } from "../../../utils";
import {
  AboutThis,
  WidgetButtonsBar,
  SentimentDriversTagCloud,
  SentimentDriversListView,
  Preloader,
  WidgetFooterButtons,
} from "../../../components";
import { useNameForDownloadFiles, useWindowWidth } from "../../../hooks";

// Inner imports
import { WidgetChart } from "../../types";
import {
  prepareDataForTagCloud,
  calculateBrandDataByPeriod,
  getBiggestBrandDataItems,
  getInitialEndDate,
  getInitialStartDate,
} from "./utils";
import { WIDGET_ID, MAX_MOBILE_WIDTH_FOR_CHART } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";
import { useBrandIndex } from "./hooks";

export const SentimentDrivers: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const windowWidth = useWindowWidth();

  const widgetRef = useRef<HTMLDivElement>(null);

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
  } = useResizeObserver<HTMLDivElement>();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const [brandIndex, setBrandIndex] = useBrandIndex(widgetData);

  const isDesktopView: boolean = useMemo(
    () => windowWidth >= MAX_MOBILE_WIDTH_FOR_CHART,
    [windowWidth],
  );

  const calculateChartWrapperWidth: string = useMemo(
    () => (isDesktopView ? "" : `${chartWrapperWidth}px`),
    [chartWrapperWidth, isDesktopView],
  );

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // Month picker data
  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");

  const brands = useMemo(() => {
    return widgetData ? widgetData.brands : [];
  }, [widgetData]);

  const brandsName = useMemo(() => brands.map((brand) => brand.name), [brands]);

  const currentBrandId = useMemo(() => {
    return brands?.[brandIndex]?.id || "";
  }, [brandIndex, brands]);

  const currentBrandName = useMemo(() => {
    return brands?.[brandIndex]?.name || "";
  }, [brandIndex, brands]);

  const maxAndMinDateForDatePicker = useMemo(() => {
    const allPossibleDates = widgetData?.chartsData?.[currentBrandId]?.map(
      ({ date }) => date,
    );

    const gotMaxAndMinDates = allPossibleDates?.reduce(
      (acc, date) => {
        const { minDate, maxDate } = acc;
        const formattedDate = new Date(date).getTime();
        const formattedMaxDate = maxDate ? new Date(maxDate).getTime() : 0;
        const formattedMinDate = minDate ? new Date(minDate).getTime() : 0;

        if (formattedDate) {
          if (!formattedMinDate || formattedDate < formattedMinDate) {
            acc.minDate = date;
          }
          if (!formattedMaxDate || formattedDate > formattedMaxDate) {
            acc.maxDate = date;
          }
        }
        return acc;
      },
      {} as {
        minDate?: string;
        maxDate?: string;
      },
    );

    return gotMaxAndMinDates || {};
  }, [currentBrandId, widgetData?.chartsData]);

  const { maxDate, minDate } = maxAndMinDateForDatePicker;

  const hiddenWords = widgetData?.hiddenWords?.[currentBrandId];

  const chosenBrandData = widgetData?.chartsData[currentBrandId];

  useEffect(() => {
    if (startDate) return;

    const initialStartDate = getInitialStartDate(chosenBrandData);
    initialStartDate && setStartDate(initialStartDate);
  }, [startDate, chosenBrandData]);

  useEffect(() => {
    if (endDate !== "") return;

    const initialEndDate = getInitialEndDate(chosenBrandData);
    initialEndDate && setEndDate(initialEndDate);
  }, [endDate, chosenBrandData]);

  const chosenBrandExcludedData = useMemo(() => {
    const { positive, negative, neutral } =
      widgetData?.hiddenWords?.[currentBrandId] || {};

    return {
      positive: positive?.map(({ word }) => word) || [],
      negative: negative?.map(({ word }) => word) || [],
      neutral: neutral?.map(({ word }) => word) || [],
    };
  }, [currentBrandId, widgetData?.hiddenWords]);

  const chosenBrandDataByPeriod = useMemo(
    () =>
      calculateBrandDataByPeriod({
        brandData: chosenBrandData,
        dateRange: {
          startDate,
          endDate,
        },
      }),
    [chosenBrandData, endDate, startDate],
  );

  const chosenBiggestBrandDataItems = useMemo(
    () => getBiggestBrandDataItems(chosenBrandDataByPeriod),
    [chosenBrandDataByPeriod],
  );

  // Widget views ---->
  const positiveNegativeAndNeutralCharts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (!chosenBiggestBrandDataItems) return charts;

    const { positive, neutral, negative } = chosenBiggestBrandDataItems;

    const allData = {
      positive,
      neutral,
      negative,
    };

    Object.entries(allData).forEach(([key, data]) => {
      if (!data?.length) return;

      const tags = prepareDataForTagCloud(data);
      const subType = key as "positive" | "neutral" | "negative";

      charts.push({
        type: "sentimentDrivers",
        subType,
        chart: (
          <SentimentDriversTagCloud
            ref={widgetRef}
            className={styles.sentimentDriversTagCloud}
            tags={tags}
            excludedTags={chosenBrandExcludedData[subType]}
            type={subType}
            shouldShowRelatedWords
            viewType="page"
          />
        ),
      });
    });

    return charts;
  }, [chosenBiggestBrandDataItems, chosenBrandExcludedData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (positiveNegativeAndNeutralCharts.length) {
      charts.push({
        type: "sentimentDrivers",
        subType: "all",
        chart: (
          <>{positiveNegativeAndNeutralCharts.map(({ chart }) => chart)}</>
        ),
      });
    }

    positiveNegativeAndNeutralCharts.forEach((chart) => charts.push(chart));

    if (positiveNegativeAndNeutralCharts.length && chosenBrandDataByPeriod) {
      charts.push({
        type: "table",
        chart: (
          <SentimentDriversListView
            searchId={searchId}
            brandName={currentBrandId}
            data={chosenBrandDataByPeriod}
            hiddenWords={hiddenWords}
          />
        ),
      });
    }

    return charts;
  }, [
    chosenBrandDataByPeriod,
    currentBrandId,
    hiddenWords,
    positiveNegativeAndNeutralCharts,
    searchId,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const {
    id: viewId,
    type: viewType,
    subType: viewSubType,
    chart: viewElement,
  } = selectedView || {};
  // <---- Widget views

  const onDateSelected = (date: [Date | null, Date | null]) => {
    const [startDate, endDate] = date;
    const formattedStartDate = startDate && format(startDate, "yyyy-MM");
    const formattedEndDate = endDate && format(endDate, "yyyy-MM");

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const isSingleView =
    viewType === "sentimentDrivers" &&
    (viewSubType === "positive" ||
      viewSubType === "neutral" ||
      viewSubType === "negative");

  const isListView = viewType === "table";

  const chosenThreeBiggestMention = useMemo(() => {
    if (!isSingleView) return [];
    const type = viewSubType as "positive" | "negative" | "neutral";

    return (
      chosenBiggestBrandDataItems?.[type]
        ?.slice(0, 3)
        ?.map(({ word }) => word) || []
    );
  }, [chosenBiggestBrandDataItems, isSingleView, viewSubType]);

  function brandChangeHandler(brandIndex: number) {
    setBrandIndex(brandIndex);
  }

  const conclusion = useMemo(() => {
    if (!chosenThreeBiggestMention.length) return "";
    return t("w_sd_small_tip", {
      currentBrandName: capitalizeString(currentBrandName),
      biggestTopic: chosenThreeBiggestMention[0] || "",
      secondBiggestTopic: chosenThreeBiggestMention[1] || "",
      thirdBiggestTopic: chosenThreeBiggestMention[2] || "",
    });
  }, [chosenThreeBiggestMention, currentBrandName, t]);

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  return (
    <div className={styles.widget} ref={chartWrapperRef}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          monthRangePicker={
            maxDate && minDate
              ? {
                  className: styles.datePickerInput,
                  calendarClassName: styles.datePicker,
                  maxDate: new Date(maxDate),
                  minDate: new Date(minDate),
                  monthRangePicker: {
                    startDate: startDate ? new Date(startDate) : null,
                    endDate: endDate ? new Date(endDate) : null,
                  },
                  onDateSelected: (date) =>
                    onDateSelected(date as [Date | null, Date | null]),
                }
              : undefined
          }
          brandSelector={{
            className: styles.brandSelector,
            brands: brandsName,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => brandChangeHandler(brandIndex),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[isListView ? "widgetWrapperListView" : ""],
            )}
            style={{ width: calculateChartWrapperWidth }}
          >
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                viewElement ?? (
                  <div className={styles.error}>
                    {t("w_sd_trending_keywords_no_data_for_period_error")}
                  </div>
                )
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            reviewDataButtonProps={{ type: "sentiment drivers" }}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
