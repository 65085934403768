import { GRAY, LIGHT_BLUE, RED, BLUE, VIOLET, CYAN, GOLD } from "./colors";

export default [
  {
    name: "Index",
    description: "",
    color: GRAY,
    key: "index",
  },
  {
    name: "Governance",
    description: "dr_governance_label_text",
    color: RED,
    key: "governance",
  },
  {
    name: "Citizenship",
    description: "dr_citizenship_label_text",
    color: LIGHT_BLUE,
    key: "citizenship",
  },
  {
    name: "Innovation",
    description: "dr_innovation_label_text",
    color: BLUE,
    key: "innovation",
  },
  {
    name: "Performance",
    description: "dr_performance_label_text",
    color: VIOLET,
    key: "performance",
  },
  {
    name: "Workplace",
    description: "dr_workplace_label_text",
    color: CYAN,
    key: "workplace",
  },
  {
    name: "Products",
    description: "dr_products_label_text",
    color: GOLD,
    key: "products",
  },
];
