import { useContext, useMemo } from "react";
import { PieChart } from "react-minimal-pie-chart";
import clamp from "lodash/clamp";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./ReputationIndexPieChart.module.scss";
import context from "../../../../../context";
import reputationParameters from "../../../../../data/reputationParameters";

interface Props {
  className?: string;
  data: ReputationIndex.PieChart.Data;
}

export const ReputationIndexPieChart = ({ className = "", data }: Props) => {
  const { isDarkTheme } = useContext(context);

  const modifiedData = useMemo(() => {
    return reputationParameters.map(({ name, color }) => {
      const fixedValue = +data.values[name]?.toFixed()! || 0;

      return {
        label: name,
        data: [
          {
            value: fixedValue,
            color: fixedValue > 0 ? color : "transparent",
          },
          {
            value: 100 - clamp(fixedValue, 0, 100),
            color: isDarkTheme ? "#313b5c" : "#f7f7f7",
          },
        ],
        color,
      };
    });
  }, [data, isDarkTheme]);

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const chartsStyle = useMemo(() => {
    if ((width - 48) / (height - 24) >= 4) {
      const x = height - 15;
      return {
        height: height,
        width: x * 4 + 48,
        gridTemplate: `
            "pie0 pie1 pie2 pie3 pie4 pie5 pie6" auto / 1fr 1fr 1fr 1fr 1fr 1fr 1fr
        `,
      };
    }

    if ((width - 32) / (height - 64) >= 3 / 2) {
      const x = (height - 64) / 2;
      return {
        height: "initial",
        width: x * 3 + 32,
        gridTemplate: `
            "pie0 pie1 pie2 pie3" max-content
            "pie0 pie4 pie5 pie6" max-content / 1fr 1fr 1fr 1fr
        `,
        fontSize: x / 7.5 > 16 ? "16px" : `${x / 7.5}px`,
      };
    }

    if ((height - 144) / width >= 4) {
      const x = width;
      return {
        height: x * 4 + 144,
        width: x,
        gridTemplate: `
          "pie0" 1fr
          "pie1" 1fr
          "pie2" 1fr
          "pie3" 1fr / auto
        `,
      };
    }

    if ((height - 104) / (width - 16) >= 3 / 2) {
      const x = (width - 16) / 2;
      return {
        height: x * 3 + 108,
        width: width / 2,
        gridTemplate: `
            "pie0 pie0" 1fr
            "pie1 pie2" 1fr
            "pie3 pie4" 1fr
            "pie5 pie6" 1fr / 1fr 1fr
        `,
        fontSize: x / 9 > 16 ? "16px" : `${x / 9}px`,
      };
    }

    let x;

    if (width - 16 > height - 64) {
      x = (height - 64) / 2;
    } else {
      x = (width - 16) / 2;
    }

    return {
      height: "100%",
      width: x * 2 - 16,
      gridTemplate: `
        "pie0 pie1 pie2" 1fr
        "pie3 pie4 pie5" 1fr
        "blank1 pie6 blank2" 1fr / 1fr 1fr 1fr
        `,
      fontSize: x / 9 > 16 ? "16px" : `${x / 9}px`,
    };
  }, [width, height]);

  return (
    <div ref={ref} className={cx(styles.reputationIndexPieChart, className)}>
      <div className={styles.charts} style={chartsStyle}>
        {modifiedData.map(({ label, data, color }, i) => (
          <div
            className={styles.chartArea}
            style={{ gridArea: "pie" + i }}
            key={i}
          >
            <div className={styles.chartWrapperOuter}>
              <div className={styles.chartWrapperInner}>
                <PieChart
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  data={data}
                  lineWidth={20}
                  label={({ dataEntry, dataIndex }) =>
                    dataIndex === 0 ? dataEntry.value : ""
                  }
                  labelPosition={0}
                  labelStyle={{
                    fill: color,
                    fontSize: 20,
                  }}
                  startAngle={-90}
                />
              </div>
              <div className={styles.label}>{label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
