import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import * as api from "./subscriptionPlansApi";
import { RawDraftContentState } from "draft-js";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  // error: string | null;
};

export type SubscriptionPlan = {
  id: string;
  name: string;
  prices: {
    gbp: number;
    sek: number;
  };
  pricesBilledAnually: {
    gbp: number;
    sek: number;
  };
  searchesLimit: number;
  dashboardsLimit: number;
  marketsLimit: number;
  additionalNamesLimit: number;
  competitorLimit: number;
  company: {
    membersLimit: number;
    adminsLimit: number;
  };
  availableWidgets: { [widgetId: string]: boolean };
  visibleBlocks: number[];
  textFields: {
    [id: string]: RawDraftContentState;
  };
  mainDataSource?: DataSource;
  hideUnavailableWidgets?: boolean;
  socialgistSourcesMap?: {
    [source: string]: boolean;
  };
  updateFrequencyInWeeks: number;
};

const subscriptionPlansAdapter = createEntityAdapter<SubscriptionPlan>({
  sortComparer: (a, b) => a.prices.gbp - b.prices.gbp,
});

const initialState = subscriptionPlansAdapter.getInitialState<InitialState>({
  status: "idle",
  // error: null,
});

export const fetchSubscriptionPlanById = createAsyncThunk<
  SubscriptionPlan,
  string
>("subscriptionPlans/fetchById", async (subscriptionPlanId) => {
  return await api.getSubscriptionPlan(subscriptionPlanId);
});

export const fetchAllSubscriptionPlans = createAsyncThunk<SubscriptionPlan[]>(
  "subscriptionPlans/fetchAll",
  async () => {
    return await api.getSubscriptionPlans();
  },
);

export const requestSubPlanUpgrade = createAsyncThunk<
  void,
  SubscriptionPlan["id"],
  { state: RootState }
>("subscriptionPlans/requestUpgrade", async (subPlanId, { getState }) => {
  const { user } = getState();

  return api.sendUpgradeSubPlanEmail(user.id, subPlanId);
});

const subscriptionPlansSlice = createSlice({
  name: "subscriptionPlans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSubscriptionPlanById.fulfilled,
      subscriptionPlansAdapter.upsertOne,
    );
    builder.addCase(fetchAllSubscriptionPlans.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllSubscriptionPlans.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.error = null;
      subscriptionPlansAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(fetchAllSubscriptionPlans.rejected, (state) => {
      state.status = "failed";
      // state.error = "Can't fetch subscription plans";
    });
  },
});

export default subscriptionPlansSlice.reducer;
