import { Fragment, useCallback, useMemo, useState } from "react";
import cx from "classnames";

import styles from "../../Table.module.scss";
import * as icons from "../../../../icons";
import { TableItemToggler } from "../index";
import { MenuDropdown } from "../../../Dropdowns/MenuDropdown/MenuDropdown";
import { TableColumn, TableItemGenericProps } from "../../types";
import { Table } from "../../Table";

// Inner imports
import { TableRowsProps } from "./types";

const TableRow = <T extends TableItemGenericProps>({
  item,
  tableColumns,
  isSubTable,
  getActionMenuOptions,
  forwardedRef,
}: TableRowsProps<T>) => {
  const [isRowCollapsed, setRowCollapsed] = useState<boolean>(false);

  const hasSubTable: boolean = useMemo(
    () => !!item.subTableProps && !!item.subTableProps?.items?.length,
    [item.subTableProps],
  );

  const toggleIsRowCollapsed = useCallback(
    (): void => setRowCollapsed((state) => !state),
    [],
  );

  const renderTableCell = useCallback(
    (tableColumn: TableColumn<T>, index: number) => {
      const {
        key,
        dataKey,
        getComponent,
        onClick,
        icon,
        checkIsClickable,
      } = tableColumn;

      const isFirstColumn = index === 0;

      const valueKey = (dataKey || key) as keyof T;
      const value = item[valueKey] || "";

      const tooltipLabel = typeof value === "string" ? value : "";

      const Icon = icon ? icons[icon] : null;

      const isClickable =
        onClick && (checkIsClickable ? checkIsClickable(item) : true);

      switch (true) {
        case isClickable && !!value:
          return (
            <button
              className={styles.clickable}
              onClick={() => onClick && onClick(item)}
              title={tooltipLabel}
            >
              {value}
            </button>
          );
        case !isClickable && !!value && !getComponent:
          return (
            <span title={tooltipLabel}>
              {isFirstColumn && hasSubTable && (
                <TableItemToggler
                  isCollapsed={isRowCollapsed}
                  toggleCollapse={toggleIsRowCollapsed}
                />
              )}
              {Icon && <Icon />}
              <span>{value}</span>
            </span>
          );
        case !!getComponent:
          return getComponent?.(item);
        default:
          return null;
      }
    },
    [hasSubTable, isRowCollapsed, item, toggleIsRowCollapsed],
  );

  return (
    <Fragment key={item.id}>
      <tr className={isSubTable ? styles.subTableRow : ""}>
        {tableColumns.map((tableColumn, index) => {
          const { key, isAction, isNumeric } = tableColumn;

          const isLastColumn = index === tableColumns.length - 1;

          const cellActionClassName = isAction ? styles.cellAction : "";
          const cellNumericClassName = isNumeric ? styles.cellNumeric : "";

          return (
            <td
              key={key}
              className={cx(cellActionClassName, cellNumericClassName)}
            >
              {renderTableCell(tableColumn, index)}
              {isLastColumn &&
                !!getActionMenuOptions &&
                !!getActionMenuOptions(item)?.length && (
                  <MenuDropdown
                    options={getActionMenuOptions(item).map((option) => ({
                      ...option,
                      onClicked: option.onClick,
                    }))}
                    className={styles.actionsMenuDropdown}
                    ref={forwardedRef}
                  />
                )}
            </td>
          );
        })}
      </tr>
      {!!item.subTableProps && isRowCollapsed && (
        <Table {...item.subTableProps} isSubTable />
      )}
    </Fragment>
  );
};

export default TableRow;
