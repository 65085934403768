const DATE_RANGES = [
  "oneMonth",
  "twoMonths",
  "threeMonths",
  "sixMonths",
  "oneYear",
] as const;

const WIDGET_ID = "result-sentiment-index";

const PIE_CHART_DATA_BRANDS_LIMIT = 5 as const;

const FULL_DATA_IN_PERCENTAGE = 100 as const;

export {
  WIDGET_ID,
  DATE_RANGES,
  PIE_CHART_DATA_BRANDS_LIMIT,
  FULL_DATA_IN_PERCENTAGE,
};
