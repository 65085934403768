import { MouseEventHandler } from "react";
import { toast } from "react-toastify";
import isFunction from "lodash/isFunction";

import { getMessageIcon } from "../../utils/getMessageIcon";

const CloseButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button type="button" onClick={onClick}>
    {text}
  </button>
);

type Props = {
  id?: string;
  type: "success" | "warning" | "error" | "info";
  text: string | JSX.Element;
  closeButton?: {
    text: string;
    // If custom onClick is absent, the parent's onClick (close notification) is triggered
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };
  autoClose?: false | number;
};

export const showToastNotification = ({
  id,
  type,
  text,
  closeButton,
  autoClose = 5000,
}: Props) => {
  const CustomCloseButton = closeButton ? (
    <CloseButton text={closeButton.text} onClick={closeButton.onClick} />
  ) : undefined;

  return isFunction(toast[type])
    ? toast[type](text, {
        toastId: id,
        icon: getMessageIcon(type),
        closeButton: CustomCloseButton,
        autoClose,
      })
    : null;
};
