const WIDGET_ID = "result-robustness-score";

const scoreChartCellQuantitySizes = {
  bigDesktopScreen: {
    breakpoint: 1200,
    elementsQuantity: 4,
  },
  tabletsScreen: {
    breakpoint: 768,
    elementsQuantity: 3,
  },
  mobileScreen: {
    breakpoint: 320,
    elementsQuantity: 2,
  },
} as const;

export { WIDGET_ID, scoreChartCellQuantitySizes };
