import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import user from "./user/reducer";
import company from "./company/reducer";
import searches from "./searches/reducer";
import widgets from "./widgets/reducer";
import subscriptionPlans from "./subscriptionPlans/subscriptionPlansSlice";
import faqs from "./faqs/faqsSlice";
import markets from "./markets/marketsSlice";
import industries from "./industries/industriesSlice";
import dashboards from "./dashboards/dashboardsSlice";
import folders from "./folders/foldersSlice";
import syncs from "./syncs/syncsSlice";
import widgetsSettings from "./widgetsSettings/reducer";
import widgetsState from "./widgetsState/reducer";
import timePlots from "./timePlots/reducer";
import dashboardsCustomData from "./dashboardsCustomData/reducer";
import widgetsDataSlice from "./widgetsData/widgetsDataSlice";
// import sosFunnelFilters from "./funnelReviewData/reducer";

const appReducer = combineReducers({
  user,
  company,
  searches,
  widgets,
  industries,
  markets,
  subscriptionPlans,
  faqs,
  dashboards,
  folders,
  syncs,
  widgetsSettings,
  widgetsState,
  timePlots,
  dashboardsCustomData,
  widgetsData: widgetsDataSlice,
  // sosFunnelFilters,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/loggedOut") {
    state = {} as RootState;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
