import { TFunction } from "react-i18next";
import compareAsc from "date-fns/compareAsc";
import isSameMonth from "date-fns/isSameMonth";

import {
  calculateTrendLine,
  checkStringIsDateRange,
  formatToMonthYearDate,
  getDifferenceInMilliseconds,
  getDifferencePercentage,
} from "src/utils";
import { DATE_RANGE_LABELS } from "src/data/dateRanges";

// Inner imports
import { SHARE_OF_VOLUME_CHANGE_DATA_POINTS_MIN_COUNT } from "./constants";

export const getColorClass = (value: number): string => {
  switch (true) {
    case value === 0:
      return "neutral";
    case value > 0:
      return "positive";
    case value < 0:
      return "negative";
    default:
      return "neutral";
  }
};

export const formatDateByPeriod = (
  data: ShareOfSearch.Data,
  dateRange: DateRange,
) => {
  const { lineChart = [], pieChart = {}, brands = [] } = data;

  const monthCount = Math.max(
    getMonthCountFromDateRange(dateRange),
    SHARE_OF_VOLUME_CHANGE_DATA_POINTS_MIN_COUNT,
  );

  const dataForDateRange = lineChart
    .slice(-monthCount)
    .sort((a, b) => compareAsc(Date.parse(a.date), Date.parse(b.date)));

  const [startValues, endValues] = [
    dataForDateRange[0],
    dataForDateRange[dataForDateRange.length - 1] || dataForDateRange[0],
  ];

  return brands.map(({ name }) => {
    const [startVolume, endVolume] = [
      startValues?.additionalValues?.[name] || 0,
      endValues?.additionalValues?.[name] || 0,
    ];

    const { value: searchPercentage = 0, additionalValue: searchVolume = 0 } =
      pieChart[dateRange]?.values?.find((item) => item?.brandName === name) ||
      {};

    const [
      volumeChangePercentage,
      { valueChangePercentage: trendChangePercentage },
    ] = [
      getDifferencePercentage(startVolume, endVolume, 2),
      getTrendChange(dataForDateRange, name),
    ];

    return {
      brand: name,
      change: volumeChangePercentage,
      trendChange: trendChangePercentage,
      searchPercentage,
      searchVolume,
    };
  });
};

export const extractShareOfVolumeData = (
  data: ShareOfSearch.LineChart.Data,
): ShareOfSearch.LineChart.Item[] =>
  data.reduce<ShareOfSearch.LineChart.Item[]>(
    (acc, { date, additionalValues }) => {
      if (additionalValues) acc.push({ date, values: additionalValues });

      return acc;
    },
    [],
  );

export const getDateRangeOptions = (
  data: ShareOfSearch.Data,
  t: TFunction,
): Option<DateRange>[] => {
  const dateRangeOptions: Option<DateRange>[] = [];

  const pieChartData = data.pieChart;

  if (!pieChartData) return dateRangeOptions;

  for (const dateRange in pieChartData) {
    if (!checkStringIsDateRange(dateRange)) continue;

    const dateRangeData = pieChartData[dateRange];

    if (!dateRangeData) continue;

    const { startDate, endDate } = dateRangeData;

    let formattedLabel: string;

    const isSameMonthDate = isSameMonth(new Date(startDate), new Date(endDate));

    if (isSameMonthDate)
      formattedLabel = t("drl_selector_single_date_label", {
        dateRange: t(DATE_RANGE_LABELS[dateRange]),
        date: formatToMonthYearDate(endDate),
      });
    else
      formattedLabel = t("drl_selector_label", {
        dateRange: t(DATE_RANGE_LABELS[dateRange]),
        startDate: formatToMonthYearDate(startDate),
        endDate: formatToMonthYearDate(endDate),
      });

    dateRangeOptions.push({
      value: dateRange,
      label: formattedLabel,
    });
  }

  return sortDateRangeOptions(dateRangeOptions, data);
};

function getMonthCountFromDateRange(dateRange: DateRange): number {
  let monthCount: number;

  switch (dateRange) {
    case "oneMonth":
      monthCount = 1;
      break;
    case "twoMonths":
      monthCount = 2;
      break;
    case "threeMonths":
      monthCount = 3;
      break;
    case "sixMonths":
      monthCount = 6;
      break;
    case "oneYear":
      monthCount = 12;
      break;
    default:
      monthCount = 1;
  }

  return monthCount;
}

function sortDateRangeOptions(
  dateRangeOptions: Option<DateRange>[],
  data: ShareOfSearch.Data,
): Option<DateRange>[] {
  return [...dateRangeOptions].sort((a, b) => {
    const [aDateRangeData, bDateRangeData] = [
      data.pieChart[a.value],
      data.pieChart[b.value],
    ];

    const [aDateRangeDiff, bDateRangeDiff] = [
      getDifferenceInMilliseconds(
        aDateRangeData?.startDate || 0,
        aDateRangeData?.endDate || 0,
      ),
      getDifferenceInMilliseconds(
        bDateRangeData?.startDate || 0,
        bDateRangeData?.endDate || 0,
      ),
    ];

    return bDateRangeDiff - aDateRangeDiff;
  });
}

function getTrendChange(
  data: ShareOfSearch.LineChart.Item[],
  brandName: string,
): {
  valueChange: number;
  valueChangePercentage: number;
} {
  const formattedData: { date: number; value: number }[] = [];

  for (const { date, additionalValues } of data) {
    try {
      const formattedDate = Date.parse(date);
      const value = additionalValues?.[brandName] || 0;

      formattedData.push({ date: formattedDate, value });
    } catch (error) {
      console.error(error);
    }
  }

  const trendData = calculateTrendLine(formattedData, "date", "value");

  const [trendStartValue, trendEndValue] = [
    trendData[0]?.value || 0,
    (trendData[trendData.length - 1] || trendData[0])?.value || 0,
  ];

  return {
    valueChange: Math.round(trendEndValue - trendStartValue),
    valueChangePercentage: getDifferencePercentage(
      trendStartValue,
      trendEndValue,
      2,
    ),
  };
}
