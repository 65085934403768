import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

// Inner imports
import { getIndexOfFirstBrandWithData } from "./utils";

export const useBrandIndex = (
  widgetData?: SentimentDrivers.Data,
): [number, Dispatch<SetStateAction<number>>] => {
  const [brandIndex, setBrandIndex] = useState<number>(0);

  const initialBrandIndex: number = useMemo(
    () => getIndexOfFirstBrandWithData(widgetData),
    [widgetData],
  );

  useEffect(() => {
    if (widgetData) setBrandIndex(initialBrandIndex);
  }, [initialBrandIndex, widgetData]);

  return [brandIndex, setBrandIndex];
};
