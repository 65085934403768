import { GRAY, BLUE, RED, GOLD } from "./colors";

export default [
  {
    name: "Index",
    description: "",
    color: GRAY,
    key: "Index",
  },
  {
    name: "Governance",
    description: "esg_governance_label_text",
    color: BLUE,
    key: "Governance",
  },
  {
    name: "Social",
    description: "esg_social_label_text",
    color: GOLD,
    key: "Social",
  },
  {
    name: "Environmental",
    description: "esg_environmental_label_text",
    color: RED,
    key: "Environmental",
  },
];
