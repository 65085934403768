import { FC, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./Attitude.module.scss";
import {
  LineChart,
  WidgetButtonsBar,
  Preloader,
  ErrorPlaceholder,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { getAttitudeDescriptionInsertion } from "../../../utils";
import { useNameForDownloadFiles } from "../../../hooks";
import DATE_RANGES from "../../../data/dateRanges";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";
import { NUMBER_MEASUREMENT_PERCENTAGE } from "../../constants";

// Inner imports
import { LABELS, WIDGET_ID } from "./constants";
import { AttitudeLineChartWithLabels, AttitudePieChart } from "./components";
import { extractAttitudeBrandData } from "./utils";

type DATE_RANGES_TYPE = typeof DATE_RANGES[number];

export const Attitude: FC<WidgetsView.Page> = ({ searchId, dashboardId }) => {
  const { t } = useTranslation();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const {
    lineChartData,
    pieChartData,
    brands,
    mainBrandId,
    mainBrandName,
  } = useMemo(() => {
    const { lineChart = [], pieChart = {}, brands = [] } = widgetData || {};
    const { id: mainBrandId = "", name: mainBrandName = "" } = brands[0] || {};

    return {
      lineChartData: extractAttitudeBrandData(lineChart, mainBrandId),
      pieChartData: pieChart,
      brands,
      mainBrandId,
      mainBrandName,
    };
  }, [widgetData]);

  const dateRanges = useMemo(
    () => DATE_RANGES.filter(({ value }) => pieChartData[value]),
    [pieChartData],
  );

  const [currentDateRange, setCurrentDateRange] = useState<
    DATE_RANGES_TYPE["value"]
  >(() => {
    if (dateRanges.some(({ value }) => value === "oneYear")) {
      return "oneYear";
    }

    return dateRanges[0]?.value || "oneMonth";
  });

  // Widget views ---->
  const isFirstBrandDataEmpty = useMemo(() => {
    if (!mainBrandId || !widgetData) return;
    let isDataEmpty: boolean = false;
    const firstBrandData = pieChartData.oneYear?.values?.[mainBrandId];
    if (firstBrandData) {
      isDataEmpty = !Object.values(firstBrandData).filter(
        (item: number) => item > 0,
      ).length;
    }
    return isDataEmpty;
  }, [mainBrandId, pieChartData.oneYear?.values, widgetData]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (pieChartData[currentDateRange]?.values) {
      charts.push({
        type: "pieChart",
        chart: (
          <AttitudePieChart
            brands={brands}
            data={pieChartData[currentDateRange]?.values}
            type="page"
          />
        ),
      });
    }

    if (lineChartData.length) {
      charts.push({
        type: "lineChart",
        subType: "oneBrand",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={LABELS}
            data={lineChartData}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              graphHeight: "100%",
              lineStrokeWidth: 4,
            }}
            axis={{
              yAxisLegend: t("w_attitude_linechart_legend"),
              yAxisUnit: NUMBER_MEASUREMENT_PERCENTAGE,
              yAxisVerticalPadding: { top: 20, bottom: 0 },
            }}
          />
        ),
      });

      charts.push({
        type: "lineChart",
        chart: (
          <AttitudeLineChartWithLabels
            className={styles.lineChart}
            searchId={searchId}
            attitude={widgetData}
            dashboardId={dashboardId}
            type="widgetPage"
          />
        ),
      });
    }

    return charts;
  }, [
    pieChartData,
    currentDateRange,
    lineChartData,
    brands,
    dashboardId,
    searchId,
    t,
    widgetData,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Description ---->
  const descriptionInsertion = getAttitudeDescriptionInsertion(
    widgetData?.pieChart?.[currentDateRange!]?.values[widgetData.brands[0]!.id],
  );

  const conclusion = useMemo(() => {
    if (isFirstBrandDataEmpty || !descriptionInsertion) return "";
    return t("w_attitude_main_tip", {
      mainBrandName,
      descriptionInsertion,
    });
  }, [descriptionInsertion, mainBrandName, isFirstBrandDataEmpty, t]);
  // <----- Description

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType !== "pieChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} conclusion={conclusion} />
      </div>
    </div>
  );
};
