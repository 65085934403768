import { useState } from "react";
import styles from "./ReputationSentimentLineChart.module.scss";
import { LineChart } from "../../../../../components";
import reputationParameters from "../../../../../data/reputationParameters";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const filters = ["positive", "negative", "neutral"] as const;
interface Props {
  data: ReputationSentiment.LineChart.Data;
  searchId: string;
  dashboardId?: string;
}

export const ReputationSentimentLineChart = ({
  data,
  searchId,
  dashboardId,
}: Props) => {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState<typeof filters[number]>(
    filters[0],
  );

  const lineChartData = useMemo(() => {
    return data?.map(({ date, values }) => ({
      date,
      values: Object.fromEntries(
        Object.entries(values).map(([label, value]) => [
          label,
          value[currentFilter],
        ]),
      ),
    }));
  }, [data, currentFilter]);

  const getFilterName = (filter: typeof filters[number]) => {
    switch (filter) {
      case "positive":
        return t("w_drs_positive_label");
      case "negative":
        return t("w_drs_negative_label");
      case "neutral":
        return t("w_drs_neutral_label");
      default:
        return filter;
    }
  };

  return (
    <div className={styles.reputationSentimentLineChart}>
      <div className={styles.filters}>
        {filters.map((filter, i) => (
          <div onClick={() => setCurrentFilter(filter)} key={i}>
            <span style={{ opacity: filter === currentFilter ? 1 : 0.5 }}>
              {getFilterName(filter)}
            </span>
          </div>
        ))}
      </div>
      <LineChart
        className={styles.lineChart}
        labels={reputationParameters.map(({ name, color }) => ({
          id: name,
          name,
          color,
        }))}
        data={lineChartData}
        searchId={searchId}
        dashboardId={dashboardId}
        chartStyles={{
          graphHeight: 438,
          lineStrokeWidth: 4,
        }}
        axis={{
          yAxisVerticalPadding: { top: 15, bottom: 0 },
        }}
      />
    </div>
  );
};
