import { useState, useEffect, useMemo } from "react";
import styles from "./TopTrendKeywordsReviewData.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReviewDataHeader } from "../ReviewDataHeader";
import { CheckboxSection } from "../../../components";
import {
  getTopKeywordsWidget,
  writeTypeOfShownKeywords,
  writeTopWordsExcludedWords,
  recalculateTopKeywordsWidget,
  subscribeOnWidgetCalculationStatus,
  setWidgetStatus,
} from "../../../store/api";
import {
  setWidget,
  updateSearchExcludedTopWords,
  updateBrandUseAllKeywords,
} from "../../../store/actions";
import {
  sortArrayByAlphabet,
  compareTwoElements,
  sendDataToGTM,
} from "../../../utils";
import { useWidgetCalculatingStatus } from "../../../hooks/useWidgetCalculatingStatus";
import { getPlug } from "../utils";
import { showToastNotification } from "../../ToastNotification/utils";

// Inner imports
import type {
  TopTrendKeywordsReviewDataProps,
  StatusType,
  ExcludedWordsType,
  CheckBoxValueType,
  CheckBoxOptionsType,
} from "./types";
import {
  WIDGET_ID,
  CUSTOM_TITLE,
  ABOUT_THIS,
  CHECKBOX_OPTIONS,
  REVIEW_DATA_CATEGORY,
} from "./constants";
import { getFilteredExcludedBrandWords, formatData } from "./utils";
import { WordsWrapper, ChangeSearchWarningModal, Message } from "./components";

export const TopTrendKeywordsReviewData = ({
  initialSearch,
  openedFromSideBar = false,
}: TopTrendKeywordsReviewDataProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const searches = useSelector(({ searches }: Store.State) => searches);

  const [currentSearchId, setCurrentSearchId] = useState(initialSearch.id);

  const TITLE = useSelector(
    ({ widgetsData }: RootState) =>
      CUSTOM_TITLE || widgetsData.entities[WIDGET_ID]?.placeHolderOnUi || "",
  );

  const [
    isCalculatingButtonPressed,
    setIsCalculatingButtonPressed,
  ] = useState<boolean>(false);

  const [isWidgetCalculating] = useWidgetCalculatingStatus(currentSearchId, [
    WIDGET_ID,
  ]);

  const topKeywordsData = useSelector(
    ({ widgets }: Store.State) => widgets[currentSearchId]?.[WIDGET_ID],
  );

  const data = useMemo(() => {
    if (!topKeywordsData) return {};
    return formatData(topKeywordsData);
  }, [topKeywordsData]);

  const [loadingStatus, setLoadingStatus] = useState<StatusType>("loading");

  const [currentBrandId, setCurrentBrandId] = useState<string>("");
  const [switchWarningIsShown, setSwitchWarningIsShown] = useState<boolean>(
    false,
  );
  const [temporarySearchId, setTemporarySearchId] = useState<string>("");
  const [wordsFilter, setWordsFilter] = useState<string>("");

  const [
    typeOfShownKeywords,
    setTypeOfShownKeywords,
  ] = useState<CheckBoxOptionsType>({});

  const [
    initialTypeOfShownKeywords,
    setInitialTypeOfShownKeywords,
  ] = useState<CheckBoxOptionsType>({});

  const brands = useMemo(
    () =>
      searches.find(({ id }) => id === currentSearchId)?.brandsTableNames || [],
    [searches, currentSearchId],
  );

  // Excluded words state
  const [excludedWordsFilter, setExcludedWordsFilter] = useState<string>("");
  const [excludedWords, setExcludedWords] = useState<ExcludedWordsType>({});
  const [
    initialExcludedWords,
    setInitialExcludedWords,
  ] = useState<ExcludedWordsType>({});
  const [
    excludedWordsNotIncludeInWidget,
    setExcludedWordsNotIncludeInWidget,
  ] = useState<ExcludedWordsType>({});

  const currentSearch = useMemo(() => {
    return searches.find((el) => el.id === currentSearchId);
  }, [searches, currentSearchId]);

  const brandWords = useMemo(() => data[currentBrandId], [
    currentBrandId,
    data,
  ]);

  const allExcludedBrandWords = useMemo(() => {
    if (!currentBrandId) return [];
    return excludedWords[currentBrandId] || [];
  }, [currentBrandId, excludedWords]);

  const excludedBrandWords = useMemo(() => {
    const filteredBrandExcludedWords = getFilteredExcludedBrandWords(
      allExcludedBrandWords,
      excludedWordsFilter,
    );

    const sortedExcludedBrandWords = sortArrayByAlphabet(
      filteredBrandExcludedWords,
    );

    const uniqueExcludedBrandWords = [...new Set(sortedExcludedBrandWords)];

    return uniqueExcludedBrandWords;
  }, [allExcludedBrandWords, excludedWordsFilter]);

  const notExcludedBrandWord = useMemo(() => {
    const wordsWithoutExcludedWords =
      brandWords?.filter(
        (word) =>
          !allExcludedBrandWords.some(
            (excludedWord) => excludedWord.toLowerCase() === word.toLowerCase(),
          ),
      ) || [];

    const filteredWords = wordsWithoutExcludedWords.filter((word) =>
      word.toLowerCase().includes(wordsFilter.toLowerCase()),
    );
    return sortArrayByAlphabet(filteredWords);
  }, [brandWords, allExcludedBrandWords, wordsFilter]);

  const wordsNotImpact = useMemo(() => {
    return (
      excludedWordsNotIncludeInWidget[currentBrandId]?.filter((wordNotImpact) =>
        wordNotImpact.toLowerCase().includes(wordsFilter.toLowerCase()),
      ) || []
    );
  }, [excludedWordsNotIncludeInWidget, currentBrandId, wordsFilter]);

  const isExcludedWordsNotIncludeInWidgetReturnedBack = useMemo(() => {
    if (!currentBrandId) return false;
    return !!excludedWordsNotIncludeInWidget[currentBrandId]?.length;
  }, [currentBrandId, excludedWordsNotIncludeInWidget]);

  const isDataHaveNotChanged = useMemo(() => {
    function prepareExcludedWordsToComparing(data: ExcludedWordsType) {
      const excludedWordsCopy = Object.entries({ ...data }).map(
        ([brandName, words]) => {
          const sortedWords = sortArrayByAlphabet([...words]);
          return [brandName, sortedWords];
        },
      );

      return Object.fromEntries(excludedWordsCopy);
    }

    const _excludedWords = prepareExcludedWordsToComparing(excludedWords);
    const _initialExcludedWords = prepareExcludedWordsToComparing(
      initialExcludedWords,
    );

    return compareTwoElements(_excludedWords, _initialExcludedWords);
  }, [excludedWords, initialExcludedWords]);

  const brandActiveCheckBox = useMemo(() => {
    if (!currentBrandId) return;

    return typeOfShownKeywords[currentBrandId];
  }, [typeOfShownKeywords, currentBrandId]);

  useEffect(() => {
    if (brandWords || !currentSearchId) return setLoadingStatus("succeeded");

    (async () => {
      setLoadingStatus("loading");

      try {
        const topKeywords = await getTopKeywordsWidget(currentSearchId, brands);

        dispatch(setWidget(WIDGET_ID, topKeywords, currentSearchId));

        setLoadingStatus("succeeded");
      } catch (err) {
        showToastNotification({ type: "error", text: err });
        setLoadingStatus("failed");
        console.error(err);
      }
    })();
  }, [brands, brandWords, currentSearchId, dispatch]);

  useEffect(() => {
    if (!topKeywordsData || !currentSearch) return;

    const { brands } = topKeywordsData;

    const formattedExcludedWords = brands.reduce((acc, brand) => {
      const { name, id } = brand;
      const brandExcludedWords =
        currentSearch.suggestions[name]?.topKeywordsExcludedWords || [];

      return { ...acc, [id]: brandExcludedWords };
    }, {} as ExcludedWordsType);

    setExcludedWords(formattedExcludedWords);
    setInitialExcludedWords(formattedExcludedWords);
  }, [topKeywordsData, currentSearch]);

  useEffect(() => {
    if (!topKeywordsData || !currentSearch) return;

    const { brands } = topKeywordsData;

    const options = brands.reduce((acc, brand) => {
      const { id: brandId, name: brandName } = brand;

      const { useAllKeywords } = currentSearch.suggestions[brandName] || {};

      acc[brandId] = Boolean(useAllKeywords) ? "all" : "only brand";

      return acc;
    }, {} as CheckBoxOptionsType);

    setTypeOfShownKeywords(options);
    setInitialTypeOfShownKeywords(options);
  }, [topKeywordsData, currentSearch]);

  const isShouldShowCheckboxMessage = useMemo(() => {
    if (!currentBrandId) return false;

    if (
      initialTypeOfShownKeywords[currentBrandId] !==
      typeOfShownKeywords[currentBrandId]
    ) {
      return true;
    }

    return false;
  }, [typeOfShownKeywords, initialTypeOfShownKeywords, currentBrandId]);

  const currentBrandWords = useMemo(() => data[currentBrandId] || [], [
    currentBrandId,
    data,
  ]);

  const isBrandDataEmpty = useMemo(() => {
    if (loadingStatus === "loading" || loadingStatus === "failed") {
      return false;
    }

    if (!currentBrandWords?.length && !excludedBrandWords?.length) {
      return true;
    }

    return false;
  }, [loadingStatus, currentBrandWords, excludedBrandWords]);

  function addWordFromExcludedList(word?: string) {
    if (!word || !currentBrandId) return;
    setExcludedWords((state) => ({
      ...state,
      [currentBrandId]: [...allExcludedBrandWords, word],
    }));
    word &&
      removeWordToExcludedWordsNotIncludeInWidgetList(word, currentBrandId);
  }

  function removeWordFromExcludedList(word?: string) {
    if (!word || !currentBrandId) return;

    const filteredExcludedWords =
      allExcludedBrandWords.filter(
        (excludedWord) => excludedWord.toLowerCase() !== word?.toLowerCase(),
      ) || [];
    setExcludedWords((state) => ({
      ...state,
      [currentBrandId]: filteredExcludedWords,
    }));

    word && setWordToExcludedWordsNotIncludeInWidgetList(word, currentBrandId);
  }

  function setWordToExcludedWordsNotIncludeInWidgetList(
    excludedWord: string,
    brandId: string,
  ) {
    if (!currentBrandId) return;

    const isWordIncludeInWidget = currentBrandWords?.some(
      (word) => word.toLowerCase() === excludedWord.toLocaleLowerCase(),
    );

    !isWordIncludeInWidget &&
      setExcludedWordsNotIncludeInWidget((state) => ({
        ...state,
        [brandId]: [...(state[brandId] || []), excludedWord],
      }));
  }

  function removeWordToExcludedWordsNotIncludeInWidgetList(
    word: string,
    currentBrandId: string,
  ) {
    const isWordNotIncludeInWidget = excludedWordsNotIncludeInWidget[
      currentBrandId
    ]?.some(
      (excludedWord) => excludedWord.toLowerCase() === word.toLowerCase(),
    );

    if (!isWordNotIncludeInWidget) return;

    const filteredExcludedWordsNotIncludeInWidget =
      excludedWordsNotIncludeInWidget[currentBrandId]?.filter(
        (excludedWord) => excludedWord.toLowerCase() !== word.toLowerCase(),
      ) || [];

    setExcludedWordsNotIncludeInWidget((state) => ({
      ...state,
      [currentBrandId]: filteredExcludedWordsNotIncludeInWidget,
    }));
  }

  function searchChangeHandler(searchId: string, forceChange: boolean = false) {
    if (currentSearchId === searchId) return;

    if (!isDataHaveNotChanged && !forceChange) {
      setTemporarySearchId(searchId);
      return setSwitchWarningIsShown(true);
    }

    if (forceChange) {
      setSwitchWarningIsShown(false);
    }

    setLoadingStatus("loading");
    resetHandler();
    setSwitchWarningIsShown(false);
    setCurrentSearchId(searchId);
  }

  function brandChangeHandler(brand: Store.Brand) {
    setCurrentBrandId(brand.id);
  }

  function resetHandler() {
    setExcludedWords(initialExcludedWords);
    setExcludedWordsNotIncludeInWidget({});
  }

  async function writeTypeOfShownKeywordsToFirebase() {
    const payloads: {
      searchId: string;
      brandId: string;
      useAllKeywords: boolean;
    }[] = [];

    Object.keys(data).forEach((brandId) => {
      const brandName = brands.find(({ id }) => brandId === id)?.name;
      const typeOfBrandShownKeywords = typeOfShownKeywords[brandId];
      const typeOfInitialBrandShownKeywords =
        initialTypeOfShownKeywords[brandId];

      const isTypeHasChanged =
        typeOfBrandShownKeywords !== typeOfInitialBrandShownKeywords;

      if (isTypeHasChanged && brandName && typeOfBrandShownKeywords) {
        const useAllKeywords = typeOfBrandShownKeywords === "all";

        const payload = {
          searchId: currentSearchId,
          brandId,
          useAllKeywords,
        };

        dispatch(
          updateBrandUseAllKeywords(currentSearchId, brandName, useAllKeywords),
        );

        payloads.push(payload);
      }
    });

    if (!!payloads.length) {
      await Promise.all(
        payloads.map(({ searchId, brandId, useAllKeywords }) =>
          writeTypeOfShownKeywords(searchId, brandId, useAllKeywords),
        ),
      );
    }
  }

  async function writeExcludedWordsToFirebase() {
    const Promises: Promise<any>[] = [];

    Object.keys(data).forEach((brandId) => {
      const brandName = brands.find(({ id }) => brandId === id)?.name;
      const brandExcludedWords = excludedWords[brandId] || [];

      if (brandName) {
        dispatch(
          updateSearchExcludedTopWords(
            currentSearchId,
            brandName,
            brandExcludedWords,
          ),
        );
        const promise = new Promise((res, rej) => {
          try {
            return res(
              writeTopWordsExcludedWords(
                currentSearchId,
                brandId,
                brandExcludedWords,
              ),
            );
          } catch (err) {
            return rej(err);
          }
        });

        Promises.push(promise);
      }
    });

    await Promise.all(Promises);
  }

  async function onAddMoreWordsClicked() {
    setLoadingStatus("loading");

    await Promise.all([
      writeExcludedWordsToFirebase(),
      writeTypeOfShownKeywordsToFirebase(),
      setWidgetStatus(currentSearchId, [WIDGET_ID]),
    ]);

    const dataAfterRecalculating = recalculateTopKeywordsWidget(
      currentSearchId,
    );
    setIsCalculatingButtonPressed(false);
    setLoadingStatus("succeeded");

    sendDataToGTM("UserUsesReviewData", {
      reviewDataCategory: REVIEW_DATA_CATEGORY,
    });

    const stopSearchWatcher = subscribeOnWidgetCalculationStatus(
      currentSearchId,
      [WIDGET_ID],
      (isAllWidgetsFinishCalculation) => {
        if (isAllWidgetsFinishCalculation) {
          dataAfterRecalculating.then(async (gotData) => {
            dispatch(
              setWidget(
                WIDGET_ID,
                {
                  brands,
                  ...gotData,
                },
                currentSearchId,
              ),
            );
            showToastNotification({
              type: "success",
              text: t("rd_recalculation_ended_message_new"),
            });
            setExcludedWordsNotIncludeInWidget({});
            stopSearchWatcher();
          });
        }
      },
    );
  }

  async function onRecalculateClicked() {
    setLoadingStatus("loading");
    await Promise.all([
      writeExcludedWordsToFirebase(),
      writeTypeOfShownKeywordsToFirebase(),
    ]);
    sendDataToGTM("UserUsesReviewData", {
      reviewDataCategory: REVIEW_DATA_CATEGORY,
    });
    setIsCalculatingButtonPressed(true);
    setLoadingStatus("succeeded");
  }

  const Plug = useMemo(() => {
    const Plug = getPlug(loadingStatus, isBrandDataEmpty, isWidgetCalculating);

    return Plug && <Plug />;
  }, [loadingStatus, isBrandDataEmpty, isWidgetCalculating]);

  return (
    <div className={styles.topTrendKeywordsReviewData}>
      <ReviewDataHeader
        currentSearchId={currentSearchId}
        widgetNameForTitle={t(TITLE)}
        aboutThisText={t(ABOUT_THIS)}
        openedFromSideBar={openedFromSideBar}
        onSearchChangeHandler={searchChangeHandler}
        onBrandChangeHandler={brandChangeHandler}
        onResetHandler={resetHandler}
      >
        {switchWarningIsShown && (
          <ChangeSearchWarningModal
            onNoClickHandler={() => setSwitchWarningIsShown(false)}
            onYesClickHandler={() =>
              searchChangeHandler(temporarySearchId, true)
            }
          />
        )}
        {Plug ?? (
          <div className={styles.content}>
            <Message
              isDataChanged={!isDataHaveNotChanged}
              isFilterTypeChanged={isShouldShowCheckboxMessage}
              isExcludedWordsNotIncludeInWidgetReturnedBack={
                isExcludedWordsNotIncludeInWidgetReturnedBack
              }
            />
            <CheckboxSection
              options={CHECKBOX_OPTIONS}
              activeValue={brandActiveCheckBox}
              userCallBack={(item: string) =>
                setTypeOfShownKeywords((state) => ({
                  ...state,
                  [currentBrandId]: item as CheckBoxValueType,
                }))
              }
            />
            <div className={styles.wordsWrapper}>
              <div>
                <WordsWrapper
                  keyWordButtonClassName={styles.notExcludedWord}
                  filterValue={wordsFilter}
                  filterPlaceholder="Find the word"
                  filterChangeHandle={setWordsFilter}
                  words={notExcludedBrandWord}
                  wordsNotImpact={wordsNotImpact}
                  onWordsClickHandler={addWordFromExcludedList}
                />
                <button
                  className={styles.addMoreWordsButton}
                  disabled={
                    isDataHaveNotChanged &&
                    !isShouldShowCheckboxMessage &&
                    !isCalculatingButtonPressed
                  }
                  onClick={onAddMoreWordsClicked}
                >
                  {t("rd_add_more_words_button")}
                </button>
              </div>
              <div className={styles.excluded}>
                <WordsWrapper
                  keyWordButtonClassName={styles.excludedWord}
                  filterValue={excludedWordsFilter}
                  filterPlaceholder="Find the word"
                  filterChangeHandle={setExcludedWordsFilter}
                  words={excludedBrandWords}
                  onWordsClickHandler={removeWordFromExcludedList}
                />
                <button
                  className={styles.excludeButton}
                  disabled={isDataHaveNotChanged}
                  onClick={onRecalculateClicked}
                >
                  {t("rd_exclude_button")}
                </button>
              </div>
            </div>
          </div>
        )}
      </ReviewDataHeader>
    </div>
  );
};
