export const WHITE = "#efe9ea";
export const GRAY = "#707070";
export const GOLD = "#CECE84";
export const BLUE = "#2F6DFB";
export const VIOLET = "#7345F6";
export const CYAN = "#0a99bc";
export const PINK = "#ff7bac";
export const GREEN = "#41B167";
export const ORANGE = "#ECAF69";
export const RED = "#EC326A";
export const LIGHT_GREEN = "#01d0bb";

export const DARK_BLUE = "#37477C";
export const DARK_GREY = "#566573";
export const LIGHT_BLUE = "#9DB5D1";

export const DATA_ROBUSTNESS_SCORE_COLORS = [
  "#EF5E33",
  "#EFA733",
  "#EFE133",
  "#B5EF33",
  "#5FEF31",
] as const;

export const LINE_CHART_COLORS: string[] = [
  "#1F78B4",
  "#33A02C",
  "#A6CEE3",
  "#B2DF8A",
  "#FB9A99",
  "#3FE8F3",
  "#7ACFFF",
  "#3F9DF3",
  "#608EFF",
  "#3F46F3",
  "#1557D4",
  "#0D2A75",
  "#3FF3BD",
  "#00CFC1",
  "#B2DF8A",
  "#ACE209",
  "#43F33F",
  "#1DD10D",
  "#15B866",
  "#FF7FA3",
  "#F33F6A",
  "#F33FCB",
  "#DB4C96",
  "#F3EC3F",
  "#FDD162",
  "#FFB800",
  "#FA7E61",
  "#F3753F",
  "#FF5930",
  "#F33F3F",
  "#A72608",
  "#E58BFB",
  "#B93FF3",
  "#6E1FAE",
  "#4E148C",
  "#4215C5",
];

export default [
  GOLD,
  BLUE,
  VIOLET,
  CYAN,
  PINK,
  GREEN,
  ORANGE,
  RED,
  LIGHT_GREEN,
  LIGHT_BLUE,
] as const;
