import { GridItem } from "../../../store/dashboards/dashboardsSlice";
import { SEARCH_ID_LENGTH } from "../../../data/searchIdLength";

function filterBrokenWidget(
  gridItem: GridItem[],
  tiles: {
    [searchId: string]: {
      [widgetId: string]: true;
    };
  },
  widgetsState: Store.WidgetsState,
  widgetsToOmit?: string[],
) {
  try {
    const sortedGridItem = gridItem?.filter(({ i }) => {
      const widgetSearchId = i.slice(0, SEARCH_ID_LENGTH);
      const widgetId = i.replace(widgetSearchId, "");

      const isWidgetPresentAtDashboard = tiles[widgetSearchId]?.[widgetId];

      if (!isWidgetPresentAtDashboard || widgetsToOmit?.includes(widgetId))
        return false;

      const { isWidgetBroken, isCalculating } =
        widgetsState[widgetSearchId]?.[widgetId] || {};

      return !isWidgetBroken && !isCalculating;
    });

    return sortedGridItem;
  } catch (err) {
    console.error(err);
    return gridItem;
  }
}

export { filterBrokenWidget };
