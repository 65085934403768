import React, { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SentimentIndexPreview.module.scss";
import {
  Preloader,
  LineChart,
  SentimentIndexPieCharts,
  ChartButton,
  ErrorPlaceholder,
} from "../../../components";

// Inner imports
import { WIDGET_ID } from "./constants";
import { preparePieChartData } from "./utils";

import { onErrorHandler } from "../../helpers";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const SentimentIndexPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { brands, pieChart, lineChart } = widgetData || {};

    if (!brands) return charts;

    const mappedBrands = brands.map((brand) => ({
      ...brand,
      name: brand.name + " sentiment score",
    }));

    if (Object.keys(pieChart || {}).length) {
      const { values } =
        pieChart?.[
          "oneYear" || "sixMonth" || "twoMonths" || "threeMonths" || "oneMonth"
        ] || {};

      const data = values ? preparePieChartData(values, brands) : [];

      if (!!data.length) {
        charts.push({
          type: "pieChart",
          chart: <SentimentIndexPieCharts data={data} />,
        });
      }
    }

    if (!!lineChart?.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            labels={mappedBrands}
            data={lineChart?.map(
              ({ date, values }: { date: string; values: any }) => ({
                date,
                values: values || {},
              }),
            )}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisLegend: t("w_se_linechart_legend"),
              yAxisVerticalPadding: { top: 5, bottom: 0 },
            }}
          />
        ),
      });
    }

    return charts;
  }, [widgetData, t, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i: number) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
