// Inner imports
import { DEFAULT_DATE_RANGE } from "./constants";
import type { DATE_RANGES_TYPE } from "./types";

function findBiggestAvailablePeriod(
  lineChart: SearchTrend.Data["lineChart"],
): DATE_RANGES_TYPE {
  let biggestPeriod: DATE_RANGES_TYPE = DEFAULT_DATE_RANGE;

  const lineChartData = Object.entries(lineChart || {});

  for (let i = lineChartData.length; i >= 0; i--) {
    try {
      const [period, values] = lineChartData[i] || [];

      if (!!Object.keys(values || {}).length) {
        biggestPeriod = period as DATE_RANGES_TYPE;
        break;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return biggestPeriod;
}

export { findBiggestAvailablePeriod };
