import { useCallback, useMemo } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";

import styles from "./TableCheckbox.module.scss";
import { showToastNotification } from "../../../ToastNotification/utils";
import {
  CheckBoxChecked,
  CheckBoxEmpty,
  Star,
  StarEmpty,
} from "../../../../icons";

// Inner imports
import { TableCheckboxProps } from "./types";

const TableCheckbox = ({
  onClick,
  isChecked = false,
  type = "default",
}: TableCheckboxProps) => {
  const dispatch = useDispatch();

  const toggleActive = useCallback(async (): Promise<void> => {
    try {
      await dispatch(onClick());
    } catch (err) {
      console.error(err);
      showToastNotification({
        type: "error",
        text: err.message,
      });
    }
  }, [dispatch, onClick]);

  const Icon = useMemo(() => {
    switch (type) {
      case "star":
        return isChecked ? Star : StarEmpty;

      case "default":
      default:
        return isChecked ? CheckBoxChecked : CheckBoxEmpty;
    }
  }, [isChecked, type]);

  return (
    <span
      className={cx(styles.checkbox, !isChecked ? styles.disabled : "")}
      onClick={toggleActive}
    >
      <Icon className={styles.checkboxChecked} />
    </span>
  );
};

export default TableCheckbox;
