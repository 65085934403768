import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import debounce from "lodash/debounce";
import { Dashboard } from "../../icons";
import * as api from "./dashboardsApi";
import { replaceObjectValuesWith } from "../../utils";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
};

export type GridItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

export type WidgetViewSettings = {
  selectedView: WidgetsView.WidgetChartTypeAndSubType;
};

export type DashboardViewSettings = {
  [searchId: string]: {
    [widgetId in WIDGET_IDS_TYPES[number]]: WidgetViewSettings;
  };
};

export type Dashboard = {
  id: string;
  name: string;
  isPrivate: boolean;
  tiles: {
    [searchId: string]: {
      [widgetId: string]: true;
    };
  };
  layouts: {
    small: GridItem[];
    medium: GridItem[];
    large: GridItem[];
  };
  userId: string;
  createdAt: string;
  updatedAt: string;
  lastUsedSearchId: string;
  companyId?: string;
  isCopiedDashboard?: boolean;
  isPinned?: boolean;
  viewSettings?: DashboardViewSettings;
  isOwnedByUser?: boolean;
  userFullName?: string;
};

const dashboardsAdapter = createEntityAdapter<Dashboard>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = dashboardsAdapter.getInitialState<InitialState>({
  status: "idle",
  error: null,
});

const debouncedUpdateDashboardViewSettings = debounce(
  api.updateDashboardViewSettings,
  3000,
);

export const addOneDashboard = createAsyncThunk<
  Dashboard,
  Omit<Dashboard, "id" | "userId" | "createdAt" | "updatedAt">,
  { state: RootState }
>(
  "dashboards/addOne",
  async (payload, { getState }): Promise<Dashboard & { companyId: string }> => {
    const { user, company } = getState();
    const userId = user.id;
    const companyId = company.id;

    const { id, createdAt, updatedAt } = await api.createDashboard({
      ...payload,
      userId,
      companyId,
    });

    return {
      ...payload,
      id,
      userId,
      companyId,
      createdAt,
      updatedAt,
    };
  },
);

export const addExampleDashboard = createAsyncThunk<
  Dashboard,
  Omit<Dashboard, "id" | "createdAt" | "updatedAt"> & { companyId: string },
  { state: RootState }
>("dashboards/addOne", async (payload) => {
  const { id, createdAt, updatedAt } = await api.createDashboard({
    ...payload,
  });

  return {
    ...payload,
    id,
    createdAt,
    updatedAt,
  };
});

export const fetchAllDashboards = createAsyncThunk<
  Dashboard[],
  void,
  { state: RootState }
>("dashboards/fetchAll", async (_, { getState }) => {
  const { company } = getState();
  const companyId = company.id;

  return await api.readDashboards(companyId);
});

export const fetchDashboardById = createAsyncThunk<
  Dashboard[] | undefined,
  Dashboard["id"] | undefined
>("dashboards/fetchById", async (payload = "", { getState }) => {
  const { dashboards } = getState() as RootState;

  const dashboard = await api.readDashboardById(payload);

  const dashboardsEntities = Object.values(dashboards.entities || {}).filter(
    (x) => x,
  ) as Dashboard[];

  return [...dashboardsEntities, dashboard] as Dashboard[];
});

export const updateOneDashboard = createAsyncThunk<
  UpdateEntity<Dashboard>,
  UpdateEntity<Dashboard>
>("dashboards/updateOne", async (payload) => {
  await api.updateDashboard(payload);
  return payload;
});

export const updateManyDashboards = createAsyncThunk<
  UpdateEntity<Dashboard>[],
  UpdateEntity<Dashboard>[]
>("dashboards/updateMany", async (payload) => {
  await api.updateDashboards(payload);
  return payload;
});

export const removeOneDashboard = createAsyncThunk<
  Dashboard["id"],
  Dashboard["id"]
>("dashboards/removeOne", async (payload) => {
  await api.deleteDashboard(payload);
  return payload;
});

export const removeDashboardFromStore = createAsyncThunk<
  Dashboard["id"],
  Dashboard["id"]
>("dashboards/removeDashboardFromStore", (payload) => {
  return payload;
});

export const removeManyDashboards = createAsyncThunk<
  Dashboard["id"][],
  Dashboard["id"][]
>("dashboards/removeMany", async (payload) => {
  await Promise.all([payload.map((id) => api.deleteDashboard(id))]);
  return payload;
});

export const fetchDashboardViewSettingsById = createAsyncThunk<
  UpdateEntity<Dashboard>,
  Dashboard["id"]
>("dashboards/fetchViewSettingsById", async (dashboardId, { getState }) => {
  const { user } = getState() as RootState;
  const userId = user.id;

  const result = await api.readDashboardViewSettings(dashboardId, userId);

  return {
    id: dashboardId,
    changes: {
      viewSettings: result || {},
    },
  };
});

export const updateOneDashboardViewSettings = createAsyncThunk<
  UpdateEntity<Dashboard>,
  {
    searchId: string;
    widgetId: WIDGET_IDS_TYPES;
    dashboardId: string;
    changes: WidgetViewSettings;
  }
>("dashboards/updateViewSettingsById", async (payload, { getState }) => {
  const {
    searchId = "",
    widgetId = "",
    dashboardId = "",
    changes: _changes,
  } = payload;

  const { user, dashboards } = getState() as RootState;
  const userId = user.id;

  const dashboardViewSettings =
    dashboards?.entities?.[dashboardId]?.viewSettings;

  if (!dashboardViewSettings)
    return {
      id: dashboardId,
      changes: {
        viewSettings: {},
      },
    };

  const dashboardSearchViewSettings =
    dashboardViewSettings?.[searchId || ""] || {};

  const dashboardSearchWidgetViewSettings =
    dashboardSearchViewSettings?.[widgetId || ""] || {};

  const changes = {
    ...dashboardViewSettings,
    [searchId]: {
      ...dashboardSearchViewSettings,
      [widgetId]: {
        ...dashboardSearchWidgetViewSettings,
        ..._changes,
      },
    },
  };

  const updatedDashboardViewSettings = replaceObjectValuesWith(
    changes,
    undefined,
    null,
  );

  await debouncedUpdateDashboardViewSettings(
    {
      id: dashboardId,
      changes: updatedDashboardViewSettings,
    },
    userId,
  );

  return {
    id: dashboardId,
    changes: {
      viewSettings: updatedDashboardViewSettings,
    },
  };
});

const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addOneDashboard.fulfilled, dashboardsAdapter.addOne);

    builder.addCase(fetchAllDashboards.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(fetchAllDashboards.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      dashboardsAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchAllDashboards.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });

    builder.addCase(updateOneDashboard.fulfilled, dashboardsAdapter.updateOne);

    builder.addCase(
      updateManyDashboards.fulfilled,
      dashboardsAdapter.updateMany,
    );

    builder.addCase(removeOneDashboard.fulfilled, dashboardsAdapter.removeOne);

    builder.addCase(
      removeDashboardFromStore.fulfilled,
      dashboardsAdapter.removeOne,
    );

    builder.addCase(fetchDashboardById.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = null;
      const dashboard = action.payload;
      if (dashboard) {
        dashboardsAdapter.setAll(state, dashboard);
      }
    });

    builder.addCase(
      removeManyDashboards.fulfilled,
      dashboardsAdapter.removeMany,
    );

    builder.addCase(fetchDashboardViewSettingsById.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(
      fetchDashboardViewSettingsById.rejected,
      (state, action) => {
        state.status = "failed";
        state.error = action.error;
      },
    );

    builder.addCase(
      fetchDashboardViewSettingsById.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        state.error = null;
        dashboardsAdapter.updateOne(state, action);
      },
    );

    builder.addCase(
      updateOneDashboardViewSettings.fulfilled,
      dashboardsAdapter.updateOne,
    );
  },
});

export default dashboardsSlice.reducer;
