import styles from "./AttitudePieChart.module.scss";
import { WidgetPlaceholder } from "../../../../../components/index";
import { PieChart } from "react-minimal-pie-chart";
import useResizeObserver from "use-resize-observer";
import { GREEN, RED, ORANGE } from "../../../../../data/colors";
import { useMemo } from "react";
interface Props {
  className?: string;
  brands?: Store.Brands;
  data?: {
    [brandId: string]: {
      positive: number;
      negative: number;
      neutral: number;
    };
  };
  type?: "preview" | "page";
}

const EMPTY_CHART_COLOR = "grey";

type CheckingData = {
  color: string;
  label: string;
  value: number;
};

type DataCheckingArray = CheckingData[];

export const AttitudePieChart = ({
  className = "",
  brands = [],
  data = {},
  type = "preview",
}: Props) => {
  const shownBrands = brands.filter((brand) => {
    return brand.id in data;
  });

  const modifiedData = shownBrands.map((brand) => ({
    brandName: brand.name,
    data: [
      {
        label: "positive",
        value: data[brand.id]!.positive,
        color: GREEN,
      },
      {
        label: "negative",
        value: data[brand.id]!.negative,
        color: RED,
      },
      {
        label: "neutral",
        value: data[brand.id]!.neutral,
        color: ORANGE,
      },
    ],
  }));

  const isAttitudeDataEmpty = (data: DataCheckingArray): boolean => {
    const result = data?.map((item: CheckingData) => item.value > 0);
    return result.includes(true);
  };

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const size = Math.min(chartWrapperHeight, chartWrapperWidth);

  const elementQuantity = modifiedData.length;

  const chartsWrapperClassName = useMemo(() => {
    if (elementQuantity > 5) return `chartsWrapperMoreThan5`;

    if (elementQuantity > 3) return `chartsWrapperFor5`;

    if (elementQuantity > 1) return `chartsWrapperFor3`;

    return `chartsWrapperFor1`;
  }, [elementQuantity]);

  return modifiedData.length ? (
    <div
      className={`${styles.attitudePieChart} ${
        type === "page" ? styles.attitudePieChartPage : ""
      } ${className} `}
      ref={chartWrapperRef}
    >
      <div className={styles.labels}>
        {modifiedData[0]?.data.map((item, i) => (
          <div className={styles.label} key={i}>
            <div
              className={styles.labelIcon}
              style={{ backgroundColor: item.color }}
            />
            <div>{item.label}</div>
          </div>
        ))}
      </div>
      <div
        className={`${styles.chartsWrapper} ${styles[chartsWrapperClassName]}`}
        style={{
          ...(chartsWrapperClassName === "chartsWrapperMoreThan5" && {
            gridTemplateColumns: `repeat(${
              Math.ceil(elementQuantity / 2) + 1
            }, 1fr)`,
          }),
        }}
      >
        {modifiedData.map((brandData, i) => (
          <div
            className={styles.chartWrapper}
            style={{
              ...(chartsWrapperClassName !== "chartsWrapperMoreThan5"
                ? {
                    gridArea: "chart" + i,
                    width:
                      type === "preview"
                        ? !i
                          ? (size - 60) / 2
                          : (size - 60) / 6
                        : "100%",
                  }
                : {
                    width:
                      type === "preview"
                        ? !i
                          ? (size - 60) / (elementQuantity / 2.5)
                          : (size - 60) / (elementQuantity / 1.3)
                        : "100%",
                  }),
            }}
            key={i}
          >
            {isAttitudeDataEmpty(brandData.data) ? (
              brandData.data && (
                <PieChart
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  data={brandData.data}
                  label={(props) => {
                    const value = props.dataEntry.value;
                    return value ? value + "%" : "";
                  }}
                  labelStyle={{
                    fill: "#efe9ea",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                  startAngle={-90}
                />
              )
            ) : (
              <PieChart
                background={EMPTY_CHART_COLOR}
                data={brandData.data}
                label={() => "Not enough data"}
                labelStyle={{
                  fill: "#efe9ea",
                  fontSize: 10,
                  fontWeight: 600,
                }}
                labelPosition={-70}
              />
            )}
            <div className={styles.brandName} title={brandData.brandName}>
              {brandData.brandName}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <WidgetPlaceholder />
  );
};
