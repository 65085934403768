type HttpStatusType =
  | "informational response"
  | "successful"
  | "redirection"
  | "client error"
  | "server error";

const HTTP_STATUS_IDENTIFIER: Record<HttpStatusType, number> = {
  "informational response": 1,
  successful: 2,
  redirection: 3,
  "client error": 4,
  "server error": 5,
};

export const getHttpResponseStatusType = (
  statusCode: string | number,
): HttpStatusType | null => {
  const _statusCode = String(statusCode);

  for (const type in HTTP_STATUS_IDENTIFIER) {
    const _type = type as HttpStatusType;

    const regExp = getHttpResponseStatusRegExp(_type);

    if (!regExp.test(_statusCode)) continue;

    return _type;
  }

  return null;
};

function getHttpResponseStatusRegExp(status: HttpStatusType): RegExp {
  return new RegExp(`^${HTTP_STATUS_IDENTIFIER[status]}([0-9]{2})$`, "g");
}
