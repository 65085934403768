import cloneDeep from "lodash/cloneDeep";

export const replaceObjectValuesWith = <T extends {}>(
  _obj: T,
  value: unknown,
  replace: unknown,
): T => {
  const obj = cloneDeep(_obj);

  return replaceValuesWith(obj, value, replace);
};

function replaceValuesWith<T extends {}>(
  obj: T,
  value: unknown,
  replace: unknown,
): T {
  for (const prop in obj) {
    if (typeof obj[prop] === "object" && obj[prop] !== null) {
      replaceValuesWith(obj[prop], value, replace);
    } else if (obj.hasOwnProperty(prop) && obj[prop] === value) {
      obj[prop] = replace as T[typeof prop];
    }
  }

  return obj;
}
