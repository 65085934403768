import { setSearches } from "../actions";
import esgParameters from "../../data/esgParameters";
import businessDriversParameters from "../../data/businessDriversParameters";
import reputationParameters from "../../data/reputationParameters";
import DATE_RANGES from "../../data/dateRanges";
import { writeSearchExcludedSuggestions } from "../api";
import { getValueRelativePercentage } from "../../utils";
import i18next from "i18next";

// Sentiment Drivers ---->
export const updateSearchExcludedSuggestionsByName = (
  searchId: Store.Search["id"],
  brandId: Store.Brand["name"],
  brandName: Store.Brand["id"],
  excludedSuggestions: string[],
  excludedArticles: {
    id: string;
    title: string;
  }[],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    const editSearchEcxludedWords =
      searches.find((el) => el.id === searchId)?.suggestions?.[brandName]
        ?.editSearchEcxludedWords || [];

    const mergedExcludedSuggestion = () => {
      const uniqueSuggestions = new Set([
        ...excludedSuggestions,
        ...editSearchEcxludedWords,
      ]);
      return Array.from(uniqueSuggestions);
    };

    if (brandName) {
      dispatch(
        setSearches(
          searches.map((search) =>
            search.id === searchId
              ? {
                  ...search,
                  suggestions: {
                    ...search.suggestions,
                    [brandName]: {
                      ...search.suggestions[brandName],
                      excluded: mergedExcludedSuggestion(),
                      aiTrainerExcludedWords: excludedSuggestions,
                      excludedArticles,
                    },
                  },
                }
              : search,
          ),
        ),
      );
    }

    await writeSearchExcludedSuggestions(
      searchId,
      brandId,
      excludedSuggestions,
      mergedExcludedSuggestion(),
      excludedArticles,
    );
  };
};
// <---- Sentiment Drivers

// Top Trend Keywords ---->
export const updateSearchExcludedTopWords = (
  searchId: string,
  brandName: string,
  excludedWords: string[],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions[brandName],
                    topKeywordsExcludedWords: excludedWords,
                  },
                },
              }
            : search,
        ),
      ),
    );
  };
};

export const updateBrandUseAllKeywords = (
  searchId: string,
  brandName: string,
  useAllKeywords: boolean,
) => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions[brandName],
                    useAllKeywords: useAllKeywords,
                  },
                },
              }
            : search,
        ),
      ),
    );
  };
};
// <---- Top Trend Keywords

// ESG Widgets ---->
export const updateSearchESGExcludedData = (
  searchId: string,
  brandName: string,
  excludedArticles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  excludedSources: string[],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions[brandName],
                    excludedESGArticles: excludedArticles,
                    excludedESGSources: excludedSources,
                  },
                },
              }
            : search,
        ),
      ),
    );
  };
};

export const formateESGWidgetData = (brands: Store.Brands) => {
  const index = (data: any) => {
    const parsedData: Store.Widgets["result-esg-index"] = [];

    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      const parsedBrandData: Store.Widgets["result-esg-index"][number]["brandData"] = {};

      // LINECHART
      const parsedLineChartData: Store.Widgets["result-esg-index"][number]["brandData"]["lineChart"] = [];

      const lineChartData = data[id]?.lineChart;

      if (Array.isArray(lineChartData)) {
        lineChartData.forEach((item) => {
          if (isNaN(Date.parse(item?.date))) return;

          const values: { [label: string]: number } = {};

          labels.forEach(({ name, key }) => {
            const value = item?.values?.[key];

            if (!Number.isFinite(value)) return;

            values[name] = value;
          });

          if (!Object.keys(values).length) return;

          parsedLineChartData.push({
            date: item.date,
            values,
          });
        });
      }

      if (parsedLineChartData.length) {
        parsedBrandData.lineChart = parsedLineChartData.reverse();
      }

      // PIECHART
      const brandPieChartData = data[id]?.pieChart;

      const startDate = !isNaN(Date.parse(brandPieChartData?.startDate))
        ? brandPieChartData.startDate
        : undefined;
      const endDate = !isNaN(Date.parse(brandPieChartData?.endDate))
        ? brandPieChartData.endDate
        : undefined;

      const values: { [label: string]: number } = {};

      labels.forEach(({ name, key }) => {
        const value = brandPieChartData?.values?.[key];

        if (!Number.isFinite(value)) return;

        values[name] = value;
      });

      if (startDate && endDate && Object.keys(values).length) {
        parsedBrandData.pieChart = {
          startDate,
          endDate,
          values,
        };
      }

      if (Object.keys(parsedBrandData).length) {
        parsedData.push({
          brandName: name,
          brandData: parsedBrandData,
        });
      }
    });

    return parsedData;
  };

  const sentiment = (data: any) => {
    const parsedData: Store.Widgets["result-esg-sentiment"] = [];

    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      const parsedBrandData: Store.Widgets["result-esg-sentiment"][number]["brandData"] = {};

      // LINECHART
      const parsedLineChartData: Store.Widgets["result-esg-sentiment"][number]["brandData"]["lineChart"] = [];

      const lineChartData = data[id]?.lineChart;
      if (Array.isArray(lineChartData)) {
        lineChartData.forEach((item) => {
          if (isNaN(Date.parse(item?.date))) return;

          const values: {
            [label: string]: {
              positive: number;
              negative: number;
              neutral: number;
            };
          } = {};
          labels.forEach(({ name, key }) => {
            const positive = item.values?.[key]?.positive;
            const negative = item.values?.[key]?.negative;
            const neutral = item.values?.[key]?.neutral;

            if (
              Number.isFinite(positive) &&
              Number.isFinite(negative) &&
              Number.isFinite(neutral)
            ) {
              values[name] = {
                positive,
                negative,
                neutral,
              };
            }
          });

          if (!Object.keys(values).length) return;

          parsedLineChartData.push({
            date: item.date,
            values,
          });
        });

        if (parsedLineChartData.length) {
          parsedBrandData.lineChart = parsedLineChartData.reverse();
        }
      }

      // PIECHART
      const parsedPieChartData: Store.Widgets["result-esg-sentiment"][number]["brandData"]["pieChart"] = {};

      const pieChartData = data[id]?.pieChart;
      if (pieChartData) {
        DATE_RANGES.forEach(({ value: range }) => {
          const rangeData = pieChartData[range];
          if (!rangeData) return;

          const startDate = !isNaN(Date.parse(rangeData.startDate))
            ? rangeData.startDate
            : undefined;
          const endDate = !isNaN(Date.parse(rangeData.endDate))
            ? rangeData.endDate
            : undefined;

          const values: {
            [label: string]: {
              positive: number;
              negative: number;
              neutral: number;
            };
          } = {};

          labels.forEach(({ name, key }) => {
            const positive = rangeData.values?.[key]?.positive;
            const negative = rangeData.values?.[key]?.negative;
            const neutral = rangeData.values?.[key]?.neutral;

            if (
              Number.isFinite(positive) &&
              positive >= 0 &&
              Number.isFinite(negative) &&
              negative >= 0 &&
              Number.isFinite(neutral) &&
              neutral >= 0
            ) {
              values[name] = {
                positive,
                negative,
                neutral,
              };
            }
          });

          if (startDate && endDate && Object.keys(values).length) {
            parsedPieChartData[range] = {
              startDate,
              endDate,
              values,
            };
          }
        });

        if (Object.keys(parsedPieChartData).length) {
          parsedBrandData.pieChart = parsedPieChartData;
        }
      }

      if (Object.keys(parsedBrandData).length) {
        parsedData.push({
          brandName: name,
          brandData: parsedBrandData,
        });
      }
    });

    return parsedData;
  };

  const distribution = (data: any) => {
    const parsedData: Store.Widgets["result-esg-distribution"] = [];

    const labels = esgParameters.map(({ name }) => ({
      name,
      key: name.toLowerCase(),
    }));

    brands.forEach(({ id, name }) => {
      //BARCHART
      const brandData = data[id]?.barChart;

      if (brandData) {
        const parsedBarChartData: Store.Widgets["result-esg-distribution"][number]["brandData"]["barChart"] = {};

        DATE_RANGES.forEach(({ value: range }) => {
          const rangeData = brandData[range];
          if (!rangeData) return;

          const startDate = !isNaN(Date.parse(rangeData.startDate))
            ? rangeData.startDate
            : undefined;
          const endDate = !isNaN(Date.parse(rangeData.endDate))
            ? rangeData.endDate
            : undefined;

          const values: { [label: string]: number } = {};
          labels.forEach(({ name, key }) => {
            const value = rangeData?.values?.[key];

            if (!Number.isFinite(value)) return;

            values[name] = value;
          });

          if (startDate && endDate && Object.keys(values).length) {
            parsedBarChartData[range] = {
              startDate,
              endDate,
              values,
            };
          }
        });

        if (Object.keys(parsedBarChartData).length) {
          parsedData.push({
            brandName: name,
            brandData: {
              barChart: parsedBarChartData,
            },
          });
        }
      }
    });

    return parsedData;
  };

  return {
    index,
    sentiment,
    distribution,
  };
};
// <---- ESG Widgets

// Business Drivers ---->
export const updateSearchBusinessDriversExcludedData = (
  searchId: string,
  brandName: string,
  excludedArticles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  excludedSources: string[],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions[brandName],
                    excludedBusinessDriversArticles: excludedArticles,
                    excludedBusinessDriversSources: excludedSources,
                  },
                },
              }
            : search,
        ),
      ),
    );
  };
};

export const formateBusinessDriversWidgetData = (
  brands: Store.Brands,
  data: any,
) => {
  const parsedData: Store.Widgets["result-business-drivers"] = [];

  if (data) {
    for (let i = 0; i < brands.length; i++) {
      const parsedBrandData: Store.Widgets["result-business-drivers"][number]["brandData"] = {};

      // LINE CHART
      const parsedLineChartData: Store.Widgets["result-business-drivers"][number]["brandData"]["lineChart"] = [];

      const lineChartData = data[brands[i]!.id]?.lineChart;

      if (Array.isArray(lineChartData)) {
        lineChartData.forEach((item) => {
          if (isNaN(Date.parse(item?.date))) return;

          const values: { [label: string]: number } = {};

          businessDriversParameters.forEach(({ name, key }) => {
            const value = item?.values[key]?.impact;

            if (!Number.isFinite(value)) return;

            const translatedName = i18next.t(name);
            values[translatedName] = value;
          });

          parsedLineChartData.push({
            date: item.date,
            values,
          });
        });

        if (parsedLineChartData.length) {
          parsedBrandData.lineChart = parsedLineChartData.reverse();
        }
      }

      //BAR CHART && QUADRANT CHART
      const brandData = data[brands[i]!.id]?.barChart;

      if (brandData) {
        const parsedBarChartData: Store.Widgets["result-business-drivers"][number]["brandData"]["barChart"] = {};
        const parsedQuadrantChartData: Store.Widgets["result-business-drivers"][number]["brandData"]["quadrantChart"] = {};

        outer: for (const { value: range } of DATE_RANGES) {
          const rangeData = brandData[range];

          if (!rangeData) continue;
          if (isNaN(Date.parse(rangeData.startDate))) continue;
          if (isNaN(Date.parse(rangeData.endDate))) continue;

          const values: {
            label: string;
            impact: number;
            potential: number;
          }[] = [];

          for (const { name, key } of businessDriversParameters) {
            const impact = rangeData?.values[key]?.impact;
            if (!Number.isFinite(impact)) continue outer;
            const potential = rangeData?.values[key]?.impact;
            if (!Number.isFinite(potential)) continue outer;

            values.push({
              label: name,
              impact,
              potential,
            });
          }

          //bar chart
          const barChartValues: { [label: string]: number } = {};

          values.forEach(
            ({ label, impact }) => (barChartValues[label] = impact),
          );

          parsedBarChartData[range] = {
            startDate: rangeData.startDate,
            endDate: rangeData.endDate,
            values: barChartValues,
          };

          // quadrant chart
          const impactValues = values.map(({ impact }) => impact);
          const potentialValues = values.map(({ potential }) => potential);

          const quadrantChartData = values
            .sort((a, b) => b.impact + b.potential - (a.impact + a.potential))
            .slice(0, 3)
            .map(({ label, impact, potential }) => ({
              label,
              values: {
                impact: getValueRelativePercentage(impact, impactValues),
                potential: getValueRelativePercentage(
                  potential,
                  potentialValues,
                ),
              },
            }));

          parsedQuadrantChartData[range] = {
            startDate: rangeData.startDate,
            endDate: rangeData.endDate,
            data: quadrantChartData,
          };
        }

        if (Object.keys(parsedBarChartData).length) {
          parsedBrandData.barChart = parsedBarChartData;
        }

        if (Object.keys(parsedQuadrantChartData).length) {
          parsedBrandData.quadrantChart = parsedQuadrantChartData;
        }
      }

      if (Object.keys(parsedBrandData).length) {
        parsedData.push({
          brandName: brands[i]!.name,
          brandData: parsedBrandData,
          _updatedAt: data?._updatedAt,
        });
      } else {
        if (i === 0) break;
      }
    }
  }

  return parsedData;
};
// <---- Business Drivers

// Reputation Widgets ---->
export const updateSearchReputationExcludedData = (
  searchId: string,
  brandName: string,
  excludedArticles: {
    id: string;
    title: string;
    date: string;
    parameter: string;
    sentence: string;
    source: string;
    url: string;
  }[],
  excludedSources: string[],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { searches } = getState() as Store.State;

    dispatch(
      setSearches(
        searches.map((search) =>
          search.id === searchId
            ? {
                ...search,
                suggestions: {
                  ...search.suggestions,
                  [brandName]: {
                    ...search.suggestions[brandName],
                    excludedReputationArticles: excludedArticles,
                    excludedReputationSources: excludedSources,
                  },
                },
              }
            : search,
        ),
      ),
    );
  };
};

export const formateReputationWidgetData = (brands: Store.Brands) => {
  const index = (data: any) => {
    const parsedData: Store.Widgets["result-reputation-index"] = [];

    if (data) {
      const labels = reputationParameters.map(({ name }) => ({
        name,
        key: name.toLowerCase(),
      }));

      brands.forEach(({ id, name }) => {
        const parsedBrandData: Store.Widgets["result-reputation-index"][number]["brandData"] = {};

        // LINECHART
        const parsedLineChartData: Store.Widgets["result-reputation-index"][number]["brandData"]["lineChart"] = [];

        const lineChartData = data[id]?.lineChart;

        if (Array.isArray(lineChartData)) {
          lineChartData.forEach((item) => {
            if (isNaN(Date.parse(item?.date))) return;

            const values: { [label: string]: number } = {};
            labels.forEach(({ name, key }) => {
              const value = item?.values?.[key];

              if (!Number.isFinite(value)) return;

              values[name] = value;
            });

            if (!Object.keys(values).length) return;

            parsedLineChartData.push({
              date: item.date,
              values,
            });
          });
        }

        if (parsedLineChartData.length) {
          parsedBrandData.lineChart = parsedLineChartData.reverse();
        }

        // PIECHART
        const brandPieChartData = data[id]?.pieChart.oneYear;

        const startDate = !isNaN(Date.parse(brandPieChartData?.startDate))
          ? brandPieChartData.startDate
          : undefined;
        const endDate = !isNaN(Date.parse(brandPieChartData?.endDate))
          ? brandPieChartData.endDate
          : undefined;

        const values: { [label: string]: number } = {};

        labels.forEach(({ name, key }) => {
          const value = brandPieChartData?.values?.[key];

          if (!Number.isFinite(value)) return;

          values[name] = value;
        });

        if (startDate && endDate && Object.keys(values).length) {
          parsedBrandData.pieChart = {
            startDate,
            endDate,
            values,
          };
        }

        if (Object.keys(parsedBrandData).length) {
          parsedData.push({
            brandName: name,
            brandData: parsedBrandData,
            _updatedAt: data?._updatedAt,
          });
        }
      });
    }

    return parsedData;
  };

  const sentiment = (data: any) => {
    const parsedData: Store.Widgets["result-reputation-sentiment"] = [];

    if (data) {
      const labels = reputationParameters.map(({ name }) => ({
        name,
        key: name.toLowerCase(),
      }));

      brands.forEach(({ id, name }) => {
        const parsedBrandData: Store.Widgets["result-reputation-sentiment"][number]["brandData"] = {};

        // LINECHART
        const parsedLineChartData: Store.Widgets["result-reputation-sentiment"][number]["brandData"]["lineChart"] = [];

        const lineChartData = data[id]?.lineChart;
        if (Array.isArray(lineChartData)) {
          lineChartData.forEach((item) => {
            if (isNaN(Date.parse(item?.date))) return;

            const values: {
              [label: string]: {
                positive: number;
                negative: number;
                neutral: number;
              };
            } = {};
            labels.forEach(({ name, key }) => {
              const positive = item.values?.[key]?.positive;
              const negative = item.values?.[key]?.negative;
              const neutral = item.values?.[key]?.neutral;

              if (
                Number.isFinite(positive) &&
                Number.isFinite(negative) &&
                Number.isFinite(neutral)
              ) {
                values[name] = {
                  positive,
                  negative,
                  neutral,
                };
              }
            });

            if (!Object.keys(values).length) return;

            parsedLineChartData.push({
              date: item.date,
              values,
            });
          });

          if (parsedLineChartData.length) {
            parsedBrandData.lineChart = parsedLineChartData.reverse();
          }
        }

        // PIECHART
        const parsedPieChartData: Store.Widgets["result-reputation-sentiment"][number]["brandData"]["pieChart"] = {};

        const pieChartData = data[id]?.pieChart;
        if (pieChartData) {
          DATE_RANGES.forEach(({ value: range }) => {
            const rangeData = pieChartData[range];
            if (!rangeData) return;

            const startDate = !isNaN(Date.parse(rangeData.startDate))
              ? rangeData.startDate
              : undefined;
            const endDate = !isNaN(Date.parse(rangeData.endDate))
              ? rangeData.endDate
              : undefined;

            const values: {
              [label: string]: {
                positive: number;
                negative: number;
                neutral: number;
              };
            } = {};
            labels.forEach(({ name, key }) => {
              const positive = rangeData.values?.[key]?.positive;
              const negative = rangeData.values?.[key]?.negative;
              const neutral = rangeData.values?.[key]?.neutral;

              if (
                Number.isFinite(positive) &&
                positive >= 0 &&
                Number.isFinite(negative) &&
                negative >= 0 &&
                Number.isFinite(neutral) &&
                neutral >= 0
              ) {
                values[name] = {
                  positive,
                  negative,
                  neutral,
                };
              }
            });

            if (startDate && endDate && Object.keys(values).length) {
              parsedPieChartData[range] = {
                startDate,
                endDate,
                values,
              };
            }
          });

          if (Object.keys(parsedPieChartData).length) {
            parsedBrandData.pieChart = parsedPieChartData;
          }
        }

        if (Object.keys(parsedBrandData).length) {
          parsedData.push({
            brandName: name,
            brandData: parsedBrandData,
            _updatedAt: data?._updatedAt,
          });
        }
      });
    }

    return parsedData;
  };

  const distribution = (data: any) => {
    const parsedData: Store.Widgets["result-reputation-distribution"] = [];

    if (data) {
      const labels = reputationParameters.map(({ name }) => ({
        name,
        key: name.toLowerCase(),
      }));

      brands.forEach(({ id, name }) => {
        //BARCHART
        const brandData = data[id]?.barChart;

        if (brandData) {
          const parsedBarChartData: Store.Widgets["result-reputation-distribution"][number]["brandData"]["barChart"] = {};

          DATE_RANGES.forEach(({ value: range }) => {
            const rangeData = brandData[range];
            if (!rangeData) return;

            const startDate = !isNaN(Date.parse(rangeData.startDate))
              ? rangeData.startDate
              : undefined;
            const endDate = !isNaN(Date.parse(rangeData.endDate))
              ? rangeData.endDate
              : undefined;

            const values: { [label: string]: number } = {};

            labels.forEach(({ name, key }) => {
              const value = rangeData?.values?.[key];

              if (!Number.isFinite(value)) return;

              values[name] = value;
            });

            if (startDate && endDate && Object.keys(values).length) {
              parsedBarChartData[range] = {
                startDate,
                endDate,
                values,
                _updatedAt: data?._updatedAt,
              };
            }
          });

          if (Object.keys(parsedBarChartData).length) {
            parsedData.push({
              brandName: name,
              brandData: {
                barChart: parsedBarChartData,
              },
            });
          }
        }
      });
    }

    return parsedData;
  };

  return {
    index,
    sentiment,
    distribution,
  };
};
// <---- Reputation Widgets
