import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./MonthPicker.module.scss";
import { DatePickerIcon } from "../../icons";

// Inner imports
import { DATE_FORMAT, POPPER_OFFSET, INITIAL_ICON_SIZE } from "./constants";

type Props = {
  selectedDate?: Date;
  maxDate?: Date;
  minDate?: Date;
  monthRangePicker?: {
    startDate: Date | null;
    endDate: Date | null;
  };
  className?: string;
  calendarClassName?: string;
  popperPosition?: "start" | "end";
  shouldHaveDateAsText?: boolean;
  disabled?: boolean;
  onDateSelected: (date: Date | [Date | null, Date | null]) => void;
};

export const MonthPicker = ({
  selectedDate,
  maxDate,
  minDate,
  monthRangePicker,
  popperPosition = "end",
  shouldHaveDateAsText,
  onDateSelected,
  disabled = false,
}: Props) => {
  const { startDate, endDate } = monthRangePicker || {};
  const ReactDatePickerInput = React.forwardRef<
    HTMLButtonElement,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  >((props, ref) => {
    return (
      <button {...props} ref={ref}>
        <DatePickerIcon width={INITIAL_ICON_SIZE} />
      </button>
    );
  });

  const popperPlacement = `bottom-${popperPosition}` as
    | "bottom-start"
    | "bottom-end";

  if (!selectedDate && !monthRangePicker) return null;

  return (
    <div className={styles.picker}>
      <DatePicker
        className={styles.datePickerInput}
        calendarClassName={styles.datePicker}
        monthClassName={() => styles.month || ""}
        showMonthYearPicker
        dateFormat={DATE_FORMAT}
        maxDate={maxDate}
        minDate={minDate}
        customInput={shouldHaveDateAsText ? null : <ReactDatePickerInput />}
        popperModifiers={{
          offset: {
            offset: popperPosition === "start" ? -POPPER_OFFSET : POPPER_OFFSET,
          },
        }}
        popperPlacement={popperPlacement}
        {...(monthRangePicker
          ? {
              onChange: (date) =>
                onDateSelected(date as [Date | null, Date | null]),
              selectsStart: true,
              selected: endDate ? null : startDate,
              startDate,
              endDate,
              selectsRange: true,
              shouldCloseOnSelect: false,
            }
          : {
              selected: selectedDate,
              onChange: (date) => onDateSelected(date as Date),
            })}
        disabled={disabled}
      />
    </div>
  );
};
