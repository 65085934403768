import { INITIAL_MONTHS_COUNT } from "../../../widgets/components/sentimentDrivers/constants";
import {
  calculateBrandDataByPeriod,
  getBiggestBrandDataItems,
  getFirstSuitableMonth,
  getLastSuitableMonth,
} from "../../../widgets/components/sentimentDrivers/utils";
import {
  checkIfLineChartDataExist,
  checkIfPieChartDataExist,
} from "../../../widgets/components/shareOfEarnedMedia/utils";
import {
  getWidgetTopWordsData,
  getWidgetTrendingWordsData,
} from "../../../widgets/components/topKeywords/utils/getWidgetData";

const checkShareOfSearchData = (data: ShareOfSearch.Data): boolean => {
  const { lineChart, pieChart } = data || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkTopKeywordsData = (data: TopKeywords.Data): boolean => {
  const [topWords, trendingWords] = [
    getWidgetTopWordsData(data),
    getWidgetTrendingWordsData(data),
  ];

  const isTopWordsOk = !!topWords?.length;
  const isTrendingWordsOk = !!trendingWords?.length;

  return isTopWordsOk || isTrendingWordsOk;
};

const checkShareOfEarnedMediaData = (
  data: ShareOfEarnedMedia.Data,
): boolean => {
  const { lineChart, pieChart } = data || {};

  const isPieChartOk = checkIfPieChartDataExist(pieChart);
  const isLineChartOk = checkIfLineChartDataExist(lineChart);

  return isPieChartOk || isLineChartOk;
};

const checkAttitudeData = (
  data: Pick<Attitude.Data, "pieChart" | "lineChart" | "_updatedAt">,
): boolean => {
  const { pieChart, lineChart } = data || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkSentimentDriversData = (data: SentimentDrivers.Data): boolean => {
  const { brands, chartsData } = data || {};

  const mainBrand = brands[0]?.id || "";

  const mainBrandData = chartsData?.[mainBrand];

  if (!mainBrandData || !mainBrandData.length) return false;

  const [firstSuitableMonth, lastSuitableMonth] = [
    getFirstSuitableMonth(mainBrandData, INITIAL_MONTHS_COUNT),
    getLastSuitableMonth(mainBrandData),
  ];

  const brandDataByPeriod = calculateBrandDataByPeriod({
    brandData: mainBrandData,
    dateRange: {
      startDate: firstSuitableMonth?.date,
      endDate: lastSuitableMonth?.date,
    },
  });

  const biggestBrandDataItems = getBiggestBrandDataItems(brandDataByPeriod);

  if (!biggestBrandDataItems) return false;

  const { positive, neutral, negative } = biggestBrandDataItems;

  const isPositiveOk = positive && !!positive.length;
  const isNeutralOk = neutral && !!neutral.length;
  const isNegativeOk = negative && !!negative.length;

  return isPositiveOk || isNeutralOk || isNegativeOk;
};

const checkBusinessDriversData = (data: BusinessDrivers.Data): boolean => {
  const mainBrandData = data[0]?.brandData;

  if (!mainBrandData) return false;

  const { barChart, lineChart } = mainBrandData;

  const isBarChartOk = Boolean(barChart);
  const isLineChartOk = Boolean(lineChart?.length);

  return isBarChartOk || isLineChartOk;
};

const checkGeneralAttributesData = (
  data: Pick<GeneralAttributes.Data, "radarChart" | "_updatedAt">,
): boolean => {
  if (!data?.radarChart) return false;

  const initialBrand = Object.keys(data?.radarChart)[0] || "";

  const { oneYear, sixMonths, twoMonths, threeMonths, oneMonth } =
    data?.radarChart?.["all" || initialBrand] || {};

  const chartData = (
    oneYear ||
    sixMonths ||
    threeMonths ||
    twoMonths ||
    oneMonth
  )?.data;

  return Boolean(chartData);
};

const checkPositionVsCompetitionData = (
  data: Omit<PositionVsCompetition.Data, "brands">,
): boolean => {
  const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } = data || {};

  const chartData =
    oneYear || sixMonths || threeMonths || twoMonths || oneMonth;

  return Boolean(chartData);
};

const checkDataRobustnessScoreData = (data: Robustness.Data): boolean => {
  const { brands, cellChart } = data || {};

  const mainBrandId = brands?.[0]?.id || "";

  const widgetData = cellChart?.[mainBrandId];

  return Boolean(widgetData);
};

const checkAwarenessData = (data: Awareness.Data): boolean => {
  const { pieChart, lineChart } = data || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkReputationIndexData = (data: ReputationIndex.Data): boolean => {
  const mainBrandData = data[0]?.brandData;

  const { pieChart, lineChart } = mainBrandData || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkReputationSentimentData = (
  data: ReputationSentiment.Data,
): boolean => {
  const mainBrandData = data[0]?.brandData;

  const { pieChart, lineChart } = mainBrandData || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkReputationDistributionData = (
  data: ReputationDistribution.Data,
): boolean => {
  const { barChart } = data[0]?.brandData || {};

  if (!barChart) return false;

  const barChartData =
    barChart.oneYear ||
    barChart.sixMonths ||
    barChart.threeMonths ||
    barChart.twoMonths ||
    barChart.oneMonth;

  return Boolean(barChartData);
};

const checkEsgIndexData = (data: EsgIndex.Data): boolean => {
  const mainBrandData = data[0]?.brandData;

  const { pieChart, lineChart } = mainBrandData || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkEsgSentimentData = (data: EsgSentiment.Data): boolean => {
  const mainBrandData = data[0]?.brandData;

  const { pieChart, lineChart } = mainBrandData || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkEsgDistributionData = (data: EsgDistribution.Data): boolean => {
  const { barChart } = data[0]?.brandData || {};

  if (!barChart) return false;

  const barChartData =
    barChart.oneYear ||
    barChart.sixMonths ||
    barChart.threeMonths ||
    barChart.twoMonths ||
    barChart.oneMonth;

  return Boolean(barChartData);
};

const checkFunnelData = (data: Funnel.Data): boolean => {
  const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } = data || {};

  const brandValues =
    (oneYear || sixMonths || threeMonths || twoMonths || oneMonth)
      ?.brandValues ?? [];

  const isHorizontalFunnelChartOk = Boolean(brandValues?.length);
  const isVerticalFunnelChartOk = brandValues?.length > 1;

  return isHorizontalFunnelChartOk || isVerticalFunnelChartOk;
};

const checkSentimentIndexData = (
  data: Omit<Store.Widgets["result-sentiment-index"], "brands">,
): boolean => {
  const { pieChart, lineChart } = data || {};

  const isPieChartOk = Boolean(Object.keys(pieChart || {})?.length);
  const isLineChartOk = Boolean(lineChart?.length);

  return isPieChartOk || isLineChartOk;
};

const checkSearchTrendsData = (data: SearchTrend.Data): boolean => {
  const { lineChart } = data || {};

  const dataFilteredByPeriod = getDataFilteredByPeriod();

  function getDataFilteredByPeriod() {
    if (!Object.keys(lineChart || {})) return [];

    const lineChartOverall = lineChart?.["overall"];
    const lineChartOneYear = lineChart?.["oneYear"];
    const lineChartSixMonths = lineChart?.["sixMonths"];
    const lineChartThreeMonths = lineChart?.["threeMonths"];
    const lineChartTwoMonths = lineChart?.["twoMonths"];
    const lineChartOneMonth = lineChart?.["oneMonth"];

    if (lineChartOverall && lineChartOverall?.length > 2) {
      return lineChartOverall;
    }

    switch (true) {
      case lineChartOverall && lineChartOverall?.length > 2:
        return lineChartOverall;
      case lineChartOneYear && lineChartOneYear?.length > 2:
        return lineChartOneYear;
      case lineChartSixMonths && lineChartSixMonths?.length > 2:
        return lineChartSixMonths;
      case lineChartThreeMonths && lineChartThreeMonths?.length > 2:
        return lineChartThreeMonths;
      case lineChartTwoMonths && lineChartTwoMonths?.length > 2:
        return lineChartTwoMonths;
      case lineChartOneMonth && lineChartOneMonth?.length > 2:
        return lineChartOneMonth;
    }
  }

  return Boolean(dataFilteredByPeriod?.length);
};

const checkShareOfSearchFunnelData = (data: Funnel.Data): boolean => {
  const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth } = data || {};

  const brandValues =
    (oneYear || sixMonths || threeMonths || twoMonths || oneMonth)
      ?.brandValues ?? [];

  const isHorizontalFunnelChartOk = Boolean(brandValues?.length);
  const isVerticalFunnelChartOk = brandValues?.length > 1;

  return isHorizontalFunnelChartOk || isVerticalFunnelChartOk;
};

const checkSentimentTrendsData = (data: SentimentTrends.Data): boolean => {
  const { speedometerChart, lineChart } = data;
  if (lineChart) return true;

  const dataFilteredByPeriod = getDataFilteredByPeriod();

  if (!!dataFilteredByPeriod?.length) return true;

  if (speedometerChart) {
    const lastAvailablePeriod =
      speedometerChart.oneMonthAgo ||
      speedometerChart.twoMonthsAgo ||
      speedometerChart.threeMonthsAgo ||
      speedometerChart.sixMonthsAgo ||
      speedometerChart.oneYearAgo;
    if (lastAvailablePeriod) return true;
  }

  function getDataFilteredByPeriod() {
    if (!Object.keys(lineChart || {})) return [];

    const lineChartOverall = lineChart?.["overall"];
    const lineChartOneYear = lineChart?.["oneYear"];
    const lineChartSixMonths = lineChart?.["sixMonths"];
    const lineChartThreeMonths = lineChart?.["threeMonths"];
    const lineChartTwoMonths = lineChart?.["twoMonths"];
    const lineChartOneMonth = lineChart?.["oneMonth"];

    if (lineChartOverall && lineChartOverall?.length > 2) {
      return lineChartOverall;
    }

    switch (true) {
      case lineChartOverall && lineChartOverall?.length > 2:
        return lineChartOverall;
      case lineChartOneYear && lineChartOneYear?.length > 2:
        return lineChartOneYear;
      case lineChartSixMonths && lineChartSixMonths?.length > 2:
        return lineChartSixMonths;
      case lineChartThreeMonths && lineChartThreeMonths?.length > 2:
        return lineChartThreeMonths;
      case lineChartTwoMonths && lineChartTwoMonths?.length > 2:
        return lineChartTwoMonths;
      case lineChartOneMonth && lineChartOneMonth?.length > 2:
        return lineChartOneMonth;
    }
  }

  return false;
};

export {
  checkShareOfSearchData,
  checkTopKeywordsData,
  checkShareOfEarnedMediaData,
  checkAttitudeData,
  checkSentimentDriversData,
  checkBusinessDriversData,
  checkGeneralAttributesData,
  checkPositionVsCompetitionData,
  checkDataRobustnessScoreData,
  checkAwarenessData,
  checkReputationIndexData,
  checkReputationSentimentData,
  checkReputationDistributionData,
  checkEsgIndexData,
  checkEsgSentimentData,
  checkEsgDistributionData,
  checkFunnelData,
  checkSentimentIndexData,
  checkSearchTrendsData,
  checkShareOfSearchFunnelData,
  checkSentimentTrendsData,
};
