import { FC } from "react";

import styles from "./List.module.scss";

// Inner imports
import type { ListProps } from "../types";

export const List: FC<ListProps> = ({
  titleText = "",
  options,
  listIcon,
  additionalButton,
  onSelect,
}) => (
  <div className={styles.listWrapper}>
    {titleText && <p className={styles.title}>{titleText}</p>}
    <ul className={styles.list}>
      {options!.map(({ label, value, onClickHandler }) => (
        <li key={value} className={styles.listItem}>
          <button
            onClick={() => {
              if (onSelect) onSelect({ label, value });
              onClickHandler({ value, label });
            }}
            type="button"
            className={styles.mainButton}
          >
            {listIcon && listIcon}
            <span title={label}>{label}</span>
          </button>
          {additionalButton && (
            <button
              type="button"
              className={styles.additionalButton}
              onClick={() => additionalButton.onClickHandler(value)}
            >
              {additionalButton.icon}
            </button>
          )}
        </li>
      ))}
    </ul>
  </div>
);
