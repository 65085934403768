import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import context from "src/context";
import { sendDataAboutUserToGTM } from "src/utils";
import { ReturnBackModal } from "src/components";
import { ROUTS } from "src/data/routs";
import { selectCompanySubscriptionPlan } from "src/store/selectors";

const INACTIVE_SUBSCRIPTION_PLAN_ID = "9lTss1cejRszuab0CG4Q" as const;

export const useInitialRedirectLink = () => {
  const searches = useSelector(({ searches }: Store.State) => searches);

  const initialRedirectLink = useMemo(() => {
    const isFirstLogin = localStorage.getItem("isFirstLogin");

    if (isFirstLogin || !searches.length) {
      localStorage.removeItem("isFirstLogin");

      return ROUTS.createTracker;
    }

    return ROUTS.dashboardsHomePage;
  }, [searches.length]);

  return initialRedirectLink;
};

export const useSendUserDataToGTM = (isMainDataLoaded: boolean) => {
  const user = useSelector(({ user }: Store.State) => user);
  const searches = useSelector(({ searches }: Store.State) => searches);
  const company = useSelector(({ company }: Store.State) => company);
  const companySubscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  useEffect(() => {
    if (isMainDataLoaded || !companySubscriptionPlan) return;

    const payload = {
      user,
      company,
      searches,
      subscriptionPlan: companySubscriptionPlan,
    };

    sendDataAboutUserToGTM(payload);
  }, [user, company, searches, companySubscriptionPlan, isMainDataLoaded]);
};

export const useReturnBackModal = (companyId: string) => {
  const { setModalElement } = useContext(context);

  const companySubscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const companySubscriptionPlanId = useMemo(() => companySubscriptionPlan?.id, [
    companySubscriptionPlan?.id,
  ]);

  useEffect(() => {
    if (!companySubscriptionPlanId || !companyId) return;

    const inactiveBySubscriptionPlan =
      companySubscriptionPlanId === INACTIVE_SUBSCRIPTION_PLAN_ID;

    if (inactiveBySubscriptionPlan) {
      return setModalElement(
        <ReturnBackModal companyId={companyId} withSearch={true} />,
      );
    }
  }, [companySubscriptionPlanId, companyId, setModalElement]);
};
