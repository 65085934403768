import {
  getWidgetTopWordsData,
  getWidgetTrendingWordsData,
} from "src/widgets/components/topKeywords/utils/getWidgetData";

function getFilteredExcludedBrandWords(
  excludedWords: string[],
  filterWord: string,
) {
  return excludedWords.filter((word) =>
    word.toLowerCase().includes(filterWord.toLowerCase()),
  );
}

function formatData(data: TopKeywords.Data) {
  const result: Record<string, string[]> = {};

  try {
    const { brands } = data;

    const [topWords, trendingWords] = [
      getWidgetTopWordsData(data),
      getWidgetTrendingWordsData(data),
    ];

    brands.forEach(({ id: brandId }) => {
      result[brandId] = getUniqueKeyWords(topWords, trendingWords, brandId);
    });
  } catch (err) {
    console.error(err);
  }

  return result;
}

const getUniqueKeyWords = (
  topWords: TopKeywords.TopWords[],
  trendingWords: TopKeywords.TrendingWords[],
  brandId: string,
) => {
  const uniqueKeyWordsCollection: Set<string> = new Set();

  function mergeAllDataToOneArray(
    arr: TopKeywords.TopWords[] | TopKeywords.TrendingWords[],
  ) {
    type TopKeywordsBrandValues = {
      value: number;
      keyword: string;
    }[];

    if (!brandId) return [];

    arr.forEach(
      ({ values }: TopKeywords.TopWords | TopKeywords.TrendingWords) => {
        const topKeywordsBrandValues = values[brandId] as
          | TopKeywordsBrandValues
          | undefined;
        topKeywordsBrandValues?.forEach(({ keyword }) => {
          if (keyword) uniqueKeyWordsCollection.add(keyword);
        });
      },
    );
  }

  mergeAllDataToOneArray(topWords);
  mergeAllDataToOneArray(trendingWords);

  return Array.from(uniqueKeyWordsCollection);
};

export { getFilteredExcludedBrandWords, formatData };
