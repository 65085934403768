import { FC, useState, useMemo, FormEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./NameModal.module.scss";
import { withError } from "../../../hocs";
import { Input, Label } from "../../../components";
import { useTemporaryErrors } from "../../../hooks";
import { ConfirmModalWrapper } from "../../../components";

// Inner imports
import { Props } from "./types";
import context from "../../../context";

const InputWithError = withError(Input);

export const NameModal: FC<Props> = ({
  searchId,
  copySearchCheck,
  initialName,
  isHaveSameSearchError,
  onNextClicked,
}) => {
  const { t } = useTranslation();
  const { setModalElement } = useContext(context);

  const [name, setName] = useState(initialName ?? "");
  const { errors, setErrors } = useTemporaryErrors(3000);

  const searches = useSelector(({ searches }: Store.State) => searches);

  const isNameExist = useMemo(
    () => searches.some((searchItem) => searchItem.name === name),
    [name, searches],
  );

  const isSearchNameError: boolean = useMemo(() => {
    const trimmedName: string = name.trim();

    const isNewSearch: boolean = !searchId && !initialName;

    const isEditSearch: boolean = !isNewSearch && !copySearchCheck;

    if (isNewSearch) return isNameExist;

    if (isEditSearch) {
      const isEditSearchCorrect: boolean =
        initialName === trimmedName ||
        (initialName !== trimmedName && !isNameExist);
      return !isEditSearchCorrect;
    }

    return false;
  }, [copySearchCheck, initialName, isNameExist, name, searchId]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();

    const trimmedName = name.trim();

    if (!trimmedName) {
      setErrors({ name: "Required" });
      return;
    }

    if (isSearchNameError) {
      setErrors({
        name: `${t("dc_same_name_error")} ${trimmedName}`,
      });
      return;
    }

    if (isHaveSameSearchError) {
      setErrors({
        name: t("dc_same_search_error", {
          trimmedName: trimmedName,
        }),
      });
      return;
    }
    onNextClicked(trimmedName);
  };

  const resetHandler = (e: FormEvent) => {
    e.preventDefault();

    setModalElement(null);
  };

  return (
    <ConfirmModalWrapper
      type="success"
      title={t("tpsm_title")}
      className={styles.nameModal}
    >
      <form
        className={styles.form}
        onSubmit={submitHandler}
        onReset={resetHandler}
      >
        <div className={styles.inputWrapper}>
          <Label leftText={t("tpsm_label")} className={styles.label} />
          <InputWithError
            className={styles.input}
            value={name}
            placeholder=""
            changeHandler={setName}
            error={errors.name}
            maxLength={120}
          />
        </div>
        <div className={styles.buttons}>
          <button className={styles.greenButton} type="reset">
            {t("tpsm_cancel_btn")}
          </button>
          <button className={styles.greenButton} type="submit">
            {t("tpsm_save_btn")}
          </button>
        </div>
      </form>
    </ConfirmModalWrapper>
  );
};
