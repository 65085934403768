import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  hardReload,
  subscribeSearchWidgetStateChange,
} from "../../../../store/actions";
import { watchSubscriptionPlan } from "../../../../store/subscriptionPlans/subscriptionPlansApi";

export const useSubscriptionOnSearchWidgetStateChange = () => {
  const dispatch = useDispatch();
  const searches = useSelector(({ searches }: Store.State) => searches);

  useEffect(() => {
    if (!searches.length) return;

    const subscriptions = searches.map(({ id: searchId }) => {
      return dispatch(subscribeSearchWidgetStateChange(searchId));
    });

    return () => {
      subscriptions.forEach((subscription) => dispatch(subscription));
    };
  }, [searches, dispatch]);
};

export const useSubscriptionOnHardReload = () => {
  const userId = useSelector(({ user }: Store.State) => user.id);

  useEffect(() => {
    const unsubscribe = hardReload(userId);
    return () => unsubscribe();
  }, [userId]);
};

export const useSubscriptionOnSubscriptionPlan = () => {
  const companyId = useSelector(({ company }: Store.State) => company.id);

  useEffect(() => {
    if (!companyId) return;

    const unsubscribe = watchSubscriptionPlan(companyId);

    return () => unsubscribe();
  }, [companyId]);
};
