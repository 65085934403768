import { useMemo } from "react";

import styles from "./PaymentModal.module.scss";
import * as icons from "../../../icons";
import { SubscriptionPlansWithStripe } from "../../../containers/Home/Settings/components/SubscriptionPlans/SubscriptionPlansWithStripe";
import { showToastNotification } from "../../ToastNotification/utils";

type Props = {
  subscriptionPlanId: string;
};

const STANDARD_INVOICE_LINK = "https://www.mytelescope.io/upgrade-plan";

export const PaymentModal = ({ subscriptionPlanId }: Props) => {
  const stripeLink = useMemo(() => {
    const { stripeLink } =
      SubscriptionPlansWithStripe.entities[subscriptionPlanId] || {};

    return stripeLink || "";
  }, [subscriptionPlanId]);

  const invoiceLink = useMemo(() => {
    return (
      SubscriptionPlansWithStripe.entities[subscriptionPlanId]?.invoiceLink ||
      STANDARD_INVOICE_LINK
    );
  }, [subscriptionPlanId]);

  function onBrokenPaymentOptionChosen() {
    showToastNotification({
      type: "warning",
      text:
        "Oops, something wrong with the payment link, please contact us for help!",
    });
  }

  return (
    <div className={styles.paymentModal}>
      <h4 className={styles.title}>Choose payment method</h4>
      <div className={styles.wrapper}>
        <div className={styles.tile}>
          <icons.CreditCards width={100} />
          <p>Credit card</p>
          <div className={styles.paymentMethods}>
            <div className={styles.links}>
              {stripeLink ? (
                <a href={stripeLink} target="_blank" rel="noreferrer">
                  Next
                </a>
              ) : (
                <button onClick={onBrokenPaymentOptionChosen}>Next</button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.tile}>
          <icons.Invoice width={60} />
          <p>Invoice</p>
          <a href={invoiceLink} target="_blank" rel="noreferrer">
            Next
          </a>
        </div>
      </div>
    </div>
  );
};
