import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import * as api from "./marketsApi";

type InitialState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  // error: string | null;
};

export type Market = {
  code: string;
  label: string;
  subRegions?: string[];
  languageCode?: string[];
};

const marketsAdapter = createEntityAdapter<Market>({
  selectId: ({ code }) => code,
  sortComparer: (a, b) => a.label.localeCompare(b.label),
});

const initialState = marketsAdapter.getInitialState<InitialState>({
  status: "idle",
  // error: null,
});

export const fetchAllMarkets = createAsyncThunk<Market[]>(
  "markets/fetchAll",
  async () => await api.getMarkets(),
);

const marketsSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllMarkets.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllMarkets.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.error = null;
      marketsAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchAllMarkets.rejected, (state) => {
      state.status = "failed";
      // state.error = "Can't fetch faqs";
    });
  },
});

export default marketsSlice.reducer;
