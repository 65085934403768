import { FC } from "react";
import { useDispatch } from "react-redux";

import styles from "./BusinessDriverPositioningPreview.module.scss";
import { Preloader, ErrorPlaceholder } from "../../../components";
import { QuadrantChart } from "./components/QuadrantChart/QuadrantChart";
import { onErrorHandler } from "../../helpers";

// Inner imports
import { WIDGET_ID } from "./constants";
import { useWidgetFetching } from "../../hooks";

export const BusinessDriverPositioningPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { oneYear, sixMonths, threeMonths, twoMonths, oneMonth, brands } =
    widgetData || {};

  const chartData =
    oneYear || sixMonths || threeMonths || twoMonths || oneMonth;

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.businessDriverPositioningPreview}>
      {chartData && brands ? (
        <>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            <QuadrantChart
              className={styles.chart}
              brands={brands}
              axes={chartData.attributes}
              data={chartData.values}
            />
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
