import { CSSProperties, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./BrandSelector.module.scss";
import { Select } from "../..";

interface Props {
  className?: string;
  style?: CSSProperties;
  brands?: string[];
  currentBrandIndex?: number;
  onBrandClicked: (brandIndex: number) => void;
  isEventPropagationStopped?: boolean;
}

export const BrandSelector = memo(
  ({
    className = "",
    brands = [],
    currentBrandIndex,
    onBrandClicked,
    isEventPropagationStopped,
  }: Props) => {
    const { t } = useTranslation();
    const title = t("brands_drop_down");

    const brandsOptions = useMemo(() => {
      return brands.map((brand) => ({ value: brand, label: brand }));
    }, [brands]);

    const currentBrand =
      (typeof currentBrandIndex === "number" && brands[currentBrandIndex]) ||
      "";

    const onBrandChanged = (value: string) => {
      const brandIndex = brandsOptions.findIndex(
        ({ value: brandValue }) => brandValue === value,
      );

      onBrandClicked(brandIndex);
    };

    if (!brands.length) return null;

    return (
      <div
        className={cx(styles.brandSelector, className)}
        onClick={(e) => isEventPropagationStopped && e.stopPropagation()}
      >
        <p className={styles.title}>{title}</p>
        <Select
          title={title}
          options={brandsOptions}
          value={currentBrand}
          changeHandler={onBrandChanged}
          openingDirection="down"
        />
      </div>
    );
  },
);
