import React, {
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import { useHistory, useParams } from "react-router-dom";
import add from "date-fns/add";
import isAfter from "date-fns/isAfter";

import styles from "./TimePlotterForm.module.scss";
import { withError } from "src/hocs";
import context from "src/context";
import { formatToDayMonthYearDate, sendDataToGTM } from "src/utils";
import {
  useWindowWidth,
  useBlockRedirection,
  useTableSettings,
} from "src/hooks";
import { Input, Label, Select, GeneralFormButtons } from "src/components";
import { TextArea } from "src/components/inputs/TextArea/TextArea";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { allCountries } from "src/data/allCountries";
import { selectSearches } from "src/store/selectors";
import { createTimePlot, updateTimePlot } from "src/store/timePlots/actions";

// Inner imports
import {
  TIME_PLOTTER_FORM_MAX_LENGTH,
  TIME_PLOTTER_FORM_MIN_DIFFERENCE_IN_DAYS,
  TIME_PLOTTER_FORM_REQUIRED_FIELDS,
} from "./constants";
import {
  checkTimePlotterBeforeAction,
  getTimePlotterDateToPlaceholder,
  trimTimePlotTextFields,
  clearEmptyFieldsFromObject,
  getBreadcrumbs,
} from "./utils";
import { TimePlotterFormProps } from "./types";
import { NewTimePlot } from "../../types";
import { DESKTOP_WIDTH_BREAKPOINT } from "../../constants";
import TimePlotterDatePicker from "../TimePlotterTimePicker/TimePlotterDatePicker";

const InputWithMessage = withError(Input);
const SelectWithError = withError(Select);
const DatePickerWithError = withError(TimePlotterDatePicker);
const TextAreaWithError = withError(TextArea);

const timePlotInitialEmptyState = {
  name: "",
  searchId: "",
  dateFrom: "",
  dateTo: "",
  country: "",
  channels: "",
  budget: "",
  notes: "",
} as NewTimePlot;

const TimePlotterForm: FC<TimePlotterFormProps> = ({
  className = "",
  isRedirectAlwaysAllowed = false,
  preselectedSearchId: passedPreselectedSearchId = "",
  children,
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const { timePlotId } = useParams() as { timePlotId?: string };

  const { setter: setTimePlottersTableSettings } = useTableSettings(
    "timePlotter",
  );

  const backToBreadcrumb = history.location.state as
    | ActionBar.BreadcrumbOption
    | undefined;

  const {
    setModalElement,
    setIsGlobalPreloaderShown,
    setActionBarProps,
  } = useContext(context);

  const searches = useSelector(selectSearches);

  const allTimePlots: Store.TimePlot[] = useSelector(
    ({ timePlots }: Store.State) => timePlots.data,
  );

  const existingTimePlot: Store.TimePlot | undefined = useMemo(
    () => allTimePlots?.find(({ id }) => id === timePlotId),
    [allTimePlots, timePlotId],
  );

  const backToState = useMemo(
    () =>
      backToBreadcrumb?.linkOptions?.state as
        | Record<string, string>
        | undefined,
    [backToBreadcrumb?.linkOptions?.state],
  );

  const preselectedSearchId: string = useMemo(() => {
    return passedPreselectedSearchId || backToState?.searchId || "";
  }, [passedPreselectedSearchId, backToState?.searchId]);

  const searchIds: string[] = useMemo(() => searches.map(({ id }) => id), [
    searches,
  ]);

  const isSearchDeleted: boolean = useMemo(
    () =>
      !!existingTimePlot?.searchId &&
      !searchIds.includes(existingTimePlot?.searchId),
    [existingTimePlot?.searchId, searchIds],
  );

  const timePlotInitialState = useMemo(
    () => ({
      ...(existingTimePlot || timePlotInitialEmptyState),
      searchId: isSearchDeleted
        ? ""
        : existingTimePlot?.searchId || preselectedSearchId,
    }),
    [existingTimePlot, isSearchDeleted, preselectedSearchId],
  );

  const [timePlot, setTimePlot] = useState<Store.TimePlot | NewTimePlot>(
    timePlotInitialState,
  );

  const [inputError, setInputError] = useState<NewTimePlot>(
    timePlotInitialEmptyState,
  );

  useEffect(() => {
    if (isRedirectAlwaysAllowed) return;

    const title = existingTimePlot?.name
      ? t("tp_edit_modal_title", { name: existingTimePlot?.name })
      : t("event_form_plotter_modal_name");

    setActionBarProps({
      title,
      breadcrumbs: getBreadcrumbs(title, backToBreadcrumb, t),
    });
  }, [
    existingTimePlot,
    isRedirectAlwaysAllowed,
    setActionBarProps,
    t,
    backToBreadcrumb,
  ]);

  const isMobileView: boolean = useMemo(
    () => windowWidth < DESKTOP_WIDTH_BREAKPOINT,
    [windowWidth],
  );

  const calendarPosition = useMemo(
    () => (isMobileView ? "bottom" : "bottom-end"),
    [isMobileView],
  );

  const minDateTo: Date = useMemo(() => {
    const dateFrom = new Date(timePlot.dateFrom);
    dateFrom.setDate(
      dateFrom.getDate() + TIME_PLOTTER_FORM_MIN_DIFFERENCE_IN_DAYS,
    );
    return dateFrom;
  }, [timePlot]);

  const dateFromInputValue: string = useMemo(
    () =>
      timePlot.dateFrom
        ? t("tp_header_form_from", {
            date: formatToDayMonthYearDate(timePlot.dateFrom),
          })
        : "",
    [t, timePlot],
  );

  const dateToInputValue: string = useMemo(
    () =>
      timePlot.dateTo
        ? t("tp_header_form_to", {
            date: formatToDayMonthYearDate(timePlot.dateTo),
          })
        : "",
    [t, timePlot.dateTo],
  );

  const parsedSearches: {
    value: string;
    label: string;
  }[] = useMemo(
    () =>
      searches.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [searches],
  );

  const isFormDataChanged: boolean = useMemo(() => {
    const clearFormData = clearEmptyFieldsFromObject(timePlot);
    const clearInitialFormData = clearEmptyFieldsFromObject(
      timePlotInitialState,
    );

    return !isEqual(clearFormData, clearInitialFormData);
  }, [timePlot, timePlotInitialState]);

  const onTimePlotChange = useCallback(
    (field: string, value: string): void => {
      const trimmedValue = value.trimStart();
      const isFieldRequired = TIME_PLOTTER_FORM_REQUIRED_FIELDS.includes(field);

      setTimePlot((timePlotter) => ({
        ...timePlotter,
        [field]: trimmedValue,
      }));

      if (!isFieldRequired) return;

      setInputError((errors) => ({
        ...errors,
        [field]: trimmedValue ? "" : t("tp_form_error_required"),
      }));
    },
    [t],
  );

  const validateInputs = useCallback((): boolean => {
    const inputErrors: Errors = {};

    TIME_PLOTTER_FORM_REQUIRED_FIELDS.forEach((field) => {
      const value = timePlot[field as keyof NewTimePlot];
      const trimmedValue = value?.trimStart();

      if (!trimmedValue) {
        inputErrors[field as keyof Store.TimePlot] = t(
          "tp_form_error_required",
        );
      }
    });

    setInputError(inputErrors as SetStateAction<NewTimePlot>);

    return !TIME_PLOTTER_FORM_REQUIRED_FIELDS.some(
      (field) => !timePlot[field as keyof NewTimePlot],
    );
  }, [t, timePlot]);

  // BLOCK REDIRECT IF DATA CHANGED --->
  const { unblockRedirection } = useBlockRedirection(
    isFormDataChanged && !isRedirectAlwaysAllowed,
  );
  // <--- BLOCK REDIRECT IF DATA CHANGED

  const onModalClose = useCallback(
    (path?: string): void => {
      setTimePlot(timePlotInitialState);
      setModalElement(null);
      setInputError(timePlotInitialEmptyState);

      // Needed for redirection without confirmation --->
      unblockRedirection();
      // <---- Needed for redirection without confirmation

      if (path) {
        history.push(path);
      } else {
        history.goBack();
      }
    },
    [history, setModalElement, unblockRedirection, timePlotInitialState],
  );

  const getInputLimitText = useCallback(
    (passedValue?: string, passedLimit?: number): string => {
      const value = passedValue || "";
      const limit = passedLimit || TIME_PLOTTER_FORM_MAX_LENGTH.input;

      return value.length === limit
        ? t("tp_max_chars_text", {
            count: limit,
          })
        : "";
    },
    [t],
  );

  const onTimePlotCreate = useCallback(async (): Promise<void> => {
    const newTimePlotId = await dispatch(createTimePlot(timePlot));

    showToastNotification({
      type: "success",
      text: t("event_header_success_modal_body"),
    });

    sendDataToGTM("UserCreateTimePlot", {
      createdTimePlotterId: newTimePlotId,
    });

    onModalClose();
  }, [dispatch, onModalClose, t, timePlot]);

  const onTimePlotEdit = useCallback(async (): Promise<void> => {
    try {
      if (isFormDataChanged && "id" in timePlot) {
        await dispatch(updateTimePlot(timePlot.id, timePlot));

        showToastNotification({
          type: "success",
          text: t("event_edit_successful"),
        });
      }
    } catch (err) {
      console.error(err);

      showToastNotification({
        type: "error",
        text: t("request_error"),
      });
    } finally {
      onModalClose();
    }
  }, [dispatch, isFormDataChanged, onModalClose, t, timePlot]);

  const onDateFromChange = (inputDate: Date): void => {
    onTimePlotChange("dateFrom", inputDate.toISOString());

    const dateWithDifference = add(inputDate, {
      days: TIME_PLOTTER_FORM_MIN_DIFFERENCE_IN_DAYS,
    });

    if (
      timePlot.dateTo &&
      isAfter(dateWithDifference, new Date(timePlot.dateTo))
    )
      onTimePlotChange("dateTo", dateWithDifference.toISOString());
  };

  const onDateToChange = (inputDate: Date): void =>
    onTimePlotChange("dateTo", inputDate.toISOString());

  const submitForm = useCallback(async (): Promise<void> => {
    const isValidated = validateInputs();

    if (!isValidated) return;

    const error = checkTimePlotterBeforeAction(timePlot, allTimePlots);

    if (error) {
      showToastNotification({
        type: "error",
        text: t(error),
      });

      return;
    }

    try {
      setIsGlobalPreloaderShown(true);
      trimTimePlotTextFields(timePlot);

      if (existingTimePlot) {
        await onTimePlotEdit();
      } else {
        await onTimePlotCreate();
      }
      setTimePlottersTableSettings({ tab: "recentTimePlots", page: 0 });
    } catch (err) {
      showToastNotification({
        type: "error",
        text: err || t("request_error"),
      });
      console.error(err);
    } finally {
      setIsGlobalPreloaderShown(false);
    }
  }, [
    allTimePlots,
    existingTimePlot,
    onTimePlotCreate,
    onTimePlotEdit,
    setIsGlobalPreloaderShown,
    setTimePlottersTableSettings,
    t,
    timePlot,
    validateInputs,
  ]);

  return (
    <div className={className || styles.timePlotterForm}>
      <form className={styles.form}>
        <div>
          <Label leftText={t("tp_form_plotter_name_lb")} />
          <InputWithMessage
            value={timePlot.name}
            placeholder={t("tp_form_plotter_name_ph")}
            changeHandler={(value: string) => {
              onTimePlotChange("name", value);
            }}
            error={inputError.name}
            maxLength={TIME_PLOTTER_FORM_MAX_LENGTH.nameInput}
            info={getInputLimitText(
              timePlot.name,
              TIME_PLOTTER_FORM_MAX_LENGTH.nameInput,
            )}
          />
        </div>
        <div>
          <Label leftText={t("event_form_choose_search_lb")} />
          <SelectWithError
            options={parsedSearches}
            value={timePlot.searchId}
            className={styles.select}
            placeholder={t("event_form_choose_search_ph")}
            changeHandler={(value: string) => {
              const message: string =
                preselectedSearchId && preselectedSearchId !== value
                  ? t("event_form_searchId_warning")
                  : "";

              onTimePlotChange("searchId", value);

              setInputError((state) => ({
                ...state,
                searchId: message,
              }));
            }}
            error={inputError.searchId}
          />
        </div>
        <div className={styles.datePickerContainer}>
          <div>
            <Label leftText={t("tp_form_start_activation_lb")} />
            <DatePickerWithError
              selectedValue={
                timePlot.dateFrom ? new Date(timePlot.dateFrom) : undefined
              }
              value={dateFromInputValue}
              onChange={onDateFromChange}
              placeholder={t("tp_form_start_activation_ph", {
                date: formatToDayMonthYearDate(new Date()),
              })}
              popperPlacement={calendarPosition}
              error={inputError.dateFrom}
            />
          </div>
          <div>
            <Label leftText={t("tp_form_end_activation_lb")} />
            <DatePickerWithError
              selectedValue={
                timePlot.dateTo ? new Date(timePlot.dateTo) : undefined
              }
              value={dateToInputValue}
              minDate={minDateTo}
              onChange={onDateToChange}
              placeholder={t("tp_form_end_activation_ph", {
                date: getTimePlotterDateToPlaceholder(),
              })}
              popperPlacement={calendarPosition}
              error={inputError.dateTo}
            />
          </div>
        </div>
        <div>
          <Label leftText={t("tp_form_country_input_lb")} isOptional />
          <Select
            options={allCountries}
            value={timePlot.country}
            className={styles.select}
            placeholder={t("tp_form_country_input_ph")}
            changeHandler={(value: string): void =>
              onTimePlotChange("country", value)
            }
          />
        </div>
        <div>
          <Label leftText={t("tp_form_channels_input_lb")} isOptional />
          <InputWithMessage
            value={timePlot.channels}
            className={styles.input}
            placeholder={t("tp_form_channels_input_ph")}
            changeHandler={(value: string): void => {
              onTimePlotChange("channels", value);
            }}
            maxLength={TIME_PLOTTER_FORM_MAX_LENGTH.input}
            info={getInputLimitText(timePlot.channels)}
          />
        </div>
        <div>
          <Label leftText={t("tp_form_budget_input_lb")} isOptional />
          <InputWithMessage
            value={timePlot.budget}
            className={styles.input}
            placeholder={t("tp_form_budget_input_ph")}
            changeHandler={(value: string): void => {
              onTimePlotChange("budget", value);
            }}
            maxLength={TIME_PLOTTER_FORM_MAX_LENGTH.input}
            info={getInputLimitText(timePlot.budget)}
          />
        </div>
        <div className={styles.notes}>
          <Label leftText={t("tp_form_notes_input_lb")} isOptional />
          <TextAreaWithError
            value={timePlot.notes}
            className={styles.textarea}
            placeholder={t("tp_form_notes_input_ph")}
            changeHandler={(value: string): void => {
              onTimePlotChange("notes", value);
            }}
            clearHandler={() => {
              onTimePlotChange("notes", "");
            }}
            maxLength={TIME_PLOTTER_FORM_MAX_LENGTH.textarea}
            info={getInputLimitText(
              timePlot.notes,
              TIME_PLOTTER_FORM_MAX_LENGTH.textarea,
            )}
          />
        </div>
      </form>
      {children}
      <GeneralFormButtons
        className={styles.controlButtons}
        isButtonsActive={isFormDataChanged}
        onSubmitHandler={submitForm}
      />
    </div>
  );
};

export default TimePlotterForm;
