import { SentimentDriversReviewData } from "../../components/ReviewData/SentimentDriversReviewData/SentimentDriversReviewData";
import { TopTrendKeywordsReviewData } from "../../components/ReviewData/TopTrendKeywordsReviewData/TopTrendKeywordsReviewData";
import { ESGReviewData } from "../../components/ReviewData/ESGReviewData/ESGReviewData";
import { BusinessDriversReviewData } from "../../components/ReviewData/BusinessDriversReviewData/BusinessDriversReviewData";
import { ReputationReviewData } from "../../components/ReviewData/ReputationReviewData/ReputationReviewData";

import type { ReviewDataProps } from "./types";

const WIDGETS_IN_REVIEW_DATA_BLOCK = {
  "result-sentiment-drivers": true,
  "result-top-trend-keywords": true,
  "result-esg-index": true,
  "result-esg-sentiment": true,
  "result-esg-distribution": true,
  "result-business-drivers": true,
  "result-reputation-index": true,
  "result-reputation-sentiment": true,
  "result-reputation-distribution": true,
};

export const REVIEW_DATA_OPTIONS: {
  widgetId: keyof typeof WIDGETS_IN_REVIEW_DATA_BLOCK;
  relatedWidgets: Array<keyof typeof WIDGETS_IN_REVIEW_DATA_BLOCK>;
  component: ({
    initialSearch,
    openedFromSideBar,
  }: ReviewDataProps) => JSX.Element;
}[] = [
  {
    widgetId: "result-sentiment-drivers",
    relatedWidgets: ["result-sentiment-drivers"],
    component: SentimentDriversReviewData,
  },
  {
    widgetId: "result-top-trend-keywords",
    relatedWidgets: ["result-top-trend-keywords"],
    component: TopTrendKeywordsReviewData,
  },
  {
    widgetId: "result-esg-index",
    relatedWidgets: [
      "result-esg-index",
      "result-esg-sentiment",
      "result-esg-distribution",
    ],
    component: ESGReviewData,
  },
  {
    widgetId: "result-business-drivers",
    relatedWidgets: ["result-business-drivers"],
    component: BusinessDriversReviewData,
  },
  {
    widgetId: "result-reputation-index",
    relatedWidgets: [
      "result-reputation-index",
      "result-reputation-sentiment",
      "result-reputation-distribution",
    ],
    component: ReputationReviewData,
  },
];

export { WIDGETS_IN_REVIEW_DATA_BLOCK };
