import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import styles from "./ReputationSentiment.module.scss";
import {
  WidgetButtonsBar,
  ErrorPlaceholder,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { useNameForDownloadFiles } from "../../../hooks";
import DATE_RANGES, {
  DATE_RANGES_TYPE,
  INITIAL_DATE_RANGE,
} from "../../../data/dateRanges";

// Inner imports
import { ReputationSentimentLineChart } from "./components/linechart/ReputationSentimentLineChart";
import { ReputationSentimentPieChartView } from "./components/piecharts/ReputationSentimentPieChartView";
import { WidgetChart } from "../../types";
import { WIDGET_ID } from "./constants";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const ReputationSentiment: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // Brands ---->
  const [brandIndex, setBrandIndex] = useState<number>(0);

  useEffect(() => {
    setBrandIndex(0);
  }, [widgetData]);

  const brands = useMemo(
    () => widgetData?.map(({ brandName }) => brandName) || [],
    [widgetData],
  );

  const brandData = useMemo(() => widgetData?.[brandIndex]?.brandData, [
    widgetData,
    brandIndex,
  ]);
  // <---- Brands

  // Date Range ---->
  const dateRanges = useMemo(
    () => DATE_RANGES.filter(({ value }) => brandData?.pieChart?.[value]),
    [brandData],
  );

  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) setCurrentDateRange(INITIAL_DATE_RANGE);
  }, [dateRanges]);
  // <---- Date Range

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { lineChart, pieChart } = brandData || {};

    if (lineChart) {
      charts.push({
        type: "lineChart",
        chart: (
          <ReputationSentimentLineChart
            data={lineChart!}
            searchId={searchId}
            dashboardId={dashboardId}
          />
        ),
      });
    }

    if (pieChart) {
      charts.push({
        type: "pieChart",
        chart: (
          <ReputationSentimentPieChartView
            data={pieChart}
            currentDateRange={currentDateRange}
          />
        ),
      });
    }

    return charts;
  }, [brandData, currentDateRange, dashboardId, searchId]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Download name ---->
  const currentBrandName = useMemo(() => brands?.[brandIndex] || "", [
    brandIndex,
    brands,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          {...(viewType === "pieChart"
            ? {
                timePeriodSelector: {
                  periods: dateRanges,
                  currentPeriodValue: currentDateRange || "",
                  onPeriodClicked: (value) =>
                    setCurrentDateRange(value as typeof currentDateRange),
                },
              }
            : {})}
          brandSelector={{
            className: styles.brandSelector,
            brands: brands,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => setBrandIndex(brandIndex),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            reviewDataButtonProps={{ type: "reputation" }}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} />
      </div>
    </div>
  );
};
