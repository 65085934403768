import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import compareDesc from "date-fns/compareDesc";
import { useTranslation } from "react-i18next";
import isPlainObject from "lodash/isPlainObject";

import styles from "./TopKeywords.module.scss";
import {
  WidgetButtonsBar,
  WidgetFooterButtons,
  Preloader,
  ErrorPlaceholder,
  AboutThis,
} from "src/components";
import { useNameForDownloadFiles } from "src/hooks";
import { TIME_PERIOD_TYPES } from "src/data/dateRanges";
import { formatToMonthYearDate } from "src/utils";

// Inner imports
import {
  useLastAvailableMonthData,
  useTopKeywordsBrandIndex,
  useDataExistChecker,
} from "./hooks";
import { TopKeywordsWidget, TopKeywordsListView } from "./components";
import {
  getWidgetTrendingWordsData,
  getWidgetTopWordsData,
  checkIfDateSuit,
} from "./utils";
import { WIDGET_ID } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const TopKeywords: FC<WidgetsView.Page> = ({
  searchId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      history.goBack();
    }
  }, [isWidgetCalculating, history]);

  const search = useSelector(({ searches }: Store.State) =>
    searches.find(({ id }) => id === searchId),
  );

  const suggestions = useMemo(() => search?.suggestions, [search?.suggestions]);

  const [
    timePeriodType,
    setTimePeriodType,
  ] = useState<TopKeywords.TimeRangeType>("yearly");

  const hasDifferentTimeRangeData = useMemo<boolean>(
    () => isPlainObject(widgetData?.topWords || widgetData?.trendingWords),
    [widgetData],
  );

  const hasNoKeywordsFilterByDate = useMemo<boolean>(
    () => hasDifferentTimeRangeData && timePeriodType === "yearly",
    [hasDifferentTimeRangeData, timePeriodType],
  );

  const topWords = useMemo<TopKeywords.TopWords[]>(
    () => getWidgetTopWordsData(widgetData, timePeriodType),
    [widgetData, timePeriodType],
  );

  const trendingWords = useMemo<TopKeywords.TrendingWords[]>(
    () => getWidgetTrendingWordsData(widgetData, timePeriodType),
    [widgetData, timePeriodType],
  );

  const [lastAvailableMonthData] = useLastAvailableMonthData(
    widgetData,
    suggestions,
    timePeriodType,
  );

  const [brandIndex, setBrandIndex] = useTopKeywordsBrandIndex(
    lastAvailableMonthData,
    widgetData,
    suggestions,
  );

  const brands = useMemo(() => {
    return widgetData?.brands.map((el) => el.name);
  }, [widgetData]);

  const mainBrand = widgetData?.brands[0]?.id;

  const mainBrandTopKeywordsExcludedWords = useMemo(() => {
    const mainBrand = widgetData?.brands[0]?.name;

    if (!mainBrand) return [];

    return suggestions?.[mainBrand]?.topKeywordsExcludedWords || [];
  }, [suggestions, widgetData?.brands]);

  const [chosenDate, setChosenDate] = useState<Date | undefined>();

  useEffect(() => {
    if (!mainBrand || chosenDate) return;

    function findLastAvailableMonthData<
      T extends TopKeywords.TopWords | TopKeywords.TrendingWords
    >(arr?: T[]): T {
      let lastAvailableMonthData = {} as T;
      if (!mainBrand) return lastAvailableMonthData;

      [...(arr ?? [])].reverse().forEach((el: T) => {
        Object.values(el?.values || {}).forEach((brandItem) => {
          const brandItemWithoutExcludedWords = brandItem?.filter(
            ({ keyword }) =>
              !mainBrandTopKeywordsExcludedWords.some(
                (excludedWord) =>
                  excludedWord.toLowerCase() === keyword?.toLowerCase(),
              ),
          );

          if (!!brandItemWithoutExcludedWords?.length) {
            return (lastAvailableMonthData = el);
          }
        });
      });

      return lastAvailableMonthData;
    }

    const lastTopWordsSuitableMonth = findLastAvailableMonthData(topWords).date;

    const lastTrendingWordsSuitableMonth = findLastAvailableMonthData(
      trendingWords,
    ).date;

    if (lastTopWordsSuitableMonth && lastTrendingWordsSuitableMonth) {
      const lastSuitableMonth = compareDesc(
        new Date(lastTopWordsSuitableMonth),
        new Date(lastTrendingWordsSuitableMonth),
      );

      setChosenDate(
        new Date(
          lastSuitableMonth === -1
            ? lastTopWordsSuitableMonth
            : lastTrendingWordsSuitableMonth,
        ),
      );
    } else {
      lastTopWordsSuitableMonth &&
        setChosenDate(new Date(lastTopWordsSuitableMonth));
      lastTrendingWordsSuitableMonth &&
        setChosenDate(new Date(lastTrendingWordsSuitableMonth));
    }

    if (
      !lastTopWordsSuitableMonth &&
      !lastTrendingWordsSuitableMonth &&
      widgetData?.dateTo
    )
      setChosenDate(new Date(widgetData?.dateTo));
  }, [
    widgetData?.dateTo,
    chosenDate,
    mainBrand,
    mainBrandTopKeywordsExcludedWords,
    topWords,
    trendingWords,
  ]);

  // Widget views ---->
  const topWordsData = useMemo(() => {
    type Element = {
      value: string;
      count: number;
      isPercentage: boolean;
    };

    if (!chosenDate) return [];

    const chosenBrand = widgetData?.brands[brandIndex]?.id || "";
    const chosenBrandName = widgetData?.brands[brandIndex]?.name || "";

    const topKeywordsExcludedWords =
      suggestions?.[chosenBrandName]?.topKeywordsExcludedWords || [];

    return topWords.reduce((acc, { date, values }) => {
      const brandValues = values[chosenBrand];
      const isDateSuitable =
        hasNoKeywordsFilterByDate ||
        checkIfDateSuit(new Date(date), chosenDate);

      if (brandValues) {
        const mappedValues = [] as Element[];

        brandValues.forEach((el) => {
          const { keyword, volume } = el;

          const isKeyWordExcluded = topKeywordsExcludedWords.some(
            (excludedWord) =>
              excludedWord.toLowerCase() === keyword?.toLowerCase(),
          );

          if (keyword && typeof volume === "number" && !isKeyWordExcluded) {
            mappedValues.push({
              value: keyword,
              count: volume,
              isPercentage: false,
            });
          }
        });
        isDateSuitable && acc.push(...mappedValues);
      }
      return acc;
    }, [] as Element[]);
  }, [
    chosenDate,
    widgetData?.brands,
    brandIndex,
    suggestions,
    topWords,
    hasNoKeywordsFilterByDate,
  ]);

  const topTrendingWordsData = useMemo(() => {
    type Element = {
      value: string;
      count: number;
      volume?: number;
      isPercentage: boolean;
    };
    if (!chosenDate) return [];

    const chosenBrand = widgetData?.brands[brandIndex]?.id || "";
    const chosenBrandName = widgetData?.brands[brandIndex]?.name || "";

    const topKeywordsExcludedWords =
      suggestions?.[chosenBrandName]?.topKeywordsExcludedWords || [];

    return trendingWords.reduce((acc, { date, values }) => {
      const brandValues = values[chosenBrand];
      const isDateSuitable =
        hasNoKeywordsFilterByDate ||
        checkIfDateSuit(new Date(date), chosenDate);

      if (brandValues) {
        const mappedValues = [] as Element[];

        brandValues.forEach((el) => {
          const { keyword, trend, volume } = el;

          const isKeyWordExcluded = topKeywordsExcludedWords.some(
            (excludedWord) =>
              excludedWord.toLowerCase() === keyword?.toLowerCase(),
          );

          if (keyword && typeof trend === "number" && !isKeyWordExcluded) {
            mappedValues.push({
              value: keyword,
              count: trend,
              ...(volume ? { volume } : {}),
              isPercentage: true,
            });
          }
        });

        isDateSuitable && acc.push(...mappedValues);
      }

      return acc;
    }, [] as Element[]);
  }, [
    chosenDate,
    widgetData?.brands,
    brandIndex,
    suggestions,
    trendingWords,
    hasNoKeywordsFilterByDate,
  ]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];
    if (topWordsData?.length) {
      charts.push({
        type: "keywords",
        subType: "topWords",
        chart: (
          <TopKeywordsWidget
            className={styles.words}
            data={topWordsData}
            type="page"
          />
        ),
      });
    }

    if (topTrendingWordsData?.length) {
      charts.push({
        type: "keywords",
        subType: "trendingWords",
        chart: (
          <TopKeywordsWidget
            className={styles.words}
            data={topTrendingWordsData}
            customStyles={{
              tagColor: "white",
              tagBackgroundColor: "#0A99BC",
            }}
            type="page"
          />
        ),
      });
    }

    if (topWordsData?.length || topTrendingWordsData?.length) {
      charts.push({
        type: "table",
        chart: (
          <TopKeywordsListView
            className={styles.list}
            topWords={topWordsData}
            topTrendingWords={topTrendingWordsData}
          />
        ),
      });
    }

    return charts;
  }, [topWordsData, topTrendingWordsData]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const { isDataExist } = useDataExistChecker(widgetData, isLoading);

  const isEmptyWidget = !isLoading && !isDataExist;
  // <---- Widget views

  // Download name ---->
  const currentBrandName = useMemo(() => brands?.[brandIndex] || "", [
    brandIndex,
    brands,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const timePeriodTypesOptions = useMemo<
    Option<TopKeywords.TimeRangeType>[]
  >(() => {
    const { dateFrom = "", dateTo = "" } = widgetData || {};

    return TIME_PERIOD_TYPES.map((timePeriodType) => {
      const { value, label } = timePeriodType;

      let formattedLabel: string = t(label);

      if (value === "monthly" && chosenDate)
        formattedLabel = t("drl_selector_single_date_label", {
          dateRange: t("drl_oneMonth"),
          date: formatToMonthYearDate(chosenDate || ""),
        });

      if (value === "yearly" && dateFrom && dateTo)
        formattedLabel = t("drl_selector_label", {
          dateRange: t("drl_oneYear"),
          startDate: formatToMonthYearDate(dateFrom),
          endDate: formatToMonthYearDate(dateTo),
        });

      return {
        ...timePeriodType,
        label: formattedLabel,
      };
    });
  }, [widgetData, chosenDate, t]);

  const isMonthPickerDisabled = useMemo<boolean>(
    () => hasDifferentTimeRangeData && timePeriodType === "yearly",
    [hasDifferentTimeRangeData, timePeriodType],
  );

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={cx(styles.widget, styles.topKeywords)}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          monthRangePicker={{
            selectedDate: chosenDate,
            className: styles.datePickerInput,
            calendarClassName: styles.datePicker,
            maxDate: new Date(widgetData?.dateTo || ""),
            minDate: new Date(widgetData?.dateFrom || ""),
            onDateSelected: (date) => setChosenDate(date as Date),
            disabled: isMonthPickerDisabled,
          }}
          brandSelector={{
            className: styles.brandSelector,
            brands: brands,
            currentBrandIndex: brandIndex,
            onBrandClicked: setBrandIndex,
          }}
          {...(hasDifferentTimeRangeData && {
            timePeriodTypeSelector: {
              periodTypes: timePeriodTypesOptions,
              currentPeriodType: timePeriodType,
              onPeriodTypeClicked: (value: string) =>
                setTimePeriodType(value as TopKeywords.TimeRangeType),
            },
          })}
        />
        {!isLoading ? (
          <>
            <div className={styles.widgetWrapperOuter}>
              <div
                ref={widgetRef}
                className={cx(
                  styles.widgetWrapper,
                  styles[viewType === "table" ? "widgetWrapperList" : ""],
                )}
              >
                {!!Object.keys(charts).length ? (
                  viewElement
                ) : (
                  <div className={styles.error}>
                    {t("w_trending_keywords_no_data_for_period_error")}
                  </div>
                )}
              </div>
              <WidgetFooterButtons
                ref={widgetRef}
                searchId={searchId}
                reviewDataButtonProps={{ type: "top trend keywords" }}
                downloadImageButtonProps={{
                  imageName: downloadedFileName,
                  widgetId: WIDGET_ID,
                }}
              />
            </div>
            <AboutThis widgetId={WIDGET_ID} />
          </>
        ) : (
          <Preloader className={styles.preloader} />
        )}
      </div>
    </div>
  );
};
