import { useMemo } from "react";
import { PieChart } from "react-minimal-pie-chart";
import useResizeObserver from "use-resize-observer";

import styles from "./ReputationSentimentPieChart.module.scss";
import reputationParameters from "../../../../../data/reputationParameters";
import { GREEN, RED, VIOLET } from "../../../../../data/colors";
import DATE_RANGES, {
  INITIAL_DATE_RANGE,
} from "../../../../../data/dateRanges";

type Props = {
  data: ReputationSentiment.PieChart.Data;
};

export const ReputationSentimentPieChart = ({ data }: Props) => {
  const dateRanges = useMemo(
    () => DATE_RANGES.filter(({ value }) => data[value]),
    [data],
  );

  const rangeData = useMemo(() => {
    if (
      INITIAL_DATE_RANGE &&
      dateRanges.some(({ value }) => value === INITIAL_DATE_RANGE)
    )
      return data[INITIAL_DATE_RANGE];

    const firstAvailableDateRange = dateRanges[dateRanges.length - 1]?.value;

    if (firstAvailableDateRange) return data[firstAvailableDateRange];

    return;
  }, [data, dateRanges]);

  const modifiedData = useMemo(
    () =>
      reputationParameters.map(({ name, description }) => {
        const { positive = 0, negative = 0, neutral = 0 } =
          rangeData?.values[name] || {};

        const positiveAsRawNumber = Number(positive.toFixed());
        const negativeAsRawNumber = Number(negative.toFixed());
        const neutralAsRawNumber = Number(neutral.toFixed());

        const positiveAsNumber = Number.isFinite(positiveAsRawNumber)
          ? positiveAsRawNumber
          : 0;
        const negativeAsNumber = Number.isFinite(negativeAsRawNumber)
          ? negativeAsRawNumber
          : 0;
        const neutralAsNumber = Number.isFinite(neutralAsRawNumber)
          ? neutralAsRawNumber
          : 0;

        return {
          label: name,
          description,
          data: [
            {
              value: positiveAsNumber,
              color: GREEN,
            },
            {
              value: negativeAsNumber,
              color: RED,
            },
            {
              value: neutralAsNumber,
              color: VIOLET,
            },
          ],
        };
      }),
    [rangeData],
  );

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const chartsStyle = useMemo(() => {
    if ((width - 48) / (height - 24) >= 4) {
      const x = height - 15;
      return {
        height: height,
        width: x * 4 + 48,
        gridTemplate: `
            "pie0 pie1 pie2 pie3 pie4 pie5 pie6" auto / 1fr 1fr 1fr 1fr 1fr 1fr 1fr
        `,
      };
    }

    if ((width - 32) / (height - 64) >= 3 / 2) {
      const x = (height - 64) / 2;
      return {
        height: "initial",
        width: x * 3 + 32,
        gridTemplate: `
            "pie0 pie1 pie2 pie3" max-content
            "pie0 pie4 pie5 pie6" max-content / 1fr 1fr 1fr 1fr
        `,
        fontSize: x / 7.5 > 16 ? "16px" : `${x / 7.5}px`,
      };
    }

    if ((height - 144) / width >= 4) {
      const x = width;
      return {
        height: x * 4 + 144,
        width: x,
        gridTemplate: `
          "pie0" 1fr
          "pie1" 1fr
          "pie2" 1fr
          "pie3" 1fr / auto
        `,
      };
    }

    if ((height - 104) / (width - 16) >= 3 / 2) {
      const x = (width - 16) / 2;
      return {
        height: x * 3 + 108,
        width: width / 2,
        gridTemplate: `
            "pie0 pie0" 1fr
            "pie1 pie2" 1fr
            "pie3 pie4" 1fr
            "pie5 pie6" 1fr / 1fr 1fr
        `,
        fontSize: x / 9 > 16 ? "16px" : `${x / 9}px`,
      };
    }

    let x;

    if (width - 16 > height - 64) {
      x = (height - 64) / 2;
    } else {
      x = (width - 16) / 2;
    }

    return {
      height: "100%",
      width: x * 2 - 16,
      gridTemplate: `
        "pie0 pie1 pie2" 1fr
        "pie3 pie4 pie5" 1fr
        "blank1 pie6 blank2" 1fr / 1fr 1fr 1fr
        `,
      fontSize: x / 9 > 16 ? "16px" : `${x / 9}px`,
    };
  }, [width, height]);

  return (
    <div ref={ref} className={styles.esgIndexPieChart}>
      <div className={styles.charts} style={chartsStyle}>
        {modifiedData.map(({ label, data }, i) => (
          <div
            className={styles.chartArea}
            style={{ gridArea: "pie" + i }}
            key={i}
          >
            <div className={styles.chartWrapperOuter}>
              <div className={styles.chartWrapperInner}>
                <PieChart
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  data={data}
                  label={(props) => {
                    const value = props.dataEntry.value;
                    return value ? value + "%" : "";
                  }}
                  labelStyle={{
                    fill: "currentColor",
                    fontSize: 8,
                    fontWeight: 500,
                  }}
                  startAngle={-90}
                />
              </div>
              <div className={styles.label}>{label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
