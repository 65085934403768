import { useMemo, useRef, FC } from "react";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";

import styles from "./RobustnessScore.module.scss";
import {
  DataSourceScoreCellChart,
  ErrorPlaceholder,
  AboutThis,
  WidgetButtonsBar,
  Preloader,
  WidgetFooterButtons,
} from "../../../components";
import { useNameForDownloadFiles, useWindowWidth } from "../../../hooks";

// Inner imports
import { WIDGET_ID, scoreChartCellQuantitySizes } from "./constants";
import { formatDataSourceScoreCellChartTitle } from "./utils";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const Robustness: FC<WidgetsView.Page> = ({ searchId, dashboardId }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const mainBrand = widgetData?.brands[0]?.id;

  const dataCellCharts = useMemo(() => {
    const elements = widgetData?.cellChart?.[mainBrand || ""] || [];
    return Object.entries(elements).filter(
      ([property]) => property !== "robustness",
    );
  }, [widgetData, mainBrand]);

  // Widget views ---->
  const {
    ref: cellCharts,
    width: chartWrapperWidth = 0,
  } = useResizeObserver<HTMLDivElement>();

  const scoreChartCellQuantity = useMemo(() => {
    const {
      bigDesktopScreen,
      tabletsScreen,
      mobileScreen,
    } = scoreChartCellQuantitySizes;
    if (windowWidth > bigDesktopScreen.breakpoint) {
      return bigDesktopScreen.elementsQuantity;
    }
    if (windowWidth > tabletsScreen.breakpoint) {
      return tabletsScreen.elementsQuantity;
    }
    return mobileScreen.elementsQuantity;
  }, [windowWidth]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (!!dataCellCharts.length) {
      charts.push({
        type: "cellChart",
        chart: (
          <div className={styles.cellChartsWrapper} ref={cellCharts}>
            {dataCellCharts.map(([property, value]) => (
              <DataSourceScoreCellChart
                title={formatDataSourceScoreCellChartTitle(
                  property as keyof Robustness.Item,
                  t,
                )}
                value={value}
                key={property}
                cellStyles={{
                  width: chartWrapperWidth / scoreChartCellQuantity / 10 - 4, // 10 - square per row; 4 - gap between squares
                  height: chartWrapperWidth / scoreChartCellQuantity / 10 - 4, // 10 - square per row; 4 - gap between squares
                }}
              />
            ))}
          </div>
        ),
      });
    }

    return charts;
  }, [
    cellCharts,
    chartWrapperWidth,
    dataCellCharts,
    scoreChartCellQuantity,
    t,
  ]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                selectedView?.chart
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} />
      </div>
    </div>
  );
};
