const popperModifiers = {
  flip: {
    enabled: false,
  },
  hide: {
    enabled: false,
  },
  preventOverflow: {
    enabled: false,
  },
};

export { popperModifiers };
