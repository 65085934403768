import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showDevelopmentError } from "src/utils";
import { showToastNotification } from "src/components/ToastNotification/utils";
import { readCompany, readSearches, readUserProfile } from "src/store/actions";
import { fetchSubscriptionPlanById } from "src/store/subscriptionPlans/subscriptionPlansSlice";
import { fetchAllWidgetsData } from "src/store/widgetsData/widgetsDataSlice";

export const useUserDataLoader = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector(({ user }: Store.State) => user.id);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");
  const [companyId, setCompanyId] = useState("");

  useEffect(() => {
    if (loadingStatus !== "idle") return;

    (async () => {
      try {
        setLoadingStatus("loading");
        const { companyId } = await dispatch(readUserProfile());
        setCompanyId(companyId);
        setLoadingStatus("succeeded");
      } catch (error) {
        errorHandler(userId, "user", error);
        setLoadingStatus("failed");
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, loadingStatus, dispatch, t]);

  return {
    loadingStatus,
    companyId,
  };
};

export const useCompanyDataLoader = (companyId?: string) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector(({ user }: Store.State) => user.id);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        setLoadingStatus("loading");
        await dispatch(readCompany(companyId));
        setLoadingStatus("succeeded");
      } catch (error) {
        errorHandler(userId, "company", error);
        setLoadingStatus("failed");
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, companyId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

export const useSearchesDataLoader = (companyId?: string) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector(({ user }: Store.State) => user.id);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        setLoadingStatus("loading");
        await dispatch(readSearches(companyId));
        setLoadingStatus("succeeded");
      } catch (error) {
        errorHandler(userId, "searches", error);
        setLoadingStatus("failed");
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, companyId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

export const useSubscriptionPlanLoader = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector(({ user }: Store.State) => user.id);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const companySubscriptionPlanId = useSelector(
    ({ company }: Store.State) => company.subscriptionPlanId,
  );

  useEffect(() => {
    if (loadingStatus !== "idle" || !companySubscriptionPlanId) return;

    (async () => {
      try {
        setLoadingStatus("loading");
        const result = await dispatch(
          fetchSubscriptionPlanById(companySubscriptionPlanId),
        );
        unwrapResult(result);
        setLoadingStatus("succeeded");
      } catch (error) {
        errorHandler(userId, "subscriptionPlan", error);
        setLoadingStatus("failed");
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, companySubscriptionPlanId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

export const useWidgetsDataLoader = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector(({ user }: Store.State) => user.id);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus !== "idle") return;

    (async () => {
      try {
        setLoadingStatus("loading");
        const result = await dispatch(fetchAllWidgetsData());
        unwrapResult(result);
        setLoadingStatus("succeeded");
      } catch (error) {
        errorHandler(userId, "widgetsData", error);
        setLoadingStatus("failed");
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

function errorHandler(
  userId: string,
  type: "user" | "company" | "searches" | "subscriptionPlan" | "widgetsData",
  error: any,
) {
  let typeError = "";

  switch (type) {
    case "user":
      typeError = "Error occurred in readUser function";
      break;
    case "company":
      typeError = "Error occurred in readCompany function";
      break;
    case "searches":
      typeError = "Error occurred in readSearches function";
      break;
    case "subscriptionPlan":
      typeError = "Error occurred in readSubscriptionPlan function";
      break;
    case "widgetsData":
      typeError = "Error occurred in readWidgetsData function";
      break;
  }

  showDevelopmentError({
    additionalTexts: [typeError],
    error,
    clickUpProps: {
      name: `[${userId}]: Error on loading initial data for user`,
      text_content: {
        error: typeError,
        message: error.message ? error.message : JSON.stringify(error),
      },
    },
  });
}
