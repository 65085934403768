import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./MultiSelectButton.module.scss";
import * as icons from "../../../../icons";
import { useOutsideClickHandler, useWindowWidth } from "../../../../hooks";

export const MultiSelectButton: FC<ActionBar.MultiSelectButton> = ({
  placeholder = "Select option",
  options,
}) => {
  const { t } = useTranslation();

  const windowWidth = useWindowWidth();

  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useOutsideClickHandler(ref, () => setIsOpen(false));

  const isMobileView = useMemo(() => windowWidth <= 991, [windowWidth]);

  function renderIcon(icon?: AppIcon) {
    const Icon = icon && icons[icon];

    return Icon ? <Icon /> : "";
  }

  if (!options.length) return null;

  return (
    <div
      className={cx(styles.multiSelect, styles[isOpen ? "Opened" : ""])}
      ref={ref}
    >
      <button
        className={styles.multiSelectButton}
        onClick={() => setIsOpen((state) => !state)}
      >
        <span>{t(placeholder)}</span>
        <div className={styles.triangle}>
          {renderIcon(isMobileView ? "Plus" : "Triangle")}
        </div>
      </button>
      {isOpen && (
        <div className={styles.dropDown}>
          {options.map(({ text, icon, onClickHandler }, i) => (
            <button
              key={`${text} + ${i}`}
              className={styles.option}
              onClick={() => {
                setIsOpen(false);
                onClickHandler();
              }}
            >
              {renderIcon(icon)}
              {t(text)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
