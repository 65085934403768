import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from "./SearchTrendsPreview.module.scss";
import {
  Preloader,
  LineChart,
  ChartButton,
  ErrorPlaceholder,
} from "../../../components";
import { WidgetChart } from "../../types";

// Inner imports
import { WIDGET_ID, DEFAULT_DATE_RANGE } from "./constants";
import { findBiggestAvailablePeriod } from "./utils";
import { onErrorHandler } from "../../helpers";
import { useWidgetFetching, useWidgetPreview } from "../../hooks";

export const SearchTrendPreview: FC<WidgetsView.Preview> = ({
  searchId,
  setChartRef,
  onChartClicked,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const { lineChart } = widgetData || {};

  const dataFilteredByPeriod = useMemo(() => {
    if (lineChart) {
      // GET DATA WITH DEFAULT VALUE --->
      const defaultData = lineChart[DEFAULT_DATE_RANGE || "oneYear"];
      if (defaultData && !!Object.keys(defaultData)) {
        return defaultData;
      }
      // <--- GET DATA WITH DEFAULT VALUE

      // GET DATA WITH BIGGEST VALUE IF DEFAULT HAS NOT BEEN FOUND --->
      const biggestAvailablePeriod = findBiggestAvailablePeriod(lineChart);
      const theBiggestData = lineChart[biggestAvailablePeriod || "oneYear"];
      if (theBiggestData && !!Object.keys(theBiggestData)) {
        return theBiggestData;
      }
      // <--- GET DATA WITH BIGGEST VALUE IF DEFAULT HAS NOT BEEN FOUND

      return [];
    }

    return [];
  }, [lineChart]);

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    if (dataFilteredByPeriod.length) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.chart}
            data={dataFilteredByPeriod}
            searchId={searchId}
            viewMode="widgetPreview"
            chartStyles={{
              lineStrokeWidth: 3,
            }}
            chartSettings={{
              hasTrendLine: true,
            }}
            axis={{
              yAxisVerticalPadding: { top: 10, bottom: 0 },
            }}
          />
        ),
      });
    }

    return charts;
  }, [dataFilteredByPeriod, searchId]);

  const { widgetViews: views, viewIndex, setViewIndex } = useWidgetPreview({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  return (
    <div className={styles.widgetPreview}>
      {views.length ? (
        <>
          <div className={styles.buttons}>
            {views.map(({ buttonIcon }, i) =>
              i !== viewIndex ? (
                <ChartButton
                  className={styles.button}
                  icon={buttonIcon}
                  onButtonClick={() => setViewIndex(i)}
                  key={buttonIcon + i}
                  isEventPropagationStopped
                />
              ) : null,
            )}
          </div>
          <div
            ref={(chartRef) => chartRef && setChartRef(chartRef)}
            className={styles.chartWrapper}
            onClick={onChartClicked}
          >
            {views[viewIndex]?.chart}
          </div>
        </>
      ) : (
        <ErrorPlaceholder
          onMountCallBack={() =>
            onErrorHandler(setChartRef, searchId, WIDGET_ID, dispatch)
          }
        />
      )}
    </div>
  );
};
