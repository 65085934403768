import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import cx from "classnames";

import styles from "./ReputationIndex.module.scss";
import {
  LineChart,
  WidgetButtonsBar,
  ErrorPlaceholder,
  Preloader,
  WidgetFooterButtons,
  AboutThis,
} from "../../../components";
import { ReputationIndexPieChart } from "./components/ReputationIndexPieChart/ReputationIndexPieChart";
import { useNameForDownloadFiles } from "../../../hooks";

// Inner imports
import { WIDGET_ID, LINE_CHART_LABELS } from "./constants";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const ReputationIndex: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // Brands ---->
  const [brandIndex, setBrandIndex] = useState<number>(0);

  useEffect(() => {
    setBrandIndex(0);
  }, [widgetData]);

  const brands = useMemo(
    () => widgetData?.map(({ brandName }) => brandName) || [],
    [widgetData],
  );
  // <---- Brands

  const brandData = useMemo(() => widgetData?.[brandIndex]?.brandData, [
    widgetData,
    brandIndex,
  ]);

  // Widget views ---->
  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { lineChart, pieChart } = brandData || {};

    if (lineChart) {
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            className={styles.lineChart}
            labels={LINE_CHART_LABELS}
            data={lineChart}
            dashboardId={dashboardId}
            searchId={searchId}
            chartStyles={{
              lineStrokeWidth: 4,
            }}
            chartSettings={{
              hasBackground: true,
            }}
            axis={{
              yAxisVerticalPadding: { top: 10, bottom: 10 },
            }}
          />
        ),
      });
    }

    if (pieChart) {
      charts.push({
        type: "pieChart",
        chart: (
          <ReputationIndexPieChart
            className={styles.pieChart}
            data={pieChart}
          />
        ),
      });
    }

    return charts;
  }, [brandData, searchId, dashboardId]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Download name ---->
  const currentBrandName = useMemo(() => brands?.[brandIndex] || "", [
    brandIndex,
    brands,
  ]);

  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
    currentBrandName,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={styles.widget}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          brandSelector={{
            className: styles.brandSelector,
            brands: brands,
            currentBrandIndex: brandIndex,
            onBrandClicked: (brandIndex) => setBrandIndex(brandIndex),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div
            ref={widgetRef}
            className={cx(
              styles.widgetWrapper,
              styles[viewType === "lineChart" ? "widgetWrapperLineChart" : ""],
            )}
          >
            {isLoading ? (
              <Preloader className={styles.preloader} />
            ) : (
              viewElement
            )}
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            reviewDataButtonProps={{ type: "reputation" }}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
          />
        </div>
        <AboutThis widgetId={WIDGET_ID} />
      </div>
    </div>
  );
};
