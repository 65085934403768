import { useMemo, FC } from "react";

import styles from "./SentimentTrendsPreview.module.scss";
import {
  Preloader,
  LineChart,
  ErrorPlaceholder,
  TimePlotterPlaceholder,
} from "../../../components";
import { useWidgetFetching } from "../../hooks";
import { usePrepareLineChartData } from "../../../components/widgets/LineChart/hooks";
import { PendingStub } from "../../../components/Tiles/WidgetTile/components/Stubs";

// Inner imports
import { WIDGET_ID } from "./constants";
import { prepareLineChartData } from "./utils";
import { WidgetSummaryProps } from "../../types";
import {
  SummaryTimePeriod,
  SummaryYearlyAverage,
} from "../../descriptionInsertionComponents";

export const SentimentTrendsSummary: FC<WidgetSummaryProps> = ({
  searchId,
  timePlot,
}) => {
  const { widgetData, isLoading, isCalculating } = useWidgetFetching(
    searchId,
    WIDGET_ID,
  );

  const { lineChartData, mainBrand } = useMemo(() => {
    const { lineChart = {}, brands = [] } = widgetData || {};
    const mainBrand = brands[0] || { id: "", name: "" };

    return {
      lineChartData: prepareLineChartData(lineChart),
      mainBrand,
    };
  }, [widgetData]);

  const { data: filteredData } = usePrepareLineChartData({
    data: lineChartData,
    searchId,
    timePlotterId: timePlot.id,
    viewMode: "timePlotterSummary",
  });

  const mainBrandLineChartData = useMemo<{ date: string; value: number }[]>(
    () =>
      lineChartData.map(({ date, values }) => ({
        date,
        value: (values[mainBrand.name] || 0) as number,
      })),
    [lineChartData, mainBrand],
  );

  const mainBrandValues = useMemo<number[]>(
    () => filteredData.map(({ values }) => values[mainBrand.name] || 0),
    [filteredData, mainBrand],
  );

  if (isLoading) return <Preloader type="bar" modifier="inner" />;

  if (isCalculating) return <PendingStub />;

  if (!lineChartData.length) return <ErrorPlaceholder />;

  if (!filteredData.length) return <TimePlotterPlaceholder />;

  return (
    <div className={styles.widgetSummary}>
      <div className={styles.descriptionWrapper}>
        <SummaryTimePeriod
          data={mainBrandValues}
          widgetId={WIDGET_ID}
          selectedBrand={mainBrand}
        />
        <SummaryYearlyAverage
          data={mainBrandLineChartData}
          widgetId={WIDGET_ID}
          selectedBrand={mainBrand}
        />
      </div>
      <div className={styles.chartWrapper}>
        <LineChart
          className={styles.chart}
          filteredData={filteredData}
          searchId={searchId}
          timePlotterId={timePlot.id}
          viewMode="timePlotterSummary"
          chartStyles={{
            lineStrokeWidth: 3,
          }}
          chartSettings={{
            hasBackground: true,
            hasTrendLine: true,
          }}
          axis={{
            yAxisVerticalPadding: { top: 5, bottom: 0 },
          }}
        />
      </div>
    </div>
  );
};
