import React, { CSSProperties, useMemo } from "react";
import classes from "./DataSourceScorePieChart.module.scss";
import { PieChart } from "react-minimal-pie-chart";

enum Color {
  Gold = "#CECE84",
  Blue = "#313B5C",
}

interface Props {
  className?: string;
  style?: CSSProperties;
  title?: string;
  articlesQuantity?: number;
  value?: number;
}

export const DataSourceScorePieChart = ({
  className,
  style,
  title,
  articlesQuantity,
  value = 0,
}: Props) => {
  const data = useMemo(() => {
    const fixedValue = +value.toFixed();

    return [
      {
        value: fixedValue,
        color: Color.Gold,
      },
      {
        value: 100 - fixedValue,
        color: Color.Blue,
      },
    ];
  }, [value]);

  return (
    <div className={className} style={style}>
      {(title || typeof articlesQuantity === "number") && (
        <div className={classes.titleWrapper}>
          <div className={classes.title} title={title}>
            {title}
          </div>
          <div className={classes.articlesQuantity}>
            {typeof articlesQuantity === "number" && (
              <>
                <span>{articlesQuantity.toLocaleString()}</span>
                <span> article{articlesQuantity !== 1 ? "s" : ""}</span>
              </>
            )}
          </div>
        </div>
      )}
      <div className={classes.chartWrapperOuter}>
        <div className={classes.chartWrapperInner}>
          <PieChart
            animate
            animationDuration={500}
            animationEasing="ease-out"
            data={data}
            lineWidth={20}
            label={({ dataEntry, dataIndex }) =>
              dataIndex === 0 ? dataEntry.value + "%" : ""
            }
            labelPosition={0}
            labelStyle={{
              fill: Color.Gold,
              fontSize: 20,
            }}
            startAngle={-90}
          />
        </div>
      </div>
    </div>
  );
};
