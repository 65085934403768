import { FC, useEffect, useMemo, useRef, useState } from "react";
import Table from "rsuite/Table";
import Pagination from "rsuite/Pagination";
import { useTranslation } from "react-i18next";

import styles from "./TreeTable.module.scss";
import "./generalTreeStyles.css";
import { CheckBoxEmpty, CheckBoxPartial, CheckBoxChecked } from "src/icons";
import { sendDataToGTM } from "src/utils";

// Inner imports
import type { TreeTableProps } from "./types";
import {
  CheckCell,
  LineChartCell,
  TotalVolumeCell,
  Loader,
  SearchLinkCell,
} from "./components";
import {
  TABLE_HEIGHT,
  CELL_HEIGHT,
  COLUMN_WIDTH,
  SMALL_COLUMN_WIDTH,
  ELEMENTS_PER_PAGE,
  PAGINATION_PAGE_LIMIT,
  PAGINATION_MAX_BUTTONS,
  PAGINATION_SIZE,
} from "./constants";
import { debounce } from "./utils";

const { HeaderCell, Column } = Table;

const TreeTable: FC<TreeTableProps> = ({
  data,
  isLoading,
  isDataFiltered,
  allKeyWordsCheckType,
  sorting,
  selectedRowIds = {},
  onAllKeyWordsChecked,
  onRowChecked,
}) => {
  const { t } = useTranslation();

  const tableRef = useRef(null);

  const [scrollCoordinates, setScrollCoordinates] = useState({ x: 0, y: 0 });
  const [page, setPage] = useState<number>(1);

  // Clean up pagination page;
  useEffect(() => setPage(1), [data]);

  const paginatedData = useMemo(() => {
    const paginatedData = data.filter((v, i) => {
      const limit = ELEMENTS_PER_PAGE;
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });

    return paginatedData;
  }, [data, page]);

  const AllKeyWordsCheckIcon: JSX.Element = useMemo(() => {
    if (isLoading) return <CheckBoxEmpty />;

    switch (allKeyWordsCheckType) {
      case "checked":
        return <CheckBoxChecked />;
      case "partial":
        return <CheckBoxPartial />;
      default:
        return <CheckBoxEmpty />;
    }
  }, [isLoading, allKeyWordsCheckType]);
  // [DEV-78454] Keywords block - temporary removed empty table placeholder due
  // to small bug in rsuite Table component.
  // const emptyTableText: string = useMemo(
  //   () =>
  //     t(
  //       isDataFiltered
  //         ? "dc_keywords_table_filtered_empty_placeholder"
  //         : "dc_keywords_table_empty_placeholder",
  //     ),
  //   [t, isDataFiltered],
  // );

  function handleSortColumn(dataKey: string, sortType?: "desc" | "asc") {
    sortType && sorting?.onSortingChanged(dataKey, sortType);
  }

  function onPageChangeHandler(pageNumber: number) {
    setScrollCoordinates({ x: 0, y: 0 });
    setPage(pageNumber);

    sendDataToGTM("UserProceedsToKeywordsPage");
  }

  function onScrollHandler(x: number, y: number) {
    debounce.call(debounce, callBack, 300);

    function callBack() {
      setScrollCoordinates({ x, y });
    }
  }

  return (
    <div className={styles.table}>
      <div className={styles.tableWrapper} ref={tableRef}>
        <Table
          isTree
          virtualized
          height={TABLE_HEIGHT}
          data={paginatedData}
          renderLoading={() => <Loader />}
          loading={isLoading}
          rowHeight={CELL_HEIGHT}
          headerHeight={CELL_HEIGHT}
          onSortColumn={handleSortColumn}
          rowKey="value"
          sortColumn={sorting?.sortColumn}
          sortType={sorting?.sortType}
          shouldUpdateScroll={() => scrollCoordinates}
          onScroll={onScrollHandler}
          renderEmpty={() => null}
        >
          <Column
            align="left"
            flexGrow={1}
            minWidth={COLUMN_WIDTH}
            treeCol
            key="value"
          >
            <HeaderCell>
              <div className={styles.allKeyWordsCheckBox}>
                <button
                  onClick={onAllKeyWordsChecked}
                  disabled={isDataFiltered}
                >
                  {AllKeyWordsCheckIcon}
                </button>
              </div>
              <div>{t("dc_keywords_table_keywords_cell_title")}</div>
            </HeaderCell>
            <CheckCell
              dataKey="value"
              checkedKeys={selectedRowIds}
              onChecked={onRowChecked}
            />
          </Column>
          <Column width={COLUMN_WIDTH} align="right" key="totalVolume">
            <HeaderCell>
              <div className={styles.totalVolumeWrapper}>
                <span>{t("dc_keywords_table_volume_cell_title")}</span>
                <div className={styles.tip}>
                  <div id="key-words-selector-tip-section" />
                </div>
              </div>
            </HeaderCell>
            <TotalVolumeCell dataKey={"totalVolume"} />
          </Column>
          <Column width={COLUMN_WIDTH}>
            <HeaderCell>{""}</HeaderCell>
            <LineChartCell dataKey={"lineChart"} ref={tableRef} />
          </Column>
          <Column key="searchLink" width={SMALL_COLUMN_WIDTH}>
            <HeaderCell>{""}</HeaderCell>
            <SearchLinkCell className={styles.tableLink} dataKey="value" />
          </Column>
          <Column key="searchLink" width={SMALL_COLUMN_WIDTH}>
            <HeaderCell>{""}</HeaderCell>
            <SearchLinkCell
              className={styles.tableLink}
              dataKey="value"
              isImageSearch
            />
          </Column>
        </Table>
      </div>

      <div className={styles.paginationWrapper}>
        <Pagination
          prev
          next
          ellipsis
          boundaryLinks
          maxButtons={PAGINATION_MAX_BUTTONS}
          size={PAGINATION_SIZE}
          layout={["pager"]}
          total={data.length}
          limit={PAGINATION_PAGE_LIMIT}
          activePage={page}
          onChangePage={onPageChangeHandler}
          className={styles.pagination}
        />
      </div>
    </div>
  );
};

export { TreeTable };
