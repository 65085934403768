import { useMemo } from "react";
import { useSelector } from "react-redux";

type Props = {
  searchesIds: string[];
  widgetId?: string;
};

const useCompetitorsPresence = ({
  searchesIds,
  widgetId,
}: Props): {
  [searchId: string]: {
    isSearchHasCompetitors?: boolean;
    isAtLeastOneSearchWidgetHasCompetitors?: boolean;
    isWidgetHasCompetitors?: boolean;
    isCompetitorsPresent?: boolean;
  };
} => {
  const searches: Store.Search[] = useSelector(
    ({ searches }: RootState) => searches,
  );

  const widgets: { [searchId: string]: Store.Widgets } = useSelector(
    ({ widgets }: RootState) => widgets,
  );

  const selectedSearches: Store.Search[] = useMemo(
    () => searches.filter(({ id }) => searchesIds.includes(id)),
    [searches, searchesIds],
  );

  return searchesIds.reduce((acc, searchId) => {
    const search = selectedSearches.find(({ id }) => id === searchId);

    const searchWidgets = widgets[searchId];

    return {
      ...acc,
      [searchId]: getCompetitorsInfo({ search, searchWidgets, widgetId }),
    };
  }, {});
};

function checkWidgetCompetitors(
  widget?: Store.Widgets[keyof Store.Widgets],
): boolean | undefined {
  if (!widget) return;

  if ("brands" in widget) return widget?.brands?.length > 1;

  if (Array.isArray(widget)) return widget?.length > 1;

  if (typeof widget === "object")
    return Object.values(widget || {}).some(
      (data) => (data?.brandValues?.length || 0) > 1,
    );

  return;
}

function getCompetitorsInfo({
  search,
  searchWidgets,
  widgetId,
}: {
  search?: Store.Search;
  searchWidgets?: Store.Widgets;
  widgetId?: string;
}) {
  const isSearchHasCompetitors = search
    ? (search.brandsTableNames || []).length > 1
    : undefined;

  const isAtLeastOneSearchWidgetHasCompetitors = searchWidgets
    ? Object.values(searchWidgets).some(checkWidgetCompetitors)
    : undefined;

  const isWidgetHasCompetitors =
    searchWidgets && widgetId
      ? checkWidgetCompetitors(searchWidgets[widgetId as keyof Store.Widgets])
      : undefined;

  const isCompetitorsPresent =
    isSearchHasCompetitors ??
    isAtLeastOneSearchWidgetHasCompetitors ??
    isWidgetHasCompetitors;

  return {
    isSearchHasCompetitors,
    isAtLeastOneSearchWidgetHasCompetitors,
    isWidgetHasCompetitors,
    isCompetitorsPresent,
  };
}

export { useCompetitorsPresence };
