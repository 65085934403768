import { useState, useMemo } from "react";
import styles from "./EsgSentimentLineChartView.module.scss";
import { LineChart } from "../../../../../components";
import esgParameters from "../../../../../data/esgParameters";
import { useTranslation } from "react-i18next";

const filters = ["positive", "negative", "neutral"] as const;

interface Props {
  data: EsgSentiment.LineChart.Data;
  searchId: string;
  dashboardId?: string;
}

const EsgSentimentLineChart = ({ data, searchId, dashboardId = "" }: Props) => {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState<typeof filters[number]>(
    filters[0],
  );

  const chartData = useMemo(
    () =>
      data?.map(({ date, values }) => ({
        date,
        values: Object.fromEntries(
          Object.entries(values).map(([label, value]) => [
            label,
            value[currentFilter],
          ]),
        ),
      })),
    [data, currentFilter],
  );

  const getFilterName = (filter: typeof filters[number]) => {
    switch (filter) {
      case "positive":
        return t("w_esg_s_positive_label");
      case "negative":
        return t("w_esg_s_negative_label");
      case "neutral":
        return t("w_esg_s_neutral_label");
      default:
        return filter;
    }
  };

  return (
    <div className={styles.esgSentimentLineChart}>
      <div className={styles.filters}>
        {filters.map((filter, i) => (
          <div onClick={() => setCurrentFilter(filter)} key={i}>
            <span style={{ opacity: filter === currentFilter ? 1 : 0.5 }}>
              {getFilterName(filter)}
            </span>
          </div>
        ))}
      </div>
      <LineChart
        className={styles.lineChart}
        labels={esgParameters}
        data={chartData}
        dashboardId={dashboardId}
        searchId={searchId}
        chartStyles={{
          graphHeight: 438,
          lineStrokeWidth: 4,
        }}
        axis={{
          yAxisVerticalPadding: { top: 10, bottom: 0 },
        }}
      />
    </div>
  );
};

export default EsgSentimentLineChart;
