import styles from "./KeyWordButton.module.scss";
import cx from "classnames";
import { AiOutlineMinusCircle as Minus } from "react-icons/ai";

type Props = {
  text: string;
  id?: string;
  excluded?: boolean;
  className?: string;
  clickHandler: ({ id, text }: { id?: string; text: string }) => void;
};

export const KeyWordButton = ({
  text,
  id,
  excluded = false,
  className = "",
  clickHandler,
}: Props) => {
  return (
    <div
      className={cx(
        styles.wordWrapper,
        excluded ? styles.excluded : "",
        className,
      )}
    >
      <p className={styles.text}>{text}</p>
      <button
        onClick={() => {
          clickHandler({ id, text });
        }}
        className={styles.button}
      >
        <Minus size={20} />
      </button>
    </div>
  );
};
