import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import context from "../../../context";
import { ConfirmModalWrapper } from "../../../components";

const DiscardModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setModalElement } = useContext(context);

  const handleOK = () => {
    history.block(() => {});
    history.goBack();
    setModalElement(null);
  };

  const handleCancel = () => {
    setModalElement(null);
  };

  return (
    <ConfirmModalWrapper
      type="error"
      acceptButton={{
        text: t("dcmw_ok_btn"),
        onClicked: () => handleOK(),
      }}
      cancelButton={{
        text: t("dcmw_cancel_btn"),
        onClicked: () => handleCancel(),
      }}
      title={t("dcmw_title")}
    >
      <p>{t("dcmw_text")}</p>
    </ConfirmModalWrapper>
  );
};

export { DiscardModal };
