import actionTypes from "../actionTypes";

export type SetDashboardCustomDataAction = {
  type: typeof actionTypes.SET_DASHBOARD_CUSTOM_DATA;
  dashboardId: string;
  customData: Store.DashboardCustomData;
};

export type SetCustomWidgetText = {
  type: typeof actionTypes.SET_CUSTOM_WIDGET_TEXT;
  dashboardId: string;
  searchId: string;
  widgetId: string;
  settings: { [setting: string]: string };
};

type ReducerActions = SetDashboardCustomDataAction | SetCustomWidgetText;

const initialState: Store.DashboardsCustomData = {};

const setDashboardCustomData = (
  state: Store.DashboardsCustomData,
  action: any,
) => {
  const { dashboardId, customData } = action;
  return {
    ...state,
    [dashboardId]: customData,
  };
};

const setCustomWidgetSettings = (
  state: Store.DashboardsCustomData,
  action: any,
) => {
  const { dashboardId, searchId, widgetId, settings } = action;
  return {
    ...state,
    [dashboardId]: {
      ...state?.[dashboardId],
      [widgetId]: {
        ...state?.[dashboardId]?.[widgetId],
        [searchId]: settings,
      },
    },
  };
};

const reducer = (state = initialState, action: ReducerActions) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_CUSTOM_DATA:
      return setDashboardCustomData(state, action);
    case actionTypes.SET_CUSTOM_WIDGET_TEXT:
      return setCustomWidgetSettings(state, action);
    default:
      return state;
  }
};

export default reducer;
