const WIDGET_ID = "result-key-areas";

const WIDGET_COLLECTION_ID = "result-business-drivers";

const DATE_RANGES = [
  "oneMonth",
  "twoMonths",
  "threeMonths",
  "sixMonths",
  "oneYear",
] as const;

export { WIDGET_ID, DATE_RANGES, WIDGET_COLLECTION_ID };
