import { useContext, memo } from "react";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import styles from "./DownloadExcelButton.module.scss";
import context from "../../../../context";
import { ButtonWithTransparentBackground } from "../../../../components";
import { downloadCSV } from "../../../../store/widgets/api";
import { showToastNotification } from "../../../ToastNotification/utils";
import { sendDataToGTM } from "../../../../utils";

// Inner imports
import { DownloadExcelButtonProps } from "./types";

export const DownloadExcelButton = memo(
  ({ searchId, widgetName, fileName }: DownloadExcelButtonProps) => {
    const { t } = useTranslation();
    const { setIsGlobalPreloaderShown } = useContext(context);

    const clickHandler = async () => {
      try {
        setIsGlobalPreloaderShown(true);

        const excel = await downloadCSV(searchId, widgetName);

        if (widgetName) {
          sendDataToGTM("UserDownloadedExcel", {
            downloadedExcelName: widgetName,
          });
        }

        const blob = new Blob([excel.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, `${fileName || widgetName}.xlsx`);
      } catch (err) {
        console.error(err);
        showToastNotification({
          type: "error",
          text: t("request_error"),
        });
      } finally {
        setIsGlobalPreloaderShown(false);
      }
    };

    return (
      <ButtonWithTransparentBackground
        className={styles.reviewDataWrapper}
        text={t("download_excel_button")}
        icon="DownloadArrow"
        handleClick={clickHandler}
      />
    );
  },
);
