import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./TimePlotterPlaceholder.module.scss";
import { LineChartEmptyData } from "../../../icons";

type Props = {
  text?: string;
};

export const TimePlotterPlaceholder: FC<Props> = ({ text }) => {
  const { t } = useTranslation();

  const timePlotterText =
    text || t("event_line_chart_time_plotter_placeholder");

  return (
    <div className={styles.timePlotterPlaceholder}>
      <div className={styles.timePlotterPlaceholderIcon}>
        <LineChartEmptyData />
      </div>
      <span className={styles.timePlotterPlaceholderText}>
        {timePlotterText}
      </span>
    </div>
  );
};
