import * as api from "./api";
import actionTypes from "../actionTypes";

export const setTimePlots = ({
  timePlotData,
  status,
  isShownInLineChart,
}: {
  timePlotData?: Store.TimePlot[];
  status?: string;
  isShownInLineChart?: boolean;
}) => ({
  type: actionTypes.SET_TIME_PLOTS,
  timePlotData,
  status,
  isShownInLineChart,
});

export const readTimePlots = (companyId?: string) => async (
  dispatch: Function,
  getState: Function,
): Promise<void> => {
  const { company } = getState() as Store.State;

  const newTimePlots = await api.getTimePlots(companyId || company.id);

  dispatch(
    setTimePlots({
      timePlotData: newTimePlots,
      status: "succeeded",
    }),
  );
};

export const createTimePlot = (
  timePlotter: Omit<Store.TimePlot, "id" | "isActive" | "createdAt">,
) => async (dispatch: Function, getState: Function) => {
  const {
    timePlots: { data },
    company,
    user,
  } = getState() as Store.State;

  const newTimePlotter = {
    ...timePlotter,
    companyId: company.id,
    userId: user.id,
    isActive: true,
  };

  const createdTimePlotter = await api.addTimePlot(newTimePlotter);

  dispatch(
    setTimePlots({
      timePlotData: [...data, createdTimePlotter],
      status: "succeeded",
    }),
  );

  return createdTimePlotter.id;
};

export const updateTimePlot = (
  id: string,
  _changes: Partial<Omit<Store.TimePlot, "id" | "createdAt">>,
  updateTimestamp: boolean = true,
) => async (dispatch: Function, getState: Function) => {
  const {
    timePlots: { data },
  } = getState() as Store.State;

  const existingTimePlotter = data.find((timePlotter) => timePlotter.id === id);

  if (!existingTimePlotter || !existingTimePlotter.searchId) return;

  const changes = {
    ..._changes,
    ...(updateTimestamp ? { updatedAt: new Date().toISOString() } : {}),
  };

  await api.updateTimePlot(id, changes);

  dispatch(
    setTimePlots({
      timePlotData: data.map((timePlotter) =>
        timePlotter.id === id ? { ...timePlotter, ...changes } : timePlotter,
      ),
      status: "succeeded",
    }),
  );
};

export const deleteTimePlot = (timePlotId: Store.TimePlot["id"]) => async (
  dispatch: Function,
  getState: Function,
) => {
  const {
    timePlots: { data },
  } = getState() as Store.State;

  await api.deleteTimePlot(timePlotId);

  dispatch(
    setTimePlots({
      timePlotData: data.filter(({ id }) => id !== timePlotId),
      status: "succeeded",
    }),
  );
};

export const toggleIsTimePlottersShown = () => async (
  dispatch: Function,
  getState: Function,
) => {
  const {
    timePlots: { isShownInLineChart },
  } = getState() as Store.State;

  dispatch(
    setTimePlots({
      isShownInLineChart: !isShownInLineChart,
    }),
  );
};
