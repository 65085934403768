import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./WidgetButtonsBar.module.scss";
import { MonthPicker, BrandSelector } from "../../components";

// Inner imports
import type { WidgetButtonsBarProps } from "./types";
import {
  ChartButtonsBlock,
  TimePeriodSelector,
  TimePeriodTypeSelector,
} from "./components";

export const WidgetButtonsBar: FC<WidgetButtonsBarProps> = ({
  className = "",
  options,
  activeChartsButtonId,
  timePeriodSelector,
  timePeriodTypeSelector,
  monthRangePicker,
  brandSelector,
}) => {
  const isChartButtonsBlockShown: boolean = useMemo(
    () => !!options && options.length > 1 && !!activeChartsButtonId,
    [activeChartsButtonId, options],
  );

  const isWidgetButtonsBarEmpty: boolean = useMemo(
    () =>
      !isChartButtonsBlockShown &&
      !monthRangePicker &&
      !timePeriodSelector &&
      !brandSelector,
    [
      brandSelector,
      isChartButtonsBlockShown,
      monthRangePicker,
      timePeriodSelector,
    ],
  );

  const isRightSideShown: boolean = !!(
    monthRangePicker ||
    timePeriodSelector ||
    brandSelector
  );

  const isBrandsSelectorShown: boolean =
    (brandSelector?.brands?.length || 0) > 1;

  if (isWidgetButtonsBarEmpty) return null;

  return (
    <div className={cx(styles.widgetButtonsBar, className)}>
      {isChartButtonsBlockShown && (
        <ChartButtonsBlock
          options={options!}
          activeChartsButtonId={activeChartsButtonId!}
        />
      )}
      {isRightSideShown && (
        <div className={styles.rightSide}>
          {brandSelector && isBrandsSelectorShown && (
            <BrandSelector {...brandSelector} />
          )}
          {timePeriodTypeSelector && (
            <TimePeriodTypeSelector {...timePeriodTypeSelector} />
          )}
          {monthRangePicker && <MonthPicker {...monthRangePicker} />}
          {timePeriodSelector && <TimePeriodSelector {...timePeriodSelector} />}
        </div>
      )}
    </div>
  );
};
