import type { TFunction } from "react-i18next";

const formatDataSourceScoreCellChartTitle = (
  title: keyof Robustness.Item,
  t: TFunction,
) => {
  switch (title) {
    case "blogs":
      return t("w_dd_blogs_label");
    case "search":
      return t("w_dd_search_label");
    case "videos":
      return t("w_dd_videos_label");
    case "robustness":
      return t("w_dd_robustness_label");
    case "forums":
      return t("w_dd_forums_label");
    case "newsAndEditorial":
      return t("w_dd_newsAndEditorial_label");
    case "productReviews":
      return t("w_dd_productReviews_label");
    case "socialNetworks":
      return t("w_dd_socialNetworks_label");
    default:
      return title;
  }
};

export { formatDataSourceScoreCellChartTitle };
