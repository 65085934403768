import { useMemo } from "react";

import classes from "./BusinessDriversBarChart.module.scss";
import { BarChart } from "../../../../../components";
import businessDriversParameters from "../../../../../data/businessDriversParameters";

// Inner imports
import { BUSINESS_DRIVERS_DATE_RANGERS_TYPE } from "../../types";

interface Props {
  data: BusinessDrivers.BarChart.Data;
  dateRange: BUSINESS_DRIVERS_DATE_RANGERS_TYPE;
}

export const BusinessDriversBarChart = ({ data, dateRange }: Props) => {
  const rangeData = useMemo(() => {
    if (dateRange && dateRange !== "allTime") {
      return data[dateRange]!;
    }
  }, [data, dateRange]);

  const modifiedData = useMemo(
    () =>
      businessDriversParameters.map(({ name }) => ({
        label: name,
        value: rangeData?.values[name] || 0,
      })),
    [rangeData],
  );

  return (
    <div className={classes.businessDriversBarChart}>
      <BarChart className={classes.barChart} data={modifiedData} />
    </div>
  );
};
