import firebase from "firebase/app";
import * as yup from "yup";
import { Folder } from "./foldersSlice";

const folderSchema = yup
  .object({
    name: yup.string().required(),
    dashboardIds: yup.array(yup.string().required()).required(),
    createdAt: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown();

export async function createFolder(
  payload: Omit<Folder, "id" | "createdAt" | "updatedAt"> & {
    userId: string;
  },
) {
  let createdAt, updatedAt;

  createdAt = updatedAt = new Date().toISOString();

  const doc = await firebase.firestore().collection("folders").add({
    name: payload.name,
    dashboardIds: payload.dashboardIds,
    userId: payload.userId,
    createdAt,
    updatedAt,
  });

  return {
    id: doc.id,
    createdAt,
    updatedAt,
  };
}

export async function readFolders(userId: string) {
  const collection = await firebase
    .firestore()
    .collection("folders")
    .where("userId", "==", userId)
    .get();

  return collection.docs.reduce<Folder[]>((acc, doc) => {
    try {
      const validatedData = folderSchema.validateSync(doc.data());
      acc.push({ id: doc.id, ...validatedData });
    } catch (error) {
      console.dir(error);
    }

    return acc;
  }, []);
}

export async function updateFolder({ id, changes }: UpdateEntity<Folder>) {
  changes = {
    updatedAt: new Date().toISOString(),
    ...changes,
  };

  await firebase.firestore().collection("folders").doc(id).update(changes);
}

export async function deleteFolder(id: Folder["id"]) {
  await firebase.firestore().collection("folders").doc(id).delete();
}
