export const getValueRelativePercentage = (value: number, values: number[]) => {
  const PERCENTAGE_BORDER = 15;
  let minValue = Math.min(...values);
  let maxValue = Math.max(...values);
  if (minValue === maxValue) {
    minValue--;
    maxValue++;
  }
  const result =
    PERCENTAGE_BORDER +
    ((value - minValue) * (100 - PERCENTAGE_BORDER * 2)) /
      (maxValue - minValue);
  return +result.toFixed();
};
