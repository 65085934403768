import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./TimePeriodTypeSelector.module.scss";
import { Select } from "src/components";
import { BookDemo } from "src/icons";

// Inner imports
import type { TimePeriodTypeSelectorType } from "../../WidgetButtonsBar/types";

export const TimePeriodTypeSelector: FC<TimePeriodTypeSelectorType> = ({
  periodTypes = [],
  currentPeriodType = "",
  onPeriodTypeClicked,
}) => {
  const { t } = useTranslation();

  const currentPeriodLabel = useMemo<string>(
    () =>
      periodTypes.find(({ value }) => value === currentPeriodType)?.value || "",
    [periodTypes, currentPeriodType],
  );

  if (!periodTypes.length) return null;

  const title = t("time_period_type_selector");

  return (
    <div className={styles.timePeriodTypeSelector}>
      <p className={styles.title}>{title}</p>
      <Select
        icon={<BookDemo />}
        title={title}
        options={periodTypes}
        value={currentPeriodLabel}
        changeHandler={onPeriodTypeClicked}
      />
    </div>
  );
};
