import { useState } from "react";
import classes from "./RadarChart.module.scss";
import {
  ResponsiveContainer,
  RadarChart as RadarChartWrapper,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Tooltip,
} from "recharts";
import { toggleArrayValue } from "../../../utils/index";
import COLORS from "../../../data/colors";
import useResizeObserver from "use-resize-observer";

const legendPositions = [
  { top: -24 },
  { right: -96 },
  { bottom: -24 },
  { left: -96 },
] as const;

interface Props {
  className?: string;
  style?: object;
  brands?: Store.Brands;
  data?: {
    attribute: string;
    values: {
      [brandId: string]: number;
    };
    words: string[];
  }[];
  fitTo?: "width" | "height";
}

export const RadarChart = ({
  className = "",
  style = {},
  brands = [],
  data = [],
  fitTo = "width",
}: Props) => {
  const [inactiveBrandNames, setInactiveBrandNames] = useState<string[]>([]);

  const shownBrands = brands.filter(({ id: brandId }) =>
    data.some((item) => brandId in item.values),
  );

  const modifiedData = data.map(({ attribute, values, words }) => {
    const shownValues: { [brandId: string]: number } = {};
    const shownBrandIds = shownBrands.map((brand) => brand.id);
    Object.keys(values).forEach((key) => {
      if (shownBrandIds.includes(key)) {
        const brandName = shownBrands.find((brand) => brand.id === key)?.name;
        const value = values[key];
        if (brandName !== undefined && value !== undefined)
          shownValues[brandName] = +value.toFixed(3);
      }
    });
    return { attribute, ...shownValues, words };
  });

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 16,
    height: chartWrapperHeight = 16,
  } = useResizeObserver<HTMLDivElement>();

  const size =
    fitTo === "width"
      ? "100%"
      : Math.min(chartWrapperHeight, chartWrapperWidth);

  return shownBrands.length ? (
    <div className={`${classes.radarChart} ${className}`} style={style}>
      <div className={classes.labels}>
        {shownBrands.map(({ name }, i) => (
          <div
            className={`${classes.label} ${
              inactiveBrandNames.includes(name) ? classes.labelInactive : ""
            }`}
            title={name}
            onClick={() =>
              setInactiveBrandNames(toggleArrayValue(inactiveBrandNames, name))
            }
            key={i}
          >
            <div style={{ backgroundColor: COLORS[i] }} />
            <div>{name}</div>
          </div>
        ))}
      </div>
      <div className={classes.chartWrapperOuter}>
        <div
          ref={chartWrapperRef}
          className={classes.chartWrapper}
          style={
            fitTo === "width" ? { paddingBottom: "100%" } : { height: "100%" }
          }
        >
          <div
            className={classes.chartWrapperInner}
            style={{
              height: size,
              width: size,
            }}
          >
            <ResponsiveContainer>
              <RadarChartWrapper
                outerRadius="100%"
                data={modifiedData}
                style={{ cursor: "inherit" }}
              >
                <PolarGrid stroke="#707070" opacity={0.5} />
                <PolarAngleAxis dataKey="attribute" tick={false} />
                <Tooltip
                  contentStyle={{ border: "none", backgroundColor: "#efe9ea" }}
                  labelStyle={{
                    marginBottom: 4,
                    color: "#20263b",
                    fontSize: 14,
                  }}
                  itemStyle={{ padding: 0, color: "#20263b", fontSize: 12 }}
                  cursor={{ stroke: "#707070", strokeWidth: 1 }}
                />
                {shownBrands.map((brand, i) => (
                  <Radar
                    style={
                      inactiveBrandNames.includes(brand.name)
                        ? { display: "none" }
                        : undefined
                    }
                    dataKey={brand.name}
                    stroke={COLORS[i]}
                    strokeWidth={2}
                    fillOpacity={0}
                    key={i}
                  />
                ))}
              </RadarChartWrapper>
            </ResponsiveContainer>
            {modifiedData.map(({ attribute, words }, i) => (
              <div
                className={classes.legendWrapper}
                style={{ ...legendPositions[i] }}
                key={i}
              >
                <div className={classes.legend} title={attribute}>
                  {attribute}
                </div>
                <div className={classes.words}>
                  {words.length
                    ? words.map((word, k) => (
                        <div className={classes.word} title={word} key={k}>
                          {k + 1}. {word}
                        </div>
                      ))
                    : "No related words"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
