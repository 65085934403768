import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./SentimentTrends.module.scss";
import {
  Marketometer,
  LineChart,
  Preloader,
  WidgetFooterButtons,
  WidgetButtonsBar,
  AboutThis,
  ErrorPlaceholder,
} from "../../../components";
import DATE_RANGES_WITH_OVERALL from "../../../data/dateRangesWithOverall";

import { useNameForDownloadFiles } from "../../../hooks";
import { parseRangeData } from "../../../components/widgets/Marketometer/utils";
import DATE_RANGES from "../../../data/dateRanges";

// Inner imports
import { prepareLineChartDateRanges } from "./utils";
import type { MarketometerDataItem, DATE_RANGES_TYPE } from "./types";
import { DEFAULT_DATE_RANGE, WIDGET_ID } from "./constants";
import { getCompetitivenessDescriptionInsertion } from "../../../utils";
import { WidgetChart } from "../../types";
import { useWidgetFetching, useWidgetView } from "../../hooks";

export const SentimentTrends: FC<WidgetsView.Page> = ({
  searchId,
  redirectLink,
  dashboardId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { widgetData, isLoading } = useWidgetFetching(searchId, WIDGET_ID);

  const isWidgetCalculating = useSelector(({ widgetsState }: Store.State) => {
    const { isCalculating } = widgetsState?.[searchId]?.[WIDGET_ID] || {};

    return Boolean(isCalculating);
  });

  useEffect(() => {
    if (isWidgetCalculating) {
      !!redirectLink && history.push(redirectLink);
    }
  }, [isWidgetCalculating, redirectLink, history]);

  // Date Range ---->
  const [currentDateRange, setCurrentDateRange] = useState<DATE_RANGES_TYPE>(
    undefined,
  );
  // <---- Date Range

  // Widget views ---->

  const charts: WidgetChart[] = useMemo(() => {
    const charts: WidgetChart[] = [];

    const { speedometerChart, lineChart, brands } = widgetData || {};

    if (speedometerChart) {
      const isDataExist =
        speedometerChart[
          "oneMonthAgo" ||
            "twoMonthsAgo" ||
            "threeMonthsAgo" ||
            "sixMonthsAgo" ||
            "oneYearAgo"
        ];

      if (isDataExist) {
        charts.push({
          type: "speedometer",
          chart: <Marketometer currentDateRange={currentDateRange} />,
        });
      }
    }

    if (lineChart && brands) {
      const isDataExist = !!lineChart[
        "oneYear" ||
          "overall" ||
          "sixMonth" ||
          "threeMonths" ||
          "twoMonths" ||
          "oneMonth"
      ]?.length;

      if (isDataExist) {
        charts.push({
          type: "lineChart",
          chart: (
            <LineChart
              className={styles.lineChart}
              data={
                widgetData?.lineChart?.[currentDateRange || DEFAULT_DATE_RANGE]
              }
              dashboardId={dashboardId}
              searchId={searchId}
              chartStyles={{
                lineStrokeWidth: 4,
              }}
              chartSettings={{
                hasBackground: true,
                hasTrendLine: true,
              }}
              axis={{
                yAxisLegend: t("w_sentiment_trend_linechart_legend"),
                yAxisVerticalPadding: { top: 10, bottom: 0 },
              }}
            />
          ),
        });
      }
    }

    return charts;
  }, [widgetData, currentDateRange, dashboardId, searchId, t]);

  const { widgetViewsOptions, selectedView } = useWidgetView({
    charts,
    searchId,
    widgetId: WIDGET_ID,
    dashboardId,
  });

  const { id: viewId, type: viewType, chart: viewElement } = selectedView || {};

  const isEmptyWidget = !isLoading && !charts.length;
  // <---- Widget views

  // Init dates ranges ---->
  const dateRanges = useMemo(() => {
    const { brands, speedometerChart, lineChart } = widgetData || {};

    if (viewType === "speedometer") {
      if (!speedometerChart || !brands) return [];

      const currentDataItem = speedometerChart.thisMonth;

      return DATE_RANGES_WITH_OVERALL.reduce(
        (acc, dateRange) => {
          const previousDataItem = speedometerChart[dateRange.additionalValue];

          if (previousDataItem) {
            const rangeData = parseRangeData(
              currentDataItem,
              previousDataItem,
              brands,
            );

            if (rangeData.length) {
              acc.push(dateRange);
            }
          }

          return acc;
        },
        [] as {
          value: string;
          additionalValue: string;
          label: string;
        }[],
      );
    }

    if (viewType === "lineChart") {
      return prepareLineChartDateRanges(lineChart);
    }

    return [];
  }, [widgetData, viewType]);

  useEffect(() => {
    const isDateRangesInitialized = !!dateRanges.length;
    if (isDateRangesInitialized) {
      const biggestAvailablePeriod = dateRanges[dateRanges.length - 1]
        ?.value as DATE_RANGES_TYPE;

      const isDefaultExist = dateRanges.some(
        ({ value }) => value === DEFAULT_DATE_RANGE,
      );

      const initialDateRange = isDefaultExist
        ? DEFAULT_DATE_RANGE
        : biggestAvailablePeriod;
      setCurrentDateRange(initialDateRange);
    }
  }, [dateRanges]);
  // <---- Init dates ranges

  // ----> Descriptions
  const firstBrand = widgetData?.brands[0];

  const marketometerDateRanges = useMemo(() => {
    const { brands, speedometerChart } = widgetData || {};

    if (!speedometerChart || !brands) return [];

    const currentDataItem = speedometerChart.thisMonth;

    return DATE_RANGES.reduce(
      (acc, { label, additionalValue }) => {
        const previousDataItem = speedometerChart[additionalValue];

        if (previousDataItem) {
          const rangeData = parseRangeData(
            currentDataItem,
            previousDataItem,
            brands,
          );

          if (rangeData.length) {
            acc.push({
              label,
              currentDataItem,
              previousDataItem,
              widgetData: rangeData,
            });
          }
        }

        return acc;
      },
      [] as {
        label: string;
        currentDataItem: MarketometerDataItem;
        previousDataItem: MarketometerDataItem;
        widgetData: ReturnType<typeof parseRangeData>;
      }[],
    );
  }, [widgetData]);

  const lastAvailablePeriod = marketometerDateRanges[0];

  const descriptionInsertion = getCompetitivenessDescriptionInsertion(
    widgetData?.lineChart?.[currentDateRange || DEFAULT_DATE_RANGE]?.map(
      (item) => item.values[firstBrand!.name],
    ),
  );

  const conclusion = useMemo(() => {
    if (viewType === "speedometer") {
      return !!lastAvailablePeriod
        ? t("marketometer_main_tip", {
            mainBrandName: firstBrand!.name,
            wonOrDropped:
              lastAvailablePeriod.widgetData[0]!.values.delta >= 0
                ? "won"
                : "dropped",
            pointsQuantity: Math.abs(
              lastAvailablePeriod.widgetData[0]!.values.delta,
            ),
            pluralOrSingular:
              Math.abs(lastAvailablePeriod.widgetData[0]!.values.delta) !== 1 &&
              "s",
          })
        : "";
    }

    if (viewType === "lineChart") {
      return descriptionInsertion
        ? t("w_sentiment_trend_main_tip", {
            mainBrandName: firstBrand!.name,
            descriptionInsertion: descriptionInsertion[1],
          })
        : "";
    }
  }, [descriptionInsertion, firstBrand, lastAvailablePeriod, t, viewType]);
  // <---- Descriptions

  // Download name ---->
  const downloadedFileName = useNameForDownloadFiles({
    searchId,
    widgetId: WIDGET_ID,
  });
  // <---- Download name

  const widgetRef = useRef<HTMLDivElement>(null);

  if (isEmptyWidget) return <ErrorPlaceholder />;

  return (
    <div className={cx(styles.widget, styles.sentimentTrend)}>
      <div className={styles.content}>
        <WidgetButtonsBar
          className={styles.widgetButtonsBar}
          options={widgetViewsOptions}
          activeChartsButtonId={viewId}
          timePeriodSelector={{
            periods: dateRanges,
            currentPeriodValue: currentDateRange || "",
            onPeriodClicked: (value) =>
              setCurrentDateRange(value as typeof currentDateRange),
          }}
        />
        <div className={styles.widgetWrapperOuter}>
          <div ref={widgetRef} className={styles.widgetWrapper}>
            <>
              {isLoading ? (
                <Preloader className={styles.preloader} />
              ) : (
                viewElement
              )}
            </>
          </div>
          <WidgetFooterButtons
            ref={widgetRef}
            searchId={searchId}
            downloadImageButtonProps={{
              imageName: downloadedFileName,
              widgetId: WIDGET_ID,
            }}
            downloadExcelButtonProps={{
              widgetName: "sentiment-trends",
              fileName: downloadedFileName,
            }}
          />
        </div>
        <AboutThis
          widgetId={WIDGET_ID}
          conclusion={conclusion}
          widgetView={viewType}
        />
      </div>
    </div>
  );
};
