import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";

import actionTypes from "../actionTypes";
import { deleteUser, getCompany } from "./api";
import { setSearches } from "../actions";
import { getUserEmailsByIds } from "../api";

// MUTATIONS
export const setCompanyData = (companyData: Partial<Store.Company>) => {
  return {
    type: actionTypes.SET_COMPANY_DATA,
    companyData,
  };
};

export const setCompanyMembersEmail = (members: Store.Company["members"]) => {
  return {
    type: actionTypes.SET_COMPANY_MEMBERS_EMAIL,
    members,
  };
};

export const removeCompanyData = () => {
  return {
    type: actionTypes.REMOVE_COMPANY_DATA,
  };
};

// ACTIONS
export const readCompany = (
  companyId: string,
): ThunkAction<Promise<void>, Store.State, unknown, AnyAction> => async (
  dispatch,
) => {
  const companyData = await getCompany(companyId);

  if (!companyData) return;

  const {
    name,
    enterpriseDashboards: companyEnterpriseDashboards,
    team,
    marketCode,
    customDashboardLimit,
    customSearchesLimit,
    subscriptionPlanId,
    _timestamps,
    workFor,
    primaryFocus,
  } = companyData;

  const enterpriseDashboards: Store.Company["enterpriseDashboards"] = [];

  if (Array.isArray(companyEnterpriseDashboards)) {
    companyEnterpriseDashboards.forEach(({ id, name }) => {
      if (typeof id !== "string" || typeof name !== "string") return;

      enterpriseDashboards.push({ id, name });
    });
  }

  const members: Store.Member[] = [];

  for (const member of Object.entries(team || {})) {
    const [id, memberData]: [string, any] = member;

    if (!memberData || typeof memberData !== "object") return;

    const { fullName, role, isOwner } = memberData;

    const formattedMember = {
      id: String(id),
      fullName: String(fullName ?? ""),
      email: "",
      isAdmin: role === "admin",
      isOwner: Boolean(isOwner),
      createdAt: "",
    };

    members.push(formattedMember);
  }

  dispatch(
    setCompanyData({
      id: companyId,
      name,
      subscriptionPlanId,
      enterpriseDashboards,
      members,
      customDashboardLimit,
      customSearchesLimit,
      createdAt: _timestamps?.createdAt,
      marketCode,
      workFor,
      primaryFocus,
    }),
  );
};

export const readCompanyMembersId = (): Function => {
  return async (dispatch: Function, getState: () => Store.State) => {
    try {
      const { company } = getState();
      const { members } = company;

      const membersId = members.map(({ id }) => id);

      const res = await getUserEmailsByIds(membersId);
      const companyMembers = prepareCompanyMembers(res, members);

      dispatch(setCompanyMembersEmail(companyMembers));
    } catch (err) {
      console.error(err);
    }
  };
};

export const updateCompanyData = (
  payload: Partial<{
    name: string;
    marketCode: string;
  }>,
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { company } = getState() as Store.State;
    const db = firebase.firestore();
    const docRef = db.collection("companies").doc(company.id);
    await docRef.set(payload, { merge: true });
    dispatch(setCompanyData(payload));
  };
};

export const toggleCompanyMemberStatus = (
  member: Store.Company["members"][number],
): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { company } = getState() as Store.State;
    const db = firebase.firestore();
    await db
      .collection("companies")
      .doc(company.id)
      .update({
        [`team.${member.id}.role`]: member.isAdmin ? "employee" : "admin",
      });
    dispatch(
      setCompanyData({
        members: company.members.map((mem) => ({
          ...mem,
          isAdmin: mem.id === member.id ? !mem.isAdmin : mem.isAdmin,
        })),
      }),
    );
  };
};

export const deleteCompanyMember = (memberId: string): Function => {
  return async (dispatch: Function, getState: Function) => {
    const { user, company, searches } = getState() as Store.State;

    await deleteUser(memberId);

    dispatch(
      setSearches(
        searches.map((search) => ({
          ...search,
          owner: {
            id: search.owner.id === memberId ? user.id : search.owner.id,
          },
        })),
      ),
    );

    dispatch(
      setCompanyData({
        members: company.members.filter(({ id }) => id !== memberId),
      }),
    );
  };
};

export const getCompanyById = async (companyId: string) => {
  const db = firebase.firestore();
  const docRef = db.collection("companies").doc(companyId);
  const doc = await docRef.get();

  return doc.data();
};

export const getCompanyIdByUserId = async (userId: string) => {
  let companyId = "";

  const db = firebase.firestore();
  const docRef = db.collection("userProfiles").doc(userId);
  const doc = await docRef.get();

  const data = doc.data();

  if (data) {
    companyId = data?.companies?.[0] || "";
  }

  return companyId;
};

export const checkIfUserCompanyOwner = (state: Store.State) => {
  const { company, user } = state;

  const userId = user.id;

  const isCompanyMemberBrandOwner = company.members.find(
    ({ id }) => id === userId,
  )?.isOwner;
  return Boolean(isCompanyMemberBrandOwner);
};

export const checkIfUserBrandOwnerSelector = (state: Store.State) => {
  return (userId: string) => {
    const { company } = state;
    const isCompanyMemberBrandOwner = company.members.find(
      ({ id }) => id === userId,
    )?.isOwner;
    return Boolean(isCompanyMemberBrandOwner);
  };
};

// utils
function prepareCompanyMembers(
  res: AxiosResponse,
  members: Store.Company["members"],
): Store.Company["members"] {
  const { data } = res;

  if (!Array.isArray(data)) return members;

  const companyMembersEmails = prepareMembersEmailObject(data);

  const mappedMembers = members.map((member) => {
    const { id } = member;
    const memberEmail = companyMembersEmails[id];

    return {
      ...member,
      ...(memberEmail ? { email: memberEmail } : {}),
    };
  });

  function prepareMembersEmailObject(arrayOfMembersEmail: any[]) {
    const membersEmailObject: {
      [memberId: string]: string;
    } = {};

    for (const memberData of arrayOfMembersEmail) {
      try {
        const { id, email } = memberData || {};

        if (typeof id === "string" && typeof email === "string") {
          membersEmailObject[id] = email;
        }
      } catch (err) {
        console.error(err);
      }
    }

    return membersEmailObject;
  }

  return mappedMembers;
}
