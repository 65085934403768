import bird from "../../images/birds/bird.png";
import birds from "../../images/birds/birds.png";
import birdAI from "../../images/birds/bird-AI.png";
import birdAI2 from "../../images/birds/bird-AI_2.png";
import birdWaving from "../../images/birds/bird-waving.png";

// NEW WIDGET IMAGES
import associatedWordsWidget from "../../images/widgetsPreviewImages/associatedWords.png";
import lineChartWidget from "../../images/widgetsPreviewImages/competitiveness.png";
import pieWidget from "../../images/widgetsPreviewImages/attitudePieChart.png";
import competitorsPieWidget from "../../images/widgetsPreviewImages/attitudePieCharts.png";
import awarenessPieCharts from "../../images/widgetsPreviewImages/awareness.png";
import timeLineWidget from "../../images/widgetsPreviewImages/attitudeLineChart.png";
import shareOfEarnedMediaPieChart from "../../images/widgetsPreviewImages/shareOfEarnedMedia.png";
import positionVScompetitionWidget from "../../images/widgetsPreviewImages/position-vs-competition.png";
import esgDistributionWidget from "../../images/widgetsPreviewImages/esgDestribution.png";
import esgIndexLinechart from "../../images/widgetsPreviewImages/esgIndex.png";
import esgSentimentLinechart from "../../images/widgetsPreviewImages/esgSentiment.png";
import funnelHorizontal from "../../images/widgetsPreviewImages/funnel.png";
import newKeyAreas from "../../images/widgetsPreviewImages/key-areas.png";
import reputationDestributionBar from "../../images/widgetsPreviewImages/reputationDestribution.png";
import reputationIndexLinechart from "../../images/widgetsPreviewImages/reputationIndex.png";
import reputationSentimentLinechart from "../../images/widgetsPreviewImages/reputationSentiment.png";
import robustnessWidget from "../../images/widgetsPreviewImages/robustnessWidget.png";
import sentimentIndexWidget from "../../images/widgetsPreviewImages/sentimentIndexWidget.png";
import mentions from "../../images/widgetsPreviewImages/mentions.png";
import presentationImage from "../../images/presentationImage.png";
import topKeywordsWidget from "../../images/widgetsPreviewImages/top-keywords.png";

const images = {
  bird,
  birds,
  birdAI,
  birdWaving,
  topKeywordsWidget,
  associatedWordsWidget,
  lineChartWidget,
  pieWidget,
  competitorsPieWidget,
  awarenessPieCharts,
  shareOfEarnedMediaPieChart,
  positionVScompetitionWidget,
  esgDestributionWidget: esgDistributionWidget,
  esgIndexLinechart,
  esgSentimentLinechart,
  funnelHorizontal,
  newKeyAreas,
  reputationDestributionBar,
  reputationIndexLinechart,
  reputationSentimentLinechart,
  robustnessWidget,
  sentimentIndexWidget,
  birdAI2,
  mentions,
  presentationImage,
  timeLineWidget,
};

export default images;
