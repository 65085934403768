import { FC, ButtonHTMLAttributes } from "react";
import styles from "./GreenButton.module.scss";
import cx from "classnames";

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

export const GreenButton: FC<Props> = ({ className, children, ...props }) => {
  return (
    <button className={cx(styles.greenButton, className)} {...props}>
      {children}
    </button>
  );
};
