export const WIDGET_ID = "result-business-drivers";

export const BUSINESS_DRIVERS_DATE_RANGES = [
  {
    value: "oneMonth",
    additionalValue: "oneMonthAgo",
    label: "1 month",
  },
  {
    value: "threeMonths",
    additionalValue: "threeMonthsAgo",
    label: "3 months",
  },
  {
    value: "sixMonths",
    additionalValue: "sixMonthsAgo",
    label: "6 months",
  },
  {
    value: "oneYear",
    additionalValue: "oneYearAgo",
    label: "1 year",
  },
] as const;
