import { useSelector } from "react-redux";
import { useMemo } from "react";
import compareAsc from "date-fns/compareAsc";
import differenceInDays from "date-fns/differenceInDays";

import { LINE_CHART_COLORS } from "../../../../data/colors";
import { formatToPartialDate } from "../../../../utils";
import { ChartItem, ChartTimePlotter } from "../types";

type UseGetTimePlotters = {
  data: ChartItem[];
  searchId: string;
  timePlotterId?: string;
};

type UseGetTimePlottersResult = {
  timePlotters: ChartTimePlotter[];
  timePlotter?: ChartTimePlotter;
};

export const useGetTimePlotters = ({
  data,
  searchId,
  timePlotterId,
}: UseGetTimePlotters): UseGetTimePlottersResult => {
  const _timePlotters: Store.TimePlot[] = useSelector(
    ({ timePlots }: Store.State) =>
      timePlots.data.filter((timePlotter) => timePlotter.searchId === searchId),
  );

  const activeTimePlotters = useMemo<ChartTimePlotter[]>(
    () =>
      _timePlotters
        .reduce((acc, timePlotter, index) => {
          if (timePlotter.isActive)
            acc.push(getFormattedTimePlotter(data, timePlotter, index));

          return acc;
        }, [] as ChartTimePlotter[])
        .sort((a, b) =>
          compareAsc(
            differenceInDays(a.startDate, a.endDate),
            differenceInDays(b.startDate, b.endDate),
          ),
        ),
    [_timePlotters, data],
  );

  const timePlotter = useMemo<ChartTimePlotter | undefined>(() => {
    if (!timePlotterId) return;

    const _timePlotter = _timePlotters.find(({ id }) => id === timePlotterId);

    if (!_timePlotter) return;

    return getFormattedTimePlotter(data, _timePlotter);
  }, [_timePlotters, data, timePlotterId]);

  return {
    timePlotters: activeTimePlotters,
    timePlotter,
  };
};

function getFormattedTimePlotter(
  data: ChartItem[],
  _timePlotter: Store.TimePlot,
  index: number = 0,
): ChartTimePlotter {
  const timePlotter = {
    ..._timePlotter,
    color: LINE_CHART_COLORS[index],
  };
  const { dateFrom: startDate, dateTo: endDate } = timePlotter;

  const [startDateTime, endDateTime] = [
    new Date(formatToPartialDate(startDate)).getTime(),
    new Date(formatToPartialDate(endDate)).getTime(),
  ];

  const { minDateTime, maxDateTime } = data.reduce(
    (acc, { time }) => {
      if (time < acc?.minDateTime) acc.minDateTime = time + 1;
      if (time > acc?.maxDateTime) acc.maxDateTime = time;
      return acc;
    },
    { minDateTime: data[0]?.time || 0, maxDateTime: 0 },
  );

  switch (true) {
    // < ... startDate ... > endDate
    case startDateTime > minDateTime &&
      startDateTime < maxDateTime &&
      endDateTime > maxDateTime:
      return {
        ...timePlotter,
        startDate: startDateTime,
        endDate: maxDateTime,
      };
    // startDate < ... endDate ... >
    case startDateTime < minDateTime &&
      endDateTime < maxDateTime &&
      endDateTime > minDateTime:
      return {
        ...timePlotter,
        startDate: minDateTime,
        endDate: endDateTime,
      };
    // startDate < ... > endDate
    case startDateTime <= minDateTime && endDateTime >= maxDateTime:
      return {
        ...timePlotter,
        startDate: minDateTime,
        endDate: maxDateTime,
      };
    // startDate endDate < ... >
    case startDateTime < minDateTime && endDateTime < minDateTime:
    // < ... > startDate endDate
    case startDateTime > maxDateTime && endDateTime > maxDateTime:
    default:
      return {
        ...timePlotter,
        startDate: startDateTime,
        endDate: endDateTime,
      };
  }
}
