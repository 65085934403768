import { compareAsc, subYears } from "date-fns";

export function getLineChartDataForOneYear<T extends { date: string }>(
  data: T[],
) {
  try {
    const sortedData = [...data].sort((a, b) =>
      compareAsc(Date.parse(a.date), Date.parse(b.date)),
    );

    const lastItem = sortedData[sortedData.length - 1];
    if (!lastItem) return [];

    const lastDate = lastItem.date;

    const oneYearAgoDate = subYears(Date.parse(lastDate), 1);

    const oneYearData: T[] = [];

    for (let i = sortedData.length - 1; i >= 0; i--) {
      if (compareAsc(Date.parse(sortedData[i]!.date), oneYearAgoDate) >= 0) {
        oneYearData.push(sortedData[i]!);
      } else {
        break;
      }
    }

    return oneYearData.reverse();
  } catch (error) {
    console.dir(error);

    return [];
  }
}
