import firebase from "firebase/app";
import * as yup from "yup";
import mapValues from "lodash/mapValues";

import { GridItem, Dashboard } from "./dashboardsSlice";
import { sendDataToGTM, showDevelopmentError } from "../../utils";
import { WIDGET_CHARTS } from "../../widgets/widgets";

const gridItemSchema: yup.SchemaOf<GridItem> = yup.object({
  i: yup.string().required(),
  x: yup.number().required(),
  y: yup.number().required(),
  w: yup.number().required(),
  h: yup.number().required(),
});

const widgetViewSettingsSchema = yup.object().shape({
  selectedView: yup.object().shape({
    type: yup
      .mixed()
      .oneOf([...WIDGET_CHARTS])
      .required(),
    subType: yup.string().transform((value) => value ?? ""),
  }),
});

const trackerViewSettingsSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => widgetViewSettingsSchema)),
);

const dashboardViewSettingsSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => trackerViewSettingsSchema)),
);

const dashboardSchema = yup
  .object({
    name: yup.string().required(),
    isPrivate: yup.boolean().required(),
    tiles: yup.lazy((obj) =>
      yup
        .object(
          mapValues(obj, () => {
            return yup.lazy((obj) =>
              yup.object(
                mapValues(obj, () => {
                  return yup.mixed().oneOf([true]);
                }),
              ),
            );
          }),
        )
        .required(),
    ),
    layouts: yup
      .object()
      .shape({
        small: yup
          .array()
          .of(gridItemSchema.required())
          .transform((value) => value || []),
        medium: yup
          .array()
          .of(gridItemSchema.required())
          .transform((value) => value || []),
        large: yup
          .array()
          .of(gridItemSchema.required())
          .transform((value) => value || []),
      })
      .required()
      .noUnknown(),
    userId: yup.string().required(),
    createdAt: yup.string().required(),
    lastUsedSearchId: yup.string().default(""),
    updatedAt: yup.string().required(),
    isCopiedDashboard: yup.boolean(),
    companyId: yup.string(),
    isPinned: yup.boolean(),
  })
  .noUnknown();

export async function createDashboard(
  payload: Omit<Dashboard, "id" | "createdAt" | "updatedAt"> & {
    companyId: string;
  },
) {
  let createdAt, updatedAt;

  createdAt = updatedAt = new Date().toISOString();

  const { isCopiedDashboard } = payload;

  const doc = await firebase
    .firestore()
    .collection("dashboards")
    .add({
      name: payload.name,
      isPrivate: payload.isPrivate,
      tiles: payload.tiles,
      layouts: payload.layouts,
      companyId: payload.companyId,
      userId: payload.userId,
      createdAt,
      updatedAt,
      lastUsedSearchId: payload.lastUsedSearchId,
      ...(typeof isCopiedDashboard === "boolean" ? { isCopiedDashboard } : {}),
    });

  sendDataToGTM("createDashboard", { createdDashboardId: doc.id });

  return {
    id: doc.id,
    createdAt,
    updatedAt,
  };
}

export async function readDashboards(companyId: string) {
  const collection = await firebase
    .firestore()
    .collection("dashboards")
    .where("companyId", "==", companyId)
    .get();

  return collection.docs.reduce<Dashboard[]>((acc, doc) => {
    try {
      const validatedData = dashboardSchema.validateSync(doc.data());

      const tiles: Dashboard["tiles"] = {};

      for (let tile in validatedData.tiles) {
        const _thisTiles: Dashboard["tiles"]["widgetId"] =
          validatedData.tiles[tile] || {};

        if (!!Object.keys(_thisTiles).length) {
          tiles[tile] = _thisTiles;
        }
      }

      acc.push({ id: doc.id, ...validatedData, tiles });
    } catch (error) {
      const errorText = `ERROR IN [${doc.id}] DASHBOARD`;

      showDevelopmentError({
        additionalTexts: [errorText],
        error,
        clickUpProps: {
          name: `[${doc.id}]: Error at dashboard`,
          text_content: {
            error: errorText,
            message: error.message ? error.message : JSON.stringify(error),
          },
        },
      });
    }

    return acc;
  }, []);
}

export async function readDashboardViewSettings(
  dashboardId: string,
  userId: string,
) {
  const doc = await firebase
    .firestore()
    .collection("dashboards")
    .doc(dashboardId)
    .collection("view-settings")
    .doc(userId)
    .get();

  try {
    return dashboardViewSettingsSchema.validateSync(doc.data());
  } catch (error) {
    console.dir(error);
  }
}

export async function readDashboardById(dashboardId: string) {
  const doc = await firebase
    .firestore()
    .collection("dashboards")
    .doc(dashboardId)
    .get();

  const validatedData = dashboardSchema.validateSync(doc.data());

  const tiles: Dashboard["tiles"] = {};

  for (let tile in validatedData.tiles) {
    const _thisTiles: Dashboard["tiles"]["widgetId"] =
      validatedData.tiles[tile] || {};

    if (!!Object.keys(_thisTiles).length) {
      tiles[tile] = _thisTiles;
    }
  }

  return { id: doc.id, ...validatedData, tiles };
}

export async function updateDashboard({
  id,
  changes,
}: UpdateEntity<Dashboard>) {
  await firebase.firestore().collection("dashboards").doc(id).update(changes);
}

export async function updateDashboards(entities: UpdateEntity<Dashboard>[]) {
  const batch = firebase.firestore().batch();

  entities.forEach(({ id, changes }) => {
    const docRef = firebase.firestore().collection("dashboards").doc(id);

    batch.set(docRef, changes, { merge: true });
  });

  await batch.commit();
}

export async function updateDashboardViewSettings(
  { id, changes }: UpdateEntity<Dashboard["viewSettings"]>,
  userId: Store.User["id"],
): Promise<void> {
  await firebase
    .firestore()
    .collection("dashboards")
    .doc(id)
    .collection("view-settings")
    .doc(userId)
    .set(changes, { merge: true });
}

export async function deleteDashboard(id: Dashboard["id"]) {
  await firebase.firestore().collection("dashboards").doc(id).delete();
}
