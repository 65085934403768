import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import context from "../../../../context";
import { downloadDomAsImage } from "../../../../utils";
import { ButtonWithTransparentBackground } from "../../../../components";

type Props = {
  parentComponent: HTMLElement;
  imageName: string;
  widgetId: string;
};

export const DownloadImageButton: FC<Props> = ({
  parentComponent,
  imageName,
  widgetId,
}) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useContext(context);

  return (
    <ButtonWithTransparentBackground
      text={t("download_image_button")}
      icon="DownloadArrow"
      handleClick={() =>
        downloadDomAsImage({
          element: parentComponent,
          widgetId,
          imageName,
          options: {
            bgcolor: isDarkTheme ? "#2b324d" : "#ffffff",
          },
        })
      }
    />
  );
};
