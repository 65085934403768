import { useContext, useState, useEffect, useMemo } from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import Notifications from "react-notify-toast";
import i18n from "i18next";

import styles from "./App.module.scss";
import context from "../context";
import ToastNotification from "../components/ToastNotification/ToastNotification";
import { getLocalization } from "../store/api";
import { setAuthObserver } from "../store/actions";
import { Preloader } from "../components";
import { ModalWrapper } from "../components/modals/ModalWrapper";
import { notIframe } from "../utils/checkIfNotTheIFrame";
import { ROUTS } from "../data/routs";

import Auth from "../containers/Auth/Auth";
import Home from "../containers/Home/Home/Home";
import VerifyEmailPage from "../containers/Auth/VerifyEmail/VerifyEmailPage";
import VerifyEmail from "../containers/Auth/VerifyEmail/VerifyEmail";
import Presentation from "../containers/Presentation/Presentation";
import TermsAndConditionsOfUse from "../containers/TermsAndConditionsOfUse/TermsAndConditionsOfUse";

interface Props {
  isAuthenticated: boolean;
  setAuthObserver: () => void;
}

const App = ({ isAuthenticated, setAuthObserver }: Props) => {
  const history = useHistory();
  const { pathname } = history.location;
  const { isDarkTheme, modalElement, isGlobalPreloaderShown } = useContext(
    context,
  );

  const [isShown, setIsShown] = useState(false);
  const [isLocalizationLoaded, setIsLocalizationLoaded] = useState(false);

  useEffect(() => {
    // Snipper to remove 100vh
    function appHeight(this: any) {
      const currentWindowHeight = this.__proto__.currentWindowHeight;
      const doc = document.documentElement;
      const windowHeight = window.innerHeight;

      if (
        typeof currentWindowHeight === "undefined" ||
        currentWindowHeight !== windowHeight
      ) {
        doc.style.setProperty("--app-height", `${windowHeight}px`);
        this.__proto__.currentWindowHeight = windowHeight;
      }
    }
    appHeight.call(appHeight);

    window.addEventListener("resize", appHeight);
    window.addEventListener("orientationchange", appHeight);
  }, []);

  useEffect(() => {
    (async () => {
      if (notIframe) {
        await setAuthObserver();
      }

      setIsShown(true);
    })();
  }, [setAuthObserver]);

  useEffect(() => {
    (async () => {
      const localization = await getLocalization();
      i18n.addResourceBundle("eng", "translation", localization, true, true);
      setIsLocalizationLoaded(true);
    })();
  }, []);

  const generalComponent: JSX.Element = useMemo(() => {
    const isPresentationPage = pathname.includes("presentation");
    if (isPresentationPage) {
      return <Presentation />;
    }
    return isAuthenticated ? <Home /> : <Auth />;
  }, [isAuthenticated, pathname]);

  return (
    <Switch>
      <Route path="/">
        {isShown && isLocalizationLoaded ? (
          <div
            className={styles.app}
            {...{ "dark-theme": isDarkTheme ? "" : undefined }}
          >
            <div
              className={styles.content}
              style={{ ...(modalElement && { filter: "blur(4px)" }) }}
            >
              <div className={styles.section}>
                <Switch>
                  <Route exact path={ROUTS.presentationFirstPage}>
                    <Presentation />
                  </Route>
                  <Route exact path={ROUTS.verifyEmailPage}>
                    <VerifyEmailPage />
                  </Route>
                  <Route exact path={ROUTS.verifyToken}>
                    <VerifyEmail />
                  </Route>
                  <Route exact path={ROUTS.termsAndCondition}>
                    <TermsAndConditionsOfUse />
                  </Route>
                  <Route path="/">{generalComponent}</Route>
                  <Redirect to="/" />
                </Switch>
              </div>
            </div>
            {modalElement && <ModalWrapper>{modalElement}</ModalWrapper>}
            {isGlobalPreloaderShown && <Preloader modifier="global" />}
            <Notifications options={{ zIndex: 16, timeout: 3000 }} />
            <ToastNotification />
          </div>
        ) : null}
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state: Store.State) => ({
  isAuthenticated: Boolean(state.user.id),
});

export default withRouter(
  connect(mapStateToProps, {
    setAuthObserver,
  })(App),
);
