import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "../generalLinkStyles.module.scss";
import context from "../../../../context";
import { Profile, Faq } from "../../../../icons";
import { ROUTS } from "../../../../data/routs";

const FAQ_PAGE_LINK = "http://help.mytelescope.io/en/";

type Props = {
  className?: string;
};

const ProfileLink: FC<Props> = ({ className }) => {
  const user = useSelector(({ user }: Store.State) => user);
  const { setIsSidebarOpen } = useContext(context);

  const userName: string = useMemo(() => user.firstName ?? "", [user]);

  return (
    <NavLink
      to={`${ROUTS.settingsPage}#profile`}
      className={className}
      activeClassName={styles.activeItem}
      onClick={() => setIsSidebarOpen(false)}
    >
      <Profile />
      <span title={userName}>{userName}</span>
    </NavLink>
  );
};

const FAQLink: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { setIsSidebarOpen } = useContext(context);

  return (
    <a
      className={className}
      href={FAQ_PAGE_LINK}
      target="_blank"
      rel="noreferrer"
      title={t("menu_help_center")}
      onClick={() => setIsSidebarOpen(false)}
    >
      <Faq />
      <span>{t("menu_help_center")}</span>
    </a>
  );
};

export { FAQLink, ProfileLink };
