import { CSSProperties } from "react";
import classes from "./AwarenessPieChart.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import { BLUE, RED } from "../../../data/colors";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";
interface Props {
  className?: string;
  style?: CSSProperties;
  data?: {
    brandName: string;
    value: number;
  }[];
  type?: "preview" | "page";
}

export const AwarenessPieChart = ({
  className = "",
  style = {},
  data = [],
  type = "preview",
}: Props) => {
  const { t } = useTranslation();

  const modifiedData = data.map(({ brandName, value }) => {
    const fixedValue = +value.toFixed(2);

    return {
      brandName,
      data: [
        {
          label: t("w_awareness_aware_label"),
          value: fixedValue,
          color: BLUE,
        },
        {
          label: t("w_awareness_not_aware_label"),
          value: 100 - fixedValue,
          color: RED,
        },
      ],
    };
  });

  const {
    ref: chartWrapperRef,
    width: chartWrapperWidth = 0,
    height: chartWrapperHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const size = Math.min(chartWrapperHeight, chartWrapperWidth);

  return modifiedData.length ? (
    <div
      className={`${classes.awarenessPieChart} ${
        type === "page" ? classes.awarenessPieChartPage : ""
      } ${className} `}
      style={style}
      ref={chartWrapperRef}
    >
      <div className={classes.labels}>
        {modifiedData[0]?.data.map((item, i) => (
          <div className={classes.label} key={i}>
            <div
              className={classes.labelIcon}
              style={{ backgroundColor: item.color }}
            />
            <div>{item.label}</div>
          </div>
        ))}
      </div>
      <div
        className={`${classes.chartsWrapper} ${
          classes[
            "chartsWrapperFor" +
              (modifiedData.length > 1 ? (modifiedData.length > 3 ? 5 : 3) : 1)
          ]
        }`}
      >
        {modifiedData.map((brandData, i) => (
          <div
            className={classes.chartWrapper}
            style={{
              gridArea: "chart" + i,
              width:
                type === "preview"
                  ? !i
                    ? (size - 60) / 2
                    : (size - 60) / 4
                  : "100%",
            }}
            key={i}
          >
            <PieChart
              animate
              animationDuration={500}
              animationEasing="ease-out"
              data={brandData.data}
              lineWidth={20}
              label={({ dataEntry, dataIndex }) =>
                dataIndex === 0 ? dataEntry.value + "%" : ""
              }
              labelPosition={0}
              labelStyle={{
                fill: "currentColor",
                fontSize: 24,
                fontWeight: 100,
              }}
            />
            <div className={classes.brandName}>{brandData.brandName}</div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
