import { createDraftSafeSelector } from "@reduxjs/toolkit";

export const selectMarkets = createDraftSafeSelector(
  ({ markets }: RootState) => markets,
  (markets) => markets,
);

export const selectMarketsEntities = createDraftSafeSelector(
  selectMarkets,
  (markets) => markets.entities,
);
