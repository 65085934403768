import { FC, memo, MouseEvent } from "react";
import cx from "classnames";

import styles from "./ChartButton.module.scss";
import * as icons from "../../../icons";

type Props = {
  icon?: AppIcon;
  className?: string;
  number?: number;
  onButtonClick?: (e: MouseEvent) => void;
  isEventPropagationStopped?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
};

const memoFunction = (prevState: Props, nextState: Props) => {
  const prevStateClassNames = prevState.className;
  const nextStateClassNames = nextState.className;

  return prevStateClassNames === nextStateClassNames;
};

export const ChartButton: FC<Props> = memo(
  ({
    className,
    icon,
    number,
    onButtonClick,
    isEventPropagationStopped,
    isSelected,
    isDisabled,
  }) => {
    const Icon = icons[icon || "NoIcon"];

    return (
      <button
        className={cx(
          styles.chartButton,
          className,
          isSelected ? styles.selected : "",
        )}
        onClick={(e) => {
          isEventPropagationStopped && e.stopPropagation();
          onButtonClick && onButtonClick(e);
        }}
        disabled={isDisabled}
      >
        <Icon />
        {number !== undefined && <div>{number}</div>}
      </button>
    );
  },
  memoFunction,
);
