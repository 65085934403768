import { useMemo } from "react";
import classes from "./EsgSentimentPieChartView.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import esgParameters from "../../../../../data/esgParameters";
import { GREEN, RED, ORANGE } from "../../../../../data/colors";
import DATE_RANGES from "../../../../../data/dateRanges";
import { useTranslation } from "react-i18next";

interface Props {
  data: EsgSentiment.PieChart.Data;
  currentDateRange?: typeof DATE_RANGES[number]["value"];
}

const EsgSentimentPieChart = ({ data, currentDateRange }: Props) => {
  const { t } = useTranslation();

  const rangeData = useMemo(() => currentDateRange && data[currentDateRange], [
    data,
    currentDateRange,
  ]);

  const modifiedData = useMemo(
    () =>
      esgParameters.map(({ name, description }) => ({
        label: name,
        description: t(description),
        data: [
          {
            value: Number(rangeData?.values[name]?.positive.toFixed()) || 0,
            color: GREEN,
          },
          {
            value: Number(rangeData?.values[name]?.negative.toFixed()) || 0,
            color: RED,
          },
          {
            value: Number(rangeData?.values[name]?.neutral.toFixed()) || 0,
            color: ORANGE,
          },
        ],
      })),
    [rangeData, t],
  );

  return (
    <div className={classes.esgSentimentPieChart}>
      {modifiedData.map(({ label, description, data }, i) => (
        <div
          className={classes.chartArea}
          style={{ gridArea: "pie" + i }}
          key={i}
        >
          <div className={classes.chartWrapperOuter}>
            <div className={classes.chartWrapperInner}>
              <PieChart
                animate
                animationDuration={500}
                animationEasing="ease-out"
                data={data}
                label={(props) => {
                  const value = props.dataEntry.value;
                  return value ? value + "%" : "";
                }}
                labelStyle={{
                  fill: "#efe9ea",
                  fontSize: 8,
                  fontWeight: 500,
                }}
                startAngle={-90}
              />
            </div>
            <div className={classes.label} title={description}>
              {label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EsgSentimentPieChart;
