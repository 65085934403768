import { ComponentType, forwardRef } from "react";
import cx from "classnames";

import styles from "./withTooltip.module.scss";

type InjectedProps = {
  tooltip?: string;
  isTooltipActive?: boolean;
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  tooltipClassName?: string;
};

export const withTooltip = <BaseProps extends { className?: string }>(
  Component: ComponentType<BaseProps>,
) =>
  forwardRef<HTMLElement, BaseProps & InjectedProps>(
    (
      {
        tooltip,
        isTooltipActive,
        tooltipPosition = "bottom",
        tooltipClassName,
        ..._componentProps
      },
      ref,
    ) => {
      const componentProps = _componentProps as BaseProps;

      return (
        <div
          className={cx(
            tooltipClassName,
            isTooltipActive ? styles.withTooltip : "",
          )}
          data-tooltip={tooltip}
          data-tooltipposition={tooltipPosition}
        >
          <Component ref={ref} {...componentProps} />
        </div>
      );
    },
  );
