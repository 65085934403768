import { texts } from "../ReviewsDataTexts";

const WIDGET_ID = "result-sentiment-drivers";

const ARTICLES_PARAMETERS = ["all", "neutral", "positive", "negative"] as const;

const ARTICLES_LIMIT = 200 as const;

const ABOUT_THIS = texts["result-sentiment-drivers"]?.aboutThis || "";

const REVIEW_DATA_CATEGORY = "Mentions review data";

export {
  WIDGET_ID,
  ARTICLES_PARAMETERS,
  ARTICLES_LIMIT,
  ABOUT_THIS,
  REVIEW_DATA_CATEGORY,
};
