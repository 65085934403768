export const checkIfPieChartDataExist = (
  data?: ShareOfEarnedMedia.PieChart.Data,
): boolean => {
  if (!data) return false;

  return Object.values(
    data,
  ).some((item: ShareOfEarnedMedia.PieChart.Item | undefined) =>
    item?.values?.some(
      ({ value }: ShareOfEarnedMedia.PieChart.Value) => value && value > 0,
    ),
  );
};

export const checkIfPieChartDataByTimePeriodExist = (
  data?: ShareOfEarnedMedia.PieChart.Value[],
): boolean => {
  if (!data || !data.length) return false;

  return data?.some(
    ({ value }: ShareOfEarnedMedia.PieChart.Value) => value && value > 0,
  );
};

export const checkIfLineChartDataExist = (
  data?: ShareOfEarnedMedia.LineChart.Data,
): boolean => {
  if (!data || !data.length) return false;

  const isMainDataExist: boolean = data?.some(
    (item: ShareOfEarnedMedia.LineChart.Item) =>
      Object.values(item.values).some((item: number) => item && item !== 0),
  );

  const isAdditionalDataExist: boolean = data?.some(
    (item: ShareOfEarnedMedia.LineChart.Item) =>
      Object.values(item?.additionalValues || 0).some(
        (item: number) => item && item !== 0,
      ),
  );

  return isMainDataExist || isAdditionalDataExist;
};
